<loading *ngIf="claimsLoader">{{ isSubmitted }}</loading>

<div
    class="row"
    dir="{{ 'LogIn.Dir' | translate }}"
    style="background-color: #226439; color: white"
>
    <div class="col-md-12">
        <p>
            <strong
                >&nbsp;{{ "LogIn.DlTitle2" | translate }} &ndash;
                {{ "LogIn.DlTitle" | translate }}</strong
            >
        </p>
    </div>
</div>

<br />
<form [formGroup]="DetailsForm">
    <mat-card>
        <mat-card-content>
            <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-6">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            class="form-control input-form-design w-100"
                        >
                            <label
                                for="dateentry"
                                class="form-label"
                                style="width: 100%"
                                >{{ "LogIn.LaborEntryDate" | translate
                                }}<span style="color: red">*</span></label
                            >
                            <div
                                style="
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 2px;
                                    display: flex;
                                    flex-direction: row;
                                "
                            >
                                <mat-datepicker-toggle
                                    style="color: #156538; margin-top: -7px"
                                    matIconSuffix
                                    [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <input
                                    matInput
                                    id="dateentry"
                                    [matDatepicker]="picker"
                                    placeholder="MM/DD/YYYY"
                                    #entrydate
                                    style="width: 70%"
                                    required
                                    formControlName="fromdate"
                                />
                            </div>
                        </mat-form-field>
                        <div
                            *ngIf="
                                fromdatevalidator != null &&
                                fromdatevalidator != ''
                            "
                            style="color: red"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ fromdatevalidator }}
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            class="form-control input-form-design w-100"
                        >
                            <label
                                for="date"
                                class="form-label"
                                style="width: 100%"
                                >{{ "LogIn.LaborExitDate" | translate }}</label
                            >
                            <div
                                style="
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 2px;
                                    display: flex;
                                    flex-direction: row;
                                "
                            >
                                <mat-datepicker-toggle
                                    style="color: #156538; margin-top: -7px"
                                    matIconSuffix
                                    [for]="pickerExp"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #pickerExp></mat-datepicker>
                                <input
                                    matInput
                                    id="date"
                                    [matDatepicker]="pickerExp"
                                    style="width: 70%"
                                    required
                                    formControlName="expirydate"
                                    placeholder="MM/DD/YYYY"
                                />
                            </div>
                        </mat-form-field>
                        <!-- <div *ngIf="
                        expirydatevalidator != null &&
                        expirydatevalidator != ''
                        " style="color: red">
                            <strong>
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ expirydatevalidator }}
                            </strong>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-6">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            style="height: 100%; width: 100%"
                        >
                            <mat-select
                                class="form-outline mb-4"
                                formControlName="ddlbenefeciery"
                                required
                                placeholder="{{
                                    'LogIn.Benefeciery' | translate
                                }}"
                                style="
                                    width: 100%;
                                    height: 35px;
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 5px;
                                "
                            >
                                <mat-option
                                    value="{{
                                        'LogIn.PolicyOwner' | translate
                                    }}"
                                    >{{
                                        "LogIn.PolicyOwner" | translate
                                    }}</mat-option
                                >
                                <mat-option
                                    value="{{ 'LogIn.Labor' | translate }}"
                                    >{{ "LogIn.Labor" | translate }}</mat-option
                                >
                                <!-- <mat-option
                                value="{{ 'LogIn.Office' | translate }}"
                                >{{ "LogIn.Office" | translate }}</mat-option
                            >
                            <mat-option
                                value="{{
                                    'LogIn.AuthBusinessOwner' | translate
                                }}"
                                >{{
                                    "LogIn.AuthBusinessOwner" | translate
                                }}</mat-option
                            > -->
                            </mat-select>
                        </mat-form-field>

                        <div
                            *ngIf="
                                benefecieryValidator != null &&
                                benefecieryValidator != ''
                            "
                            style="color: red"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ benefecieryValidator }}
                            </strong>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            style="height: 100%; width: 100%"
                        >
                            <mat-select
                                class="form-outline mb-4"
                                formControlName="ddlRecuitment"
                                required
                                placeholder="{{
                                    'LogIn.Recuitment' | translate
                                }}"
                                style="
                                    width: 100%;
                                    height: 35px;
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 5px;
                                "
                            >
                                <mat-option
                                    value="{{ 'LogIn.Office' | translate }}"
                                    >{{
                                        "LogIn.Office" | translate
                                    }}</mat-option
                                >
                                <mat-option
                                    value="{{
                                        'LogIn.AuthBusinessOwner' | translate
                                    }}"
                                    >{{
                                        "LogIn.AuthBusinessOwner" | translate
                                    }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>

                        <div
                            *ngIf="
                                recuitmentValidator != null &&
                                recuitmentValidator != ''
                            "
                            style="color: red"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ recuitmentValidator }}
                            </strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-6">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            class="example-full-width"
                            style="height: 55px; width: 100%"
                        >
                            <mat-label style="margin: 5px">{{
                                "LogIn.ContractAmount" | translate
                            }}</mat-label>
                            <input
                                type="text"
                                matInput
                                style="
                                    height: 55px;
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 5px;
                                "
                                formControlName="contractamt"
                                required
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            />
                        </mat-form-field>

                        <div
                            *ngIf="
                                contractamountValidator != null &&
                                contractamountValidator != ''
                            "
                            style="color: red"
                            dir="{{ 'LogIn.Dir' | translate }}"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ contractamountValidator }}
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            class="form-control input-form-design w-100"
                        >
                            <label
                                for="date"
                                class="form-label"
                                style="width: 100%"
                                >{{ "LogIn.DateOfLaborPickup" | translate
                                }}<span style="color: red">*</span></label
                            >
                            <div
                                style="
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 2px;
                                    display: flex;
                                    flex-direction: row;
                                "
                            >
                                <mat-datepicker-toggle
                                    style="color: #156538; margin-top: -7px"
                                    matIconSuffix
                                    [for]="pickupdate"
                                >
                                </mat-datepicker-toggle>
                                <mat-datepicker #pickupdate></mat-datepicker>
                                <input
                                    matInput
                                    [matDatepicker]="pickupdate"
                                    placeholder="MM/DD/YYYY"
                                    required
                                    style="width: 70%"
                                    formControlName="pickupdate"
                                />
                            </div>
                        </mat-form-field>
                        <div
                            *ngIf="
                                pickupdatevalidator != null &&
                                pickupdatevalidator != ''
                            "
                            style="color: red"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ pickupdatevalidator }}
                            </strong>
                        </div>
                    </div>
                </div>
            </div>

            <!-- new fields -->
            <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-3">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            style="height: 100%; width: 100%"
                        >
                            <mat-label style="margin: 5px">{{
                                "LogIn.PolicyMobile" | translate
                            }}</mat-label>
                            <input
                                type="text"
                                matInput
                                style="
                                    height: 55px;
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 5px;
                                "
                                formControlName="policyMobile"
                                required
                                maxlength="10"
                                minlength="10"
                                (keypress)="
                                    ($event.charCode >= 48 &&
                                        $event.charCode < 58)
                                "
                            />
                        </mat-form-field>

                        <div
                            *ngIf="
                                policyMobileValidator != null &&
                                policyMobileValidator != ''
                            "
                            style="color: red"
                            dir="{{ 'LogIn.Dir' | translate }}"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ policyMobileValidator }}
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            class="example-full-width"
                            style="height: 55px; width: 100%"
                        >
                            <mat-label style="margin: 5px">{{
                                "LogIn.PolicyEmail" | translate
                            }}</mat-label>
                            <input
                                type="text"
                                matInput
                                style="
                                    height: 55px;
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 5px;
                                "
                                formControlName="policyEmail"
                                required
                            />
                        </mat-form-field>

                        <div
                            *ngIf="
                                policyEmailValidator != null &&
                                policyEmailValidator != ''
                            "
                            style="color: red"
                            dir="{{ 'LogIn.Dir' | translate }}"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ policyEmailValidator }}
                            </strong>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            class="example-full-width"
                            style="height: 55px; width: 100%"
                        >
                            <mat-label style="margin: 5px">{{
                                "LogIn.ClaimaintMobile" | translate
                            }}</mat-label>
                            <input
                                type="text"
                                matInput
                                style="
                                    height: 55px;
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 5px;
                                "
                                formControlName="claimaintMobile"
                                required
                                maxlength="10"
                                minlength="10"
                                (keypress)="
                                    ($event.charCode >= 48 &&
                                        $event.charCode < 58)
                                "
                            />
                        </mat-form-field>

                        <div
                            *ngIf="
                                claimaintMobileValidator != null &&
                                claimaintMobileValidator != ''
                            "
                            style="color: red"
                            dir="{{ 'LogIn.Dir' | translate }}"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ claimaintMobileValidator }}
                            </strong>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            class="example-full-width"
                            style="height: 55px; width: 100%"
                        >
                            <mat-label style="margin: 5px">{{
                                "LogIn.ClaimaintEmail" | translate
                            }}</mat-label>
                            <input
                                type="text"
                                matInput
                                style="
                                    height: 55px;
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 5px;
                                "
                                formControlName="claimaintEmail"
                                required
                            />
                        </mat-form-field>

                        <div
                            *ngIf="
                                claimaintEmailValidator != null &&
                                claimaintEmailValidator != ''
                            "
                            style="color: red"
                            dir="{{ 'LogIn.Dir' | translate }}"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ claimaintEmailValidator }}
                            </strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-5" dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-12">
                    <div
                        class="form-outline mb-4"
                        style="width: 100%; height: 55px"
                    >
                        <mat-form-field
                            appearance="none"
                            style="height: 100%; width: 100%"
                        >
                            <textarea
                                formControlName="claimDescription"
                                matInput
                                cols="20"
                                rows="5"
                                required
                                placeholder="{{
                                    'LogIn.ClaimDescription' | translate
                                }}"
                                style="
                                    border: 1px solid black;
                                    border-radius: 7px;
                                    padding: 5px;
                                "
                            ></textarea>
                        </mat-form-field>

                        <div
                            *ngIf="
                                claimDescriptionValidator != null &&
                                claimDescriptionValidator != ''
                            "
                            style="color: red"
                            class="mt-3"
                        >
                            <strong>
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                {{ claimDescriptionValidator }}
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <br />
    <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
        <div class="col-md-12" dir="{{ 'LogIn.Dir' | translate }}">
            <mat-card style="box-shadow: 2px 3px 10px 4px #dee2e6">
                <mat-card-title style="color: #156538; font-size: larger"
                    >{{ "LogIn.ClaimDLDetails" | translate }}
                    <span style="color: red">*</span></mat-card-title
                >
                <mat-card-content>
                    <mat-radio-group
                        aria-labelledby="example-radio-group-label"
                        formControlName="claimreason"
                        (change)="defineDocs($event)"
                    >
                        <mat-radio-button style="margin-right: 1%" value="1">{{
                            "LogIn.Reasonone" | translate
                        }}</mat-radio-button>
                        <mat-radio-button style="margin-right: 1%" value="2">{{
                            "LogIn.ReasonTwo" | translate
                        }}</mat-radio-button>
                        <mat-radio-button style="margin-right: 1%" value="3">{{
                            "LogIn.ReasonThree" | translate
                        }}</mat-radio-button>
                        <mat-radio-button style="margin-right: 1%" value="4">{{
                            "LogIn.ReasonFour" | translate
                        }}</mat-radio-button>
                        <mat-radio-button style="margin-right: 1%" value="5">{{
                            "LogIn.ReasonFive" | translate
                        }}</mat-radio-button>
                    </mat-radio-group>

                    <div
                        class="row"
                        *ngIf="
                            claimTypeDetailsValidator != null &&
                            claimTypeDetailsValidator != ''
                        "
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red; text-decoration: underline">
                                <strong>{{ claimTypeDetailsValidator }}</strong>
                            </p>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <br />
    <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
        <p style="text-decoration: underline; color: #226439">
            <strong> {{ "LogIn.documentsDeclare" | translate }}</strong>
        </p>
    </div>
    <br />
    <mat-card style="box-shadow: 2px 3px 10px 4px #dee2e6">
        <mat-card-content>
            <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-8">
                    <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
                        <div class="col-md-8">
                            <div class="form-outline">
                                <input
                                    type="file"
                                    id="contract"
                                    required
                                    #myInput
                                    (change)="setDocsList($event, 'contract')"
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                />

                                <label
                                    for="contract"
                                    class="custom-file-input upload-lbl"
                                >
                                    <i
                                        class="fa fa-upload"
                                        aria-hidden="true"
                                        style="color: #d4ab36"
                                    ></i>
                                    {{ "LogIn.ContractAttachment" | translate
                                    }}<span style="color: red">*</span>
                                </label>
                            </div>
                        </div>
                        <div
                            class="col-md-4 text-center"
                            dir="{{ 'LogIn.Dir' | translate }}"
                        >
                            <div class="form-outline">
                                <div
                                    class="row"
                                    *ngIf="ImgsId.includes('contract')"
                                >
                                    <ng-container
                                        *ngFor="
                                            let img of nationalImgsList;
                                            let x = index
                                        "
                                    >
                                        <div
                                            class="col-md-12 imageContainer"
                                            *ngIf="img.id == 'contract'"
                                        >
                                            <div class="col-md-12">
                                                <div
                                                    class="item-container"
                                                    (click)="
                                                        DisplayDocument(img)
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            overflow: clip;
                                                            margin-right: 5%;
                                                        "
                                                    >
                                                        {{ img.name }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="delete"
                                                            (click)="
                                                                deleteAtt(x)
                                                            "
                                                            *ngIf="img.id == 1"
                                                        >
                                                            <fa-icon
                                                                [icon]="faXmark"
                                                            ></fa-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
                        <div class="col-md-8">
                            <div class="form-outline">
                                <input
                                    type="file"
                                    id="passport"
                                    required
                                    #myInput
                                    (change)="setDocsList($event, 'passport')"
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                />

                                <label
                                    for="passport"
                                    class="custom-file-input upload-lbl"
                                >
                                    <i
                                        class="fa fa-upload"
                                        aria-hidden="true"
                                        style="color: #d4ab36"
                                    ></i>
                                    {{ "LogIn.PassportAttachment" | translate
                                    }}<span style="color: red">*</span>
                                </label>
                            </div>
                        </div>
                        <div
                            class="col-md-4 text-center"
                            dir="{{ 'LogIn.Dir' | translate }}"
                        >
                            <div class="form-outline">
                                <div
                                    class="row"
                                    *ngIf="ImgsId.includes('passport')"
                                >
                                    <ng-container
                                        *ngFor="
                                            let img of nationalImgsList;
                                            let x = index
                                        "
                                    >
                                        <div
                                            class="col-md-12 imageContainer"
                                            *ngIf="img.id == 'passport'"
                                        >
                                            <div class="col-md-12">
                                                <div
                                                    class="item-container"
                                                    (click)="
                                                        DisplayDocument(img)
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            overflow: clip;
                                                            margin-right: 5%;
                                                        "
                                                    >
                                                        {{ img.name }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="delete"
                                                            (click)="
                                                                deleteAtt(x)
                                                            "
                                                            *ngIf="img.id == 1"
                                                        >
                                                            <fa-icon
                                                                [icon]="faXmark"
                                                            ></fa-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
                        <div class="col-md-8">
                            <div class="form-outline">
                                <input
                                    type="file"
                                    id="iban"
                                    required
                                    #myInput
                                    (change)="setDocsList($event, 'iban')"
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                />

                                <label
                                    for="iban"
                                    class="custom-file-input upload-lbl"
                                >
                                    <i
                                        class="fa fa-upload"
                                        aria-hidden="true"
                                        style="color: #d4ab36"
                                    ></i>
                                    {{ "LogIn.IBANAttachment" | translate
                                    }}<span style="color: red">*</span>
                                </label>
                            </div>
                        </div>
                        <div
                            class="col-md-4 text-center"
                            dir="{{ 'LogIn.Dir' | translate }}"
                        >
                            <div class="form-outline">
                                <div
                                    class="row"
                                    *ngIf="ImgsId.includes('iban')"
                                >
                                    <ng-container
                                        *ngFor="
                                            let img of nationalImgsList;
                                            let x = index
                                        "
                                    >
                                        <div
                                            class="col-md-12 imageContainer"
                                            *ngIf="img.id == 'iban'"
                                        >
                                            <div class="col-md-12">
                                                <div
                                                    class="item-container"
                                                    (click)="
                                                        DisplayDocument(img)
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            overflow: clip;
                                                            margin-right: 5%;
                                                        "
                                                    >
                                                        {{ img.name }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="delete"
                                                            (click)="
                                                                deleteAtt(x)
                                                            "
                                                            *ngIf="img.id == 1"
                                                        >
                                                            <fa-icon
                                                                [icon]="faXmark"
                                                            ></fa-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br *ngIf="documents == 1" />
                    <div
                        class="row"
                        dir="{{ 'LogIn.Dir' | translate }}"
                        *ngIf="documents == 1"
                    >
                        <div class="col-md-8">
                            <div class="form-outline">
                                <input
                                    type="file"
                                    id="hospitalreport"
                                    #myInput
                                    (change)="
                                        setDocsList($event, 'hospitalreport')
                                    "
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                />

                                <label
                                    for="hospitalreport"
                                    class="custom-file-input upload-lbl"
                                >
                                    <i
                                        class="fa fa-upload"
                                        aria-hidden="true"
                                        style="color: #d4ab36"
                                    ></i>
                                    {{
                                        "LogIn.HospitalReportAttachment"
                                            | translate
                                    }}<span style="color: red">*</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="form-outline">
                                <div
                                    class="row"
                                    *ngIf="ImgsId.includes('hospitalreport')"
                                >
                                    <ng-container
                                        *ngFor="
                                            let img of nationalImgsList;
                                            let x = index
                                        "
                                    >
                                        <div
                                            class="col-md-12 imageContainer"
                                            *ngIf="img.id == 'hospitalreport'"
                                        >
                                            <div class="col-md-12">
                                                <div
                                                    class="item-container"
                                                    (click)="
                                                        DisplayDocument(img)
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            overflow: clip;
                                                            margin-right: 5%;
                                                        "
                                                    >
                                                        {{ img.name }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="delete"
                                                            (click)="
                                                                deleteAtt(x)
                                                            "
                                                            *ngIf="img.id == 1"
                                                        >
                                                            <fa-icon
                                                                [icon]="faXmark"
                                                            ></fa-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br *ngIf="documents == 2" />
                    <div
                        class="row"
                        dir="{{ 'LogIn.Dir' | translate }}"
                        *ngIf="documents == 2"
                    >
                        <div class="col-md-8">
                            <div class="form-outline">
                                <input
                                    type="file"
                                    id="WorkerDeath"
                                    #myInput
                                    (change)="
                                        setDocsList($event, 'WorkerDeath')
                                    "
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                />

                                <label
                                    for="WorkerDeath"
                                    class="custom-file-input upload-lbl"
                                >
                                    <i
                                        class="fa fa-upload"
                                        aria-hidden="true"
                                        style="color: #d4ab36"
                                    ></i>
                                    {{ "LogIn.WorkerDeath" | translate
                                    }}<span style="color: red">*</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-outline">
                                <div
                                    class="row"
                                    *ngIf="ImgsId.includes('WorkerDeath')"
                                >
                                    <ng-container
                                        *ngFor="
                                            let img of nationalImgsList;
                                            let x = index
                                        "
                                    >
                                        <div
                                            class="col-md-12 imageContainer"
                                            *ngIf="img.id == 'WorkerDeath'"
                                        >
                                            <div class="col-md-12">
                                                <div
                                                    class="item-container"
                                                    (click)="
                                                        DisplayDocument(img)
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            overflow: clip;
                                                            margin-right: 5%;
                                                        "
                                                    >
                                                        {{ img.name }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="delete"
                                                            (click)="
                                                                deleteAtt(x)
                                                            "
                                                            *ngIf="img.id == 1"
                                                        >
                                                            <fa-icon
                                                                [icon]="faXmark"
                                                            ></fa-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br *ngIf="documents == 3" />
                    <div
                        class="row"
                        dir="{{ 'LogIn.Dir' | translate }}"
                        *ngIf="documents == 3"
                    >
                        <div class="col-md-8">
                            <div class="form-outline">
                                <input
                                    type="file"
                                    id="PolicyAbsenceReport"
                                    #myInput
                                    (change)="
                                        setDocsList(
                                            $event,
                                            'PolicyAbsenceReport'
                                        )
                                    "
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                />

                                <label
                                    for="PolicyAbsenceReport"
                                    class="custom-file-input upload-lbl"
                                >
                                    <i
                                        class="fa fa-upload"
                                        aria-hidden="true"
                                        style="color: #d4ab36"
                                    ></i>
                                    {{ "LogIn.PoliceAbsenceReport" | translate
                                    }}<span style="color: red">*</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="form-outline">
                                <div
                                    class="row"
                                    *ngIf="
                                        ImgsId.includes('PolicyAbsenceReport')
                                    "
                                >
                                    <ng-container
                                        *ngFor="
                                            let img of nationalImgsList;
                                            let x = index
                                        "
                                    >
                                        <div
                                            class="col-md-12 imageContainer"
                                            *ngIf="
                                                img.id == 'PolicyAbsenceReport'
                                            "
                                        >
                                            <div class="col-md-12">
                                                <div
                                                    class="item-container"
                                                    (click)="
                                                        DisplayDocument(img)
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            overflow: clip;
                                                            margin-right: 5%;
                                                        "
                                                    >
                                                        {{ img.name }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="delete"
                                                            (click)="
                                                                deleteAtt(x)
                                                            "
                                                            *ngIf="img.id == 1"
                                                        >
                                                            <fa-icon
                                                                [icon]="faXmark"
                                                            ></fa-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br *ngIf="documents == 4" />
                    <div
                        class="row"
                        dir="{{ 'LogIn.Dir' | translate }}"
                        *ngIf="documents == 4"
                    >
                        <div class="col-md-8">
                            <div class="form-outline">
                                <input
                                    type="file"
                                    id="proofOfEmergency"
                                    #myInput
                                    (change)="
                                        setDocsList($event, 'proofOfEmergency')
                                    "
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                />

                                <label
                                    for="proofOfEmergency"
                                    class="custom-file-input upload-lbl"
                                >
                                    <i
                                        class="fa fa-upload"
                                        aria-hidden="true"
                                        style="color: #d4ab36"
                                    ></i>
                                    {{ "LogIn.ProofOfEmergencyCase" | translate
                                    }}<span style="color: red">*</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="form-outline">
                                <div
                                    class="row"
                                    *ngIf="ImgsId.includes('proofOfEmergency')"
                                >
                                    <ng-container
                                        *ngFor="
                                            let img of nationalImgsList;
                                            let x = index
                                        "
                                    >
                                        <div
                                            class="col-md-12 imageContainer"
                                            *ngIf="img.id == 'proofOfEmergency'"
                                        >
                                            <div class="col-md-12">
                                                <div
                                                    class="item-container"
                                                    (click)="
                                                        DisplayDocument(img)
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            overflow: clip;
                                                            margin-right: 5%;
                                                        "
                                                    >
                                                        {{ img.name }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="delete"
                                                            (click)="
                                                                deleteAtt(x)
                                                            "
                                                            *ngIf="img.id == 1"
                                                        >
                                                            <fa-icon
                                                                [icon]="faXmark"
                                                            ></fa-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br *ngIf="documents == 5" />
                    <div
                        class="row"
                        dir="{{ 'LogIn.Dir' | translate }}"
                        *ngIf="documents == 5"
                    >
                        <div class="col-md-8">
                            <div class="form-outline">
                                <input
                                    type="file"
                                    id="stopwork"
                                    #myInput
                                    (change)="setDocsList($event, 'stopwork')"
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                />

                                <label
                                    for="stopwork"
                                    class="custom-file-input upload-lbl"
                                >
                                    <i
                                        class="fa fa-upload"
                                        aria-hidden="true"
                                        style="color: #d4ab36"
                                    ></i>
                                    {{ "LogIn.StopWork" | translate
                                    }}<span style="color: red">*</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="form-outline">
                                <div
                                    class="row"
                                    *ngIf="ImgsId.includes('stopwork')"
                                >
                                    <ng-container
                                        *ngFor="
                                            let img of nationalImgsList;
                                            let x = index
                                        "
                                    >
                                        <div
                                            class="col-md-12 imageContainer"
                                            *ngIf="img.id == 'stopwork'"
                                        >
                                            <div class="col-md-12">
                                                <div
                                                    class="item-container"
                                                    (click)="
                                                        DisplayDocument(img)
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            overflow: clip;
                                                            margin-right: 5%;
                                                        "
                                                    >
                                                        {{ img.name }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="delete"
                                                            (click)="
                                                                deleteAtt(x)
                                                            "
                                                            *ngIf="img.id == 1"
                                                        >
                                                            <fa-icon
                                                                [icon]="faXmark"
                                                            ></fa-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="row"
                        dir="{{ 'LogIn.Dir' | translate }}"
                        *ngIf="documents == 5"
                    >
                        <div class="col-md-8">
                            <div class="form-outline">
                                <input
                                    type="file"
                                    id="PassportOfficePrint"
                                    #myInput
                                    (change)="
                                        setDocsList(
                                            $event,
                                            'PassportOfficePrint'
                                        )
                                    "
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                />

                                <label
                                    for="PassportOfficePrint"
                                    class="custom-file-input upload-lbl"
                                >
                                    <i
                                        class="fa fa-upload"
                                        aria-hidden="true"
                                        style="color: #d4ab36"
                                    ></i>
                                    {{ "LogIn.PassportAgencyPrint" | translate
                                    }}<span style="color: red">*</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="form-outline">
                                <div
                                    class="row"
                                    *ngIf="
                                        ImgsId.includes('PassportOfficePrint')
                                    "
                                >
                                    <ng-container
                                        *ngFor="
                                            let img of nationalImgsList;
                                            let x = index
                                        "
                                    >
                                        <div
                                            class="col-md-12 imageContainer"
                                            *ngIf="
                                                img.id == 'PassportOfficePrint'
                                            "
                                        >
                                            <div class="col-md-12">
                                                <div
                                                    class="item-container"
                                                    (click)="
                                                        DisplayDocument(img)
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            overflow: clip;
                                                            margin-right: 5%;
                                                        "
                                                    >
                                                        {{ img.name }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="delete"
                                                            (click)="
                                                                deleteAtt(x)
                                                            "
                                                            *ngIf="img.id == 1"
                                                        >
                                                            <fa-icon
                                                                [icon]="faXmark"
                                                            ></fa-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="
                            attachmentsCheck != null && attachmentsCheck != ''
                        "
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong>{{ attachmentsCheck }}</strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="contractFileValidator"
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong
                                    >{{
                                        "LogIn.ContractAttachment" | translate
                                    }}
                                    {{ "LogIn.IsRequired" | translate }}
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="passportFileValidator"
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong
                                    >{{
                                        "LogIn.PassportAttachment" | translate
                                    }}
                                    {{ "LogIn.IsRequired" | translate }}
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="ibanFileValidator"
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong
                                    >{{ "LogIn.IBANAttachment" | translate }}
                                    {{ "LogIn.IsRequired" | translate }}
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="hospitalReportValidator"
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong
                                    >{{
                                        "LogIn.HospitalReportAttachment"
                                            | translate
                                    }}
                                    {{ "LogIn.IsRequired" | translate }}
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="workerDeathValidator"
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong
                                    >{{ "LogIn.WorkerDeath" | translate }}
                                    {{ "LogIn.IsRequired" | translate }}
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="policyAbsenceValidator"
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong
                                    >{{
                                        "LogIn.PoliceAbsenceReport" | translate
                                    }}
                                    {{ "LogIn.IsRequired" | translate }}
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="proofOfEmergencyCaseValidator"
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong
                                    >{{
                                        "LogIn.ProofOfEmergencyCase" | translate
                                    }}
                                    {{ "LogIn.IsRequired" | translate }}
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="stopWorkValidator"
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong
                                    >{{ "LogIn.StopWork" | translate }}
                                    {{ "LogIn.IsRequired" | translate }}
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div
                        class="row"
                        *ngIf="passportOfficePrintValidator"
                        dir="{{ 'LogIn.Dir' | translate }}"
                    >
                        <div class="col-md-12">
                            <p style="color: red">
                                <i
                                    class="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;
                                <strong
                                    >{{
                                        "LogIn.PassportAgencyPrint" | translate
                                    }}
                                    {{ "LogIn.IsRequired" | translate }}
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-center">
                    <h5
                        style="
                            color: #000;
                            text-decoration: underline;
                            font-size: 16px;
                        "
                    >
                        {{ "FOOTER.IMPORTANT_Linkes" | translate }}*:
                    </h5>
                    <div class="d-flex flex-column" style="font-size: 12px">
                        <a
                            [href]="
                                'https://www.moi.gov.sa/wps/wcm/connect/ea26fa8d-400f-4b9b-ac52-f3c730f5e44d/%D8%A5%D8%AC%D8%B1%D8%A7%D8%A1%D8%A7%D8%AA+%D8%AA%D9%82%D8%AF%D9%8A%D9%85+%D8%A8%D9%84%D8%A7%D8%BA+%D8%AA%D8%BA%D9%8A%D8%A8+%D8%B9%D9%86+%D8%A7%D9%84%D8%B9%D9%85%D9%84+%D9%84%D9%84%D8%B9%D9%85%D8%A7%D9%84%D8%A9+%D8%A7%D9%84%D9%85%D9%86%D8%B2%D9%84%D9%8A%D8%A9.pdf?MOD=AJPERES&CVID=ohh1MQ2'
                            "
                            target="_blank"
                            style="
                                color: #d4ab36;
                                text-decoration: underline;
                                cursor: pointer;
                            "
                        >
                            <i
                                class="fa fa-exclamation-circle"
                                aria-hidden="true"
                                style="margin-left: 3px; margin-right: 3px"
                            ></i
                            >إجراءات تقديم بالغ تغيب عن العمل للعمالة المنزلية
                        </a>
                        <a
                            [href]="
                                'assets/files/domestic-files/Attached Video for the steps to follow in absher..mp4'
                            "
                            target="_blank"
                            style="
                                color: #d4ab36;
                                text-decoration: underline;
                                cursor: pointer;
                            "
                        >
                            <i
                                class="fa fa-exclamation-circle"
                                aria-hidden="true"
                                style="margin-left: 3px; margin-right: 3px"
                            ></i
                            >Attached Video for the steps to follow in absher
                        </a>
                        <a
                            [href]="
                                'https://www.mol.gov.sa/securessl/login.aspx'
                            "
                            target="_blank"
                            style="
                                color: #d4ab36;
                                text-decoration: underline;
                                cursor: pointer;
                            "
                        >
                            <i
                                class="fa fa-exclamation-circle"
                                aria-hidden="true"
                                style="margin-left: 3px; margin-right: 3px"
                            ></i
                            >Attached the Steps to register a domestic worker
                            lawsuit إدارة الخدمات الالكترونية لوزارة الموارد
                            البشرية والتنمية الاجتماعية
                        </a>
                        <a
                            [href]="
                                'assets/files/domestic-files/Steps to register a domestic worker lawsuit.pdf'
                            "
                            target="_blank"
                            style="
                                color: #d4ab36;
                                text-decoration: underline;
                                cursor: pointer;
                            "
                        >
                            <i
                                class="fa fa-exclamation-circle"
                                aria-hidden="true"
                                style="margin-left: 3px; margin-right: 3px"
                            ></i
                            >Steps to register a domestic worker lawsuit
                            (attachment)
                        </a>
                        <a
                            [href]="
                                'assets/files/domestic-files/تقرير أبشر - عمالة.pdf'
                            "
                            target="_blank"
                            style="
                                color: #d4ab36;
                                text-decoration: underline;
                                cursor: pointer;
                            "
                        >
                            <i
                                class="fa fa-exclamation-circle"
                                aria-hidden="true"
                                style="margin-left: 3px; margin-right: 3px"
                            ></i
                            >تقرير أبشر
                        </a>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <br />
    <mat-card style="box-shadow: 2px 3px 10px 4px #dee2e6">
        <mat-card-content>
            <div class="row" dir="{{ 'LogIn.Dir' | translate }}">
                <p>
                    {{ "LogIn.DlClaimDecleration" | translate }}
                    <span style="color: red">*</span>
                    <mat-checkbox
                        formControlName="declare"
                        required
                        value="1"
                        style="color: black !important"
                    ></mat-checkbox>
                </p>
                <div
                    *ngIf="declarevalidator != null && declarevalidator != ''"
                    style="color: red"
                >
                    <strong>
                        <i
                            class="fa fa-exclamation-circle"
                            aria-hidden="true"
                        ></i>
                        {{ declarevalidator }}
                    </strong>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div mat-dialog-actions class="row">
        <div class="col-md-6">
            <button
                type="button"
                mat-dialog-close
                class="btn btn-rounded"
                style="background-color: darkred; color: white"
            >
                {{ "LogIn.Cancel" | translate }}
            </button>
        </div>
        <div class="col-md-6" style="float: right">
            <button
                type="button"
                class="btn btn-rounded"
                style="
                    background-color: #226439;
                    color: white;
                    float: inline-end;
                " (click)="SendOTPAndOpenVerificationDialog()">
                {{ "GENERIC.SUBMIT" | translate }}
            </button>
        </div>
    </div>
</form>
