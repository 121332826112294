import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-upload-members-result-dialog',
  templateUrl: './upload-members-result-dialog.component.html',
  styleUrls: ['./upload-members-result-dialog.component.scss']
})
export class UploadMembersResultDialogComponent implements OnInit {

  successMembers =[];
  failedMembers = [];
  displayedColumns: string[] = ['IqamaId', 'Errors'];
  dataSource: MatTableDataSource<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.successMembers = data.successMembers;
    this.failedMembers = data.failedMembers;

    this.dataSource = new MatTableDataSource(this.failedMembers);
   }

  ngOnInit(): void {
  }

}
