import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatInput } from '@angular/material/input';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
@Component({
    selector: 'app-medical-cash-claims',
    templateUrl: './medical-cash-claims.component.html',
    styleUrls: ['./medical-cash-claims.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class MedicalCashClaimsComponent implements OnInit, AfterViewInit {
    today: Date;
    minDate: Date;
    languageValue = localStorage.getItem('language');
    selectedMember: any;
    memID = localStorage.getItem('memberID');
    fromDate: any;
    memberDetails: any;
    ToDate: any;
    insuredMember: any;
    Claims: any;
    ClaimsDetails: any;
    resertoggle: any;
    isLoading = true;
    pageInit: boolean = true;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('fromInput', {
        read: MatInput,
    })
    fromInput: MatInput;

    @ViewChild('toInput', {
        read: MatInput,
    })
    toInput: MatInput;

    dataSource = new MatTableDataSource();

    constructor(
        public translate: TranslateService,
        public medicalClaimsService: MedicalClaimsService,
        private _LocalService: LocalService
    ) {
        this.memID = this._LocalService.getJsonValue('memberID');

        this.dataSource = new MatTableDataSource(this.Claims);
        this.languageValue = localStorage.getItem('language');
        this.fromDate = null;
        this.ToDate = null;
        this.insuredMember = null;
        this.minDate = null;
        this.memberDetails = [];
        this.selectedMember = {
            id: '',
            policyNumber: '',
            policyHolder: '',
            PolicyStartData: '',
            policyExpiryData: '',
            memberCode: '',
            memberEName: '',
            gender: '',
            birthDate: '',
            className: '',
            relation: '',
        };
        this.resertoggle = false;
        this.pageInit = true;
    }
    columnsToDisplay = [
        'button',
        'referenceNum',
        'serviceProvider',
        'claimDate',
        'memberName',
    ];
    ngOnInit(): void {
        this.today = new Date();
        this.GetMemeberDetails();
    }
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }
    GetMemeberDetails() {
        this.memberDetails = this._LocalService.getJsonValue('memberDetails');
        if (this.memberDetails !== null) {
            if (this.memberDetails?.length > 0) {
                this.selectedMember = this.memberDetails[0];
            }
        } else {
            let queryString = 'MemberId=' + this.memID;
            this.medicalClaimsService
                .MemberDetails(queryString)
                .subscribe((res) => {
                    this.memberDetails = res;
                    this._LocalService.setJsonValue('memberDetails', res?.data);
                    if (res?.data.length > 0) {
                        this.selectedMember = res?.data[0];
                        this.memberDetails = res?.data;
                    }
                });
        }
        this.isLoading = false;
    }
    toggleRow(element: { expanded: boolean }) {
        this.ClaimsDetails = null;

        if (element.expanded === false) {
            this.GetClaimsDetials(element['referenceNum']);
            // Uncommnet to open only single row at once
            this.Claims.forEach((row) => {
                row.expanded = false;
            });
        }
        element.expanded = !element.expanded;
    }

    GetCliams() {
        let queryString = 'policyNumber=' + this.selectedMember.policyNumber;
        this.medicalClaimsService
            .CliamRequests(queryString)
            .subscribe((res) => {
                this.Claims = res;

                for (let index = 0; index < this.Claims?.length; index++) {
                    this.Claims[index].expanded = false;
                }
                this.dataSource = new MatTableDataSource<medClaim>(
                    this?.Claims
                );
                setTimeout(() => (this.dataSource.paginator = this.paginator));

                this.dataSource.paginator = this.paginator;
                this.isLoading = false;
            });
    }
    GetClaimsDetials(id) {
        let queryString = '&lang=' + this.languageValue + '&refNumber=' + id;
        this.medicalClaimsService
            .CliamRequestsDetails(queryString)
            .subscribe((res) => {
                this.ClaimsDetails = res;
            });
    }

    newMedicalCliam() {
        window.location.href = '/new-medical-claim';
    }

    addFromDate(type: string, event) {
        this.toInput.value = '';
        this.minDate = event.value;
        this.fromDate = this.format(event.value, 1);
    }
    addToDate(type: string, event) {
        this.ToDate = this.format(event.value, 1);
    }
    format(inputDate, mnth) {
        let date, month, year;

        date = inputDate.getDate();
        month = inputDate.getMonth() + mnth;
        year = inputDate.getFullYear();
        if (month === 13) {
            month = 1;
        }
        date = date.toString().padStart(2, '0');

        month = month.toString().padStart(2, '0');

        return `${month}-${date}-${year}`;
    }

    filterByName(event) {
        this.insuredMember = event;
    }
    getMemberData() {
        this.isLoading = true;

        let queryString = 'policyNumber=' + this.selectedMember.policyNumber;
        if (this.fromDate && this.ToDate) {
            let newTo = this.ToDate + ' 23:59:59';
            queryString += '&From=' + this.fromDate + '&To=' + newTo;
        }

        if (this.insuredMember) {
            queryString += '&MemberId=' + this.insuredMember.id;
        }
        this.medicalClaimsService
            .CliamRequests(queryString)
            .subscribe((res) => {
                this.Claims = res;

                for (let index = 0; index < this.Claims?.length; index++) {
                    this.Claims[index].expanded = false;
                }
                this.dataSource = new MatTableDataSource<medClaim>(
                    this?.Claims
                );
                setTimeout(() => (this.dataSource.paginator = this.paginator));

                this.dataSource.paginator = this.paginator;
                this.isLoading = false;
            });
        this.resertoggle = true;
        this.pageInit = false;

    }
    reset() {
        this.resertoggle = false;
        this.fromInput.value = '';
        this.toInput.value = '';
        this.insuredMember = null;
        this.pageInit = true;
        this.Claims=null;
        this.today = new Date();
       
    }
}

export interface medClaim {
    referenceNum: string;
    claimDate: string;
    memberName: string;
    serviceProvider: string;
    serviceType: string;
}
