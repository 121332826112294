<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'SERVICES.CLAIMS' | translate}}</h2>
            <ul>
                <li><a routerLink="/">{{'NAV.HOME' | translate}} <span style="margin: 0 10px;"> / </span></a></li>
                <li><a routerLink="/">{{'NAV.ESERVICES' | translate}} <span style="margin: 0 10px;"> / </span></a></li>
                <li class="active">{{'NAV.SERVICES' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="container pt-5 pd-5 col-md-10">
    <div class="claims-header pull-left">
        <span class="ml-4">{{'MOTORCLAIMS.WELCOME' | translate}}</span>
        <span><i class="hand">👋</i>Mahmoud Salah</span>
    </div>
    <div class="pull-right">
        <button class="btn btn-success"> <i class="fa fa-plus" aria-hidden="true"></i>
            {{'SERVICES.BUYONLINE' | translate}}</button>
    </div>
</div>
<div class="container pt-5 pd-5 col-md-10">
    <div class="col-md-12">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="col-md-2"></div>
                <div class="table-content col-md-11" style="overflow-x:auto;">
                    <table class="table table-condensed table-striped col-md-8">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{{'MOTORCLAIMS.POLICYNUMBER' | translate}}</th>
                                <th>{{'MOTORCLAIMS.CARTYPE' | translate}}</th>
                                <th>{{'MOTORCLAIMS.STARTDATE' | translate}}</th>
                                <th>{{'MOTORCLAIMS.ENDDATE' | translate}}</th>
                                <th>{{'MOTORCLAIMS.STATE' | translate}}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr data-bs-toggle="collapse" data-bs-target="#demo1" class="accordion-toggle">
                                <td><button class="btn btn-default btn-xs" style="color: #D4AB36;"><span
                                            class="fa fa-angle-right" aria-hidden="true"></span></button></td>
                                <td>85521582285878</td>
                                <td>Mathias</td>
                                <td>18 November 2022</td>
                                <td>18 November2024</td>
                                <td class="status-column">
                                    <div class="circle-green"></div> Active
                                </td>
                            </tr>

                            <tr>
                                <td colspan="6" class="hiddenRow">
                                    <div id="demo1" class="accordian-body collapse">
                                        <div class="col-md-12">
                                            <div class="collapse-body row">
                                                <div class="collapse-title col-md-2">
                                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                    {{'MOTORCLAIMS.MOTORINFO' | translate}}
                                                </div>

                                                <div class="motor-info row col-md-7">
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Type:</span> BMW
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Year:</span> 2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Plate Number:</span> 3 5 8 K H
                                                        R
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Motor:</span> 2363505
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Model:</span> X6
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Color:</span> Black
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="collapse-body row">
                                                <hr class="col-md-9" />
                                            </div>

                                            <div class="collapse-body row">
                                                <div class="collapse-title col-md-2">
                                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                    {{'MOTORCLAIMS.POLICYINFO' | translate}}
                                                </div>

                                                <div class="motor-info row col-md-7">
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Policy Value:</span> 111111
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Start Date:</span> 19 November
                                                        2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">End Date:</span> 19 November
                                                        2024
                                                    </div>
                                                </div>

                                                <div class="col-md-1"></div>
                                                <div class="col-md-2">
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.ENDORSMENT' | translate}}">
                                                         <mat-icon>brush</mat-icon>
                                                    </button>
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.ACCIDENT' | translate}}">
                                                        <mat-icon> directions_car</mat-icon>
                                                    </button>
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.PRINTING' | translate}}">
                                                        <mat-icon> local_printshop</mat-icon>
                                                    </button>
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.EDIT' | translate}}">
                                                        <mat-icon>settings</mat-icon>
                                                    </button>
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.CANCELATION' | translate}}">
                                                        <mat-icon>cancel</mat-icon>
                                                    </button>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </td>
                            </tr>



                            <tr data-bs-toggle="collapse" data-bs-target="#demo2" class="accordion-toggle">
                                <td><button class="btn btn-default btn-xs" style="color: #D4AB36;"><span
                                            class="fa fa-angle-right" aria-hidden="true"></span></button></td>
                                <td>85521582285878</td>
                                <td>Mathias</td>
                                <td>18 November 2022</td>
                                <td>18 November2024</td>
                                <td class="status-column">
                                    <div class="circle-green"></div> Active
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6" class="hiddenRow">
                                    <div id="demo2" class="accordian-body collapse">
                                        <div class="col-md-12">
                                            <div class="collapse-body row">
                                                <div class="collapse-title col-md-2">
                                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                    {{'MOTORCLAIMS.MOTORINFO' | translate}}
                                                </div>

                                                <div class="motor-info row col-md-7">
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Type:</span> BMW
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Year:</span> 2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Plate Number:</span> 3 5 8 K H
                                                        R
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Motor:</span> 2363505
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Model:</span> X6
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Color:</span> Black
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="collapse-body row">
                                                <hr class="col-md-9" />
                                            </div>

                                            <div class="collapse-body row">
                                                <div class="collapse-title col-md-2">
                                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                    {{'MOTORCLAIMS.POLICYINFO' | translate}}
                                                </div>

                                                <div class="motor-info row col-md-7">
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Policy Value:</span> 111111
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">Start Date:</span> 19 November
                                                        2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="collapse-text-title">End Date:</span> 19 November
                                                        2024
                                                    </div>
                                                </div>

                                                <div class="col-md-1"></div>
                                                <div class="col-md-2">
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.ENDORSMENT' | translate}}">
                                                         <mat-icon>brush</mat-icon>
                                                    </button>
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.ACCIDENT' | translate}}">
                                                        <mat-icon> directions_car</mat-icon>
                                                    </button>
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.PRINTING' | translate}}">
                                                        <mat-icon> local_printshop</mat-icon>
                                                    </button>
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.EDIT' | translate}}">
                                                        <mat-icon>settings</mat-icon>
                                                    </button>
                                                    <button class="icon-btn" mat-raised-button matTooltip="{{'MOTORCLAIMS.CANCELATION' | translate}}">
                                                        <mat-icon>cancel</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    </div>
</div>