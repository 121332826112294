import {
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MatDialog } from '@angular/material/dialog';
import { MotorClaimsService } from 'src/app/core/services/motor-claims.service';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';
@Component({
    selector: 'app-motor-details-login',
    templateUrl: './motor-details-login.component.html',
    styleUrls: ['./motor-details-login.component.scss'],
})
export class MotorDetailsLoginComponent implements OnInit {
    otpStep: boolean = false;
    formInput = ['input1', 'input2', 'input3', 'input4'];
    form: any;
    claimsDetails = [];
    claimDetails: any;
    HasClaims: string = '';
    invalidOTP: boolean;
    isLoading: boolean;
    otpExpire: boolean = false;
    mobilePattern = '^(05)([0-9]{8})$';
    idPattern = '^([1,2,4,7]{1})([0-9]{9})$';
    MobileNumber: string = '';
    driverId: string = '';
    timerr: any;
    display: any;
    isLoading2: boolean;
    policyInfo: PolicyDetails[] = [];
    LoginForm: FormGroup;
    isSubmitted = false;

    @ViewChildren('formRow') rows: any;
    @ViewChild('swiper') swiper: ElementRef;
    constructor(
        public dialog: MatDialog,
        private _formbuilder: FormBuilder,
        private _router: Router,
        public motorClaimsService: MotorClaimsService,
        public translate: TranslateService,
        private _LocalService: LocalService
    ) {
        this.form = this.toFormGroup(this.formInput);
        this.LoginForm = this._formbuilder.group({
            MobileNumber: [
                '',
                [Validators.required, Validators.pattern(this.mobilePattern)],
            ],
            OTP: [null],
            driverId: [
                '',
                [Validators.required, Validators.pattern(this.idPattern)],
            ],
        });
    }
    ngOnInit(): void {}

    toFormGroup(elements: any) {
        const group: any = {};
        elements.forEach((key: any) => {
            group[key] = new FormControl('', Validators.required);
        });
        return new FormGroup(group);
    }
    timer(minute: any) {
        // let minute = 1;
        let seconds: number = minute * 60;
        let textSec: any = '0';
        let statSec: number = 60;

        const prefix = minute < 10 ? '0' : '';

        this.timerr = setInterval(() => {
            seconds--;
            if (statSec != 0) statSec--;
            else statSec = 59;

            if (statSec < 10) {
                textSec = '0' + statSec;
            } else textSec = statSec;

            this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
            if (this.otpExpire === false)
                if (seconds == 0) {
                    //console.log("finished");
                    clearInterval(this.timerr);
                    this.otpExpire = true;
                }
        }, 1000);
    }
    keyUpEvent(event: any, index: any) {
        if (
            ((event.keyCode >= 48 && event.keyCode < 58) ||
                (event.keyCode >= 96 && event.keyCode < 110) ||
                event.keyCode === 8 ||
                event.keyCode == 13) &&
            this.otpStep
        ) {
            if (event.keyCode == 13 && this.otpStep) {
            } else {
                let x = this.form;
                let pos = index;
                if (event.keyCode === 8 && event.which === 8) {
                    pos = index - 1;
                } else {
                    pos = index + 1;
                }
                if (pos > -1 && pos < this.formInput.length) {
                    this.rows._results[pos].nativeElement.focus();
                }
            }
        }
    }

    ResendVerification() {
        this.LoginForm.controls['OTP'].setValue(Number('0000'));
        this.isLoading2 = true;
        this.otpExpire = false;
    }

    ValidateOTP() {
        let code =
            this.form.value.input1 +
            this.form.value.input2 +
            this.form.value.input3 +
            this.form.value.input4;

        this.LoginForm.controls['OTP'].setValue(Number(code));
        this.motorClaimsService
            .validateOTP(
                this.driverId,
                this.MobileNumber,
                this.LoginForm.controls['OTP'].value
            )
            .subscribe((res) => {
                if (res) {
                    localStorage.removeItem('policyInfo');
                    localStorage.setItem(
                        'policyInfo',
                        JSON.stringify(this.policyInfo)
                    );
                    this.HasClaims = '';

                    this._router.navigateByUrl('/motor-plicy-details', {
                        state: {
                            policyInfo: this.policyInfo,
                        },
                    });
                } else {
                    this.invalidOTP = true;
                }
            });
    }
    ValidateCredentials() {
        this.isSubmitted = true;

        //this.MobileNumber = this.LoginForm.controls['MobileNumber'].value;//'2284814056'
        this.driverId = this.LoginForm.controls['driverId'].value;
        this.motorClaimsService
            .getPolicyDetails(this.driverId)
            .subscribe((res) => {
                if (res != null) {
                    this.motorClaimsService
                        .getAuthenticate(
                            this.LoginForm.controls['driverId'].value,
                            res[0].mobile, 
                            LineOfBusiness.Motor, SMSActionType.Login
                        )
                        .subscribe((auth) => {
                            if (auth != null) {
                                this.otpStep = true;
                                this.policyInfo = res;
                                this.MobileNumber = this.policyInfo[0].mobile;
                                localStorage.removeItem('policyInfo');
                                localStorage.setItem(
                                    'policyInfo',
                                    JSON.stringify(this.policyInfo)
                                );
                                this.HasClaims = '';
                            } else {
                                this.HasClaims = 'Has no Policy Information';
                            }
                            this.isSubmitted = false;
                        });
                } else {
                    this.HasClaims = 'Has no Policy Information';
                }
            });
    }
}

interface PolicyDetails {
    policyNo: string;
    idNo: string;
    mobile: string;
    policyDate: string;
    plateNo: string;
    vehicleName: string;
    daysPassed: number;
    daysLeft: number;
    status: string;
    manefacturingYear: string;
    chassis: string;
}
