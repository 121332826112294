import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-health-declaration-dialog',
    templateUrl: './health-declaration-dialog.component.html',
    styleUrls: ['./health-declaration-dialog.component.scss'],
})
export class HealthDeclarationDialogComponent implements OnInit {
    questions = [];
    questionAnswerBind: any[] = [];

    lang = localStorage.getItem("language");
    memberDetails: any;
    questionsAnswers: any[] = [];
    order: number = 0;

    submitLoader = false

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private smeService: MedicalSMEService, public translate: TranslateService,
        private dialogRef: MatDialogRef<HealthDeclarationDialogComponent>) { }

    ngOnInit(): void {
        this.questions = this.data.questions;
        this.memberDetails = this.data.memberDetails
        if (this.memberDetails.isFillHd) {

            for (let index = 0; index < this.questions.length; index++) {
                let yesValue = false;
                let noValue = false;
                if (this.memberDetails.questionYesCode != null) {
                    if (this.memberDetails.questionYesCode.includes(this.questions[index].code))
                        yesValue = true;
                    else
                        noValue = true;


                }
                else
                    noValue = true;

                this.questionAnswerBind.push({
                    "description": this.questions[index].description,
                    "a_Description": this.questions[index].a_Description,
                    "code": this.questions[index].code,
                    "yesValue": yesValue,
                    "noValue": noValue
                });

                let item = {
                    questionCode: this.questions[index].code,
                    answer: yesValue == true ? true : false,
                    order: this.questions.find(c => c.code == this.questions[index].code).q_ORDER
                }
                this.questionsAnswers.push(item)

            }
        }
        else {
            for (let index = 0; index < this.questions.length; index++) {

                this.questionAnswerBind.push({
                    "description": this.questions[index].description,
                    "a_Description": this.questions[index].a_Description,
                    "code": this.questions[index].code,
                    "yesValue": false,
                    "noValue": false
                });
            }

        }



    }

    addRemoveQuestionAnswer(element: any, event: any) {
        this.order++;
        if (this.questionsAnswers != undefined && this.questionsAnswers != null) {
            if (this.questionsAnswers.some(e => e.questionCode === element.code)) {
                const item = this.questionsAnswers.find(x => x.questionCode === element.code);
                if (event.value == "false")
                    item.answer = false;
                else
                    item.answer = true;

            }
            else {
                let answerCheck: boolean;
                if (event.value == "false")
                    answerCheck = false;
                else
                    answerCheck = true;
                let item = {
                    questionCode: element.code,
                    answer: answerCheck,
                    order: this.questions.find(c => c.code == element.code).q_ORDER
                }
                this.questionsAnswers.push(item)
            }
        }
    }

    saveAnswers() {
        // call api and pass data.
        if (this.questionsAnswers.length < this.questionAnswerBind.length) {
            let dir: string = '';
            let ErrMsg = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
                ErrMsg = 'You should answer all health declaration questions !';
            } else {
                dir = 'rtl';
                ErrMsg = 'يجب الاجابة علي جميع اسئلة الافصاح الطبي.';
            }

            this.ErrDialog(ErrMsg);
        } else {
            this.submitLoader = true
            let model = {
                questionsAnswers: this.questionsAnswers,
                memberCode: this.memberDetails.memberCode.toString(),
                iqamaNumber: this.memberDetails.iqamaId.toString(),
                endt: 0,
                quotationNo: localStorage.getItem("quotationNumber"),
                MemberId: this.memberDetails.mainMemberID.toString()
            }

            this.smeService.fillhd(model).subscribe((res) => {
                //console.log(res);
                this.submitLoader = false

                if (res != null)
                    this.dialogRef.close({ data: res });
            })
        }
    }

    ErrDialog(msg) {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        Swal.fire({
            title: this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#FF001F; direction:" +
                dir +
                "'>" +
                msg +
                '</div>',
            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
                icon: 'submit-claim-failed-icon-img',
                title: 'submit-claim-failed-swal-title',
                confirmButton: 'submit-claim-failed-confirm-btn',
            },
        });
    }
}
