<div mat-dialog-title>
    <div class="d-flex justify-content-end">
        <!-- <span class="upload-dialog-header">{{ "SME.STEP1" | translate }}</span> -->
        <img
            src="assets/img/medical-sme/dialog-close.png"
            mat-dialog-close
            width="30px"
        />
    </div>
    <div class="d-flex">
        <span class="upload-dialog-second-header">
            {{ "SME.DOWNLOAD_MEMBERS_2ND_HEADER" | translate }}
        </span>
    </div>
</div>

<div mat-dialog-content>
    <div
        class="col-lg-12 col-md-12 col-sm-12 d-flex flex-column upload-dialog-content align-items-center justify-content-center"
        style="padding: 3% 5% 3% 5%"
    >
        <a
            class="excel-btn"
            mat-raised-button
            href="./assets/templates/members.xlsx"
            matTooltip="Download Template"
        >
            <img src="assets/img/medical-sme/download (1) 1.png" width="50px" />
        </a>
        <span class="download-btn"> {{ "SME.DOWNLOAD" | translate }} </span>
    </div>
</div>

<div mat-dialog-title class="pt-3">
    <div class="d-flex justify-content-between align-items-center">
        <!-- <span class="upload-dialog-header">{{ "SME.STEP2" | translate }}</span> -->
    </div>
    <div class="d-flex">
        <span class="upload-dialog-second-header">
            {{ "SME.UPLOAD_MEMBERS_2ND_HEADER" | translate }}
        </span>
    </div>
</div>

<div mat-dialog-content>
    <div
        class="col-lg-12 col-md-12 col-sm-12 d-flex flex-column upload-dialog-content align-items-center justify-content-center"
        style="padding: 3% 5% 5% 5%"
    >
        <a
            class="excel-btn"
            mat-raised-button
            matTooltip="Upload Members"
            style="cursor: pointer"
            (click)="fileId.click()"
        >
            <img src="assets/img/medical-sme/upload (1) 1.png" width="50px" />
        </a>
        <span
            class="download-btn"
            style="cursor: pointer"
            (click)="fileId.click()"
        >
            {{ "SME.UPLOAD" | translate }}
        </span>
        <input
            (change)="openFile($event.target)"
            hidden
            #fileId
            type="file"
            placeholder="Choose File"
            accept=".xlsx, .xls, .csv"
        />
    </div>
</div>

<div class="row" *ngIf="fileName != undefined && fileName != null">
    <span class="file-name">{{fileName}}</span>
</div>

<div *ngIf="progress > 0" class="row sme-health-save-container mb-2 mt-5">
    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    <div>
        <p>{{ progress }}%</p>
    </div>
</div>
<div style="color:red; font-size: 18px; font-weight: bold;display: flex; justify-content: center;"
[ngClass]="{'dir-ar': translate.currentLang === 'ar-SA', 'dir-en': translate.currentLang === 'en-US'}"
*ngIf="invalidFile">
    {{ "SME.INVALID_FILE" | translate }}
</div>
<div *ngIf="progress === 0">
    <div class="row sme-health-save-container mb-2 mt-5">
        <button
            class="sme-save-btn"
            (click)="CheckQuotationExistance()"
            [ngClass]="{'disabled-btn': fileName == undefined || fileName == null || fileName == '' || invalidFile}"
            [disabled]="isLoading || fileName == undefined || fileName == null || fileName == '' || invalidFile">
            <span *ngIf="progress === 0">{{ "SME.ADD" | translate }}</span>
        </button>
    </div>
</div>
