import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'app-medical-approvals',
    templateUrl: './medical-approvals.component.html',
    styleUrls: ['./medical-approvals.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class MedicalApprovalsComponent implements OnInit, AfterViewInit {
    today: Date;
    minDate: Date;
    medicalApprovals: any;
    medicalApprovalsDetails: any;
    languageValue = localStorage.getItem('language');
    memberDetails: any;
    selectedMember: any;
    memID = localStorage.getItem('memberID');
    fromDate: any;
    ToDate: any;
    insuredMember: any;
    deffrom:any;
    defTo:any;
    resertoggle:any;
    pageInit: boolean = true;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('fromInput', {
        read: MatInput,
    })
    fromInput: MatInput;

    @ViewChild('toInput', {
        read: MatInput,
    })
    toInput: MatInput;

    dataSource = new MatTableDataSource();
    isLoading = true;

    constructor(
        public translate: TranslateService,
        public medicalClaimsService: MedicalClaimsService,
        private _LocalService: LocalService
    ) {
        this.memID = this._LocalService.getJsonValue('memberID');

        this.dataSource = new MatTableDataSource(this.medicalApprovals);
        //this.GetMedicalApprovals();
        this.languageValue = localStorage.getItem('language');
        this.memberDetails = this._LocalService.getJsonValue('memberDetails');
        this.fromDate = null;
        this.ToDate = null;
        this.insuredMember = null;
        this.minDate = null;
        this.deffrom=this.formatForCalnder(new Date(Date.now() - 604800000),0);
        this.defTo=this.formatForCalnder(new Date(),0);
        this.resertoggle=false;
        this.pageInit = true;

    }
    columnsToDisplay = [
        'button',
        'approvalNumber',
        'serviceProvider',
        'date',
        'insuredMember',
        'state',
    ];
    ngOnInit(): void {
        
        this.today = new Date();
        //this.GetMedicalApprovals();
        this.GetMemeberDetails();

    }
    ngAfterViewInit() {
        
        this.dataSource.paginator = this.paginator;
       
    }

    toggleRow(element: { expanded: boolean }) {
        this.medicalApprovalsDetails=null;
        if (element.expanded === false) {
            this.GetMedicalApprovalsDetials(element['approvalNo']);
            // Uncommnet to open only single row at once
            this.medicalApprovals.data.forEach((row) => {
                row.expanded = false;
            });
        }
        element.expanded = !element.expanded; 
    }

    GetMedicalApprovals() {
        const  to = this.format(new Date(), 1);
        const from = this.formatFromlastWeek(new Date(),1);        
        let queryString =
            'MemberId=' + this.memID + '&From=' + from + '&To=' + to;
        this.medicalClaimsService
            .MedicalApprovals(queryString)
            .subscribe((res) => {
                this.medicalApprovals = res;

                for (
                    let index = 0;
                    index < this.medicalApprovals.data.length;
                    index++
                ) {
                    this.medicalApprovals.data[index].expanded = false;
                }
                this.dataSource = new MatTableDataSource<medicalApproval>(
                    this.medicalApprovals?.data
                );
                setTimeout(() => this.dataSource.paginator = this.paginator);

                this.dataSource.paginator = this.paginator;
                this.isLoading=false
               

            },
            (err)=>
            {
              this.isLoading=false
        
            });
        

    }
    GetMedicalApprovalsDetials(id) {
        let queryString = 'approvalId=' + id;
        this.medicalClaimsService
            .MedicalApprovalsDetails(queryString)
            .subscribe((res) => {
                this.medicalApprovalsDetails = res?.data;
            });
    }
    addFromDate(type: string, event) {
        this.toInput.value = '';
        this.minDate = event.value;
        this.fromDate = this.format(event.value, 1);
    }
    addToDate(type: string, event) {
        this.ToDate = this.format(event.value, 1);

    }
    format(inputDate, mnth) {
        let date, month, year;

        date = inputDate.getDate();
        month = inputDate.getMonth() + mnth;
        year = inputDate.getFullYear();
        if(month===13)
        {
            month=1;
        }
        date = date.toString().padStart(2, '0');

        month = month.toString().padStart(2, '0');

        return `${month}-${date}-${year}`;
    }
    formatFromlastWeek(inputDate,mnth)
    {
        let date, month, year;

        date = inputDate.getDate()-7;
        month = inputDate.getMonth()+mnth;
        year = inputDate.getFullYear();
        if(month===13)
        {
            month=1;
        }
        date = date.toString().padStart(2, '0');

        month = month.toString().padStart(2, '0');

        return `${month}-${date}-${year}`;
    }

    formatForCalnder(inputDate,day)
    {
        let date, month, year;
        
        date = inputDate.getDate()-day;
        month = inputDate.getMonth()+ 1;
        year = inputDate.getFullYear();
        if(month===13)
        {
            month=1;
        }
        date = date.toString().padStart(2, '0');

        month = month.toString().padStart(2, '0');

        return `${date}/${month}/${year}`;
    }
    filterByName(event) {
        this.insuredMember = event;
    }
    getMemberData() {  
        this.isLoading=true;
 
        const from = this.fromDate;
        const  to = this.ToDate;
        let queryString =
            'MemberId=' + this.insuredMember.id + '&From=' + from + '&To=' + to;
        this.medicalClaimsService
            .MedicalApprovals(queryString)
            .subscribe((res) => {
                this.medicalApprovals = res;

                for (
                    let index = 0;
                    index < this.medicalApprovals.data.length;
                    index++
                ) {
                    this.medicalApprovals.data[index].expanded = false;
                }
                this.dataSource = new MatTableDataSource<medicalApproval>(
                    this.medicalApprovals?.data
                );
                setTimeout(() => this.dataSource.paginator = this.paginator);

                this.dataSource.paginator = this.paginator;
                this.isLoading=false
              
            }, (err)=>
            {
              this.isLoading=false
        
            });
            this.resertoggle=true;
            this.pageInit = false;

    }

    reset()
    {
        this.resertoggle=false;
        this.today = new Date();
        this.fromDate = null;
        this.ToDate = null;
        this.insuredMember = null;
        this.fromInput.value = '';
        this.toInput.value = '';
        this.minDate = null;
        this.medicalApprovals=null;
        this.pageInit = true;



    }

    GetMemeberDetails()
    {
        this.memberDetails = this._LocalService.getJsonValue('memberDetails');
        if( this.memberDetails !==null )
        {
        if (this.memberDetails?.length > 0) {
            this.selectedMember = this.memberDetails[0];
        }
    }
    else{
        
        let queryString = 'MemberId=' + this.memID  ;
        this.medicalClaimsService
            .MemberDetails(queryString)
            .subscribe((res) => {
                this.memberDetails = res;
                this._LocalService.setJsonValue('memberDetails', res?.data);
                if (res?.data.length > 0) {
                    this.selectedMember = res?.data[0];
                    this.memberDetails=res?.data;

                }
            });
        }
        this.isLoading = false;

    
    }
}
export interface medicalApproval {
    approvalNo: string;
    approvalName: string;
    creationDate: string;
    approvalStatus: string;
    notes: string;
    benefitCategory: string;
    memberNme: string;
    expanded: boolean;
}
