import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SmeClassSelectionDialogComponent } from '../sme-class-selection-dialog/sme-class-selection-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';

@Component({
    selector: 'app-sme-login-page',
    templateUrl: './sme-login-page.component.html',
    styleUrls: ['./sme-login-page.component.scss'],
})
export class SmeLoginPageComponent implements OnInit {
    SMEWathqForm: FormGroup;
    isLoading: boolean;
    WathqCompanyDetails: any;
    birthDatePatternD = '^(0[1-9]|1|1[0-9]|2|2[0-9]|3|3[0]|4|5|6|7|8|9)$';
    birthDatePatternM = '^(0[1-9]|1|1[0-2]|4|5|6|7|8|9)$';
    birthDatePatternY = '^([1-9][0-9]{3})$';
    emailPattern =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    mobilePattern = '^(05)([0-9]{8})$';

    errorMessage: string;
    isErrorOccured: boolean;
    otpStep: boolean;
    formInput = ['input1', 'input2', 'input3', 'input4'];
    form: any;
    @ViewChildren('formRow') rows: any;
    @ViewChild('swiper') swiper: ElementRef;

    invalidOTP: boolean;
    isLoading2: boolean;
    CompanyId: number;
    UserEmail: string = '';
    MobileNumber: string = '';
    defaultClassFlag: boolean = true;
    display: any;

    timerr: any;
    otpExpire: boolean = false;

    constructor(
        public dialog: MatDialog,
        private _formbuilder: FormBuilder,
        private _router: Router,
        private smeService: MedicalSMEService,
        public translate: TranslateService,
        private _LocalService: LocalService,
    ) {
        this.form = this.toFormGroup(this.formInput);
        this.SMEWathqForm = this._formbuilder.group({
            CompanyId: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
            MobileNumber: [
                '',
                [Validators.required, Validators.pattern(this.mobilePattern)],

            ],
            EmailAddress: ['', [Validators.required, Validators.pattern(this.emailPattern), Validators.maxLength(100)]],
            BirthDateDay: [null, [Validators.pattern(this.birthDatePatternD)]],
            BirthDateMonth: [
                null,
                [Validators.pattern(this.birthDatePatternM)],
            ],
            BirthDateYear: [null, [Validators.pattern(this.birthDatePatternY)]],
            OTP: [null],
            isWeb:true,
            Lob:LineOfBusiness.MedicalSME,
            Type: SMSActionType.Login
        });
    }

    ngOnInit(): void {
        localStorage.removeItem("quotationNumber");
        localStorage.removeItem("company");
        localStorage.removeItem("policyId");
    }

    toFormGroup(elements: any) {
        const group: any = {};
        elements.forEach((key: any) => {
            group[key] = new FormControl('', Validators.required);
        });
        return new FormGroup(group);
    }

    GetCompanyDetails() { }

    SubmitCompanySearchForm() {
        if (this.newSMEWathqCompanyCheckForm.CompanyId.value.charAt(0) == '7')
            this.ValidateWathqLogin();
        else if (this.newSMEWathqCompanyCheckForm.CompanyId.value.charAt(0) == '1')
            this.GetCitizenInfo();
    }

    ValidateWathqLogin() {
        this.isLoading = true;
        this.otpStep = false;
        this.SMEWathqForm.controls['OTP'].setValue(Number("0000"));
        clearInterval(this.timerr);
        this.smeService
            .ValidateWathqLogin(this.SMEWathqForm.value)
            .subscribe(
                (res) => {
                    if (res) {
                        console.log(res);
                        if (res.statusCode == 200) {
                            this.timer(3);
                            this.otpStep = true;
                            this.form.controls['input1'].setValue("");
                            this.form.controls['input2'].setValue("");
                            this.form.controls['input3'].setValue("");
                            this.form.controls['input4'].setValue("");
                        }
                        else if (res.statusCode == 200 &&
                            res.content.crInquiryResponse.status.id.toString().toLowerCase() !== "active") {
                            let dir: string = '';
                            let ErrMsg = '';
                            if (this.translate.currentLang == 'en-US') {
                                dir = 'ltr';
                                ErrMsg = 'Commerical registeration number is not active';
                            } else {
                                dir = 'rtl';
                                ErrMsg = 'رقم السجل التجاري غير نشط';
                            }

                            this.ErrDialog(ErrMsg);
                            this.otpStep = false;
                        } else if (res.statusCode == 404) {
                            let dir: string = '';
                            let ErrMsg = '';
                            if (this.translate.currentLang == 'en-US') {
                                dir = 'ltr';
                                ErrMsg = 'Commerical registeration number is not correct';
                            } else {
                                dir = 'rtl';
                                ErrMsg = 'رقم السجل التجاري غير صحيح';
                            }

                            this.ErrDialog(ErrMsg);
                            this.otpStep = false;
                        } else if (res.statusCode == 401) {
                            let dir: string = '';
                            let ErrMsg = '';
                            if (this.translate.currentLang == 'en-US') {
                                dir = 'ltr';
                                ErrMsg = 'Please Enter the correct mobile number registered with this Commerical registeration number';
                            } else {
                                dir = 'rtl';
                                ErrMsg = 'برجاء ادخال رقم الهاتف الصحيح المسجل لدي رقم السجل التجاري';
                            }

                            this.ErrDialog(ErrMsg);
                            this.otpStep = false;
                        } else if (res.statusCode == 400) {
                            let dir: string = '';
                            let ErrMsg = '';
                            if (this.translate.currentLang == 'en-US') {
                                dir = 'ltr';
                                ErrMsg = 'Commerical registeration number is Expired';
                            } else {
                                dir = 'rtl';
                                ErrMsg = ' رقم السجل التجاري منتهي صلاحيته';
                            }

                            this.ErrDialog(ErrMsg);
                            this.otpStep = false;
                        }
                    }

                    this.isLoading = false;
                },
                (err) => {
                    this.isLoading = false;
                }
            );
    }

    timer(minute: any) {
        // let minute = 1;
        let seconds: number = minute * 60;
        let textSec: any = "0";
        let statSec: number = 60;

        const prefix = minute < 10 ? "0" : "";

        this.timerr = setInterval(() => {
            seconds--;
            if (statSec != 0) statSec--;
            else statSec = 59;

            if (statSec < 10) {
                textSec = "0" + statSec;
            } else textSec = statSec;

            this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
            if (this.otpExpire === false)

                if (seconds == 0) {
                    //console.log("finished");
                    clearInterval(this.timerr);
                    this.otpExpire = true;
                }
        }, 1000);
    }

    isDisabled(): boolean {
        return this.otpExpire;
    }

    keyUpEvent(event: any, index: any) {
        if (((event.keyCode >= 48 && event.keyCode < 58) || (event.keyCode >= 96 && event.keyCode < 110)
            || event.keyCode === 8 || event.keyCode == 13) && this.otpStep) {
            if (event.keyCode == 13 && this.otpStep) {
                this.GetWathqData();
            }
            else {
                let x = this.form;
                let pos = index;
                if (event.keyCode === 8 && event.which === 8) {
                    pos = index - 1;
                } else {
                    pos = index + 1;
                }
                if (pos > -1 && pos < this.formInput.length) {
                    this.rows._results[pos].nativeElement.focus();
                }
            }
        }
    }

    ResendVerification() {
        this.SMEWathqForm.controls['OTP'].setValue(Number("0000"));
        this.isLoading2 = true;
        this.otpExpire = false;
        this.smeService
            .ValidateWathqLogin(this.SMEWathqForm.value)
            .subscribe(
                (res) => {
                    if (res) {
                        this.timer(3);
                        this.otpStep = true;
                        this.isLoading2 = false;
                    }
                    this.isLoading2 = false;
                },
                (err) => {
                    this.isLoading2 = false;
                }
            );
    }

    GetWathqData() {
        this.isLoading = true;
        let code = this.form.value.input1 + this.form.value.input2 + this.form.value.input3 + this.form.value.input4

        this.SMEWathqForm.controls['OTP'].setValue(Number(code));
        this.smeService
            .GetWathqCompanyDetails(this.SMEWathqForm.value)
            .subscribe(
                (res) => {
                    if (res == null || res == undefined) {
                        let dir: string = '';
                        let ErrMsg = '';
                        if (this.translate.currentLang == 'en-US') {
                            dir = 'ltr';
                            ErrMsg = 'The entered Code not matched with the sent Code!';
                        } else {
                            dir = 'rtl';
                            ErrMsg = 'الرمز المدخل لا يطابق الرمز المرسل';
                        }
                        this.form.controls['input1'].setValue("");
                        this.form.controls['input2'].setValue("");
                        this.form.controls['input3'].setValue("");
                        this.form.controls['input4'].setValue("");
                        this.swiper.nativeElement.focus();

                        this.ErrDialog(ErrMsg);
                    }
                    else {
                        if (res.statusCode == 200) {
                            //console.log(res);

                            this._LocalService.setJsonValue('SponsorEmail', this.SMEWathqForm.controls['EmailAddress'].value);
                            this._LocalService.setJsonValue('SponsorMobile', this.SMEWathqForm.controls['MobileNumber'].value);
                            this._LocalService.setJsonValue('SponsorId', this.SMEWathqForm.controls['CompanyId'].value);
                            this._LocalService.setJsonValue('CompanyName', res.content.crInquiryResponse.crName);

                            if (res.paymentDetails != null) {
                                this._LocalService.setJsonValue('SponsorIBAN', res.paymentDetails?.iban);
                                this._LocalService.setJsonValue('SponsorVAT', res.paymentDetails?.vat);
                            }
                            else {
                                this._LocalService.setJsonValue('SponsorIBAN', null);
                                this._LocalService.setJsonValue('SponsorVAT', null);
                            }

                            this.WathqCompanyDetails =
                                res.content.crInquiryResponse;
                            this._router.navigateByUrl('/medical-sme', {
                                state: {
                                    CompanyDetails: this.WathqCompanyDetails,
                                },
                            });
                        } else {
                            let dir: string = '';
                            let ErrMsg = '';
                            if (this.translate.currentLang == 'en-US') {
                                dir = 'ltr';
                                ErrMsg = 'Please check Commerical registeration number';
                            } else {
                                dir = 'rtl';
                                ErrMsg = 'برجاء التأكد من رقم السجل التجاري للشركة';
                            }

                            this.ErrDialog(ErrMsg);
                            this.otpStep = false;
                        }
                    }
                    this.isLoading = false;
                },
                (err) => {
                    this.isLoading = false;
                }
            );
    }

    GetCitizenInfo() {
        this.isLoading = true;
        this.isErrorOccured = false;
        let birthDay = this.handleBirthDateDayAndMonthFormat(
            this.SMEWathqForm.controls['BirthDateDay'].value
        );
        let birthMonth = this.handleBirthDateDayAndMonthFormat(
            this.SMEWathqForm.controls['BirthDateMonth'].value
        );
        let birthDate = `${birthMonth}-${this.SMEWathqForm.controls['BirthDateYear'].value}`;
        let queryString;

        queryString = `identityNumber=${this.SMEWathqForm.controls['CompanyId'].value}&MobilePhone=${this.SMEWathqForm.controls['MobileNumber'].value}&dateOfBirth=${birthDate}`;

        this.smeService.GetCitizenInfo(queryString).subscribe(
            (res) => {
                if (res.statusCode == 200)
                    this.WathqCompanyDetails = res.content.crInquiryResponse;
                this._router.navigateByUrl('/medical-sme', {
                    state: {
                        CompanyDetails: this.WathqCompanyDetails,
                    },
                });

                this.isLoading = false;
            },
            (err) => {
                this.isErrorOccured = true;
                this.errorMessage = err[0].error;
                this.isLoading = false;
            }
        );
    }

    get newSMEWathqCompanyCheckForm() {
        return this.SMEWathqForm.controls;
    }

    isSA: any = false;
    handleIqamaNumberChange(e) {
        if (e.target.value.charAt(0) == 1) {
            this.isSA = true;

            this.newSMEWathqCompanyCheckForm.BirthDateDay.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternD),
            ]);
            this.newSMEWathqCompanyCheckForm.BirthDateDay.updateValueAndValidity();

            this.newSMEWathqCompanyCheckForm.BirthDateMonth.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternM),
            ]);
            this.newSMEWathqCompanyCheckForm.BirthDateMonth.updateValueAndValidity();

            this.newSMEWathqCompanyCheckForm.BirthDateYear.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternY),
            ]);
            this.newSMEWathqCompanyCheckForm.BirthDateYear.updateValueAndValidity();
        } else {
            this.isSA = false;
            this.newSMEWathqCompanyCheckForm.BirthDateDay.clearValidators();
            this.newSMEWathqCompanyCheckForm.BirthDateDay.updateValueAndValidity();

            this.newSMEWathqCompanyCheckForm.BirthDateMonth.clearValidators();
            this.newSMEWathqCompanyCheckForm.BirthDateMonth.updateValueAndValidity();

            this.newSMEWathqCompanyCheckForm.BirthDateYear.clearValidators();
            this.newSMEWathqCompanyCheckForm.BirthDateYear.updateValueAndValidity();
        }
    }

    handleBirthDateDayAndMonthFormat(value) {
        switch (value) {
            case '1':
                return '01';
            case '2':
                return '02';
            case '3':
                return '03';
            case '4':
                return '04';
            case '5':
                return '05';
            case '6':
                return '06';
            case '7':
                return '07';
            case '8':
                return '08';
            case '9':
                return '09';
            default:
                return value;
        }
    }

    ErrDialog(msg) {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        Swal.fire({
            title: this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#FF001F; direction:" +
                dir +
                "'>" +
                msg +
                '</div>',
            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
                icon: 'submit-claim-failed-icon-img',
                title: 'submit-claim-failed-swal-title',
                confirmButton: 'submit-claim-failed-confirm-btn',
            },
        });
    }
}
