<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container-fluid mt-5 mb-5">
    <!-- sponsor -->
    <div class="row">
        <div class="sme-quotation-left-line"></div>
        <div class="sme-quotation-sponsor-details-container">
            <h4 class="sme-quotation-details">
                {{ "SME.SPONSORINFO" | translate }}
            </h4>
        </div>
        <div class="sme-quotation-right-line"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.SPONSORID" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyDetails.crEntityNumber }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.ACTIVITY" | translate
                }}</label>
                <div class="sme-quoation-text" *ngIf="this.translate.currentLang === 'en-US'">
                    <label>{{ CompanyDetails.activities.isic[0].nameEN }}</label>
                </div>

                <div class="sme-quoation-text" *ngIf="this.translate.currentLang === 'ar-SA'">
                    <label>{{ CompanyDetails.activities.isic[0].name }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.SPONSORMOBILE" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyDetails.crNumber }}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- address -->
    <div class="row mt-5">
        <div class="sme-quotation-left-line"></div>
        <div class="sme-quotation-address-details-container">
            <h4 class="sme-quotation-details">
                {{ "SME.SPONSORADDRESS" | translate }}
            </h4>
        </div>
        <div class="sme-quotation-right-line"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.CITYCODE" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyAddress?.addresses[0]?.city }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.ADDNUMBER" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{
                        CompanyAddress?.addresses[0]?.additionalNumber
                    }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.POSTALCODE" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyAddress?.addresses[0]?.postCode }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.BULNUMBER" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{
                        CompanyAddress?.addresses[0]?.buildingNumber
                    }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.DISTRICT" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyAddress?.addresses[0]?.district }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.STREET" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{
                        CompanyAddress?.addresses[0]?.streetName
                    }}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- cchi -->
    <div class="row mt-5">
        <div class="sme-quotation-left-line"></div>
        <div class="sme-quotation-cchi-details-container">
            <h4 class="sme-quotation-details">
                {{ "SME.CCHISPONSOR" | translate }}
            </h4>
        </div>
        <div class="sme-quotation-right-line"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.REQMAIN" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyCCHI?.required_Main }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.REQDEP" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyCCHI?.required_Dependents }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.REMDEP" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyCCHI?.remaining_Dependents }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.REMMAIN" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyCCHI?.remaining_Main }}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- quotation info -->
    <div class="row mt-5">
        <div class="sme-quotation-left-line"></div>
        <div class="sme-quotation-info-details-container">
            <h4 class="sme-quotation-details">
                {{ "SME.QUOTATIONINFO" | translate }}
            </h4>
        </div>
        <div class="sme-quotation-right-line"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.QUOTATIONNO" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>
                        {{ QuotationNumber }}
                    </label>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.CREATIONDATE" | translate
                }}</label>
                <div class="sme-quoation-text">
                    <label>
                        {{ creationDate | date : "dd/MM/yyyy" }}
                    </label>
                </div>
            </div>
        </div> -->
    </div>
    <!-- <div class="row mt-2">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label" style="width: 28%">{{
                    "SME.CLASSTYPE" | translate
                }}</label>
                <div style="display: flex; flex-direction: row">
                    <div class="sme-quoation-text">
                        <label>Classic</label>
                    </div>
                    <div class="sme-type-line"></div>
                    <div class="sme-quoation-text">
                        <label>Base</label>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- next btn -->
    <div class="row sme-next-btn-container mb-5 mt-5">
        <button class="sme-next-btn" mat-button (click)="checkQuotationMembers()" id="members-details-btn">
            {{ "SME.NEXT" | translate }}
        </button>
    </div>
</div>
