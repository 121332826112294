<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div mat-dialog-title class="sme-fast-calc-summary-container">
    <div class="d-flex justify-content-center align-items-center">
        <span>{{ "SME.FASTCALC" | translate }}</span>
    </div>
</div>

<div mat-dialog-content class="sme-details-container mt-3 mb-5">
    <div class="row col-lg-12 col-md-12 col-sm-12" 
    [ngClass]="{'d-flex': fastCalcResult.length == 1, 'align-items-center': fastCalcResult.length == 1,
            'flex-column': fastCalcResult.length == 1}">
        <div class="d-flex col-lg-6 col-md-6 col-sm-6" style="padding:0" 
            [ngClass]="{'justify-content-end': i==0, 'justify-content-start': i==1, 'width-90': fastCalcResult.length == 1,
            'dir-en': this.translate.currentLang === 'en-US', 'dir-ar': this.translate.currentLang === 'ar-SA'}"
            *ngFor="let element of fastCalcResult; index as i">
            <div class="mt-3 {{ 'class-header-' + i }}" [ngClass]="{'class-header-2': fastCalcResult.length == 1}">
                <div class="d-flex flex-row justify-content-center">
                    <div class="sme-quotation-details-container">
                        <h4 class="sme-quotation-details"> <span>{{ element.class }}
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
        </div>


        <div class="d-flex col-lg-6 col-md-6 col-sm-6 justify-content-between mt-3"
            [ngClass]="{'width-90': fastCalcResult.length == 1, 'dir-en': this.translate.currentLang === 'en-US', 'dir-ar': this.translate.currentLang === 'ar-SA'}"
            *ngFor="let element of fastCalcResult; index as i">
            <div class="d-flex flex-row" style="width: 100%;">
                <div class="d-flex sme-quotation-details-container col-2" style="text-align: center; align-self: center;">
                    <h4 class="sme-table-quotation-details box-container">
                        {{ "SME.MEMBER_DETAILS" | translate }}
                    </h4>
                </div>

                <div class="d-flex align-items-start flex-column box-container-3">
                    <div class="d-flex align-items-center" *ngFor="let detail of element.fastCalcDetails">
                        <span class="members-count-label">
                            <span style="color:#156538;;">{{ detail.noOfMembers }} &nbsp;</span> {{ detail.description
                            }} &nbsp;
                            <span style="color:#D4AB36;">{{ formatter.format(detail.totalPremiumPerGender) }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex col-lg-6 col-md-6 col-sm-6 mt-3"
            *ngFor="let element of fastCalcResult; index as i"
            [ngClass]="{'width-90': fastCalcResult.length == 1,
            'dir-en': this.translate.currentLang === 'en-US', 'dir-ar': this.translate.currentLang === 'ar-SA'}"
            >

            <div class="sme-quotation-details-container">
                <h4 class="sme-table-quotation-details">
                    &nbsp; &nbsp;{{ "SME.NOOFMEMBERS" | translate }} &nbsp; &nbsp;
                </h4>
            </div>

            <div class="d-flex align-items-center" style="margin-left: 30%;margin-right: 30%;">
                <span class="members-count-label">{{ element.totalMembers }}
                </span>
            </div>
        </div>

        <div class="d-flex col-lg-6 col-md-6 col-sm-6 justify-content-between mt-3"
            *ngFor="let element of fastCalcResult"
            [ngClass]="{'width-90': fastCalcResult.length == 1,
            'dir-en': this.translate.currentLang === 'en-US', 'dir-ar': this.translate.currentLang === 'ar-SA'}">
            <div class="d-flex flex-row" style="width: 100%;">
                <div class="d-flex sme-quotation-details-container col-2" style="text-align: center; align-self: center;">
                    <h4 class="sme-table-quotation-details box-container">
                        {{ "SME.BREAKDOWN" | translate }}
                    </h4>
                </div>

                <div class="d-flex align-items-start flex-column box-container-2" style="max-height: 100%; overflow-y: hidden;">
                    <div class="members-count-label d-flex justify-content-between" style="width: 100%;">
                        <span style="color:#156538;">{{ "MMLHome.premAmount" | translate }}</span>
                        <span> {{ formatter.format(element.totalPremimum) }} </span>
                    </div>

                    <div class="members-count-label d-flex justify-content-between" style="width: 100%;">
                        <span style="color:#156538;">{{ "MMLHome.Vat" | translate }} 15%</span>
                        <span> {{ formatter.format((element.totalPremimum * 15) / 100) }} </span>
                    </div>

                    <div class="members-count-label d-flex justify-content-between" style="width: 100%;">
                        <span style="color:#156538;">{{"MMLHome.TAmount" | translate}}</span>
                        <span> {{ formatter.format(((element.totalPremimum * 15) / 100 + element.totalPremimum)) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="d-flex flex-row text-center justify-content-center">
                <div class="sme-quotation-last-line"></div>
            </div>

            <div class="d-flex flex-row text-center justify-content-center pt-3">
                <div class="d-flex flex-row align-items-center">
                    <span class="members-count-label " style="color: #156538;">{{ "SME.TOTAL_AMOUT_MSG" | translate }}
                        &nbsp; &nbsp;
                        {{ formatter.format(totalAmount) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-row align-items-center justify-content-center pt-2">
    <button class="create-qoute-btn-o" (click)="backToFastCalc()">
        {{ "SME.back" | translate }}
    </button>
    <button class="create-qoute-btn" (click)="CreateSMEQuotation()">
        {{ "SME.CREATEQUOTATION" | translate }}
    </button>
</div>