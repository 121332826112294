<!-- Start Page Title Area -->
<div class="page-title-area bg-1">
    <div class="container col-md-10">
        <div class="page-title-content">
            <h2>{{ "MEDICAL.NewClaim" | translate }}</h2>
            <ul>
                <li>
                    <a routerLink="/"
                        >{{ "NAV.ESERVICES" | translate }}
                        <span style="margin: 0 10px"> / </span></a
                    >
                </li>
                <li>
                    <a routerLink="/medical-profile">
                        {{ "NAV.MEDICAL" | translate }}

                        <span style="margin: 0 10px"> / </span></a
                    >
                </li>
                <li class="active" style="color: white">
                    {{ "MEDICAL.NewClaim" | translate }}
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="container pt-5 pb-5">
    <div class="col-lg-12 col-md-12 col-sm-12 form-container p-2">
        <div class="row">
            <div style="display: flex">
                <a href="/medical-profile">
                    <img
                        src="assets/img/medical-service/icons8-back-arrow-90.png"
                        class="back-icon"
                        alt=""
                        [ngClass]="{
                            'arrow-img-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                    />
                </a>
                <h5
                    class="color-green"
                    [ngClass]="{
                        'title-en': this.translate.currentLang == 'en-US',
                        'title-ar': this.translate.currentLang != 'en-US'
                    }"
                >
                    {{ "MEDICAL.NewCashClaim" | translate }}
                </h5>
            </div>
            <hr
                [ngClass]="{
                    'hr-en': this.translate.currentLang == 'en-US',
                    'hr-ar': this.translate.currentLang != 'en-US'
                }"
            />
        </div>

        <form [formGroup]="NewMedicalClaimFrom" style="padding: 2%">
            <div class="row form-element-container pt-4 pb-4">
                <div style="display: flex" class="pb-4">
                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="inKsa" class="form-label">
                                {{ "MEDICAL.ClaimType" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <ng-select
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.inKsa
                                            .errors &&
                                        newMedicalClaimFromControl.inKsa.touched
                                }"
                                placeholder="{{
                                    'MEDICAL.ClaimType' | translate
                                }}"
                                formControlName="inKsa"
                                [searchable]="true"
                                class="input-form-design"
                                [items]="ClaimTypes"
                                bindLabel="name"
                                [(ngModel)]="selectedClaimType"
                                (change)="ChangeClaimType()"
                            >
                            </ng-select>
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.inKsa.errors &&
                                    newMedicalClaimFromControl.inKsa.touched
                                "
                                style="color: #ff001f; font-weight: bold"
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.inKsa.errors
                                            .required
                                    "
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="country" class="form-label">
                                {{ "MEDICAL.Country" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <ng-select
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.country
                                            .errors &&
                                        newMedicalClaimFromControl.country
                                            .touched
                                }"
                                placeholder="{{
                                    'MEDICAL.Country' | translate
                                }}"
                                formControlName="country"
                                [searchable]="true"
                                class="input-form-design"
                            >
                                <ng-option
                                    [value]="item.countryCode"
                                    *ngFor="let item of Countries"
                                >
                                    {{ item.countryName }}
                                </ng-option>
                            </ng-select>
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.country.errors &&
                                    newMedicalClaimFromControl.country.touched
                                "
                                style="color: #ff001f; font-weight: bold"
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.country
                                            .errors.required
                                    "
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="cityName" class="form-label">
                                {{ "MEDICAL.City" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <input
                                id="cityNameId"
                                name="cityName"
                                formControlName="cityName"
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.cityName
                                            .errors &&
                                        newMedicalClaimFromControl.cityName
                                            .touched
                                }"
                                class="form-control input-form-design"
                                placeholder="{{ 'MEDICAL.City' | translate }}"
                                type="text"
                                onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))"
                            />
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.cityName
                                        .errors &&
                                    newMedicalClaimFromControl.cityName.touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.cityName
                                            .errors.required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl?.cityName
                                            ?.value?.length > 10
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.maxLengthC" | translate }} 10
                                    {{ "GENERIC.maxLengthChar" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex" class="pb-3">
                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="category" class="form-label">
                                {{ "MEDICAL.Category" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <ng-select
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.category
                                            .errors &&
                                        newMedicalClaimFromControl.category
                                            .touched
                                }"
                                placeholder="{{
                                    'MEDICAL.Category' | translate
                                }}"
                                formControlName="category"
                                [searchable]="true"
                                class="input-form-design"
                            >
                                <ng-option
                                    [value]="item.categoryName"
                                    *ngFor="let item of Categories"
                                >
                                    {{ item.categoryName }}
                                </ng-option>
                            </ng-select>
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.category
                                        .errors &&
                                    newMedicalClaimFromControl.category.touched
                                "
                                style="color: #ff001f; font-weight: bold"
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.category
                                            .errors.required
                                    "
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="type" class="form-label">
                                {{ "MEDICAL.Type" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <ng-select
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.type
                                            .errors &&
                                        newMedicalClaimFromControl.type.touched
                                }"
                                placeholder="{{ 'MEDICAL.Type' | translate }}"
                                formControlName="type"
                                [searchable]="true"
                                class="input-form-design"
                            >
                                <ng-option [value]="'I'">
                                    {{ "MEDICAL.InPatient" | translate }}
                                </ng-option>
                                <ng-option [value]="'O'">
                                    {{
                                        "MEDICAL.OutPatient" | translate
                                    }}</ng-option
                                >
                            </ng-select>
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.type.errors &&
                                    newMedicalClaimFromControl.type.touched
                                "
                                style="color: #ff001f; font-weight: bold"
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.type.errors
                                            .required
                                    "
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row form-element-container pt-4 pb-4 mt-4">
                <div style="display: flex">
                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="memberName" class="form-label">
                                {{ "MEDICAL.MemberName" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <ng-select
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.inKsa
                                            .errors &&
                                        newMedicalClaimFromControl.inKsa.touched
                                }"
                                placeholder="{{
                                    'MEDICAL.MemberName' | translate
                                }}"
                                formControlName="memberName"
                                [searchable]="true"
                                class="input-form-design"
                                [items]="memberDetails"
                                bindLabel="memberEName"
                            >
                            </ng-select>
                            <!-- <input
                                id="memberNameId"
                                name="memberName"
                                type="text"
                                formControlName="memberName"
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.memberName
                                            .errors &&
                                        newMedicalClaimFromControl.memberName
                                            .touched
                                }"
                                class="form-control input-form-design"
                                placeholder="{{
                                    'MEDICAL.MemberName' | translate
                                }}"
                                type="text"
                            /> -->
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.memberName
                                        .errors &&
                                    newMedicalClaimFromControl.memberName
                                        .touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.memberName
                                            .errors.required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="visitDate" class="form-label">
                                {{ "MEDICAL.VisitDate" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field
                                appearance="none"
                                class="form-control input-form-design w-100"
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.visitDate
                                            .errors &&
                                        newMedicalClaimFromControl.visitDate
                                            .touched
                                }"
                            >
                                <input
                                    formControlName="visitDate"
                                    matInput
                                    [matDatepicker]="picker"
                                    placeholder="MM/DD/YYYY"
                                    [max]="today"
                                />
                                <mat-datepicker-toggle
                                    style="
                                        margin-right: -6%;
                                        margin-top: -3%;
                                        color: #156538;
                                    "
                                    matIconSuffix
                                    [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.visitDate
                                        .errors &&
                                    newMedicalClaimFromControl.visitDate.touched
                                "
                                style="color: #ff001f"
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.visitDate
                                            .errors.required
                                    "
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="visitDate" class="form-label">
                                {{ "MEDICAL.ServiceProvider" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <input
                                id="serviceProviderId"
                                name="serviceProvider"
                                type="text"
                                formControlName="serviceProvider"
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl
                                            .serviceProvider.errors &&
                                        newMedicalClaimFromControl
                                            .serviceProvider.touched
                                }"
                                class="form-control input-form-design"
                                placeholder="{{
                                    'MEDICAL.ServiceProvider' | translate
                                }}"
                            />
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.serviceProvider
                                        .errors &&
                                    newMedicalClaimFromControl.serviceProvider
                                        .touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl
                                            .serviceProvider.errors.required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl
                                            ?.serviceProvider?.value?.length >
                                        100
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.maxLengthC" | translate }} 100
                                    {{ "GENERIC.maxLengthChar" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-element-container pt-4 pb-4 mt-4">
                <div style="display: flex">
                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="currency" class="form-label">
                                {{ "MEDICAL.Currency" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <ng-select
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.currency
                                            .errors &&
                                        newMedicalClaimFromControl.currency
                                            .touched
                                }"
                                placeholder="{{
                                    'MEDICAL.Currency' | translate
                                }}"
                                formControlName="currency"
                                [searchable]="true"
                                class="input-form-design"
                                [disabled]="true"
                                readOnly="this.disableCurrency==true"
                            >
                                <ng-option
                                    [value]="item.currencyCode"
                                    *ngFor="let item of Currencies"
                                >
                                    {{ item.currencyName }}
                                </ng-option>
                            </ng-select>
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.currency
                                        .errors &&
                                    newMedicalClaimFromControl.currency.touched
                                "
                                style="color: #ff001f; font-weight: bold"
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.currency
                                            .errors.required
                                    "
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="amount" class="form-label">
                                {{ "MEDICAL.Amount" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <input
                                id="amountId"
                                name="amount"
                                type="currency"
                                formControlName="amount"
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.amount
                                            .errors &&
                                        newMedicalClaimFromControl.amount
                                            .touched
                                }"
                                class="form-control input-form-design"
                                placeholder="{{ 'MEDICAL.Amount' | translate }}"
                                type="number"
                            />
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.amount.errors &&
                                    newMedicalClaimFromControl.amount.touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.amount.errors
                                            .required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl?.amount
                                            ?.value <= 10
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.amountVald" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex" class="pt-4">
                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="claimReason" class="form-label">
                                {{ "MEDICAL.ClaimReason" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <ng-select
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.claimReason
                                            .errors &&
                                        newMedicalClaimFromControl.claimReason
                                            .touched
                                }"
                                placeholder="{{
                                    'MEDICAL.ClaimReason' | translate
                                }}"
                                formControlName="claimReason"
                                [searchable]="true"
                                class="input-form-design"
                                [(ngModel)]="selectedClaimReason"
                                (change)="ChangeClaimReason()"
                            >
                                <ng-option
                                    [value]="item.reasonCode"
                                    *ngFor="let item of ClaimReasons"
                                >
                                    {{ item.reasonName }}
                                </ng-option>
                            </ng-select>
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.claimReason
                                        .errors &&
                                    newMedicalClaimFromControl.claimReason
                                        .touched
                                "
                                style="color: #ff001f; font-weight: bold"
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.claimReason
                                            .errors.required
                                    "
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style="display: flex"
                    class="pt-4"
                    *ngIf="showClaimReasonText"
                >
                    <div class="row col-md-8">
                        <div class="col-lg-2 col-md-2 col-sm-12 text-end">
                            <label for="claimReason" class="form-label">
                            </label>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-12">
                            <textarea
                                id="claimReasonTextId"
                                name="claimReasonText"
                                type="textarea"
                                formControlName="claimReasonText"
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl
                                            .claimReasonText.errors &&
                                        newMedicalClaimFromControl
                                            .claimReasonText.touched
                                }"
                                class="form-control input-form-design"
                                placeholder="{{
                                    'MEDICAL.ClaimReason' | translate
                                }}"
                            >
                            </textarea>
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.claimReasonText
                                        .errors &&
                                    newMedicalClaimFromControl.claimReasonText
                                        .touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl
                                            .claimReasonText.errors.required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl
                                            ?.claimReasonText?.value?.length >
                                        1000
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.maxLengthC" | translate }} 1000
                                    {{ "GENERIC.maxLengthChar" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row form-element-container pt-4 pb-4 mt-4">
                <div style="display: flex">
                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="memberIBan" class="form-label">
                                {{ "MEDICAL.IBAN" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <input
                                id="memberIBanId"
                                name="memberIBan"
                                type="text"
                                formControlName="memberIBan"
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.memberIBan
                                            .errors &&
                                        newMedicalClaimFromControl.memberIBan
                                            .touched
                                }"
                                class="form-control input-form-design"
                                placeholder="{{ 'MEDICAL.IBAN' | translate }}"
                                maxlength="22"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            />
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.memberIBan
                                        .errors &&
                                    newMedicalClaimFromControl.memberIBan
                                        .touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.memberIBan
                                            .errors.required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="memberEmail" class="form-label">
                                {{ "MEDICAL.MemberEmail" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <input
                                id="memberEmailId"
                                name="memberEmail"
                                type="email"
                                formControlName="memberEmail"
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.memberEmail
                                            .errors &&
                                        newMedicalClaimFromControl.memberEmail
                                            .touched
                                }"
                                class="form-control input-form-design"
                                placeholder="{{
                                    'MEDICAL.MemberEmail' | translate
                                }}"
                            />
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.memberEmail
                                        .errors &&
                                    newMedicalClaimFromControl.memberEmail
                                        .touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.memberEmail
                                            .errors.required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl?.memberEmail
                                            ?.value?.length > 100
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.maxLengthC" | translate }} 100
                                    {{ "GENERIC.maxLengthChar" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-4">
                        <div class="col-md-4 text-end">
                            <label for="bankName" class="form-label">
                                {{ "MEDICAL.BankName" | translate }}
                            </label>
                        </div>
                        <div class="col-md-8">
                            <input
                                id="bankNameId"
                                name="bankName"
                                type="text"
                                formControlName="bankName"
                                [ngClass]="{
                                    'red-border-class':
                                        newMedicalClaimFromControl.bankName
                                            .errors &&
                                        newMedicalClaimFromControl.bankName
                                            .touched
                                }"
                                class="form-control input-form-design"
                                placeholder="{{
                                    'MEDICAL.BankName' | translate
                                }}"
                                onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))"
                            />
                            <div
                                *ngIf="
                                    newMedicalClaimFromControl.bankName
                                        .errors &&
                                    newMedicalClaimFromControl.bankName.touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl.bankName
                                            .errors.required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                                <div
                                    *ngIf="
                                        newMedicalClaimFromControl?.bankName
                                            ?.value?.length > 100
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.maxLengthC" | translate }} 100
                                    {{ "GENERIC.maxLengthChar" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row form-element-container pt-4 pb-4 mt-4">
                <h5 style="color: #156538">
                    {{ "MEDICAL.RequiredDocuments" | translate }}
                </h5>
                <div
                    style="display: flex; flex-direction: row"
                    class="pt-3 row"
                >
                    <div
                        class="col-md-4"
                        *ngFor="let item of ClaimRequiredDocuments"
                    >
                        <div class="text-start upload-item pb-5">
                            <!-- <label
                                for="medicalReports"
                                class="form-label font-18"
                                *ngIf="item.mandatory === true"
                            >
                                MAN
                            </label> -->
                            <label class="form-label font-18">
                                <span
                                    style="color: #ff001f; font-size: 13px"
                                    *ngIf="item.mandatory === true"
                                    >*
                                    {{ "GENERIC.REQUIRED" | translate }}</span
                                >
                                <span
                                    style="
                                        color: #ff001f;
                                        font-size: 14px;
                                        visibility: hidden;
                                    "
                                    *ngIf="item.mandatory === false"
                                    >* required</span
                                >
                            </label>

                            <label
                                for="medicalReports"
                                class="form-label font-18"
                                style="min-height: 70px"
                            >
                                {{ item.documentName }}
                            </label>
                            <div>
                                <input
                                    type="file"
                                    [id]="item.documentType"
                                    [required]="true"
                                    #myInput
                                    class="hidden"
                                    accept="application/pdf, image/*"
                                    multiple
                                    (change)="
                                        setDocsList(
                                            $event,
                                            item.documentType,
                                            item.mandatory
                                        )
                                    "
                                />

                                <label
                                    [for]="item.documentType"
                                    class="custom-file-input upload-lbl"
                                >
                                    <img
                                        src="assets/img/medical-service/cloud-computing.png"
                                        width="25"
                                        alt=""
                                    />
                                    {{ "MEDICAL.UploadFiles" | translate }}
                                </label>
                            </div>
                        </div>
                        <div
                            class="row text-center"
                            *ngIf="ImgsId.includes(item.documentType)"
                        >
                            <ng-container
                                *ngFor="
                                    let img of nationalImgsList;
                                    let x = index
                                "
                            >
                                <div
                                    class="col-md-5 imageContainer"
                                    *ngIf="img.id == item.documentType"
                                >
                                    <div class="col-md-12 row mt-3">
                                        <div
                                            class="item-container"
                                            (click)="DisplayDocument(img)"
                                        >
                                            <div
                                                style="
                                                    overflow: clip;
                                                    margin-right: 5%;
                                                "
                                            >
                                                {{ img.name }}
                                            </div>
                                            <div>
                                                <span
                                                    class="delete"
                                                    (click)="deleteAtt(x)"
                                                    *ngIf="
                                                        img.id ==
                                                        item.documentType
                                                    "
                                                >
                                                    <fa-icon
                                                        [icon]="faXmark"
                                                    ></fa-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 pt-5 ml-3">
                <mat-checkbox
                    class="mt-4 user-agreement-confirm"
                    formControlName="save"
                    id="save"
                    [checked]="newMedicalClaimFromControl.save.value"
                    style="margin-left: -31px"
                >
                    {{ "MEDICAL.Agreement" | translate }}
                </mat-checkbox>
            </div>

            <div class="col-lg-12 text-center pt-3">
                <button
                    class="btn next-btn p-1"
                    (click)="submitMedicalClaim()"
                    [disabled]="isLoading"
                >
                    <span *ngIf="!isLoading">{{
                        "GENERIC.SUBMIT" | translate
                    }}</span>
                    <span *ngIf="isLoading">
                        <div class="spinner-border text-warning"></div>
                    </span>
                </button>
            </div>
        </form>
    </div>
</div>
