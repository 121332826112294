import { Component } from '@angular/core';

@Component({
  selector: 'app-vehicle-details-form',
  templateUrl: './vehicle-details-form.component.html',
  styleUrls: ['./vehicle-details-form.component.scss']
})
export class VehicleDetailsFormComponent {
  activeButton = 0;
  lang = localStorage.getItem('language');
  Sequence=1;
  switchButton(value: number) {
    this.activeButton = value;
  }
  years: number[] = [];
  constructor() {
    this.populateYears();
  }
  SequenceOrCustomerSwitch(ev)
  {
    
    this.Sequence=+ev.target.value;
  }

  populateYears() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 50; // Adjust as needed
    const endYear = currentYear + 0; // Adjust as needed

    for (let year = startYear; year <= endYear; year++) {
      this.years.push(year);
    }
  }
}
