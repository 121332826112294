import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-login-motor',
    templateUrl: './login-motor.component.html',
    styleUrls: ['./login-motor.component.scss'],
})
export class LoginMotorComponent {
    mobilePattern = '^(05)([0-9]{8})$';
    IqamaPattern = /[12]+[0-9]{9}$/;
    idForm: FormGroup;
    get idFormControl() {
        return this.idForm.controls;
    }
    ngOnInit(): void {
        this.buildIDFormForm();
    }

    constructor(
        public translate: TranslateService,
        public fb: FormBuilder,
        public _router: Router
    ) {}
    buildIDFormForm() {
        this.idForm = this.fb.group({
            Iqama: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.IqamaPattern),
                    Validators.maxLength(100),
                ],
            ],
            MobileNumber: [
                null,
                [Validators.required, Validators.pattern(this.mobilePattern)],
            ],
            BirthDate: [null, [Validators.required]],
        });
    }

    handleMotorLogin() {
        this._router.navigate(['/motor-request']);
    }
}
