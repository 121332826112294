<div [ngClass]="{ 'dir-en': this.translate.currentLang === 'en-US', 'dir-ar': this.translate.currentLang === 'ar-SA' }">
    <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
            <span>{{ "SME.ADD_MEMBER" | translate }}</span>
            <img src="assets/img/medical-sme/dialog-close.png" mat-dialog-close />
        </div>
        <hr class="title-underline" />
    </div>

    <div mat-dialog-content class="pt-5">
        <form [formGroup]="AddMemberForm">
            <div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12">
                <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center">
                    <div class="col-lg-5 col-md-5 col-sm-12">
                        <label class="add-member-lbl">{{
                            "SME.ID/IQAMA" | translate
                            }}&nbsp; <span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <input (keypress)="($event.charCode >= 48 && $event.charCode < 58)" [maxLength]="10"
                            id="IqamaId" name="IqamaId" formControlName="IqamaId" [ngClass]="{
                            'red-border-class':
                                newMemberFormControl.IqamaId.errors &&
                                newMemberFormControl.IqamaId.touched
                        }" class="form-control sme-quoation-text" placeholder="{{
                            'SME.IQAMAID_PLACEHOLDER' | translate}}" (blur)="focusOutIqama($event.target)" type="text"
                            (focus)="ClearValidation()" />
                        <div *ngIf="
                            newMemberFormControl.IqamaId.errors &&
                            newMemberFormControl.IqamaId.touched
                        ">
                            <div *ngIf="newMemberFormControl.IqamaId.errors.required" style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="newMemberFormControl?.IqamaId.errors.pattern" style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "SME.ONLYNUMBERS" | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center"
                    style="margin-left: 5%;margin-right:5%">
                    <div class="col-lg-5 col-md-5 col-sm-12">
                        <label class="add-member-lbl">{{
                            "SME.RELATION" | translate
                            }}&nbsp; <span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <mat-form-field appearance="none" class="sme-select">
                            <mat-select placeholder="{{ 'SME.SELECTRELATION' | translate }}" formControlName="Relation"
                                (selectionChange)="onRelationChange($event)">
                                <mat-option *ngFor="let relation of RelationList" [value]="relation.type">{{
                                    relation.typeName
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="
                            newMemberFormControl.Relation.errors &&
                            newMemberFormControl.Relation.touched
                        " style="color: #ff001f">
                            <div *ngIf="
                                newMemberFormControl.Relation.errors.required
                            ">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12 pt-3">
                <!-- <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center">
                    <div class="col-lg-5 col-md-5 col-sm-6">
                        <label class="add-member-lbl">{{
                            "SME.MEMBERID" | translate
                            }}&nbsp; <span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-6">
                        <input (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                         [maxLength]="10" id="MemberId" [value]="AddMemberForm.controls['IqamaId'].value" name="MemberId" formControlName="MemberId" [ngClass]="{
                            'red-border-class':
                                newMemberFormControl.MemberId.errors &&
                                newMemberFormControl.MemberId.touched
                        }" class="form-control sme-quoation-text" placeholder="{{
                            'SME.MEMBERID_PLACEHOLDER' | translate
                        }}" type="text" [disabled]="true" />
                        <div *ngIf="
                            newMemberFormControl.MemberId.errors &&
                            newMemberFormControl.MemberId.touched
                        ">
                            <div *ngIf="
                                newMemberFormControl.MemberId.errors.required
                            " style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="
                                newMemberFormControl?.MemberId.errors.pattern
                            " style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "SME.ONLYNUMBERS" | translate }}
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center">
                    <div class="col-lg-5 col-md-5 col-sm-6">
                        <label class="add-member-lbl">{{
                            "SME.MOBILE" | translate
                            }}&nbsp; <span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-6">
                        <input (keypress)="($event.charCode >= 48 && $event.charCode < 58)" id="MobileNumber"
                            name="MobileNumber" formControlName="MobileNumber" [maxLength]="10" [ngClass]="{
                            'red-border-class':
                                newMemberFormControl.MobileNumber.errors &&
                                newMemberFormControl.MobileNumber.touched
                        }" class="form-control sme-quoation-text" placeholder="{{
                            'SME.COMPANY_MOBILE_PLACEHOLDER' | translate
                        }}" type="text" />
                        <div *ngIf="
                            newMemberFormControl.MobileNumber.errors &&
                            newMemberFormControl.MobileNumber.touched
                        ">
                            <div *ngIf="
                                newMemberFormControl.MobileNumber.errors
                                    .required
                            " style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="
                                newMemberFormControl?.MobileNumber.errors
                                    .pattern
                            " style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "SME.ONLYNUMBERS" | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center"
                    style="margin-left: 5%;margin-right:5%">
                    <div class="col-lg-5 col-md-5 col-sm-6">
                        <label class="add-member-lbl">{{
                            "SME.CLASS" | translate
                            }}&nbsp; <span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-6">
                        <mat-form-field appearance="none" class="sme-select">
                            <mat-select placeholder="{{ 'SME.CHOOSECLASS' | translate }}" formControlName="ClassType">
                                <mat-option *ngFor="let class of SMEClasses" [value]="class.id">{{ class.name
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="
                            newMemberFormControl.ClassType.errors &&
                            newMemberFormControl.ClassType.touched
                        " style="color: #ff001f">
                            <div *ngIf="
                                newMemberFormControl.ClassType.errors.required
                            ">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12 pt-3">

                <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center">
                    <div class="col-lg-5 col-md-5 col-sm-6">
                        <label class="add-member-lbl">{{
                            "SME.MARITAL" | translate
                            }}&nbsp; <span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-6">
                        <mat-form-field appearance="none" class="sme-select">
                            <mat-select placeholder="{{
                                'SME.MARITAL_STATUS_PLACEHOLDER' | translate
                            }}" formControlName="MaritalStatus">
                                <mat-option *ngFor="let status of MaritalStatusList" [value]="status.Code">{{
                                    status.Name
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="
                            newMemberFormControl.MaritalStatus.errors &&
                            newMemberFormControl.MaritalStatus.touched
                        " style="color: #ff001f">
                            <div *ngIf="
                                newMemberFormControl.MaritalStatus.errors
                                    .required
                            ">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center"
                    style="margin-left: 5%;margin-right:5%">
                    <div class="col-lg-5 col-md-5 col-sm-6">
                        <label class="add-member-lbl">{{
                            "SME.BD" | translate
                            }}&nbsp; <span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-6">
                        <mat-form-field appearance="none" class="sme-select" style="padding: 1% 6%">
                            <input matInput [matDatepicker]="picker" placeholder="{{
                                'SME.BIRTH_DATE_PLACEHOLDER' | translate
                            }}" [max]="today" (keypress)="($event.charCode >= 48 && $event.charCode < 58) 
                                            || ($event.charCode == 47) || ($event.charCode == 45)"
                                formControlName="BirthDate"
                                [ngClass]="{'margin-ar': translate.currentLang === 'ar-SA'}" />
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="
                            newMemberFormControl.BirthDate.errors &&
                            newMemberFormControl.BirthDate.touched
                        " style="color: #ff001f">
                            <div *ngIf="
                                newMemberFormControl.BirthDate.errors.required
                            ">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12 pt-3">
                <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center">
                    <div class="col-lg-5 col-md-5 col-sm-12">
                        <label class="add-member-lbl">{{
                            "SME.HEIGHT" | translate
                            }} &nbsp; <span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <input (keypress)="($event.charCode >= 48 && $event.charCode < 58) || $event.charCode == 46"
                            [maxLength]="4" id="Height" name="Height" formControlName="Height" [ngClass]="{
                            'red-border-class':
                                newMemberFormControl.Height.errors &&
                                newMemberFormControl.Height.touched
                        }" class="form-control sme-quoation-text" placeholder="{{
                            'SME.HEIGHT_PLACEHOLDER' | translate
                        }}" type="text" (focus)="ClearValidation()" />
                        <div *ngIf="
                            newMemberFormControl.Height.errors &&
                            newMemberFormControl.Height.touched
                        ">
                            <div *ngIf="newMemberFormControl.Height.errors.required" style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="newMemberFormControl?.Height.errors.pattern" style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "SME.ONLYNUMBERS" | translate }}
                            </div>
                            <div *ngIf="newMemberFormControl?.Height.errors.max" style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "SME.MAX_HEIGHT" | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center"
                    style="margin-left: 5%;margin-right:5%">
                    <div class="col-lg-5 col-md-5 col-sm-12">
                        <label class="add-member-lbl">{{
                            "SME.WEIGHT" | translate
                            }}&nbsp; <span style="color:red">*</span></label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <input (keypress)="($event.charCode >= 48 && $event.charCode < 58)" [maxLength]="3" id="Weight"
                            name="Weight" formControlName="Weight" [ngClass]="{
                            'red-border-class':
                                newMemberFormControl.Weight.errors &&
                                newMemberFormControl.Weight.touched
                        }" class="form-control sme-quoation-text" placeholder="{{
                            'SME.WEIGHT_PLACEHOLDER' | translate
                        }}" type="text" (focus)="ClearValidation()" />
                        <div *ngIf="
                            newMemberFormControl.Weight.errors &&
                            newMemberFormControl.Weight.touched
                        ">
                            <div *ngIf="newMemberFormControl.Weight.errors.required" style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="newMemberFormControl?.Weight.errors.pattern" style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "SME.ONLYNUMBERS" | translate }}
                            </div>
                            <div *ngIf="newMemberFormControl?.Weight.errors.max" style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "SME.MAX_WEIGHT" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12 pt-3">
                <div class="d-flex flex-row col-lg-5 col-md-12 col-sm-12 align-items-center">
                    <div class="col-lg-5 col-md-5 col-sm-6">
                        <label class="add-member-lbl">{{
                            "SME.EXCSPONSOR" | translate
                            }}</label>
                        <label class="add-member-lbl" *ngIf="this.translate.currentLang === 'ar-SA' && 
                            isShowSponsor2
                            ">
                            {{
                            "SME.EXCSPONSOR_2" | translate
                            }}
                        </label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-6">
                        <input (keypress)="($event.charCode >= 48 && $event.charCode < 58)" id="ExceptionSponsor"
                            name="ExceptionSponsor" formControlName="ExceptionSponsor" [ngClass]="{
                            'red-border-class':
                                newMemberFormControl.ExceptionSponsor.errors &&
                                newMemberFormControl.ExceptionSponsor.touched
                        }" class="form-control sme-quoation-text" placeholder="{{
                            'SME.EXCPSPONSOR_PLACEHOLDER' | translate
                        }}" type="text" [maxLength]="10" />
                        <div *ngIf="
                            newMemberFormControl.ExceptionSponsor.errors &&
                            newMemberFormControl.ExceptionSponsor.touched
                        ">
                            <div *ngIf="
                                newMemberFormControl.ExceptionSponsor.errors
                                    .required
                            " style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "GENERIC.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="
                                newMemberFormControl?.ExceptionSponsor.errors
                                    .pattern
                            " style="color: #ff001f">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                {{ "SME.ONLYNUMBERS" | translate }}
                            </div>
                        </div>
                    </div>
                </div>


            </div>



            <div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12 pt-1 justify-content-center"
                *ngIf="isErrorOccured">
                <span style="color: #ff001f"><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    {{ ErrorMessage }}</span>
            </div>


            <div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12 pt-1">
                <span 
                    *ngIf="translate.currentLang === 'en-US'">
                     <span style="color:red">*</span>&nbsp;
                    Filling Wrong Information of weight and height will result
                    in refusal to cover the cost of obesity surgery.
                </span>

                <span 
                    *ngIf="translate.currentLang === 'ar-SA'">
                    <span style="color:red">*</span>&nbsp;
                    في حالة عدم تعبئة معلومات الوزن والطول بشكل صحيح، سيؤدي ذلك إلى رفض تغطية تكاليف جراحة السمنة
                </span>
            </div>

            <div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12 pt-2 justify-content-center">
                <button class="sme-save-btn mt-5" [ngClass]="{ 'disabled-btn': !AddMemberForm.valid }"
                    [disabled]="isLoading || !AddMemberForm.valid" (click)="AddMember()">
                    <span *ngIf="!isLoading">{{ "SME.ADD" | translate }}</span>
                    <span *ngIf="isLoading">
                        <div class="spinner-border text-warning"></div>
                    </span>
                </button>
            </div>
        </form>
    </div>
</div>