import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    STEPPER_GLOBAL_OPTIONS,
    StepperOrientation,
} from '@angular/cdk/stepper';
import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { eventNames } from 'process';
import { Observable, Subscription, interval, map } from 'rxjs';
import { MmpService } from 'src/app/core/services/MMP-Service/mmp.service';
import Swal from 'sweetalert2';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { DatePipe } from '@angular/common';
import { AppConfigService } from 'src/app/core/AppConfigService';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';
import { MatDialog } from '@angular/material/dialog';
import { OTPService } from 'src/app/core/services/eservice-otp.service';
import { OtpVerificationDialogComponent } from '../../OTPVerification/otp-verification-dialog/otp-verification-dialog.component';

@Component({
    selector: 'app-purchase-policy',
    templateUrl: './purchase-policy.component.html',
    styleUrls: ['./purchase-policy.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ],
})
export class PurchasePolicyComponent implements OnInit, OnDestroy {
    languageValue = localStorage.getItem('language');
    @ViewChild('stepper') private myStepper: MatStepper;
    @ViewChildren('input') vc;

    idForm: FormGroup;
    personalDetailsForm: FormGroup;
    CoverageAndDocsForm: FormGroup;
    PaymentForm: FormGroup;
    @ViewChild('myFirstButton') myFirstButton: ElementRef;
    @ViewChild('hiddenFormDataPayment') hiddenForm!: ElementRef;

    mobilePattern = '^(05)([0-9]{8})$';
    IqamaPattern = /[12]+[0-9]{9}$/;
    birthDatePatternD = '^(0[1-9]|1|1[0-9]|2|2[0-9]|3|3[0]|4|5|6|7|8|9)$';
    birthDatePatternM = '^(0[1-9]|1|1[0-2]|4|5|6|7|8|9)$';
    birthDatePatternY = '^([1-9][0-9]{3})$';
    promoCodePattern = '^[A-Za-z0-9]+$';
    sponserPattern = /[127]+[0-9]{9}$/;

    emailPattern =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    IBANpattern = '^([0-9]{22})$';
    faXmark = faXmark;
    maxDate: any = new Date();
    today: Date;
    minPolicyDate: any;
    minRatroActiveDate: any;

    maxPolicyDate: any;
    maxExpiryDate: any;

    smallScreen: boolean;
    isSubmit: boolean = false;
    defaultClassFlag: boolean = true;
    personalFormFlag = null;
    otherFlag = false;
    memDetails: any;
    stepperOrientation: Observable<StepperOrientation>;
    isLoading = true;
    Load = true;

    claimLimitData: claimLimitDataObj[] = [];
    totalLimitData = [];
    claimAndTotalLimitData = [];
    DocsList: any;
    mandatoryDocs = [];
    ImgsId: any[] = [];
    nationalImgsList: any[] = [];

    professionCategories: any[];
    professionSubCategories: any[];
    policyPeriods: any[];

    prevPolicyData: any = [];
    insuranceCompanies: any = [];
    questions: any = [];

    qutationResponce: any;
    includeObjectCoverage: any = [];
    questionsAnswers: any = [];
    selectedMember: any;
    memberDetails: any;
    memberBillingDetails: any;
    newF: HTMLFormElement;
    includeObjectPreview: IncludeInterface[] = [];
    retrievedObject = JSON.parse(localStorage.getItem('idObject'));
    policyConfig: any;
    billingCondtion: any;

    trxId: Number;

    paymentStatus: any;
    paymentLinkEn: any;
    paymentLinkAR: any;
    paymentReport: any;

    showPromo: any = true;

    timeRemaining: string = 'Calculating...';
    private timerSubscription: Subscription;

    paymentDone = null;
    paymentObjectRedirect = null;
    loggedInMobileNumber: any = '';
    loggedInIqamaId: any = '';
    get idFormControl() {
        return this.idForm.controls;
    }
    get personalDetailsControl() {
        return this.personalDetailsForm.controls;
    }
    get CoverageAndDocsFormControl() {
        return this.CoverageAndDocsForm.controls;
    }

    get PaymentFormControl() {
        return this.PaymentForm.controls;
    }

    constructor(
        public translate: TranslateService,
        public fb: FormBuilder,
        public _router: Router,
        private breakpointObserver: BreakpointObserver,
        private mmpService: MmpService,
        private _LocalService: LocalService,
        public medicalClaimsService: MedicalClaimsService,
        private renderer: Renderer2,
        private datePipe: DatePipe,
        private environment: AppConfigService,
        private gtmService: GoogleTagManagerService,
        private otpService: OTPService,
        private _dialog: MatDialog,
    ) {
        this.showPromo = environment?.config?.ShowPromo;
        this.retrievedObject = JSON.parse(localStorage.getItem('idObject'));

        this.languageValue = localStorage.getItem('language');
        // breakpointObserver
        //     .observe([Breakpoints.XSmall, Breakpoints.Small])
        //     .subscribe((result) => {
        //         this.smallScreen = result.matches;
        //     });

        this.stepperOrientation = breakpointObserver
            .observe('(min-width: 800px)')
            .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

        this.memDetails = {};
        this.memberDetails = {};

        this.isLoading = true;
        this.qutationResponce = this.qutationResponce = {
            trxId: '',
            quotationCode: '',
            quotationMessage: '',
            trxExpiryDate: '',
            insuredIqamaId: '',
            trxSourceChannel: '',
            insuredName: '',
            hasPrevInsurance: '',
            planCode: '',
            prodCode: '',
            policyNumber: '',
            policyPeriod: {
                id: '',
                name: '',
            },
            policyDateFrom: '',
            policyDateTo: '',
            policyPremium: '',
            policyFee: '',
            policyVatAmount: '',
            policyDiscount: '',
            policyTotalPremium: '',

            calcStatus: '',

            trxPaymentType: {
                id: '',
                name: '',
            },
            risks: [
                {
                    riskId: '',
                    insuredIqamaId: '',
                    insuredNameAr: '',
                    insuredNameEn: '',
                    insuredMobileNumber: '',
                    insuredEmail: '',
                    insuredIban: '',
                    insuredYakeenTrxId: '',
                    professionCategoryCode: {
                        id: '',
                        name: '',
                    },
                    professionCode: {
                        id: '',
                        name: '',
                    },
                    promotionCode: '',
                    licenseNumber: '',
                    licenseExpiryDate: '',
                    policyNumber: '',
                    policyPremium: '',
                    policyDeductable: '',
                    basicRate: '',
                    retroactiveType: '',
                    ratingId: '',
                    policyAggregate: '',
                    policyCategoryExcess: '',
                    insuredNationalAddressId: '',
                },
            ],
        };
        this.memDetails = this._LocalService.getJsonValue('memberDetails');
        if (this.memDetails) {
            this.selectedMember = this.memDetails[0];
        }
    }

    // Utlization

    download(input) {
        const gtmTag = {
            event: 'page',
            pageName: 'Payment Successfully',
        };
        this.gtmService.pushTag(gtmTag);
        if (input === 'en') {
            window.open(this.paymentLinkEn, '_blank');
        } else if (input === 'report') {
            window.open(this.paymentReport, '_blank');
        } else {
            window.open(this.paymentLinkAR, '_blank');
        }
    }

    ngOnInit(): void {
        if (this.translate.currentLang == 'en-US') {
            this.timeRemaining = 'Calculating...';
        } else {
            this.timeRemaining = 'جاري الحساب...';
        }
        this.billingCondtion = false;
        localStorage.removeItem('EligableForIDVerfication');

        this.memDetails = this._LocalService.getJsonValue('memberDetails');
        if (this.memDetails) {
            this.selectedMember = this.memDetails[0];
        }
        this.retrievedObject = JSON.parse(localStorage.getItem('idObject'));
        this.maxDate.setDate(this.maxDate.getDate() - 1);
        this.buildIDFormForm();
        this.buildPersonalDetailsForm();
        this.buildCoverageAndDocsForm();
        this.today = new Date();
        // this.GetDocs();
        //this.GetCategoryLimits();
        //this.GetIncludeDocs();
        this.minPolicyDate = new Date(
            new Date().setDate(new Date().getDate() + 1)
        );

        //get lookups
        // this.getProfessionCategories();
        this.getProfessionSubCatebogry();
        this.getPolicyPeriodData();
        this.buildPaymentForm();
    }

    policyIsPaid:any = false;

    async ngAfterViewInit() {
        this.displayTheCovers(null);
        this.isLoading = true;
        this.vc.first.nativeElement.focus();
        let userActivePolicy = await this.getActivePolicy();
        if (userActivePolicy != null) {
            if (this.translate.currentLang == 'en-US') {
                this.ErrDialogPolicy('You already have an active policy');
            } else {
                this.ErrDialogPolicy('لديك بالفعل وثيقة سارية');
            }
        } else {
            let ActiveQutationRes = await this.getActiveQutation();
            debugger;
            this.trxId = ActiveQutationRes?.trxId;
            this.policyConfig = await this.getPolicyConfig();
            let result = await this.getPaymentStatus(this.trxId);
            if(result?.content.toLowerCase() === 'success' &&result?.enLink!=null &&result?.arLink!=null &&result?.policyNumber!=null){
                this.policyIsPaid = true
            }
            else{
                this.policyIsPaid = false;
            }
            this.minPolicyDate = new Date(
                new Date().setDate(new Date().getDate() + 1)
            );
            this.minRatroActiveDate = new Date(
                new Date().setDate(new Date().getDate() - 7)
            );
            if (this.policyConfig?.policyRetroActiveDate <= 0) {
                this.minRatroActiveDate = new Date(
                    new Date().setDate(new Date().getDate() - 1000000)
                );
            } else {
                this.minRatroActiveDate = new Date(
                    new Date().setDate(
                        new Date().getDate() -
                        this.policyConfig?.policyRetroActiveDate
                    )
                );
            }

            if (this.policyConfig?.policyStartLimit <= 0) {
                this.maxPolicyDate = new Date(
                    new Date().setDate(new Date().getDate() + 10000000)
                );
            } else {
                this.maxPolicyDate = new Date(
                    new Date().setDate(
                        new Date().getDate() +
                        this.policyConfig?.policyStartLimit
                    )
                );
            }

            if (this.policyConfig?.prevInsExpiryLimit <= 0) {
                this.maxExpiryDate = new Date(
                    new Date().setDate(new Date().getDate() + 1000000)
                );
            } else {
                this.maxExpiryDate = new Date(
                    new Date().setDate(
                        new Date().getDate() +
                        this.policyConfig?.prevInsExpiryLimit
                    )
                );
            }

            let citizeInfochaced = JSON.parse(
                localStorage.getItem('CitizenInfo')
            );

            this.scrollTop();
            this.retrievedObject = JSON.parse(localStorage.getItem('idObject'));
            debugger;
            if (
                this.retrievedObject &&
                ActiveQutationRes !== null &&
                this.idForm.controls['Iqama']?.value != null &&
                this.idForm.controls['MobileNumber']?.value != null
            ) {
                this.qutationResponce = ActiveQutationRes;

                this.myStepper.next();
                this.myStepper.next();
                // this.myStepper.next();
                this.startTimer();
                if (
                    this.qutationResponce?.calcStatus === 'UW' ||
                    this.qutationResponce.policyTotalPremium === 0
                ) {
                    this.isLoading = false;
                    let msg = '';
                    let dir: string = '';
                    if (this.translate.currentLang == 'en-US') {
                        dir = 'ltr';
                        msg = `Your request ${this.qutationResponce.trxId} is under review. You will receive SMS once it got finished. Thank you for choosing GulfUnion Al Ahliya.`;
                    } else {
                        dir = 'rtl';
                        msg = `طلبك قيد الدراسة سيتم ارسال رسالة نصية قصيرة حال الانتهاء من دراسة طلبك رقم ${this.qutationResponce.trxId}`;
                    }
                    Swal.fire({
                        title:
                            this.translate.currentLang == 'en-US'
                                ? 'Please Note !'
                                : '!يرجى الملاحظة ',
                        iconHtml:
                            '<img width=40 height=40 style="margin-top:10px;" src="assets/img/swal-icons-img/check.png">',
                        html:
                            "<div style='color:#333333; direction:" +
                            dir +
                            "'>" +
                            msg +
                            '</div>',
                        confirmButtonText:
                            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                        customClass: {
                            icon: 'submit-claim-success-icon-img',
                            title: 'submit-claim-success-swal-title',
                            confirmButton: 'submit-claim-success-confirm-btn',
                        },
                    });
                }
                // this.getBillingData();

                this.isLoading = false;
            } else {
                if (
                    this.retrievedObject &&
                    localStorage.getItem('phNumberVerification') === 'true' &&
                    ActiveQutationRes === null
                ) {
                    this.idForm.controls['Iqama'].setValue(
                        this.retrievedObject?.Iqama
                    );
                    this.idForm.controls['MobileNumber'].setValue(
                        this.retrievedObject?.MobileNumber
                    );
                    this.idForm.controls['BirthDateDay'].setValue(
                        this.retrievedObject?.BirthDateDay
                    );
                    this.idForm.controls['BirthDateMonth'].setValue(
                        this.retrievedObject?.BirthDateMonth
                    );
                    this.idForm.controls['BirthDateYear'].setValue(
                        this.retrievedObject?.BirthDateYear
                    );
                    this.idForm.controls['SponsorNumber'].setValue(
                        this.retrievedObject?.SponsorNumber
                    );
                    this.getQuestionsData();

                    this.memberDetails = {
                        id: this.idForm.controls['Iqama'].value,
                        memberEName: citizeInfochaced?.memberEName,
                        birthDate: citizeInfochaced?.birthDate,
                        nationality: citizeInfochaced?.nationality
                            ? citizeInfochaced?.nationality
                            : 'SA',
                        YakeenTrxId: citizeInfochaced?.YakeenTrxId,
                        gender: citizeInfochaced?.gender,
                        nameAR: citizeInfochaced?.nameAR,
                        nameEN: citizeInfochaced?.nameEN,
                    };

                    if (this.memberDetails !== null) {
                        this.myStepper.next();
                    }

                    localStorage.removeItem('idObject');
                    localStorage.removeItem('phNumberVerification');
                    this.isLoading = false;
                } else if (
                    this.retrievedObject &&
                    localStorage.getItem('phNumberVerification') === 'true' &&
                    ActiveQutationRes !== null
                ) {
                    this.idForm.controls['Iqama'].setValue(
                        this.retrievedObject?.Iqama
                    );
                    this.idForm.controls['MobileNumber'].setValue(
                        this.retrievedObject?.MobileNumber
                    );
                    this.idForm.controls['BirthDateDay'].setValue(
                        this.retrievedObject?.BirthDateDay
                    );
                    this.idForm.controls['BirthDateMonth'].setValue(
                        this.retrievedObject?.BirthDateMonth
                    );
                    this.idForm.controls['BirthDateYear'].setValue(
                        this.retrievedObject?.BirthDateYear
                    );
                    this.idForm.controls['SponsorNumber'].setValue(
                        this.retrievedObject?.SponsorNumber
                    );
                    // this.getBillingData();

                    this.memberDetails = {
                        id: this.idForm.controls['Iqama'].value,
                        memberEName: citizeInfochaced?.memberEName,
                        birthDate: citizeInfochaced?.birthDate,
                        nationality: citizeInfochaced?.nationality
                            ? citizeInfochaced?.nationality
                            : 'SA',
                        YakeenTrxId: citizeInfochaced?.YakeenTrxId,
                        gender: citizeInfochaced?.gender,
                        nameAR: citizeInfochaced?.nameAR,
                        nameEN: citizeInfochaced?.nameEN,
                    };
                    this.getQuestionsData();

                    localStorage.removeItem('idObject');
                    localStorage.removeItem('phNumberVerification');
                    this.qutationResponce = ActiveQutationRes;
                    if (
                        this.qutationResponce?.calcStatus === 'UW' ||
                        this.qutationResponce.policyTotalPremium === 0
                    ) {
                        this.isLoading = false;
                        let msg = '';
                        let dir: string = '';
                        if (this.translate.currentLang == 'en-US') {
                            dir = 'ltr';
                            msg = `Your request ${this.qutationResponce.trxId} is under review. You will receive SMS once it got finished. Thank you for choosing GulfUnion Al Ahliya.`;
                        } else {
                            dir = 'rtl';
                            msg = `طلبك قيد الدراسة سيتم ارسال رسالة نصية قصيرة حال الانتهاء من دراسة طلبك رقم ${this.qutationResponce.trxId}`;
                        }
                        Swal.fire({
                            title:
                                this.translate.currentLang == 'en-US'
                                    ? 'Please Note !'
                                    : '!يرجى الملاحظة ',
                            iconHtml:
                                '<img width=40 height=40 style="margin-top:10px;" src="assets/img/swal-icons-img/check.png">',
                            html:
                                "<div style='color:#333333; direction:" +
                                dir +
                                "'>" +
                                msg +
                                '</div>',
                            confirmButtonText:
                                this.translate.currentLang == 'en-US'
                                    ? 'OK'
                                    : 'تم',
                            customClass: {
                                icon: 'submit-claim-success-icon-img',
                                title: 'submit-claim-success-swal-title',
                                confirmButton:
                                    'submit-claim-success-confirm-btn',
                            },
                        });
                    }
                    if (this.qutationResponce && this.memberDetails) {
                        this.myStepper.next();
                        this.myStepper.next();
                        // this.myStepper.next();
                    }
                    this.startTimer();

                    this.isLoading = false;
                }
            }
            // this.getQuestionsData();

            //  this.myStepper.next();
            // this.myStepper.next();
            //  this.myStepper.next();
            //  this.myStepper.next();
            this.Load = false;
            this.isLoading = false;
        }
    }
    private startTimer() {
        const interval$ = interval(1000); // Update every second
        this.timerSubscription = interval$.subscribe(() => {
            this.timeRemaining = this.calculateTimeRemaining();
        });
    }

    calculateTimeRemaining() {
        if (this?.qutationResponce?.quotationExpiryDate) {
            const currentDate = new Date();
            const expiryDate = new Date(
                this?.qutationResponce?.quotationExpiryDate
            );

            // Calculate the time remaining in milliseconds
            const timeRemainingMs =
                expiryDate.getTime() - currentDate.getTime();

            // If the time has expired, stop the timer
            if (timeRemainingMs <= 0) {
                this.timerSubscription.unsubscribe();
                return 'Time expired';
            }

            // Convert the time remaining to a human-readable format
            return (this.timeRemaining = this.msToTime(timeRemainingMs));
            //this.secondsRemaining = Math.floor((timeRemainingMs / 1000) % 60);
        }
        if (this.translate.currentLang == 'en-US') {
            return 'Calculating...';
        } else {
            return 'جاري الحساب...';
        }
        // Convert the time remaining to a human-readable format
    }

    // Helper function to convert milliseconds to a formatted time string
    msToTime(duration: number): string {
        const seconds = Math.floor((duration / 1000) % 60);
        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        //const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        const days = Math.floor(duration / (1000 * 60 * 60));
        if (this.translate.currentLang == 'en-US') {
            return `${days} : ${minutes}  : ${seconds}  `;
        } else {
            return `${seconds} : ${minutes} : ${days} `;
        }
    }

    ErrDialogPolicy(msg) {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        Swal.fire({
            title:
                this.translate.currentLang == 'en-US' ? 'Warning!' : '!تحذير',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#156538; direction:" +
                dir +
                "'>" +
                msg +
                '</div>',
            confirmButtonColor: '#156538',
            confirmButtonText:
                this.translate.currentLang == 'en-US'
                    ? 'Policy Details'
                    : 'تفاصيل الوثيقة',
            customClass: {
                icon: 'active-policy-icon-img',
                title: 'active-policy-swal-title',
                confirmButton: 'active-policy-confirm-btn',
            },
        }).then((res) => {
            if (res.isConfirmed)
                this._router.navigate(['/medical-malpractice-inquiry']);
        });
    }

    ErrDialog(msg) {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        Swal.fire({
            title: this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#FF001F; direction:" +
                dir +
                "'>" +
                msg +
                '</div>',
            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
                icon: 'submit-claim-failed-icon-img',
                title: 'submit-claim-failed-swal-title',
                confirmButton: 'submit-claim-failed-confirm-btn',
            },
        });
    }
    ////////////////////////////////////

    onExpiryDateChange() {
        if (
            this.personalDetailsForm.controls['expiryDate'].value >= new Date()
        ) {
            this.minPolicyDate;
            const expiryDateValue =
                this.personalDetailsForm.controls['expiryDate'].value;
            this.minPolicyDate = new Date(
                expiryDateValue.setDate(expiryDateValue.getDate() + 1)
            );
            new Date(expiryDateValue.setDate(expiryDateValue.getDate() - 1));
        } else {
            this.minPolicyDate = new Date(
                new Date().setDate(new Date().getDate() + 1)
            );
        }
        this.personalDetailsForm.controls['Ratification'].setValue(null);
        this.personalDetailsForm.controls['policyStartDate'].setValue(null);
        const dateOnly: Date = new Date(
            this.personalDetailsForm.controls['expiryDate'].value.getFullYear(),
            this.personalDetailsForm.controls['expiryDate'].value.getMonth(),
            this.personalDetailsForm.controls['expiryDate'].value.getDate()
        );
        //        this.personalDetailsControl.policyStartDate.setValue(dateOnly);
    }

    async getPolicyConfig(Iqama = this.idForm.controls['Iqama'].value) {
        try {
            const res = await this.mmpService
                .GetpolicyConfig()

                .toPromise();

            return res;
        } catch (err) {
            console.log(err);
            return 30;
        }
        return 30;
    }

    async checkQutationLimit(Iqama = this.idForm.controls['Iqama'].value) {
        try {
            var query = `?insuredIqamaId=${this.idForm.controls['Iqama'].value}`;
            const res = await this.mmpService
                .GetQuotationLimit(query)
                .toPromise();

            return res;
        } catch (err) {
            console.log(err);
            return true;
        }
    }

    // id Vervication Part
    buildIDFormForm() {
        this.idForm = this.fb.group({
            Iqama: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.IqamaPattern),
                    Validators.maxLength(100),
                ],
            ],
            MobileNumber: [
                null,
                [Validators.required, Validators.pattern(this.mobilePattern)],
            ],
            BirthDateDay: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.birthDatePatternD),
                ],
            ],
            BirthDateMonth: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.birthDatePatternM),
                ],
            ],
            BirthDateYear: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.birthDatePatternY),
                ],
            ],
            SponsorNumber: [null],
            save: [false, [Validators.required]],

            //BirthDateY: [null, [Validators.required ,Validators.pattern(this.birthDatePatternM) ]],
        });
    }

    isSA: any = true;
    handleIqamaNumberChange(e) {
        if (e.target.value.charAt(0) == 1) {
            this.isSA = true;

            this.idFormControl.BirthDateDay.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternD),
            ]);
            this.idFormControl.BirthDateDay.updateValueAndValidity();

            this.idFormControl.BirthDateMonth.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternM),
            ]);
            this.idFormControl.BirthDateMonth.updateValueAndValidity();

            this.idFormControl.BirthDateYear.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternY),
            ]);
            this.idFormControl.BirthDateYear.updateValueAndValidity();

            this.idFormControl.SponsorNumber.clearValidators();
            this.idFormControl.SponsorNumber.updateValueAndValidity();
        } else if (e.target.value.charAt(0) == 2) {
            this.isSA = false;
            this.idFormControl.SponsorNumber.setValidators([
                Validators.required,
                Validators.pattern(this.sponserPattern),
            ]);
            this.idFormControl.SponsorNumber.updateValueAndValidity();

            this.idFormControl.BirthDateDay.clearValidators();
            this.idFormControl.BirthDateDay.updateValueAndValidity();

            this.idFormControl.BirthDateMonth.clearValidators();
            this.idFormControl.BirthDateMonth.updateValueAndValidity();

            this.idFormControl.BirthDateYear.clearValidators();
            this.idFormControl.BirthDateYear.updateValueAndValidity();
        } else {
            this.isSA = true;

            this.idFormControl.BirthDateDay.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternD),
            ]);
            this.idFormControl.BirthDateDay.updateValueAndValidity();

            this.idFormControl.BirthDateMonth.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternM),
            ]);
            this.idFormControl.BirthDateMonth.updateValueAndValidity();

            this.idFormControl.BirthDateYear.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternY),
            ]);
            this.idFormControl.BirthDateYear.updateValueAndValidity();

            this.idFormControl.SponsorNumber.clearValidators();
            this.idFormControl.SponsorNumber.updateValueAndValidity();
        }
    }
    handleBirthDateDayAndMonthFormat(value) {
        switch (value) {
            case '1':
                return '01';
            case '2':
                return '02';
            case '3':
                return '03';
            case '4':
                return '04';
            case '5':
                return '05';
            case '6':
                return '06';
            case '7':
                return '07';
            case '8':
                return '08';
            case '9':
                return '09';

            default:
                return value;
        }
    }

    async getCitizenInfo() {
        try {
            this.isLoading = true;
            let birthDay = this.handleBirthDateDayAndMonthFormat(
                this.idForm.controls['BirthDateDay'].value ??
                this.retrievedObject?.BirthDateDay
            );
            let birthMonth = this.handleBirthDateDayAndMonthFormat(
                this.idForm.controls['BirthDateMonth'].value ??
                this.retrievedObject?.BirthDateMonth
            );
            let birthDate = `${birthMonth}-${this.idForm.controls['BirthDateYear'].value ??
                this.retrievedObject?.BirthDateYear
                }`;
            let queryString;
            if (
                this.idForm.controls['SponsorNumber'].value ||
                this.retrievedObject?.SponsorNumber
            ) {
                queryString = `identityNumber=${this.idForm.controls['Iqama'].value ??
                    this.retrievedObject?.Iqama
                    }&MobilePhone=${this.idForm.controls['MobileNumber'].value ??
                    this.retrievedObject?.MobileNumber
                    }&sponsor=${this.idForm.controls['SponsorNumber'].value ??
                    this.retrievedObject?.SponsorNumber
                    }`;
            } else {
                queryString = `identityNumber=${this.idForm.controls['Iqama'].value ??
                    this.retrievedObject?.Iqama
                    }&MobilePhone=${this.idForm.controls['MobileNumber'].value ??
                    this.retrievedObject?.MobileNumber
                    }&dateOfBirth=${birthDate ?? this.retrievedObject?.BirthDate}`;
            }

            const res = await this.mmpService
                .GetCitizenInfoURLService(queryString)
                .toPromise();

            if (res) {
                debugger;
                if (
                    res !== null &&
                    res?.data !== null &&
                    res?.errors.length == 0 &&
                    (res?.data.birthDate !== null ||
                        res?.data.birthDate !== undefined ||
                        birthDate) &&
                    (res?.data?.fullName !== null ||
                        res?.data.birthDate !== undefined ||
                        res?.data.birthDate !== '')
                ) {
                    this.memberDetails = {
                        id: this.idForm.controls['Iqama'].value,
                        memberEName: res?.data.fullName,
                        birthDate: res?.data.birthDate
                            ? res?.data.birthDate
                            : `${this.handleBirthDateDayAndMonthFormat(
                                this.idForm.controls['BirthDateDay'].value
                            )}` +
                            '-' +
                            birthDate,
                        nationality: res?.data.nationalityCode
                            ? res?.data.nationalityCode
                            : 'SA',
                        YakeenTrxId: res?.data.yakeenTrxId,
                        nameAR: res?.data.fullNameAr,
                        nameEN: res?.data.fullNameEn,
                    };
                    if (
                        (res?.data.gender.toLowerCase() === 'm' ||
                            res?.data.gender.toLowerCase() === 'male') &&
                        this.translate.currentLang == 'en-US'
                    ) {
                        this.memberDetails = {
                            ...this.memberDetails,
                            gender: 'Male',
                        };
                    } else if (
                        (res?.data.gender.toLowerCase() === 'f' ||
                            res?.data.gender.toLowerCase() === 'female') &&
                        this.translate.currentLang == 'en-US'
                    ) {
                        this.memberDetails = {
                            ...this.memberDetails,
                            gender: 'Female',
                        };
                    } else if (
                        (res?.data.gender.toLowerCase() === 'm' ||
                            res?.data.gender.toLowerCase() === 'male') &&
                        this.translate.currentLang == 'ar-SA'
                    ) {
                        this.memberDetails = {
                            ...this.memberDetails,
                            gender: 'ذكر',
                        };
                    } else {
                        this.memberDetails = {
                            ...this.memberDetails,
                            gender: 'انثي',
                        };
                    }
                } else {
                    this.memberDetails = null;

                    if (res?.errors.length > 0) {
                        let msg;

                        for (
                            let index = 0;
                            index < res?.errors?.length;
                            index++
                        ) {
                            if (res?.errors?.error) {
                                msg += res?.errors?.error;
                                msg += '<br/>';
                            }
                        }
                        this.ErrDialog(msg);
                    } else if (res?.code == -1) {
                        this.ErrDialog(res?.message);
                    } else {
                        let msg = '';
                        if (this.translate.currentLang == 'en-US') {
                            msg = 'Error Getting User Data.';
                        } else {
                            msg = 'حدث خطأ أثناء الحصول على بيانات المستخدم';
                        }

                        this.ErrDialog(msg);
                        this.isLoading = false;
                    }
                }
                localStorage.setItem(
                    'CitizenInfo',
                    JSON.stringify(this.memberDetails)
                );
                return res;
            }
        } catch (err) {
            this.memberDetails = null;

            if (err?.code == -1) {
                this.ErrDialog(err?.message);
            } else if (err?.length > 0) {
                let msg = '';

                for (let index = 0; index < err?.length; index++) {
                    if (err[index]?.error) {
                        msg += `-` + err[index]?.error;
                        msg += '<br/>';
                    }
                }
                this.ErrDialog(msg);
            } else {
                let msg = '';
                if (this.translate.currentLang == 'en-US') {
                    msg = 'Error Getting User Data.';
                } else {
                    msg = 'حدث خطأ أثناء الحصول على بيانات المستخدم';
                }

                this.ErrDialog(msg);
                this.isLoading = false;
            }
        }
        this.isLoading = false;
        return null;
    }

    async submitIDVer(stepper: MatStepper) {
        this.isSubmit = true;
        if (
            this.idForm.valid &&
            (this.isSA ||
                (!this.isSA &&
                    this.idForm.controls['SponsorNumber'].value !== null &&
                    this.idForm.controls['SponsorNumber'].value !== ''))
        ) {
            const qutationLimit = await this.checkQutationLimit();

            if (qutationLimit === true) {
                this.isSubmit = false;
                this.personalDetailsForm.reset();
                this.buildPersonalDetailsForm();
                let idObject = {
                    Iqama: this.idForm.controls['Iqama']?.value,
                    MobileNumber: this.idForm.controls['MobileNumber']?.value,
                    BirthDateDay: this.idForm.controls['BirthDateDay']?.value,
                    BirthDateMonth:
                        this.idForm.controls['BirthDateMonth']?.value,
                    BirthDateYear: this.idForm.controls['BirthDateYear']?.value,
                    BirthDate: this.idForm.controls['birthDate']?.value,
                    SponsorNumber: this.idForm.controls['SponsorNumber']?.value,
                };
                localStorage.setItem('idObject', JSON.stringify(idObject));
                localStorage.setItem('EligableForIDVerfication', 'true');
                try {
                    this._LocalService.setJsonValue('mmpIqamaId', this.idForm.controls['Iqama']?.value.toString());
                    this._LocalService.setJsonValue('mmpMobileNumber', this.idForm.controls['MobileNumber']?.value.toString());

                    let query =
                        'iqamaNumber=' +
                        this.idForm.controls['Iqama']?.value +
                        '&mobileNumber=' +
                        this.idForm.controls['MobileNumber']?.value +
                        '&isWeb=' + true +
                        '&Lob=' + LineOfBusiness.MedicalMalPractice +
                        '&Type=' + SMSActionType.Login;;
                    let otpRes = await this.mmpService
                        .GetGenarateOTP(query)
                        .toPromise();

                    this._router.navigate([`/id-verification`]);
                } catch (err) {
                    this._router.navigate([`/id-verification`]);
                }
            } else {
                if (qutationLimit?.errors?.length > 0) {
                    let msg = '';

                    for (
                        let index = 0;
                        index < qutationLimit?.errors?.length;
                        index++
                    ) {
                        if (qutationLimit?.errors[index]?.error) {
                            msg += '-' + qutationLimit?.errors[index]?.error;
                            msg += '<br/>';
                        }
                    }
                    this.ErrDialog(msg);
                }
            }
            //stepper.next();
        } else {
            let errorMessage: string = '';
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }
            let index: number = 1;
            if (this.idForm.controls['Iqama'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Id / Iqama is required!<br/>'
                        : index +
                        '- رقم الهوية الوطنية / رقم الإقامة يجب ان يبدأ برقم 1 او 2!<br/>';
                index++;
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Id / Iqama should start with 1 or 2!<br/>'
                        : index +
                        '- رقم الهوية الوطنية / رقم الإقامة يجب ان يبدأ برقم 1 او 2!<br/>';
                index++;
            } else if (this.idForm.controls['Iqama'].status === 'INVALID') {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Id / Iqama should start with 1 or 2!<br/>'
                        : index +
                        '- رقم الهوية الوطنية / رقم الإقامة يجب ان يبدأ برقم 1 او 2!<br/>';
                index++;
            }

            if (this.idForm.controls['MobileNumber'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Mobile Number is required!<br/>'
                        : index + '- رقم الهاتف مطلوب!<br/>';
                index++;
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Mobile Number should start with two letters then numbers!<br/>'
                        : index + '- رقم الهاتف يجب ان يبدأ 05!<br/>';
                index++;
            } else if (
                this.idForm.controls['MobileNumber'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Mobile Number should start with two letters then numbers!<br/>'
                        : index + '- رقم الهاتف يجب ان يبدأ 05 !<br/>';
                index++;
            }

            if (
                this.isSA &&
                (this.idForm.controls['BirthDateDay'].value == null ||
                    this.idForm.controls['BirthDateDay'].value == '')
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Birth Day is required!<br/>'
                        : index + '- يوم الميلاد مطلوب!<br/>';
                index++;
            } else if (
                this.idForm.controls['BirthDateDay'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Birth Day should start with 0 or 1 or 2 or 3 and max value is 30!<br/>'
                        : index +
                        '- يوم الميلاد يجب ان يبدأ ب 0 او 1 او 2 او 3 واكبر قيمة 30!<br/>';
                index++;
            }

            if (
                this.isSA &&
                (this.idForm.controls['BirthDateMonth'].value == null ||
                    this.idForm.controls['BirthDateMonth'].value == '')
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Birth Month is required!<br/>'
                        : index + '- شهر الميلاد مطلوب!<br/>';
                index++;
            } else if (
                this.idForm.controls['BirthDateMonth'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Birth Month should start with 0 or 1 and max value is 12!<br/>'
                        : index +
                        '- شهر الميلاد يجب ان يبدأ ب 0 او 1 واكبر قيمة 12 !<br/>';
                index++;
            }

            if (
                this.isSA &&
                (this.idForm.controls['BirthDateYear'].value == null ||
                    this.idForm.controls['BirthDateYear'].value == '')
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Birth Year is required!<br/>'
                        : index + '- سنة الميلاد مطلوب!<br/>';
                index++;
            } else if (
                this.idForm.controls['BirthDateYear'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Invalid Year Format !<br/>'
                        : index + '- تنسيق السنة غير صالح!<br/>';
                index++;
            }

            if (
                !this.isSA &&
                (this.idForm.controls['SponsorNumber'].value === null ||
                    this.idForm.controls['SponsorNumber'].value === '')
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Sponsor Number is required!<br/>'
                        : index + '- رقم الكفيل / المنشأه مطلوب!<br/>';
                index++;
            } else if (
                this.idForm.controls['SponsorNumber'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Sponsor Number should start with 1 or 2 or 7!<br/>'
                        : index +
                        '-   رقم الكفيل / المنشأه يجب ان يبدأ برقم 1 او 2 او 7  <br/>';
                index++;
            }

            this.ErrDialog(errorMessage);
        }
        this.scrollTop();
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }
    ///////////////////////////////////////////

    // personal Deatials part
    buildPersonalDetailsForm() {
        this.personalDetailsForm = this.fb.group({
            Profession: [null, Validators.required],
            ProfessionSubCategory: [null, Validators.required],
            haveMMP: [false, Validators.required],
            promotionCode: [null, Validators.pattern(this.promoCodePattern)],
            questions: [[]],
            policyHolder: [null],
            insurance: [null],
            expiryDate: [null],
            policyStartDate: [null, Validators.required],
            coveragePeriod: [null, Validators.required],
            Ratification: [null],
        });
    }
    changeHaveMMPPersonalForm(input) {
        this.personalDetailsForm.controls['haveMMP'].setValue(input);
        if (this.personalDetailsForm.controls['haveMMP'].value === true) {
            this.getPrevPolicyData(this.idForm.controls['Iqama'].value);
            this.getInsuranceCompaniesData();

            this.personalDetailsControl.policyHolder.setValidators([
                Validators.required,
            ]);
            this.personalDetailsControl.policyHolder.updateValueAndValidity();

            this.personalDetailsControl.insurance.setValidators([
                Validators.required,
            ]);
            this.personalDetailsControl.insurance.updateValueAndValidity();

            this.personalDetailsControl.expiryDate.setValidators([
                Validators.required,
            ]);
            this.personalDetailsControl.expiryDate.updateValueAndValidity();

            this.personalDetailsControl.Ratification.setValidators([
                Validators.required,
            ]);
            this.personalDetailsControl.Ratification.updateValueAndValidity();
        } else {
            this.personalFormFlag = null;
            this.personalDetailsForm.controls['policyHolder'].markAsUntouched();
            this.personalDetailsForm.controls['insurance'].markAsUntouched();
            this.personalDetailsForm.controls['expiryDate'].markAsUntouched();
            this.personalDetailsForm.controls['Ratification'].markAsUntouched();

            this.personalDetailsForm.controls['policyHolder'].setValue(null);
            this.personalDetailsForm.controls['insurance'].setValue(null);
            this.personalDetailsForm.controls['expiryDate'].setValue(null);
            this.personalDetailsForm.controls['Ratification'].setValue(null);

            this.personalDetailsControl.policyHolder.clearValidators();
            this.personalDetailsControl.policyHolder.updateValueAndValidity();

            this.personalDetailsControl.insurance.clearValidators();
            this.personalDetailsControl.insurance.updateValueAndValidity();

            this.personalDetailsControl.expiryDate.clearValidators();
            this.personalDetailsControl.expiryDate.updateValueAndValidity();

            this.personalDetailsControl.Ratification.clearValidators();
            this.personalDetailsControl.Ratification.updateValueAndValidity();
        }
    }

    changeQuestionFlag(event, question) {
        let arr = this.questionsAnswers;
        if (event) {
            question['visible'] = true;
            arr.push(question);
        } else {
            question['visible'] = false;
            const index = arr.indexOf(question);
            if (index > -1) {
                arr.splice(index, 1);
                this.questionsAnswers = arr;
            }
        }
    }

    handleQuestionText(event, question) {
        this.questionsAnswers.forEach((q) => {
            if (question?.id == q?.id) q['text'] = event.target.value;
        });
    }

    getProfessionCategories() {
        this.isLoading = true;

        this.mmpService.GetProfessionCategoriesService().subscribe(
            (res) => {
                this.professionCategories = res;
                this.isLoading = false;
            },
            (err) => {
                console.log(err);
                this.isLoading = false;
            }
        );
    }

    changeProfessionCategory(selectedProfessionCategory) {
        this.isLoading = true;
        // this.getProfessionSubCatebogry()
    }
    ddlLoader: any = false;
    getProfessionSubCatebogry() {
        this.ddlLoader = true;
        this.mmpService.GetProfessionSubCategoriesService('').subscribe(
            (res) => {
                this.professionSubCategories = res;
                this.ddlLoader = false;
            },
            (err) => {
                console.log(err);
                this.ddlLoader = false;
            }
        );
    }

    changeProfessionSubCategory(subCategory) {
        this.isLoading = true;
        this.personalDetailsForm.controls['Profession'].setValue(
            subCategory?.profCategory
        );
        this.personalDetailsForm.controls['ProfessionSubCategory'].setValue(
            subCategory?.id
        );
        this.isLoading = false;
    }

    changeCoveragePeriod() {
        this.GetCategoryLimits();
    }

    getPolicyPeriodData() {
        this.isLoading = true;

        this.mmpService.GetPolicyPeriodService().subscribe(
            (res) => {
                this.policyPeriods = res;
                this.isLoading = false;
            },
            (err) => {
                console.log(err);

                this.isLoading = false;
            }
        );
    }

    getPrevPolicyData(iqamaNo) {
        this.isLoading = true;
        this.mmpService.GetPrevPolicyDataService(iqamaNo).subscribe(
            (res) => {
                this.prevPolicyData = res;
                this.isLoading = false;
                if (res.length > 0) {
                    this.personalDetailsForm.controls['policyHolder'].setValue(
                        res[0]?.policyNumber
                    );
                    this.personalDetailsForm.controls['insurance'].setValue(0);
                    this.personalDetailsForm.controls['expiryDate'].setValue(
                        res[0].dateTo
                    );
                    this.personalDetailsForm.controls['Ratification'].setValue(
                        res[0].dateFrom
                    );
                    this.onExpiryDateChange();

                    this.personalFormFlag = true;
                } else {
                    this.personalFormFlag = false;
                }
            },
            (err) => {
                console.log(err);
                this.personalFormFlag = false;

                this.isLoading = false;
            }
        );
    }

    getInsuranceCompaniesData() {
        this.isLoading = true;

        this.mmpService.GetInsuranceCompaniesService().subscribe(
            (res) => {
                this.insuranceCompanies = res;
            },
            (err) => {
                console.log(err);
                this.isLoading = false;
            }
        );
    }

    getQuestionsData() {
        this.isLoading = true;

        this.mmpService.GetQuestionsService().subscribe(
            (res) => {
                this.questions = res;
                this.isLoading = false;
            },
            (err) => {
                console.log(err);
                this.isLoading = false;
            }
        );
    }

    submitPersonalDetails() {
        this.isSubmit = true;
        let notValidQuestions = [];
        this.questionsAnswers.forEach((q) => {
            if (q?.visible && q?.id && q?.text == undefined) {
                notValidQuestions.push(q?.name);
            }
        });
        //        var date = this.personalDetailsForm.controls['policyStartDate'].value.getFullYear()+'/'+(this.personalDetailsForm.controls['policyStartDate'].value.getMonth()+1)+'/'+this.personalDetailsForm.controls['policyStartDate'].value.getDate();

        if (this.personalDetailsForm.valid && notValidQuestions.length === 0) {
            this.isSubmit = false;
            // this.CoverageAndDocsForm.reset();
            // stepper.next();
            // this.GetCategoryLimits();
            // this.GetDocs();
            // this.buildCoverageAndDocsForm();
            this.includeObjectCoverage = [];
            const gtmTag = {
                event: 'page',
                pageName:
                    'Submit Information First Page Successfully Completed',
            };
            this.gtmService.pushTag(gtmTag);
        } else {
            let errorMessage: string = '';
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }

            let index: number = 1;
            if (this.personalDetailsForm.controls['Profession'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Profession Category is required!<br/>'
                        : index + '- فئة المهنة مطلوب!<br/>';
                index++;
            }

            if (
                this.personalDetailsForm.controls['ProfessionSubCategory']
                    .value == null
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Profession SubCategory is required!<br/>'
                        : index + '- الفئة الفرعية المهنية مطلوب!<br/>';
                index++;
            }

            if (
                this.personalDetailsForm.controls['policyStartDate'].value ==
                null
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- policy Start Date is required!<br/>'
                        : index + '- تاريخ بداية الوثية مطلوب!<br/>';
                index++;
            }

            if (
                this.personalDetailsForm.controls['coveragePeriod'].value ==
                null
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Coverage Period is required!<br/>'
                        : index + '- فترة التغطية مطلوب!<br/>';
                index++;
            }

            if (
                this.personalDetailsForm.controls['policyHolder'].value ==
                null &&
                this.personalDetailsForm.controls['haveMMP'].value === true
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- policy Holder is required!<br/>'
                        : index + '- مالك الوثيقة مطلوب!<br/>';
                index++;
            }

            if (
                this.personalDetailsForm.controls['insurance'].value == null &&
                this.personalDetailsForm.controls['haveMMP'].value === true
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Insurance company is required!<br/>'
                        : index + '- شركة التأمين مطلوب!<br/>';
                index++;
            }

            if (
                this.personalDetailsForm.controls['expiryDate'].value == null &&
                this.personalDetailsForm.controls['haveMMP'].value === true
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- expiry Date is required!<br/>'
                        : index + '- تاريخ الانتهاء مطلوب!<br/>';
                index++;
            }
            if (
                this.personalDetailsForm.controls['Ratification'].value ==
                null &&
                this.personalDetailsForm.controls['haveMMP'].value === true
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Ratification Date is required!<br/>'
                        : index + '- تاريخ التديق مطلوب!<br/>';
                index++;
            }

            if (notValidQuestions.length > 0) {
                notValidQuestions.forEach((q) => {
                    errorMessage +=
                        this.translate.currentLang == 'en-US'
                            ? index +
                            '-  Please fill opened question text!<br/>' +
                            q +
                            '<br/>'
                            : index + '- من فضلك أدخل إجابة علي السؤال!<br/>';
                    index++;
                });
            }

            this.ErrDialog(errorMessage);
        }
    }
    ///////////////////////////////////////////

    // coverage And Docs part
    buildCoverageAndDocsForm() {
        this.CoverageAndDocsForm = this.fb.group({
            claimLimit: [null, [Validators.required]],
            totalAnnualLimit: [null, [Validators.required]],
            Includequestion: [false],
            ratingId: [null],
            basicRate: [null],
            save: [false, [Validators.required]],
            //BirthDateY: [null, [Validators.required ,Validators.pattern(this.birthDatePatternM) ]],
        });
        this.CoverageAndDocsForm.controls['Includequestion'].setValue(false);
    }
    async submitCovergaeDocsForm(stepper: MatStepper) {
        this.submitPersonalDetails();

        this.isSubmit = true;

        if (this.CoverageAndDocsForm.valid) {
            // const userData = await this.getCitizenInfo();
            // if (
            //     userData !== null &&
            //     userData?.data !== null &&
            //     !userData?.errors
            // ) {
            //     localStorage.setItem(
            //         'CitizenInfo',
            //         JSON.stringify(this.memberDetails)
            //     );
            // }
            this.isLoading = true;
            this.isSubmit = false;
            //this.personalDetailsForm.reset();

            this.isSubmit = false;
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }
            let errorMesg;

            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
                errorMesg = 'Do You Want To Save This Qutation ? ';
            } else {
                dir = 'rtl';
                errorMesg =
                    'هل ترغب في حفظ عرض السعر علما بأن مدة صلاحية عرض السعر ستكون 3أيام ؟';
            }
            Swal.fire({
                title:
                    this.translate.currentLang == 'en-US'
                        ? 'Warning !'
                        : '!تحذير ',
                iconHtml:
                    '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                html:
                    "<div style='color:#FF001F; direction:" +
                    dir +
                    "'>" +
                    errorMesg +
                    '</div>',
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonColor: '#36d446',
                cancelButtonColor: '#FF001F',

                confirmButtonText:
                    this.translate.currentLang == 'en-US' ? 'YES' : 'نعم',
                cancelButtonText:
                    this.translate.currentLang == 'en-US' ? 'NO' : 'لا',
                customClass: {
                    icon: 'submit-claim-failed-icon-img',
                    title: 'submit-claim-failed-swal-title',
                    actions: 'actions',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.saveFinalForm();
                    this.scrollTop();
                    // this.billingCondtion = true;
                    // this.getBillingData();
                } else {
                    this.scrollTop();
                }
            });

            //stepper.next();
        } else {
            let errorMessage: string = '';
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }
            let index: number = 1;
            if (this.CoverageAndDocsForm.controls['claimLimit'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Claim Limit is required!<br/>'
                        : index + '- حدود المطالبة مطلوب!<br/>';
                index++;
            }

            if (
                this.CoverageAndDocsForm.controls['totalAnnualLimit'].value ==
                null
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Total Annual Limit is required!<br/>'
                        : index + '- إجمالي الحد السنوي مطلوب!<br/>';
                index++;
                this.scrollTop();
            }

            if (!this.docsValidation()) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Upload the required files  !<br/>'
                        : index + '- الرجاء تحميل الملفات المطلوبة!<br/>';
                index++;
            }
            this.scrollTop();

            this.ErrDialog(errorMessage);
        }
        this.isLoading = false;
        this.scrollTop();
    }

    GetCategoryLimits() {
        this.isLoading = true;
        this.claimAndTotalLimitData = [];
        let queryString =
            'profCat=' +
            this.personalDetailsForm.controls['Profession'].value +
            '&policyPeriod=' +
            this.personalDetailsForm.controls['coveragePeriod'].value;
        this.mmpService.CategoryLimits(queryString).subscribe(
            (res) => {
                for (let index = 0; index < res?.length; index++) {
                    this.claimAndTotalLimitData.push(res[index]);
                }

                this.claimLimitData = res.reduce((unique, o) => {
                    if (!unique.some((obj) => obj.excess === o.excess)) {
                        unique.push(o);
                    }
                    return unique;
                }, []);
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            }
        );
    }
    ChangeClaimLimit() {
        this.CoverageAndDocsForm.controls['totalAnnualLimit'].setValue(null);
        this.totalLimitData = [];
        this.claimAndTotalLimitData.forEach((element) => {
            if (
                element.excess ===
                this.CoverageAndDocsForm.controls['claimLimit'].value
            ) {
                this.totalLimitData.push(element);
            }
        });
    }

    GetDocs() {
        this.isLoading = true;
        this.mandatoryDocs = [];
        this.mmpService.GetDocsService().subscribe(
            (res) => {
                this.DocsList = res;
                for (let index = 0; index < res?.length; index++) {
                    if (res[index]['isMandatory'] === true)
                        this.mandatoryDocs.push(res[index]['id']);
                }
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            }
        );
    }

    displayTheCovers(value) {
        // this.CoverageAndDocsForm.controls['Includequestion'].setValue(value);

        this.includeObjectCoverage = [];
        this.mmpService.GetGetDocumentsService().subscribe(
            (res) => {
                if (
                    !this.CoverageAndDocsForm.controls['Includequestion']?.value
                ) {
                    this.includeObjectCoverage = [];
                } else {
                    for (let index = 0; index < res?.length; index++) {
                        this.includeObjectCoverage.push(res[index]);
                    }
                }
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            }
        );
    }
    setDocsList(event, id, man, name) {
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            const fileName = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.nationalImgsList.push({
                    image: file,
                    id: id,
                    name: fileName,
                    mandatory: man,
                    imgPerview: reader.result,
                    docFileName: name,
                });
                if (!this.ImgsId.includes(id)) {
                    this.ImgsId.push(id);
                }
            };
        }
    }
    DisplayDocument(img: any) {
        Swal.fire({
            title: img.name,
            showCloseButton: true,
            showConfirmButton: false,
            imageUrl: img.imgPerview,
            imageHeight: 300,
            imageAlt: 'A tall image',
        });
    }
    docsValidation() {
        let manLength = this.mandatoryDocs.length;
        let count = 0;
        for (let i = 0; i < this.nationalImgsList.length; i++) {
            if (this.nationalImgsList[i]['id'] === this.mandatoryDocs[0]) {
                count++;
                this.mandatoryDocs.splice(0, 1);
            }
        }
        if (manLength === count) return true;

        return false;
    }
    deleteAtt(x) {
        this.nationalImgsList.splice(x, 1);
    }

    ///////////////////////////////////////////////////

    // Qutation
    finalFormLoader: any = false;
    async saveFinalForm() {
        if (this.personalDetailsForm.valid && this.CoverageAndDocsForm.valid) {
            // const userData = await this.getCitizenInfo();
            // if (
            //     userData !== null &&
            //     userData?.data !== null &&
            //     !userData?.errors
            // ) {
            //     localStorage.setItem(
            //         'CitizenInfo',
            //         JSON.stringify(this.memberDetails)
            //     );
            // }
            this.finalFormLoader = true;
            const formData = new FormData();
            let questionList = [];
            this.questionsAnswers.forEach((element) => {
                questionList.push({
                    QuestionId: element?.id,
                    Answer: element?.visible ? 'Y' : 'N',
                    Notes: element?.text,
                });
            });

            this.claimAndTotalLimitData.forEach((element) => {
                if (
                    element.excess ===
                    this.CoverageAndDocsForm.controls['claimLimit'].value &&
                    element.aggregate ===
                    this.CoverageAndDocsForm.controls['totalAnnualLimit']
                        ?.value
                ) {
                    this.CoverageAndDocsForm.controls['ratingId'].setValue(
                        element?.ratingId
                    );
                    this.CoverageAndDocsForm.controls['basicRate'].setValue(
                        element?.basicRate
                    );
                }
            });

            this.questions.forEach((element) => {
                var found = false;
                for (var i = 0; i < questionList.length; i++) {
                    if (questionList[i].QuestionId === element.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    questionList.push({
                        QuestionId: element?.id,
                        Answer: 'N',
                        Notes: '',
                    });
                }
            });
            let risks = {
                InsuredNationalAddressId:
                    this.memberBillingDetails?.YakeenTrxId,
                InsuredYakeenTrxId: this.memberDetails?.YakeenTrxId,
                InsuredNationality: this.memberDetails?.nationality,
                InsuredGender: this.memberDetails?.gender,
                InsuredNameEn: this.memberDetails?.nameEN,
                InsuredNameAr: this.memberDetails?.nameAR,
                InsuredIqamaId: this.idForm.controls['Iqama']?.value,
                InsuredMobileNumber:
                    this.idForm.controls['MobileNumber']?.value,
                ProfessionCatCode:
                    this.personalDetailsForm.controls['Profession']?.value,
                ProfessionCode:
                    this.personalDetailsForm.controls['ProfessionSubCategory']
                        ?.value,

                ClaimLimit:
                    this.CoverageAndDocsForm.controls['claimLimit']?.value,
                TotalLimit:
                    this.CoverageAndDocsForm.controls['totalAnnualLimit']
                        ?.value,
                QuestionsWithAnswers: questionList,
                RatingId: this.CoverageAndDocsForm.controls['ratingId']?.value,
                BasicRate:
                    this.CoverageAndDocsForm.controls['basicRate']?.value,
                PrevInsurance: [
                    {
                        CompanyCode:
                            this.personalDetailsForm.controls['insurance']
                                ?.value,
                        CompanyPolicy:
                            this.personalDetailsForm.controls['policyHolder']
                                ?.value,
                        CompanyPolicyExpiryDate:
                            this.personalDetailsForm.controls['expiryDate']
                                ?.value,
                        RetroactiveDate:
                            this.personalDetailsForm.controls['Ratification']
                                ?.value,
                    },
                ],
            };
            debugger;
            let data = {
                TrxSourceChannel: 'MMP_WEB',
                InsuredName: this.memberDetails?.memberEName,
                InsuredIqamaId: this.idForm.controls['Iqama']?.value,
                promotionCode:
                    this.personalDetailsForm.controls['promotionCode']?.value,
                HasPrevInsurance:
                    this.personalDetailsForm.controls['haveMMP']?.value,
                PolicyPeriod:
                    this.personalDetailsForm.controls['coveragePeriod']?.value,
                PolicyDateFrom:
                    this.personalDetailsForm.controls['policyStartDate']?.value,
                IncludeOptionCovers:
                    this.CoverageAndDocsForm.controls['Includequestion']?.value,
                Risks: [risks],
            };
            //formData.append('InsuredName ', name);
            //formData.append('TrxPaymentType ', payment);

            formData.append('data', JSON.stringify(data));

            for (let index = 0; index < this.nationalImgsList.length; index++) {
                let newFileName;
                const fileExtension = this.nationalImgsList[index]?.image.name
                    .split('.')
                    .pop();

                if (this.nationalImgsList[index]?.mandatory) {
                    newFileName = `${this.nationalImgsList[index].docFileName}_${index}.${fileExtension}`;
                } else {
                    {
                        newFileName = `${this.nationalImgsList[index].docFileName}_${index}.${fileExtension}`;
                    }
                }
                // Create a new File object with the modified filename
                const modifiedImageFile = new File(
                    [this.nationalImgsList[index]?.image],
                    newFileName
                );
                formData.append('file', modifiedImageFile);
            }

            this.mmpService.SubmitNewQutation(formData).subscribe(
                async (res) => {
                    if (res?.trxId) {
                        // this.qutationResponce = await this.getActiveQutation();
                        this.includeObjectPreview = [];
                        this.qutationResponce = res;
                        this.myStepper.next();
                        //this.GetIncludeDocs();
                        this.startTimer();
                        for (
                            let index = 0;
                            index < res?.policyCovers?.length;
                            index++
                        ) {
                            this.includeObjectPreview.push(
                                res?.policyCovers[index]
                            );
                        }
                        if (
                            this.qutationResponce?.calcStatus === 'UW' ||
                            this.qutationResponce.policyTotalPremium === 0
                        ) {
                            let msg = '';
                            let dir: string = '';
                            if (this.translate.currentLang == 'en-US') {
                                dir = 'ltr';
                                msg = `Your request ${this.qutationResponce.trxId} is under review. You will receive SMS once it got finished. Thank you for choosing GulfUnion Al Ahliya.`;
                            } else {
                                dir = 'rtl';
                                msg = `طلبك قيد الدراسة سيتم ارسال رسالة نصية قصيرة حال الانتهاء من دراسة طلبك رقم ${this.qutationResponce.trxId}`;
                            }
                            Swal.fire({
                                title:
                                    this.translate.currentLang == 'en-US'
                                        ? 'Please Note !'
                                        : '!يرجى الملاحظة ',
                                iconHtml:
                                    '<img width=40 height=40 style="margin-top:10px;" src="assets/img/swal-icons-img/check.png">',
                                html:
                                    "<div style='color:#333333; direction:" +
                                    dir +
                                    "'>" +
                                    msg +
                                    '</div>',
                                confirmButtonText:
                                    this.translate.currentLang == 'en-US'
                                        ? 'OK'
                                        : 'تم',
                                customClass: {
                                    icon: 'submit-claim-success-icon-img',
                                    title: 'submit-claim-success-swal-title',
                                    confirmButton:
                                        'submit-claim-success-confirm-btn',
                                },
                            });
                            this.finalFormLoader = false;
                            const gtmTag = {
                                event: 'page',
                                pageName:
                                    'All Quotation information submitted successfully',
                            };
                            this.gtmService.pushTag(gtmTag);
                        }
                    } else {
                        let dir: string = '';
                        if (this.translate.currentLang == 'en-US') {
                            dir = 'ltr';
                        } else {
                            dir = 'rtl';
                        }
                        this.ErrDialog(res?.errors[0]?.error);
                        this.finalFormLoader = false;
                    }
                    this.finalFormLoader = false;
                },
                (error) => {
                    let ErrMsg = '';

                    if (this.translate.currentLang == 'en-US') {
                        ErrMsg = 'An Error Occurred !';
                    } else {
                        ErrMsg = 'حدث خطأ ما !';
                    }
                    this.ErrDialog(ErrMsg);
                    this.isLoading = false;
                    this.finalFormLoader = false;
                }
            );
        }
    }

    cancelQutation() {
        this.isLoading = false;
        let dir: string = '';
        let errorMesg;

        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
            errorMesg = 'Are You Want To Cancel This Quotation? ';
        } else {
            dir = 'rtl';
            errorMesg = 'هل تريد ألالغاء ؟  ';
        }

        Swal.fire({
            title:
                this.translate.currentLang == 'en-US' ? 'Warning !' : '!تحذير ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#FF001F; direction:" +
                dir +
                "'>" +
                errorMesg +
                '</div>',
            allowOutsideClick: true,
            showCancelButton: true,
            confirmButtonColor: '#FF001F',
            cancelButtonColor: '#FF001F',

            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'YES ' : 'موافق',
            cancelButtonText:
                this.translate.currentLang == 'en-US' ? 'NO ' : 'ارفض',
            customClass: {
                icon: 'submit-claim-failed-icon-img',
                title: 'submit-claim-failed-swal-title',
                actions: 'actions',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                this.isLoading = true;
                this.mmpService
                    .CancelQutation(this.qutationResponce.trxId)
                    .subscribe(
                        (res) => {
                            this.idForm.reset();
                            this.personalDetailsForm.reset();
                            this.CoverageAndDocsForm.reset();
                            this.nationalImgsList = [];
                            this.mandatoryDocs = [];
                            window.location.reload();
                            this.isLoading = false;
                            const gtmTag = {
                                event: 'page',
                                pageName: 'Quotation Cancelled by Client',
                            };
                            this.gtmService.pushTag(gtmTag);
                        },
                        (err) => {
                            this.isLoading = false;
                            let dir: string = '';
                            if (this.translate.currentLang == 'en-US') {
                                dir = 'ltr';
                            } else {
                                dir = 'rtl';
                            }

                            Swal.fire({
                                title:
                                    this.translate.currentLang == 'en-US'
                                        ? 'Error!'
                                        : '!خطأ',
                                iconHtml:
                                    '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                                html:
                                    "<div style='color:#FF001F; direction:" +
                                    dir +
                                    "'>" +
                                    err.title +
                                    '</div>',
                                confirmButtonText:
                                    this.translate.currentLang == 'en-US'
                                        ? 'OK'
                                        : 'تم',
                                customClass: {
                                    icon: 'submit-claim-failed-icon-img',
                                    title: 'submit-claim-failed-swal-title',
                                    confirmButton:
                                        'submit-claim-failed-confirm-btn',
                                },
                            });
                        }
                    );
            } else {
            }
        });
        this.scrollTop();
    }

    goBack() {
        this._router.navigate(['/']);
        this.scrollTop();
    }

    async getActiveQutation(Iqama = this.idForm.controls['Iqama'].value) {
        try {
            Iqama = this.idForm.controls['Iqama'].value
                ? this.idForm.controls['Iqama'].value
                : this.retrievedObject?.Iqama
                    ? this.retrievedObject?.Iqama
                    : this.selectedMember?.id;

            var queryString = '?insuredIqamaId=' + Iqama;
            if (Iqama) {
                const res = await this.mmpService
                    .GetActiveQuotation(queryString)
                    .toPromise();
                for (
                    let index = 0;
                    index < res?.policyCovers?.length;
                    index++
                ) {
                    this.includeObjectPreview.push(res?.policyCovers[index]);
                }
                return res;
            }
        } catch (err) {
            console.log(err);

            if (
                this.idForm.valid &&
                this.personalDetailsForm.valid &&
                this.CoverageAndDocsForm.valid &&
                this.docsValidation()
            ) {
                this.ErrDialog(err.title);
            }
            return null;
        } finally {
            this.isLoading = false; // Set isLoading to false regardless of success or error
        }
    }

    async getActivePolicy() {
        try {
            let Iqama = '';
            Iqama = this.idForm.controls['Iqama'].value
                ? this.idForm.controls['Iqama'].value
                : this.retrievedObject?.Iqama
                    ? this.retrievedObject?.Iqama
                    : this.selectedMember?.id;

            var queryString = '?insuredIqamaId=' + Iqama;
            if (Iqama) {
                const res = await this.mmpService
                    .GetActivePolicy(queryString)
                    .toPromise();
                this.isLoading = false; // Set isLoading to false regardless of success or error
                return res;
            }
        } catch (err) {
            console.log(err);

            if (this.idForm.valid) {
                this.ErrDialog(err.title);
            }
            this.isLoading = false; // Set isLoading to false regardless of success or error
            return null;
        } finally {
            this.isLoading = false; // Set isLoading to false regardless of success or error
        }
    }

    async getPaymentStatus(trxId) {
        try {
            debugger;
            this.isLoading = true;
            if (trxId != null) {
                const res = await this.mmpService
                    .GetpaymentStatus(trxId)
                    .toPromise();
                if (res?.content.toLowerCase() === 'success' &&res?.enLink!=null &&res?.arLink!=null &&res?.policyNumber!=null) {
                    this.paymentStatus = true;
                    this.paymentLinkEn = res?.enLink;
                    this.paymentLinkAR = res?.arLink;
                    this.paymentReport = res?.newReport;
                    const gtmTag = {
                        event: 'page',
                        pageName: 'Payment Successful by client',
                    };
                    this.gtmService.pushTag(gtmTag);
                } else {
                    this.paymentStatus = false;
                    this.paymentLinkEn = null;
                    this.paymentLinkAR = null;
                    this.paymentReport = null;
                }

                return res;
            }
        } catch (err) {
            console.log(err);

            this.paymentStatus = false;
            this.paymentLinkEn = null;
            this.paymentLinkAR = null;
            this.paymentReport = null;

            return null;
        } finally {
            this.isLoading = false; // Set isLoading to false regardless of success or error
        }
    }
    /////////////////////////////////////

    // Payment

    billingLoader: any = false;
    async getBillingData() {
        debugger;
        this.billingLoader = true;
        let birthDay = this.handleBirthDateDayAndMonthFormat(
            this.idForm.controls['BirthDateDay']?.value ??
            this.retrievedObject?.BirthDateDay
        );
        let birthMonth = this.handleBirthDateDayAndMonthFormat(
            this.idForm.controls['BirthDateMonth']?.value ??
            this.retrievedObject?.BirthDateMonth
        );
        let cachedBD = '';
        if (this.retrievedObject?.birthDate != null) {
            cachedBD = this.retrievedObject?.birthDate;
        } else if (
            JSON.parse(localStorage.getItem('CitizenInfo'))?.birthDate != null
        ) {
            cachedBD = JSON.parse(
                localStorage.getItem('CitizenInfo')
            )?.birthDate;
        } else cachedBD = null;
        let birthDate =
            cachedBD != null
                ? cachedBD
                : `${birthDay}-${birthMonth}-${this.idForm.controls['BirthDateYear']?.value ??
                this.retrievedObject?.BirthDateYear
                }`;
        let queryString;
        if (
            this.idForm.controls['SponsorNumber'].value ||
            this.retrievedObject?.SponsorNumber
        ) {
            queryString = `identityNumber=${this.idForm.controls['Iqama']?.value ??
                this.retrievedObject?.Iqama
                }&sponsor=${this.idForm.controls['SponsorNumber']?.value ??
                this.retrievedObject?.SponsorNumber
                }&dateOfBirth=${birthDate}`;
        } else {
            queryString = `identityNumber=${this.idForm.controls['Iqama']?.value ??
                this.retrievedObject?.Iqama
                }&dateOfBirth=${birthDate}`;
        }
        try {

            const res = await this.mmpService.GetBillingInfoURLService(queryString).toPromise();

            if (res) {
                debugger;
                if (
                    res !== null &&
                    res?.responseObject !== null &&
                    res?.responseObject?.addresses.length > 0 &&
                    !res?.errors
                ) {
                    for (
                        let index = 0;
                        index < res?.responseObject?.addresses.length;
                        index++
                    ) {
                        if (
                            res?.responseObject?.addresses[index]
                                .isPrimaryAddress
                        ) {
                            this.memberBillingDetails = {
                                city: res?.responseObject?.addresses[index]
                                    ?.city,
                                street: res?.responseObject?.addresses[
                                    index
                                ]?.streetName
                                    ? res?.responseObject?.addresses[index]
                                        ?.streetName
                                    : res?.responseObject?.addresses[index]
                                        ?.district,
                                district:
                                    res?.responseObject?.addresses[index]
                                        ?.district,
                                buildingNum:
                                    res?.responseObject?.addresses[index]
                                        ?.buildingNumber,
                                postalCode:
                                    res?.responseObject?.addresses[index]
                                        ?.postCode,
                                YakeenTrxId:
                                    res?.responseObject?.yakeenTrxId,
                            };
                        }
                    }
                    // if (this.billingCondtion) {
                    // this.saveFinalForm();
                    // }
                    this.billingLoader = false;
                    this.isLoading = false;
                    this.Load = false;
                    return res;
                } else {
                    if (res?.errors?.length > 0) {
                        let msg = '';

                        for (
                            let index = 0;
                            index < res?.errors?.length;
                            index++
                        ) {
                            if (res?.errors[index]?.error) {
                                msg += '-' + res?.errors[index]?.error;
                                msg += '<br/>';
                            }
                        }
                        this.ErrDialog(msg);
                    } else {
                        let msg = '';
                        if (this.translate.currentLang == 'en-US') {
                            msg = 'Error Getting User Billing Info.';
                        } else {
                            msg =
                                'حدث خطأ أثناء الحصول على معلومات العنوان الوطني .';
                        }

                        this.ErrDialog(msg);
                    }
                    this.billingLoader = false;
                    this.isLoading = false;
                    this.Load = false;
                    return null;
                }
            }
            else {
                let msg = '';
                if (this.translate.currentLang == 'en-US') {
                    msg = 'Error Getting User Billing Info.';
                } else {
                    msg =
                        'حدث خطأ أثناء الحصول على معلومات العنوان الوطني .';
                }

                this.ErrDialog(msg);

                this.billingLoader = false;
                this.isLoading = false;
                this.Load = false;
                return null;
            }

        } catch {
            let msg = '';
            if (this.translate.currentLang == 'en-US') {
                msg = 'Error Getting User Billing Info.';
            } else {
                msg = 'حدث خطأ أثناء الحصول على معلومات العنوان الوطني .';
            }

            this.ErrDialog(msg);
            this.billingLoader = false;
            this.isLoading = false;
            this.Load = false;
            return null;
        }
        this.isLoading = false;
    }

    async proceedToPayment(stepper: MatStepper) {

        if (this.qutationResponce?.policyTotalPremium > 0) {
            const userData = await this.getCitizenInfo();
            const billingData = await this.getBillingData();
            debugger;
            if (
                userData !== null &&
                userData?.data !== null &&
                userData?.errors.length==0 &&
                billingData !== null &&
                billingData?.responseObjectresponseObject!== null &&
                !billingData?.errors
            ) {
                this.buildPaymentForm();
                this.myStepper.next();
            }

            // if (this.billingCondtion) {
            // }
            const gtmTag = {
                event: 'page',
                pageName: 'Client Proceed to Payment',
            };
            this.gtmService.pushTag(gtmTag);
        } else {
            let errorMessage: string = '';
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
                errorMessage = 'Payment amount cannot be zero !';
            } else {
                dir = 'rtl';
                errorMessage = 'قيمة الدفع يجب أن تكون أكبر من صفر ! ';
            }

            this.ErrDialog(errorMessage);
            this.isLoading = false;
            this.Load = false;
            this.billingLoader = false;
        }
        this.scrollTop();
    }

    buildPaymentForm() {
        this.PaymentForm = this.fb.group({
            type: ['cash', Validators.required],
            iban: [
                null,
                [Validators.required, Validators.pattern(this.IBANpattern)],
            ],
            email: [
                null,
                [Validators.required, Validators.pattern(this.emailPattern)],
            ],
            paymentType: [null, [Validators.required]],
        });
    }
    changePaymentType(val) {
        this.PaymentForm.controls['type'].setValue(val);

        if (val === 'cash') {
            this.PaymentFormControl.paymentType.setValidators([
                Validators.required,
            ]);
            this.PaymentFormControl.paymentType.updateValueAndValidity();
        } else {
            this.PaymentFormControl.paymentType.clearValidators();
            this.PaymentFormControl.paymentType.updateValueAndValidity();
        }
    }

    SendOTPAndOpenVerificationDialog() {
        let otpObject = {
            ActionType: SMSActionType.Payment,
            Lob: LineOfBusiness.MedicalMalPractice,
            IqamaId: this._LocalService.getJsonValue('mmpIqamaId'),
            MobileNumber: this._LocalService.getJsonValue('mmpMobileNumber'),
            isWeb: true
        }

        this.isLoading = true;
        this.otpService.sendOTP(otpObject).subscribe((res) => {
            let result = res;
            if (result.errors.length <= 0) {
                const dialogRef = this._dialog.open(OtpVerificationDialogComponent, {
                    data: { otpDetails: result },
                    width: '600px',
                    disableClose: true
                });
                dialogRef.afterClosed().subscribe((data) => {
                    if (data.verified) {
                        this.handleGetPaymentData();
                    }
                });
            }
        });
    }

    async submitPayment() {
        this.isSubmit = true;

        if (
            this.PaymentForm.valid &&
            this.memberBillingDetails.street &&
            this.memberBillingDetails.street !== '' &&
            this.memberBillingDetails.city &&
            this.memberBillingDetails.city !== '' &&
            this.memberBillingDetails.district &&
            this.memberBillingDetails.district !== '' &&
            this.memberBillingDetails.postalCode &&
            this.memberBillingDetails.postalCode !== ''
        ) {
            this.SendOTPAndOpenVerificationDialog();
            const gtmTag = {
                event: 'page',
                pageName: 'Client Submitted Payment Information',
            };
            this.gtmService.pushTag(gtmTag);
        } else {
            let errorMessage: string = '';
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }
            let index: number = 1;
            if (this.PaymentForm.controls['type'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  payment type is required!<br/>'
                        : index + '-  نوع الدفع مطلوب!<br/>';
                index++;
            }

            if (this.PaymentForm?.controls['iban']?.value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  IBAN Number is required!<br/>'
                        : index + '- رقم IBAN  مطلوب!<br/>';
                index++;
            } else if (
                this.PaymentForm.controls['iban']?.status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Invalid IBAN Number !<br/>'
                        : index + '- رقم IBAN  غير صحيح!<br/>';
                index++;
            }
            if (this.PaymentForm.controls['email']?.value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Email Address is required!<br/>'
                        : index + '- البريد إلكتروني مطلوب!<br/>';
                index++;
            } else if (
                this.PaymentForm.controls['email']?.status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Invalid Email Address !<br/>'
                        : index + '- بريد الكتروني غير صحيح!<br/>';
                index++;
            }

            if (
                this.PaymentForm.controls['paymentType']?.value == null &&
                !this.PaymentForm.controls['paymentType'].valid
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Payment Method is required!<br/>'
                        : index + '- طريقة الدفع مطلوب!<br/>';
                index++;
            }

            if (
                !this.memberBillingDetails.street ||
                this.memberBillingDetails.street === ''
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Error while getting user billing info ,  Street info is missing!<br/>'
                        : index +
                        '- حدث خطأ اثناء الحصول علي العنوان الوطني للمستخدم , بيانات الشارع غير موجودة !<br/>';
                index++;
            }

            if (
                !this.memberBillingDetails.city ||
                this.memberBillingDetails.city === ''
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '-  Error while getting user billing info , city info is missing!<br/>'
                        : index +
                        '- حدث خطأ اثناء الحصول علي العنوان الوطني للمستخدم ,  بيانات المدينة غير موجودة!<br/>';
                index++;
            }

            if (
                !this.memberBillingDetails.district ||
                this.memberBillingDetails.district === ''
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Error while getting user billing info , state info is missing!<br/>'
                        : index +
                        '-  حدث خطأ اثناء الحصول علي العنوان الوطني للمستخدم , بيانات الحي غير موجودة!<br/>';
                index++;
            }

            if (
                !this.memberBillingDetails.postalCode ||
                this.memberBillingDetails.postalCode === ''
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Error while getting user billing info , postal Code info is missing!<br/>'
                        : index +
                        '-  حدث خطأ اثناء الحصول علي العنوان الوطني للمستخدم , بيانات الرمز البريدي غير موجودة!<br/>';
                index++;
            }
            this.ErrDialog(errorMessage);
        }
        this.scrollTop();
    }

    async handleGetPaymentData() {
        await this.handlePaymentReqwest();
    }

    async handlePaymentReqwest() {
        this.isLoading = true;
        let langg;
        if (this.translate.currentLang == 'en-US') {
            langg = 'en';
        } else {
            langg = 'ar';
        }
        try {
            let Model = {
                TrxId: this.qutationResponce?.trxId.toString(),
                IqamaNumber: this.qutationResponce?.insuredIqamaId.toString(),
                entityName: this.PaymentForm.controls['paymentType']?.value,
                amount: this.qutationResponce?.policyTotalPremium,
                iban: this.PaymentForm.controls['iban']?.value,
                lang: langg,
                billingDto: {
                    street1: this.memberBillingDetails.street,
                    city: this.memberBillingDetails.city,
                    state: this.memberBillingDetails.district,
                    country: 'SA',
                    postcode: this.memberBillingDetails.postalCode.toString(),
                },
                customerDto: {
                    email: this.PaymentForm.controls['email']?.value,
                    givenName: this.memberDetails.memberEName.split(' ')[0],
                    surname: this.memberDetails.memberEName.split(' ')[1],
                },
            };
            const res = await this.mmpService.GetPaymentData(Model).toPromise();
            if (res?.statusCode >= 300) {
                this.isLoading = false;

                let dir: string = '';
                let ErrMsg = '';

                if (this.translate.currentLang == 'en-US') {
                    dir = 'ltr';
                    ErrMsg = 'An Error Occurred !';
                } else {
                    dir = 'rtl';
                    ErrMsg = 'حدث خطأ ما !';
                }

                this.ErrDialog(ErrMsg);
                this.isLoading = false;
                this.Load = false;
                this.billingLoader = false;
            } else if (res?.statusCode == 200) {
                localStorage.setItem(
                    'method',
                    this.PaymentForm.controls['paymentType']?.value
                );
                localStorage.setItem('trxId', this.qutationResponce?.trxId);

                this.paymentDone = true;
                // this.paymentObjectRedirect = res?.content;
                this.isLoading = false;
                // window.location.href = '';
                window.open(res?.content?.formUrl, '_blank');
                // window.location.href = res?.content?.formUrl;
                // const newTab = window.open();
                // newTab.document.open();
                // newTab.document.write(res?.content);
                // newTab.document.close();
                // let child = window.open('about:blank', 'myChild');
                // child?.document?.write(res?.content);
                // child?.document?.close();
                setTimeout(() => {
                    window.location.href = '';
                }, 5000);
            }
        } catch (err) {
            this.isLoading = false;
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }

            this.ErrDialog(err.title);
        }
    }

    ////////////////////////////////////////////////////////
    ngOnDestroy() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }
}

interface claimLimitDataObj {
    descVal: any;
    basicRate: any;
    excess: any;
    aggregate: any;
    ratingId: any;
}

interface IncludeInterface {
    id: any;
    name: any;
    isMandatory: any;
}
