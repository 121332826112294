<!-- Start Header Area -->
<!-- Start Top Header -->
<div class="container">
    <div class="row align-items-center underlined">
        <div class="col-md-3 desktop-nav logo-nav">
            <a class="navbar-brand" href="{{ websiteURL }} " target="_blank"
                ><img src="assets//img/logo/NewLogo2.png" alt="logo"
            /></a>
        </div>

        <div class="mobile-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <div class="logo">
                        <a href="{{ websiteURL }}" target="_blank"
                            ><img src="assets/img/logo/NewLogo2.png" alt="logo"
                        /></a>
                    </div>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </nav>
            </div>
        </div>

        <div class="col-md-4"></div>
        <div class="col-md-5">
            <div class="d-flex flex-row items-style">
                <div class="d-flex flex-row m-1">
                    <img
                        src="assets/img/logo/phone-call.png"
                        width="40px"
                        class="m-1"
                    />
                    <a
                        style="color: #d4ab36"
                        [ngClass]="{
                            fw: this.translate.currentLang === 'en-US',
                            'fw-ar': this.translate.currentLang === 'ar-SA'
                        }"
                        href="tel:8003040077"
                        target="_blank"
                        class="align-items-center d-flex"
                        >{{ "NAV.Phone-Number" | translate }}</a
                    >
                </div>
                <!-- <div class="d-flex flex-row m-1" *ngIf="currentPath==='0'">
                    <img
                        src="assets/img/logo/login.png"
                        width="20px"
                        height="20px"
                        style="align-self: center"
                        class="m-1"
                    />
                    <a
                        routerLink="/user-login"
                        class="fw align-items-center d-flex"
                        *ngIf="!userVerified"
                    >
                        {{ "NAV.LOGIN" | translate }}
                    </a>
                    <a
                        (click)="logout()"
                        class="fw align-items-center d-flex"
                        style="cursor: pointer"
                        *ngIf="userVerified"
                    >
                        {{ "NAV.logOut" | translate }}
                    </a>
                </div> -->
                <div class="d-flex flex-row m-1 pt-1">
                    <button
                        type="button"
                        class="fw cursor-pointer langBtn"
                        (click)="swichLanguage()"
                    >
                        <img
                            src="assets/img/logo/world.png"
                            width="20px"
                            class="m-1"
                        />
                        {{ "NAV.Lang" | translate }}
                    </button>
                </div>
            </div>
            <!-- <div class="col-md-2 header-col">
               
            </div>
            <div class="col-md-2 header-col">
                <div class="align">
                  
                </div>
            </div>

            <div class="col-md-2 header-col">
                <div class="align-lang">
                    
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- Start Top Header -->

<!-- Start Navbar Area -->
<div class="container mt-2 d-none d-md-block" *ngIf="currentPath==='0'">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div>
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a
                            href="{{ websiteURL }}"
                            target="_blank"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{
                                exact: true
                            }"
                            class="nav-link"
                            >{{ "NAV.HOME" | translate }}
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            href="javascript:void(0)"
                            class="nav-link"
                            [ngClass]="{
                                active: aboutUsButton === true,
                                '': aboutUsButton === false
                            }"
                            >{{ "NAV.about-us" | translate }}
                            <i class="bx bx-chevron-down"></i
                        ></a>

                        <ul
                            class="dropdown-menu"
                            [ngClass]="{
                                'dropdown-menu-en': languageValue === 'en-US',
                                'dropdown-menu-ar': languageValue === 'ar-SA'
                            }"
                        >
                            <li aria-labelledby="navbarDropdown">
                                <a
                                    href="{{ websiteURL }}/about-us"
                                    target="_blank"
                                    [ngClass]="{
                                        'menu-item-eng':
                                            languageValue === 'en-US',
                                        'menu-item-ar':
                                            languageValue === 'ar-SA'
                                    }"
                                >
                                    <span
                                        [ngClass]="{
                                            'menu-eng':
                                                languageValue === 'en-US',
                                            'menu-ar': languageValue === 'ar-SA'
                                        }"
                                    >
                                        {{ "NAV.introduction" | translate }}
                                    </span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="{{
                                        websiteURL
                                    }}/chairman-message;pageSec=chairman-message"
                                    target="_blank"
                                    [ngClass]="{
                                        'menu-item-eng':
                                            languageValue === 'en-US',
                                        'menu-item-ar':
                                            languageValue === 'ar-SA'
                                    }"
                                >
                                    <span
                                        [ngClass]="{
                                            'menu-eng':
                                                languageValue === 'en-US',
                                            'menu-ar': languageValue === 'ar-SA'
                                        }"
                                    >
                                        {{ "NAV.chairman-message" | translate }}
                                    </span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    [ngClass]="{
                                        'menu-item-eng':
                                            languageValue === 'en-US',
                                        'menu-item-ar':
                                            languageValue === 'ar-SA'
                                    }"
                                    href="{{
                                        websiteURL
                                    }}/chairman-message;pageSec=ceo-message"
                                    target="_blank"
                                >
                                    <span
                                        [ngClass]="{
                                            'menu-eng':
                                                languageValue === 'en-US',
                                            'menu-ar': languageValue === 'ar-SA'
                                        }"
                                    >
                                        {{
                                            "chairman-message.ceo-header"
                                                | translate
                                        }}
                                    </span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    [ngClass]="{
                                        'menu-item-eng':
                                            languageValue === 'en-US',
                                        'menu-item-ar':
                                            languageValue === 'ar-SA'
                                    }"
                                    href="{{
                                        websiteURL
                                    }}/chairman-message;pageSec=board-of-directors"
                                    target="_blank"
                                >
                                    <span
                                        [ngClass]="{
                                            'menu-eng':
                                                languageValue === 'en-US',
                                            'menu-ar': languageValue === 'ar-SA'
                                        }"
                                    >
                                        {{
                                            "chairman-message.Directors"
                                                | translate
                                        }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="{{ websiteURL }}/our-products"
                            target="_blank"
                            >{{ "NAV.Commerical-Products" | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            href="{{ websiteURL }}/investor-relations"
                            target="_blank"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{
                                exact: true
                            }"
                            class="nav-link"
                            >{{ "NAV.Investor-Relations" | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="{{ websiteURL }}/news-details"
                            target="_blank"
                            >{{ "NAV.News-Events" | translate }}
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="{{ websiteURL }}/career"
                            target="_blank"
                            >{{ "NAV.Career" | translate }}
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="{{ websiteURL }}/contact-us"
                            target="_blank"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{
                                exact: true
                            }"
                            >{{ "NAV.Contact-Us" | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div
            class="get-quote justify-content-end collapse navbar-collapse responcive-style pt-0"
            id="navbarSupportedContent"
        >
            <!-- <button
                type="button"
                (click)="openEsericeportal()"
                class="default-btn"
                [ngClass]="{
                    'd-en': languageValue === 'en-US',
                    'd-ar': languageValue === 'ar-SA'
                }"
            >
                {{ "NAV.Quote" | translate }}
            </button> -->
        </div>
    </nav>
</div>
<!-- End Navbar Area -->
<!-- End Header Area -->

<div class="container mt-2 d-block d-sm-block d-md-none d-lg-none" *ngIf="currentPath==='0'">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div>
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a
                            href="{{ websiteURL }}"
                            target="_blank"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{
                                exact: true
                            }"
                            class="nav-link"
                            >{{ "NAV.HOME" | translate }}
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            href="javascript:void(0)"
                            target="_blank"
                            class="nav-link"
                            [ngClass]="{
                                active: aboutUsButton === true,
                                '': aboutUsButton === false
                            }"
                            >{{ "NAV.about-us" | translate }}
                            <i class="bx bx-chevron-down"></i
                        ></a>

                        <ul
                            class="dropdown-menu"
                            [ngClass]="{
                                'dropdown-menu-en': languageValue === 'en-US',
                                'dropdown-menu-ar': languageValue === 'ar-SA'
                            }"
                        >
                            <li aria-labelledby="navbarDropdown">
                                <a
                                    href="{{ websiteURL }}/about-us"
                                    target="_blank"
                                    [ngClass]="{
                                        'menu-item-eng':
                                            languageValue === 'en-US',
                                        'menu-item-ar':
                                            languageValue === 'ar-SA'
                                    }"
                                >
                                    <span
                                        [ngClass]="{
                                            'menu-eng':
                                                languageValue === 'en-US',
                                            'menu-ar': languageValue === 'ar-SA'
                                        }"
                                    >
                                        {{ "NAV.introduction" | translate }}
                                    </span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="{{
                                        websiteURL
                                    }}/chairman-message;pageSec=chairman-message"
                                    target="_blank"
                                    [ngClass]="{
                                        'menu-item-eng':
                                            languageValue === 'en-US',
                                        'menu-item-ar':
                                            languageValue === 'ar-SA'
                                    }"
                                >
                                    <span
                                        [ngClass]="{
                                            'menu-eng':
                                                languageValue === 'en-US',
                                            'menu-ar': languageValue === 'ar-SA'
                                        }"
                                    >
                                        {{ "NAV.chairman-message" | translate }}
                                    </span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    [ngClass]="{
                                        'menu-item-eng':
                                            languageValue === 'en-US',
                                        'menu-item-ar':
                                            languageValue === 'ar-SA'
                                    }"
                                    href="{{
                                        websiteURL
                                    }}/chairman-message;pageSec=ceo-message"
                                    target="_blank"
                                >
                                    <span
                                        [ngClass]="{
                                            'menu-eng':
                                                languageValue === 'en-US',
                                            'menu-ar': languageValue === 'ar-SA'
                                        }"
                                    >
                                        {{
                                            "chairman-message.ceo-header"
                                                | translate
                                        }}
                                    </span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    [ngClass]="{
                                        'menu-item-eng':
                                            languageValue === 'en-US',
                                        'menu-item-ar':
                                            languageValue === 'ar-SA'
                                    }"
                                    href="{{
                                        websiteURL
                                    }}/chairman-message;pageSec=board-of-directors"
                                    target="_blank"
                                >
                                    >
                                    <span
                                        [ngClass]="{
                                            'menu-eng':
                                                languageValue === 'en-US',
                                            'menu-ar': languageValue === 'ar-SA'
                                        }"
                                    >
                                        {{
                                            "chairman-message.Directors"
                                                | translate
                                        }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="{{ websiteURL }}/our-products"
                            target="_blank"
                            >{{ "NAV.Commerical-Products" | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            href="{{ websiteURL }}/investor-relations"
                            target="_blank"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{
                                exact: true
                            }"
                            class="nav-link"
                            >{{ "NAV.Investor-Relations" | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="{{ websiteURL }}/news-details"
                            target="_blank"
                            >{{ "NAV.News-Events" | translate }}
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="{{ websiteURL }}/career"
                            target="_blank"
                            >{{ "NAV.Career" | translate }}
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="{{ websiteURL }}/contact-us"
                            target="_blank"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{
                                exact: true
                            }"
                            >{{ "NAV.Contact-Us" | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div
            class="get-quote justify-content-end collapse navbar-collapse responcive-style"
            id="navbarSupportedContent"
        >
            <!-- <button
                type="button"
                (click)="openEsericeportal()"
                class="default-btn"
                [ngClass]="{
                    'd-en': languageValue === 'en-US',
                    'd-ar': languageValue === 'ar-SA'
                }"
            >
                {{ "NAV.Quote" | translate }}
            </button> -->
        </div>
    </nav>
</div>
