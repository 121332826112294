import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancellation-submission',
  templateUrl: './cancellation-submission.component.html',
  styleUrls: ['./cancellation-submission.component.scss'],

})
export class CancellationSubmissionComponent implements OnInit {
    ProductCode: number = 1;
    ProductDetails:any;
  constructor() { }

  ngOnInit(): void {
  }

}
