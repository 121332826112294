<h6 mat-dialog-title style="color: black">
    {{ "LogIn.cancelPolicy" | translate }}
</h6>
<div mat-dialog-content>
    <form [formGroup]="DetailsForm">
        <div class="form-outline mb-4">
            <label class="form-label" for="form2Example11"
                >{{ "LogIn.CancellationReason" | translate }} :</label
            >
            <mat-select
                class="form-control"
                formControlName="cancellationReason"
                multiple="true"
                style="border: 1px solid black"
            >
                <mat-option
                    *ngFor="let reason of cancellationReasons"
                    [value]="
                        translate.currentLang == 'en-US'
                            ? reason.labelEN
                            : reason.labelAR
                    "
                    formControlName="cancellationReason"
                    >{{
                        translate.currentLang == "en-US"
                            ? reason.labelEN
                            : reason.labelAR
                    }}</mat-option
                >
            </mat-select>
            <!-- <input autocomplete="false" type="text" id="form2Example11" required  formControlName="cancellationReason" class="form-control" maxlength="255"
        value="" /> -->
        </div>
        <!-- file -->
        <div
            *ngIf="
                DetailsForm.controls['cancellationReason'].value != undefined &&
                DetailsForm.controls['cancellationReason'].value != null &&
                DetailsForm.controls['cancellationReason'].value != ''
            "
        >
            <div
                *ngFor="
                    let reason of this.DetailsForm.controls[
                        'cancellationReason'
                    ].value
                "
                class="form-outline"
            >
                <input
                    type="file"
                    [id]="reason"
                    required
                    (change)="setCancellationReasonFile($event, reason)"
                    class="hidden"
                    accept="application/pdf, image/*"
                />

                <label [for]="reason" class="custom-file-input upload-lbl">
                    <i
                        class="fa fa-upload"
                        aria-hidden="true"
                        style="color: #d4ab36"
                    ></i>
                    {{ "LogIn.cancellReasonDoc" | translate }}
                    {{ reason }}</label
                >
            </div>
        </div>
        <div>
            <p *ngFor="let file of cancellationFiles">{{ file.name }}</p>
        </div>
        <br />
        <div class="form-outline mb-4">
            <label class="form-label" for="bankNum">IBAN</label>
            <input
                id="bankNum"
                name="bankNum"
                style="text-transform: uppercase; border: 1px solid black"
                type="text"
                maxlength="32"
                required
                formControlName="iban"
                oninput="javascript:  var element = document.getElementById(id);    var regex = /[^SA][^0-9\-]/gi;        element.value = element.value.replace(regex, '');"
                class="form-control input-form-design"
            />
            <br />
            <div
                *ngIf="lengthIban != null && lengthIban != ''"
                style="color: red"
            >
                <strong>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    {{ lengthIban }}
                </strong>
            </div>
        </div>

        <div class="form-outline">
            <input
                type="file"
                [id]="'docs' + 1"
                required
                #myInput
                (change)="setDocsList($event, 1)"
                class="hidden"
                accept="application/pdf, image/*"
                multiple
            />

            <label [for]="'docs' + 1" class="custom-file-input upload-lbl">
                <i
                    class="fa fa-upload"
                    aria-hidden="true"
                    style="color: #d4ab36"
                ></i>
                {{ "LogIn.IbanCopy" | translate }}</label
            >
        </div>
        <div class="form-outline">
            <div class="row" *ngIf="ImgsId.includes(1)">
                <ng-container
                    *ngFor="let img of nationalImgsList; let x = index"
                >
                    <div class="col-md-5 imageContainer" *ngIf="img.id == 1">
                        <div class="col-md-12 row mt-3">
                            <div
                                class="item-container"
                                (click)="DisplayDocument(img)"
                            >
                                <div style="overflow: clip; margin-right: 5%">
                                    {{ img.name }}
                                </div>
                                <div>
                                    <span
                                        class="delete"
                                        (click)="deleteAtt(x)"
                                        *ngIf="img.id == 1"
                                    >
                                        <fa-icon [icon]="faXmark"></fa-icon>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <br />
        <mat-radio-group
            aria-label="Are you aware of any complain or suit that has not been
                             notified to the insurance company?"
            formControlName="hasSuit"
        >
            <p>
                {{ "LogIn.Suits" | translate }}
            </p>

            <mat-radio-button value="1" style="float: inline-start">{{
                "LogIn.Yes" | translate
            }}</mat-radio-button>
            &ensp;&ensp;&ensp;
            <mat-radio-button value="2" style="float: inline-end">{{
                "LogIn.No" | translate
            }}</mat-radio-button>
        </mat-radio-group>
        <br />
        <br />
        <div class="form-outline">
            <label class="form-label" for="form2Example11">{{
                "LogIn.yesdetails" | translate
            }}</label>
            <input
                autocomplete="false"
                type="text"
                id="form2Example11"
                formControlName="notes"
                class="form-control"
                maxlength="255"
                value=""
                style="border: 1px solid black"
            />
        </div>
        <br />
        <div class="form-outline mb-4">
            <label class="form-label" for="form2Example11">{{
                "LogIn.ProposerName" | translate
            }}</label>
            <input
                autocomplete="false"
                type="text"
                id="form2Example11"
                required
                formControlName="proposer"
                class="form-control"
                maxlength="255"
                value=""
                style="border: 1px solid black"
            />
        </div>
        <br />
        <div class="form-outline mb-4">
            <label class="form-label" for="form2Example11">{{
                "LogIn.natproposer" | translate
            }}</label>
            <input
                autocomplete="false"
                type="text"
                id="form2Example11"
                required
                formControlName="proposerId"
                class="form-control"
                maxlength="255"
                value=""
                style="border: 1px solid black"
            />
            <div *ngIf="lengthId != null && lengthId != ''" style="color: red">
                <strong>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    {{ lengthId }}
                </strong>
            </div>
        </div>
        <br />
        <p>
            {{ "LogIn.declarepropose" | translate }}
        </p>
    </form>
</div>
<div mat-dialog-actions class="row">
    <div class="col-md-6">
        <button
            type="button"
            mat-dialog-close
            class="btn btn-rounded"
            style="background-color: darkred; color: white"
        >
            {{ "LogIn.Cancel" | translate }}
        </button>
    </div>
    <div class="col-md-6" style="float: right">
        <button
            type="button"
            class="btn btn-rounded"
            style="background-color: #226439; color: white; float: inline-end"
            (click)="SendOTPAndOpenVerificationDialog()"
        >
            {{ "LogIn.cancelPolicy" | translate }}
        </button>
    </div>
</div>
