import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SmeClassSelectionDialogComponent } from '../sme-class-selection-dialog/sme-class-selection-dialog.component';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
    nationalities = new FormControl('');
    classes = new FormControl('');
    isLoading = true;
    SMENationalities = [];
    SMEClasses = [];
    SMEAges = [];
    selectedClasses = [];
    CompanyDetails: any;
    tabIndex = 0;
    quotationNumber = 0;
    premiumData: any;
    CompanyAddress: any;
    fastCalcDisabled: boolean = false;
    CreatePolicyDisabled: boolean = false;
    CreateQuotationDisabled: boolean = false;
    quotationDetailsDisabled: boolean = false;
    Disable3Tabs: boolean = false;
    quotationDetailsTab: boolean = false;
    arLink: string;
    enLink: string;
    newReport: string;
    companyName: any;


    constructor(
        public dialog: MatDialog,
        private smeService: MedicalSMEService,
        private location: Location,
        public translate: TranslateService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        const currentUrl = this.location.path();

        let index = 0;
        this.route.queryParams.subscribe(params => {
            index = params['tabindex'];
          });

        if (currentUrl.includes('tabindex')) {
            if (index == 4) {
                this.GetRouteData();
                this.fastCalcDisabled = true;
                this.CreateQuotationDisabled = true;
                this.quotationDetailsDisabled = true;
                this.tabIndex = 3;
            }
            else {
                this.tabIndex = 2;
                this.GetRouteData();
                let data = {
                    Endto: 0,
                    QuotationNo: localStorage.getItem('quotationNumber'),
                }
                this.smeService.GetPremiumData(data).subscribe(
                    (premRes) => {
                        if (premRes != null) {
                            this.isLoading = false;

                            if (premRes.premiumData != null) {
                                this.premiumData = premRes.premiumData;
                                if (this.premiumData.totalVat != null &&
                                    this.premiumData.totalPremWithoutVat != null &&
                                    this.premiumData.totalPremWithVat != null) {

                                    let quotNumber = localStorage.getItem('quotationNumber');
                                    this.premiumData = this.premiumData;
                                    this.quotationNumber = Number(quotNumber);
                                    localStorage.removeItem('premiumData');
                                    localStorage.setItem('premiumData', this.premiumData);
                                }
                            }
                        }
                    },
                    (err) => {
                        this.isLoading = false;
                    }
                );

            }
        }
        else {
            this.GetRouteData();
            this.getQtnAndPolicyNo();
            this.GetSMENationalities();
            this.GetSMEClasses();
        }
        // this.getAcceptedMembers();


    }
    getAcceptedMembers() {

        let reqData = {
            QuotationNo: this.quotationNumber,
            Endto: 0
        }
        this.smeService.getAccptedmembersCount(reqData).subscribe(
            (res) => {
                if (res != null) {
                    if (res == "false")
                        this.quotationDetailsTab = true;
                    else
                        this.quotationDetailsTab = false;


                }
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            })
    }
    getQtnAndPolicyNo() {
        this.isLoading = true;
        let reqData = {
            companyId: `${this.CompanyDetails.crEntityNumber}`,
            mobileNumber: '',
            emailAddress: '',
        };
        localStorage.removeItem("quotationNumber");
        this.smeService.sponserHasQtn(reqData).subscribe(
            (res) => {
                if (res != null) {
                    if (res.quotationNo > 0 && res.policyId == 0) {
                        this.fastCalcDisabled = false;
                        this.CreateQuotationDisabled = false;
                        this.quotationDetailsDisabled = true;
                        this.CreatePolicyDisabled = true;
                        this.quotationNumber = res.quotationNo;
                        localStorage.setItem("quotationNumber", this.quotationNumber.toString());
                        this.CreatePolicyDisabled = true;
                        this.tabIndex = 1
                    }
                    else if (res.policyId > 0) {
                        //this.getPolicyReports(res.policyId, res.quotationNo);
                        localStorage.setItem("quotationNumber", res.quotationNo);
                        localStorage.setItem("policyId", res.policyId.toString());
                        this.fastCalcDisabled = true;
                        this.CreateQuotationDisabled = true;
                        this.quotationDetailsDisabled = true;
                        this.CreatePolicyDisabled = false;
                        this.tabIndex = 3;
                    }
                    else {
                        this.quotationDetailsDisabled = true;
                        this.CreatePolicyDisabled = true;
                        this.CreateQuotationDisabled = false;
                        this.fastCalcDisabled = false;
                    }


                }
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            }
        );
    }

    GetRouteData() {
        if (this.location.getState() !== null && this.location.getState() !== undefined &&
            this.location.getState()['CompanyDetails'] !== null && this.location.getState()['CompanyDetails'] !== undefined) {
            this.CompanyDetails = this.location.getState()['CompanyDetails'];
            localStorage.removeItem('company');
            localStorage.setItem('company', JSON.stringify(this.CompanyDetails));

            sessionStorage.removeItem('CompanyInfo');
            sessionStorage.setItem('CompanyInfo', JSON.stringify(this.CompanyDetails));
        }
        else if (localStorage.getItem('company') !== null && localStorage.getItem('company') !== undefined) {
            this.CompanyDetails = JSON.parse(localStorage.getItem('company'));
        }
        else {
            window.location.href = '/medical-sme-login';
        }

        if (this.CompanyDetails != null && this.CompanyDetails != undefined)
            if (localStorage.getItem("language") == "en-US")
                this.companyName = this.CompanyDetails.crName;
            else
                this.companyName = this.CompanyDetails.crName;
    }

    OpenAddClassDialog() {
        let dialogRef = this.dialog.open(SmeClassSelectionDialogComponent, {
            width: '1270px',
            height: '720px',
            position: { top: '80px' },
            data: this.SMEClasses,
        });
        dialogRef.afterClosed().subscribe((res) => {
            this.selectedClasses = res.data;
        });
    }

    GetSMENationalities() {
        this.isLoading = true;
        this.SMENationalities = [];
        this.smeService.GetSMENationalities().subscribe(
            (res) => {
                for (let index = 0; index < res?.length; index++) {
                    this.SMENationalities.push(res[index]);
                }
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            }
        );
    }

    GetSMEClasses() {
        this.isLoading = true;
        this.SMEClasses = [];
        this.smeService.GetSMEClasses().subscribe(
            (res) => {
                for (let index = 0; index < res?.length; index++) {
                    this.SMEClasses.push(res[index]);
                }
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            }
        );
    }

    updateTabIndex(number) {
        this.tabIndex = 1;
        this.quotationNumber = number;
        localStorage.removeItem('quotationNumber');
        localStorage.setItem('quotationNumber', number);
    }

    nextTab(event: any) {
        this.tabIndex = 2;
        this.premiumData = event.premData;
        this.quotationNumber = event.quotNumber;
        localStorage.removeItem('premiumData');
        localStorage.setItem('premiumData', this.premiumData);
    }

    goToCreatePolicytab(event: any) {
        //console.log('policy data', event);
        this.tabIndex = 3;
        // this.premiumData = event.PremData;
        // this.CompanyDetails = event.CompanyDetails;
        // this.CompanyAddress = event.CompanyAddress.addresses[0];
    }

    tabChange(event: any) {
        this.tabIndex = event;
    }

    goToIssuedPolicy(event: any) {

    }
}
