import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    standalone: true,
    name: 'ClaimStatusReplacement'
})
export class ClaimStatusReplacementPipe implements PipeTransform {
    constructor(public translate: TranslateService) {

    }
    transform(value: string): string {
        if (value.toLocaleLowerCase() === 'approved')
            return this.translate.instant('MMLHome.Approved');
        else if (value.toLocaleLowerCase() === 'rejected')
            return this.translate.instant('MMLHome.Rejected');
        else 
            return this.translate.instant('MMLHome.InProgress');
    }
}