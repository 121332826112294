import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MotorClaimsService } from 'src/app/core/services/motor-claims.service';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { claimsSaveModel } from 'src/app/core/interfaces/claims-save-model';
import { MatStepper } from '@angular/material/stepper';
import { Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { log } from 'console';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { OTPService } from 'src/app/core/services/eservice-otp.service';
import { MatDialog } from '@angular/material/dialog';
import { OtpVerificationDialogComponent } from '../../OTPVerification/otp-verification-dialog/otp-verification-dialog.component';

@Component({
  selector: 'app-new-motor-claim',
  templateUrl: './new-motor-claim.component.html',
  styleUrls: ['./new-motor-claim.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class NewMotorClaimComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  @ViewChildren('input') vc;
  najmCheck: boolean = false;
  faXmark = faXmark;
  claimForm: FormGroup;
  userInfoFrom: FormGroup;
  driverInfoFrom: FormGroup;
  accidentDetailFrom: FormGroup;
  ownerDetailFrom: FormGroup;
  documentsFrom: FormGroup;
  finalFrom: FormGroup;
  isSubmit: boolean = false;
  isNajm: boolean = true;
  isTaqdeer: boolean = true;
  nationalImgsList: any[] = [];
  num = 1;
  isThirdParty: boolean = true;
  fault: number = 0;
  save: boolean = false;
  requesterTypeList: any[] = [];
  drivingLicenseList: any[] = [];
  cityList: any[] = [];
  docsList: any[] = [];
  isValidAccNo: boolean = true;
  isSaveFrom: boolean = false;
  refranceNumber: string = null;
  isSaved: boolean = false;
  defaultClassFlag: boolean = true;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  leftMenuSelectedItem: number = 1;
  ImgsId: any[] = [];
  isBasher: boolean = true;
  reportType: any;
  isPolicyValid: any;
  isCarSerialNumberValid: any;
  get claimFormControl() {
    return this.claimForm.controls;
  };
  get userInfoFromControl() {
    return this.userInfoFrom.controls;
  };
  get driverInfoFromControl() {
    return this.driverInfoFrom.controls;
  };
  get accidentDetailFromControl() {
    return this.accidentDetailFrom.controls;
  };
  get ownerDetailFromControl() {
    return this.ownerDetailFrom.controls;
  };
  get documentsFromControl() {
    return this.documentsFrom.controls;
  };
  get finalFromControl() {
    return this.finalFrom.controls;
  };
  rxTime = new Date();
  maxTime = new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds;
  mobilePattern = '^(05)([0-9]{8})$';
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  number = /[127]+[0-9]{9}$/;
  IBANpattern = /^[saSA]+[0-9]{22}$/;
  refrancePattern = /GU-CLM-[0-9]{10}$/;
  plateTextPattern = /^[a-zA-Z ]{3}$/;
  platNumber = /^[0-9]{1,5}$/;
  accidentNumPattern = /[a-zA-Z]{2}[0-9]{1,20}$/;
  onlyLettersAndNum = /^[A-Za-z0-9]*$/;
  isLinear = false;
  maxDate: any = new Date();
  dte = new Date();
  isshowDelegate = true;
  isLoading = false;
  ClaimTypeId: number;
  today: Date;
  smallScreen: boolean;
  claimMessages: any;
  isLoadingClaimMsgs: boolean = false;

  constructor(
    public fb: FormBuilder,
    public translate: TranslateService,
    public motorClaimsService: MotorClaimsService,
    public _router: Router,
    private breakpointObserver: BreakpointObserver,
    private _LocalService: LocalService,
    private otpService: OTPService,
    private _dialog: MatDialog,
  ) {
    let motorClaimType = this._LocalService.getJsonValue('motorClaimType');
    if (motorClaimType != null)
      this.ClaimTypeId = this._LocalService.getJsonValue('motorClaimType').typeId;

    if (this.ClaimTypeId == null || this.ClaimTypeId == undefined) {
      this._router.navigate(['/']);
    }

    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      this.smallScreen = result.matches;
    });

  }
  ngOnInit(): void {
    this.maxDate.setDate(this.maxDate.getDate() - 1);
    this.GetMotorClaimMessages();
    this.buildClaimFormForm();
    this.builduserInfoFrom();
    this.builddriverInfoFrom();
    this.buildaccidentDetailFrom();
    this.buildownerDetailFrom();
    this.builddocumentsFromFrom();
    this.buildfinalFrom();
    this.changeType(12);
    // apis call
    this.getRequesterTypeList();
    this.getDrivingLicenseList();
    this.getCitysList();

    this.getDocsList('TP');
    this.today = new Date();
    this.maxTime = new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds;

    this.claimFormControl.reportType.setValue('basher');
  }

  ngAfterViewInit() {

    this.vc.first.nativeElement.focus()

    const middle = (window.innerHeight / 2);
    window.scrollTo(0, middle);

    if (this.ClaimTypeId != undefined && this.ClaimTypeId != null) {
      this.changeClaimType(this.ClaimTypeId);
      if (this.ClaimTypeId == 7) {
        this.getDocsList('TP')
      }
      else if (this.ClaimTypeId == 8) {
        this.getDocsList('COMP')
      }
    }
  }

  buildClaimFormForm() {
    const EmployeeNoPattern = '/^[0-9\\s]*$/';
    const idNumber = '^[0-9]+$';
    let emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var mobilePattern = '^(05)([0-9]{8})$';
    const IBANpattern = '^(SA)([0-9-a-z]{22})$';
    this.claimForm = this.fb.group({
      najmId: [12, [Validators.required]],
      partyId: [7, Validators.required],
      driverId: [
        null,
        [Validators.required, Validators.pattern(this.number)],
      ],
      accidentNum: [
        null,
        [
          Validators.required,
          Validators.pattern(this.accidentNumPattern),
        ],
      ],
      reportType: [null],
      referenceNumber: [null],
      carSerialNumber: [null]
    });
  }
  builduserInfoFrom() {
    this.userInfoFrom = this.fb.group({
      requesterType: [null, Validators.required],
      requesterId: [
        null,
        [Validators.required, Validators.pattern(this.number)],
      ],
      requesterName: [null, Validators.required],
      requesterMob: [
        null,
        [Validators.required, Validators.pattern(this.mobilePattern)],
      ],
      delegationNo: [null, [Validators.required]],
    });
  }
  builddriverInfoFrom() {
    this.driverInfoFrom = this.fb.group({
      vehicleOwner: [false],
      claimRequester: [false],
      otherPerson: [false],
      driverName: [null, Validators.required],
      licenceType: [null, Validators.required],
    });
  }
  buildaccidentDetailFrom() {
    this.accidentDetailFrom = this.fb.group({
      city: [null, Validators.required],
      date: [null, Validators.required],
      time: [null, Validators.required],
      plateNum: [
        null,
        [Validators.required, Validators.pattern(this.platNumber)],
      ],
      plateText: [
        null,
        [
          Validators.required,
          //   Validators.pattern(this.plateTextPattern),
        ],
      ],
      fault: [0, Validators.required],
    });
  }
  buildownerDetailFrom() {
    this.ownerDetailFrom = this.fb.group({
      name: [null, Validators.required],
      id: [null, [Validators.required, Validators.pattern(this.number)]],
      bankNum: [
        null,
        [Validators.required, Validators.pattern(this.IBANpattern)],
      ],
      phone: [
        null,
        [Validators.required, Validators.pattern(this.mobilePattern)],
      ],
      email: [
        null,
        [Validators.required, Validators.pattern(this.emailPattern)],
      ],
    });
  }
  builddocumentsFromFrom() {
    this.documentsFrom = this.fb.group({
      docs: [null],
    });
  }
  buildfinalFrom() {
    this.finalFrom = this.fb.group({
      save: [false],
    });
  }

  changeReportType(type) {
    debugger;
    this.claimFormControl.reportType.setValue(type);

    this.claimFormControl.referenceNumber.clearValidators();
    this.claimFormControl.referenceNumber.removeValidators([
      Validators.required
    ]);
    this.claimFormControl.referenceNumber.updateValueAndValidity();

    this.claimFormControl.carSerialNumber.clearValidators();
    this.claimFormControl.carSerialNumber.removeValidators([
      Validators.required
    ]);
    this.claimFormControl.carSerialNumber.updateValueAndValidity();

    if (type == 'basher') {
      this.isBasher = true;
      this.claimFormControl.referenceNumber.setValidators([
        Validators.required
      ]);
      this.claimFormControl.referenceNumber.updateValueAndValidity();

    } else {
      this.isBasher = false;
      this.claimFormControl.carSerialNumber.setValidators([
        Validators.required
      ]);
      this.claimFormControl.carSerialNumber.updateValueAndValidity();
    }
  }

  changeType(type) {
    this.claimFormControl.carSerialNumber.setValue(null);
    this.claimFormControl.referenceNumber.setValue(null);
    this.claimFormControl.referenceNumber.clearValidators();
    this.claimFormControl.referenceNumber.removeValidators([
      Validators.required
    ]);
    this.claimFormControl.referenceNumber.updateValueAndValidity();

    this.claimFormControl.carSerialNumber.clearValidators();
    this.claimFormControl.carSerialNumber.removeValidators([
      Validators.required
    ]);
    this.claimFormControl.carSerialNumber.updateValueAndValidity();

    this.claimFormControl.najmId.setValue(type);
    if (type == 12) {
      this.isNajm = true;
      this.claimFormControl.reportType.setValue(null);

      this.claimFormControl.accidentNum.setValidators([
        Validators.required,
        Validators.pattern(this.accidentNumPattern),
      ]);
      this.claimFormControl.accidentNum.updateValueAndValidity();
      this.accidentDetailFromControl.city.clearValidators();
      this.accidentDetailFromControl.city.updateValueAndValidity();
      this.accidentDetailFromControl.date.clearValidators();
      this.accidentDetailFromControl.date.updateValueAndValidity();
      this.accidentDetailFromControl.time.clearValidators();
      this.accidentDetailFromControl.time.updateValueAndValidity();
      this.accidentDetailFromControl.plateNum.clearValidators();
      this.accidentDetailFromControl.plateNum.updateValueAndValidity();
      this.accidentDetailFromControl.plateText.clearValidators();
      this.accidentDetailFromControl.plateText.updateValueAndValidity();
      this.accidentDetailFromControl.fault.clearValidators();
      this.accidentDetailFromControl.fault.updateValueAndValidity();
    } else if (type == 13) {
      this.isNajm = false;
      this.isValidAccNo = true;
      this.claimFormControl.accidentNum.setValidators(
        Validators.pattern(this.onlyLettersAndNum)
      );
      this.claimFormControl.accidentNum.updateValueAndValidity();
      this.accidentDetailFromControl.city.setValidators(
        Validators.required
      );
      this.accidentDetailFromControl.city.updateValueAndValidity();
      this.accidentDetailFromControl.date.setValidators(
        Validators.required
      );
      this.accidentDetailFromControl.date.updateValueAndValidity();
      this.accidentDetailFromControl.time.setValidators(
        Validators.required
      );
      this.accidentDetailFromControl.time.updateValueAndValidity();
      this.accidentDetailFromControl.plateNum.setValidators([
        Validators.required,
        Validators.pattern(this.platNumber),
      ]);
      this.accidentDetailFromControl.plateNum.updateValueAndValidity();
      this.accidentDetailFromControl.plateText.setValidators(
        Validators.required
      );
      debugger;
      this.accidentDetailFromControl.plateText.updateValueAndValidity();
      this.accidentDetailFromControl.fault.setValidators(
        Validators.required
      );
      this.accidentDetailFromControl.fault.setValue(0);
      this.accidentDetailFromControl.fault.updateValueAndValidity();
    }
  }
  changeClaimType(type) {
    this.claimFormControl.partyId.setValue(Number(type));
    if (type == 8) {
      this.isThirdParty = false;
    } else if (type == 7) {
      this.isThirdParty = true;
    }
  }

  changePlace(id) {
    if (id == 1) {
      this.isTaqdeer = true;
      this.claimFormControl.taqdeerId.addValidators(Validators.required);
    } else if (id == 0) {
      this.isTaqdeer = false;
      this.claimFormControl.taqdeerId.clearValidators();
      this.claimFormControl.taqdeerId.updateValueAndValidity();
    }
  }
  getRequesterTypeList() {
    this.motorClaimsService.getRequesterTypeList().subscribe((res) => {
      this.requesterTypeList = res;
    });
  }
  getDrivingLicenseList() {
    this.motorClaimsService.getDrivingLicenseList().subscribe((res) => {
      this.drivingLicenseList = res;
    });
  }
  getCitysList() {
    this.motorClaimsService.getCitysList().subscribe((res) => {
      this.cityList = res;
    });
  }
  getDocsList(type) {
    this.motorClaimsService.getDocs(type).subscribe((res) => {
      this.docsList = res;
    });
  }
  GetMotorClaimMessages() {
    this.isLoadingClaimMsgs = true;
    this.motorClaimsService.getMotorClaimMessages(this.translate.currentLang).subscribe((res) => {
      this.claimMessages = res.claimMessages;
      this.isLoadingClaimMsgs = false;
    });
  }
  isVehicleOwner(val) {
    if (val == false) {
      this.driverInfoFromControl.otherPerson.setValue(false);
    }
    if (
      val == false &&
      this.driverInfoFromControl.claimRequester.value == true
    ) {
      this.ownerDetailFromControl.name.setValue(
        this.userInfoFromControl.requesterName.value
      );
      this.ownerDetailFromControl.id.setValue(
        this.userInfoFromControl.requesterId.value
      );
      this.ownerDetailFromControl.phone.setValue(
        this.userInfoFromControl.requesterMob.value
      );
    } else {
      this.ownerDetailFromControl.name.setValue(null);
      this.ownerDetailFromControl.id.setValue(null);
      this.ownerDetailFromControl.phone.setValue(null);
    }
  }
  isOtherPerson(val) {
    if (val == false) {
      this.driverInfoFromControl.vehicleOwner.setValue(false);
      this.driverInfoFromControl.claimRequester.setValue(false);
    }
  }
  isClaimRequester(val) {
    if (val == false) {
      this.driverInfoFromControl.driverName.setValue(
        this.userInfoFromControl.requesterName.value
      );
      this.driverInfoFromControl.otherPerson.setValue(false);
    } else {
      this.driverInfoFromControl.driverName.setValue(null);
    }
    if (
      val == false &&
      this.driverInfoFromControl.vehicleOwner.value == true
    ) {
      this.ownerDetailFromControl.name.setValue(
        this.userInfoFromControl.requesterName.value
      );
      this.ownerDetailFromControl.id.setValue(
        this.userInfoFromControl.requesterId.value
      );
      this.ownerDetailFromControl.phone.setValue(
        this.userInfoFromControl.requesterMob.value
      );
    } else {
      this.ownerDetailFromControl.name.setValue(null);
      this.ownerDetailFromControl.id.setValue(null);
      this.ownerDetailFromControl.phone.setValue(null);
    }
  }

  SendOTPAndOpenVerificationDialog() {
    let otpObject = {
      ActionType: SMSActionType.IssueClaim,
      Lob: LineOfBusiness.Motor,
      IqamaId: this.userInfoFromControl.requesterId.value.toString(),
      MobileNumber: this.userInfoFromControl.requesterMob.value.toString(),
      isWeb: true
    }

    this.isLoading = true;
    this.otpService.sendOTP(otpObject).subscribe((res) => {
      let result = res;
      if (result.errors.length <= 0) {
        const dialogRef = this._dialog.open(OtpVerificationDialogComponent, {
          data: { otpDetails: result },
          width: '600px',
          disableClose:true
        });
        dialogRef.afterClosed().subscribe((data) => {
          if (data.verified) {
            this.finalSave();
          }
        });
      }
    });
  }

  finalSave() {
    this.isSubmit = true;
    if (this.finalFromControl.save.value === true) {
      if (
        this.claimForm.valid &&
        this.userInfoFrom.valid &&
        this.driverInfoFrom.valid &&
        this.accidentDetailFrom.valid &&
        this.ownerDetailFrom.valid
      ) {
        this.buildSaveModel();
      } else {

        let errorMessage: string = this.translate.currentLang == 'en-US'
          ? 'Please fill all mandatory fields'
          : 'يرجى ملء جميع الحقول الإلزامية';
        let dir: string = "";
        if (this.translate.currentLang == 'en-US') {
          dir = "ltr";
        }
        else {
          dir = "rtl";
        }

        Swal.fire({
          title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
          iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
          html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
          confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
          customClass: {
            icon: 'submit-claim-failed-icon-img',
            title: 'submit-claim-failed-swal-title',
            confirmButton: 'submit-claim-failed-confirm-btn',
          }
        });
        this.isSaveFrom = false;
      }
    } else {
      let errorMessage: string = this.translate.currentLang == 'en-US'
        ? 'please confirm  the rule and policies  '
        : 'الرجاء الموافقه علي السياسات والقوانين.';
      let dir: string = "";
      if (this.translate.currentLang == 'en-US') {
        dir = "ltr";
      }
      else {
        dir = "rtl";
      }

      Swal.fire({
        title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
        iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
        html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
        confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
        customClass: {
          icon: 'submit-claim-failed-icon-img',
          title: 'submit-claim-failed-swal-title',
          confirmButton: 'submit-claim-failed-confirm-btn',
        }
      });
    }
  }
  buildSaveModel() {
    debugger;
    let claimsModel: claimsSaveModel = new (class
      implements claimsSaveModel {
      cS_ACC_CITY_ID: string; // done
      cS_ACC_DATETIME: Date; // done
      cS_ACC_DRIVERID: number; // done
      cS_ACC_FAULTPCT: number; // done
      cS_ACC_ID: string; // ????????????????????????????
      cS_ACC_PLATECHARACTER: string; // done
      cS_ACC_PLATENO: number; // done
      cS_ACC_PROCESSEDBY_ID: string; // done
      cS_BRANCH: string; // ?????????????????????????
      cS_CITY: string; // ????????????????????????
      cS_CLM_DELEGATIONNO: string; // done
      cS_CLM_DRIVERLICENSE_TYPE_ID: string; // done
      cS_CLM_DRIVERNAME: string; // done
      cS_CLM_OWNEREMAIL: string; // done
      cS_CLM_OWNERID: number; // done
      cS_CLM_OWNERMOBNO: string; // done
      cS_CLM_OWNERNAME: string; // done
      cS_CLM_OWNER_IBAN: string; // done
      cS_CLM_REQUESTERID: number; // done
      cS_CLM_REQUESTERMOBNO: string; // done
      cS_CLM_REQUESTERNAME: string; // done
      cS_CLM_REQUESTERTYPE_ID: string; // done
      cS_CLM_TYPE_ID: string; // done
      cS_REFERENCE_NO: string; // done
      report_TYPE: string;
      cAR_SERIAL_NUMBER: string;
      pOLICY_SEQ: string;
      
      claiM_DOCS: [
        { cS_DOC_TYPE: string; doC_BYTE: string; doC_EXTENSION: string }
      ];
    })();
    //    start assign data
    claimsModel.cS_ACC_CITY_ID = JSON.stringify(
      this.accidentDetailFromControl.city.value
    );
    if (this.accidentDetailFromControl.city.value == null) {
      claimsModel.cS_ACC_CITY_ID = null;
    } else {
      claimsModel.cS_ACC_CITY_ID = JSON.stringify(
        this.accidentDetailFromControl.city.value
      );
    }
    claimsModel.cS_ACC_DATETIME = this.accidentDetailFromControl.date.value;
    claimsModel.cS_ACC_DRIVERID = this.claimFormControl.driverId.value;
    claimsModel.cS_ACC_FAULTPCT =
      this.accidentDetailFromControl.fault.value;
    let accId = this.claimFormControl.accidentNum.value;
    claimsModel.cS_ACC_ID = accId.toUpperCase();
    let plateText = this.accidentDetailFromControl.plateText.value;
    if (plateText) {
      claimsModel.cS_ACC_PLATECHARACTER = plateText.slice(0, -1);
    } else {
      claimsModel.cS_ACC_PLATECHARACTER = null;
    }
    claimsModel.cS_ACC_PLATENO =
      this.accidentDetailFromControl.plateNum.value;
    claimsModel.cS_ACC_PROCESSEDBY_ID = JSON.stringify(
      this.claimFormControl.najmId.value
    );
    claimsModel.cS_CLM_DELEGATIONNO =
      this.userInfoFromControl.delegationNo.value;
    claimsModel.cS_CLM_DRIVERLICENSE_TYPE_ID =
      this.driverInfoFromControl.licenceType.value;
    claimsModel.cS_CLM_DRIVERNAME =
      this.driverInfoFromControl.driverName.value;
    claimsModel.cS_CLM_OWNEREMAIL = this.ownerDetailFromControl.email.value;
    claimsModel.cS_CLM_OWNERID = this.ownerDetailFromControl.id.value;
    claimsModel.cS_CLM_OWNERMOBNO = this.ownerDetailFromControl.phone.value;
    claimsModel.cS_CLM_OWNERNAME = this.ownerDetailFromControl.name.value;
    let bank = this.ownerDetailFromControl.bankNum.value;
    claimsModel.cS_CLM_OWNER_IBAN = bank.toUpperCase();
    claimsModel.cS_CLM_REQUESTERID =
      this.userInfoFromControl.requesterId.value;
    claimsModel.cS_CLM_REQUESTERMOBNO =
      this.userInfoFromControl.requesterMob.value;
    claimsModel.cS_CLM_REQUESTERNAME =
      this.userInfoFromControl.requesterName.value;
    claimsModel.cS_CLM_REQUESTERTYPE_ID =
      this.userInfoFromControl.requesterType.value;
    claimsModel.cS_CLM_TYPE_ID = JSON.stringify(
      this.claimFormControl.partyId.value
    );
    claimsModel.cAR_SERIAL_NUMBER = this.claimFormControl.carSerialNumber.value;
    claimsModel.report_TYPE = this.claimFormControl.reportType.value;
    claimsModel.pOLICY_SEQ = this.claimFormControl.referenceNumber.value;
    // claimsModel.cS_REFERENCE_NO = "GU-CLM-1234567890";
    // claimsModel.cS_BRANCH = this.cityList[0].branch;
    // claimsModel.cS_CITY = this.cityList[0].citynameen;
    claimsModel.claiM_DOCS = [
      {
        cS_DOC_TYPE: '',
        doC_BYTE: '',
      },
    ];
    if (this.nationalImgsList != null && this.nationalImgsList.length) {
      let finalList: any = [];
      for (let i = 0; i < this.nationalImgsList.length; i++) {
        let item = this.nationalImgsList[i];
        let list: {
          cS_DOC_TYPE: string;
          doC_BYTE: string;
        } = {
          cS_DOC_TYPE: '',
          doC_BYTE: '',
        };
        list.cS_DOC_TYPE = item.id;
        list.doC_BYTE = item.image;
        finalList.push(list);
      }
      claimsModel.claiM_DOCS = finalList;
    }
    this.isLoading = true;
    debugger;
    this.motorClaimsService.saveClaim(claimsModel).subscribe((res) => {
      if (res.isSuccess) {
        this.refranceNumber = res.data.cS_REFERENCE_NO;
        this.isSaved = true;
        this.isLoading = false;

        Swal.fire({
          title: this.translate.currentLang == 'en-US' ? "Success" : "نجحت المطالبة",
          iconHtml: '<img width=40 height=40 style="margin-top:10px;" src="assets/img/swal-icons-img/check.png">',
          html: this.translate.currentLang == 'en-US' ? "Your reference number is " + "<span style='color:#D4AB36'>" + this.refranceNumber + "</span>" :
            "<span style='color:#D4AB36'>" + this.refranceNumber + "</span>" + "الرقم المرجعي للمطالبة هو ",
          confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
          customClass: {
            icon: 'submit-claim-success-icon-img',
            title: 'submit-claim-success-swal-title',
            confirmButton: 'submit-claim-success-confirm-btn',
          }
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this._router.navigate(['/']);
          }
        })



      } else {
        this.isSaved = false;
        this.isSaveFrom = false;
        this.isLoading = false;
        let dir: string = "";
        if (this.translate.currentLang == 'en-US') {
          dir = "ltr";
        }
        else {
          dir = "rtl";
        }

        if (res.messages != null && res.messages.length > 0) {
          Swal.fire({
            title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
            iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html: "<div style='color:#FF001F; direction:" + dir + "'>" + res.messages + "</div>",
            confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
            customClass: {
              icon: 'submit-claim-failed-icon-img',
              title: 'submit-claim-failed-swal-title',
              confirmButton: 'submit-claim-failed-confirm-btn',
            }
          });
        } else if (res.message) {
          Swal.fire({
            title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
            iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html: "<div style='color:#FF001F; direction:" + dir + "'>" + res.message + "</div>",
            confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
            customClass: {
              icon: 'submit-claim-failed-icon-img',
              title: 'submit-claim-failed-swal-title',
              confirmButton: 'submit-claim-failed-confirm-btn',
            }
          });
        }
      }
    });
  }
  addSpace(val) {
    let value = this.accidentDetailFromControl.plateText.value;
    if (value.length < 5) {
      var charTyped = String.fromCharCode(val.which);
      if (/^[a-zA-Z]$/.test(charTyped)) {
        let result = value + ' ';
        this.accidentDetailFromControl.plateText.setValue(result);
      }
    }
  }
  onInputChange(event: any) {
    debugger;
    let value = this.accidentDetailFromControl.plateText.value;

    if (event.key === 'Backspace' || event.keyCode === 8) {
      // Remove the last character and the space before it
      if (value.length > 0) {
        // Check if the last character is a space
        if (value.charAt(value.length - 1) === ' ') {
          value = value.slice(0, -1);
          this.accidentDetailFromControl.plateText.setValue(value);
        }
      }
    } else {
      if (value.length < 5) {
        // Check if the last character is not already a space
        if (value.charAt(value.length - 1) !== ' ') {
          value += ' ';
          this.accidentDetailFromControl.plateText.setValue(value);
        }
      }
    }

    const regex = /^[a-zA-Z\u0600-\u06FF]*\s+[a-zA-Z\u0600-\u06FF]*\s+[a-zA-Z\u0600-\u06FF]*$/;
    var testResult = regex.test(this.accidentDetailFromControl.plateText.value);
    if (!testResult) {
      this.accidentDetailFromControl.plateText.setErrors({
        customError: this.translate.currentLang == 'en-US' ?
          "Invalid Plate Number" : "رقم السيارة خطأ"
      });
    } else {
      this.accidentDetailFromControl.plateText.setErrors(null);
    }
  }
  submitClaimInfo(stepper: MatStepper) {
    debugger;
    this.isSubmit = true;
    if (this.claimForm.valid) {
      this.isSubmit = false;
      this.userInfoFrom.reset();
      this.accidentDetailFrom.reset();
      this.accidentDetailFromControl.fault.setValue(0);
      this.ownerDetailFrom.reset();
      this.finalFrom.reset();
      this.documentsFrom.reset();
      this.nationalImgsList = [];
      if (this.isNajm) {
        this.motorClaimsService.validateAcciedentNo(this.claimForm.controls['accidentNum'].value).subscribe((res) => {
          this.isValidAccNo = res;
          if (this.isValidAccNo) {
            stepper.next();
          }
          else {

          }
        });
      }
      else {
        if (this.isBasher) {
          if (this.claimFormControl.referenceNumber.value != null && this.claimFormControl.referenceNumber.value != undefined) {
            this.isLoading = true;
            this.motorClaimsService.validatePolicyNumber(this.claimFormControl.referenceNumber.value).subscribe((res) => {
              this.isPolicyValid = res.isValid;
              if (this.isPolicyValid) {
                this.isValidAccNo = true;
                stepper.next();
              } else {
                  let errorMessage: string = "";
                  let dir: string = "";

                  if (this.translate.currentLang == 'en-US') {
                    dir = "ltr";
                    errorMessage = "Invalid Car Policy Number"
                  }
                  else {
                    dir = "rtl";
                    errorMessage = "رقم الوثيقة غير صحيح"
                  }

                  Swal.fire({
                    title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
                    iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                    html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
                    confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
                    customClass: {
                      icon: 'submit-claim-failed-icon-img',
                      title: 'submit-claim-failed-swal-title',
                      confirmButton: 'submit-claim-failed-confirm-btn',
                    }
                  });
                }
              this.isLoading = false;
            });
          }
        } else {
          if (this.claimFormControl.carSerialNumber.value != null && this.claimFormControl.carSerialNumber.value != undefined) {
            this.isLoading = true;
            this.motorClaimsService.validateCarReferenceNumber(this.claimFormControl.carSerialNumber.value).subscribe((res) => {
              this.isCarSerialNumberValid = res.isValid;
              this.isLoading = false;
              if (this.isCarSerialNumberValid) {
                this.isValidAccNo = true;
                stepper.next();
              }
              else {
                let errorMessage: string = "";
                let dir: string = "";

                if (this.translate.currentLang == 'en-US') {
                  dir = "ltr";
                  errorMessage = "Invalid Car Serial Number"
                }
                else {
                  dir = "rtl";
                  errorMessage = "الرقم التسلسلي للمركبة غير صحيح"
                }

                Swal.fire({
                  title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
                  iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                  html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
                  confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
                  customClass: {
                    icon: 'submit-claim-failed-icon-img',
                    title: 'submit-claim-failed-swal-title',
                    confirmButton: 'submit-claim-failed-confirm-btn',
                  }
                });
              }
            });
          }
        }
      }
    }
    else {
      let errorMessage: string = "";
      let dir: string = "";
      if (this.translate.currentLang == 'en-US') {
        dir = "ltr";
      }
      else {
        dir = "rtl";
      }
      let index: number = 1;
      if (this.claimForm.controls['driverId'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Driver Id / Iqama is required!<br/>" : index + "- الرقم القومي للسائق / الاقامة مطلوب!<br/>";
        index++;
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Driver Id / Iqama should start with 1 or 2!<br/>" : index + "- الرقم القومي للسائق / الاقامة يجب ان يبدأ برقم 1 او 2!<br/>"
        index++;
      }
      else {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Driver Id / Iqama should start with 1 or 2!<br/>" : index + "- الرقم القومي للسائق / الاقامة يجب ان يبدأ برقم 1 او 2!<br/>"
        index++;
      }

      if (this.claimForm.controls['accidentNum'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Accident Number is required!<br/>" : index + "- رقم الحادث مطلوب!<br/>"
        index++;
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Accident Number should start with two letters then numbers!<br/>" : index + "- رقم الحادث يجب ان يبدأ بحرفين ثم ارقام!<br/>"
        index++;
      } else {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Accident Number should start with two letters then numbers!<br/>" : index + "- رقم الحادث يجب ان يبدأ بحرفين ثم ارقام!<br/>"
        index++;
      }

      if (!this.isNajm) {
        if (this.isBasher && this.claimForm.controls['referenceNumber'].value == null) {
          errorMessage += this.translate.currentLang == 'en-US' ? index + "- Policy Number is required!<br/>" : index + "- رقم الوثيقة مطلوب!<br/>"
          index++;
        }
      }

      if (!this.isNajm) {
        if (!this.isBasher && this.claimForm.controls['carSerialNumber'].value == null) {
          errorMessage += this.translate.currentLang == 'en-US' ? index + "- Car Serial Number is required!<br/>" : index + "- الرقم التسلسلي للمركبة مطلوب!<br/>"
          index++;
        }
      }

      Swal.fire({
        title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
        iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
        html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
        confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
        customClass: {
          icon: 'submit-claim-failed-icon-img',
          title: 'submit-claim-failed-swal-title',
          confirmButton: 'submit-claim-failed-confirm-btn',
        }
      });
    }
  }
  submituserInfo(stepper: MatStepper) {
    this.isSubmit = true;
    if (this.userInfoFrom.valid) {
      this.isSubmit = false;
      // vehicleOwner: [false],
      //     claimRequester: [false],
      //     otherPerson: [false],
      //     driverName:[null, Validators.required],
      //     licenceType:[null, Validators.required],
      this.driverInfoFromControl.vehicleOwner.setValue(false);
      this.driverInfoFromControl.claimRequester.setValue(false);
      this.driverInfoFromControl.otherPerson.setValue(false);
      this.driverInfoFromControl.driverName.setValue(null);
      this.driverInfoFromControl.licenceType.setValue(null);

      stepper.next();
    } else {

      let errorMessage: string = "";
      let dir: string = "";
      let index = 1;
      if (this.translate.currentLang == 'en-US') {
        dir = "ltr";
      }
      else {
        dir = "rtl";
      }

      if (this.userInfoFrom.controls['requesterType'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Requester Type Id is required!<br/>" : index + "- صفة مقدم الطلب مطلوبه!<br/>"
        index++;
      }

      if (this.userInfoFrom.controls['requesterId'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Requester Id / Iqama is required!<br/>" : index + "- الرقم القومي لمقدم الطلب / الاقامة مطلوب!<br/>"
        index++;
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Requester Id / Iqama should start with 1 or 2 with length 10 numbers!<br/>" : index + "- الرقم القومي لمقدم الطلب / الاقامة يجب ان يبدأ برقم 1 او 2 ويحتوي علي 10 ارقام! <br />"
        index++;
      } else if (this.userInfoFrom.controls['requesterId'].status == "INVALID") {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Requester Id / Iqama should start with 1 or 2 with length 10 numbers!<br/>" : index + "- الرقم القومي لمقدم الطلب يجب ان يبدأ برقم 1 او 2 ويحتوي علي 10 ارقام! <br />"
        index++;
      }

      if (this.userInfoFrom.controls['requesterName'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Requester Name is required!<br/>" : index + "- اسم مقدم الطلب مطلوب!<br/>"
        index++;
      }

      if (this.userInfoFrom.controls['requesterMob'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Mobile Number is required!<br/>" : index + "- رقم الهاتف مطلوب!<br/>"
        index++;
      }

      if (this.userInfoFrom.controls['delegationNo'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Delegation Number is required!<br/>" : index + "- رقم الوكالة مطلوب!<br/>"
        index++;
      }


      Swal.fire({
        title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
        iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
        html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
        confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
        customClass: {
          icon: 'submit-claim-failed-icon-img',
          title: 'submit-claim-failed-swal-title',
          confirmButton: 'submit-claim-failed-confirm-btn',
        }
      });
    }
  }
  submitDriverInfo(stepper: MatStepper) {
    this.isSubmit = true;
    if (this.driverInfoFrom.valid) {
      this.isSubmit = false;
      this.accidentDetailFrom.reset();
      this.accidentDetailFromControl.fault.setValue(0);
      stepper.next();
    } else {

      let errorMessage: string = "";
      let dir: string = "";
      let index = 1;
      if (this.translate.currentLang == 'en-US') {
        dir = "ltr";
      }
      else {
        dir = "rtl";
      }

      if (this.driverInfoFrom.controls['driverName'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Driver Name is required!<br/>" : index + "- اسم السائق مطلوب!<br/>"
        index++;
      }

      if (this.driverInfoFrom.controls['licenceType'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- License Type Required!<br/>" : index + "- نوع الرخصة مطلوب<br/>"
        index++;
      }

      Swal.fire({
        title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
        iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
        html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
        confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
        customClass: {
          icon: 'submit-claim-failed-icon-img',
          title: 'submit-claim-failed-swal-title',
          confirmButton: 'submit-claim-failed-confirm-btn',
        }
      });
    }
  }

  submitAccednentDetails(stepper: MatStepper) {
    debugger;
    if (this.accidentDetailFrom.controls["fault"].value == null) {
      this.accidentDetailFromControl.fault.setValue(0);
    }

    this.isSubmit = true;
    if (this.accidentDetailFrom.valid) {
      this.isSubmit = false;
      stepper.next();
    } else {

      let errorMessage: string = "";
      let dir: string = "";
      let index = 1;
      if (this.translate.currentLang == 'en-US') {
        dir = "ltr";
      }
      else {
        dir = "rtl";
      }

      if (this.accidentDetailFrom.controls['city'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- City is required!<br/>" : index + "- اسم المدينة مطلوب!<br/>"
        index++;
      }

      if (this.accidentDetailFrom.controls['date'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Accident Date is Required!<br/>" : index + "- تاريخ الحادث مطلوب<br/>"
        index++;
      }

      if (this.accidentDetailFrom.controls['time'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Accident Time is Required!<br/>" : index + "- وقت وقوع الحادث مطلوب<br/>"
        index++;
      }

      if (this.accidentDetailFrom.controls['plateNum'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Plate Number is Required!<br/>" : index + "- ارقام لوحة السيارة مطلوبة<br/>"
        index++;
      }

      if (this.accidentDetailFrom.controls['plateText'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Plate Text is Required!<br/>" : index + "- حروف لوحة السيارة مطلوبة<br/>"
        index++;
      }

      Swal.fire({
        title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
        iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
        html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
        confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
        customClass: {
          icon: 'submit-claim-failed-icon-img',
          title: 'submit-claim-failed-swal-title',
          confirmButton: 'submit-claim-failed-confirm-btn',
        }
      });
    }
  }

  submitOwnerDetails(stepper: MatStepper) {
    this.isSubmit = true;
    if (this.ownerDetailFrom.valid) {
      this.isSubmit = false;
      stepper.next();
    } else {

      let errorMessage: string = "";
      let dir: string = "";
      let index = 1;
      if (this.translate.currentLang == 'en-US') {
        dir = "ltr";
      }
      else {
        dir = "rtl";
      }

      if (this.ownerDetailFrom.controls['name'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Owner Name is required!<br/>" : index + "- اسم صاحب السيارة مطلوب!<br/>"
        index++;
      }

      if (this.ownerDetailFrom.controls['id'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Owner ID or Iqama is Required!<br/>" : index + "-  رقم هوية المالك مطلوب<br/>"
        index++;
      }

      if (this.ownerDetailFrom.controls['bankNum'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Owner IBAN Number is Required!<br/>" : index + "- رقم الحساب البنكي للمالك مطلوب<br/>"
        index++;
      }

      if (this.ownerDetailFrom.controls['phone'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Phone Number is Required!<br/>" : index + "- رقم هاتف المالك مطلوب<br/>"
        index++;
      } else if (this.ownerDetailFrom.controls['phone'].status == "INVALID") {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Please Enter Correct Phone Number!<br/>" : index + "- ادخل رقم هاتف صحيح <br />"
        index++;
      }

      if (this.ownerDetailFrom.controls['email'].value == null) {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Email is Required!<br/>" : index + "- البريد الالكتروني للمالك مطلوب<br/>"
        index++;
      } else if (this.ownerDetailFrom.controls['email'].status == "INVALID") {
        errorMessage += this.translate.currentLang == 'en-US' ? index + "- Please Enter Correct Email!<br/>" : index + "- ادخل بريد الكتروني صحيح <br />"
        index++;
      }

      Swal.fire({
        title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
        iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
        html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
        confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
        customClass: {
          icon: 'submit-claim-failed-icon-img',
          title: 'submit-claim-failed-swal-title',
          confirmButton: 'submit-claim-failed-confirm-btn',
        }
      });
    }
  }
  showSaveForm(stepper: MatStepper) {
    let required = [];
    for (let i = 0; i < this.docsList.length; i++) {
      if (this.docsList[i].clM_DOC_REQUIERD === 'Y') {
        required.push(this.docsList[i].id);
      }
    }
    for (let x = 0; x < this.nationalImgsList.length; x++) {
      if (required.includes(this.nationalImgsList[x].id)) {
        const index: number = required.indexOf(
          this.nationalImgsList[x].id
        );
        required.splice(index, 1);
      }
    }
    if (required.length > 0) {
      let errorMessage: string = this.translate.currentLang == 'en-US'
        ? 'please add all required files.  '
        : 'الرجاء إضافة جميع الصور المطلوبه.';
      let dir: string = "";
      if (this.translate.currentLang == 'en-US') {
        dir = "ltr";
      }
      else {
        dir = "rtl";
      }

      Swal.fire({
        title: this.translate.currentLang == 'en-US' ? "Error!" : "!خطأ",
        iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
        html: "<div style='color:#FF001F; direction:" + dir + "'>" + errorMessage + "</div>",
        confirmButtonText: this.translate.currentLang == 'en-US' ? "OK" : "تم",
        customClass: {
          icon: 'submit-claim-failed-icon-img',
          title: 'submit-claim-failed-swal-title',
          confirmButton: 'submit-claim-failed-confirm-btn',
        }
      });
    } else {
      //this.isSaveFrom = true;
      this.isSubmit = false;
      stepper.next();
    }
  }
  showDelegate(val) {
    if (val == 9) {
      this.isshowDelegate = false;
      this.userInfoFromControl.delegationNo.clearValidators();
      this.userInfoFromControl.delegationNo.updateValueAndValidity();
    } else {
      this.isshowDelegate = true;
      this.userInfoFromControl.delegationNo.setValidators(
        Validators.required
      );
      this.userInfoFromControl.delegationNo.updateValueAndValidity();
    }
  }
  checkAccidentNo(val) {
    if (this.isNajm) {
      this.motorClaimsService.validateAcciedentNo(val.target.value).subscribe((res) => {
        this.isValidAccNo = res;
        console.log(res);
      });
    }
    else {
      this.isValidAccNo = true;
    }

  }
  setDocsList(event, id) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const fileName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.nationalImgsList.push({
          image: reader.result,
          id: id,
          name: fileName,
        });
        if (!this.ImgsId.includes(id)) {
          this.ImgsId.push(id);
        }
      };
    }

    this.myInputVariable.nativeElement.value = '';
  }
  deleteAtt(x) {
    this.nationalImgsList.splice(x, 1);
  }

  DisplayDocument(img: any) {
    Swal.fire({
      title: img.name,
      showCloseButton: true,
      showConfirmButton: false,
      imageUrl: img.image,
      imageHeight: 300,
      imageAlt: 'A tall image',
    });
  }
}
