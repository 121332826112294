import { Component,EventEmitter, Input , Output, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, Route } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/core/AppConfigService';
import { PaymentOnlineService } from 'src/app/core/services/Payment/payment-online.service';
@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss']
})
export class PaymentPageComponent {
    @Input() query: any;
      @Output() changeLang = new EventEmitter();
    constructor(
        private activatedRoute: ActivatedRoute,
        private environment: AppConfigService,
        public translate: TranslateService,
        private paymentOnlineService: PaymentOnlineService,
        private route: Router
    ) {
        
    }
    transectionID: any;
     languageValue = localStorage.getItem('language');
    paymentinfo: PaymentInfo = {} as PaymentInfo;
    ngOnInit(): void {
      this.languageHandler();
       /* this.activatedRoute.queryParams.subscribe(params => {
            this.transectionID = params.data;
        });*/

        this.activatedRoute.params.subscribe(params => this.transectionID = params.data);
       
        this.paymentOnlineService.GetPaymentInfo(this.transectionID).subscribe(
            (res) => {
                this.paymentinfo = res;
                if (this.paymentinfo.policyno == 0) {
                    if (this.paymentinfo.lang !== undefined && this.paymentinfo.lang !== '' && this.paymentinfo.lang === 'E') {

                        this.languageSwitcher('en-US');

                    }
                    else {
                        this.languageSwitcher('ar-SA');
                    }
                    this.route.navigateByUrl('/online-sadad-forbidden');
                }
                else {
                    
                    if (this.paymentinfo.lang !== undefined && this.paymentinfo.lang !== '' && this.paymentinfo.lang === 'E') {
                        
                          this.languageSwitcher('en-US');
                       
                    }
                    else {
                         this.languageSwitcher('ar-SA');
                    }
                }
            },
            (err) => {
                  window.location.href = '';
            }
        );

    }

    async  ProceedPayment($event) {
        let langg;
        if (this.translate.currentLang == 'en-US') {
            langg = 'en';
        } else {
            langg = 'ar';
        }
        let Model = {
            TrxId: this.paymentinfo.trxid.toString(),
            IqamaNumber: this.paymentinfo.policyholderid.toString(),
            entityName: '',
            amount: this.paymentinfo.totalpremium,
            iban: '',
            lang: langg,
            billingDto: {
                street1: '',
                city: '',
                state: '',
                country: 'SA',
                postcode: '',
            },
            customerDto: {
                email: '',
                givenName: this.paymentinfo.clientname,
                surname: this.paymentinfo.clientname,
            },
        };
        const res = await this.paymentOnlineService.ProceedPaymentData(Model).toPromise();
        if (res?.statusCode >= 300) {
           

            let dir: string = '';
            let ErrMsg = '';

            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
                ErrMsg = 'An Error Occurred !';
            } else {
                dir = 'rtl';
                ErrMsg = 'حدث خطأ ما !';
            }

           
        } else if (res?.statusCode == 200) {

            localStorage.setItem('generaltrxId', this.paymentinfo.trxid.toString());

           // this.paymentDone = true;
            // this.paymentObjectRedirect = res?.content;
            //this.isLoading = false;
            // window.location.href = '';
            //window.open(res?.content?.formUrl, '_blank');
            window.location.href = res?.content?.formUrl;
            // window.location.href = res?.content?.formUrl;
            // const newTab = window.open();
            // newTab.document.open();
            // newTab.document.write(res?.content);
            // newTab.document.close();
            // let child = window.open('about:blank', 'myChild');
            // child?.document?.write(res?.content);
            // child?.document?.close();
           /* setTimeout(() => {
                window.location.href = '';
            }, 5000);*/
        }
    }
      languageSwitcher(language): void {
       
        this.translate.use(language);

        this.translate.use(language);
        localStorage.setItem('language', language);

        this.changeLang.emit();
        this.languageValue = localStorage.getItem('language');
         this.languageHandler();
    }
     languageHandler(): void {
        let localLang = localStorage.getItem('language');
        if (localLang) {
            this.translate.use(localLang);
            this.translate.currentLang = localLang;
            // this.authService.switchLanguage(localLang).subscribe((res) => {});
        } else {
            this.translate.use('ar-SA');
            // this.authService.switchLanguage('ar-SA').subscribe((res) => {});
        }
        this.languageValue = localStorage.getItem('language');
    }
}
export interface PaymentInfo {
    policyno: number;
    endno: number;
    lob: string;
    product: string;
    clientname: string;
    premium: number;
    vat: number;
    discount: number;
    totalpremium: number;
    additionalinfo: string;
    paymentstatus: string;
    paymentexpirydate: Date;
    policyholderid: string;
    paymenttrxid: string;
    trxid: number;
    mobilE_NO: string;
    lang: string;
    issmssent: number;
}
