<section style="background-color: #eee;  top: 0">
    <div class="container-md py-5">
        <div class="row">
            <div class="col-md-4 text-center">

            </div>
            <div class="col-md-4 text-center">
                <div class="card">
                    <div class="card-body">

                        <br />
                        <div class="row d-flex justify-content-center pb-5">

                            <div class="col-md-12 text-center">
                                <img src="assets//img/logo/disconnect.jpg" alt="logo" />
                            </div>
                            <div class="col-md-12 text-center">
                                <p style="color:darkred; font-size:2rem">
                                    <i class="fas fa-exclamation-triangle"></i> 
                                </p>

                                <p style="color:darkred; font-size:1rem">
                                    <strong>{{ "AfterPayment.AccessError" | translate}}</strong> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 text-center">

            </div>
        </div>
     
    </div>
</section>
