<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'NAV.SERVICES' | translate}}</h2>
            <ul>
                <li><a routerLink="/">{{'NAV.SERVICES' | translate}}</a></li>
            </ul>
        </div>
    </div>
</div>


<!-- End Page Title Area -->


<!-- Start Featured Services Area -->
<section class="featured-services-area pt-70 pb-70">
    <div class="container">
        <!-- <div class="section-title">
            <h2>{{'SERVICES.Featured' | translate}}</h2>
        </div> -->
        <div class="row" style="align-items: center;">
            
            <div class="col-lg-1 col-md-1 col-sm-1"></div>
            <div class="col-lg-1 col-md-2 col-2">
                <div class="col-lg-4 col-md-4 col-5 up-down-arrow">
                    <img src="assets/img/home/next.png" width="15" height="15" alt="">
                </div>
                <div class="col-lg-4 col-md-4 col-5 up-down-arrow">
                    <img src="assets/img/home/down.png" width="15" height="15" alt="">
                </div>
            </div>

            <div class="col-lg-4 col-md-8 col-sm-6 col-8">
                <div class="services-container col-lg-11 col-md-11">
                    <div style="display: flex;align-items: center;">
                        <span class="divider"></span>
                        <button type="button" routerLink="/medical-profile"
                        class="col-lg-11 col-md-11 col-12 btn btn-default btn-lg btn-block left-icon-holder service-button">
                            {{'SERVICES.MEDICAL' | translate}}
                        </button>
                        <img width="75" height="75" *ngIf="this.translate.currentLang == 'en-US'"
                        style="position: relative; margin-left: -15%; margin-top: -3%;" 
                        src="assets/img/home/Path 21.png" alt="Image">

                        <img width="75" height="75" *ngIf="this.translate.currentLang != 'en-US'"
                        style="position: relative; margin-right: -12%; margin-top: -3%; margin-left: -15%;" 
                        src="assets/img/home/Path-rtl.png" alt="Image">

                        <span class="button-icon"><i class="fa fa-medkit fa-2x btn-label-car btn-icon"></i></span>
                    </div>
                    
                    <div style="display: flex;align-items: center;" *ngIf="!selectedService">
                        <span class="divider"></span>
                        <button type="button" (click)="ChooseClaimType()"
                        class="col-lg-11 col-md-11 col-12 btn btn-default btn-lg btn-block left-icon-holder service-button">
                        {{'SERVICES.MOTOR' | translate}}
                        </button>

                        <img width="75" height="75" *ngIf="this.translate.currentLang == 'en-US'"
                        style="position: relative; margin-left: -15%; margin-top: -3%;" 
                        src="assets/img/home/Path 21.png" alt="Image">

                        <img width="75" height="75" *ngIf="this.translate.currentLang != 'en-US'"
                        style="position: relative; margin-right: -12%; margin-top: -3%; margin-left: -15%;" 
                        src="assets/img/home/Path-rtl.png" alt="Image">

                        <span class="button-icon"><i class="fa fa-car fa-2x btn-label-car btn-icon"></i></span>
                    </div>

                    <div style="display: flex;align-items: center;" *ngIf="selectedService">
                        <span class="divider"></span>
                        <button type="button" (click)="ChooseClaimType()"
                        class="col-lg-11 col-md-11 col-12 btn btn-default btn-lg btn-block left-icon-holder service-button-active">
                        {{'SERVICES.MOTOR' | translate}}
                        </button>

                        <img width="75" height="75" *ngIf="this.translate.currentLang == 'en-US'"
                        style="position: relative; margin-left: -15%; margin-top: -3%;" 
                        src="assets/img/home/Path 21.png" alt="Image">

                        <img width="75" height="75" *ngIf="this.translate.currentLang != 'en-US'"
                        style="position: relative; margin-right: -12%; margin-top: -3%; margin-left: -15%;" 
                        src="assets/img/home/Path-rtl.png" alt="Image">

                        <span class="button-icon"><i class="fa fa-car fa-2x btn-label-car btn-icon"></i></span>
                    </div>

                    <div style="display: flex;align-items: center;">
                        <span class="divider"></span>
                        <button type="button"
                        class="col-lg-11 col-md-11 col-12 btn btn-default btn-lg btn-block left-icon-holder service-button">
                        {{'SERVICES.TRAVEL' | translate}}
                        </button>

                        <img width="75" height="75" *ngIf="this.translate.currentLang == 'en-US'"
                        style="position: relative; margin-left: -15%; margin-top: -3%;" 
                        src="assets/img/home/Path 21.png" alt="Image">

                        <img width="75" height="75" *ngIf="this.translate.currentLang != 'en-US'"
                        style="position: relative; margin-right: -12%; margin-top: -3%; margin-left: -15%;" 
                        src="assets/img/home/Path-rtl.png" alt="Image">

                        <span class="button-icon"><i class="fa fa-plane fa-2x btn-label btn-icon"></i></span>
                    </div>

                    <div style="display: flex;align-items: center;">
                        <span class="divider"></span>
                        <button type="button"
                        class="col-lg-11 col-md-11 col-12 btn btn-default btn-lg btn-block left-icon-holder service-button">
                        {{'NAV.HOME' | translate}}
                        </button>
                        
                        <img width="75" height="75" *ngIf="this.translate.currentLang == 'en-US'"
                        style="position: relative; margin-left: -15%; margin-top: -3%;" 
                        src="assets/img/home/Path 21.png" alt="Image">

                        <img width="75" height="75" *ngIf="this.translate.currentLang != 'en-US'"
                        style="position: relative; margin-right: -12%; margin-top: -3%; margin-left: -15%;" 
                        src="assets/img/home/Path-rtl.png" alt="Image">

                        <span class="button-icon"><i class="fa fa-home fa-2x btn-label btn-icon"></i></span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-11 parent">
                <div class="thumbnail">
                    <p></p>
                    <img class="body-image" src="assets/img/home/home-body.png" alt="Image">
                    <div class="caption">
                        <h4>{{'SERVICES.HOMESERVICES' | translate}}</h4>
                        <span>
                            <div> <img src="assets/img/home/info (1).png" width="12" height="12" alt=""> 
                                {{'SERVICES.POLICYINFO' | translate}}</div>
                            <div> <img src="assets/img/home/printer.png" width="12" height="12" alt=""> 
                                 {{'SERVICES.PRINTING' | translate}}</div>
                        </span>

                        <span>
                            <div> <img src="assets/img/home/trash.png" width="12" height="12" alt=""> 
                                 {{'SERVICES.CANCELATION' | translate}}</div>
                            <div> <img src="assets/img/home/shopping-bag (2).png" width="12" height="12" alt="">
                                {{'SERVICES.BUYONLINE' | translate}}</div>
                        </span>

                    </div>
                </div>
                <!-- <div class="featured-services-box">
                    <a routerLink="/register-complaint">
                        <img src="assets/img/services/complaint1.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-insurance"></i>
                        <h3><a routerLink="/register-complaint">{{'SERVICES.COMPLAINT' | translate}}</a></h3>
                    </div>
                </div>  -->
            </div>
            <div class="col-lg-1 col-md-1">
            </div>
<!-- 
            <div class="featured-services-title">
                <i class="flaticon-car-insurance"></i>
                <h3><a routerLink="/motor-claims">{{'SERVICES.CLAIMS' | translate}}</a></h3>
            </div> -->

            <!-- <div class="col-lg-4 col-md-4">
                <div class="featured-services-box">
                    <a href="https://medical.gulfunion-saudi.com/" >
                        <img src="assets/img/services/MEDICAL.png" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-medical"></i>
                        <h3><a href="https://medical.gulfunion-saudi.com/">{{'SERVICES.MEDICAL' | translate}}</a></h3>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Featured Services Area -->

<!-- Start Get Quote Area -->
<!--<section class="get-quote-area get-quote-area-two services-page pb-100">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-lg-6 col-md-12 p-0">-->
<!--                <div class="get-quote-img jarallax" data-jarallax='{"speed": 0.3}'>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-6 col-md-12 p-0">-->
<!--                <div class="tab get-quote-tab">-->
<!--                    <div class="section-title">-->
<!--                        <span>Get Quote</span>-->
<!--                        <h2>Get Insurance Quote</h2>-->
<!--                    </div>-->

<!--                    <ul class="tabs">-->
<!--                        <li>Life Insurance </li>-->
<!--                        <li>Home Insurance</li>-->
<!--                        <li>Auto Insurance</li>-->
<!--                        <li>Travel Insurance</li>-->
<!--                    </ul>-->

<!--                    <div class="tab_content">-->
<!--                        <div class="tabs_item">-->
<!--                            <form class="get-quote-form">-->
<!--                                <div class="we-area-title">-->
<!--                                    <h3>Life Insurance Quote</h3>-->
<!--                                </div>-->

<!--                                <div class="row">-->
<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <div class="all-skill-bar">-->
<!--                                            <div class="skill-bar" data-percentage="25%">-->
<!--                                                <h4 class="progress-title-holder">-->
<!--                                                    <span class="progress-title">Leave of Protection:</span>-->
<!--                                                    <span class="usd">$180.00</span>-->
<!--                                                    <span class="progress-number-wrapper">-->
<!--                                                        <span class="progress-number-mark">-->
<!--                                                            <span class="percent"></span>-->
<!--                                                            <span class="down-arrow"></span>-->
<!--                                                        </span>-->
<!--                                                    </span>-->
<!--                                                </h4>-->
<!--                                                <div class="progress-content-outter">-->
<!--                                                    <div class="progress-content"></div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <span class="from-title">Personal Information:</span>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <select>-->
<!--                                                <option value="1">Employment Status</option>-->
<!--                                                <option value="2">Services Man</option>-->
<!--                                                <option value="3">Management</option>-->
<!--                                                <option value="4">Receiver</option>-->
<!--                                                <option value="5">Cline</option>-->
<!--                                            </select>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <select>-->
<!--                                                <option value="1">Your Age</option>-->
<!--                                                <option value="2">29</option>-->
<!--                                                <option value="3">18</option>-->
<!--                                                <option value="4">22</option>-->
<!--                                                <option value="5">30</option>-->
<!--                                            </select>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <span class="from-title">Contact Details:</span>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" id="First-Name-1" placeholder="Name">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="email" class="form-control" id="Email-1" placeholder="Email">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <button type="submit" class="default-btn">Get a Quote</button>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </form>-->
<!--                        </div>-->

<!--                        <div class="tabs_item">-->
<!--                            <form class="get-quote-form">-->
<!--                                <div class="we-area-title">-->
<!--                                    <h3>Home Insurance Quote</h3>-->
<!--                                </div>-->

<!--                                <div class="row">-->
<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <div class="all-skill-bar">-->
<!--                                            <div class="skill-bar" data-percentage="25%">-->
<!--                                                <h4 class="progress-title-holder">-->
<!--                                                    <span class="progress-title">Leave of Protection:</span>-->
<!--                                                    <span class="usd">$180.00</span>-->
<!--                                                    <span class="progress-number-wrapper">-->
<!--                                                        <span class="progress-number-mark">-->
<!--                                                            <span class="percent"></span>-->
<!--                                                            <span class="down-arrow"></span>-->
<!--                                                        </span>-->
<!--                                                    </span>-->
<!--                                                </h4>-->
<!--                                                <div class="progress-content-outter">-->
<!--                                                    <div class="progress-content"></div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <span class="from-title">Personal Information:</span>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <select>-->
<!--                                                <option value="1">Employment Status</option>-->
<!--                                                <option value="2">Services Man</option>-->
<!--                                                <option value="3">Management</option>-->
<!--                                                <option value="4">Receiver</option>-->
<!--                                                <option value="5">Cline</option>-->
<!--                                            </select>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <select>-->
<!--                                                <option value="1">Your Age</option>-->
<!--                                                <option value="2">29</option>-->
<!--                                                <option value="3">18</option>-->
<!--                                                <option value="4">22</option>-->
<!--                                                <option value="5">30</option>-->
<!--                                            </select>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <span class="from-title">Contact Details:</span>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" id="First-Name-1" placeholder="Name">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="email" class="form-control" id="Email-1" placeholder="Email">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <button type="submit" class="default-btn">Get a Quote</button>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </form>-->
<!--                        </div>-->

<!--                        <div class="tabs_item">-->
<!--                            <form class="get-quote-form">-->
<!--                                <div class="we-area-title">-->
<!--                                    <h3>Auto Insurance Quote</h3>-->
<!--                                </div>-->

<!--                                <div class="row">-->
<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <div class="all-skill-bar">-->
<!--                                            <div class="skill-bar" data-percentage="25%">-->
<!--                                                <h4 class="progress-title-holder">-->
<!--                                                    <span class="progress-title">Leave of Protection:</span>-->
<!--                                                    <span class="usd">$180.00</span>-->
<!--                                                    <span class="progress-number-wrapper">-->
<!--                                                        <span class="progress-number-mark">-->
<!--                                                            <span class="percent"></span>-->
<!--                                                            <span class="down-arrow"></span>-->
<!--                                                        </span>-->
<!--                                                    </span>-->
<!--                                                </h4>-->
<!--                                                <div class="progress-content-outter">-->
<!--                                                    <div class="progress-content"></div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <span class="from-title">Personal Information:</span>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <select>-->
<!--                                                <option value="1">Employment Status</option>-->
<!--                                                <option value="2">Services Man</option>-->
<!--                                                <option value="3">Management</option>-->
<!--                                                <option value="4">Receiver</option>-->
<!--                                                <option value="5">Cline</option>-->
<!--                                            </select>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <select>-->
<!--                                                <option value="1">Your Age</option>-->
<!--                                                <option value="2">29</option>-->
<!--                                                <option value="3">18</option>-->
<!--                                                <option value="4">22</option>-->
<!--                                                <option value="5">30</option>-->
<!--                                            </select>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <span class="from-title">Contact Details:</span>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" id="First-Name-1" placeholder="Name">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="email" class="form-control" id="Email-1" placeholder="Email">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <button type="submit" class="default-btn">Get a Quote</button>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </form>-->
<!--                        </div>-->

<!--                        <div class="tabs_item">-->
<!--                            <form class="get-quote-form">-->
<!--                                <div class="we-area-title">-->
<!--                                    <h3>Travel Insurance Quote</h3>-->
<!--                                </div>-->

<!--                                <div class="row">-->
<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <div class="all-skill-bar">-->
<!--                                            <div class="skill-bar" data-percentage="25%">-->
<!--                                                <h4 class="progress-title-holder">-->
<!--                                                    <span class="progress-title">Leave of Protection:</span>-->
<!--                                                    <span class="usd">$180.00</span>-->
<!--                                                    <span class="progress-number-wrapper">-->
<!--                                                        <span class="progress-number-mark">-->
<!--                                                            <span class="percent"></span>-->
<!--                                                            <span class="down-arrow"></span>-->
<!--                                                        </span>-->
<!--                                                    </span>-->
<!--                                                </h4>-->
<!--                                                <div class="progress-content-outter">-->
<!--                                                    <div class="progress-content"></div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <span class="from-title">Personal Information:</span>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <select>-->
<!--                                                <option value="1">Employment Status</option>-->
<!--                                                <option value="2">Services Man</option>-->
<!--                                                <option value="3">Management</option>-->
<!--                                                <option value="4">Receiver</option>-->
<!--                                                <option value="5">Cline</option>-->
<!--                                            </select>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <select>-->
<!--                                                <option value="1">Your Age</option>-->
<!--                                                <option value="2">29</option>-->
<!--                                                <option value="3">18</option>-->
<!--                                                <option value="4">22</option>-->
<!--                                                <option value="5">30</option>-->
<!--                                            </select>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-12 col-sm-12">-->
<!--                                        <span class="from-title">Contact Details:</span>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" id="First-Name-1" placeholder="Name">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="email" class="form-control" id="Email-1" placeholder="Email">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <div class="form-group">-->
<!--                                            <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="col-lg-6 col-sm-6">-->
<!--                                        <button type="submit" class="default-btn">Get a Quote</button>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </form>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<!-- End Get Quote Area -->
