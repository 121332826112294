<loading *ngIf="isLoading || isSubmit">{{ isLoading }}</loading>
<div class="container mt-5" #container>
    <div>
        <div class="row content">
            <div class="col-12 main-style">
                <div class="row mb-3">
                    <mat-stepper
                        ngClass="{{
                            'last-edited-step-' + stepperInquiry.selectedIndex
                        }}"
                        labelPosition="bottom"
                        [orientation]="(stepperOrientation | async)!"
                        #stepperInquiry
                    >
                        <ng-template matStepperIcon="edit">
                            <mat-icon>done</mat-icon>
                        </ng-template>

                        <!--01-->
                        <mat-step [stepControl]="idForm">
                            <ng-template matStepLabel>
                                {{ "MMLHome.IDVerification" | translate }}
                            </ng-template>

                            <div class="claim-form mt-5">
                                <legend
                                    class="claim-title"
                                    style="
                                        padding-left: 30px;
                                        padding-right: 30px;
                                    "
                                >
                                    {{ "MMLHome.IDVerification" | translate }}
                                </legend>
                                <hr />
                                <div class="row">
                                    <div
                                        class="col-md-6 col-sm-12"
                                        style="
                                            padding-left: 40px;
                                            padding-right: 40px;
                                            padding-bottom: 25px;
                                        "
                                    >
                                        <form [formGroup]="idForm">
                                            <!--(ngSubmit)="" -->

                                            <!-- Claim Processed By -->

                                            <!-- Driver Id / Iqama -->

                                            <div class="mb-4 mt-5">
                                                <div class="row">
                                                    <div class="col-md-4 pt-2">
                                                        <label
                                                            for="driverId"
                                                            class="form-label"
                                                            >{{
                                                                "MMLHome.ID"
                                                                    | translate
                                                            }}:</label
                                                        >
                                                    </div>
                                                    <div class="col-md-7">
                                                        <input
                                                            #input
                                                            id="iqama"
                                                            name="iqama"
                                                            type="text"
                                                            maxlength="10"
                                                            (input)="
                                                                handleIqamaNumberChange(
                                                                    $event
                                                                )
                                                            "
                                                            onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '');"
                                                            formControlName="Iqama"
                                                            [ngClass]="{
                                                                'red-border-class':
                                                                    idFormControl
                                                                        .Iqama
                                                                        .errors &&
                                                                    (idFormControl
                                                                        .Iqama
                                                                        .touched ||
                                                                        isSubmit),
                                                                'form-control':
                                                                    defaultClassFlag ==
                                                                    true,
                                                                'input-form-design':
                                                                    defaultClassFlag ==
                                                                    true
                                                            }"
                                                            class="form-control input-form-design"
                                                            pattern="[0-9]+"
                                                        />
                                                        <div
                                                            *ngIf="
                                                                idFormControl
                                                                    .Iqama
                                                                    .errors &&
                                                                (idFormControl
                                                                    .Iqama
                                                                    .touched ||
                                                                    isSubmit)
                                                            "
                                                            class=""
                                                        >
                                                            <div
                                                                *ngIf="
                                                                    idFormControl
                                                                        .Iqama
                                                                        .errors
                                                                        .required
                                                                "
                                                                style="
                                                                    color: #ff001f;
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {{
                                                                    "GENERIC.REQUIRED"
                                                                        | translate
                                                                }}
                                                            </div>
                                                            <div
                                                                *ngIf="
                                                                    idFormControl
                                                                        .Iqama
                                                                        .errors
                                                                        .pattern
                                                                "
                                                                style="
                                                                    color: #ff001f;
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {{
                                                                    "GENERIC.DRIVER_ID_PLACEHOLDER"
                                                                        | translate
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--Mobile Number-->
                                            <div class="mb-4">
                                                <div class="row mt-2">
                                                    <div class="col-md-4 pt-2">
                                                        <label
                                                            for="driverId"
                                                            class="form-label"
                                                            >{{
                                                                "MEDICAL.MobileNumber"
                                                                    | translate
                                                            }}:</label
                                                        >
                                                    </div>
                                                    <div class="col-md-7">
                                                        <input
                                                            #input
                                                            id="MMLMobileNumber"
                                                            name="MMLMobileNumber"
                                                            type="text"
                                                            maxlength="10"
                                                            onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                            formControlName="MobileNumber"
                                                            [ngClass]="{
                                                                'red-border-class':
                                                                    idFormControl
                                                                        .MobileNumber
                                                                        .errors &&
                                                                    (idFormControl
                                                                        .MobileNumber
                                                                        .touched ||
                                                                        isSubmit),
                                                                'form-control':
                                                                    defaultClassFlag ==
                                                                    true,
                                                                'input-form-design':
                                                                    defaultClassFlag ==
                                                                    true
                                                            }"
                                                            class="form-control input-form-design"
                                                            pattern="[0-9]+"
                                                        />
                                                        <div
                                                            *ngIf="
                                                                idFormControl
                                                                    .MobileNumber
                                                                    .errors &&
                                                                (idFormControl
                                                                    .MobileNumber
                                                                    .touched ||
                                                                    isSubmit)
                                                            "
                                                            class=""
                                                        >
                                                            <div
                                                                *ngIf="
                                                                    idFormControl
                                                                        .MobileNumber
                                                                        .errors
                                                                        .required
                                                                "
                                                                style="
                                                                    color: #ff001f;
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {{
                                                                    "GENERIC.REQUIRED"
                                                                        | translate
                                                                }}
                                                            </div>
                                                            <div
                                                                *ngIf="
                                                                    idFormControl
                                                                        .MobileNumber
                                                                        .errors
                                                                        .pattern
                                                                "
                                                                style="
                                                                    color: #ff001f;
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {{
                                                                    "GENERIC.MN_PLACEHOLDER"
                                                                        | translate
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--BirthDate-->
                                            <div class="mb-5" *ngIf="isSA">
                                                <div
                                                    class="row"
                                                    style="margin-top: -8px"
                                                >
                                                    <div class="col-md-4 pt-2">
                                                        <label
                                                            for="driverId"
                                                            class="form-label"
                                                            >{{
                                                                "MMLHome.Birthdate"
                                                                    | translate
                                                            }}:</label
                                                        >
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div
                                                            class="row justify-content-between"
                                                        >
                                                            <div
                                                                class="col-3 pt-1"
                                                            >
                                                                <input
                                                                    #input
                                                                    id="BirthDateDay"
                                                                    name="BirthDateDay"
                                                                    type="text"
                                                                    type="number"
                                                                    maxlength="2"
                                                                    onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                    formControlName="BirthDateDay"
                                                                    [ngClass]="{
                                                                        'red-border-class':
                                                                            idFormControl
                                                                                .BirthDateDay
                                                                                .errors &&
                                                                            (idFormControl
                                                                                .BirthDateDay
                                                                                .touched ||
                                                                                isSubmit),
                                                                        '':
                                                                            defaultClassFlag ==
                                                                            true,
                                                                        'mmp-input-form-design':
                                                                            defaultClassFlag ==
                                                                            true
                                                                    }"
                                                                    class="mmp-input-form-design"
                                                                    placeholder="DD"
                                                                    style="
                                                                        font-size: 12px;
                                                                        display: flex;
                                                                        text-align: center;
                                                                    "
                                                                    pattern="\d*"
                                                                />
                                                            </div>
                                                            <div
                                                                class="col-1 pt-2"
                                                                style="
                                                                    font-size: 20px;
                                                                    font-weight: 500;
                                                                "
                                                            >
                                                                /
                                                            </div>
                                                            <div
                                                                class="col-3 pt-1"
                                                            >
                                                                <input
                                                                    #input
                                                                    id="BirthDateMonth"
                                                                    name="BirthDateMonth"
                                                                    type="text"
                                                                    type="number"
                                                                    maxlength="2"
                                                                    onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                    formControlName="BirthDateMonth"
                                                                    [ngClass]="{
                                                                        'red-border-class':
                                                                            idFormControl
                                                                                .BirthDateMonth
                                                                                .errors &&
                                                                            (idFormControl
                                                                                .BirthDateMonth
                                                                                .touched ||
                                                                                isSubmit),
                                                                        '':
                                                                            defaultClassFlag ==
                                                                            true,
                                                                        'mmp-input-form-design':
                                                                            defaultClassFlag ==
                                                                            true
                                                                    }"
                                                                    class="mmp-input-form-design"
                                                                    placeholder="MM"
                                                                    style="
                                                                        font-size: 12px;
                                                                        display: flex;
                                                                        text-align: center;
                                                                    "
                                                                    pattern="\d*"
                                                                />
                                                            </div>
                                                            <div
                                                                class="col-1 pt-2"
                                                                style="
                                                                    font-size: 20px;
                                                                    font-weight: 500;
                                                                "
                                                            >
                                                                /
                                                            </div>
                                                            <div
                                                                class="col-3 pt-1"
                                                            >
                                                                <input
                                                                    #input
                                                                    id="BirthDateYear"
                                                                    name="BirthDateYear"
                                                                    type="email"
                                                                    maxlength="4"
                                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                    formControlName="BirthDateYear"
                                                                    [ngClass]="{
                                                                        'red-border-class':
                                                                            idFormControl
                                                                                .BirthDateYear
                                                                                .errors &&
                                                                            (idFormControl
                                                                                .BirthDateYear
                                                                                .touched ||
                                                                                isSubmit),
                                                                        '':
                                                                            defaultClassFlag ==
                                                                            true,
                                                                        'mmp-input-form-design':
                                                                            defaultClassFlag ==
                                                                            true
                                                                    }"
                                                                    class="mmp-input-form-design"
                                                                    placeholder="YYYY"
                                                                    style="
                                                                        font-size: 12px;
                                                                        display: flex;
                                                                        text-align: center;
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--Sponsor Number-->
                                            <div class="mb-5" *ngIf="!isSA">
                                                <div class="row">
                                                    <div class="col-md-4 pt-2">
                                                        <label
                                                            for="driverId"
                                                            class="form-label"
                                                            >{{
                                                                "MEDICAL.SponsorNumber"
                                                                    | translate
                                                            }}:</label
                                                        >
                                                    </div>
                                                    <div class="col-md-7">
                                                        <input
                                                            #input
                                                            id="MMLSponsorNumber"
                                                            name="MMLSponsorNumber"
                                                            type="text"
                                                            maxlength="10"
                                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '');"
                                                            formControlName="SponsorNumber"
                                                            [ngClass]="{
                                                                'red-border-class':
                                                                    idFormControl
                                                                        .SponsorNumber
                                                                        .errors &&
                                                                    (idFormControl
                                                                        .SponsorNumber
                                                                        .touched ||
                                                                        isSubmit),
                                                                'form-control':
                                                                    defaultClassFlag ==
                                                                    true,
                                                                'input-form-design':
                                                                    defaultClassFlag ==
                                                                    true
                                                            }"
                                                            class="form-control input-form-design"
                                                            pattern="[0-9]+"
                                                        />

                                                        <div
                                                            *ngIf="
                                                                idFormControl
                                                                    .SponsorNumber
                                                                    .errors &&
                                                                (idFormControl
                                                                    .SponsorNumber
                                                                    .touched ||
                                                                    isSubmit)
                                                            "
                                                            class=""
                                                        >
                                                            <div
                                                                *ngIf="
                                                                    idFormControl
                                                                        .SponsorNumber
                                                                        .errors
                                                                        .required
                                                                "
                                                                style="
                                                                    color: #ff001f;
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {{
                                                                    "GENERIC.REQUIRED"
                                                                        | translate
                                                                }}
                                                            </div>
                                                            <div
                                                                *ngIf="
                                                                    idFormControl
                                                                        .SponsorNumber
                                                                        .errors
                                                                        .pattern
                                                                "
                                                                style="
                                                                    color: #ff001f;
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {{
                                                                    "GENERIC.SN_PLACEHOLDER"
                                                                        | translate
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-2 d-flex">
                                                <mat-checkbox
                                                    class="mt-4 user-agreement-confirm"
                                                    formControlName="save"
                                                    id="save2"
                                                    [checked]="
                                                        idFormControl.save.value
                                                    "
                                                >
                                                </mat-checkbox>
                                                <p
                                                    style="
                                                        padding-left: 12px !important;
                                                        padding-right: 12px !important;
                                                        padding-top: 22px;
                                                        font-size: 18px;
                                                        color: #777676;
                                                    "
                                                >
                                                    {{
                                                        "MMLHome.idCheckBox"
                                                            | translate
                                                    }}
                                                </p>
                                            </div>

                                            <!-- Next & back Buttons -->

                                            <div
                                                class="mt-3 id-next"
                                                [ngClass]="{
                                                    'mmp-next-btn-container-en':
                                                        translate.currentLang ==
                                                        'en-US',
                                                    'mmp-next-btn-container-ar':
                                                        translate.currentLang !=
                                                        'en-US'
                                                }"
                                                style="padding-right: 25px"
                                            >
                                                <div>
                                                    <button
                                                        mat-button
                                                        class="next-btn p-1"
                                                        style="
                                                            margin-right: 10px;
                                                        "
                                                        (click)="
                                                            submitIDVer(
                                                                stepperInquiry
                                                            )
                                                        "
                                                        [disabled]="
                                                            !idFormControl.save
                                                                .value
                                                        "
                                                        [ngClass]="{
                                                            'disabled-button':
                                                                !idFormControl
                                                                    .save.value
                                                        }"
                                                    >
                                                        {{
                                                            "GENERIC.NEXT"
                                                                | translate
                                                        }}
                                                        <i
                                                            class="fa fa-arrow-right"
                                                            aria-hidden="true"
                                                            *ngIf="
                                                                translate.currentLang ==
                                                                'en-US'
                                                            "
                                                        ></i>
                                                        <i
                                                            class="fa fa-arrow-left"
                                                            aria-hidden="true"
                                                            *ngIf="
                                                                translate.currentLang !=
                                                                'en-US'
                                                            "
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="col-md-1"></div>

                                    <div class="col-md-5">
                                        <div>
                                            <img
                                                class="form-ltr-image"
                                                *ngIf="
                                                    this.translate
                                                        .currentLang == 'en-US'
                                                "
                                                src="assets/img/MML/IDIMG.png"
                                                alt="aaa"
                                            />

                                            <img
                                                class="form-rtl-image"
                                                *ngIf="
                                                    this.translate
                                                        .currentLang != 'en-US'
                                                "
                                                src="assets/img/MML/IDIMG1.png"
                                                alt="aaa"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-step>

                        <!--Perview -->
                        <mat-step [stepControl]="">
                            <ng-template matStepLabel class="Stepperheader">
                                {{ "MMLHome.perview" | translate }}
                            </ng-template>

                            <div
                                class="claim-form p-5 mt-5"
                                style="max-height: fit-content"
                                *ngIf="policyData !== null"
                            >
                                <div class="d-flex justify-content-between">
                                    <legend
                                        class="claim-title"
                                        style="width: auto"
                                    >
                                        {{ "MMLHome.perview" | translate }}
                                    </legend>
                                </div>

                                <hr style="margin-top: 0" />

                                <div style="text-align: center" class="pb-5">
                                    <legend
                                        class="claim-title"
                                        style="font-size: 20px"
                                    >
                                        {{ "MMLHome.QutationInfo" | translate }}
                                    </legend>
                                </div>

                                <div class="row w-100 m-0">
                                    <div
                                        class="col-md-9 col-12 pt-2"
                                        style="padding: 0"
                                    >
                                        <div class="row w-100 m-0">
                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MEDICAL.PolicyNumber"
                                                        | translate
                                                }}
                                                :
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        policyData?.policyNumber
                                                    }}
                                                </p>
                                            </div>

                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            ></div>

                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.claimLimit"
                                                        | translate
                                                }}
                                                :

                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        "MEDICAL.KSACUR"
                                                            | translate
                                                    }}
                                                </p>
                                            </div>

                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.totalLimit"
                                                        | translate
                                                }}
                                                :
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        "MEDICAL.KSACUR"
                                                            | translate
                                                    }}
                                                </p>
                                            </div>

                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.mmpStartDate"
                                                        | translate
                                                }}
                                                :
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        policyData?.policyDateFrom
                                                            | date
                                                                : "dd/MM/yyyy"
                                                    }}
                                                </p>
                                            </div>

                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.mmpEndDate"
                                                        | translate
                                                }}
                                                :
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        policyData?.policyDateTo
                                                            | date
                                                                : "dd/MM/yyyy"
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-3 col-12 pt-2"
                                        style="text-align: end"
                                    >
                                        <img
                                            src="assets/img/MML/quotation.png"
                                            alt=""
                                            style="height: 220px"
                                        />
                                    </div>
                                </div>
                                <div style="text-align: center" class="pb-5">
                                    <legend
                                        class="claim-title"
                                        style="font-size: 20px"
                                    >
                                        {{
                                            "MMLHome.PersonalDetails"
                                                | translate
                                        }}
                                    </legend>
                                </div>

                                <div class="row w-100 m-0">
                                    <div
                                        class="col-md-9 col-12"
                                        style="padding: 0"
                                    >
                                        <div class="row w-100 m-0 pt-4 pb-5">
                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.Client" | translate
                                                }}
                                                :
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        policyData?.insuredName
                                                    }}
                                                </p>
                                            </div>

                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.idNumber"
                                                        | translate
                                                }}
                                                :
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        policyData?.insuredIqamaId
                                                    }}
                                                </p>
                                            </div>

                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.Birthdate"
                                                        | translate
                                                }}
                                                :
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        memberDetails?.birthDate
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.nationality"
                                                        | translate
                                                }}
                                                :
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        memberDetails?.nationality ??
                                                            policyData?.risks[0]
                                                                ?.insuredNationality
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.gender" | translate
                                                }}
                                                :
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        memberDetails?.gender ??
                                                            policyData?.risks[0]
                                                                ?.insuredGender
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-12 pt-2">
                                        <img
                                            src="assets/img/MML/per.png"
                                            alt=""
                                        />
                                    </div>
                                </div>

                                <div style="text-align: center" class="pb-5">
                                    <legend
                                        class="claim-title"
                                        style="font-size: 20px"
                                    >
                                        {{ "MMLHome.Covers" | translate }}
                                    </legend>
                                </div>

                                <div class="row w-100 m-0">
                                    <div
                                        class="col-md-9 col-12"
                                        style="padding: 0"
                                    >
                                        <!--Covers-->

                                        <ul>
                                            <div class="row w-100 m-0 pb-5">
                                                <div
                                                    class="col-md-6 col-12 pt-2"
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        color: #777676;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                    *ngFor="
                                                        let cat of includeObjectPreview
                                                    "
                                                >
                                                    <li>
                                                        {{ cat?.name }}
                                                    </li>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                <!--Premium -->

                                <div style="text-align: center" class="pb-5">
                                    <legend
                                        class="claim-title"
                                        style="font-size: 20px"
                                    >
                                        {{ "MMLHome.premBreak" | translate }}
                                    </legend>
                                </div>

                                <div class="row w-100 m-0">
                                    <div
                                        class="col-md-9 col-12"
                                        style="padding: 0"
                                    >
                                        <div class="row w-100 m-0 pt-4 pb-5">
                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{ "MMLHome.PS1" | translate }}
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        policyData?.policyPremium
                                                            | number
                                                    }}

                                                    {{
                                                        "MEDICAL.KSACUR"
                                                            | translate
                                                    }}
                                                </p>
                                            </div>

                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{ "MMLHome.PS2" | translate }}
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        policyData?.policyVatAmount
                                                            | number
                                                    }}

                                                    {{
                                                        "MEDICAL.KSACUR"
                                                            | translate
                                                    }}
                                                </p>
                                            </div>

                                            <div
                                                class="col-md-6 col-12 pt-4"
                                                style="
                                                    padding-left: 0px;
                                                    padding-right: 0px;
                                                    font-size: 18px;
                                                    display: flex;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{ "MMLHome.PS3" | translate }}
                                                <p
                                                    style="
                                                        padding-left: 0px;
                                                        padding-right: 0px;
                                                        font-size: 18px;
                                                        font-weight: 600;
                                                        color: black;
                                                        margin-top: -4px;
                                                    "
                                                    [ngClass]="{
                                                        fs:
                                                            this.translate
                                                                .currentLang ===
                                                            'en-US',
                                                        'fs-ar':
                                                            this.translate
                                                                .currentLang ===
                                                            'ar-SA'
                                                    }"
                                                >
                                                    {{
                                                        policyData?.policyTotalPremium
                                                            | number
                                                    }}

                                                    {{
                                                        "MEDICAL.KSACUR"
                                                            | translate
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-12 pt-2">
                                        <img
                                            src="assets/img/MML/money.png"
                                            alt=""
                                            style="height: 220px"
                                        />
                                    </div>
                                </div>

                                <div
                                    class="d-flex mt-3 justify-content-end align-items-center"
                                    style="
                                        justify-content: center !important ;
                                        padding-bottom: 25px;
                                    "
                                >
                                    <div>
                                        <button
                                            class="btn default-btn"
                                            style="
                                                margin-bottom: 30px;
                                                margin-top: 30px;
                                                font-size: 18px;
                                                margin-left: 15px;
                                                margin-right: 15px;
                                            "
                                            *ngIf="
                                                translate.currentLang == 'en-US'
                                            "
                                            (click)="download('en')"
                                        >
                                            Download Policy
                                        </button>

                                        <button
                                            class="btn default-btn"
                                            style="
                                                margin-bottom: 30px;
                                                margin-top: 30px;
                                                font-size: 18px;
                                                margin-left: 15px;
                                                margin-right: 15px;
                                            "
                                            *ngIf="
                                                translate.currentLang != 'en-US'
                                            "
                                            (click)="download('ar')"
                                        >
                                            تحميل الوثيقة
                                        </button>

                                        <button
                                            class="btn default-btn"
                                            style="
                                                margin-bottom: 30px;
                                                margin-top: 30px;
                                                font-size: 18px;
                                                margin-left: 15px;
                                                margin-right: 15px;
                                            "
                                            (click)="download('report')"
                                        >
                                            {{ "MMLHome.report" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="claim-form p-5 mt-5"
                                style="max-height: fit-content"
                                *ngIf="policyData === null"
                            >
                                <div class="row mt-5 mb-5 text-center">
                                    <legend style="font-size: 24px">
                                        {{ "MMLHome.ActivePolicy" | translate }}
                                    </legend>
                                    <div
                                        class="row mt-5 mb-5 justify-content-center"
                                    >
                                        <button
                                            class="btn default-btn"
                                            style="
                                                margin-bottom: 30px;
                                                margin-top: 30px;
                                                font-size: 18px;
                                                margin-left: 15px;
                                                margin-right: 15px;
                                            "
                                            (click)="
                                                redirectToCreateQuotation()
                                            "
                                        >
                                            {{
                                                "SME.CREATEQUOTATION"
                                                    | translate
                                            }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </div>
            </div>
        </div>
    </div>
</div>
