<h6 mat-dialog-title style="color: black;">{{"LogIn.cancelPolicy" | translate}}</h6>
<div mat-dialog-content>
    <form  [formGroup]="DetailsForm">
        <!-- <div class="form-outline">
            <input autocomplete="false" type="text" id="form2Example11" required   class="form-control" maxlength="255"
            value="{{policyDetails.policyNo}}" />
        </div>
        <br />
        <div class="form-outline">
            <input autocomplete="false" type="text" id="form2Example11" required  class="form-control" maxlength="255"
            value="{{policyDetails.plateNo}}" />
        </div>
        <br />
        <div class="form-outline">
            <input autocomplete="false" type="text" id="form2Example11" required   class="form-control" maxlength="255"
            value="{{policyDetails.vehicleName}}" />
        </div>
        <br />
        <div class="form-outline">
            <input autocomplete="false" type="text" id="form2Example11" required   class="form-control" maxlength="255"
            value="{{policyDetails.policyDate}}" />
        </div>
        <br />
        <div class="form-outline">
            <input autocomplete="false" type="text" id="form2Example11" required  class="form-control" maxlength="255"
            value="{{policyDetails.idNo}}" />
        </div>
        <br />
        <div class="form-outline">
            <input autocomplete="false" type="text" id="form2Example11" required   class="form-control" maxlength="255"
            value="{{policyDetails.mobile}}" />
        </div> -->


    <br />
    <div class="form-outline mb-4">
        <label class="form-label" for="bankNum">IBAN</label>
    <input
    id="bankNum"
    name="bankNum"
    style="
        text-transform: uppercase;
    "
    type="text"
    maxlength="32"
    formControlName="iban"
    oninput="javascript:  var element = document.getElementById(id);    var regex = /[^SA][^0-9\-]/gi;        element.value = element.value.replace(regex, '');"
    class="form-control input-form-design"
/>

</div>
<br/>
<div class="form-outline mb-4">
    <mat-form-field style="height: 100%;width: 100%;">
        <mat-select class="form-outline mb-4" formControlName="cancellationReason"
            placeholder="{{'LogIn.CancellationReason' | translate}}" style="width: 100%;height: 35px;">
            <mat-option
                *ngFor="let food of foods"
                [value]="food.value"
formControlName="cancellationReason"
                >{{ food.viewValue }}</mat-option
            >
        </mat-select>
    </mat-form-field>
</div>
<br/>
<div class="form-outline mb-4">
    <label class="form-label" for="form2Example11">{{"LogIn.Remarks" | translate}} :</label>
    <input autocomplete="false" type="text" id="form2Example11" required  formControlName="remarks" class="form-control" maxlength="255"
    value="" />
</div>

</form>
</div>
<div mat-dialog-actions class="row">

        <div class="col-md-6">

            <button type="button" mat-dialog-close class="btn btn-rounded" style="background-color: darkred; color: white;" >{{"LogIn.Cancel" | translate}}  </button>
        </div>
        <div class="col-md-6" style="float: right;">
            <button type="button" class="btn btn-rounded" style="background-color:#226439; color:white;float:inline-end" (click)="saveCancellation()">{{"LogIn.SendRequest" | translate}} </button>

        </div>


</div>
