import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MmpService } from 'src/app/core/services/MMP-Service/mmp.service';
import Swal from 'sweetalert2';
import { StepperOrientation } from '@angular/cdk/stepper';
import { Observable, map } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';

interface IncludeInterface {
    id: any;
    name: any;
    isMandatory: any;
}

@Component({
    selector: 'app-mmp-inquiry',
    templateUrl: './mmp-inquiry.component.html',
    styleUrls: ['./mmp-inquiry.component.scss'],
})
export class MmpInquiryComponent implements OnInit {
    @ViewChild('stepperInquiry') private myStepper: MatStepper;

    policyData: any = null;
    idForm: FormGroup;
    isSA: any = true;
    mobilePattern = '^(05)([0-9]{8})$';
    IqamaPattern = /[12]+[0-9]{9}$/;
    birthDatePatternD = '^(0[1-9]|1|1[0-9]|2|2[0-9]|3|3[0]|4|5|6|7|8|9)$';
    birthDatePatternM = '^(0[1-9]|1|1[0-2]|4|5|6|7|8|9)$';
    birthDatePatternY = '^([1-9][0-9]{3})$';
    sponserPattern = /[127]+[0-9]{9}$/;
    retrievedObject = JSON.parse(localStorage.getItem('idObject'));
    selectedMember: any;
    memDetails: any;
    isLoading = true;
    includeObjectPreview: IncludeInterface[] = [];
    stepperOrientation: Observable<StepperOrientation>;
    isSubmit: boolean = false;
    defaultClassFlag: boolean = true;
    memberDetails: any;

    constructor(
        public translate: TranslateService,
        private router: Router,
        private _LocalService: LocalService,
        private mmpService: MmpService,
        private _router: Router,
        public fb: FormBuilder,
        private breakpointObserver: BreakpointObserver
    ) {
        this.stepperOrientation = breakpointObserver
            .observe('(min-width: 800px)')
            .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
    }

    ngOnInit(): void {
        this.buildIDFormForm();
        this.memDetails = this._LocalService.getJsonValue('memberDetails');
        if (this.memDetails) {
            this.selectedMember = this.memDetails[0];
        }
    }

    async ngAfterViewInit() {
        this.isLoading = true;
        this.policyData = await this.getActivePolicy();
        localStorage.removeItem('idObject');
        localStorage.removeItem('CitizenInfo');
        localStorage.removeItem('phNumberVerification');
    }

    get idFormControl() {
        return this.idForm?.controls;
    }

    handleIqamaNumberChange(e) {
        if (e.target.value.charAt(0) == 1) {
            this.isSA = true;

            this.idFormControl?.BirthDateDay.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternD),
            ]);
            this.idFormControl?.BirthDateDay.updateValueAndValidity();

            this.idFormControl?.BirthDateMonth.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternM),
            ]);
            this.idFormControl?.BirthDateMonth.updateValueAndValidity();

            this.idFormControl?.BirthDateYear.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternY),
            ]);
            this.idFormControl?.BirthDateYear.updateValueAndValidity();

            this.idFormControl?.SponsorNumber.clearValidators();
            this.idFormControl?.SponsorNumber.updateValueAndValidity();
        } else if (e.target.value.charAt(0) == 2) {
            this.isSA = false;
            this.idFormControl?.SponsorNumber.setValidators([
                Validators.required,
                Validators.pattern(this.sponserPattern),
            ]);
            this.idFormControl?.SponsorNumber.updateValueAndValidity();

            this.idFormControl?.BirthDateDay.clearValidators();
            this.idFormControl?.BirthDateDay.updateValueAndValidity();

            this.idFormControl?.BirthDateMonth.clearValidators();
            this.idFormControl?.BirthDateMonth.updateValueAndValidity();

            this.idFormControl?.BirthDateYear.clearValidators();
            this.idFormControl?.BirthDateYear.updateValueAndValidity();
        } else {
            this.isSA = true;

            this.idFormControl?.BirthDateDay.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternD),
            ]);
            this.idFormControl?.BirthDateDay.updateValueAndValidity();

            this.idFormControl?.BirthDateMonth.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternM),
            ]);
            this.idFormControl?.BirthDateMonth.updateValueAndValidity();

            this.idFormControl?.BirthDateYear.setValidators([
                Validators.required,
                Validators.pattern(this.birthDatePatternY),
            ]);
            this.idFormControl?.BirthDateYear.updateValueAndValidity();

            this.idFormControl?.SponsorNumber.clearValidators();
            this.idFormControl?.SponsorNumber.updateValueAndValidity();
        }
    }

    handleBirthDateDayAndMonthFormat(value) {
        switch (value) {
            case '1':
                return '01';
            case '2':
                return '02';
            case '3':
                return '03';
            case '4':
                return '04';
            case '5':
                return '05';
            case '6':
                return '06';
            case '7':
                return '07';
            case '8':
                return '08';
            case '9':
                return '09';

            default:
                return value;
        }
    }

    buildIDFormForm() {
        this.idForm = this.fb.group({
            Iqama: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.IqamaPattern),
                    Validators.maxLength(100),
                ],
            ],
            MobileNumber: [
                null,
                [Validators.required, Validators.pattern(this.mobilePattern)],
            ],
            BirthDateDay: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.birthDatePatternD),
                ],
            ],
            BirthDateMonth: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.birthDatePatternM),
                ],
            ],
            BirthDateYear: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.birthDatePatternY),
                ],
            ],
            SponsorNumber: [null],
            save: [false, [Validators.required]],

            //BirthDateY: [null, [Validators.required ,Validators.pattern(this.birthDatePatternM) ]],
        });
    }

    redirectToCreateQuotation() {
        this.router.navigate(['/medical-malpractice']);
    }

    async submitIDVer(stepper: MatStepper) {
        this.isSubmit = true;
        if (
            this.idForm.valid &&
            (this.isSA ||
                (!this.isSA &&
                    this.idForm.controls['SponsorNumber'].value !== null &&
                    this.idForm.controls['SponsorNumber'].value !== ''))
        ) {
            const userData = await this.getCitizenInfo();

            if (
                userData !== null &&
                userData?.data !== null &&
                userData?.errors?.length == 0
            ) {
                this.isSubmit = false;
                let idObject = {
                    Iqama: this.idForm.controls['Iqama'].value,
                    MobileNumber: this.idForm.controls['MobileNumber'].value,
                    BirthDateDay: this.idForm.controls['BirthDateDay'].value,
                    BirthDateMonth:
                        this.idForm.controls['BirthDateMonth'].value,
                    BirthDateYear: this.idForm.controls['BirthDateYear'].value,
                    SponsorNumber: this.idForm.controls['SponsorNumber'].value,
                };
                localStorage.setItem('idObject', JSON.stringify(idObject));
                localStorage.setItem('EligableForIDVerfication', 'true');
                localStorage.setItem(
                    'CitizenInfo',
                    JSON.stringify(this.memberDetails)
                );

                try {
                    let query =
                        'iqamaNumber=' +
                        this.idForm.controls['Iqama'].value +
                        '&mobileNumber=' +
                        this.idForm.controls['MobileNumber'].value +
                        '&isWeb=' + true + 
                        '&Lob=' + LineOfBusiness.MedicalMalPractice + 
                        '&Type=' + SMSActionType.Login;
                    let otpRes = await this.mmpService
                        .GetGenarateOTP(query)
                        .toPromise();
                    this._router.navigate([`/id-verification`]);
                } catch (err) {
                    this._router.navigate([`/id-verification`]);
                }
            }
        } else {
            let errorMessage: string = '';
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }
            let index: number = 1;
            if (this.idForm.controls['Iqama'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Id / Iqama is required!<br/>'
                        : index +
                        '- رقم الهوية الوطنية / رقم الإقامة يجب ان يبدأ برقم 1 او 2!<br/>';
                index++;
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Id / Iqama should start with 1 or 2!<br/>'
                        : index +
                        '- رقم الهوية الوطنية / رقم الإقامة يجب ان يبدأ برقم 1 او 2!<br/>';
                index++;
            } else if (this.idForm.controls['Iqama'].status === 'INVALID') {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Id / Iqama should start with 1 or 2!<br/>'
                        : index +
                        '- رقم الهوية الوطنية / رقم الإقامة يجب ان يبدأ برقم 1 او 2!<br/>';
                index++;
            }

            if (this.idForm.controls['MobileNumber'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Mobile Number is required!<br/>'
                        : index + '- رقم الهاتف مطلوب!<br/>';
                index++;
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Mobile Number should start with two letters then numbers!<br/>'
                        : index + '- رقم الهاتف يجب ان يبدأ 05!<br/>';
                index++;
            } else if (
                this.idForm.controls['MobileNumber'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Mobile Number should start with two letters then numbers!<br/>'
                        : index + '- رقم الهاتف يجب ان يبدأ 05 !<br/>';
                index++;
            }

            if (
                this.isSA &&
                (this.idForm.controls['BirthDateDay'].value == null ||
                    this.idForm.controls['BirthDateDay'].value == '')
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Birth Day is required!<br/>'
                        : index + '- يوم الميلاد مطلوب!<br/>';
                index++;
            } else if (
                this.idForm.controls['BirthDateDay'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Birth Day should start with 0 or 1 or 2 or 3 and max value is 30!<br/>'
                        : index +
                        '- يوم الميلاد يجب ان يبدأ ب 0 او 1 او 2 او 3 واكبر قيمة 30!<br/>';
                index++;
            }

            if (
                this.isSA &&
                (this.idForm.controls['BirthDateMonth'].value == null ||
                    this.idForm.controls['BirthDateMonth'].value == '')
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Birth Month is required!<br/>'
                        : index + '- شهر الميلاد مطلوب!<br/>';
                index++;
            } else if (
                this.idForm.controls['BirthDateMonth'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Birth Month should start with 0 or 1 and max value is 12!<br/>'
                        : index +
                        '- شهر الميلاد يجب ان يبدأ ب 0 او 1 واكبر قيمة 12 !<br/>';
                index++;
            }

            if (
                this.isSA &&
                (this.idForm.controls['BirthDateYear'].value == null ||
                    this.idForm.controls['BirthDateYear'].value == '')
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Birth Year is required!<br/>'
                        : index + '- سنة الميلاد مطلوب!<br/>';
                index++;
            } else if (
                this.idForm.controls['BirthDateYear'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Invalid Year Format !<br/>'
                        : index + '- تنسيق السنة غير صالح!<br/>';
                index++;
            }

            if (
                !this.isSA &&
                (this.idForm.controls['SponsorNumber'].value === null ||
                    this.idForm.controls['SponsorNumber'].value === '')
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Sponsor Number is required!<br/>'
                        : index + '- رقم الكفيل / المنشأه مطلوب!<br/>';
                index++;
            } else if (
                this.idForm.controls['SponsorNumber'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Sponsor Number should start with 1 or 2 or 7!<br/>'
                        : index +
                        '-   رقم الكفيل / المنشأه يجب ان يبدأ برقم 1 او 2 او 7  <br/>';
                index++;
            }

            this.ErrDialog(errorMessage);
        }
    }

    async getCitizenInfo() {
        try {
            this.isLoading = true;
            let birthDay = this.handleBirthDateDayAndMonthFormat(
                this.idForm.controls['BirthDateDay'].value
            );
            let birthMonth = this.handleBirthDateDayAndMonthFormat(
                this.idForm.controls['BirthDateMonth'].value
            );
            let birthDate = `${birthMonth}-${this.idForm.controls['BirthDateYear'].value}`;
            let queryString;
            if (this.idForm.controls['SponsorNumber'].value) {
                queryString = `identityNumber=${this.idForm.controls['Iqama'].value}&MobilePhone=${this.idForm.controls['MobileNumber'].value}&sponsor=${this.idForm.controls['SponsorNumber'].value}`;
            } else {
                queryString = `identityNumber=${this.idForm.controls['Iqama'].value}&MobilePhone=${this.idForm.controls['MobileNumber'].value}&dateOfBirth=${birthDate}`;
            }

            const res = await this.mmpService
                .GetCitizenInfoURLService(queryString)
                .toPromise();
debugger
            if (res) {
                if (
                    res !== null &&
                    res?.data !== null &&
                    res?.errors?.length == 0 &&
                    (res?.data.birthDate !== null ||
                        res?.data.birthDate !== undefined ||
                        birthDate) &&
                    (res?.data?.fullName !== null ||
                        res?.data.birthDate !== undefined ||
                        res?.data.birthDate !== '') 
                ) {
                    this.memberDetails = {
                        id: this.idForm.controls['Iqama'].value,
                        memberEName: res?.data.fullName,
                        birthDate: res?.data.birthDate
                            ? res?.data.birthDate
                            : `${this.handleBirthDateDayAndMonthFormat(
                                this.idForm.controls['BirthDateDay'].value
                            )}` +
                            '-' +
                            birthDate,
                        nationality: res?.data.nationalityCode
                            ? res?.data.nationalityCode
                            : 'SA',
                        YakeenTrxId: res?.data.yakeenTrxId,
                        nameAR: res?.data.fullNameAr,
                        nameEN: res?.data.fullNameEn,
                    };
                    if (
                        (res?.data.gender.toLowerCase() === 'm' ||
                            res?.data.gender.toLowerCase() === 'male') &&
                        this.translate.currentLang == 'en-US'
                    ) {
                        this.memberDetails = {
                            ...this.memberDetails,
                            gender: 'Male',
                        };
                    } else if (
                        (res?.data.gender.toLowerCase() === 'f' ||
                            res?.data.gender.toLowerCase() === 'female') &&
                        this.translate.currentLang == 'en-US'
                    ) {
                        this.memberDetails = {
                            ...this.memberDetails,
                            gender: 'Female',
                        };
                    } else if (
                        (res?.data.gender.toLowerCase() === 'm' ||
                            res?.data.gender.toLowerCase() === 'male') &&
                        this.translate.currentLang == 'ar-SA'
                    ) {
                        this.memberDetails = {
                            ...this.memberDetails,
                            gender: 'ذكر',
                        };
                    } else {
                        this.memberDetails = {
                            ...this.memberDetails,
                            gender: 'انثي',
                        };
                    }
                    this.isLoading = false;

                } else {
                    this.memberDetails = null;
                    this.isLoading = false;

                    if (res?.errors.length > 0) {
                        let msg;

                        for (
                            let index = 0;
                            index < res?.errors?.length;
                            index++
                        ) {
                            if (res?.errors?.error) {
                                msg += res?.errors?.error;
                                msg += '<br/>';
                            }
                        }
                        this.ErrDialog(msg);
                    } else if (res?.code == -1) {
                        this.ErrDialog(res?.message);
                    } else {
                        let msg = '';
                        if (this.translate.currentLang == 'en-US') {
                            msg = 'Error Getting User Data.';
                        } else {
                            msg = 'حدث خطأ أثناء الحصول على بيانات المستخدم';
                        }
                        this.ErrDialog(msg);
                    }
                }
                this.isLoading = false
                return res;
            }
        } catch (err) {
            this.memberDetails = null;

            this.isLoading = false;
            if (err?.code == -1) {
                this.ErrDialog(err?.message);
            } else if (err?.length > 0) {
                let msg = '';

                for (let index = 0; index < err?.length; index++) {
                    if (err[index]?.error) {
                        msg += `-` + err[index]?.error;
                        msg += '<br/>';
                    }
                }
                this.ErrDialog(msg);
            } else {
                let msg = '';
                if (this.translate.currentLang == 'en-US') {
                    msg = 'Error Getting User Data.';
                } else {
                    msg = 'حدث خطأ أثناء الحصول على بيانات المستخدم';
                }

                this.ErrDialog(msg);
            }
        }
        this.isLoading=false
        return null;
    }

    async getActivePolicy() {
        try {
            let Iqama = '';
            Iqama = this.idForm.controls['Iqama'].value
                ? this.idForm.controls['Iqama'].value
                : this.retrievedObject?.Iqama
                    ? this.retrievedObject?.Iqama
                    : this.selectedMember?.id;

            var queryString = '?insuredIqamaId=' + Iqama;
            if (Iqama) {
                const res = await this.mmpService
                    .GetActivePolicy(queryString)
                    .toPromise();
                for (
                    let index = 0;
                    index < res?.policyCovers?.length;
                    index++
                ) {
                    this.includeObjectPreview.push(res?.policyCovers[index]);
                }

                this.myStepper.next();
                this.isLoading = false; // Set isLoading to false regardless of success or error
                return res;
            }
        } catch (err) {
            console.log(err);

            if (this.idForm.valid) {
                this.ErrDialog(err.title);
            }
            this.isLoading = false; // Set isLoading to false regardless of success or error
            return null;
        } finally {
            this.isLoading = false; // Set isLoading to false regardless of success or error
        }
    }

    ErrDialog(msg) {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        Swal.fire({
            title: this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#FF001F; direction:" +
                dir +
                "'>" +
                msg +
                '</div>',
            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
                icon: 'submit-claim-failed-icon-img',
                title: 'submit-claim-failed-swal-title',
                confirmButton: 'submit-claim-failed-confirm-btn',
            },
        });
    }

    download(input) {
        if (input === 'en') {
            window.open(this.policyData.paymentLinkEn, '_blank');
        } else if (input === 'report') {
            window.open(this.policyData.paymentReport, '_blank');
        } else {
            window.open(this.policyData.paymentLinkAR, '_blank');
        }
    }
}
