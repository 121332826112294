import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {
    MatDialog,
    MatDialogRef,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
} from '@angular/material/dialog';
import { Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MotorClaimsService } from 'src/app/core/services/motor-claims.service';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';
@Component({
    selector: 'app-motor-policy-details',
    templateUrl: './motor-policy-details.component.html',
    styleUrls: ['./motor-policy-details.component.scss'],
})
export class MotorPolicyDetailsComponent implements OnInit {
    ProductCode: number = 1;
    ProductDetails: any;
    claimsDetails = [];
    form: any;
    policyNumbers: string[] = [];
    national: string;
    policyInfo: PolicyDetails[] = [];
    filteredPolicy: PolicyDetails = {} as PolicyDetails;
    HasClaims: string;
    DetailsForm: FormGroup;
    percePassedDays: number;
    isSubmitted = false;
    cancellationDto: CancellationDTO;
    dataSource: MatTableDataSource<PolicyDetails>;
    displayedColumns = [
        'vehicleName',
        'plateNo',
        'chassis',
        'manefacturingYear',
    ];
    showCancelButton: boolean = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    constructor(
        public _dialog: MatDialog,
        public motorClaimsService: MotorClaimsService,
        private _formbuilder: FormBuilder,
        private _router: Router,
        public translate: TranslateService,
        private location: Location
    ) {
        //  this.form = this.toFormGroup(this.formInput);
        // this.DetailsForm = this._formbuilder.group({
        // MobileNumber: [
        //     '',

        //      ],
        //     OTP: [null],
        //     driverId:[
        //         '',

        //     ]
        //     });
        this.policyInfo = this.location.getState()['policyInfo'];

        if (
            localStorage.getItem('policyInfo') !== null &&
            localStorage.getItem('policyInfo') !== undefined
        ) {
            this.policyInfo = JSON.parse(localStorage.getItem('policyInfo'));
            //  this.filteredPolicy =  JSON.parse(localStorage.getItem('policyInfo'));
        }
        if (this.policyInfo != null && this.policyInfo.length > 0) {
            this.policyInfo.forEach((item) => {
                if (this.policyNumbers !== undefined) {
                    if (!this.policyNumbers.includes(item.policyNo)) {
                        this.policyNumbers.push(item.policyNo);
                    }
                } else {
                    this.policyNumbers.push(item.policyNo);
                }
            });
        }

        if (this.policyInfo != null && this.policyInfo.length == 1) {
            this.filteredPolicy = this.policyInfo[0];
            this.percePassedDays =
                100 - (this.filteredPolicy.daysLeft / 365) * 100;
            this.motorClaimsService
                .getCancellation(this.filteredPolicy.idNo)
                .subscribe((res) => {
                    if (res != null && res.srV_TRX_ID > 0) {
                        this.cancellationDto = res;
                        this.showCancelButton = false;
                    } else {
                        this.showCancelButton = true;
                    }
                });
        }
    }

    ngOnInit(): void {}
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
    openDialog(
        enterAnimationDuration: string,
        exitAnimationDuration: string,
        element
    ): void {
        this._dialog.open(MotorCancelationModal, {
            data: { details: element },
            width: '500px',
            enterAnimationDuration,
            exitAnimationDuration,
        });
    }
    GoToclaims() {
        this.isSubmitted = true;
        this.motorClaimsService
            .getClaimDetails(
                this.filteredPolicy.idNo,
                this.filteredPolicy.mobile,
                LineOfBusiness.Motor,
                SMSActionType.Login
            )
            .subscribe((res) => {
                if (res != null && res.length > 0) {
                    this.claimsDetails = res;
                    localStorage.removeItem('claimDetails');
                    localStorage.setItem(
                        'claimDetails',
                        JSON.stringify(this.claimsDetails)
                    );
                    this.HasClaims = '';
                    this._router.navigateByUrl('/motor-claims-inquire', {
                        state: {
                            claimsDetails: this.claimsDetails,
                        },
                    });
                } else {
                    this.HasClaims = 'Has no claims';
                }
                this.isSubmitted = false;
            });
    }
    getPolicyInfo(policyId: string) {
        this.filteredPolicy = this.policyInfo.find(
            (p) => p.policyNo === policyId
        );
        this.dataSource = new MatTableDataSource(
            this.policyInfo.filter((p) => p.policyNo === policyId)
        );
        this.percePassedDays = 100 - (this.filteredPolicy.daysLeft / 365) * 100;
        this.motorClaimsService
            .getCancellation(this.filteredPolicy.idNo)
            .subscribe((res) => {
                if (res != null && res.srV_TRX_ID > 0) {
                    this.cancellationDto = res;
                    this.showCancelButton = false;
                } else {
                    this.showCancelButton = true;
                }
            });
    }
}

interface PolicyDetails {
    policyNo: string;
    idNo: string;
    mobile: string;
    policyDate: string;
    plateNo: string;
    vehicleName: string;
    daysPassed: number;
    daysLeft: number;

    status: string;
    manefacturingYear: string;
    chassis: string;
}
interface Food {
    value: string;
    viewValue: string;
}
interface CancellationDTO {
    SRV_TRX_ID: number;
    CANCELLATIONREASON: string;
    POLICYNO: string;
    DATEOFREQUEST: string;
    IBAN: string;
    ISWITHDRAW: number;
    ID_NUMBER: string;
}
interface cancellationDocs {
    cS_DOC_TYPE: string;
    dOC_BYTE: string;
}
@Component({
    selector: 'motor-Cancelation-Modal.component',
    templateUrl: 'Motor-Cancelation-Modal.component.html',
})
export class MotorCancelationModal {
    policyDetails: PolicyDetails;
    foods: Food[] = [
        { value: '1', viewValue: 'Cancellation of vehicle registration' },
        { value: '2', viewValue: 'Transfer vehicle ownership to new owner' },
        {
            value: '3',
            viewValue:
                'Provide alternative insurance policy from another company',
        },
        { value: '4', viewValue: 'Vehicle exported to abroad' },
        { value: '5', viewValue: 'Vehicle not sold' },
        { value: '6', viewValue: 'Entry Mistake in policy' },
        { value: '7', viewValue: 'Policy upgrade to comprehensive' },
    ];
    nationalImgsList: any[] = [];
    ImgsId: any[] = [];
    IBANpattern = /^[saSA]+[0-9]{22}$/;
    DetailsForm: FormGroup;
    cancellationDto: CancellationDTO = {} as CancellationDTO;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<MotorCancelationModal>,
        public motorClaimsService: MotorClaimsService,
        private _formbuilder: FormBuilder,
        private _router: Router,
        public translate: TranslateService
    ) {
        const IBANpattern = '^(SA)([0-9-a-z]{22})$';
        this.DetailsForm = this._formbuilder.group({
            remarks: [''],

            cancellationReason: [''],
            iban: [
                null,
                [Validators.required, Validators.pattern(this.IBANpattern)],
            ],
        });

        this.policyDetails = data.details;
    }

    setDocsList(event) {
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            const fileName = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.nationalImgsList.push({
                    image: reader.result,
                    id: 1,
                    name: fileName,
                });
                if (!this.ImgsId.includes(1)) {
                    this.ImgsId.push(1);
                }
            };
        }
    }

    saveCancellation() {
        this.cancellationDto.CANCELLATIONREASON =
            this.DetailsForm.controls['cancellationReason'].value;
        this.cancellationDto.IBAN = this.DetailsForm.controls['iban'].value;
        this.cancellationDto.DATEOFREQUEST = '';
        this.cancellationDto.ISWITHDRAW = 0;
        this.cancellationDto.POLICYNO = this.policyDetails.policyNo;
        this.cancellationDto.ID_NUMBER = this.policyDetails.idNo.toString();
        this.motorClaimsService
            .saveCancellation(this.cancellationDto)
            .subscribe((res) => {
                if (res) {
                    this.cancellationDto.SRV_TRX_ID = res.sRV_TRX_ID;
                    this.dialogRef.close();
                    if (res.srV_TRX_ID) {
                        this._router
                            .navigateByUrl('/motor-plicy-details', {})
                            .then(() => {
                                window.location.reload();
                            });
                    }
                } else {
                }
            });
    }
}
