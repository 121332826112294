<div class="container-fluid">
    <div mat-dialog-content class="row text-center">
        <h3 mat-dialog-title style="color: #d4ab36">
            {{ "MOTORCLAIMS.Motor_Service" | translate }}
        </h3>
    </div>
    <div mat-dialog-content class="row text-center pt-3">
        <p>{{ lang === "en-US" ? message : messageAR }}</p>
    </div>
    <div mat-dialog-actions class="row justify-content-center pt-5">
        <button
            type="button"
            mat-dialog-close
            class="btn btn-rounded w-25"
            style="background-color: #d4ab36; color: white"
        >
            {{ "MOTORCLAIMS.Ok" | translate }}
        </button>
    </div>
</div>
