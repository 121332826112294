<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 style="color: white">{{ "SERVICES.CLAIMS" | translate }}</h2>
            <ul>
                <li>
                    <a routerLink="/"
                        >{{ "NAV.ESERVICES" | translate }}
                        <span style="margin: 0 10px"> / </span></a
                    >
                </li>
                <li class="active">{{ "SERVICES.CLAIMS" | translate }}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->
<!-- <div class="row pt-3 col-lg-12 col-md-12">
    <div class="col-lg-2 col-md-12 col-sm-12"></div>
    <div class="col-lg-9 col-md-12 col-sm-12 policy-container">
        <div *ngIf="this.translate.currentLang == 'en-US'" style="color: #156538;font-size: 16px; font-weight: bold;">
            {{ "MOTORCLAIMS.POLICYNUMBER" | translate }} : 85521582285878
        </div>
        <div *ngIf="this.translate.currentLang != 'en-US'" dir="ltr" style="color: #156538;font-size: 16px; font-weight: bold;">
            85521582285878 : {{ "MOTORCLAIMS.POLICYNUMBER" | translate }}
        </div>
        <div *ngIf="this.translate.currentLang == 'en-US'" dir="rtl" style="color: #156538;font-size: 16px; font-weight: bold;">
             <img src="assets/img/home/bmw-logo-1997-1200x1200.png" alt="" width="50"> BMW X6
        </div>
        <div *ngIf="this.translate.currentLang != 'en-US'" style="color: #156538;font-size: 16px; font-weight: bold;">
            BMW X6 <img src="assets/img/home/bmw-logo-1997-1200x1200.png" width="50" alt="">
       </div>
    </div>
</div> -->
<div class="row pt-5 pd-5 col-lg-12 col-md-12" style="padding-bottom: 2%">
    <div class="col-lg-2 col-md-12">
        <div [ngClass]= "{'col-md-12': defaultClassFlag ==true,
        'pd-2': defaultClassFlag == true,
        'p-1': defaultClassFlag == true,
        'col-12': defaultClassFlag == true,
        'col-lg-8': defaultClassFlag == true,
        'pull-right': this.translate.currentLang == 'en-US',
        'pull-left': this.translate.currentLang != 'en-US' }">
            <div class="menu-container">
                <div [ngClass]="{'claim-left-menu-item-selected mb-3': this.leftMenuSelectedItem === 1,
                        'claim-left-menu-item mb-2': this.leftMenuSelectedItem !== 1 }"
                    (click)="this.leftMenuSelectedItem = 1">
                    <img src="assets/img/icons/writing.png" width="25" height="25" style="margin-right: 5%" alt="" />
                    <span style="font-size: 16px;">{{ "MOTORCLAIMS.CLAIMS" | translate }}</span>
                    <div class="pull-right" *ngIf="this.translate.currentLang == 'en-US'">
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                    </div>

                    <div class="pull-left" *ngIf="this.translate.currentLang != 'en-US'">
                      <i class="fa fa-caret-left" aria-hidden="true"></i>
                  </div>
                </div>

                <!-- <div [ngClass]="{'claim-left-menu-item-selected mb-3': this.leftMenuSelectedItem === 2,
                        'claim-left-menu-item mb-3': this.leftMenuSelectedItem !== 2}">
                    <img
                        src="assets/img/icons/pen.png"
                        width="22"
                        height="20"
                        style="margin-right: 5%"
                        alt=""
                    />
                    {{ "MOTORCLAIMS.ENDORSMENT" | translate }}
                </div>

                <div
                    [ngClass]="{
                        'claim-left-menu-item-selected mb-3':
                            this.leftMenuSelectedItem === 3,
                        'claim-left-menu-item mb-3':
                            this.leftMenuSelectedItem !== 3
                    }"
                >
                    <img
                        src="assets/img/icons/info.png"
                        width="22"
                        height="20"
                        style="margin-right: 5%"
                        alt=""
                    />
                    {{ "MOTORCLAIMS.POLICYINFO" | translate }}
                </div>

                <div
                    [ngClass]="{
                        'claim-left-menu-item-selected mb-3':
                            this.leftMenuSelectedItem === 4,
                        'claim-left-menu-item mb-3':
                            this.leftMenuSelectedItem !== 4
                    }"
                >
                    <img
                        src="assets/img/icons/system-update.png"
                        width="22"
                        height="20"
                        style="margin-right: 5%"
                        alt=""
                    />
                </div> -->
            </div>
        </div>
    </div>
    <div class="col-lg-9 col-md-12 col-sm-12 submit-claim-right-side" *ngIf="!smallScreen">
        <mat-horizontal-stepper
            ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}"
            labelPosition="bottom"
            #stepper
            *ngIf="!isSaveFrom && !isSaved">
            <ng-template matStepperIcon="edit">
                <mat-icon>done</mat-icon>
            </ng-template>

            <mat-step [stepControl]="claimForm">
                <ng-template matStepLabel>
                    <span style="font-weight: bold">01</span>
                    {{ "SERVICES.CLAIMS_INFO" | translate }}
                </ng-template>

                <div class="claim-form p-5 mt-5">
                    <legend class="claim-title">
                        <span style="font-weight: bold">01</span>
                        {{ "SERVICES.CLAIMS_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 200vh"
                        >
                            <form [formGroup]="claimForm">
                                <!--(ngSubmit)="" -->

                                <!-- Claim Processed By -->
                                <div class="mt-3 mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="ProcessedBy"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.PROCESSED"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7 row">
                                            <div class="col-md-6">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="ProcessedBy"
                                                        id="1"
                                                        checked
                                                        (click)="changeType(12)"
                                                    />
                                                    <label
                                                        style="
                                                            color: #d4ab36 !important;
                                                        "
                                                        class="form-check-label"
                                                        for="1"
                                                    >
                                                        {{
                                                            "SERVICES.NAJM"
                                                                | translate
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="ProcessedBy"
                                                        id="2"
                                                        (click)="changeType(13)"
                                                    />
                                                    <label
                                                        style="
                                                            color: #d4ab36 !important;
                                                        "
                                                        class="form-check-label"
                                                        for="2"
                                                    >
                                                        {{
                                                            "SERVICES.MOROR"
                                                                | translate
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Claim Accident Report Type -->
                                <div class="mt-3 mb-3" *ngIf="!isNajm">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="ProcessedBy"
                                                class="form-label"
                                                >{{
                                                    "MOTORCLAIMS.MORROR_ACCIDENT_REPORT_TYPE"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7 row">
                                            <div class="col-md-6">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        id="1"
                                                        checked
                                                        name="reportType"
                                                        (click)="changeReportType('basher')"
                                                    />
                                                    <label
                                                        style="
                                                            color: #d4ab36 !important;
                                                        "
                                                        class="form-check-label"
                                                        for="1"
                                                    >
                                                        {{
                                                            "MOTORCLAIMS.MORROR_TYPE_BASHER"
                                                                | translate
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        id="2"
                                                        name="reportType"
                                                        (click)="changeReportType('muror')"
                                                    />
                                                    <label
                                                        style="
                                                            color: #d4ab36 !important;
                                                        "
                                                        class="form-check-label"
                                                        for="2"
                                                    >
                                                        {{
                                                            "MOTORCLAIMS.MORROR_TYPE_PRINT_MORROR"
                                                                | translate
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Driver Id / Iqama -->
                                <div class="mb-3" *ngIf="isBasher && !isNajm">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label
                                                for="driverId"
                                                class="form-label"
                                                >{{
                                                    "MOTORCLAIMS.REFERENCE_NUMBER"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <input #input
                                                id="referenceNumber"
                                                name="referenceNumber"
                                                type="text"
                                                maxlength="20"
                                                formControlName="referenceNumber"
                                                [ngClass]="{'red-border-class': claimFormControl.referenceNumber.errors &&
                                                        (claimFormControl.referenceNumber.touched || isSubmit),
                                                      'form-control': defaultClassFlag == true,
                                                    'input-form-design': defaultClassFlag == true}"
                                                class="form-control input-form-design"
                                            />
                                            <div *ngIf="claimFormControl.referenceNumber.errors &&
                                             (claimFormControl.referenceNumber.touched || isSubmit)"
                                                class="">
                                                <div *ngIf="claimFormControl.referenceNumber.errors.required"
                                                    style="color: #ff001f">
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Driver Id / Iqama -->
                                <div class="mb-3" *ngIf="!isBasher && !isNajm">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label
                                                for="driverId"
                                                class="form-label"
                                                >{{
                                                    "MOTORCLAIMS.CAR_SERIAL_NUMBER"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <input #input
                                                id="carSerialNumber"
                                                name="carSerialNumber"
                                                type="text"
                                                maxlength="20"
                                                formControlName="carSerialNumber"
                                                [ngClass]="{'red-border-class': claimFormControl.carSerialNumber.errors &&
                                                        (claimFormControl.carSerialNumber.touched || isSubmit),
                                                      'form-control': defaultClassFlag == true,
                                                    'input-form-design': defaultClassFlag == true}"
                                                class="form-control input-form-design"
                                            />
                                            <div *ngIf="claimFormControl.carSerialNumber.errors &&
                                             (claimFormControl.carSerialNumber.touched || isSubmit)"
                                                class="">
                                                <div *ngIf="claimFormControl.carSerialNumber.errors.required"
                                                    style="color: #ff001f">
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Driver Id / Iqama -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label
                                                for="driverId"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.DRIVER_ID"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <input #input
                                                id="driverId"
                                                name="driverId"
                                                type="text"
                                                maxlength="10"

                                                oninput="javascript:  var element = document.getElementById(id);    var regex = /[^0-9\-]/gi;        element.value = element.value.replace(regex, '');"
                                                formControlName="driverId"
                                                [ngClass]="{'red-border-class': claimFormControl.driverId.errors &&
                                                        (claimFormControl.driverId.touched || isSubmit),
                                                      'form-control': defaultClassFlag == true,
                                                    'input-form-design': defaultClassFlag == true}"
                                                class="form-control input-form-design"
                                            />
                                            <div *ngIf="claimFormControl.driverId.errors &&
                                             (claimFormControl.driverId.touched || isSubmit)"
                                                class="">
                                                <div *ngIf="claimFormControl.driverId.errors.required"
                                                    style="color: #ff001f">
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        claimFormControl
                                                            .driverId.errors
                                                            .pattern
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.DRIVER_ID_PLACEHOLDER"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Accident Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label
                                                for="accidentNum"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.ACCIDENT_NUM"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <input
                                                id="accidentNum"
                                                name="accidentNum"
                                                style="
                                                    text-transform: uppercase;
                                                "
                                                type="text"
                                                maxlength="15"
                                                formControlName="accidentNum"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        claimFormControl
                                                            .accidentNum
                                                            .errors &&
                                                        (claimFormControl
                                                            .accidentNum
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                                (change)="checkAccidentNo($event)"
                                            />
                                            <div
                                                *ngIf="
                                                    claimFormControl.accidentNum
                                                        .errors &&
                                                    (claimFormControl
                                                        .accidentNum.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        claimFormControl
                                                            .accidentNum.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        claimFormControl
                                                            .accidentNum.errors
                                                            .pattern && !isNajm
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.accidentNum"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        claimFormControl
                                                            .accidentNum.errors
                                                            .pattern && isNajm
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.accNum"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                            *ngIf="isValidAccNo == false"
                                            style="color: #ff001f"
                                        >
                                        <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{
                                        "MOTORCLAIMS.ACCError"
                                            | translate
                                    }}
                                    </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="submitClaimInfo(stepper)"
                                            [disabled]="!isValidAccNo"
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i class="fa fa-arrow-left" aria-hidden="true"
                                            *ngIf="translate.currentLang != 'en-US'"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <div class="motor-claim-details">
                                <span>
                                  {{ "MOTORCLAIMS.DEAR_CUSTOMER" | translate }}
                                    <ul *ngFor="let msg of claimMessages">
                                        <li>
                                            {{ msg }}
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </div>

                        <div class="col col-md-6">
                          <div>
                            <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                              src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                            <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                              src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                          </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="userInfoFrom">
                <ng-template matStepLabel
                    ><span style="font-weight: bold">02</span>
                    {{ "SERVICES.USER_INFO" | translate }}</ng-template
                >
                <div class="claim-form p-5 mt-5">
                    <legend class="claim-title">
                        <span style="font-weight: bold">02</span>
                        {{ "SERVICES.USER_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="userInfoFrom">
                                <!-- Requester Type -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="requesterType"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.requesterType"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <ng-select
                                                [ngClass]="{
                                                    'red-border-class':
                                                        userInfoFromControl
                                                            .requesterType
                                                            .errors &&
                                                        (userInfoFromControl
                                                            .requesterType
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                placeholder="{{
                                                    'SERVICES.requesterType'
                                                        | translate
                                                }}"
                                                formControlName="requesterType"
                                                [searchable]="true"
                                                (change)="showDelegate($event)"
                                                class="input-form-design"
                                            >
                                                <ng-option
                                                    [value]="item.id"
                                                    *ngFor="
                                                        let item of requesterTypeList
                                                    "
                                                >
                                                    {{
                                                        translate.currentLang ==
                                                        "en-US"
                                                            ? item.e_LOOKUP__DET_DESCR_EN
                                                            : item.e_LOOKUP__DET_DESCR_AR
                                                    }}
                                                </ng-option>
                                            </ng-select>
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .requesterType.errors &&
                                                    (userInfoFromControl
                                                        .requesterType
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterType
                                                            .errors.required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Requester ID Iqama -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="requesterId"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.REQUESTER_ID"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="requesterId"
                                                name="requesterId"
                                                type="number"
                                                maxlength="10"
                                                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                formControlName="requesterId"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        userInfoFromControl
                                                            .requesterId
                                                            .errors &&
                                                        (userInfoFromControl
                                                            .requesterId
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .requesterId.errors &&
                                                    (userInfoFromControl
                                                        .requesterId.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterId.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterId.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.DRIVER_ID_PLACEHOLDER"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Requester Name -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="requesterName"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.REQUESTER_NAME"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="requesterName"
                                                name="requesterName"
                                                type="text"
                                                maxlength="20"
                                                formControlName="requesterName"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        userInfoFromControl
                                                            .requesterName
                                                            .errors &&
                                                        (userInfoFromControl
                                                            .requesterName
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .requesterName.errors &&
                                                    (userInfoFromControl
                                                        .requesterName
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterName
                                                            .errors.required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Requester Mobile Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="requesterMob"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.REQUESTER_MOB"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="requesterMob"
                                                name="requesterMob"
                                                type="text"
                                                maxlength="10"
                                                formControlName="requesterMob"
                                                [ngClass]="{
                                                    'red-border-class': false
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .requesterMob.errors &&
                                                    (userInfoFromControl
                                                        .requesterMob.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterMob.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterMob.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.PHONE"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Delegation Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="delegationNo"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.DELEGATION_NO"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="delegationNo"
                                                name="delegationNo"
                                                [readOnly]="!isshowDelegate"
                                                maxlength="30"
                                                type="text"
                                                formControlName="delegationNo"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        userInfoFromControl
                                                            .delegationNo
                                                            .errors &&
                                                        (userInfoFromControl
                                                            .delegationNo
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .delegationNo.errors &&
                                                    (userInfoFromControl
                                                        .delegationNo.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .delegationNo.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="submituserInfo(stepper)"
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="driverInfoFrom">
                <ng-template matStepLabel
                    ><span style="font-weight: bold">03</span>
                    {{ "SERVICES.DRIVER_INFO" | translate }}</ng-template
                >
                <div class="claim-form p-5 mt-5">
                    <legend class="claim-title">
                        <span style="font-weight: bold">03</span>
                        {{ "SERVICES.DRIVER_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="driverInfoFrom">
                                <!-- Is the driver -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label
                                                for="requesterName"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.IS_DRIVER"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-12">
                                            <mat-checkbox
                                            class="check-box-margin"
                                                formControlName="vehicleOwner"
                                                [checked]="
                                                    driverInfoFromControl
                                                        .vehicleOwner.value
                                                "
                                                (click)="
                                                    isVehicleOwner(
                                                        driverInfoFromControl
                                                            .vehicleOwner.value
                                                    )
                                                "
                                            >
                                                {{
                                                    "SERVICES.VEHICLE_OWNER"
                                                        | translate
                                                }}
                                            </mat-checkbox>

                                            <mat-checkbox
                                            class="check-box-margin"
                                                formControlName="claimRequester"
                                                [checked]="
                                                    driverInfoFromControl
                                                        .claimRequester.value
                                                "
                                                (click)="
                                                    isClaimRequester(
                                                        driverInfoFromControl
                                                            .claimRequester
                                                            .value
                                                    )
                                                "
                                            >
                                                {{
                                                    "SERVICES.CLAIM_REQUESTER"
                                                        | translate
                                                }}
                                            </mat-checkbox>
                                            <mat-checkbox
                                            class="check-box-margin"
                                                formControlName="otherPerson"
                                                [checked]="
                                                    driverInfoFromControl
                                                        .otherPerson.value
                                                "
                                                (click)="
                                                    isOtherPerson(
                                                        driverInfoFromControl
                                                            .otherPerson.value
                                                    )
                                                "
                                            >
                                                {{
                                                    "SERVICES.OTHER_PERSON"
                                                        | translate
                                                }}
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>

                                <h5 class="col-md-12 p-0 we-area-title">
                                    {{ "SERVICES.DRIVER_DETAILS" | translate }}
                                </h5>

                                <!-- Driver Name -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="driverName"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.DRIVER_NAME"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="driverName"
                                                name="driverName"
                                                maxlength="30"
                                                type="text"
                                                formControlName="driverName"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        driverInfoFromControl
                                                            .driverName
                                                            .errors &&
                                                        (driverInfoFromControl
                                                            .driverName
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    driverInfoFromControl
                                                        .driverName.errors &&
                                                    (driverInfoFromControl
                                                        .driverName.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        driverInfoFromControl
                                                            .driverName.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- License Type -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="licenceType"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.LICENSE_TYPE"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <ng-select class="input-form-design"
                                                [ngClass]="{'red-border-class': driverInfoFromControl.licenceType.errors
                                                 && (driverInfoFromControl.licenceType.touched || isSubmit)}"
                                                placeholder="{{'SERVICES.LICENSE_TYPE' | translate }}"
                                                formControlName="licenceType"
                                                [searchable]="true">
                                                <ng-option
                                                    [value]="item.id"
                                                    *ngFor="let item of drivingLicenseList">
                                                    {{ translate.currentLang == "en-US" ? item.e_LOOKUP__DET_DESCR_EN
                                                            : item.e_LOOKUP__DET_DESCR_AR }}
                                                </ng-option>
                                            </ng-select>
                                            <div
                                                *ngIf="driverInfoFromControl.licenceType.errors &&
                                                    (driverInfoFromControl.licenceType.touched || isSubmit)"
                                                style="color: #ff001f">
                                                <div *ngIf="driverInfoFromControl.licenceType.errors.required">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                    {{ "GENERIC.REQUIRED" | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex mt-30 justify-content-end align-items-center">
                                    <div>
                                        <button mat-button matStepperPrevious class="next-btn mr-2 ml-2 p-1">
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="submitDriverInfo(stepper)"
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="accidentDetailFrom" *ngIf="!isNajm">
                <ng-template matStepLabel>
                  <span style="font-weight: bold">04</span>
                    {{ "SERVICES.ACCIDENT_INFO" | translate }}</ng-template
                >
                <div class="claim-form p-5 mt-5">
                    <legend class="claim-title">
                        <span style="font-weight: bold">04</span>
                        {{ "SERVICES.ACCIDENT_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="accidentDetailFrom">
                                <div class="mb-3">
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <label for="city" class="form-label"
                                                >{{
                                                    "SERVICES.CITY" | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <ng-select
                                                [ngClass]="{
                                                    'red-border-class':
                                                        accidentDetailFromControl
                                                            .city.errors &&
                                                        (accidentDetailFromControl
                                                            .city.touched ||
                                                            isSubmit)
                                                }"
                                                placeholder="{{
                                                    'SERVICES.CITY' | translate
                                                }}"
                                                formControlName="city"
                                                id="city"
                                                [searchable]="true"
                                                class="input-form-design"
                                            >
                                                <ng-option
                                                    [value]="item.id"
                                                    *ngFor="
                                                        let item of cityList
                                                    "
                                                >
                                                    {{
                                                        translate.currentLang ==
                                                        "en-US"
                                                            ? item.citynameen
                                                            : item.citynamear
                                                    }}
                                                    <!-- {{translate.currentLang =="en-US"? item.e_LOOKUP__DET_DESCR_EN: item.e_LOOKUP__DET_DESCR_AR}} -->
                                                </ng-option>
                                            </ng-select>
                                            <div
                                                *ngIf="
                                                    accidentDetailFromControl
                                                        .city.errors &&
                                                    (accidentDetailFromControl
                                                        .city.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        accidentDetailFromControl
                                                            .city.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <label for="date" class="form-label"
                                                >{{
                                                    "SERVICES.DATE" | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field
                                                appearance="none"
                                                class="form-control input-form-design w-100"
                                                [ngClass]="{'red-border-class': driverInfoFromControl.licenceType.errors
                                                 && (driverInfoFromControl.licenceType.touched || isSubmit)}">
                                                <input
                                                    formControlName="date"
                                                    matInput
                                                    [matDatepicker]="picker"
                                                    placeholder="MM/DD/YYYY"
                                                    [max]="today"
                                                />
                                                <mat-datepicker-toggle style="margin-right: -6%;
                                                                              margin-top: -3%;
                                                                              color: #156538;"
                                                    matIconSuffix
                                                    [for]="picker"
                                                ></mat-datepicker-toggle>
                                                <mat-datepicker
                                                    #picker
                                                ></mat-datepicker>
                                            </mat-form-field>
                                            <div
                                                *ngIf="
                                                    accidentDetailFromControl
                                                        .date.errors &&
                                                    (accidentDetailFromControl
                                                        .date.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        accidentDetailFromControl
                                                            .date.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <label for="time" class="form-label"
                                                >{{
                                                    "SERVICES.TIME" | translate
                                                }}:</label
                                            >
                                        </div>

                                        <div class="col-md-8">
                                            <mat-form-field appearance="none" style="height: 100%"
                                            class="form-control input-form-design w-100"
                                            [ngClass]="{'red-border-class': driverInfoFromControl.licenceType.errors
                                                 && (driverInfoFromControl.licenceType.touched || isSubmit)}">

                                            <input
                                                    formControlName="time"
                                                    matTimepicker
                                                    class="w-100"
                                                    placeholder="--:--"
                                                />
                                                <img src="assets/img/services/clock.png" width="20" height="20" alt="">
                                                <!-- <mat-icon
                                                    style="
                                                        width: 13%;
                                                        margin-top: 2px;
                                                    "
                                                    >watch_later</mat-icon
                                                > -->
                                            </mat-form-field>

                                            <div
                                                *ngIf=" accidentDetailFromControl.time.errors &&
                                                    (accidentDetailFromControl.time.touched ||isSubmit)"
                                                    style="color: #ff001f">
                                                <div *ngIf="accidentDetailFromControl.time.errors.required">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true" ></i>
                                                    {{ "GENERIC.REQUIRED" | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <label
                                                for="plateNum"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.PLATE_NO"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-4 car-plate-text">
                                          <input name="plateNum" type="text" placeholder="3333" maxlength="4"
                                          onkeydown="javascript:  if (
                                            (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
                                            (e.keyCode < 96 || e.keyCode > 105)
                                          ) {
                                            e.preventDefault();
                                          }" formControlName="plateNum"
                                            [ngClass]="{ 'red-border-class': accidentDetailFromControl.plateNum.errors
                                             && (accidentDetailFromControl.plateNum.touched || isSubmit) }"
                                            class="form-control input-form-design" />

                                          <div *ngIf="accidentDetailFromControl.plateNum.errors
                                            && (accidentDetailFromControl.plateNum.touched || isSubmit)"
                                             style="color: #ff001f">
                                            <div *ngIf="accidentDetailFromControl.plateNum.errors.required">
                                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                              {{'GENERIC.REQUIRED' | translate}}
                                            </div>
                                            <div *ngIf="accidentDetailFromControl.plateNum.errors.pattern">
                                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                              {{'GENERIC.PLATE_NUMBER' | translate}}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <input id="plateText" name="plateText"  type="text" placeholder="ح ح ح "
                                           maxlength="5" formControlName="plateText"
                                           (keyup)="onInputChange($event)"
                                            [ngClass]="{ 'red-border-class': accidentDetailFromControl.plateText.errors
                                            && (accidentDetailFromControl.plateText.touched || isSubmit) }"
                                            class="form-control input-form-design"/>

                                            <div *ngIf="accidentDetailFromControl.plateText.errors
                                             && (accidentDetailFromControl.plateText.touched || isSubmit)"
                                             style="color: #ff001f">
                                                <div *ngIf="accidentDetailFromControl.plateText.errors.required">
                                                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>
                                                <div *ngIf="accidentDetailFromControl.plateText.errors.pattern">
                                                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                    {{'GENERIC.PLATE_TEXT' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <div
                                                style="
                                                    display: flex;
                                                    height: 100%;
                                                    align-items: flex-end;
                                                "
                                            >
                                                <label
                                                    for="fault"
                                                    class="form-label"
                                                    >{{
                                                        "SERVICES.FAULT"
                                                            | translate
                                                    }}:</label
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div
                                                style="
                                                    text-align: end;
                                                    color: #777676;
                                                "
                                            >
                                                <label>
                                                    {{
                                                        accidentDetailFrom.controls['fault'].value
                                                    }}%
                                                </label>
                                            </div>
                                            <mat-slider
                                                class="w-100"
                                                formControlName="fault"
                                                min="0"
                                                max="100"
                                                step="25"
                                            ></mat-slider>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="
                                                submitAccednentDetails(stepper)
                                            "
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="ownerDetailFrom">
                <ng-template matStepLabel>
                  <span style="font-weight: bold" *ngIf="!isNajm">05</span>
                  <span style="font-weight: bold" *ngIf="isNajm">04</span>
                    {{ "SERVICES.OWNER_INFO" | translate }}
                  </ng-template>
                <div class="claim-form p-5 mt-5">
                    <legend class="claim-title">
                      <span style="font-weight: bold" *ngIf="!isNajm">05</span>
                      <span style="font-weight: bold" *ngIf="isNajm">04</span>
                        {{ "SERVICES.OWNER_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="ownerDetailFrom">
                                <!-- Owner Name -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label for="name" class="form-label"
                                                >{{
                                                    "SERVICES.OWNER_NAME"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="name"
                                                name="name"
                                                type="text"
                                                maxlength="30"
                                                formControlName="name"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .name.errors &&
                                                        (ownerDetailFromControl
                                                            .name.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl.name
                                                        .errors &&
                                                    (ownerDetailFromControl.name
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .name.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Owner Id -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label for="id" class="form-label"
                                                >{{
                                                    "SERVICES.OWNER_ID"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="id"
                                                name="id"
                                                type="number"
                                                maxlength="10"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                formControlName="id"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .id.errors &&
                                                        (ownerDetailFromControl
                                                            .id.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl.id
                                                        .errors &&
                                                    (ownerDetailFromControl.id
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .id.errors.required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .id.errors.pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.DRIVER_ID_PLACEHOLDER"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Bank Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="bankNum"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.OWNER_IBAN"
                                                        | translate
                                                }}:
                                            </label>
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="bankNum"
                                                name="bankNum"
                                                style="
                                                    text-transform: uppercase;
                                                "
                                                type="text"
                                                maxlength="24"
                                                formControlName="bankNum"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .bankNum.errors &&
                                                        (ownerDetailFromControl
                                                            .bankNum.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl
                                                        .bankNum.errors &&
                                                    (ownerDetailFromControl
                                                        .bankNum.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .bankNum.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .bankNum.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.IBAN"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Phone Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="phone"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.OWNER_PHONE"
                                                        | translate
                                                }}:
                                            </label>
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="phone"
                                                name="phone"
                                                type="text"
                                                maxlength="10"
                                                formControlName="phone"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .phone.errors &&
                                                        (ownerDetailFromControl
                                                            .phone.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl.phone
                                                        .errors &&
                                                    (ownerDetailFromControl
                                                        .phone.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .phone.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .phone.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.PHONE"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Email -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="email"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.EMAIL"
                                                        | translate
                                                }}:
                                            </label>
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                style="direction: rtl"
                                                formControlName="email"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .email.errors &&
                                                        (ownerDetailFromControl
                                                            .email.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl.email
                                                        .errors &&
                                                    (ownerDetailFromControl
                                                        .email.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .email.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .email.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.EMAIL"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="
                                                submitOwnerDetails(stepper)
                                            "
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="documentsFrom">
                <ng-template matStepLabel
                    ><span style="font-weight: bold" *ngIf="!isNajm">06</span>
                    <span style="font-weight: bold" *ngIf="isNajm">05</span>
                    {{ "SERVICES.DOCUMENTS" | translate }}
                </ng-template>
                <div class="claim-form p-5 mt-5">
                    <legend class="claim-title">
                      <span style="font-weight: bold" *ngIf="!isNajm">06</span>
                      <span style="font-weight: bold" *ngIf="isNajm">05</span>
                        {{ "SERVICES.DOCUMENTS" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12">
                            <form [formGroup]="documentsFrom">
                                <div class="col-md-12 input-danger">
                                    <div
                                        class="form-group pt-4"
                                        *ngFor="let item of docsList"
                                    >
                                        <div class="row">
                                            <label
                                                class="col-md-4 p-0 we-area-title"
                                            >
                                                {{
                                                    translate.currentLang ==
                                                    "en-US"
                                                        ? item.clM_DOC_DESCR_EN
                                                        : item.clM_DOC_DESCR_AR
                                                }}
                                                <span
                                                    *ngIf="
                                                        item.clM_DOC_REQUIERD ===
                                                        'Y'
                                                    "
                                                    style="color: #ff001f"
                                                    >*</span
                                                >:
                                            </label>
                                            <div class="col-md-8">
                                                <input
                                                    type="file"
                                                    [id]="'docs' + item.id"
                                                    [required]="
                                                        item.clM_DOC_REQUIERD ===
                                                        'Y'
                                                    "
                                                    #myInput
                                                    (change)="
                                                        setDocsList(
                                                            $event,
                                                            item.id
                                                        )
                                                    "
                                                    class="hidden"
                                                    accept="application/pdf, image/*"
                                                    multiple
                                                />
                                                <div
                                                    *ngIf="
                                                        documentsFromControl
                                                            .docs.errors &&
                                                        (documentsFromControl
                                                            .docs.touched ||
                                                            isSubmit)
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <div
                                                        *ngIf="
                                                            documentsFromControl
                                                                .docs.errors
                                                                .required
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        ></i>
                                                        {{
                                                            "GENERIC.REQUIRED"
                                                                | translate
                                                        }}
                                                    </div>
                                                </div>
                                                <label
                                                    [for]="'docs' + item.id"
                                                    class="custom-file-input upload-lbl"
                                                >
                                                    <i
                                                        class="fa fa-upload"
                                                        aria-hidden="true"
                                                        style="color: #d4ab36"
                                                    ></i>
                                                    Upload Files</label
                                                >
                                            </div>

                                            <div class="col-md-12" *ngIf="item.id == 5 || item.id == 11" style="color:red">{{ "MOTORCLAIMS.DMG_REPORT_MSG" | translate }}</div>
                                        </div>
                                        <div
                                            class="row"
                                            *ngIf="ImgsId.includes(item.id)"
                                        >
                                            <ng-container
                                                *ngFor="
                                                    let img of nationalImgsList;
                                                    let x = index
                                                "
                                            >
                                                <div
                                                    class="col-md-5 imageContainer"
                                                    *ngIf="img.id == item.id"
                                                >
                                                    <div
                                                        class="col-md-12 row mt-3"
                                                    >
                                                        <div
                                                            class="item-container"
                                                            (click)="
                                                                DisplayDocument(
                                                                    img
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                style="
                                                                    overflow: clip;
                                                                    margin-right: 5%;
                                                                "
                                                            >
                                                                {{ img.name }}
                                                            </div>
                                                            <div>
                                                                <span
                                                                    class="delete"
                                                                    (click)="
                                                                        deleteAtt(
                                                                            x
                                                                        )
                                                                    "
                                                                    *ngIf="
                                                                        img.id ==
                                                                        item.id
                                                                    "
                                                                >
                                                                    <fa-icon
                                                                        [icon]="
                                                                            faXmark
                                                                        "
                                                                    ></fa-icon>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="showSaveForm(stepper)"
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="finalFrom">
                <ng-template matStepLabel
                    ><span style="font-weight: bold" *ngIf="!isNajm">07</span>
                    <span style="font-weight: bold" *ngIf="isNajm">06</span>
                        {{ "MOTORCLAIMS.USERAGREEMENT" | translate }}
                    </ng-template
                >
                <div class="claim-form p-5 mt-5">
                    <legend class="claim-title">
                      <span style="font-weight: bold" *ngIf="!isNajm">07</span>
                      <span style="font-weight: bold" *ngIf="isNajm">06</span>
                            {{ "MOTORCLAIMS.USERAGREEMENT" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="finalFrom" style="height: 100%">
                                <div class="user-agreement-text p-4 mt-3">
                                    <label class="user-agreement-lbl">
                                        Terms and Conditions Please read these
                                        terms and conditions carefully before
                                        using Our Service. Interpretation and
                                        Definitions Interpretation The words of
                                        which the initial letter is capitalized
                                        have meanings defined under the
                                        following conditions. The following
                                        definitions shall have the same meaning
                                        regardless of whether Terms and
                                        Conditions Please read these terms and
                                        conditions carefully before using Our
                                        Service. Interpretation and Definitions
                                        Interpretation The words of which the
                                        initial letter is capitalized have
                                        meanings defined under the following
                                        conditions. The following definitions
                                        shall have the same meaning regardless
                                        of whether
                                    </label>
                                    <mat-checkbox
                                        class="mt-4 user-agreement-confirm"
                                        formControlName="save"
                                        id="save"
                                        [checked]="finalFromControl.save.value"
                                        style="margin-left: -31px"
                                    >
                                        {{ "SERVICES.AGREEMENT" | translate }}
                                    </mat-checkbox>
                                </div>

                                <div
                                    class="d-flex justify-content-end align-items-center"
                                    style="margin-top: 100px"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="SendOTPAndOpenVerificationDialog()"
                                            [disabled]="isLoading"
                                        >
                                            <span *ngIf="!isLoading">{{
                                                "GENERIC.SUBMIT" | translate
                                            }}</span>
                                            <span *ngIf="isLoading">
                                                <div
                                                    class="spinner-border text-warning"
                                                ></div>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
    <div class="col-lg-9 col-md-12 col-sm-12 submit-claim-right-side" *ngIf="smallScreen">
        <mat-vertical-stepper
            ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}"
            labelPosition="bottom"
            #stepper
            *ngIf="!isSaveFrom && !isSaved">
            <ng-template matStepperIcon="edit">
                <mat-icon>done</mat-icon>
            </ng-template>

            <mat-step [stepControl]="claimForm">
                <ng-template matStepLabel>
                    <span style="font-weight: bold">01</span>
                    {{ "SERVICES.CLAIMS_INFO" | translate }}
                </ng-template>

                <div class="claim-form">
                    <legend class="claim-title">
                        <span style="font-weight: bold">01</span>
                        {{ "SERVICES.CLAIMS_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="claimForm">
                                <!--(ngSubmit)="" -->

                                <!-- Claim Processed By -->
                                <div class="mt-3 mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="ProcessedBy"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.PROCESSED"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7 row">
                                            <div class="col-md-6">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="ProcessedBy"
                                                        id="1"
                                                        checked
                                                        (click)="changeType(12)"
                                                    />
                                                    <label
                                                        style="
                                                            color: #d4ab36 !important;
                                                        "
                                                        class="form-check-label"
                                                        for="1"
                                                    >
                                                        {{
                                                            "SERVICES.NAJM"
                                                                | translate
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="ProcessedBy"
                                                        id="2"
                                                        (click)="changeType(13)"
                                                    />
                                                    <label
                                                        style="
                                                            color: #d4ab36 !important;
                                                        "
                                                        class="form-check-label"
                                                        for="2"
                                                    >
                                                        {{
                                                            "SERVICES.MOROR"
                                                                | translate
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Driver Id / Iqama -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label
                                                for="driverId"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.DRIVER_ID"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <input #input
                                                id="driverId"
                                                name="driverId"
                                                type="number"
                                                maxlength="10"
                                                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                formControlName="driverId"
                                                [ngClass]="{'red-border-class': claimFormControl.driverId.errors &&
                                                        (claimFormControl.driverId.touched || isSubmit),
                                                      'form-control': defaultClassFlag == true,
                                                    'input-form-design': defaultClassFlag == true}"
                                                class="form-control input-form-design"
                                            />
                                            <div *ngIf="claimFormControl.driverId.errors &&
                                             (claimFormControl.driverId.touched || isSubmit)"
                                                class="">
                                                <div *ngIf="claimFormControl.driverId.errors.required"
                                                    style="color: #ff001f">
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        claimFormControl
                                                            .driverId.errors
                                                            .pattern
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.DRIVER_ID_PLACEHOLDER"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Accident Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label
                                                for="accidentNum"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.ACCIDENT_NUM"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <input
                                                id="accidentNum"
                                                name="accidentNum"
                                                style="
                                                    text-transform: uppercase;
                                                "
                                                type="text"
                                                maxlength="30"
                                                formControlName="accidentNum"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        claimFormControl
                                                            .accidentNum
                                                            .errors &&
                                                        (claimFormControl
                                                            .accidentNum
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                                (change)="checkAccidentNo($event)"

                                            />
                                            <div
                                                *ngIf="
                                                    claimFormControl.accidentNum
                                                        .errors &&
                                                    (claimFormControl
                                                        .accidentNum.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        claimFormControl
                                                            .accidentNum.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        claimFormControl
                                                            .accidentNum.errors
                                                            .pattern && !isNajm
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.accidentNum"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        claimFormControl
                                                            .accidentNum.errors
                                                            .pattern && isNajm
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.accNum"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                            *ngIf="isValidAccNo == false"
                                            style="color: #ff001f"
                                        >
                                        <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{
                                        "MOTORCLAIMS.ACCError"
                                            | translate
                                    }}
                                    </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="submitClaimInfo(stepper)"
                                            [disabled]="!isValidAccNo"
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i class="fa fa-arrow-left" aria-hidden="true"
                                            *ngIf="translate.currentLang != 'en-US'"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col col-md-6">
                          <div>
                            <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                              src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                            <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                              src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                          </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="userInfoFrom">
                <ng-template matStepLabel
                    ><span style="font-weight: bold">02</span>
                    {{ "SERVICES.USER_INFO" | translate }}</ng-template
                >
                <div class="claim-form">
                    <legend class="claim-title">
                        <span style="font-weight: bold">02</span>
                        {{ "SERVICES.USER_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="userInfoFrom">
                                <!-- Requester Type -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="requesterType"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.requesterType"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <ng-select
                                                [ngClass]="{
                                                    'red-border-class':
                                                        userInfoFromControl
                                                            .requesterType
                                                            .errors &&
                                                        (userInfoFromControl
                                                            .requesterType
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                placeholder="{{
                                                    'SERVICES.requesterType'
                                                        | translate
                                                }}"
                                                formControlName="requesterType"
                                                [searchable]="true"
                                                (change)="showDelegate($event)"
                                                class="input-form-design"
                                            >
                                                <ng-option
                                                    [value]="item.id"
                                                    *ngFor="
                                                        let item of requesterTypeList
                                                    "
                                                >
                                                    {{
                                                        translate.currentLang ==
                                                        "en-US"
                                                            ? item.e_LOOKUP__DET_DESCR_EN
                                                            : item.e_LOOKUP__DET_DESCR_AR
                                                    }}
                                                </ng-option>
                                            </ng-select>
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .requesterType.errors &&
                                                    (userInfoFromControl
                                                        .requesterType
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterType
                                                            .errors.required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Requester ID Iqama -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="requesterId"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.REQUESTER_ID"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="requesterId"
                                                name="requesterId"
                                                type="number"
                                                maxlength="10"
                                                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                formControlName="requesterId"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        userInfoFromControl
                                                            .requesterId
                                                            .errors &&
                                                        (userInfoFromControl
                                                            .requesterId
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .requesterId.errors &&
                                                    (userInfoFromControl
                                                        .requesterId.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterId.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterId.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.DRIVER_ID_PLACEHOLDER"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Requester Name -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="requesterName"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.REQUESTER_NAME"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="requesterName"
                                                name="requesterName"
                                                type="text"
                                                maxlength="20"
                                                formControlName="requesterName"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        userInfoFromControl
                                                            .requesterName
                                                            .errors &&
                                                        (userInfoFromControl
                                                            .requesterName
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .requesterName.errors &&
                                                    (userInfoFromControl
                                                        .requesterName
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterName
                                                            .errors.required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Requester Mobile Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="requesterMob"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.REQUESTER_MOB"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="requesterMob"
                                                name="requesterMob"
                                                type="text"
                                                maxlength="10"
                                                formControlName="requesterMob"
                                                [ngClass]="{
                                                    'red-border-class': false
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .requesterMob.errors &&
                                                    (userInfoFromControl
                                                        .requesterMob.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterMob.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .requesterMob.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.PHONE"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Delegation Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="delegationNo"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.DELEGATION_NO"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="delegationNo"
                                                name="delegationNo"
                                                [readOnly]="!isshowDelegate"
                                                maxlength="30"
                                                type="text"
                                                formControlName="delegationNo"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        userInfoFromControl
                                                            .delegationNo
                                                            .errors &&
                                                        (userInfoFromControl
                                                            .delegationNo
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    userInfoFromControl
                                                        .delegationNo.errors &&
                                                    (userInfoFromControl
                                                        .delegationNo.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        userInfoFromControl
                                                            .delegationNo.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="submituserInfo(stepper)"
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="driverInfoFrom">
                <ng-template matStepLabel
                    ><span style="font-weight: bold">03</span>
                    {{ "SERVICES.DRIVER_INFO" | translate }}</ng-template
                >
                <div class="claim-form">
                    <legend class="claim-title">
                        <span style="font-weight: bold">03</span>
                        {{ "SERVICES.DRIVER_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="driverInfoFrom">
                                <!-- Is the driver -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label
                                                for="requesterName"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.IS_DRIVER"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-12">
                                            <mat-checkbox
                                            class="check-box-margin"
                                                formControlName="vehicleOwner"
                                                [checked]="
                                                    driverInfoFromControl
                                                        .vehicleOwner.value
                                                "
                                                (click)="
                                                    isVehicleOwner(
                                                        driverInfoFromControl
                                                            .vehicleOwner.value
                                                    )
                                                "
                                            >
                                                {{
                                                    "SERVICES.VEHICLE_OWNER"
                                                        | translate
                                                }}
                                            </mat-checkbox>

                                            <mat-checkbox
                                            class="check-box-margin"
                                                formControlName="claimRequester"
                                                [checked]="
                                                    driverInfoFromControl
                                                        .claimRequester.value
                                                "
                                                (click)="
                                                    isClaimRequester(
                                                        driverInfoFromControl
                                                            .claimRequester
                                                            .value
                                                    )
                                                "
                                            >
                                                {{
                                                    "SERVICES.CLAIM_REQUESTER"
                                                        | translate
                                                }}
                                            </mat-checkbox>
                                            <mat-checkbox
                                            class="check-box-margin"
                                                formControlName="otherPerson"
                                                [checked]="
                                                    driverInfoFromControl
                                                        .otherPerson.value
                                                "
                                                (click)="
                                                    isOtherPerson(
                                                        driverInfoFromControl
                                                            .otherPerson.value
                                                    )
                                                "
                                            >
                                                {{
                                                    "SERVICES.OTHER_PERSON"
                                                        | translate
                                                }}
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>

                                <h5 class="col-md-12 p-0 we-area-title">
                                    {{ "SERVICES.DRIVER_DETAILS" | translate }}
                                </h5>

                                <!-- Driver Name -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="driverName"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.DRIVER_NAME"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="driverName"
                                                name="driverName"
                                                maxlength="30"
                                                type="text"
                                                formControlName="driverName"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        driverInfoFromControl
                                                            .driverName
                                                            .errors &&
                                                        (driverInfoFromControl
                                                            .driverName
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    driverInfoFromControl
                                                        .driverName.errors &&
                                                    (driverInfoFromControl
                                                        .driverName.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        driverInfoFromControl
                                                            .driverName.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- License Type -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="licenceType"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.LICENSE_TYPE"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <ng-select class="input-form-design"
                                                [ngClass]="{'red-border-class': driverInfoFromControl.licenceType.errors
                                                 && (driverInfoFromControl.licenceType.touched || isSubmit)}"
                                                placeholder="{{'SERVICES.LICENSE_TYPE' | translate }}"
                                                formControlName="licenceType"
                                                [searchable]="true">
                                                <ng-option
                                                    [value]="item.id"
                                                    *ngFor="let item of drivingLicenseList">
                                                    {{ translate.currentLang == "en-US" ? item.e_LOOKUP__DET_DESCR_EN
                                                            : item.e_LOOKUP__DET_DESCR_AR }}
                                                </ng-option>
                                            </ng-select>
                                            <div
                                                *ngIf="driverInfoFromControl.licenceType.errors &&
                                                    (driverInfoFromControl.licenceType.touched || isSubmit)"
                                                style="color: #ff001f">
                                                <div *ngIf="driverInfoFromControl.licenceType.errors.required">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                    {{ "GENERIC.REQUIRED" | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex mt-30 justify-content-end align-items-center">
                                    <div>
                                        <button mat-button matStepperPrevious class="next-btn mr-2 ml-2 p-1">
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="submitDriverInfo(stepper)"
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="accidentDetailFrom" *ngIf="!isNajm">
                <ng-template matStepLabel>
                  <span style="font-weight: bold">04</span>
                    {{ "SERVICES.ACCIDENT_INFO" | translate }}</ng-template
                >
                <div class="claim-form">
                    <legend class="claim-title">
                        <span style="font-weight: bold">04</span>
                        {{ "SERVICES.ACCIDENT_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="accidentDetailFrom">
                                <div class="mb-3">
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <label for="city" class="form-label"
                                                >{{
                                                    "SERVICES.CITY" | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <ng-select
                                                [ngClass]="{
                                                    'red-border-class':
                                                        accidentDetailFromControl
                                                            .city.errors &&
                                                        (accidentDetailFromControl
                                                            .city.touched ||
                                                            isSubmit)
                                                }"
                                                placeholder="{{
                                                    'SERVICES.CITY' | translate
                                                }}"
                                                formControlName="city"
                                                id="city"
                                                [searchable]="true"
                                                class="input-form-design"
                                            >
                                                <ng-option
                                                    [value]="item.id"
                                                    *ngFor="
                                                        let item of cityList
                                                    "
                                                >
                                                    {{
                                                        translate.currentLang ==
                                                        "en-US"
                                                            ? item.citynameen
                                                            : item.citynamear
                                                    }}
                                                    <!-- {{translate.currentLang =="en-US"? item.e_LOOKUP__DET_DESCR_EN: item.e_LOOKUP__DET_DESCR_AR}} -->
                                                </ng-option>
                                            </ng-select>
                                            <div
                                                *ngIf="
                                                    accidentDetailFromControl
                                                        .city.errors &&
                                                    (accidentDetailFromControl
                                                        .city.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        accidentDetailFromControl
                                                            .city.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <label for="date" class="form-label"
                                                >{{
                                                    "SERVICES.DATE" | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-8">
                                            <mat-form-field
                                                appearance="none"
                                                class="form-control input-form-design w-100"
                                                [ngClass]="{'red-border-class': driverInfoFromControl.licenceType.errors
                                                 && (driverInfoFromControl.licenceType.touched || isSubmit)}">
                                                <input
                                                    formControlName="date"
                                                    matInput
                                                    [matDatepicker]="picker"
                                                    placeholder="MM/DD/YYYY"
                                                    [max]="today"
                                                />
                                                <mat-datepicker-toggle style="margin-right: -6%;
                                                                              margin-top: -3%;
                                                                              color: #156538;"
                                                    matIconSuffix
                                                    [for]="picker"
                                                ></mat-datepicker-toggle>
                                                <mat-datepicker
                                                    #picker
                                                ></mat-datepicker>
                                            </mat-form-field>
                                            <div
                                                *ngIf="
                                                    accidentDetailFromControl
                                                        .date.errors &&
                                                    (accidentDetailFromControl
                                                        .date.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        accidentDetailFromControl
                                                            .date.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <label for="time" class="form-label"
                                                >{{
                                                    "SERVICES.TIME" | translate
                                                }}:</label
                                            >
                                        </div>

                                        <div class="col-md-8">

                                            <mat-form-field appearance="none" style="height: 100%"
                                            class="form-control input-form-design w-100"
                                            [ngClass]="{'red-border-class': driverInfoFromControl.licenceType.errors
                                                 && (driverInfoFromControl.licenceType.touched || isSubmit)}">

                                            <input
                                                    formControlName="time"
                                                    matTimepicker
                                                    class="w-100"
                                                    placeholder="--:--"

                                                />
                                                <img src="assets/img/services/clock.png" width="20" height="20" alt="">
                                                <!-- <mat-icon
                                                    style="
                                                        width: 13%;
                                                        margin-top: 2px;
                                                    "
                                                    >watch_later</mat-icon
                                                > -->
                                            </mat-form-field>

                                            <div
                                                *ngIf=" accidentDetailFromControl.time.errors &&
                                                    (accidentDetailFromControl.time.touched ||isSubmit)"
                                                    style="color: #ff001f">
                                                <div *ngIf="accidentDetailFromControl.time.errors.required">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true" ></i>
                                                    {{ "GENERIC.REQUIRED" | translate }}
                                                </div>
                                            </div>

                                            <div
                                            *ngIf=" accidentDetailFromControl.time > maxTime"
                                                style="color: #ff001f">
                                            <div *ngIf="accidentDetailFromControl.time.errors.required">
                                                <i class="fa fa-exclamation-circle" aria-hidden="true" ></i>
                                               Time can not be in future
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <label
                                                for="plateNum"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.PLATE_NO"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-4">
                                          <input name="plateNum" type="text" placeholder="3333" maxlength="5"
                                            onkeydown="javascript:  if (
                                                (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
                                                (e.keyCode < 96 || e.keyCode > 105)
                                              ) {
                                                e.preventDefault();
                                              }"  formControlName="plateNum"
                                            [ngClass]="{ 'red-border-class': accidentDetailFromControl.plateNum.errors
                                             && (accidentDetailFromControl.plateNum.touched || isSubmit) }"
                                            class="form-control input-form-design" />

                                          <div *ngIf="accidentDetailFromControl.plateNum.errors
                                            && (accidentDetailFromControl.plateNum.touched || isSubmit)"
                                             style="color: #ff001f">
                                            <div *ngIf="accidentDetailFromControl.plateNum.errors.required">
                                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                              {{'GENERIC.REQUIRED' | translate}}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4 car-plate-text">
                                          <input id="plateText" name="plateText"  type="text" placeholder="ح ح ح "
                                           (keyup)="onInputChange($event)" maxlength="5" formControlName="plateText"
                                           (input)="onInputChange()"
                                            [ngClass]="{ 'red-border-class': accidentDetailFromControl.plateText.errors
                                            && (accidentDetailFromControl.plateText.touched || isSubmit) }"
                                            class="form-control input-form-design"/>

                                            <div *ngIf="accidentDetailFromControl.plateText.errors
                                             && (accidentDetailFromControl.plateText.touched || isSubmit)"
                                             style="color: #ff001f">
                                                <div *ngIf="accidentDetailFromControl.plateText.errors.required">
                                                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>
                                                <div *ngIf="accidentDetailFromControl.plateText.errors.pattern">
                                                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                    {{'GENERIC.PLATE_TEXT' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-4">
                                            <div
                                                style="
                                                    display: flex;
                                                    height: 100%;
                                                    align-items: flex-end;
                                                "
                                            >
                                                <label
                                                    for="fault"
                                                    class="form-label"
                                                    >{{
                                                        "SERVICES.FAULT"
                                                            | translate
                                                    }}:</label
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div
                                                style="
                                                    text-align: end;
                                                    color: #777676;
                                                "
                                            >
                                                <label>
                                                    {{
                                                        accidentDetailFrom.value
                                                            .fault
                                                    }}%
                                                </label>
                                            </div>
                                            <mat-slider
                                                class="w-100"
                                                formControlName="fault"
                                                min="0"
                                                max="100"
                                                step="1"
                                            ></mat-slider>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="
                                                submitAccednentDetails(stepper)
                                            "
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="ownerDetailFrom">
                <ng-template matStepLabel>
                  <span style="font-weight: bold" *ngIf="!isNajm">05</span>
                  <span style="font-weight: bold" *ngIf="isNajm">04</span>
                    {{ "SERVICES.OWNER_INFO" | translate }}
                  </ng-template>
                <div class="claim-form">
                    <legend class="claim-title">
                      <span style="font-weight: bold" *ngIf="!isNajm">05</span>
                      <span style="font-weight: bold" *ngIf="isNajm">04</span>
                        {{ "SERVICES.OWNER_INFO" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="ownerDetailFrom">
                                <!-- Owner Name -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label for="name" class="form-label"
                                                >{{
                                                    "SERVICES.OWNER_NAME"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="name"
                                                name="name"
                                                type="text"
                                                maxlength="30"
                                                formControlName="name"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .name.errors &&
                                                        (ownerDetailFromControl
                                                            .name.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl.name
                                                        .errors &&
                                                    (ownerDetailFromControl.name
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .name.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Owner Id -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label for="id" class="form-label"
                                                >{{
                                                    "SERVICES.OWNER_ID"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="id"
                                                name="id"
                                                type="number"
                                                maxlength="10"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                formControlName="id"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .id.errors &&
                                                        (ownerDetailFromControl
                                                            .id.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl.id
                                                        .errors &&
                                                    (ownerDetailFromControl.id
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .id.errors.required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .id.errors.pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.DRIVER_ID_PLACEHOLDER"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Bank Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="bankNum"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.OWNER_IBAN"
                                                        | translate
                                                }}:
                                            </label>
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="bankNum"
                                                name="bankNum"
                                                style="
                                                    text-transform: uppercase;
                                                "
                                                type="text"
                                                maxlength="24"
                                                formControlName="bankNum"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .bankNum.errors &&
                                                        (ownerDetailFromControl
                                                            .bankNum.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl
                                                        .bankNum.errors &&
                                                    (ownerDetailFromControl
                                                        .bankNum.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .bankNum.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .bankNum.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.IBAN"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Phone Number -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="phone"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.OWNER_PHONE"
                                                        | translate
                                                }}:
                                            </label>
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="phone"
                                                name="phone"
                                                type="text"
                                                maxlength="10"
                                                formControlName="phone"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .phone.errors &&
                                                        (ownerDetailFromControl
                                                            .phone.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl.phone
                                                        .errors &&
                                                    (ownerDetailFromControl
                                                        .phone.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .phone.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .phone.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.PHONE"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Email -->
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <label
                                                for="email"
                                                class="form-label"
                                                >{{
                                                    "SERVICES.EMAIL"
                                                        | translate
                                                }}:
                                            </label>
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                style="direction: rtl"
                                                formControlName="email"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        ownerDetailFromControl
                                                            .email.errors &&
                                                        (ownerDetailFromControl
                                                            .email.touched ||
                                                            isSubmit)
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    ownerDetailFromControl.email
                                                        .errors &&
                                                    (ownerDetailFromControl
                                                        .email.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .email.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        ownerDetailFromControl
                                                            .email.errors
                                                            .pattern
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.EMAIL"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="
                                                submitOwnerDetails(stepper)
                                            "
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="documentsFrom">
                <ng-template matStepLabel
                    ><span style="font-weight: bold" *ngIf="!isNajm">06</span>
                    <span style="font-weight: bold" *ngIf="isNajm">05</span>
                    {{ "SERVICES.DOCUMENTS" | translate }}
                </ng-template>
                <div class="claim-form">
                    <legend class="claim-title">
                      <span style="font-weight: bold" *ngIf="!isNajm">06</span>
                      <span style="font-weight: bold" *ngIf="isNajm">05</span>
                        {{ "SERVICES.DOCUMENTS" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12">
                            <form [formGroup]="documentsFrom">
                                <div class="col-md-12 input-danger">
                                    <div
                                        class="form-group pt-4"
                                        *ngFor="let item of docsList"
                                    >
                                        <div class="row">
                                            <label
                                                class="col-md-4 p-0 we-area-title"
                                            >
                                                {{
                                                    translate.currentLang ==
                                                    "en-US"
                                                        ? item.clM_DOC_DESCR_EN
                                                        : item.clM_DOC_DESCR_AR
                                                }}
                                                <span
                                                    *ngIf="
                                                        item.clM_DOC_REQUIERD ===
                                                        'Y'
                                                    "
                                                    style="color: #ff001f"
                                                    >*</span
                                                >:
                                            </label>
                                            <div class="col-md-8">
                                                <input
                                                    type="file"
                                                    [id]="'docs' + item.id"
                                                    [required]="
                                                        item.clM_DOC_REQUIERD ===
                                                        'Y'
                                                    "
                                                    #myInput
                                                    (change)="
                                                        setDocsList(
                                                            $event,
                                                            item.id
                                                        )
                                                    "
                                                    class="hidden"
                                                    accept="application/pdf, image/*"
                                                    multiple
                                                />
                                                <div
                                                    *ngIf="
                                                        documentsFromControl
                                                            .docs.errors &&
                                                        (documentsFromControl
                                                            .docs.touched ||
                                                            isSubmit)
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <div
                                                        *ngIf="
                                                            documentsFromControl
                                                                .docs.errors
                                                                .required
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-exclamation-circle"
                                                            aria-hidden="true"
                                                        ></i>
                                                        {{
                                                            "GENERIC.REQUIRED"
                                                                | translate
                                                        }}
                                                    </div>
                                                </div>
                                                <label
                                                    [for]="'docs' + item.id"
                                                    class="custom-file-input upload-lbl"
                                                >
                                                    <i
                                                        class="fa fa-upload"
                                                        aria-hidden="true"
                                                        style="color: #d4ab36"
                                                    ></i>
                                                    Upload Files</label
                                                >
                                            </div>

                                            <div class="col-md-12" *ngIf="item.id == 5 || item.id == 11" style="color:red">{{ "MOTORCLAIMS.DMG_REPORT_MSG" | translate }}</div>
                                        </div>
                                        <div
                                            class="row"
                                            *ngIf="ImgsId.includes(item.id)"
                                        >
                                            <ng-container
                                                *ngFor="
                                                    let img of nationalImgsList;
                                                    let x = index
                                                "
                                            >
                                                <div
                                                    class="col-md-5 imageContainer"
                                                    *ngIf="img.id == item.id"
                                                >
                                                    <div
                                                        class="col-md-12 row mt-3"
                                                    >
                                                        <div
                                                            class="item-container"
                                                            (click)="
                                                                DisplayDocument(
                                                                    img
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                style="
                                                                    overflow: clip;
                                                                    margin-right: 5%;
                                                                "
                                                            >
                                                                {{ img.name }}
                                                            </div>
                                                            <div>
                                                                <span
                                                                    class="delete"
                                                                    (click)="
                                                                        deleteAtt(
                                                                            x
                                                                        )
                                                                    "
                                                                    *ngIf="
                                                                        img.id ==
                                                                        item.id
                                                                    "
                                                                >
                                                                    <fa-icon
                                                                        [icon]="
                                                                            faXmark
                                                                        "
                                                                    ></fa-icon>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="d-flex mt-30 justify-content-end align-items-center"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="showSaveForm(stepper)"
                                        >
                                            {{ "GENERIC.NEXT" | translate }}
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="finalFrom">
                <ng-template matStepLabel
                    ><span style="font-weight: bold" *ngIf="!isNajm">07</span>
                    <span style="font-weight: bold" *ngIf="isNajm">06</span>
                        {{ "MOTORCLAIMS.USERAGREEMENT" | translate }}
                    </ng-template
                >
                <div class="claim-form">
                    <legend class="claim-title">
                      <span style="font-weight: bold" *ngIf="!isNajm">07</span>
                      <span style="font-weight: bold" *ngIf="isNajm">06</span>
                            {{ "MOTORCLAIMS.USERAGREEMENT" | translate }}
                    </legend>
                    <hr />
                    <div class="row">
                        <div
                            class="col-md-6 col-sm-12"
                            style="max-height: 100vh"
                        >
                            <form [formGroup]="finalFrom" style="height: 100%">
                                <div class="user-agreement-text p-4 mt-3">
                                    <label class="user-agreement-lbl">
                                        Terms and Conditions Please read these
                                        terms and conditions carefully before
                                        using Our Service. Interpretation and
                                        Definitions Interpretation The words of
                                        which the initial letter is capitalized
                                        have meanings defined under the
                                        following conditions. The following
                                        definitions shall have the same meaning
                                        regardless of whether Terms and
                                        Conditions Please read these terms and
                                        conditions carefully before using Our
                                        Service. Interpretation and Definitions
                                        Interpretation The words of which the
                                        initial letter is capitalized have
                                        meanings defined under the following
                                        conditions. The following definitions
                                        shall have the same meaning regardless
                                        of whether
                                    </label>
                                    <mat-checkbox
                                        class="mt-4 user-agreement-confirm"
                                        formControlName="save"
                                        id="save"
                                        [checked]="finalFromControl.save.value"
                                        style="margin-left: -31px"
                                    >
                                        {{ "SERVICES.AGREEMENT" | translate }}
                                    </mat-checkbox>
                                </div>

                                <div
                                    class="d-flex justify-content-end align-items-center"
                                    style="margin-top: 100px"
                                >
                                    <div>
                                        <button
                                            mat-button
                                            matStepperPrevious
                                            class="next-btn mr-2 ml-2 p-1"
                                        >
                                            <i
                                                class="fa fa-arrow-left"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang ==
                                                    'en-US'
                                                "
                                            ></i>
                                            <i
                                                class="fa fa-arrow-right"
                                                aria-hidden="true"
                                                *ngIf="
                                                    translate.currentLang !=
                                                    'en-US'
                                                "
                                            ></i>
                                            {{ "GENERIC.BACK" | translate }}
                                        </button>

                                        <button
                                            mat-button
                                            class="next-btn p-1"
                                            style="margin-right: 10px"
                                            (click)="SendOTPAndOpenVerificationDialog()"
                                            [disabled]="isLoading"
                                        >
                                            <span *ngIf="!isLoading">{{
                                                "GENERIC.SUBMIT" | translate
                                            }}</span>
                                            <span *ngIf="isLoading">
                                                <div
                                                    class="spinner-border text-warning"
                                                ></div>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col col-md-6">
                            <div>
                                <img class="form-ltr-image" *ngIf="this.translate.currentLang == 'en-US'"
                                  src="assets/img/services/motor-claim-ltr.png" alt="aaa" />

                                <img class="form-rtl-image" *ngIf="this.translate.currentLang != 'en-US'"
                                  src="assets/img/services/motor-claim-rtl.png" alt="aaa" />
                              </div>
                        </div>
                    </div>
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </div>
</div>
