<div class="page-title-area ">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'SERVICES.COMPLAINT' | translate}}</h2>
            <ul>
                <li><a routerLink="/">{{'NAV.HOME' | translate}}</a></li>
<!--                <li ><a routerLink="/services">{{'NAV.SERVICES' | translate}}</a> </li>-->
                <li class="active">{{'SERVICES.COMPLAINT' | translate}} </li>
            </ul>
        </div>
    </div>
</div>
<!-- Start Get Quote Area -->
<h1 style="text-align:center; margin: 20px ;">Coming Soon!</h1>
<section class="get-quote-area get-quote-area-two services-page pb-100 mt-30 d-none">
    <div class="container">
        <div class="row">

            <div class="col-lg-6 col-md-12 p-0">
                <div class="tab get-quote-tab">
                    <div class="section-title">
                        <h2>{{'SERVICES.COMPLAINT' | translate}}</h2>
                    </div>

                    <ul class="tabs ">
                        <li class="first active">{{'SERVICES.COMPLAINT_DETAILS' | translate}}</li>
                        <li class="second active">{{'SERVICES.CONTACT' | translate}}</li>

                    </ul>

                    <form class="form-group get-quote-form" (submit)="Submit()" [formGroup]="complaintForm">
                        <div class="tab_content">
                            <div class="tabs_item firstTap">

                                <div class="we-area-title">
                                    <h3>{{'SERVICES.TITLE' | translate}}</h3>
                                </div>

                                <div class="row">
                                    <div class="col-md-6  input-danger">
                                        <div class="form-group">
                                            <ng-select formControlName="caseType" placeholder="{{'SERVICES.CASETYPE' | translate}}" [searchable]="true">
                                                <ng-option [value]="item.id"  *ngFor="let item of caseTypeList" >
                                                    {{translate.currentLang == 'en-US'?item.nameEn : item.nameAr}}
                                                </ng-option>

                                            </ng-select>
                                            <div *ngIf="f.caseType.errors && (f.caseType.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.caseType.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6  input-danger">
                                        <div class="form-group">
                                            <ng-select formControlName="about" placeholder="{{'SERVICES.ABOUT' | translate}}" [searchable]="true">
                                                <ng-option [value]="item.id"  *ngFor="let item of about" >
                                                    {{translate.currentLang == 'en-US'?item.nameEn : item.nameAr}}
                                                </ng-option>

                                            </ng-select>
                                            <div *ngIf="f.about.errors && (f.about.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.about.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6  input-danger" >
                                        <div class="form-group">

                                            <input id="najmId" name="policyNo" placeholder="{{'SERVICES.POLICYNO' | translate}}" type="text" formControlName="policyNo" [ngClass]="{ 'red-border-class': false }"
                                                   class="form-control input-md"/>
                                            <div *ngIf="f.policyNo.errors && (f.policyNo.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.policyNo.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12  input-danger" >
                                        <div class="form-group">


                                            <textarea formControlName="details"  class="form-control  " rows="10" cols="20" placeholder="{{'SERVICES.DETAILS' | translate}}"></textarea>
                                            <div *ngIf="f.details.errors && (f.details.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.details.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6  input-danger">
                                        <button class="w-100  saveButton firstSave" type="button">{{'GENERIC.NEXT' | translate}}</button>

                                    </div>
                                </div>

                            </div>
                            <div class="tabs_item secondTap" >

                                <div class="row">
                                    <div class="col-md-6  input-danger">
                                        <div class="form-group">
                                            <input id="nationalId" name="nationalId" placeholder="{{'SERVICES.ID' | translate}}" type="text" formControlName="nationalId" [ngClass]="{ 'red-border-class': false }"
                                                   class="form-control input-md"/>
                                            <div *ngIf="f.nationalId.errors && (f.nationalId.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.nationalId.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>
                                                <div *ngIf="f.nationalId.errors.pattern">
                                                    {{'GENERIC.EMPLOYEE' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6  input-danger">
                                        <div class="form-group">
                                            <input id="name" name="name" placeholder="{{'SERVICES.NAME' | translate}}" type="text" formControlName="name" [ngClass]="{ 'red-border-class': false }"
                                                   class="form-control input-md"/>
                                            <div *ngIf="f.name.errors && (f.name.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.name.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6  input-danger">
                                        <div class="form-group">
                                            <input id="date" name="dateOfBirth" size="30"
                                                    placeholder="{{'SERVICES.NAME' | translate}}" type="date" formControlName="dateOfBirth"
                                                   [ngClass]="{ 'red-border-class': false }"
                                                   class="form-control input-md"/>
                                            <div *ngIf="f.dateOfBirth.errors && (f.dateOfBirth.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.dateOfBirth.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6  input-danger">
                                        <div class="form-group">
                                            <ng-select formControlName="area" placeholder="{{'SERVICES.AREA' | translate}}" [searchable]="true">
                                                <ng-option [value]="item.id"  *ngFor="let item of areaList" >
                                                    {{translate.currentLang == 'en-US'?item.nameEn : item.nameAr}}
                                                </ng-option>

                                            </ng-select>
                                            <div *ngIf="f.area.errors && (f.area.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.area.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12  input-danger">
                                        <div class="form-group">
                                            <input id="email" name="email" placeholder="{{'SERVICES.EMAIL' | translate}}" type="text" formControlName="email" [ngClass]="{ 'red-border-class': false }"
                                                   class="form-control input-md"/>
                                            <div *ngIf="f.email.errors && (f.email.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.email.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}fff
                                                </div>
                                                <div *ngIf="f.email.errors.pattern">
                                                    {{'GENERIC.EMAIL' | translate}}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6  input-danger">
                                        <div class="form-group">
                                            <input id="phone" name="phone" placeholder="{{'SERVICES.PHONE' | translate}}" type="text" formControlName="phone" [ngClass]="{ 'red-border-class': false }"
                                                   class="form-control input-md"/>
                                            <div *ngIf="f.phone.errors && (f.phone.touched || isSubmit)" class="alert alert-danger mt-2 mb-2">
                                                <div *ngIf="f.phone.errors.required">
                                                    {{'GENERIC.REQUIRED' | translate}}
                                                </div>
                                                <div *ngIf="f.phone.errors.pattern">
                                                    {{'GENERIC.PHONE' | translate}}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">

                                    </div>
                                    <div class="col-md-6  input-danger">
                                        <button class="w-100  saveButton " type="submit"> {{'GENERIC.SUBMIT' | translate}}</button>

                                    </div>
                                </div>



                            </div>



                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <img src="assets/img/services/COMPLAINT.jpg" class="w-auto h-100" alt="Image">

            </div>
        </div>
    </div>
</section>
<!-- End Get Quote Area-->
