import { Component,EventEmitter, Input , Output, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, Route } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/core/AppConfigService';
import { PaymentOnlineService } from 'src/app/core/services/Payment/payment-online.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-after-payment-page',
  templateUrl: './after-payment-page.component.html',
  styleUrls: ['./after-payment-page.component.scss']
})
export class AfterPaymentPageComponent {
     @Output() changeLang = new EventEmitter();
    constructor(
        private activatedRoute: ActivatedRoute,
        private environment: AppConfigService,
        public translate: TranslateService,
        private location: Location,
        private paymentOnlineService: PaymentOnlineService,
     
        private route: Router
    ) {

    }
     languageValue = localStorage.getItem('language');
    transectionID: any;
    orderID : any;
    transactionStatus:any;
    paymentinfo: PaymentInfo = {} as PaymentInfo;
    generalPaymentStatus : GeneralPaymentStatus ={} as GeneralPaymentStatus;
    downloadEn : string;
    downloadAr : string;
    message:string;
    errMessage:string;
    isLoading: boolean;
    ngOnInit(): void {
        const currentUrl = this.location.path();
        let trxId: string;
       
        this.activatedRoute.queryParams.subscribe(params => {
          
            this.orderID = params.orderId;
        });
         this.activatedRoute.params.subscribe(params => this.transectionID = params.data);
         // this.activatedRoute.params.subscribe(params => this.orderID = params['orderId']);

        this.paymentOnlineService.GetPaymentInfo(this.transectionID).subscribe(
            (res) => {
                this.paymentinfo = res;
                 if (this.paymentinfo.lang !== undefined && this.paymentinfo.lang !== '' && this.paymentinfo.lang.toUpperCase() === 'E') {
                        
                          this.languageSwitcher('en-US');
                       
                    }
                    else {
                         this.languageSwitcher('ar-SA');
                    }
                if (this.paymentinfo.policyno !== null && this.paymentinfo.policyno !== undefined && this.paymentinfo.policyno > 0) {
                    this.generalPaymentStatus.transactionId = this.orderID;
                    this.generalPaymentStatus.key = this.paymentinfo.trxid.toString();

                    this.paymentOnlineService.GetPaymentStatus(this.generalPaymentStatus).subscribe(
                        (res) => {
                            console.log('paymentstatusres', res);
                            this.transactionStatus = res;

                            if (res?.statusCode == '200') {
                                if (this.translate.currentLang == 'en-US') {
                                    this.message = 'Your policy issued successfully,';

                                } else {
                                    this.message = 'لقد تم إصدار وثيقتك بنجاح';
                                }

                                this.downloadEn = res?.enLink;
                                this.downloadAr = res?.arLink;

                            } else {
                                this.message = res?.content?.status;
                                this.errMessage = res?.content?.errorMsg;
                            }
                            this.isLoading = false;
                        },
                        (err) => {
                            console.log(err);
                            this.isLoading = false;
                        }
                    );
                }
                else if ((this.paymentinfo.policyno !== null || this.paymentinfo.policyno !== undefined || this.paymentinfo.policyno == 0) && this.paymentinfo.trxid > 0) {
                    this.generalPaymentStatus.transactionId = this.orderID;
                    this.generalPaymentStatus.key = this.paymentinfo.trxid.toString();
                    this.paymentOnlineService.GetPaymentStatus(this.generalPaymentStatus).subscribe(
                        (res) => {
                            console.log('paymentstatusres', res);
                            this.transactionStatus = res;

                            if (res?.statusCode == '200') {
                                if (this.translate.currentLang == 'en-US') {
                                    this.message = 'Your policy issued successfully,';

                                } else {
                                    this.message = 'لقد تم إصدار وثيقتك بنجاح';
                                }

                                this.downloadEn = res?.enLink;
                                this.downloadAr = res?.arLink;

                            } else {
                                this.message = res?.content?.status;
                                this.errMessage = res?.content?.errorMsg;
                            }
                            this.isLoading = false;
                        },
                        (err) => {
                            console.log(err);
                            this.isLoading = false;
                        }
                    );
                }
            },
            (err) => {

            }
        );

    }

    download()
    {
         if (this.translate.currentLang === 'en') {
            window.open(this.downloadEn, '_blank');
        } else {
            window.open(this.downloadAr, '_blank');
        }
    }
     languageSwitcher(language): void {
       
        this.translate.use(language);

        this.translate.use(language);
        localStorage.setItem('language', language);

        this.changeLang.emit();
        this.languageValue = localStorage.getItem('language');
         this.languageHandler();
    }
     languageHandler(): void {
        let localLang = localStorage.getItem('language');
        if (localLang) {
            this.translate.use(localLang);
            this.translate.currentLang = localLang;
            // this.authService.switchLanguage(localLang).subscribe((res) => {});
        } else {
            this.translate.use('ar-SA');
            // this.authService.switchLanguage('ar-SA').subscribe((res) => {});
        }
        this.languageValue = localStorage.getItem('language');
    }
    
}
export interface PaymentInfo {
    policyno: number;
    endno: number;
    lob: string;
    product: string;
    clientname: string;
    premium: number;
    vat: number;
    discount: number;
    totalpremium: number;
    additionalinfo: string;
    paymentstatus: string;
    paymentexpirydate: Date;
    policyholderid: string;
    paymenttrxid: string;
    trxid: number;
    mobilE_NO: string;
     lang: string;
    issmssent: number;
}
export interface GeneralPaymentStatus
{
    transactionId:string;
    key:string;
}
