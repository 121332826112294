import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MmpService } from 'src/app/core/services/MMP-Service/mmp.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import { Router } from '@angular/router';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';

@Component({
    selector: 'app-add-payment-info',
    templateUrl: './add-payment-info.component.html',
    styleUrls: ['./add-payment-info.component.scss'],
})
export class AddPaymentInfoComponent implements OnInit {
    CompanyDetails: any;
    premData: any;
    @Input() QuotationNumber: any;
    @Input() memberBillingDetails: any;
    @Output() onPaymentSuccess = new EventEmitter<any>();

    isSubmit: boolean = false;
    defaultClassFlag: boolean = true;
    PaymentForm: FormGroup;
    emailPattern =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    IBANpattern = '^([0-9]{22})$';
    isLoading = false;
    qutationResponce: any;
    memberDetails: any;
    paymentDone = null;
    tabIndex = 0;
    PaymentTrxId: any;
    VATNumber: any;
    isCompany: boolean;
    CreatePolicyDisabled: boolean;
    formatter: any;
    languageValue = localStorage.getItem('language');
    SponsorEmail: any;
    SponsorIBAN: any;
    SponsorVAT: any;
    CompanyAddress: any;

    constructor(
        public translate: TranslateService,
        private fb: FormBuilder,
        private smeService: MedicalSMEService,
        private location: Location,
        public router: Router,
        private _LocalService: LocalService,
    ) {
        if (this.premData == null || this.premData == undefined) {
            let data = localStorage.getItem('PremData');
            if (data != undefined && data != null && data != 'undefined') {
                this.premData = JSON.parse(data);
            }
        }

        if (this.CompanyDetails == null || this.CompanyDetails == undefined)
            this.CompanyDetails = JSON.parse(
                localStorage.getItem('CompanyDetails')
            );
        if (
            this.memberBillingDetails == null ||
            this.memberBillingDetails == undefined
        ) {
            let companyAddress = localStorage.getItem('CompanyAddress');
            if (companyAddress == undefined || companyAddress == null) {
                this.GetCompanyAddress();
                this.memberBillingDetails = this.CompanyAddress?.addresses[0];
            }
            else {
                this.memberBillingDetails = JSON.parse(
                    localStorage.getItem('CompanyAddress')
                );
            }
        }

        if (this.CompanyDetails.crEntityNumber.toString().startsWith('7')) {
            this.isCompany = true;
        }

        localStorage.removeItem('PremData');
        localStorage.removeItem('CompanyDetails');
        localStorage.removeItem('CompanyAddress');

        this.smeService.GetQuotationTrxId(this.QuotationNumber).subscribe(
            (res) => {
                if (res != null) this.PaymentTrxId = res;
            },
            (err) => { }
        );

        this.memberDetails = {};
        this.qutationResponce = this.qutationResponce = {
            trxId: '',
            quotationCode: '',
            quotationMessage: '',
            trxExpiryDate: '',
            insuredIqamaId: '',
            trxSourceChannel: '',
            insuredName: '',
            hasPrevInsurance: '',
            planCode: '',
            prodCode: '',
            policyNumber: '',
            policyPeriod: {
                id: '',
                name: '',
            },
            policyDateFrom: '',
            policyDateTo: '',
            policyPremium: '',
            policyFee: '',
            policyVatAmount: '',
            policyDiscount: '',
            policyTotalPremium: '',

            calcStatus: '',

            trxPaymentType: {
                id: '',
                name: '',
            },
            risks: [
                {
                    riskId: '',
                    insuredIqamaId: '',
                    insuredNameAr: '',
                    insuredNameEn: '',
                    insuredMobileNumber: '',
                    insuredEmail: '',
                    insuredIban: '',
                    insuredYakeenTrxId: '',
                    professionCategoryCode: {
                        id: '',
                        name: '',
                    },
                    professionCode: {
                        id: '',
                        name: '',
                    },
                    promotionCode: '',
                    licenseNumber: '',
                    licenseExpiryDate: '',
                    policyNumber: '',
                    policyPremium: '',
                    policyDeductable: '',
                    basicRate: '',
                    retroactiveType: '',
                    ratingId: '',
                    policyAggregate: '',
                    policyCategoryExcess: '',
                    insuredNationalAddressId: '',
                },
            ],
        };
    }

    ngOnInit(): void {
        this.formatter = new Intl.NumberFormat(this.languageValue, {
            style: 'currency',
            currency: 'SAR',
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        this.SponsorEmail = this._LocalService.getJsonValue('SponsorEmail');
        if (this._LocalService.getJsonValue('SponsorIBAN') != null && this._LocalService.getJsonValue('SponsorIBAN') != undefined)
            this.SponsorIBAN = this._LocalService.getJsonValue('SponsorIBAN');
        else
            this.SponsorIBAN = null

        if (this._LocalService.getJsonValue('SponsorVAT') != null && this._LocalService.getJsonValue('SponsorVAT') != undefined)
            this.SponsorVAT = this._LocalService.getJsonValue('SponsorVAT');
        else
            this.SponsorVAT = null

        this.buildPaymentForm();
    }

    get PaymentFormControl() {
        return this.PaymentForm.controls;
    }

    async GetCompanyAddress() {
        this.isLoading = true;
        this.smeService
            .GetCompanyAddressInfo(this.CompanyDetails.crEntityNumber)
            .subscribe(
                (res) => {
                    this.CompanyAddress = res;
                    this.isLoading = false;
                },
                (err) => {
                    this.isLoading = false;
                }
            );
    }

    submitPayment() {
        this.isSubmit = true;
        this.isLoading = true;
        //console.log('first', this.PaymentForm.valid, this.memberBillingDetails);
        if (
            this.PaymentForm.valid &&
            this.memberBillingDetails.streetName &&
            this.memberBillingDetails.streetName !== '' &&
            this.memberBillingDetails.city &&
            this.memberBillingDetails.city !== '' &&
            this.memberBillingDetails.district &&
            this.memberBillingDetails.district !== '' &&
            this.memberBillingDetails.postCode &&
            this.memberBillingDetails.postCode !== ''
        ) {
            this.handleGetPaymentData();
        } else {
            let errorMessage: string = '';
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }
            let index: number = 1;
            if (this.PaymentForm.controls['type'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  payment type is required!<br/>'
                        : index + '-  نوع الدفع مطلوب!<br/>';
                index++;
            }

            if (this.PaymentForm?.controls['vatNumber']?.value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  VAT Number is required!<br/>'
                        : index + '- الرقم الضريبي مطلوب <br/>';
                index++;
            } else if (
                this.PaymentForm.controls['vatNumber']?.status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  VAT Number is invalid!<br/>'
                        : index + '- الرقم الضريبي غير صحيح<br/>';
                index++;
            }

            if (this.PaymentForm?.controls['iban']?.value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  IBAN Number is required!<br/>'
                        : index + '- رقم IBAN  مطلوب!<br/>';
                index++;
            } else if (
                this.PaymentForm.controls['iban']?.status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Invalid IBAN Number !<br/>'
                        : index + '- رقم IBAN  غير صحيح!<br/>';
                index++;
            }
            if (this.PaymentForm.controls['email']?.value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Email Address is required!<br/>'
                        : index + '- البريد إلكتروني مطلوب!<br/>';
                index++;
            } else if (
                this.PaymentForm.controls['email']?.status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Invalid Email Address !<br/>'
                        : index + '- بريد الكتروني غير صحيح!<br/>';
                index++;
            }

            if (
                this.PaymentForm.controls['paymentType']?.value == null &&
                !this.PaymentForm.controls['paymentType'].valid
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '-  Payment Method is required!<br/>'
                        : index + '- طريقة الدفع مطلوب!<br/>';
                index++;
            }

            if (
                !this.memberBillingDetails.streetName ||
                this.memberBillingDetails.streetName === ''
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Error while getting user billing info ,  Street info is missing!<br/>'
                        : index +
                        '- حدث خطأ اثناء الحصول علي العنوان الوطني للمستخدم , بيانات الشارع غير موجودة !<br/>';
                index++;
            }

            if (
                !this.memberBillingDetails.city ||
                this.memberBillingDetails.city === ''
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '-  Error while getting user billing info , city info is missing!<br/>'
                        : index +
                        '- حدث خطأ اثناء الحصول علي العنوان الوطني للمستخدم ,  بيانات المدينة غير موجودة!<br/>';
                index++;
            }

            if (
                !this.memberBillingDetails.district ||
                this.memberBillingDetails.district === ''
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Error while getting user billing info , state info is missing!<br/>'
                        : index +
                        '-  حدث خطأ اثناء الحصول علي العنوان الوطني للمستخدم , بيانات الحي غير موجودة!<br/>';
                index++;
            }

            if (
                !this.memberBillingDetails.postCode ||
                this.memberBillingDetails.postCode === ''
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index +
                        '- Error while getting user billing info , postal Code info is missing!<br/>'
                        : index +
                        '-  حدث خطأ اثناء الحصول علي العنوان الوطني للمستخدم , بيانات الرمز البريدي غير موجودة!<br/>';
                index++;
            }
            this.ErrDialog(errorMessage);
        }
    }
    buildPaymentForm() {
        this.PaymentForm = this.fb.group({
            type: ['cash', Validators.required],
            iban: [
                this.SponsorIBAN,
                [Validators.required, Validators.pattern(this.IBANpattern)],
            ],
            email: [
                this.SponsorEmail,
                [Validators.required, Validators.pattern(this.emailPattern)],
            ],
            paymentType: [null, [Validators.required]],
            vatNumber: [this.SponsorVAT],
        });
        if (this.isCompany) {
            this.PaymentFormControl.vatNumber.setValidators([
                Validators.required,
            ]);
            this.PaymentFormControl.vatNumber.updateValueAndValidity();
        }
    }
    changePaymentType(val) {
        this.PaymentForm.controls['type'].setValue(val);

        if (val === 'cash') {
            this.PaymentFormControl.paymentType.setValidators([
                Validators.required,
            ]);
            this.PaymentFormControl.paymentType.updateValueAndValidity();
        } else {
            this.PaymentFormControl.paymentType.clearValidators();
            this.PaymentFormControl.paymentType.updateValueAndValidity();
        }
    }
    async handleGetPaymentData() {

        await this.handlePaymentReqwest();
        // this.tabIndex=3;
        // this.GetPaymentStatus(localStorage.getItem("quotationNumber"));
    }

    GetPaymentStatus(trxId: any) {
        this.tabIndex = 3;
        let model = {
            transactionId: trxId,
            // clientId: '12345678',
        };
        this.smeService.GetPaymentStatus(model).subscribe((res) => {

            this.CreatePolicyDisabled = false;
            this.tabIndex = 3;
        });
    }

    async handlePaymentReqwest() {
        this.isLoading = true;
        let langg;
        if (this.translate.currentLang == 'en-US') {
            langg = 'en';
        } else {
            langg = 'ar';
        }
        try {
            let model = {
                trxId: localStorage.getItem('quotationNumber'),
                iqamaNumber: this.CompanyDetails.crEntityNumber.toString(),
                entityName: this.PaymentForm.controls['paymentType']?.value,
                amount: this.premData?.totalPremWithVat,
                iban: this.PaymentForm.controls['iban']?.value,
                clientId: "12345678",
                merchantId: "123456789",
                CompanyEmail: this.PaymentForm.controls['email']?.value,
                sponserId: this.CompanyDetails.crEntityNumber.toString(),
                VAT: this.PaymentForm.controls['vatNumber']?.value,
                //lang: langg,
                // billingDto: {
                //     street1: this.memberBillingDetails.streetName,
                //     city: this.memberBillingDetails.city,
                //     state: this.memberBillingDetails.district,
                //     country: 'SA',
                //     postcode: this.memberBillingDetails.postCode.toString(),
                // },
                // customerDto: {
                //     email: this.PaymentForm.controls['email']?.value,
                //     givenName: this.CompanyDetails.crName,
                //     surname: this.CompanyDetails.crName,
                //     phone: "0511111111"
                // },
                //vatNumber:this.PaymentForm.controls['vatNumber']?.value
            };

            //const res = await this.smeService.GetPaymentData(Model).toPromise();
            await this.smeService.GetPaymentData(model).subscribe((res) => {
                if (res == null) {
                    this.isLoading = false;

                    let dir: string = '';
                    let ErrMsg = '';
                    if (this.translate.currentLang == 'en-US') {
                        dir = 'ltr';
                        ErrMsg = 'An Error Occurred !';
                    } else {
                        dir = 'rtl';
                        ErrMsg = 'حدث خطأ ما !';
                    }

                    this.ErrDialog(ErrMsg);
                }

                if (res?.statusCode >= 300) {
                    this.isLoading = false;

                    let dir: string = '';
                    let ErrMsg = '';

                    if (this.translate.currentLang == 'en-US') {
                        dir = 'ltr';
                        ErrMsg = 'An Error Occurred !';
                    } else {
                        dir = 'rtl';
                        ErrMsg = 'حدث خطأ ما !';
                    }

                    this.ErrDialog(ErrMsg);
                } else if (res?.statusCode == 200) {
                    localStorage.setItem(
                        'method',
                        this.PaymentForm.controls['paymentType']?.value
                    );
                    localStorage.setItem('trxId', this.qutationResponce?.trxId);

                    this.paymentDone = true;
                    // this.paymentObjectRedirect = res?.content;
                    this.isLoading = false;
                    // window.location.href = '';
                    window.open(res?.content?.formUrl, '_blank');
                    this.onPaymentSuccess.emit(res);
                    // const newTab = window.open();
                    // newTab.document.open();
                    // newTab.document.write(res?.content);
                    // newTab.document.close();
                    // let child = window.open('about:blank', 'myChild');
                    // child?.document?.write(res?.content);
                    // child?.document?.close();
                    setTimeout(() => {
                        window.location.href = '';
                    }, 5000);
                }
            });
        } catch (err) {
            this.isLoading = false;
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }

            this.ErrDialog(err.title);
        }
    }

    ErrDialog(msg) {
        this.isLoading = false;
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        Swal.fire({
            title: this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#FF001F; direction:" +
                dir +
                "'>" +
                msg +
                '</div>',
            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
                icon: 'submit-claim-failed-icon-img',
                title: 'submit-claim-failed-swal-title',
                confirmButton: 'submit-claim-failed-confirm-btn',
            },
        });
    }
}
