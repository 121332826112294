import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { nextTick } from 'process';
import { last, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';

@Component({
    selector: 'app-upload-members-dialog',
    templateUrl: './upload-members-dialog.component.html',
    styleUrls: ['./upload-members-dialog.component.scss'],
})
export class UploadMembersDialogComponent implements OnInit {
    languageValue = localStorage.getItem('language');
    isLoading: boolean;
    uploadedFile: any;
    passedMembers = [];
    failedMembers = [];
    fileName: string;
    progress: number = 0;
    progressRange = new Array(100);
    validExtension: boolean;
    invalidFile: boolean;
    QuotationNumber: any;

    ngOnInit(): void { }
    constructor(
        private dialogRef: MatDialogRef<UploadMembersDialogComponent>,
        private smeService: MedicalSMEService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public translate: TranslateService,
        public _LocalService: LocalService
    ) { }



    ValidateDoc(file: any) {
        if (file.name.toLowerCase().includes("xlsx")
            || file.name.toLowerCase().includes("xls")
            || file.name.toLowerCase().includes("csv"))
            return true;
        else
            return false;
    }

    async openFile(file: any) {
        this.invalidFile = false;
        this.uploadedFile = file.files[0];
        if (!this.ValidateDoc(this.uploadedFile)) {
            this.invalidFile = true;
        }
        this.fileName = this.uploadedFile.name;
    }

    uploadMembers() {
        const formData = new FormData();
        formData.append('file', this.uploadedFile);
        formData.append('lang', this.languageValue);
        formData.append('SponsorNumber', this.data.sponsorId);
        formData.append('QuotationNumber', this.QuotationNumber);
        this.isLoading = true;
        this.smeService
            .ValidateMembersExcel(formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(
                tap((event) => {
                    if (event.type == HttpEventType.UploadProgress) {
                        this.progress = 25//Math.round((event.loaded / event.total) * 100);
                    } else if (event.type == HttpEventType.ResponseHeader) {
                        this.progress = 50
                    }
                    else if (event.type == HttpEventType.DownloadProgress) {
                        this.progress = 75
                    }
                    else if (event.type == HttpEventType.Response) {
                        this.progress = 100
                    }
                }),
                last()
            )
            .subscribe({
                next: (response: any) => {
                    if (response != null) {
                        console.log(response);
                        response.body.successMembers.forEach((element) => {
                            this.passedMembers.push(element);
                        });
                        response.body.failedMembers.forEach((element) => {
                            this.failedMembers.push(element);
                        });
                    }
                    this.isLoading = false;
                    this.dialogRef.close({
                        data: {
                            successMembers: this.passedMembers,
                            failedMembers: this.failedMembers,
                            isExceed: response.body.isExceed,
                            inCorrectFile: response.body.inCorrectFile,
                            errorOccured: false
                        },
                    });
                },
                error: (err) => {
                    this.dialogRef.close({
                        data: {
                            successMembers: [],
                            failedMembers: [],
                            errorOccured: true
                        },

                    });
                    console.log(err);
                },
            });


    }

    CheckQuotationExistance() {
        let QuotationNo = localStorage.getItem('quotationNumber');
        if (QuotationNo == undefined) {
            if (localStorage.getItem("crEntityNumber") != undefined) {
                let EmailAddress = this._LocalService.getJsonValue('SponsorEmail');
                let MobileNumber = this._LocalService.getJsonValue('SponsorMobile');
                let CompanyName = this._LocalService.getJsonValue('CompanyName');

                let requestData = {
                    CompanyId: localStorage.getItem("crEntityNumber"),
                    EmailAddress: EmailAddress,
                    MobileNumber: MobileNumber,
                    CompanyName: CompanyName
                };
                this.smeService.GetQuotationId(requestData).subscribe(
                    (res) => {
                        if (res != null) {
                            this.QuotationNumber = res;
                            localStorage.removeItem('quotationNumber');
                            localStorage.setItem('quotationNumber', this.QuotationNumber);

                            this.uploadMembers();
                        }
                    },
                    (err) => { }
                );
            }
        }
        else {
            this.QuotationNumber = QuotationNo;
            this.uploadMembers();
        }
    }

}
