<div>
    <div class="page-title-a bg-2">
        <div class="container col-md-10">
            <div class="page-title-content">
                <h2>{{ "MMLHome.MedicalMalpractice" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/"
                            >{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>

                    <li class="active" style="font-weight: 500">
                        {{ "MMLHome.MedicalMalpractice" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="container mt-5" #container>
    <div>
        <div class="row content">
            <!-- <div class="col-lg-3 col-md-3 col-12">
                <mat-selection-list #items [multiple]="false">
                    <mat-list-option
                        (click)="onTabChanged(item.index)"
                        *ngFor="let item of productsObj; let i = index"
                        [selected]="item && i == activeTab"
                        [value]="item"
                        [disabled]="true"
                    >
                        <div
                            *ngIf="i === activeTab; else elseStatement"
                            class="tab-div"
                        >
                            <img
                                src="{{ item.secondIcon }}"
                                [ngClass]="{
                                    'icons-en': languageValue === 'en-US',
                                    'icons-ar': languageValue === 'ar-SA'
                                }"
                            />

                            {{ item.title | translate }}
                        </div>

                        <ng-template #elseStatement>
                            <div class="tab-div">
                                <img
                                    src="{{ item.firstiIcon }}"
                                    [ngClass]="{
                                        'icons-en': languageValue === 'en-US',
                                        'icons-ar': languageValue === 'ar-SA'
                                    }"
                                />
                                {{ item.title | translate }}
                            </div>
                        </ng-template>
                    </mat-list-option>
                </mat-selection-list>
            </div> -->
            <div class="col-12 main-style">
                <div class="row mb-3" *ngIf="activeTab === 0">
                    <app-purchase-policy></app-purchase-policy>
                </div>
            </div>
        </div>
    </div>
</div>
