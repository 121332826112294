<loading *ngIf="isLoading2">{{ isLoading2 }}</loading>
<div
    [ngClass]="{ 'medical-sme-container': this.translate.currentLang === 'en-US', 'medical-sme-container-ar': this.translate.currentLang === 'ar-SA' }">
</div>
<div class="container-fluid" style="padding: 2%">
    <div class="sme-main-container">
        <div class="container-body row col-lg-12 col-md-12 col-sm-12">
            <div class="img-holder col-lg-4 col-md-4 col-sm-12">
                <img src="assets/img/medical-sme/user-login-1 1.png" alt="" width="50%" style="height: 50%" />
            </div>

            <div class="col-lg-7 col-md-7 col-sm-12" *ngIf="!otpStep">
                <div class="form-container">
                    <span class="welcome-title">{{ "SME.WELCOME" | translate }}</span>
                    <span class="input-title">{{ "SME.ADDCOMPANYDATA" | translate }}</span>

                    <form [formGroup]="SMEWathqForm">
                        <div class="d-flex align-items-center row pt-4">
                            <div class="col-md-3">
                                <label class="form-label-title">{{ "SME.COMPANY_ID" | translate }}
                                </label>
                            </div>

                            <div class="col-md-7">
                                <input (keypress)="($event.charCode >= 48 && $event.charCode < 58)" [maxLength]="10"
                                    id="CompanyId" name="CompanyId" formControlName="CompanyId" [ngClass]="{
                                        'red-border-class':
                                            newSMEWathqCompanyCheckForm
                                                .CompanyId.errors &&
                                            newSMEWathqCompanyCheckForm
                                                .CompanyId.touched
                                    }" class="form-control input-form-design" placeholder="{{
                                        'SME.COMPANY_ID_PLACEHOLDER' | translate
                                    }}" type="text" (input)="handleIqamaNumberChange($event)" />
                                <div *ngIf="
                                        newSMEWathqCompanyCheckForm.CompanyId
                                            .errors &&
                                        newSMEWathqCompanyCheckForm.CompanyId
                                            .touched
                                    ">
                                    <div *ngIf="
                                            newSMEWathqCompanyCheckForm
                                                .CompanyId.errors.required
                                        " style="color: #ff001f">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        {{ "GENERIC.REQUIRED" | translate }}
                                    </div>
                                    <div *ngIf="
                                            newSMEWathqCompanyCheckForm
                                                ?.CompanyId.errors.pattern
                                        " style="color: #ff001f">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        {{ "SME.ONLYNUMBERS" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center row pt-4">
                            <div class="col-md-3">
                                <label class="form-label-title">{{ "SME.EMAIL" | translate }}
                                </label>
                            </div>
                            <div class="col-md-7">
                                <input id="EmailAddress" name="EmailAddress" formControlName="EmailAddress"
                                [maxLength]="50"

                                [ngClass]="{ 'red-border-class':
                                            newSMEWathqCompanyCheckForm
                                                .EmailAddress.errors &&
                                            newSMEWathqCompanyCheckForm
                                                .EmailAddress.touched
                                    }" class="form-control input-form-design" placeholder="{{
                                        'SME.COMPANY_EMAIL_PLACEHOLDER'
                                            | translate
                                    }}" type="text" />
                                <div *ngIf="
                                        newSMEWathqCompanyCheckForm.EmailAddress
                                            .errors &&
                                        newSMEWathqCompanyCheckForm.EmailAddress
                                            .touched
                                    ">
                                    <div *ngIf="
                                            newSMEWathqCompanyCheckForm
                                                .EmailAddress.errors.required
                                        " style="color: #ff001f">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        {{ "GENERIC.REQUIRED" | translate }}
                                    </div>

                                    <div *ngIf="
                                            newSMEWathqCompanyCheckForm
                                                .EmailAddress.errors.pattern
                                        " style="color: #ff001f">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        {{ "GENERIC.EMAIL" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center row pt-4">
                            <div class="col-md-3">
                                <label class="form-label-title">{{ "SME.MOBILE_NUMBER" | translate }}
                                </label>
                            </div>
                            <div class="col-md-7">
                                <input (keypress)="($event.charCode >= 48 && $event.charCode < 58)" id="MobileNumber"
                                    name="MobileNumber" formControlName="MobileNumber" [ngClass]="{
                                        'red-border-class':
                                            newSMEWathqCompanyCheckForm
                                                .MobileNumber.errors &&
                                            newSMEWathqCompanyCheckForm
                                                .MobileNumber.touched
                                    }" [maxLength]="10" class="form-control input-form-design" placeholder="{{
                                        'SME.COMPANY_MOBILE_PLACEHOLDER'
                                            | translate
                                    }}" type="text" />
                                <div *ngIf="
                                        newSMEWathqCompanyCheckForm.MobileNumber
                                            .errors &&
                                        newSMEWathqCompanyCheckForm.MobileNumber
                                            .touched
                                    ">
                                    <div *ngIf="
                                            newSMEWathqCompanyCheckForm
                                                .MobileNumber.errors.required
                                        " style="color: #ff001f">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        {{ "GENERIC.REQUIRED" | translate }}
                                    </div>
                                    <div *ngIf="
                                            newSMEWathqCompanyCheckForm
                                                ?.MobileNumber.errors.pattern
                                        " style="color: #ff001f">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        {{ "SME.ONLYNUMBERS" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center row pt-4" *ngIf="isSA">
                            <div class="col-md-3">
                                <label class="form-label-title">{{ "SME.BIRTHDATE" | translate }}
                                </label>
                            </div>

                            <div class="col-7 row">
                                <div class="col-3 pt-1">
                                    <input #input id="BirthDateDay" name="BirthDateDay" type="text" type="number"
                                        maxlength="2" onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        formControlName="BirthDateDay" [ngClass]="{
                                            'red-border-class':
                                                newSMEWathqCompanyCheckForm
                                                    .BirthDateDay.errors &&
                                                (newSMEWathqCompanyCheckForm
                                                    .BirthDateDay.touched ||
                                                    isSubmit),
                                            '': defaultClassFlag == true,
                                            'mmp-input-form-design':
                                                defaultClassFlag == true
                                        }" class="mmp-input-form-design" placeholder="DD" style="
                                            font-size: 12px;
                                            display: flex;
                                            text-align: center;
                                        " pattern="\d*" />
                                </div>
                                <div class="col-1 pt-2" style="font-size: 20px; font-weight: 500">
                                    /
                                </div>
                                <div class="col-3 pt-1">
                                    <input #input id="BirthDateMonth" name="BirthDateMonth" type="text" type="number"
                                        maxlength="2" onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        formControlName="BirthDateMonth" [ngClass]="{
                                            'red-border-class':
                                                newSMEWathqCompanyCheckForm
                                                    .BirthDateMonth.errors &&
                                                (newSMEWathqCompanyCheckForm
                                                    .BirthDateMonth.touched ||
                                                    isSubmit),
                                            '': defaultClassFlag == true,
                                            'mmp-input-form-design':
                                                defaultClassFlag == true
                                        }" class="mmp-input-form-design" placeholder="MM" style="
                                            font-size: 12px;
                                            display: flex;
                                            text-align: center;
                                        " pattern="\d*" />
                                </div>
                                <div class="col-1 pt-2" style="font-size: 20px; font-weight: 500">
                                    /
                                </div>
                                <div class="col-3 pt-1">
                                    <input #input id="BirthDateYear" name="BirthDateYear" type="email" maxlength="4"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        formControlName="BirthDateYear" [ngClass]="{
                                            'red-border-class':
                                                newSMEWathqCompanyCheckForm
                                                    .BirthDateYear.errors &&
                                                (newSMEWathqCompanyCheckForm
                                                    .BirthDateYear.touched ||
                                                    isSubmit),
                                            '': defaultClassFlag == true,
                                            'mmp-input-form-design':
                                                defaultClassFlag == true
                                        }" class="mmp-input-form-design" placeholder="YYYY" style="
                                            font-size: 12px;
                                            display: flex;
                                            text-align: center;
                                        " />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 text-center pt-3">
                            <div class="d-flex justify-content-center pt-4 mb-5">
                                <button class="sme-submit-btn" [disabled]="
                                        isLoading || SMEWathqForm.invalid
                                    " [ngClass]="{'disabled-btn':SMEWathqForm.invalid}"
                                    (click)="SubmitCompanySearchForm()">
                                    <span *ngIf="!isLoading">{{
                                        "GENERIC.SUBMIT" | translate
                                        }}</span>
                                    <span *ngIf="isLoading">
                                        <div class="spinner-border text-warning"></div>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-7 col-md-7 col-sm-12" *ngIf="otpStep">
                <div class="form-container">
                    <span class="welcome-title">{{
                        "SME.WELCOME" | translate
                        }}</span>
                    <span class="input-title">{{
                        "SME.ADDOTP" | translate
                        }}</span>

                    <form (ngSubmit)="GetWathqData()" [formGroup]="form">
                        <div class="card pt-4  d-flex flex-row justify-content-center text-center"
                            style="direction: ltr;">
                            <!-- <div> <span>A code has been sent to</span> <small>*******9897</small> </div> -->
                            <div id="otp" class="inputs  mt-2" style="margin-right: 2%; width: 8%;"
                                *ngFor="let input of formInput;  index as i">

                                <input #formRow type="text" formControlName="{{input}}" autofocus #swiper
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                                    class="m-2 text-center form-control rounded" maxlength="1"
                                    (keyup)="keyUpEvent($event, i)">
                            </div>

                        </div>

                    </form>
                    <div class="card-2 pt-3">
                        <div class="content d-flex justify-content-center align-items-center" style="font-size:15px">
                            {{display}} </div>
                        <div class="content d-flex justify-content-center align-items-center" style="font-size:15px">
                            <span style="color:#156538">{{ "SME.OTP_RESEND_MSG" | translate }}</span>
                            <button (click)="ResendVerification()" [ngClass]="{'otpExpire': otpExpire}"
                                [disabled]="!otpExpire" class="text-decoration-underline ms-3 otp">{{
                                "SME.OTP_RESEND_BTN" | translate }}</button>
                        </div>
                    </div>

                    <div class="mt-4 d-flex justify-content-center">
                        <span *ngIf="invalidOTP" style="font-weight: 700; color:red; font-size: 20px;">
                            {{
                            "SME.INVALID_OTP" | translate
                            }}
                        </span>
                    </div>

                    <div class="pt-5 pb-5 d-flex justify-content-center"> <button (click)="GetWathqData()" id="login-btn"
                            [disabled]="!form.valid" [ngClass]="{'disabled-btn':!form.valid}"
                            class="sme-submit-btn"><span *ngIf="!isLoading">{{
                                "GENERIC.SUBMIT" | translate
                                }}</span>
                            <span *ngIf="isLoading">
                                <div class="spinner-border text-warning"></div>
                            </span></button> </div>
                </div>
            </div>
        </div>
    </div>
</div>