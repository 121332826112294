<br />
<br />
<section class="h-100 gradient-form" style="background-color: #eee;">
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-xl-10">
                <div class="card text-black" style="border-radius:2rem">
                    <div class="row g-0">
                        <div class="col-lg-6" *ngIf="!otpStep">
                            <div class="card-body p-md-5 mx-md-4" >

                                <div class="text-center">
                                    <img src="../../../../assets/img/logo/user-login.png"
                                         style="width: 185px;" alt="logo">
                                </div>
                                <form method="post" >
                                    <div class="form-outline mb-4">
                                        <label class="form-label" for="form2Example11">Id Number</label>
                                        <input autocomplete="false" type="text" id="form2Example11" class="form-control" maxlength="10"

                                        oninput="javascript:  var element = document.getElementById(id);    var regex = /[^0-9\-]/gi;        element.value = element.value.replace(regex, '');"
                                               placeholder="Id Number" />
                                    </div>

                                    <div class="form-outline mb-4">
                                        <label class="form-label" for="form2Example22">Mobile No</label>
                                        <input autocomplete="false" type="text" id="form2Example22" class="form-control" maxlength="10"
                                        oninput="javascript:  var element = document.getElementById(id);    var regex = /[^0-9\-]/gi;        element.value = element.value.replace(regex, '');" />

                                    </div>

                                    <div class="text-center pt-1 mb-5 pb-1">
                                        <button class="btn btn-rounded btn-block fa-lg gradient-custom-2 mb-3" style="background-color: #226439;color: white;width: 10rem;font-size: 1.2rem;" type="submit">
                                            Save
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div class="col-lg-6" *ngIf="otpStep">

                            <div class="card-body p-md-5 mx-md-4" >

                                <div class="text-center">
                                    <img src="../../../../assets/img/logo/user-login.png"
                                         style="width: 185px;" alt="logo">
                                         <br />
                                         <span class="input-title">{{
                                            "MOTORCLAIMS.ADDOTP" | translate
                                            }}</span>
                                </div>
                                <form (ngSubmit)="GetWathqData()" [formGroup]="form" >
                                    <div class="card pt-4  d-flex flex-row justify-content-center text-center"
                                        style="direction: ltr;">
                                        <!-- <div> <span>A code has been sent to</span> <small>*******9897</small> </div> -->
                                        <div id="otp" class="inputs  mt-2" style="margin-right: 2%; width: 15%;"
                                            *ngFor="let input of formInput;  index as i">

                                            <input #formRow type="text" formControlName="{{input}}" autofocus #swiper
                                            (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                                                class="m-2 text-center form-control rounded" maxlength="1"
                                                (keyup)="keyUpEvent($event, i)">
                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div class="card-2 pt-3">
                                <div class="content d-flex justify-content-center align-items-center" style="font-size:15px">
                                    {{display}} </div>
                                <div class="content d-flex justify-content-center align-items-center" style="font-size:15px">
                                    <span style="color:#156538">{{ "MOTORCLAIMS.OTP_RESEND_MSG" | translate }}</span>
                                    <button (click)="ResendVerification()" [ngClass]="{'otpExpire': otpExpire}"
                                        [disabled]="!otpExpire" class="text-decoration-underline ms-3 otp">{{
                                        "MOTORCLAIMS.OTP_RESEND_BTN" | translate }}</button>
                                </div>
                            </div>

                            <div class="mt-4 d-flex justify-content-center">
                                <span *ngIf="invalidOTP" style="font-weight: 700; color:red; font-size: 20px;">
                                    {{
                                    "MOTORCLAIMS.INVALID_OTP" | translate
                                    }}
                                </span>
                            </div>

                            <div class="pt-5 pb-5 d-flex justify-content-center"> <button (click)="ValidateOTP()" id="login-btn"
                                    [disabled]="!form.valid" [ngClass]="{'disabled-btn':!form.valid}"
                                    class="btn btn-rounded btn-block fa-lg gradient-custom-2 mb-3" style="background-color: #226439;color: white;width: 10rem;font-size: 1.2rem;"><span *ngIf="!isLoading">{{
                                        "GENERIC.SUBMIT" | translate
                                        }}</span>
                                    <span *ngIf="isLoading">
                                        <div class="spinner-border text-warning"></div>
                                    </span></button> </div>

                        </div>
                        <div class="col-lg-6 d-flex align-items-center gradient-custom-2" style="background-color: #226439;border-radius:2rem">
                            <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                                <h4 class="mb-4"> عزيزي العميل</h4>

                                    <ul class="small mb-0">
                                        <li>
                                            مرحبا بكم في منصة الخدمات الإلكترونية اتحاد الخليج الأهلية. تكرس شركة اتحاد الخليج الأهلية جهودها لتزويدك بأفضل تغطية تأمينية ممكنة ، ونحن ندرك أنه في عالم اليوم المزدحم ، يقدر عملاؤنا الوصول السريع والمريح إلى وثائق التأمين الخاصة بهم. لهذا السبب قمنا بتطوير مجموعة من الخدمات الإلكترونية المصممة لجعل إدارة وثيقة التأمين الخاصة بك أسهل وأكثر كفاءة وأكثر ملاءمة من أي وقت مضى.
                                        </li>
                                        <li>
                                            تم تصميم منصة الخدمات الإلكترونية الخاصة بنا مع وضعك في الاعتبار ، مما يوفر لك مجموعة من الأدوات عبر الإنترنت لمساعدتك في إدارة سياستك من أي مكان وفي أي وقت. مع خدماتنا الإلكترونية ، لن تقلق أبدًا بشأن الانتظار في الانتظار لممثل خدمة العملاء أو زيارة مكتبنا شخصيًا عندما تحتاج إلى إجراء تغيير على سياستك.
                                        </li>
                                        <li>
                                            تقدم منصة الخدمات الإلكترونية الخاصة بنا مجموعة واسعة من الأدوات التي يمكنك الوصول إليها مباشرة من موقعنا ، بما في ذلك إدارة السياسة عبر الإنترنت ، وتقديم المطالبات عبر الإنترنت ، وغير ذلك الكثير. ما عليك سوى تسجيل الدخول إلى بوابتنا الآمنة باستخدام تفاصيل تسجيل الدخول الفريدة الخاصة بك ، وسيكون لديك وصول فوري إلى جميع المعلومات التي تحتاجها.
                                        </li>
                                        <li>
                                            في اتحاد الخليج الأهلية ، نحن ملتزمون بتزويد عملائنا بأفضل خدمة ممكنة ، ومنصة الخدمات الإلكترونية لدينا هي مجرد مثال واحد على هذا الالتزام. نحن نتفهم أن وقتك ثمين ، ولهذا صممنا خدماتنا الإلكترونية لتكون سهلة الاستخدام ومريحة قدر الإمكان
                                        </li>
                                    </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
