import { Component } from '@angular/core';

@Component({
  selector: 'app-error-payment-online',
  templateUrl: './error-payment-online.component.html',
  styleUrls: ['./error-payment-online.component.scss']
})
export class ErrorPaymentOnlineComponent {

}
