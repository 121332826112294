<div>
    <div class="page-title-area bg-1">
        <div class="container col-md-10">
            <div class="page-title-content">
                <h2>{{ "MEDICAL.Medical-Approval" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/"
                            >{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li>
                        <a routerLink="/medical-profile">
                            {{ "NAV.MEDICAL" | translate }}

                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li class="active" style="font-weight: 500">
                        {{ "MEDICAL.Medical-Approval" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container pt-3">
    <div class="row">
        <div class="claims-header col-lg-3 col-md-12 col-sm-12">
            <div class="d-flex">
                <a href="/medical-profile">
                    <img
                        src="assets/img/medical-service/icons8-back-arrow-90.png"
                        class="back-icon"
                        alt=""
                        [ngClass]="{
                            'arrow-img-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                    />
                </a>
                <h5
                    class="color-green"
                    [ngClass]="{
                        'title-en': this.translate.currentLang == 'en-US',
                        'title-ar': this.translate.currentLang != 'en-US'
                    }"
                >
                    {{ "MEDICAL.Medical-Approval" | translate }}
                </h5>
            </div>
            <hr
                [ngClass]="{
                    'hr-en': this.translate.currentLang == 'en-US',
                    'hr-ar': this.translate.currentLang != 'en-US'
                }"
            />
        </div>

        <div class="row d-flex drobdowns-area pt-3">
            <div class="row" style="margin-top: -40px">
                <div class="col-lg-2 col-md-6 col-sm-12 pb-3"></div>
                <div class="col-lg-2 col-md-6 col-sm-12 pb-3">
                    <mat-form-field appearance="none">
                        <mat-select
                            placeholder="{{
                                'MEDICAL.insuredMember' | translate
                            }}"
                            style="height: 20px !important; margin-top: -5px"
                            (selectionChange)="filterByName($event.value)"
                            [(ngModel)]="insuredMember"
                        >
                            <mat-option
                                *ngFor="let mem of memberDetails"
                                [value]="mem"
                                >{{ mem.memberEName }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 pb-3">
                    <mat-form-field
                        appearance="none"
                        class="form-control input-form-design w-100"
                    >
                        <input
                            #fromInput
                            formControlName="date"
                            matInput
                            [matDatepicker]="fromInputPicker"
                            placeholder="{{
                                'MEDICAL.Medical-Approval-Fdate' | translate
                            }}"
                            [max]="today"
                            class="ng-placeholder"
                            (dateChange)="addFromDate('change', $event)"
                        />
                        <mat-datepicker-toggle
                            style="
                                margin-right: -6%;
                                margin-top: -100%;
                                color: #156538;
                            "
                            matIconSuffix
                            [for]="fromInputPicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #fromInputPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 pb-3">
                    <mat-form-field
                        appearance="none"
                        class="form-control input-form-design w-100"
                    >
                        <input
                            #toInput
                            formControlName="date"
                            matInput
                            [matDatepicker]="toInputPicker"
                            placeholder="{{
                                'MEDICAL.Medical-Approval-Tdate' | translate
                            }}"
                            [max]="today"
                            [min]="minDate"
                            (dateChange)="addToDate('change', $event)"
                            [disabled]="!minDate"
                        />
                        <mat-datepicker-toggle
                            style="
                                margin-right: -6%;
                                margin-top: -100%;
                                color: #156538;
                            "
                            matIconSuffix
                            [for]="toInputPicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                            [startAt]="today"
                            #toInputPicker
                        ></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12 pb-3 margin-left-right">
                    <button
                        class="btn default-btn"
                        [disabled]="
                            !fromDate || !toInput.value || !insuredMember
                        "
                        (click)="getMemberData()"
                    >
                        {{ "MEDICAL.Search" | translate }}
                    </button>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 pb-3 margin-left-right">
                    <button
                        class="btn default-btn"
                        [disabled]="!resertoggle"
                        (click)="reset()"
                    >
                        {{ "MEDICAL.Reset" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div
    class="container pt-0 pb-2"
    [ngClass]="{
        'table-container-en': this.translate.currentLang == 'en-US',
        'table-container-ar': this.translate.currentLang != 'en-US'
    }"
>
    <div class="col-md-12">
        <div class="panel panel-default">
            <div class="panel-body" *ngIf="medicalApprovals?.data?.length > 0">
                <div
                    class="table-content col-md-12"
                    style="overflow-x: auto; padding: 0 4%"
                >
                    <table
                        mat-table
                        [dataSource]="dataSource"
                        multiTemplateDataRows
                        class="mat-elevation-z8"
                    >
                        <ng-container matColumnDef="button">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button
                                    class="btn btn-default btn-xs"
                                    style="color: #d4ab36"
                                    *ngIf="element.expanded === false"
                                >
                                    <span
                                        *ngIf="
                                            this.translate.currentLang ==
                                            'en-US'
                                        "
                                        class="fa fa-angle-right"
                                        aria-hidden="true"
                                    ></span>

                                    <span
                                        *ngIf="
                                            this.translate.currentLang !=
                                            'en-US'
                                        "
                                        class="fa fa-angle-left"
                                        aria-hidden="true"
                                    ></span>
                                </button>

                                <button
                                    class="btn btn-default btn-xs"
                                    style="color: #d4ab36"
                                    *ngIf="element.expanded === true"
                                >
                                    <span
                                        class="fa fa-angle-down"
                                        aria-hidden="true"
                                    ></span>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="approvalNumber">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "MEDICAL.ApprovalNUMBER" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.approvalNo }}
                            </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="serviceProvider">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "MEDICAL.Service" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.approvalName }}
                            </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "MEDICAL.Date" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{
                                    element.creationDate | date : "dd MMMM yyyy"
                                }}
                            </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="insuredMember">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "MEDICAL.insuredMember" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.memberNme }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="state">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "MEDICAL.STATE" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div
                                    [ngClass]="{
                                        'circle-green':
                                            element.approvalStatus ===
                                            'Approved',
                                        'circle-Red':
                                            element.approvalStatus ===
                                            'Rejected'
                                    }"
                                ></div>
                                {{ element.approvalStatus }}
                            </td>
                        </ng-container>
                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [attr.colspan]="columnsToDisplay.length"
                            >
                                <div
                                    class="example-element-detail"
                                    [@detailExpand]="
                                        element.expanded
                                            ? 'expanded'
                                            : 'collapsed'
                                    "
                                >
                                    <div class="col-md-12">
                                        <div class="collapse-body row">
                                            <div
                                                class="row justify-content-end p-0 m-0"
                                            >
                                                <div
                                                    [ngClass]="{
                                                        'item-des-en':
                                                            this.translate
                                                                .currentLang ==
                                                            'en-US',
                                                        'item-des-ar':
                                                            this.translate
                                                                .currentLang !=
                                                            'en-US'
                                                    }"
                                                >
                                                    <div
                                                        class="p-3 d-flex justify-content-center"
                                                    >
                                                        <img
                                                            src="assets/img/medical-service/dental (1).png"
                                                            width="60"
                                                            alt=""
                                                            style="
                                                                margin-top: -6px;
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="row"
                                                style="
                                                    margin-top: -60px;
                                                    text-align: start;
                                                "
                                            >
                                                <div
                                                    class="col-md-7 col-11 pb-3"
                                                >
                                                    <h3 class="submenu p-3">
                                                        {{
                                                            "MEDICAL.approvedItems"
                                                                | translate
                                                        }}
                                                    </h3>
                                                    <div class="Approved-div">
                                                        <table
                                                            class="table table-condensed table-striped col-md-8 borderless"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th
                                                                        style="
                                                                            width: 250px;
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MEDICAL.MedicineName"
                                                                                | translate
                                                                        }}
                                                                    </th>

                                                                    <th
                                                                        style="
                                                                            color: #15b42b;
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MEDICAL.Accepted"
                                                                                | translate
                                                                        }}
                                                                    </th>
                                                                    <th
                                                                        style="
                                                                            color: red;
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MEDICAL.STATE"
                                                                                | translate
                                                                        }}
                                                                    </th>
                                                                    <th
                                                                        style="
                                                                            color: #155fb4;
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MEDICAL.Note"
                                                                                | translate
                                                                        }}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody
                                                                *ngFor="
                                                                    let med of medicalApprovalsDetails
                                                                "
                                                            >
                                                                <tr
                                                                    class="borderless"
                                                                >
                                                                    <td
                                                                        style="
                                                                            width: 250px;
                                                                        "
                                                                    >
                                                                        {{
                                                                            med.serviceDesc
                                                                        }}
                                                                    </td>

                                                                    <td>
                                                                        {{
                                                                            med.approvedQuantity
                                                                        }}
                                                                    </td>
                                                                    <td
                                                                        *ngIf="
                                                                            med.status ===
                                                                            ''
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MEDICAL.under"
                                                                                | translate
                                                                        }}
                                                                    </td>
                                                                    <td
                                                                        *ngIf="
                                                                            med.status ===
                                                                            'Approved'
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MEDICAL.Approved"
                                                                                | translate
                                                                        }}
                                                                    </td>
                                                                    <td
                                                                        *ngIf="
                                                                            med.status ===
                                                                            'Partial Approved'
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MEDICAL.Partial"
                                                                                | translate
                                                                        }}
                                                                    </td>
                                                                    <td
                                                                        *ngIf="
                                                                            med.status ===
                                                                            'Rejected'
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MEDICAL.Rejected"
                                                                                | translate
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            med.rejectedReason
                                                                        }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-md-4 col-11 pb-3"
                                                >
                                                    <h3 class="submenu p-3">
                                                        {{
                                                            "MEDICAL.approvedNote"
                                                                | translate
                                                        }}
                                                    </h3>
                                                    <div
                                                        class="Approved-div d-grid align-items-center w-100"
                                                    >
                                                        <p class="note p-1">
                                                            {{ element.notes }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="columnsToDisplay"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="let element; columns: columnsToDisplay"
                            class="example-element-row"
                            [class.example-expanded-row]="element.expanded"
                            (click)="toggleRow(element)"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: ['expandedDetail']"
                            class="example-detail-row"
                        ></tr>
                    </table>
                    <mat-paginator
                        [pageSizeOptions]="[10]"
                        showFirstLastButtons
                        aria-label="Select page of periodic elements"
                    >
                    </mat-paginator>
                </div>
            </div>

            <div
                *ngIf="
                    (!medicalApprovals ||
                        medicalApprovals?.data?.length == 0) &&
                    pageInit === false &&
                    isLoading === false
                "
                class="d-flex justify-content-center"
                style="text-align: center !important"
            >
                <div>
                    <img
                        src="assets/img/medical-service/noClaims.png"
                        alt=""
                        style="width: 250px"
                    />
                    <h5 class="color-green" style="text-align: center">
                        {{ "MEDICAL.approval-Mes" | translate }}
                    </h5>
                </div>
            </div>
            <div *ngIf="pageInit === true" style="height: 30vh"></div>

            <!-- <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item next">
                        <a class="page-link" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <div class="pag-items">
                        <li class="page-item">
                            <a class="page-link pagentation-items">1</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link pagentation-items">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link pagentation-items">3</a>
                        </li>
                    </div>
                    <li class="page-item next">
                        <a class="page-link" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav> -->
        </div>
    </div>
</div>
