
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { Location } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    Inject
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import{MatSort} from '@angular/material/sort'
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MotorClaimsService } from 'src/app/core/services/motor-claims.service';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Dialog, DialogModule, DialogRef} from '@angular/cdk/dialog';
import {

    MatDialogRef,

  } from '@angular/material/dialog';
  import { Router } from '@angular/router';
import {
    MatDialog,

    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
  } from '@angular/material/dialog';

@Component({
  selector: 'app-motor-claims-inquire',
  templateUrl: './motor-claims-inquire.component.html',
  styleUrls: ['./motor-claims-inquire.component.scss']
})
export class MotorClaimsInquireComponent implements OnInit, AfterViewInit {
    claimsDetails : any[];
    name:string;
    reuploaded:string = "";
    nat:string;
    isThereMissingDocs:string;
    displayedColumns = ['portalReference', 'accidentNo', 'accidentDate','status', 'claimStatus','documentNote','details'];
    dataSource: MatTableDataSource<ClaimDetails>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

  constructor(
    public translate: TranslateService,
    public medicalClaimsService: MedicalClaimsService,
    private _LocalService: LocalService,
    public motorClaimsService: MotorClaimsService,
    private location: Location,
    public dialog: MatDialog,
  ) {
    this.reuploaded =this.location.getState()['reuploaded'] != null ? this.location.getState()['reuploaded'] :'';

    this.claimsDetails = this.location.getState()['claimsDetails'];

    if (localStorage.getItem('claimDetails') !== null && localStorage.getItem('claimDetails') !== undefined) {
        this.claimsDetails = JSON.parse(localStorage.getItem('claimDetails'));
    }
    if( this.reuploaded != null &&  this.reuploaded != '' &&  this.reuploaded !==undefined)
    {
       let item =  this.claimsDetails.find(p=>p.claimno == this.reuploaded );
       item.documentStatus = 'Completed';
       this.claimsDetails.find(p=>p.claimno == this.reuploaded ).remove();
       this.claimsDetails.push( item );

    }
    this.dataSource = new MatTableDataSource(this.claimsDetails);
    this.name = this.claimsDetails[0].claimantnameen;
    this.nat= this.claimsDetails[0].iD_NO;
    }



  ngOnInit(): void {


  }
  openDialog(element) {
    this.dialog.open(ClaimDetailsDialogComponent,{
        data: {details: element },
        height: '600px',
        width: '600px',
      });
  }
  openMissingDialog(element)
  {
    this.motorClaimsService.getMissingDocs(element.reportno,element.iD_NO).subscribe((res) => {
        if (res) {
            this.dialog.open(MissingDocDialogComponent,{
                data: {details: res , claim: element},
                height: '340px',
                width: '750px',
              });
        }
        else
        {
            this.isThereMissingDocs ="There are no missing documents";

        }

      });

  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
export interface ClaimDetails {
    iD_NO:string;
    claimNumber: 'string';
    accidentNo: 'string';
    claimDate: 'string';
    attachmentStatus: 'string';
    portal_Ref:'string';
    accidentDate:'string';
    insuranceType:'string';
    vehicleMake:'string';
    vehicleMaker:'string';
    vehicleModel:'string';
    plateNo:'string';
    sequenceNo:'string';
    claimStatus:'string';
    documentStatus:'string';
    paymentAmount:'string';
    paymentDate:'string';
    ibanNo:'string';

}

export interface reuploadDocs{
    cLAIMNO:string;
    PATH :string;
    UPLOADDATE:string;
    cS_DOC_TYPE :string;
    doC_BYTE :string;
}

@Component({
    selector: 'claim-details-dialog',
    templateUrl: 'claim-details-dialog.component.html',
    styleUrls: ['./motor-claims-dialog.component.scss'],


  })

  export class ClaimDetailsDialogComponent {
    infodetails : any;
    nationalImgsList = [];
    ImgsId: any[] = [];
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public translate: TranslateService,public dialogRef: DialogRef){
    this.infodetails = data.details;
    }

  }

  @Component({
    selector: 'missing-doc-dialog',
    templateUrl: 'missing-doc-dialog.component.html'


  })

  export class MissingDocDialogComponent {
    docsList : [] ;
    UploadStatus : string='';
    reupload : reuploadDocs[]= [];
    claimInfo:any = {} as any ;
    index : number = 0;
    docs : MissingDocs[] = [];
    documentsLst : string = '';
    nationalImgsList = [];
    ImgsId: any[] = [];
    constructor(@Inject(MAT_DIALOG_DATA) public data: any ,public translate: TranslateService,public dialogRef: MatDialogRef<MissingDocDialogComponent>,private _router: Router,public motorClaimsService: MotorClaimsService){
        this.docsList =data.details;
        this.claimInfo = data.claim;
        this.docs = data.details;
        data.details.forEach(item=>{
            if(this.index != data.details.length - 1 )
            {
                this.documentsLst += item.eName+',';
            }
            else
            {
                this.documentsLst += item.eName;
            }
            this.index++;
        });
    }
    ngOnInit(): void {


    }
    setDocsList(event, id) {
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];
          const fileName = file.name;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.nationalImgsList.push({
              image: reader.result,
              id: id,
              name: fileName,
            });
              if(!this.ImgsId.includes(id)) {
              this.ImgsId.push(id);
            }
          };
        }

      }
      deleteAtt(x) {
        this.nationalImgsList.splice(x, 1);
      }
      ReUpload() {
      this.nationalImgsList.forEach(x =>{
        let item = {} as reuploadDocs;
        item.doC_BYTE = x.image;
        item.cS_DOC_TYPE = x.id;
        item.cLAIMNO = this.claimInfo.claimno;
        item.PATH = 'path:';
        item.UPLOADDATE = 'date';
        this.reupload.push(item);
      });
      this.motorClaimsService.reUploadClaimDocs(this.reupload).subscribe((res) => {
        if (res) {
        this.UploadStatus = 'Documents uploaded successfully';
        this._router.navigateByUrl('/motor-claims-inquire', {
            state: {
                reuploaded: this.claimInfo.claimno,
            },

        });

        }
        else
        {

        }
      });


      }
  }
  export interface MissingDocs {
    eName:string;
    aName:string;
}
