<!-- Start Page Title Area -->
<div>
    <div class="page-title-area bg-1">
        <div class="container col-md-10">
            <div class="page-title-content">
                <h2>{{ "NAV.CASHCLAIMS" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/"
                            >{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li>
                        <a routerLink="/medical-profile">
                            {{ "NAV.MEDICAL" | translate }}

                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li class="active" style="color: white">
                        {{ "NAV.CASHCLAIMS" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <loading *ngIf="isLoading">{{ isLoading }}</loading>

    <div class="container pt-3 pb-1">
        <div class="row">
            <div style="display: flex">
                <a href="/medical-profile">
                    <img
                        src="assets/img/medical-service/icons8-back-arrow-90.png"
                        class="back-icon"
                        alt=""
                        [ngClass]="{
                            'arrow-img-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                    />
                </a>
                <h5
                    class="color-green"
                    [ngClass]="{
                        'title-en': this.translate.currentLang == 'en-US',
                        'title-ar': this.translate.currentLang != 'en-US'
                    }"
                >
                    {{ "MEDICAL.MedicalCashClaims" | translate }}
                </h5>
            </div>
            <hr
                [ngClass]="{
                    'hr-en': this.translate.currentLang == 'en-US',
                    'hr-ar': this.translate.currentLang != 'en-US'
                }"
            />
        </div>

        <div
            class="row claims-container"
            [ngClass]="{
                'inner-container-en': this.translate.currentLang == 'en-US',
                'inner-container-ar': this.translate.currentLang != 'en-US'
            }"
            style="margin-top: -10px; width: 99%"
        >
            <div class="col-lg-3 col-md-3 col-sm-6 col-5 pb-2">
                <button
                    mat-button
                    class="new-claim-btn"
                    (click)="newMedicalCliam()"
                >
                    <span style="font-size: 20px; font-weight: 600">+</span>
                    {{ "MEDICAL.NewMedicalCashClaim" | translate }}
                </button>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 pb-2">
                <mat-form-field appearance="none">
                    <mat-select
                        placeholder="{{
                            'MEDICAL.Medical-Approval-placeholder' | translate
                        }}"
                        style="padding-top: 4px"
                        (selectionChange)="filterByName($event.value)"
                        [(ngModel)]="insuredMember"
                    >
                        <mat-option
                            *ngFor="let mem of memberDetails"
                            [value]="mem"
                            >{{ mem.memberEName }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 pb-2">
                <mat-form-field
                    appearance="none"
                    class="form-control input-form-design w-100"
                >
                    <input
                        #fromInput
                        formControlName="date"
                        matInput
                        [matDatepicker]="fromInputPicker"
                        placeholder="{{
                            'MEDICAL.Medical-Approval-Fdate' | translate
                        }}"
                        [max]="today"
                        (dateChange)="addFromDate('change', $event)"
                    />
                    <mat-datepicker-toggle
                        style="margin-top: -3%; color: #156538"
                        matIconSuffix
                        [for]="fromInputPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #fromInputPicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 pb-2">
                <mat-form-field
                    appearance="none"
                    class="form-control input-form-design w-100"
                >
                    <input
                        #toInput
                        formControlName="date"
                        matInput
                        [matDatepicker]="toInputPicker"
                        placeholder="{{
                            'MEDICAL.Medical-Approval-Tdate' | translate
                        }}"
                        [max]="today"
                        [min]="minDate"
                        (dateChange)="addToDate('change', $event)"
                        [disabled]="!minDate"
                    />
                    <mat-datepicker-toggle
                        style="margin-top: -3%; color: #156538"
                        matIconSuffix
                        [for]="toInputPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #toInputPicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div
                class="col-lg-1 col-md-3 col-sm-6 col-12 pb-2"
                style="padding-right: 0px"
            >
                <label class="providers-area"></label>
                <button
                    class="btn default-btn searchBtn"
                    [disabled]="
                        (!fromDate || !toInput.value || !insuredMember) &&
                        !insuredMember
                    "
                    (click)="getMemberData()"
                >
                    {{ "MEDICAL.Search" | translate }}
                </button>
            </div>
            <div
                class="col-lg-1 col-md-3 col-sm-6 col-12 pb-2"
                style="padding-right: 0px"
            >
                <button
                    class="btn default-btn searchBtn"
                    [disabled]="!resertoggle"
                    (click)="reset()"
                >
                    {{ "MEDICAL.Reset" | translate }}
                </button>
            </div>
        </div>

        <div
            class="row pt-2"
            [ngClass]="{
                'inner-container-en': this.translate.currentLang == 'en-US',
                'inner-container-ar': this.translate.currentLang != 'en-US'
            }"
        >
            <div class="panel panel-default col-lg-12 col-md-12 col-sm-12">
                <div class="panel-body" *ngIf="Claims?.length > 0">
                    <div class="col-md-2"></div>
                    <div
                        class="table-content col-md-11"
                        style="overflow-x: auto"
                    >
                        <table
                            mat-table
                            [dataSource]="dataSource"
                            multiTemplateDataRows
                            class="mat-elevation-z8"
                        >
                            <ng-container matColumnDef="button">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <button
                                        class="btn btn-default btn-xs"
                                        style="color: #d4ab36"
                                        *ngIf="element.expanded === false"
                                    >
                                        <span
                                            *ngIf="
                                                this.translate.currentLang ==
                                                'en-US'
                                            "
                                            class="fa fa-angle-right"
                                            aria-hidden="true"
                                        ></span>

                                        <span
                                            *ngIf="
                                                this.translate.currentLang !=
                                                'en-US'
                                            "
                                            class="fa fa-angle-left"
                                            aria-hidden="true"
                                        ></span>
                                    </button>

                                    <button
                                        class="btn btn-default btn-xs"
                                        style="color: #d4ab36"
                                        *ngIf="element.expanded === true"
                                    >
                                        <span
                                            class="fa fa-angle-down"
                                            aria-hidden="true"
                                        ></span>
                                    </button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="referenceNum">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.Claim-Refrence" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.referenceNum }}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="serviceProvider">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.ServiceProvider" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.serviceProvider }}
                                </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="claimDate">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.ClaimDate" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.claimDate }}
                                </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="memberName">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.insuredMember" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.memberName }}
                                </td>
                            </ng-container>

                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [attr.colspan]="columnsToDisplay.length"
                                >
                                    <div
                                        class="example-element-detail"
                                        [@detailExpand]="
                                            element.expanded
                                                ? 'expanded'
                                                : 'collapsed'
                                        "
                                    >
                                        <div class="col-md-12">
                                            <div
                                                class="collapse-body row pt-1 pb-1"
                                            >
                                                <div
                                                    class="row justify-content-end p-0 m-0"
                                                ></div>
                                                <div
                                                    class="row justify-content-center"
                                                    style="
                                                        margin-top: -60px;
                                                        text-align: start;
                                                    "
                                                >
                                                    <div
                                                        class="col-md-7 col-11 pb-3"
                                                    >
                                                        <h3 class="submenu p-3">
                                                            {{
                                                                "MEDICAL.approvedItems"
                                                                    | translate
                                                            }}
                                                        </h3>
                                                        <div
                                                            class="Approved-div"
                                                        >
                                                            <table
                                                                class="table table-condensed table-striped col-md-8 borderless"
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            {{
                                                                                "MEDICAL.des"
                                                                                    | translate
                                                                            }}
                                                                        </th>
                                                                        <th>
                                                                            {{
                                                                                "MEDICAL.Date"
                                                                                    | translate
                                                                            }}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody
                                                                    *ngFor="
                                                                        let med of ClaimsDetails
                                                                    "
                                                                >
                                                                    <tr
                                                                        class="borderless"
                                                                    >
                                                                        <td>
                                                                            {{
                                                                                med.statusDESC
                                                                            }}
                                                                        </td>
                                                                        <td>
                                                                            {{
                                                                                med.statusDATE
                                                                                    | date
                                                                                        : "dd MMMM yyyy"
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <tr
                                mat-header-row
                                *matHeaderRowDef="columnsToDisplay"
                            ></tr>
                            <tr
                                mat-row
                                *matRowDef="
                                    let element;
                                    columns: columnsToDisplay
                                "
                                class="example-element-row"
                                [class.example-expanded-row]="element.expanded"
                                (click)="toggleRow(element)"
                            ></tr>
                            <tr
                                mat-row
                                *matRowDef="
                                    let row;
                                    columns: ['expandedDetail']
                                "
                                class="example-detail-row"
                            ></tr>
                        </table>
                        <mat-paginator
                            [pageSizeOptions]="[10]"
                            showFirstLastButtons
                            aria-label="Select page of periodic elements"
                        >
                        </mat-paginator>
                    </div>
                </div>

                <div
                    *ngIf="
                        (!Claims || Claims?.length == 0) &&
                        pageInit === false &&
                        isLoading === false
                    "
                    class="d-flex justify-content-center"
                    style="text-align: center !important"
                >
                    <div>
                        <img
                            src="assets/img/medical-service/noClaims.png"
                            alt=""
                            style="width: 250px"
                        />
                        <h5 class="color-green" style="text-align: center">
                            {{ "MEDICAL.cash-Mes" | translate }}
                        </h5>
                    </div>
                </div>
                <div *ngIf="pageInit === true" style="height: 30vh"></div>
            </div>
        </div>
    </div>
</div>
