<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ "NAV.SERVICES" | translate }}</h2>
            <ul>
                <li>
                    <a routerLink="/">{{ "NAV.SERVICES" | translate }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="container pt-5 pb-5">
    <div class="row">
        <mat-tab-group mat-stretch-tabs animationDuration="0ms" (selectedTabChange)="selectedTabValue($event)"
            [class]="elClass" [selectedIndex]="null">
            <mat-tab *ngFor="let item of productsCards; let i = index">
                <ng-template mat-tab-label *ngIf="labelName === item.index; else elseStatement">
                    <ul class="pt-5">
                        <div>
                            <img class="icon-Active" src="{{ item.secondIcon }}" />
                        </div>
                        <div>
                            <h6>{{ item.title | translate }}</h6>
                        </div>
                    </ul>
                </ng-template>
                <ng-template mat-tab-label #elseStatement>
                    <ul>
                        <div class="pb-4 pt-4">
                            <img class="icon" src="{{ item.firstiIcon }}" />
                        </div>
                        <div>
                            <h5 class="inActive">
                                {{ item.title | translate }}
                            </h5>
                        </div>
                    </ul>
                </ng-template>

                <div class="mt-4 contiant-div">
                    <div class="main-contiant-div">
                        <diV class="row" *ngIf="languageValue === 'en-US'">
                            <h1 class="title-border">
                                {{ item.title | translate }}
                                {{ "Products.Service" | translate }}
                            </h1>
                        </diV>
                        <diV class="row" *ngIf="languageValue === 'ar-SA'">
                            <h1 class="title-border">
                                {{ item.title | translate }}
                            </h1>
                        </diV>
                        <div class="container-vid">
                            <div class="row pt-3 cards-scrolling" *ngIf="
                                item.subItems &&
                                !(item.index == 1 && disableMotor)">
                                <div class="col-xl-4 col-md-6 col-12 p-2 card-continer" *ngFor="
                                    let serivce of item.subItems;
                                    let i = index
                                ">
                                    <a (click)="
                                        logInoPage(
                                            serivce.link,
                                            serivce.eventItem
                                        )
                                    " style="cursor: pointer">
                                        <div class="sub-card d-flex">
                                            <img class="logo-sub" src="{{ serivce.icon }}" />

                                            <p class="d-flex justify-content-center w-100 align-items-center pFont"
                                                style="padding-right: 1.5rem">
                                                {{ serivce.title | translate }}
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="item.index == 7">
                                <iframe  src="https://www.youtube.com/embed/QkACm2ti1yM?rel=0"
                                [ngClass]="{
                                    'absolute-div-en': this.translate.currentLang == 'en-US',
                                    'absolute-div': this.translate.currentLang != 'en-US'
                                }"
                                loading="lazy"
                                title="Domestic Labor How To Submit Claim" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerpolicy="strict-origin-when-cross-origin" 
                                allowfullscreen></iframe>

                            </div>
                        </div>
                        <div class="row pt-3 cards-scrolling justify-content-center align-items-center" *ngIf="
                                !item.subItems ||
                                (item.index == 1 && disableMotor)
                            ">
                            <div class="col-xl-4 col-md-6 col-12 p-2 card-continer">
                                <h4 class="cs">
                                    {{ "NAV.coming-soon" | translate }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>