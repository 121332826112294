<div class="motor-form-container">
    <div class="custom-stepper-content-form">
        <h3>{{ "motor.Vehicles Details" | translate }}</h3>
        <div class="btn-group-toggle-container">
            <div class="ppm-type">
                <div class="buttons-div">
                    <button
                        class="filter-button"
                        [ngClass]="{
                            active: activeButton === 0
                        }"
                        (click)="switchButton(0)"
                    >
                        {{ "motor.New Insurance" | translate }}
                    </button>
                    <button
                        class="filter-button"
                        [ngClass]="{
                            active: activeButton === 1
                        }"
                        (click)="switchButton(1)"
                    >
                        {{ "motor.Transfer Ownership" | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="chooseinsurance col-6">
                <h2>{{ "motor.ChooseType" | translate }}</h2>
                <div class="radiobuttoncontainer">
                    <div
                        [ngClass]="{
                            'form-check': lang === 'en-US',
                            'form-check-ar': lang === 'ar-SA'
                        }"
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked
                            [value]="1"
                            (change)="SequenceOrCustomerSwitch($event)"
                        />
                        <label
                            [ngClass]="{
                                'form-check-label': lang === 'en-US',
                                'form-check-label-ar': lang === 'ar-SA'
                            }"
                            for="flexRadioDefault1"
                        >
                            {{ "motor.Sequence Number" | translate }}
                        </label>
                    </div>
                    <div
                        [ngClass]="{
                            'form-check': lang === 'en-US',
                            'form-check-ar': lang === 'ar-SA'
                        }"
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            [value]="0"
                            (change)="SequenceOrCustomerSwitch($event)"
                        />
                        <label
                            [ngClass]="{
                                'form-check-label': lang === 'en-US',
                                'form-check-label-ar': lang === 'ar-SA'
                            }"
                            for="flexRadioDefault2"
                        >
                            {{ "motor.Customer Number" | translate }}
                        </label>
                    </div>
                </div>
                <div class="sequencenumber" *ngIf="Sequence === 1">
                    <span class="sequencetitle">
                        {{ "motor.Sequence Number" | translate }}
                    </span>
                    <div data-mdb-input-init>
                        <input
                            placeholder="{{ 'motor.EnterSequence' | translate }}
                        "
                            type="text"
                            id="form12"
                            class="form-control"
                        />
                    </div>
                </div>

                <div
                    class="sequencenumber"
                    *ngIf="Sequence === 0"
                    style="
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    "
                >
                    <div style="width: 60%">
                        <span class="sequencetitle">
                            {{ "motor.Customer Number" | translate }}
                        </span>
                        <div data-mdb-input-init>
                            <input
                                placeholder="{{
                                    'motor.CustomerSequence' | translate
                                }}
                        "
                                type="text"
                                id="form12"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <div style="width: 30%">
                        <span class="sequencetitle">
                            {{ "motor.ModelYear" | translate }}
                        </span>
                        <div >
                            <select
                                [(ngModel)]="selectedYear"
                                class="form-control-select"
                            >
                                <option
                                    *ngFor="let year of years"
                                    [value]="year"
                                >
                                    {{ year ? year : 'Choose'  }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="applypromo col-6">
                <h2>
                    <img
                        src="../../../../../../assets/img/motor-pages/promo 1.png"
                    />
                    {{ "motor.ApplyPromoCode" | translate }}
                </h2>
                <div class="promocode">
                    <div data-mdb-input-init>
                        <input
                            placeholder="{{ 'motor.EnterPromo' | translate }}
                        "
                            type="text"
                            id="form12"
                            class="form-control"
                        />
                        <button
                            class="promobutton"
                            [ngClass]="{
                                promobutton: lang === 'en-US',
                                'promobutton-ar': lang === 'ar-SA'
                            }"
                        >
                            {{ "SME.ADD" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
