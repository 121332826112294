<loading *ngIf="isSubmitted">{{ isSubmitted }}</loading>

<br />
<br />
<section class="h-100 gradient-form" style="background-color: #eee">
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-xl-10">
                <div class="card text-black" style="border-radius: 2rem">
                    <div class="row g-0">
                        <div class="col-lg-6" *ngIf="!otpStep">
                            <div class="card-body p-md-5 mx-md-4">
                                <div class="text-center">
                                    <img
                                        src="../../../../assets/img/logo/user-login.png"
                                        style="width: 185px"
                                        alt="logo"
                                    />
                                </div>
                                <form [formGroup]="LoginForm">
                                    <div class="form-outline mb-4">
                                        <label
                                            class="form-label"
                                            for="form2Example11"
                                            >{{
                                                "LogIn.IdNumber" | translate
                                            }}</label
                                        >
                                        <input
                                            autocomplete="false"
                                            type="text"
                                            id="form2Example11"
                                            formControlName="driverId"
                                            class="form-control"
                                            style="border: 1px solid black"
                                            maxlength="10"
                                            required
                                            oninput="javascript:  var element = document.getElementById(id);    var regex = /[^0-9\-]/gi;        element.value = element.value.replace(regex, '');"
                                            placeholder=""
                                        />
                                    </div>

                                    <div class="form-outline mb-4">
                                        <label
                                            class="form-label"
                                            for="form2Example22"
                                            >{{
                                                "LogIn.Mobile" | translate
                                            }}</label
                                        >
                                        <input
                                            autocomplete="false"
                                            type="text"
                                            id="form2Example22"
                                            class="form-control"
                                            style="border: 1px solid black"
                                            maxlength="10"
                                            formControlName="MobileNumber"
                                            required
                                            oninput="javascript:  var element = document.getElementById(id);    var regex = /[^0-9\-]/gi;        element.value = element.value.replace(regex, '');"
                                        />
                                    </div>

                                    <div class="text-center pt-1 mb-5 pb-1">
                                        <button
                                            class="btn btn-rounded btn-block fa-lg gradient-custom-2 mb-3"
                                            (click)="ValidateCredentials()"
                                            style="
                                                background-color: #226439;
                                                color: white;
                                                width: 10rem;
                                                font-size: 1.2rem;
                                            "
                                            type="submit"
                                        >
                                            {{ "GENERIC.SUBMIT" | translate }}
                                        </button>
                                    </div>
                                    <div
                                        *ngIf="HasClaims == null"
                                        style="color: #ff001f"
                                    >
                                        <i
                                            class="fa fa-exclamation-circle"
                                            aria-hidden="true"
                                        ></i>
                                        {{
                                            "LogIn.mobileErrMessage" | translate
                                        }}
                                    </div>
                                    <div
                                        *ngIf="
                                            HasClaims != '' && HasClaims != null
                                        "
                                        style="color: #ff001f"
                                    >
                                        <i
                                            class="fa fa-exclamation-circle"
                                            aria-hidden="true"
                                        ></i>
                                        {{ "LogIn.NoPolicy" | translate }}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6" *ngIf="otpStep">
                            <div class="card-body p-md-5 mx-md-4">
                                <div class="text-center">
                                    <img
                                        src="../../../../assets/img/logo/user-login.png"
                                        style="width: 185px"
                                        alt="logo"
                                    />
                                    <br />
                                    <span class="input-title">{{
                                        "MOTORCLAIMS.ADDOTP" | translate
                                    }}</span>
                                </div>
                                <form
                                    (ngSubmit)="ValidateOTP()"
                                    [formGroup]="form"
                                >
                                    <div
                                        class="card pt-4 d-flex flex-row justify-content-center text-center"
                                        style="direction: ltr"
                                    >
                                        <!-- <div> <span>A code has been sent to</span> <small>*******9897</small> </div> -->
                                        <div
                                            id="otp"
                                            class="inputs mt-2"
                                            style="margin-right: 2%; width: 15%"
                                            *ngFor="
                                                let input of formInput;
                                                index as i
                                            "
                                        >
                                            <input
                                                #formRow
                                                type="text"
                                                formControlName="{{ input }}"
                                                autofocus
                                                #swiper
                                                (keypress)="
                                                    ($event.charCode >= 48 &&
                                                        $event.charCode < 58)
                                                "
                                                class="m-2 text-center form-control rounded"
                                                maxlength="1"
                                                (keyup)="keyUpEvent($event, i)"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-2 pt-3">
                                <div
                                    class="content d-flex justify-content-center align-items-center"
                                    style="font-size: 15px"
                                >
                                    {{ display }}
                                </div>
                                <div
                                    class="content d-flex justify-content-center align-items-center"
                                    style="font-size: 15px"
                                >
                                    <span style="color: #156538">{{
                                        "MOTORCLAIMS.OTP_RESEND_MSG" | translate
                                    }}</span>
                                    <button
                                        (click)="ResendVerification()"
                                        [ngClass]="{ otpExpire: otpExpire }"
                                        [disabled]="!otpExpire"
                                        class="text-decoration-underline ms-3 otp"
                                    >
                                        {{
                                            "MOTORCLAIMS.OTP_RESEND_BTN"
                                                | translate
                                        }}
                                    </button>
                                </div>
                            </div>

                            <div class="mt-4 d-flex justify-content-center">
                                <span
                                    *ngIf="invalidOTP"
                                    style="
                                        font-weight: 700;
                                        color: red;
                                        font-size: 20px;
                                    "
                                >
                                    {{ "MOTORCLAIMS.INVALID_OTP" | translate }}
                                </span>
                            </div>

                            <div
                                class="pt-5 pb-5 d-flex justify-content-center"
                            >
                                <button
                                    (click)="ValidateOTP()"
                                    id="login-btn"
                                    [disabled]="!form.valid"
                                    [ngClass]="{ 'disabled-btn': !form.valid }"
                                    class="btn btn-rounded btn-block fa-lg gradient-custom-2 mb-3"
                                    style="
                                        background-color: #226439;
                                        color: white;
                                        width: 10rem;
                                        font-size: 1.2rem;
                                    "
                                >
                                    <span *ngIf="!isLoading">{{
                                        "GENERIC.SUBMIT" | translate
                                    }}</span>
                                    <span *ngIf="isLoading">
                                        <div
                                            class="spinner-border text-warning"
                                        ></div>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div
                            class="col-lg-6 d-flex align-items-center gradient-custom-2"
                            style="
                                background-color: #226439;
                                border-radius: 2rem;
                            "
                        >
                            <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                                <h4 class="mb-4">
                                    {{ "LogIn.DearClient" | translate }}
                                </h4>

                                <ul class="small mb-0">
                                    <li>
                                        {{ "LogIn.LineOne" | translate }}
                                    </li>
                                    <li>
                                        {{ "LogIn.LineTwo" | translate }}
                                    </li>
                                    <li>
                                        {{ "LogIn.LineThree" | translate }}
                                    </li>
                                    <li>
                                        {{ "LogIn.LineFour" | translate }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
