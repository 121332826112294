<loading *ngIf="isSubmitted || claimsLoader">{{ isSubmitted }}</loading>

<br />
<section style="background-color: #eee">
    <div class="container pt-5" *ngIf="ProductCode == 1">
        <div class="row">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <nav
                            aria-label="breadcrumb"
                            class="bg-light rounded-3 p-3 mb-4"
                        >
                            <ol class="breadcrumb mb-0">
                                <li class="breadcrumb-item">
                                    <a href="#" style="color: #b58e3e">
                                        <strong>
                                            {{ "LogIn.Home" | translate }}
                                        </strong>
                                    </a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="#" style="color: #b58e3e">
                                        <strong>
                                            {{ "LogIn.Login" | translate }}
                                        </strong>
                                    </a>
                                </li>
                                <li
                                    class="breadcrumb-item active"
                                    aria-current="page"
                                    style="color: #226439"
                                >
                                    <strong>
                                        {{ "LogIn.PolicyDetails" | translate }}
                                    </strong>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-12">
                        <div class="card mb-4">
                            <div class="card-body text-center">
                                <img
                                    src="../../../../assets/img/logo/domestic.png"
                                    alt="avatar"
                                    class="rounded-circle img-fluid"
                                    style="width: 50%"
                                />
                                <hr />
                                <div *ngIf="!isCancel" class="d-flex justify-content-center mb-2">
                                    <div class="col-md-6">
                                        <button
                                            type="button"
                                            class="btn btn-rounded"
                                            style="
                                                background-color: darkred;
                                                color: white;
                                            "
                                            (click)="
                                                openDialog(
                                                    '500ms',
                                                    '200ms',
                                                    policyInfo
                                                )
                                            "
                                            *ngIf="showCancelButton"
                                        >
                                            {{
                                                "LogIn.cancelPolicy" | translate
                                            }}
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <button
                                            type="button"
                                            class="btn btn-rounded"
                                            style="
                                                background-color: #226439;
                                                color: white;
                                            "
                                            (click)="
                                                openClaimSubmissionDialog(
                                                    policyInfo
                                                )
                                            "
                                        >
                                            {{ "SERVICES.CLAIMS" | translate }}
                                        </button>
                                    </div>
                                </div>

                                <div
                                    class="d-flex justify-content-center mb-2"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col">
                        <div
                            class="toast show fade toast-info"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div
                                class="toast-header toast-info"
                                style="background-color: #226439; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ "LogIn.PolicyNumber" | translate }}
                                </strong>
                            </div>
                            <div class="toast-body text-center">
                                <strong>{{ policyInfo.policy }}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div
                            class="toast show fade toast-info"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div
                                class="toast-header toast-info"
                                style="background-color: #d4ab36; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ "LogIn.PolicyStatus" | translate }}
                                </strong>
                            </div>
                            <div class="toast-body text-center">
                                <strong>{{ policyInfo.status }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col">
                        <div
                            class="toast show fade toast-info"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div
                                class="toast-header toast-info"
                                style="background-color: #d4ab36; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ "LogIn.PolicyEffective" | translate }}
                                </strong>
                            </div>
                            <div class="toast-body text-center">
                                <strong>{{ policyInfo.effectivedate }}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div
                            class="toast show fade toast-info"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div
                                class="toast-header toast-info"
                                style="background-color: #226439; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ "LogIn.PolicyExpiry" | translate }}
                                </strong>
                                <small></small>
                            </div>
                            <div class="toast-body text-center">
                                <strong>{{ policyInfo.expirydate }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col">
                        <div
                            class="toast show fade toast-info"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div
                                class="toast-header toast-info"
                                style="background-color: #226439; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ "LogIn.NationalityWorker" | translate }}
                                </strong>
                            </div>
                            <div class="toast-body text-center">
                                <strong> {{ policyInfo.nationality }}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div
                            class="toast show fade toast-info"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div
                                class="toast-header toast-info"
                                style="background-color: #d4ab36; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ "LogIn.PolicyHolder" | translate }}
                                </strong>
                            </div>
                            <div class="toast-body text-center">
                                <strong>{{ policyInfo.policyholderAr }}</strong>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
                <br />
                <div class="row">
                    <div class="col" *ngIf="showCancelButton == false">
                        <div
                            class="toast show fade toast-info"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div
                                class="toast-header toast-info"
                                style="background-color: darkred; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ "LogIn.CancellationReason" | translate }}
                                </strong>
                            </div>
                            <div
                                class="toast-body text-center"
                                *ngIf="policyInfo.status == 'Active'"
                            >
                                <strong>
                                    {{ "LogIn.Registered" | translate }}
                                </strong>
                                <div
                                    class="toast-body text-center"
                                    *ngIf="policyInfo.status != 'Active'"
                                >
                                    <strong>
                                        {{ "LogIn.Accepted" | translate }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col" *ngIf="showCancelButton == false">
                        <div
                            class="toast show fade toast-info"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div
                                class="toast-header toast-info"
                                style="background-color: darkred; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ "LogIn.CancellationBy" | translate }}
                                </strong>
                            </div>
                            <div
                                class="toast-body text-center"
                                *ngIf="policyInfo.status == 'Active'"
                            >
                                <strong> {{ cancellationDto.proposer }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    class="row"
                    *ngIf="
                        listClaims != null &&
                        listClaims != '' &&
                        listClaims.length > 0
                    "
                >
                    <div class="col" *ngFor="let item of listClaims">
                        <div
                            class="toast show fade toast-info"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div
                                class="toast-header toast-info"
                                *ngIf="item.claimstatus == 'Registered'"
                                style="background-color: darkred; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ item.claimNo }}
                                </strong>
                            </div>
                            <div
                                class="toast-header toast-info"
                                *ngIf="item.claimstatus == 'Closed'"
                                style="background-color: darkgray; color: white"
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ item.claimNo }}
                                </strong>
                            </div>
                            <div
                                class="toast-header toast-info"
                                *ngIf="item.claimstatus == 'Open'"
                                style="
                                    background-color: cornflowerblue;
                                    color: white;
                                "
                            >
                                <i class="fa fa-info-circle fa-lg me-2"></i>
                                <strong class="me-auto">
                                    {{ item.claimNo }}
                                </strong>
                            </div>
                            <div class="toast-body text-center">
                                <strong>
                                    {{ item.claimstatus }}
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br />
    </div>
    <div class="container pb-5" *ngIf="claims.length > 0">
        <div class="w-25 py-3">
            <input
                type="text"
                placeholder="{{ 'LogIn.Search' | translate }}"
                class="form-control search-table-text"
                matInput
                (keyup)="applyFilter($event.target.value)"
            />
        </div>

        <table
            mat-table
            [dataSource]="dataSource"
            class="w-100 claims-table-container"
        >
            <ng-container matColumnDef="claimno">
                <th mat-header-cell *matHeaderCellDef style="padding: 0">
                    {{ "LogIn.Claim-No" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.claimno }}
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="reporteddate">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "LogIn.Reported-Date" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.reporteddate | date : "dd/MM/yyyy" }}
                </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="claimstatus">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "LogIn.Claim-Status" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.claimstatus }}
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="accidentdate">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "LogIn.Accident-Date" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.accidentdate | date : "dd/MM/yyyy" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="insuredname">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "LogIn.Insured-Name" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.insuredname }}
                </td>
            </ng-container>
            <ng-container matColumnDef="settlementdate">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "LogIn.Settlement-Date" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.settlementdate | date : "dd/MM/yyyy" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="paymentamount">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "LogIn.Payment-Amount" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.paymentamount }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "LogIn.Actions" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <button style="background: inherit;" (click)="resubmitMissingFiles(element)">    
                        <i class="fa fa-upload" aria-hidden="true" style="color: #d4ab36"></i>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr
                mat-row
                *matRowDef="let element; columns: columnsToDisplay"
                class="example-element-row"
                [class.example-expanded-row]="element.expanded"
            ></tr>
        </table>
        <mat-paginator
            [pageSizeOptions]="[5]"
            [length]="claims.length"
            [pageSize]="pageSize"
            showFirstLastButtons
        ></mat-paginator>
    </div>
</section>
