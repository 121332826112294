import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/core/AppConfigService';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    languageValue = localStorage.getItem('language');
    websiteURL: any;
    playStore: string;
    appleStore: string;
    privacy: string;
    terms: string;
    privacyAR: string;
    termsAR: string;
    hover:boolean;
    hoverT:boolean;
    constructor(private environment: AppConfigService) {
        this.playStore = environment?.config?.playStore;
        this.appleStore = environment?.config?.appleStore;
        this.languageValue = localStorage.getItem('language');
        this.websiteURL = environment?.config?.webSiteURL;
        this.privacy = environment?.config?.privacyEN;
        this.terms = environment?.config?.termsEN;
        this.privacyAR = environment?.config?.privacyAR;
        this.termsAR = environment?.config?.termsAR;
        this.hover=false;
        this.hoverT=false;
    }
    over(){
        this.hover=true;
      }
      out(){
        this.hover=false;
      }
       
      overT(){
        this.hoverT=true;
      
      }
      outT(){
        this.hoverT=false;
      }
    ngOnInit(): void {
        this.languageValue = localStorage.getItem('language');
    }
    ngDoCheck() {
        this.languageValue = localStorage.getItem('language');
    }
    navToStore(value) {
        if (value === 'google') {
            window.open(this.playStore);
        } else {
            window.open(this.appleStore);
        }
    }
    openPDF(value) {
        if (value === 'privacy') {
            if (this.languageValue === 'en-US') {
                window.open(this.privacy, '_blank');
            } else {
                window.open(this.privacyAR, '_blank');
            }
        } else {
            if (this.languageValue === 'en-US') {
                window.open(this.terms, '_blank');
            } else {
                window.open(this.termsAR, '_blank');
            }
        }
    }

    openEsericeportal()
    {
        window.open(this.websiteURL+'/compliant'  ,'_blank' )
    }  
}
