
<div [ngClass]="{ 'medical-sme-container': this.translate.currentLang === 'en-US', 'medical-sme-container-ar': this.translate.currentLang === 'ar-SA' }"></div>
<div class="container">
    <div class="row mt-4 mb-4">
        <h2 class="sme-welcome-title">
            {{ "SME.WELCOME" | translate }}
            <span class="sme-title-name" *ngIf="companyName != null && companyName != undefined">
                {{ companyName }}</span>
                <span class="sme-title-name" *ngIf="companyName == null || companyName == undefined">
                    {{ "SME.MUTHEDA" | translate }}</span> 
        </h2>
    </div>
    <div class="mb-3">
        <mat-tab-group [selectedIndex]="tabIndex" (selectedIndexChange)="tabChange($event)">
            <mat-tab [disabled]=" fastCalcDisabled"  label="{{ 'SME.FASTCALCHEADER' | translate }}">
                <p class="sme-note">
                    {{ "SME.MUSTFILL" | translate }}
                </p>
                <app-sme-table [CompanyDetails]="CompanyDetails"
                    (onQuotationSubmit)="updateTabIndex($event)"></app-sme-table>
            </mat-tab>
            <mat-tab [disabled]="CreateQuotationDisabled" label="{{ 'SME.CREATEQUOTATION' | translate }}">
                <div *ngIf="tabIndex == 1">
                    <app-sme-create-quotation [CompanyDetails]="CompanyDetails" [QuotationNumber]="quotationNumber"
                        (onUploadUsersSubmit)="nextTab($event)"></app-sme-create-quotation>
                </div>
            </mat-tab>
            <mat-tab [disabled]="quotationDetailsDisabled" label="{{ 'SME.QUOTATIONDETAILS' | translate }}">
                <div *ngIf="tabIndex == 2">
                    <app-quotation-details (onPaymentSuccess)="goToCreatePolicytab($event)"
                    (onQuotationSubmit)="updateTabIndex($event)"
                        [CompanyDetails]="CompanyDetails" [PremiumData]="premiumData"
                        [QuotationNumber]="quotationNumber"></app-quotation-details>
                </div>
            </mat-tab>
            <mat-tab [disabled]="CreatePolicyDisabled" label="{{ 'SME.CREATEPOLICY' | translate }}">
                <div *ngIf="tabIndex == 3">
                    <app-sme-issued-policy></app-sme-issued-policy>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>