import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-embassy-letter',
    templateUrl: './embassy-letter.component.html',
    styleUrls: ['./embassy-letter.component.scss'],
})
export class EmbassyLetterComponent implements OnInit {
    today: Date;
    picker:any
    languageValue = localStorage.getItem('language');
    allcountries: any;
    isLoading = false;
    embassyFrom: FormGroup;
    finalListOfCountr =[];
    selectedMember: any;
    memID = localStorage.getItem('memberID');
    memberDetails: any;
    memberId: number;

    constructor(
        public translate: TranslateService,
        public medicalClaimsService: MedicalClaimsService,
        public fb: FormBuilder,
        private _LocalService: LocalService

    ) {
        this.memID = this._LocalService.getJsonValue('memberID');

        this.languageValue = localStorage.getItem('language');
        this.allcountries=[];        ;
        this.finalListOfCountr=[];
        this.selectedMember = {
            id: '',
            policyNumber: '',
            policyHolder: '',
            policyExpiryData: '',
            PolicyStartData: '',
            memberCode: '',
            memberEName: '',
            gender: '',
            birthDate: '',
            className: '',
            relation: '',
        };
       
    }

    get newEmbassyFromControl() {
        return this.embassyFrom.controls;
    }
    async ngOnInit(): Promise<void> {
        this.embassyFrom = this.fb.group({
            passport: [null, Validators.required],
            memberCode : [null, Validators.required]
        });
        await this.GetMemeberDetails();
        this.memID=localStorage.getItem('memberID');

    }
   

    GetMemeberDetails()
    {
        
        this.memberDetails = this._LocalService.getJsonValue('memberDetails');
        if( this.memberDetails !== null &&  this.memberDetails !== undefined )
        {
        if (this.memberDetails?.length > 0) {
            this.selectedMember = this.memberDetails[0];
        }
        }
    else{
        
        let queryString = 'MemberId=' + this.memID  ;
        this.medicalClaimsService
            .MemberDetails(queryString)
            .subscribe((res) => {
                window.location.reload();
                this.memberDetails = res;
                this._LocalService.setJsonValue('memberDetails', res?.data);
                if (res?.data.length > 0) {
                    this.selectedMember = res?.data[0];
                }
            });
        }
    
    }
    submit ()
    {
        let queryString;
        this.isLoading=true;
        if( this.embassyFrom.controls['passport'].value && this.embassyFrom.controls['memberCode'].value)
        {
         queryString = `memberCode=${this.embassyFrom.controls['memberCode'].value?.memberCode}&passportBumber=${this.embassyFrom.controls['passport'].value}&policyNumber=${this.selectedMember.policyNumber}`

         //queryString = `memberCode=002307388807001&passportBumber=A112233&policyNumber=103002619`
         this.medicalClaimsService
            .GetEmbassyLetterDetails(queryString)
            .subscribe((res) => {
                this.isLoading=false
                if (res?.embassyLink )
                {
                    window.open(res?.embassyLink  ,'_blank' )
                }
                else
                {
                    let dir: string = '';
                   let msg = ''
                if (this.translate.currentLang == 'en-US') {
                    dir = 'ltr';
                    msg = 'An Error occurred'
                } else {
                    dir = 'rtl';
                    msg = 'حدث خطأ ما'

                }
                    Swal.fire({
                        title:
                            this.translate.currentLang == 'en-US'
                                ? 'Error!'
                                : '!خطأ',
                        iconHtml:
                            '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                        html:
                            "<div style='color:#FF001F; direction:" +
                            dir +
                            "'>" +
                            msg +
                            '</div>',
                        confirmButtonText:
                            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                        customClass: {
                            icon: 'submit-claim-failed-icon-img',
                            title: 'submit-claim-failed-swal-title',
                            confirmButton: 'submit-claim-failed-confirm-btn',
                        },
                    });
                
            }},
            (err)=>
            {
              this.isLoading=false
              let dir: string = '';
              let msg = ''
           if (this.translate.currentLang == 'en-US') {
               dir = 'ltr';
               msg = 'An Error occurred'
           } else {
               dir = 'rtl';
               msg = 'حدث خطأ ما'

           }
               Swal.fire({
                   title:
                       this.translate.currentLang == 'en-US'
                           ? 'Error!'
                           : '!خطأ',
                   iconHtml:
                       '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                   html:
                       "<div style='color:#FF001F; direction:" +
                       dir +
                       "'>" +
                       msg +
                       '</div>',
                   confirmButtonText:
                       this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                   customClass: {
                       icon: 'submit-claim-failed-icon-img',
                       title: 'submit-claim-failed-swal-title',
                       confirmButton: 'submit-claim-failed-confirm-btn',
                   },
               });
            }
            );
        }

       
    }
   
}
