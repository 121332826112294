<div>
    <div class="page-title-area bg-1">
        <div class="container col-md-10">
            <div class="page-title-content">
                <h2>{{ "MEDICAL.Embassy-Letter" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/"
                            >{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li>
                        <a routerLink="/medical-profile">
                            {{ "NAV.MEDICAL" | translate }}

                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li class="active" style="font-weight: 500">
                        {{ "MEDICAL.Embassy-Letter" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container pt-4 pb-4 d-flex justify-content-center">
    <div class="Ecard d-flex justify-content-center align-items-center">
        <div
            [ngClass]="{
                'SEcard-en': this.translate.currentLang == 'en-US',
                'SEcard-ar': this.translate.currentLang != 'en-US'
            }"
        >
            <div
                class="p-4 pt-5"
                [ngClass]="{
                    'inner-card-en': this.translate.currentLang == 'en-US',
                    'inner-card-ar': this.translate.currentLang != 'en-US'
                }"
            >
                <div class="d-flex pb-2">
                    <a href="/medical-profile">
                        <img
                            src="assets/img/medical-service/icons8-back-arrow-90.png"
                            width="30"
                            alt=""
                            [ngClass]="{
                                'arrow-img-ar':
                                    this.translate.currentLang != 'en-US'
                            }"
                        />
                    </a>
                    <h4
                        [ngClass]="{
                            'header-en': this.translate.currentLang == 'en-US',
                            'header-ar': this.translate.currentLang != 'en-US'
                        }"
                    >
                        {{ "MEDICAL.New-Embassy-Letter" | translate }}
                    </h4>
                </div>
                <form [formGroup]="embassyFrom">
                    <div class="row col-lg-12 col-md-12 col-sm-12 pt-5">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <h5 class="content">
                                {{ "MEDICAL.Member" | translate }}
                            </h5>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-8">
                            <ng-select
                                [ngClass]="{
                                    'red-border-class':
                                        newEmbassyFromControl.memberCode
                                            .errors &&
                                        newEmbassyFromControl.memberCode.touched
                                }"
                                placeholder="{{
                                    'MEDICAL.SelectMeM' | translate
                                }}"
                                formControlName="memberCode"
                                [searchable]="true"
                                class="input-form-design"
                                [items]="memberDetails"
                                bindLabel="memberEName"
                            >
                            </ng-select>
                            <div
                                *ngIf="
                                    newEmbassyFromControl.memberCode.errors &&
                                    newEmbassyFromControl.memberCode.touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newEmbassyFromControl.memberCode.errors
                                            .required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-lg-12 col-md-12 col-sm-12 pt-5">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <h5 class="content">
                                {{ "MEDICAL.passport" | translate }}
                            </h5>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-8">
                            <input
                                id="passportId"
                                name="passport"
                                formControlName="passport"
                                [ngClass]="{
                                    'red-border-class':
                                        newEmbassyFromControl.passport.errors &&
                                        newEmbassyFromControl.passport.touched
                                }"
                                class="form-control input-form-design"
                                placeholder="{{
                                    'MEDICAL.passport' | translate
                                }}"
                                type="text"
                            />
                            <div
                                *ngIf="
                                    newEmbassyFromControl.passport.errors &&
                                    newEmbassyFromControl.passport.touched
                                "
                            >
                                <div
                                    *ngIf="
                                        newEmbassyFromControl.passport.errors
                                            .required
                                    "
                                    style="color: #ff001f"
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-center p-5">
                    <button
                        class="default-btn"
                        [disabled]="
                            !newEmbassyFromControl.passport.value ||
                            !newEmbassyFromControl.memberCode.value
                        "
                        [ngClass]="{
                            'disabled-button':
                                !newEmbassyFromControl.passport.value ||
                                !newEmbassyFromControl.memberCode.value
                        }"
                        (click)="submit()"
                    >
                        {{ "MEDICAL.Generate-Letter" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
