<br/>
<section style="background-color: #eee;">
    <div class="container py-5" *ngIf="ProductCode == 1">
      <div class="row">
        <div class="col-md-4">
          <nav aria-label="breadcrumb" class="bg-light rounded-3 p-3 mb-4">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item"><a href="#" style="color: #b58e3e;"><strong>Home </strong></a></li>
              <li class="breadcrumb-item"><a href="#" style="color: #b58e3e;"><strong>Login </strong></a></li>
              <li class="breadcrumb-item active" aria-current="page" style="color: #226439;"><strong>Policy Cancellation </strong></li>
            </ol>
          </nav>

        </div>
        <div class="col-md-4">
       <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
         <div class="toast-header toast-info" style="background-color: #226439;color: white;">
        <i class="fa fa-info-circle fa-lg me-2"></i>
        <strong class="me-auto">Client Id No</strong>
        <small>11 mins ago</small>
      </div>
      <div class="toast-body text-center"><strong>2500003844</strong></div>
    </div>
        </div>
        <div class="col-md-4">
            <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
              <div class="toast-header toast-info" style="background-color: #D4AB36;color: white;">
             <i class="fa fa-info-circle fa-lg me-2"></i>
             <strong class="me-auto">Mobile No</strong>
             <small>11 mins ago</small>
           </div>
           <div class="toast-body text-center"><strong>0541573636</strong></div>
         </div>
        </div>
    </div>
    <br/>
    <div class="row">
        <div class="col-md-4">
                    <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
                      <div class="toast-header toast-info" style="background-color: #33655a;color: white;">
                     <i class="fa fa-info-circle fa-lg me-2"></i>
                     <strong class="me-auto">Policy No</strong>
                     <small>11 mins ago</small>
                   </div>
                   <div class="toast-body text-center"><strong>9111512142</strong></div>
                 </div>
        </div>
        <div class="col-md-4">
                        <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
                          <div class="toast-header toast-info" style="background-color: #47657e;color: white;">
                         <i class="fa fa-info-circle fa-lg me-2"></i>
                         <strong class="me-auto">Effective Date</strong>
                         <small>11 mins ago</small>
                       </div>
                       <div class="toast-body text-center"><strong>01, January , 2024</strong></div>
                     </div>
        </div>
        <div class="col-md-4">

            <div class="progress rounded" style="height: 20px; background-color: white;cursor: pointer;">
              <div class="progress-bar"  role="progressbar" style="width: 89%;background-color: darkred;color:white;" aria-valuenow="89" matTooltip="35 Days Left in your policy"
                aria-valuemin="0" aria-valuemax="100"><strong>Policy Expired Days 345</strong> </div>
            </div>
        </div>
    </div>
    <br/>


      <div class="row">
        <div class="col-lg-4">
          <div class="card mb-4">
            <div class="card-body text-center">
              <img src="../../../../assets/img/logo/femalelogo.jpg" alt="avatar"
                class="rounded-circle img-fluid" style="width: 150px;">
              <p class="text-muted mb-1" style="color: #226439;"><strong style="color: #226439;">Sindey Charlies</strong></p>
              <p class="text-muted mb-4" style="color: #D4AB36;">United Kingdom , Married</p>
              <div class="d-flex justify-content-center mb-2">
                <button type="button" class="btn btn-rounded" style="background-color: darkred; color: white;">Cancel Policy</button>
              </div>
            </div>
          </div>
                 </div>
        <div class="col-lg-8">
          <div class="card mb-4">
            <div class="card-body">
                <div class="container-fluid py-5">
            <div class="row">
                <div class="col-md-4">
                    <div matBadge="Policy" matBadgeOverlap="false" class="demo-section"></div>
                </div>
            </div>
                    <div class="row">
                      <div class="col-lg-12">

                        <div class="horizontal-timeline">

                          <ul class="list-inline items">
                            <li class="list-inline-item items-list">
                              <div class="px-4">
                                <h5 class="pt-2" style="color: #226439;">Medical Mail Practice</h5>
                                <ul>
                                    <li >
                                       <p style="color: #226439;">9111512142</p>
                                    </li>
                                    <li>
                                        <p style="color: #226439;">Policy Period 2 Years</p>
                                    </li>
                                </ul>

                              </div>
                            </li>
                            <li class="list-inline-item items-list">
                              <div class="px-4">
                                <div class="event-date badge" style="background-color: #33655a">Benefecieries</div>
                                <h5 class="pt-2" style="color: #226439;">Surgent Doctor</h5>
                                <ul>
                                    <li>
                                        <p style="color: #226439;">Age: 35 Years</p>
                                    </li>
                                    <li>
                                        <p style="color: #226439;">Female</p>
                                    </li>
                                </ul>


                              </div>
                            </li>
                            <li class="list-inline-item items-list">
                              <div class="px-4">
                                <div class="event-date badge"  style="background-color: #D4AB36">Cover</div>
                                <h5 class="pt-2" style="color: #226439;">Main Cover</h5>
                                <ul>
                                    <li>
                                        <p style="color: #226439;">Extension Cover</p>
                                    </li>
                                    <li>
                                        <p style="color: #226439;">Amount: 850 SR</p>
                                    </li>
                                </ul>
                            </div>
                            </li>
                            <li class="list-inline-item items-list">
                              <div class="px-4">
                                <div class="event-date badge " style="background-color: #226439">Claims</div>
                                <h5 class="pt-2" style="color: #226439;">Limits</h5>
                                <ul>
                                    <li>
                                        <p style="color: #226439;">Agg Limit: 500K</p>
                                    </li>
                                    <li>
                                        <p style="color: #226439;">Annual Limit : 1M</p>
                                    </li>
                                </ul>
                              </div>
                            </li>
                          </ul>

                        </div>

                      </div>
                    </div>

                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
