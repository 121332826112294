import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../AppConfigService';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class MmpService {
    baseUrl: string;
    lang: string;

    mmpBaseUrl: string;
    mmpQuotationUrl: string;
    CitizenInfoURL: string;
    ProfessionCategoriesBaseURL: string;
    ProfessionCategoriesURL: string;
    ProfessionSubCategoriesURL: string;

    mmpPolicyBaseUrl: string;
    mmpGetPolicyPeriodUrl: string;

    prevPolicyData: string;

    mmpInsuranceCompanyBaseUrl: string;
    mmpInsuranceCompanyUrl: string;

    mmpQuestionsBaseUrl: string;
    mmpQuestionsUrl: string;
    DocsCodeURL: string;
    CategoryLimitsURL: string;
    GetQuotationURL: string;
    CancelQuotationURL: string;
    GetActiveQuotationURL: string;
    GetActivePolicyURL: string;
    GetBillingQuotationURL: string;

    GetDocumentsURL: string;

    GetPaymentDataURL: string;
    GetPaymentStatusURL: string;

    generateOTPURL: string;
    verifyOtpURL: string;

    policyConfigURL: string;
    paymentStatusURL: string;
    qutationLimitURL: string;


    getMMPClaimsURL: string;
    getCashClaimMandatoryFilesURL: string;
    submitNewMMPCashClaimURL: string;
    getCauseOfLossesURL:string; 
    
    constructor(
        private http: HttpClient,
        private environment: AppConfigService,
        public translate: TranslateService
    ) {
        this.lang = this.translate.currentLang == 'en-US' ? 'en' : 'ar';

        this.baseUrl = environment?.config?.servicesBaseUrl + 'MMPDocCode/';
        this.DocsCodeURL = this.baseUrl + 'GetDocCodes?lang=';
        this.CategoryLimitsURL =
            environment?.config?.servicesBaseUrl +
            'MMPLookup/' +
            'GetCategoryLimits?';

        this.mmpBaseUrl = environment?.config?.servicesBaseUrl;
        this.mmpQuotationUrl = this.mmpBaseUrl + 'MMPQuotation/';
        this.CitizenInfoURL = this.mmpQuotationUrl + 'GetCitizenInfo?';
        this.ProfessionCategoriesBaseURL =
            this.mmpBaseUrl + 'MMPProfessionsCategory/';
        this.ProfessionCategoriesURL =
            this.ProfessionCategoriesBaseURL + 'GetProfessionsCategory?lang=';
        this.ProfessionSubCategoriesURL =
            this.mmpBaseUrl + 'MMPProfessions/GetProfessions?profCat=';

        this.mmpPolicyBaseUrl = this.mmpBaseUrl + 'MMPPolicyPeriod/';
        this.mmpGetPolicyPeriodUrl =
            this.mmpPolicyBaseUrl + 'GetPolicyPeriod?lang=';

        this.prevPolicyData =
            this.mmpQuotationUrl + 'GetPrevPolicyData?iqamaNumber=';

        this.mmpInsuranceCompanyBaseUrl =
            environment?.config?.servicesBaseUrl + 'MMPInsuranceCompanyLookup/';
        this.mmpInsuranceCompanyUrl =
            this.mmpInsuranceCompanyBaseUrl + 'GetInsuranceCompaines?lang=';

        this.mmpQuestionsBaseUrl =
            environment?.config?.servicesBaseUrl + 'MMPQuestion/';
        this.mmpQuestionsUrl = this.mmpQuestionsBaseUrl + 'GetQuestions?lang=';
        this.GetQuotationURL = this.mmpQuotationUrl + 'GetQuotationV1?lang=';
        this.CancelQuotationURL =
            this.mmpQuotationUrl + 'CancelQuotation?trxId=';
        this.GetActiveQuotationURL =
            this.mmpQuotationUrl + 'GetActiveQuotation';
        this.GetActivePolicyURL = this.mmpQuotationUrl + 'GetActivePolicy';

        this.GetBillingQuotationURL = this.mmpQuotationUrl + 'GetBillingData?';

        this.GetDocumentsURL = this.baseUrl + 'GetDocuments?lang=';
        this.GetPaymentDataURL =
            environment?.config?.servicesBaseUrl +
            'MMPTrxPaymentType/GetPaymentData';
        this.GetPaymentStatusURL =
            environment?.config?.servicesBaseUrl +
            'MMPTrxPaymentType/GetPaymentStatus?';
        this.generateOTPURL =
            this.mmpBaseUrl + 'MMPAuthentication/GenerateOtp?';
        this.verifyOtpURL = this.mmpBaseUrl + 'MMPAuthentication/VerifyOtp?';

        this.policyConfigURL = this.mmpBaseUrl + 'MMPPolicyPeriod/PolicyConfig';
        this.paymentStatusURL =
            this.mmpBaseUrl + 'MMPTrxPaymentType/TrxPaymentStatus';
        this.qutationLimitURL = this.mmpQuotationUrl + 'GetQuotationLimit';

        this.getMMPClaimsURL = this.mmpBaseUrl + 'MMPClaim/claims';
        this.getCashClaimMandatoryFilesURL = this.mmpBaseUrl + 'MMPClaim/MandatoryFiles';
        this.submitNewMMPCashClaimURL = this.mmpBaseUrl + 'MMPClaim/Claim'
        this.getCauseOfLossesURL = this.mmpBaseUrl + 'MMPLookup/GetCauseOfLoss'
    }

    GetCitizenInfoURLService(queryString): Observable<any> {
        return this.http.get<any>(
            this.CitizenInfoURL + queryString + '&lang=' + this.lang
        );
    }

    GetBillingInfoURLService(queryString): Observable<any> {
        return this.http.get<any>(
            this.GetBillingQuotationURL + queryString + '&lang=' + this.lang
        );
    }

    GetProfessionCategoriesService(): Observable<any> {
        return this.http.get<any>(this.ProfessionCategoriesURL + this.lang);
    }

    GetProfessionSubCategoriesService(queryString): Observable<any> {
        return this.http.get<any>(
            this.ProfessionSubCategoriesURL + queryString + '&lang=' + this.lang
        );
    }

    GetPolicyPeriodService(): Observable<any> {
        return this.http.get<any>(this.mmpGetPolicyPeriodUrl + this.lang);
    }

    GetPrevPolicyDataService(queryString): Observable<any> {
        return this.http.get<any>(
            this.prevPolicyData + queryString + '&lang=' + this.lang
        );
    }

    GetInsuranceCompaniesService(): Observable<any> {
        return this.http.get<any>(this.mmpInsuranceCompanyUrl + this.lang);
    }

    GetQuestionsService(): Observable<any> {
        return this.http.get<any>(this.mmpQuestionsUrl + this.lang);
    }

    GetDocsService(): Observable<any> {
        return this.http.get<any>(this.DocsCodeURL + this.lang);
    }
    CategoryLimits(queryString): Observable<any> {
        return this.http.get<any>(this.CategoryLimitsURL + queryString);
    }

    SubmitNewQutation(model): Observable<any> {
        return this.http.post<any>(this.GetQuotationURL + this.lang, model);
    }
    CancelQutation(data): Observable<any> {
        return this.http.get<any>(this.CancelQuotationURL + data);
    }
    GetActiveQuotation(queryString): Observable<any> {
        return this.http.get<any>(
            this.GetActiveQuotationURL + queryString + '&lang=' + this.lang
        );
    }
    GetActivePolicy(queryString): Observable<any> {
        return this.http.get<any>(
            this.GetActivePolicyURL + queryString + '&lang=' + this.lang
        );
    }
    GetGetDocumentsService(): Observable<any> {
        return this.http.get<any>(this.GetDocumentsURL + this.lang);
    }
    GetPaymentData(model): Observable<any> {
        return this.http.post<any>(this.GetPaymentDataURL, model);
    }
    GetPaymentStatus(queryString): Observable<any> {
        return this.http.get<any>(this.GetPaymentStatusURL + queryString);
    }

    GetGenarateOTP(queryString): Observable<any> {
        return this.http.get<any>(
            this.generateOTPURL + queryString + '&lang=' + this.lang
        );
    }
    GetVerifyOTP(queryString): Observable<any> {
        return this.http.get<any>(
            this.verifyOtpURL + queryString + '&lang=' + this.lang
        );
    }

    GetpolicyConfig(): Observable<any> {
        return this.http.get<any>(this.policyConfigURL);
    }

    GetpaymentStatus(data): Observable<any> {
        return this.http.get<any>(this.paymentStatusURL + '?trxId=' + data);
    }
    GetQuotationLimit(queryString): Observable<any> {
        return this.http.get<any>(
            this.qutationLimitURL + queryString + '&lang=' + this.lang
        );
    }

    GetMMPClaims(queryString): Observable<any> {
        return this.http.get<any>(
            this.getMMPClaimsURL + queryString + '&lang=' + this.lang
        );
    }

    GetCashClaimMandatoryFiles() : Observable<any> {
        return this.http.get<any>(
            this.getCashClaimMandatoryFilesURL + '?lang=' + this.lang
        );
    }

    GetMMPCauseOfLosses() : Observable<any> {
        return this.http.get<any>(
            this.getCauseOfLossesURL + '?lang=' + this.lang
        );
    }

    SubmitNewMMPCashClaim(model) : Observable<any> {
        return this.http.post<any>(this.submitNewMMPCashClaimURL, model);
    }
}
