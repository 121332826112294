<div class="py-3 custom-stepper-content2">
    <div class="Policytypecontainer">
        <div class="comprehensive" [class.expanded]="ComprehensiveShow">
            <div class="compimg"></div>
            <div
                [ngClass]="{
                    'compdetails-en': lang === 'en-US',
                    'compdetails-ar': lang === 'ar-SA'
                }"
            >
                <h3>{{ "motor.Comprehensive" | translate }}</h3>
                <p>1201 {{ "motor.SAR" | translate }}</p>
            </div>
            <div class="compdescription" [class.show]="ComprehensiveShow">
                <div>
                    <p>
                        <img src="assets/img/motor-pages/next 2.png" />No claims
                        discout benefits
                    </p>
                    <p>
                        <img
                            src="assets/img/motor-pages/next 2.png"
                        />Windscreen + Fire + Theft
                    </p>
                    <p>
                        <img src="assets/img/motor-pages/next 2.png" />No claims
                        discout benefits
                    </p>
                    <p>
                        <img
                            src="assets/img/motor-pages/next 2.png"
                        />Windscreen + Fire + Theft
                    </p>
                    <p>
                        <img src="assets/img/motor-pages/next 2.png" />No claims
                        discout benefits
                    </p>
                    <p>
                        <img
                            src="assets/img/motor-pages/next 2.png"
                        />Windscreen + Fire + Theft
                    </p>
                </div>
                <div class="viewmorecontainer">
                    <a
                        (click)="ComprehensiveShow = !ComprehensiveShow"
                        *ngIf="!ComprehensiveShow"
                        >{{ "motor.ViewMore" | translate }}</a
                    >

                    <a
                        (click)="ComprehensiveShow = !ComprehensiveShow"
                        *ngIf="ComprehensiveShow"
                        >{{ "motor.ViewLess" | translate }}</a
                    >
                    <br />
                    <button type="button" class="btn btn-primary selectbtn">
                        {{ "motor.Select" | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="tpl" [class.expanded]="TPLShow">
            <div class="tplimg"></div>
            <div class="tpldetails">
                <h3>{{ "motor.TPL" | translate }}</h3>
                <p>4597 {{ "motor.SAR" | translate }}</p>
            </div>
            <div class="tpldescription" [class.show]="TPLShow">
                <p>
                    <img src="assets/img/motor-pages/next 2.png" />No claims
                    discout benefits
                </p>
                <p>
                    <img src="assets/img/motor-pages/next 2.png" />Windscreen +
                    Fire + Theft
                </p>
                <p><img src="assets/img/motor-pages/next 2.png" />Own Damage</p>

                <div class="viewmorecontainer">
                    <a (click)="TPLShow = !TPLShow" *ngIf="!TPLShow">
                        {{ "motor.ViewMore" | translate }}</a
                    >

                    <a (click)="TPLShow = !TPLShow" *ngIf="TPLShow">
                        {{ "motor.ViewLess" | translate }}</a
                    >
                    <br />
                    <button type="button" class="btn btn-primary selectbtn">
                        {{ "motor.Select" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-stepper-content-form">
        <div class="roundbuttoncontainer">
            <button class="addvehicle">
                +{{ "motor.AddAnotherDriver" | translate }}
            </button>
        </div>
        <div class="btn-group-toggle-container"></div>

        <div class="policycoverageformleft">
            <h3 class="h3stylepolicy">
                {{ "motor.PolicyDetails" | translate }}
            </h3>
            <div class="forminputs">
                <span class="formtitle">{{
                    "motor.PolicyEffectiveDate" | translate
                }}</span>
                <div class="form-outline formnuminput">
                    <input
                        placeholder="MM/DD/YYYY"
                        type="date"
                        class="form-input"
                        id="exampleDatepicker11"
                    />
                </div>
            </div>
            <div class="forminputs">
                <span class="formtitle">{{
                    "motor.RepairType" | translate
                }}</span>
                <div class="form-outline formnuminput">
                    <mat-select class="form-control" value="1">
                        <mat-option value="1">Workshop</mat-option>
                        <mat-option value="2">Workshop Two</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>

        <div class="policycoverageformleft">
            <h3 class="h3stylepolicy">{{ "motor.DriverList" | translate }}</h3>
            <div class="forminputs">
                <span class="formtitle">{{
                    "motor.FullName" | translate
                }}</span>
                <div class="form-outline formnuminput">
                    <input
                        placeholder="Ahmed Omar Mohamed"
                        type="text"
                        class="form-input"
                    />
                </div>
            </div>
            <div class="forminputs">
                <span class="formtitle">{{
                    "motor.Birthdate" | translate
                }}</span>
                <div class="form-outline formnuminput">
                    <input
                        placeholder="15/06/1994"
                        type="date"
                        class="form-input"
                    />
                </div>
            </div>
        </div>

        <div class="policycoverageformleft">
            <div class="forminputs">
                <span class="formtitle">{{
                    "motor.ID/IQAMANumber" | translate
                }}</span>
                <div class="form-outline formnuminput">
                    <input
                        placeholder="2500003847"
                        type="text"
                        class="form-input"
                    />
                </div>
            </div>
            <div class="forminputs">
                <span class="formtitle">{{ "motor.Gender" | translate }}</span>
                <div class="form-outline formnuminput">
                    <input placeholder="Male" type="text" class="form-input" />
                </div>
            </div>
        </div>
    </div>

    <div class="custom-stepper-content-form">
        <p (click)="handleShowAdditionalInfo()">
            <mat-icon
                *ngIf="showAdditionalInfo"
                src="assets/img/motor-pages/arrow-down-sign-to-navigate 14.png"
                width="25px;"
                [ngClass]="{
                    'collapsebutton-en': lang === 'en-US',
                    'collapsebutton-ar': lang === 'ar-SA'
                }"
            >
                keyboard_arrow_down</mat-icon
            >
            <mat-icon
                *ngIf="!showAdditionalInfo"
                src="assets/img/motor-pages/arrow-down-sign-to-navigate 14.png"
                width="25px;"
                [ngClass]="{
                    'collapsebutton-en': lang === 'en-US',
                    'collapsebutton-ar': lang === 'ar-SA'
                }"
            >
                keyboard_arrow_up</mat-icon
            >
        </p>
        <div style="position: relative; float: left; width: 100%">
            <h3>{{ "motor.AdditionalInformation" | translate }}</h3>
        </div>

        <div *ngIf="showAdditionalInfo">
            <div class="userdataleft">
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.IDNumber" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="250003849"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.Nationality" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <mat-select class="form-control" value="1">
                            <mat-option value="1">Saudi Arabian</mat-option>
                            <mat-option value="2">Egyptian</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.Occupation" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <mat-select class="form-control" value="1">
                            <mat-option value="1">UX Developer</mat-option>
                            <mat-option value="2">Graphic Designer</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div class="userdataleft">
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.IdentificationType" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <mat-select class="form-control" value="1">
                            <mat-option value="1">IQAMA ID</mat-option>
                            <mat-option value="2">National ID</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.MaritalStatus" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="Married"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="userdataleft">
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.FullName" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="Ahmed Omar Mahmoud Ibrahim"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.MobileNumber" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="541573835"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="userdataleft">
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.DateofBirth" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="09-06-1994"
                            type="date"
                            class="form-input"
                        />
                    </div>
                </div>
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.Email" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="email@gmail.com"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
            </div>

            <div style="position: relative; float: left; width: 100%">
                <h3>{{ "motor.NationalAddress" | translate }}</h3>
            </div>
            <div class="userdataleft">
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.RegionNumber" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="Egyptian"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.BuildingNumber" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="3575"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="userdataleft">
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.City" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <mat-select class="form-control" value="1">
                            <mat-option value="1">Cairo</mat-option>
                            <mat-option value="2">Giza</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.UnitNumber" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="23"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="userdataleft">
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.District" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="New Cairo"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.ZipCode" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="12574"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="userdataleft">
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.Street" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="King Abdullah"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
                <div class="forminputs">
                    <span class="formtitle">{{
                        "motor.AdditionalNumber" | translate
                    }}</span>
                    <div class="form-outline formnuminput">
                        <input
                            placeholder="6522"
                            type="text"
                            class="form-input"
                        />
                    </div>
                </div>
            </div>
            <div
                style="
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    width: 100%;
                "
            >
                <div class="d-flex flex-column w-50">
                    <p>
                        {{
                            "motor.Policyholder'sAuthorizedAttorneyQ"
                                | translate
                        }}
                    </p>
                    <div class="radiobuttoncontainer2">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked
                            />
                            <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                            >
                                {{ "motor.Yes" | translate }}
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                            />
                            <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                            >
                                {{ "motor.No" | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column w-50">
                    <p>{{ "motor.PolicyOwnerQ" | translate }}</p>
                    <div class="radiobuttoncontainer2">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault2"
                                id="flexRadioDefault3"
                                checked
                            />
                            <label
                                class="form-check-label"
                                for="flexRadioDefault3"
                            >
                                {{ "motor.Yes" | translate }}
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault2"
                                id="flexRadioDefault4"
                            />
                            <label
                                class="form-check-label"
                                for="flexRadioDefault4"
                            >
                                {{ "motor.No" | translate }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-stepper-content-form">
        <div style="position: relative; float: left; width: 100%">
            <h3>{{ "motor.AdditionalCovers" | translate }}</h3>
        </div>
        <div
            class="form-check form-check-inline"
            [ngClass]="{
                'additionalcoverstyles-en': lang === 'en-US',
                'additionalcoverstyles-ar': lang === 'ar-SA'
            }"
        >
            <img src="assets/img/motor-pages/fire (1) 1.png" />
            <label class="form-check-label label2" for="inlineCheckbox1"
                >Windscreen + Fire + Theft</label
            >
            <input
                class="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                value="option1"
            />
        </div>

        <div
            class="form-check form-check-inline"
            [ngClass]="{
                'additionalcoverstyles-en': lang === 'en-US',
                'additionalcoverstyles-ar': lang === 'ar-SA'
            }"
        >
            <img src="assets/img/motor-pages/naturehazard.png" />
            <label class="form-check-label label2" for="inlineCheckbox1"
                >Natural Perils</label
            >
            <input
                class="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                value="option1"
            />
        </div>

        <div
            class="form-check form-check-inline"
            [ngClass]="{
                'additionalcoverstyles-en': lang === 'en-US',
                'additionalcoverstyles-ar': lang === 'ar-SA'
            }"
        >
            <img src="assets/img/motor-pages/tow-truck (1) 1.png" />
            <label class="form-check-label label2" for="inlineCheckbox1"
                >Roadside Assistance</label
            >
            <input
                class="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                value="option1"
            />
        </div>

        <div
            class="form-check form-check-inline"
            [ngClass]="{
                'additionalcoverstyles-en': lang === 'en-US',
                'additionalcoverstyles-ar': lang === 'ar-SA'
            }"
        >
            <img src="assets/img/motor-pages/fender-bender (1) 1.png" />
            <label class="form-check-label label2" for="inlineCheckbox1"
                >Personal Accident Cover</label
            >
            <input
                class="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                value="option1"
            />
        </div>
    </div>
</div>
