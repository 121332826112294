import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-cancellation-digitalization',
  templateUrl: './cancellation-digitalization.component.html',
  styleUrls: ['./cancellation-digitalization.component.scss']
})
export class CancellationDigitalizationComponent implements OnInit {
    ProductCode: number ; // 1 MMP Policy Profile , 2 Motor Claim Inquiry
    otpStep :boolean = false;
    formInput = ['input1', 'input2', 'input3', 'input4'];
    form: any;
    invalidOTP: boolean;
    isLoading: boolean;
    otpExpire: boolean = false;
    mobilePattern = '^(05)([0-9]{8})$';
    MobileNumber: string = '';
    timerr: any;
    display: any;
    isLoading2: boolean;
    LoginForm: FormGroup;
    @ViewChildren('formRow') rows: any;
    @ViewChild('swiper') swiper: ElementRef;
  constructor( public dialog: MatDialog,
    private _formbuilder: FormBuilder,
    private _router: Router,
    public translate: TranslateService,
    private _LocalService: LocalService,) {

    this.form = this.toFormGroup(this.formInput);
    this.LoginForm = this._formbuilder.group({
    MobileNumber: [
        '',
        [Validators.required, Validators.pattern(this.mobilePattern)],

         ],
    OTP: [null]
        });
  }

  ngOnInit(): void {
  }

  toFormGroup(elements: any) {
    const group: any = {};
    elements.forEach((key: any) => {
        group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
}
timer(minute: any) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    this.timerr = setInterval(() => {
        seconds--;
        if (statSec != 0) statSec--;
        else statSec = 59;

        if (statSec < 10) {
            textSec = "0" + statSec;
        } else textSec = statSec;

        this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
        if (this.otpExpire === false)

            if (seconds == 0) {
                //console.log("finished");
                clearInterval(this.timerr);
                this.otpExpire = true;
            }
    }, 1000);
}
keyUpEvent(event: any, index: any) {
    if (((event.keyCode >= 48 && event.keyCode < 58) || (event.keyCode >= 96 && event.keyCode < 110)
    || event.keyCode === 8 || event.keyCode == 13) && this.otpStep) {
        if (event.keyCode == 13 && this.otpStep) {

        }
        else {
            let x = this.form;
            let pos = index;
            if (event.keyCode === 8 && event.which === 8) {
                pos = index - 1;
            } else {
                pos = index + 1;
            }
            if (pos > -1 && pos < this.formInput.length) {
                this.rows._results[pos].nativeElement.focus();
            }
        }
    }
}

ResendVerification() {
    this.LoginForm.controls['OTP'].setValue(Number("0000"));
    this.isLoading2 = true;
    this.otpExpire = false;

}

ValidateOTP()
{
    this._router.navigateByUrl('/cancellation-submission', {
        state: {
            ProductCode: this.ProductCode,
        },
    });
}
}
