<loading *ngIf="isLoading">{{ isSubmitted }}</loading>
<div [ngClass]="{ 'dir-en': this.translate.currentLang === 'en-US', 'dir-ar': this.translate.currentLang === 'ar-SA' }">

    <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center" style="color: #226439;">
            <span>{{ "LogIn.Actions" | translate }}</span>
            <img src="assets/img/medical-sme/dialog-close.png" mat-dialog-close style="width: 4%;"/>
        </div>
        <hr class="title-underline" />
    </div>

    <div mat-dialog-content class="pt-2 pb-5">
        <mat-card style="box-shadow: 2px 3px 10px 4px #dee2e6">
            <mat-card-content>
                <div class="row pb-2" dir="{{ 'LogIn.Dir' | translate }}">
                    <div class="col-md-6">
                        <div class="form-outline">
                            <input type="file" id="contract" required #myInput (change)="setDocsList($event, 'contract')"
                                class="hidden" accept="application/pdf, image/*" multiple />
    
                            <label for="contract" class="custom-file-input upload-lbl">
                                <i class="fa fa-upload" aria-hidden="true" style="color: #d4ab36"></i>
                                {{ "LogIn.ContractAttachment" | translate }}<span style="color: red;">*</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6" dir="{{ 'LogIn.Dir' | translate }}">
                        <div class="form-outline">
                            <div class="row" *ngIf="ImagesId.includes('contract')">
                                <ng-container *ngFor="
                                        let img of domesticClaimImagesList;
                                        let x = index
                                    ">
                                    <div class="col-md-5 imageContainer" *ngIf="img.id == 'contract'">
                                        <div class="col-md-12">
                                            <div class="item-container" (click)="DisplayDocument(img)">
                                                <div style="
                                                        overflow: clip;
                                                        margin-right: 5%;
                                                    ">
                                                    {{ img.name }}
                                                </div>
                                                <div>
                                                    <span class="delete" (click)="deleteAtt(x)" *ngIf="img.id == 1">
                                                        <fa-icon [icon]="faXmark"></fa-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="row pb-2" dir="{{ 'LogIn.Dir' | translate }}">
                    <div class="col-md-6">
                        <div class="form-outline">
                            <input type="file" id="passport" required #myInput (change)="setDocsList($event, 'passport')"
                                class="hidden" accept="application/pdf, image/*" multiple />
    
                            <label for="passport" class="custom-file-input upload-lbl">
                                <i class="fa fa-upload" aria-hidden="true" style="color: #d4ab36"></i>
                                {{ "LogIn.PassportAttachment" | translate }}<span style="color: red;">*</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6" dir="{{ 'LogIn.Dir' | translate }}">
                        <div class="form-outline">
                            <div class="row" *ngIf="ImagesId.includes('passport')">
                                <ng-container *ngFor="
                                        let img of domesticClaimImagesList;
                                        let x = index
                                    ">
                                    <div class="col-md-5 imageContainer" *ngIf="img.id == 'passport'">
                                        <div class="col-md-12">
                                            <div class="item-container" (click)="DisplayDocument(img)">
                                                <div style="
                                                        overflow: clip;
                                                        margin-right: 5%;
                                                    ">
                                                    {{ img.name }}
                                                </div>
                                                <div>
                                                    <span class="delete" (click)="deleteAtt(x)" *ngIf="img.id == 1">
                                                        <fa-icon [icon]="faXmark"></fa-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="row pb-2" dir="{{ 'LogIn.Dir' | translate }}">
                    <div class="col-md-6">
                        <div class="form-outline">
                            <input type="file" id="iban" required #myInput (change)="setDocsList($event, 'iban')"
                                class="hidden" accept="application/pdf, image/*" multiple />
    
                            <label for="iban" class="custom-file-input upload-lbl">
                                <i class="fa fa-upload" aria-hidden="true" style="color: #d4ab36"></i>
                                {{ "LogIn.IBANAttachment" | translate }}<span style="color: red;">*</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6" dir="{{ 'LogIn.Dir' | translate }}">
                        <div class="form-outline">
                            <div class="row" *ngIf="ImagesId.includes('iban')">
                                <ng-container *ngFor="
                                        let img of domesticClaimImagesList;
                                        let x = index
                                    ">
                                    <div class="col-md-5 imageContainer" *ngIf="img.id == 'iban'">
                                        <div class="col-md-12">
                                            <div class="item-container" (click)="DisplayDocument(img)">
                                                <div style="
                                                        overflow: clip;
                                                        margin-right: 5%;
                                                    ">
                                                    {{ img.name }}
                                                </div>
                                                <div>
                                                    <span class="delete" (click)="deleteAtt(x)" *ngIf="img.id == 1">
                                                        <fa-icon [icon]="faXmark"></fa-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="attachmentsCheck != null && attachmentsCheck != ''"
                dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-12">
                    <p style="color: red;">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> &nbsp;
                        <strong>{{ attachmentsCheck }}</strong>
                    </p>
                </div>
            </div>

            <!-- <div class="row" *ngIf="contractFileValidator" dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-12">
                    <p style="color: red;">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> &nbsp;
                        <strong>{{ "LogIn.ContractAttachment" | translate }}
                            {{ "LogIn.IsRequired" | translate }}
                        </strong>
                    </p>
                </div>
            </div>

            <div class="row" *ngIf="passportFileValidator" dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-12">
                    <p style="color: red;">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> &nbsp;
                        <strong>{{ "LogIn.PassportAttachment" | translate }}
                            {{ "LogIn.IsRequired" | translate }}
                        </strong>
                    </p>
                </div>
            </div>

            <div class="row" *ngIf="ibanFileValidator" dir="{{ 'LogIn.Dir' | translate }}">
                <div class="col-md-12">
                    <p style="color: red;">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> &nbsp;
                        <strong>{{ "LogIn.IBANAttachment" | translate }}
                            {{ "LogIn.IsRequired" | translate }}
                        </strong>
                    </p>
                </div>
            </div> -->
            </mat-card-content>
        </mat-card>

        <div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12 pt-2 justify-content-center">
            <button class="save-btn mt-5" (click)="SubmitClaimMissingDocs()">
                <span *ngIf="!isLoading">{{ "SME.ADD" | translate }}</span>
                <span *ngIf="isLoading">
                    <div class="spinner-border text-warning"></div>
                </span>
            </button>
        </div>
    </div>
</div>