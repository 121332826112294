import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './components/pages/not-found/not-found.component';

import { ServicesPageComponent } from './components/pages/services-page/services-page.component';

import { UserLoginComponent } from './components/user-login/user-login.component';
import { UserVerificationComponent } from './components/user-login/user-verification/user-verification.component';
import { MotorClaimsComponent } from './components/E-Services/Motor/motor-claims/motor-claims.component';
import { NewMotorClaimComponent } from './components/E-Services/Motor/new-motor-claim/new-motor-claim.component';
import { MedicalApprovalsComponent } from './components/E-Services/Medical/medical-approvals/medical-approvals.component';
import { MedicalProfileComponent } from './components/E-Services/Medical/medical-profile/medical-profile.component';
import { ServicesProvidersComponent } from './components/E-Services/Medical/services-providers/services-providers.component';
import { MedicalBenefitsComponent } from './components/E-Services/Medical/medical-benefits/medical-benefits.component';
import { NewMedicalCashClaimComponent } from './components/E-Services/Medical/new-medical-cash-claim/new-medical-cash-claim.component';
import { MedicalCashClaimsComponent } from './components/E-Services/Medical/medical-cash-claims/medical-cash-claims.component';
import { FamilyMembersComponent } from './components/E-Services/Medical/family-members/family-members.component';
import { EmbassyLetterComponent } from './components/E-Services/Medical/embassy-letter/embassy-letter.component';
import { EServiceHomeComponent } from './components/pages/e-service-home/e-service-home.component';
import { AuthguardGuard } from './core/authGuards/authguard.guard';
import { IDVerificationGuard } from './core/authGuards/idverification.guard';

import { OtpaAuthGuardService } from './core/authGuards/otpa-auth-guard.guard';
import { LoginGuardGuard } from './core/authGuards/login-guard.guard';
import { MMLHomeComponent } from './components/E-Services/MML/mml-home/mml-home.component';
import { IdVerificationComponent } from './components/E-Services/MML/id-verification/id-verification.component';
import { RedirectPageComponent } from './components/E-Services/MML/redirect-page/redirect-page.component';
import { MainPageComponent } from './components/E-Services/Medical-SME/main-page/main-page.component';
import { SmeLoginPageComponent } from './components/E-Services/Medical-SME/sme-login-page/sme-login-page.component';
import { MmpInquiryComponent } from './components/E-Services/MML/mmp-inquiry/mmp-inquiry.component';
import { ClaimDigitalizationComponent } from './components/E-Services/ClaimDigitalization/claim-digitalization/claim-digitalization.component';
import { CancellationDigitalizationComponent } from './components/E-Services/cancellation-digitalization/cancellation-digitalization.component';
import { CancellationSubmissionComponent } from './components/E-Services/cancellation-submission/cancellation-submission.component';
import { MotorClaimsInquireComponent } from './components/E-Services/Motor/motor-claims-inquire/motor-claims-inquire.component';
import { MotorLoginComponent } from './components/E-Services/Motor/motor-login/motor-login.component';
import { MotorPolicyDetailsComponent } from './components/E-Services/Motor/motor-policy-details/motor-policy-details.component';
import { MotorDetailsLoginComponent } from './components/E-Services/Motor/motor-details-login/motor-details-login.component';
import { DLPolicyDetailsComponent } from './components/E-Services/Domestic-Labor/dl-policy-details/dl-policy-details.component';
import { DLPOLICYLOGINComponent } from './components/E-Services/Domestic-Labor/dl-policy-login/dl-policy-login.component';
import { MmpCashClaimsComponent } from './components/E-Services/MML/mmp-cash-claims/mmp-cash-claims.component';
import { NewMmpCashClaimComponent } from './components/E-Services/MML/mmp-cash-claims/new-mmp-cash-claim/new-mmp-cash-claim.component';
import { MmpPolicyDetailsComponent } from './components/E-Services/MML/mmp-cash-claims/mmp-policy-details/mmp-policy-details.component';
import { LoginMotorComponent } from './components/E-Services/Motor-pages/login-motor/login-motor.component';
import { MotorStepperComponent } from './components/E-Services/Motor-pages/motor-stepper/motor-stepper.component';
import { PaymentPageComponent } from './components/E-Services/payment/payment-page/payment-page.component';
import { AfterPaymentPageComponent } from './components/E-Services/payment/Payment-ThankPage/after-payment-page/after-payment-page.component';
import {ErrorPaymentOnlineComponent} from './components/E-Services/payment/error-payment-online/error-payment-online.component';
const routes: Routes = [
    { path: '', component: EServiceHomeComponent },

    {
        path: 'user-login',
        component: UserLoginComponent,
        canActivate: [LoginGuardGuard],
    },
    {
        path: 'user-verification',
        component: UserVerificationComponent,
        canActivate: [OtpaAuthGuardService],
    },
    { path: 'motor-claims', component: MotorClaimsComponent },
    { path: 'new-motor-claim', component: NewMotorClaimComponent },
    {
        path: 'medical-approvals',
        component: MedicalApprovalsComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'medical-profile',
        component: MedicalProfileComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'medical-service-providers',
        component: ServicesProvidersComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'medical-benefits',
        component: MedicalBenefitsComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'new-medical-claim',
        component: NewMedicalCashClaimComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'medical-claims',
        component: MedicalCashClaimsComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'mmp-cash-claims',
        component: MmpCashClaimsComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'family-members',
        component: FamilyMembersComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'embassy-letter',
        component: EmbassyLetterComponent,
        canActivate: [AuthguardGuard],
    },
    { path: 'claim-submission', component: ClaimDigitalizationComponent },
    { path: 'User-Gate', component: CancellationDigitalizationComponent },
    {
        path: 'cancellation-submission',
        component: CancellationSubmissionComponent,
    },
    { path: 'motor-claims-inquire', component: MotorClaimsInquireComponent },
    { path: 'motor-login', component: MotorLoginComponent },
    { path: 'motor-request', component: MotorStepperComponent },
    { path: 'motor-plicy-details', component: MotorPolicyDetailsComponent },
    { path: 'motor-policy-login', component: MotorDetailsLoginComponent },
    { path: 'domestic-policy-details', component: DLPolicyDetailsComponent },
    { path: 'domestic-policy-login', component: DLPOLICYLOGINComponent },
    //{path: 'services', component: ServicesPageComponent},

    {
        path: 'user-login',
        component: UserLoginComponent,
        canActivate: [LoginGuardGuard],
    },
    {
        path: 'user-verification',
        component: UserVerificationComponent,
        canActivate: [OtpaAuthGuardService],
    },
    { path: 'motor-claims', component: MotorClaimsComponent },
    { path: 'new-motor-claim', component: NewMotorClaimComponent },
    {
        path: 'medical-approvals',
        component: MedicalApprovalsComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'medical-profile',
        component: MedicalProfileComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'medical-service-providers',
        component: ServicesProvidersComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'medical-benefits',
        component: MedicalBenefitsComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'new-medical-claim',
        component: NewMedicalCashClaimComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'medical-claims',
        component: MedicalCashClaimsComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'family-members',
        component: FamilyMembersComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'embassy-letter',
        component: EmbassyLetterComponent,
        canActivate: [AuthguardGuard],
    },
    { path: 'medical-malpractice', component: MMLHomeComponent },
    { path: 'medical-malpractice-inquiry', component: MmpInquiryComponent },
    {
        path: 'new-mmp-cash-claim',
        component: NewMmpCashClaimComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'mmp-policy-details',
        component: MmpPolicyDetailsComponent,
        canActivate: [AuthguardGuard],
    },
    {
        path: 'id-verification',
        component: IdVerificationComponent,
        canActivate: [IDVerificationGuard],
    },
    //{path: 'services', component: ServicesPageComponent},
    { path: 'medical-sme', component: MainPageComponent },
    { path: 'medical-sme-login', component: SmeLoginPageComponent },
    { path: 'RedirectPage', component: RedirectPageComponent },
    { path: 'OnlineSadad/:data', component: PaymentPageComponent },
    { path: 'Online-Sadad-Page/:data', component: AfterPaymentPageComponent },
    { path: 'online-sadad-forbidden', component: ErrorPaymentOnlineComponent },
    {
        path: '',
        loadChildren: () =>
            import('./components/pages/services-page/service.module').then(
                (m) => m.ServiceModule
            ),
    },
    { path: 'Login-Motor', component: LoginMotorComponent },
   
    { path: '**', component: NotFoundComponent }, // This line will remain down from the whole pages component list 
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
