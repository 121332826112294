import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MedicalSmeService } from 'src/app/core/services/Medical-Sme-Service/medical-sme-service';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-sme-issued-policy',
    templateUrl: './sme-issued-policy.component.html',
    styleUrls: ['./sme-issued-policy.component.scss'],
})
export class SmeIssuedPolicyComponent implements OnInit {
    isLoading = true;
    arLink: string;
    enLink: string;
    newReport: string;
    message = '';
    downloadEn: any;
    downloadAr: any;
    payIsAccpted:boolean=true;

    downloadReport: any;
    cchiReportAR: any;
    cchiReportEn: any;
    draftReportAr: any;
    draftReportEn: any;
    finalReportAR: any;
    finalReportEn: any;
    hdReportAR: any;
    hdReportEn: any;
    invoiceReportAR: any;
    invoiceReportEn: any;
    medSMEReportAR: any;
    medSMEReportEn: any;
    memListReportAR: any;
    memListReportEn: any;
    policyScheduleReportAR: any;
    policyScheduleReportEn: any;
    providerReportAR: any;
    providerReportEn: any;
    errMessage: any;
    constructor(
        public translate: TranslateService,
        private smeService: MedicalSMEService,
        private location: Location,
        private _router: Router,
        private _localService: LocalService
    ) { }

    ngOnInit(): void {
        if (this.translate.currentLang == 'en-US') {
            this.message = 'Your policy issued successfully,';
        } else {
            this.message = 'لقد تم إصدار وثيقتك بنجاح';
        }
        const currentUrl = this.location.path();
        let trxId: string;
        if (currentUrl.includes('&')) {
            let seperateUrl = currentUrl.split('&');
            let OrderId = seperateUrl[1].split('=');
            trxId = OrderId[1];
        }
        if (
            localStorage.getItem('quotationNumber') != undefined &&
            localStorage.getItem('policyId') == undefined
        ) {
            // trxId = localStorage.getItem('quotationNumber');
            this.GetPaymentStatus(trxId, localStorage.getItem('quotationNumber'));
        } else if (
            localStorage.getItem('policyId') != undefined &&
            localStorage.getItem('quotationNumber') != undefined
        ) {
            this.getPolicyReports(
                localStorage.getItem('policyId'),
                localStorage.getItem('quotationNumber')
            );
        }
    }
    getPolicyReports(policyId: any, quotationNo: any) {
        let model = {
            quotationNo: quotationNo.toString(),
            policyId: policyId.toString(),
            branchCode: 99,
        };
        this.smeService.GetPolicyReports(model).subscribe((res) => {
            if (res != null) {
                this.UpdateSponsorMobile();

                //console.log(res);
                this.isLoading=false;
                this.arLink = res.arLink;
                this.enLink = res.enLink;
                this.newReport = res.newReport;
                this.payIsAccpted

                this.cchiReportAR = res.smeReports.cchiReportAR;
                this.cchiReportEn = res.smeReports.cchiReportEn;

                this.draftReportAr = res.smeReports.draftReport;
                this.draftReportEn = res.smeReports.draftReport;

                this.finalReportAR = res.smeReports.finalReport;
                this.finalReportEn = res.smeReports.finalReport;

                this.hdReportAR = res.smeReports.hdReport;
                this.hdReportEn = res.smeReports.hdReport;

                this.invoiceReportAR = res.smeReports.invoiceReport;
                this.invoiceReportEn = res.smeReports.invoiceReport;

                this.medSMEReportAR = res.smeReports.medSMEReportAR;
                this.medSMEReportEn = res.smeReports.medSMEReportEn;

                this.memListReportAR = res.smeReports.memListReport;
                this.memListReportEn = res.smeReports.memListReport;

                this.policyScheduleReportAR = res.smeReports.policyScheduleReport;
                this.policyScheduleReportEn = res.smeReports.policyScheduleReport;

                this.providerReportAR = res.smeReports.providerReport;
                this.providerReportEn = res.smeReports.providerReport;
                
                
            }
        });
    }

    GetPaymentStatus(trxId: any, quota: any) {
        let model = {
            "transactionId": trxId,
            "clientId": "12345678",
            "quotationNumber": quota.toString(),
            "entityName":"Visa"
        };
        this.smeService.GetPaymentStatus(model).subscribe((res) => {
            if (res != null) {
                this.isLoading=false;
                if(res.statusCode==200){
                    // update mobile number 
                    this.UpdateSponsorMobile();

                this.arLink = res.arLink;
                this.enLink = res.enLink;
                this.newReport = res.newReport;

                this.cchiReportAR = res.smeReports.cchiReportAR;
                this.cchiReportEn = res.smeReports.cchiReportEn;

                this.draftReportAr = res.smeReports.draftReportAr;
                this.draftReportEn = res.smeReports.draftReportEn;

                this.finalReportAR = res.smeReports.finalReportAR;
                this.finalReportEn = res.smeReports.finalReportEn;

                this.hdReportAR = res.smeReports.hdReportAR;
                this.hdReportEn = res.smeReports.hdReportEn;

                this.invoiceReportAR = res.smeReports.invoiceReportAR;
                this.invoiceReportEn = res.smeReports.invoiceReportEn;

                this.medSMEReportAR = res.smeReports.medSMEReportAR;
                this.medSMEReportEn = res.smeReports.medSMEReportEn;

                this.memListReportAR = res.smeReports.memListReportAR;
                this.memListReportEn = res.smeReports.memListReportEn;

                this.policyScheduleReportAR = res.smeReports.policyScheduleReportAR;
                this.policyScheduleReportEn = res.smeReports.policyScheduleReportEn;

                this.providerReportAR = res.smeReports.providerReportAR;
                this.providerReportEn = res.smeReports.providerReportEn;
                }
                else
                {
                    this.payIsAccpted=false;
                    if(this.translate.currentLang == 'ar-SA'){
                        if(res?.noonStatusResponse?.status.toString().toLowerCase() === 'cancelled'){
                            this.message = 'الغيت'
                        } else if(res?.noonStatusResponse?.status.toString().toLowerCase() === 'rejected'){
                            this.message = 'مرفوضة'
                        } else if(res?.noonStatusResponse?.status.toString().toLowerCase() === 'accepted'){
                            this.message = 'مقبولة'
                        }
                    }
                    else {
                        this.message = res?.noonStatusResponse?.status;
                    }
                    this.errMessage = res?.noonStatusResponse?.errorMsg;

                }
            }
        });
    }

    UpdateSponsorMobile(){
        let mobileNumber = this._localService.getJsonValue("SponsorMobile");
        let CompanyId = this._localService.getJsonValue("SponsorId");

        let model = {
            "CompanyId": Number(CompanyId),
            "MobileNumber": mobileNumber
        };

        this.smeService.UpdateSponsorMobile(model).subscribe((res) => {
        });
    }
    
    createQuotation(){
        window.location.href = '/medical-sme?tabindex=3&merchantReference=1231905';
    }

    download(input) {
        if (input === 'en') {
            window.open(this.enLink, '_blank');
        } else if (input === 'report') {
            window.open(this.newReport, '_blank');
        }
        //////////////////////////////
        else if (input === 'cchiReportAR') {
            window.open(this.cchiReportAR, '_blank');
        }
        else if (input === 'cchiReportEn') {
            window.open(this.cchiReportEn, '_blank');
        }
        /////////////////////////
        else if (input === 'draftReportAr') {
            window.open(this.draftReportAr, '_blank');
        }
        else if (input === 'draftReportEn') {
            window.open(this.draftReportEn, '_blank');
        }
        ////////////////////////////
        else if (input === 'finalReportAR') {
            window.open(this.finalReportAR, '_blank');
        }
        else if (input === 'finalReportEn') {
            window.open(this.finalReportEn, '_blank');
        }
        ////////////////////////////
        else if (input === 'hdReportAR') {
            window.open(this.hdReportAR, '_blank');
        }
        else if (input === 'hdReportEn') {
            window.open(this.hdReportEn, '_blank');
        }
        ////////////////////////////
        else if (input === 'invoiceReportAR') {
            window.open(this.invoiceReportAR, '_blank');
        }
        else if (input === 'invoiceReportEn') {
            window.open(this.invoiceReportEn, '_blank');
        }
        ////////////////////////////
        else if (input === 'medSMEReportAR') {
            window.open(this.medSMEReportAR, '_blank');
        }
        else if (input === 'medSMEReportEn') {
            window.open(this.medSMEReportEn, '_blank');
        }
        ////////////////////////////
        else if (input === 'memListReportAR') {
            window.open(this.memListReportAR, '_blank');
        }
        else if (input === 'memListReportEn') {
            window.open(this.memListReportEn, '_blank');
        }
        ////////////////////////////
        else if (input === 'policyScheduleReportAR') {
            window.open(this.policyScheduleReportAR, '_blank');
        }
        else if (input === 'policyScheduleReportEn') {
            window.open(this.policyScheduleReportEn, '_blank');
        }
        ////////////////////////////
        else if (input === 'providerReportAR') {
            window.open(this.providerReportAR, '_blank');
        }
        else if (input === 'providerReportEn') {
            window.open(this.providerReportEn, '_blank');
        }
        else {
            window.open(this.arLink, '_blank');
        }
    }

    homePage() {
        window.location.href = '';
    }

    ErrDialog(msg) {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        Swal.fire({
            title: this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#FF001F; direction:" +
                dir +
                "'>" +
                msg +
                '</div>',
            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
                icon: 'submit-claim-failed-icon-img',
                title: 'submit-claim-failed-swal-title',
                confirmButton: 'submit-claim-failed-confirm-btn',
            },
        });
    }
}
