import { Pipe, PipeTransform, LOCALE_ID, Inject, Renderer2, ElementRef, RendererFactory2 } from '@angular/core';

@Pipe({
  name: 'commaToSpacePipe'
})
export class CommaToSpacePipePipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value !== 'number' && typeof value !== 'string') {
      return value;
    }

    const stringValue = value.toString();
    const parts = stringValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return parts.join('.');
  }
}
