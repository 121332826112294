import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadMembersDialogComponent } from '../upload-members-dialog/upload-members-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { HealthDeclarationDialogComponent } from '../health-declaration-dialog/health-declaration-dialog.component';
import { UploadMembersResultDialogComponent } from '../upload-members-result-dialog/upload-members-result-dialog.component';
import { AddMemberDialogComponent } from '../add-member-dialog/add-member-dialog.component';
import * as XLSX from 'xlsx';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { MatStepper } from '@angular/material/stepper';
import { TermsAndConditionsDialogComponent } from '../terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { numbers } from '@material/slider';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';

@Component({
    selector: 'app-sme-quotation-table-step',
    templateUrl: './sme-quotation-table-step.component.html',
    styleUrls: ['./sme-quotation-table-step.component.scss'],
})
export class SmeQuotationTableStepComponent implements OnInit {
    @Input() SponsorId: any;
    QuotationNumber: any;

    @Output() onUploadUsersSubmit = new EventEmitter<any>();
    @Input() myStepper: MatStepper;

    remainingMembers: any;
    IsFoundHd: boolean = false;
    dataSource = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    youCanCalc: boolean = false;
    failedMembersSource = new MatTableDataSource();
    displayedColumns: string[] = ['IqamaId', 'phone', 'sponsorId', 'health', 'actions'];
    HDQuestions: any;
    displayedColumns2: string[] = ['IqamaId', 'Errors'];
    successMembers = [];
    failedMembers = [];
    isMembersAdded: boolean;
    passedRemaining = false;
    premiumData: any;
    Members: any;
    isLoading: boolean;
    memberHDQuestionAnswers: any;
    isSubmitted: boolean;
    CalcbtnDisabled: boolean = false;
    isApproved: boolean = false;
    MustFillHd: boolean = false;
    yesValue:boolean=false;
    noValue:boolean=false;
    isUnderWriting:boolean=false;
    HDReportAr: any;
    HDReportEn: any;


    constructor(
        private dialog: MatDialog,
        private smeService: MedicalSMEService,
        public translate: TranslateService,
        public _LocalService: LocalService
    ) {
    }

    ngOnInit(): void {
        if (localStorage.getItem("quotationNumber") != undefined)
            this.GetQutationReq(Number(localStorage.getItem("quotationNumber")));
        this.GetHDQuestion();
        this.GetTableData();
        this.checkQuotationMembers();
        this
        if (this.successMembers.length > 0)
        {
            this.IsFoundHd = true;
            let quotaNo=localStorage.getItem("quotationNumber");
            
            if(this.HDReportEn == null || this.HDReportEn == undefined)
                this.getHDReport(quotaNo);
        }

        if (this.remainingMembers === null || this.remainingMembers === undefined || this.remainingMembers === 0) {
            this.remainingMembers = localStorage.getItem('remainingMembers');
        }
    }
    GetQutationReq(quotaId: number) {
        this.smeService.GetQuotationReqById(quotaId).subscribe(res=>{
            if(res!=null)
            {
                
                if(res.isFoundHd=="Y"){
                    this.yesValue=true;
                    this.noValue=false;
                    this.isUnderWriting=true;
                }
                else if(res.isFoundHd=="N")
                {
                    this.yesValue=false;
                    this.noValue=true;
                    this.isUnderWriting=true;
                }
                else{
                    this.yesValue=false;
                    this.noValue=false;
                }



            }

        });
    }
    getUnderWritingStatus() {
        let data = {
            QuotationNo: Number(localStorage.getItem("quotationNumber")),
            Endto: 0,
            SuccessMemers: [],
            HDQuestions: []
        }
        this.smeService.GetUnderWritingStatus(data).subscribe(res => {
            if (res != null)
                console.log("underWritingStatus: " + res);
        })
    }

    setAll(event: any) {
        this.isApproved = event;
        if (event) {
            // open dialog with terms and condifitons
            let dialogRef = this.dialog.open(TermsAndConditionsDialogComponent, {
                width: '60%',
                height: '80%',
                position: { top: '50px' },
                disableClose:true,
            });
        }
    }

    GetHDQuestion() {
        this.smeService.GetHDQuestions().subscribe(
            (res) => {
                if (res != null) this.HDQuestions = res;
            },
            (err) => { }
        );
    }

    GetTableData() {
        this.dataSource = new MatTableDataSource(this.successMembers);
    }
    addRemoveQuestionAnswer(event: any) {
        if (event == "true")
            this.MustFillHd = true;
        else
            this.MustFillHd = false;
        this.youCanCalc = true;

    }

    getHDReport(quotaNo: string) {
        this.smeService.GetHDReport(quotaNo).subscribe(res=>{
            if(res!=null && res!=undefined){
                this.HDReportAr = res.hdReport;
                this.HDReportEn = res.hdReport;
            }
        });
    }

    OpenAddMemberDialog() {
        let quotId = localStorage.getItem('quotationNumber');
        let dialogRef = this.dialog.open(AddMemberDialogComponent, {
            width: '60%',
            height: '80%',
            position: { top: '50px' },
            data: {
                sponsorId: this.SponsorId,
                quotationNumber: quotId,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            this.successMembers = res.data.successMembers;
            this.failedMembers = res.data.failedMembers;
            this.failedMembersSource.paginator = this.paginator;
            this.failedMembersSource = new MatTableDataSource(
                this.failedMembers
            );
            this.dataSource = new MatTableDataSource(
                this.successMembers
            );
            this.isMembersAdded = true;

            if(this.successMembers.length > 0) {
                let quotaNo=localStorage.getItem("quotationNumber");
            
                if(this.HDReportEn == null || this.HDReportEn == undefined)
                    this.getHDReport(quotaNo);
            }

            if (this.successMembers.length >= this.remainingMembers) {
                this.passedRemaining = true;
                this.IsFoundHd = true;
            }

        });
    }

    exportexcel(): void {
        let title = 'failed-members';
        let fileName = 'failed-members.xlsx';
        /* pass here the table id */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            this.failedMembers,
            { skipHeader: false }
        );
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, fileName);
    }

    OpenUploadMembersDialog() {
        this.passedRemaining = false;
        let quota = localStorage.getItem("quotationNumber");
        let dialogRef = this.dialog.open(UploadMembersDialogComponent, {
            width: '40%',
            height: '85%',
            position: { top: '50px' },
            data: {
                sponsorId: this.SponsorId,
                quotationNumber: quota,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res != null && res != undefined && res.data != null) {
                if (res.data.errorOccured == false) {
                    this.successMembers = res.data.successMembers;
                    this.failedMembers = res.data.failedMembers;
                    this.failedMembersSource.paginator = this.paginator;
                    this.failedMembersSource = new MatTableDataSource(
                        this.failedMembers
                    );
                    this.dataSource = new MatTableDataSource(
                        this.successMembers
                    );
                    this.isMembersAdded = true;

                    if(this.successMembers.length > 0) {
                        let quotaNo=localStorage.getItem("quotationNumber");
                    
                        if(this.HDReportEn == null || this.HDReportEn == undefined)
                            this.getHDReport(quotaNo);
                    }

                    if (this.successMembers.length >= this.remainingMembers)
                        this.passedRemaining = true;

                    if (res.data.isExceed) {
                        let dir: string = '';
                        let ErrMsg = '';
                        if (this.translate.currentLang == 'en-US') {
                            dir = 'ltr';
                            ErrMsg = 'Number of employees exceeded 250, please visit any GULF Union AL AHLIA Branches for more assistance!';
                        } else {
                            dir = 'rtl';
                            ErrMsg = 'عدد الموظفيين تخطي 250 موظف, يرجى مراجعة احد افرع شركة اتحاد الخليج الأهليه لنقوم بخدمتكم';
                        }

                        this.ErrDialog(ErrMsg);
                    }

                    if (res.data.inCorrectFile) {
                        let dir: string = '';
                        let ErrMsg = '';
                        if (this.translate.currentLang == 'en-US') {
                            dir = 'ltr';
                            ErrMsg = "The attached file doesn't include any member, please check the attached file and re-upload again.";
                        } else {
                            dir = 'rtl';
                            ErrMsg = 'الملف المرفوع لا يحتوي علي أي افراد, برجاء مراجعة و التأكد من الملف و اعادة تحملية مرة اخري.';
                        }

                        this.ErrDialog(ErrMsg);
                    }
                }
                else {
                    let dir: string = '';
                    let ErrMsg = '';
                    if (this.translate.currentLang == 'en-US') {
                        dir = 'ltr';
                        ErrMsg = 'Failed to upload members, please check the template and try again !';
                    } else {
                        dir = 'rtl';
                        ErrMsg = 'حدث خطأ اثناء رفع الافراد, برجاء مرجاعة ملف الافراد و اعادة تحميلة مرة اخري!';
                    }

                    this.ErrDialog(ErrMsg);
                }
            }
        });
    }

    download(input) {
        if (input === 'hdReportAR') {
            window.open(this.HDReportAr, '_blank');
        }
        else if (input === 'hdReportEn') {
            window.open(this.HDReportEn, '_blank');
        }
    }

    openSelectedRows(element: any) {
        let dialogRef = this.dialog.open(HealthDeclarationDialogComponent, {
            width: '40%',
            height: '85%',
            position: { top: '50px' },
            data: {
                questions: this.HDQuestions,
                memberDetails: element
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            this.successMembers = res.data.successMembers;
            this.failedMembers = res.data.failedMembers;
            this.failedMembersSource.paginator = this.paginator;
            this.failedMembersSource = new MatTableDataSource(
                this.failedMembers
            );
            this.dataSource = new MatTableDataSource(
                this.successMembers
            );
            this.isMembersAdded = true;
            if (this.successMembers.length >= this.remainingMembers)
                this.passedRemaining = true;

            if (this.successMembers.length > 0) {
                let quotaNo = localStorage.getItem("quotationNumber");

                if (this.HDReportEn == null || this.HDReportEn == undefined)
                    this.getHDReport(quotaNo);
            }
        });
    }

    onSearchChange(event: any) {
        if (event != "") {
            var bindingMembers = this.successMembers.filter(c => c.iqamaId.toString().includes(event));
            this.dataSource = new MatTableDataSource(
                bindingMembers
            );
        }
        else {
            this.dataSource = new MatTableDataSource(
                this.successMembers
            );
        }

    }

    getPremiumData() {
        // quotation under revision
        if (!this.passedRemaining) {
            let dir: string = '';
            let ErrMsg = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
                ErrMsg = 'Number of accepted members are less than the required number from the CCHI !';
            } else {
                dir = 'rtl';
                ErrMsg = 'عدد المؤمنين المقبولين اقل من العدد المطلوب من مجلس الضمان الصحي';
            }

            this.ErrDialog(ErrMsg);
        } else if (!this.isApproved) {
            let dir: string = '';
            let ErrMsg = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
                ErrMsg = 'You should accept the terms and condition.';
            } else {
                dir = 'rtl';
                ErrMsg = 'يجب الموافقة علي الشروط والأحكام';
            }

            this.ErrDialog(ErrMsg);
        }
        else {
            var data = this.successMembers.filter(c => c.questionYesCode != null);
            if (data.length == 0 && this.MustFillHd) {
                let dir: string = '';
                let ErrMsg = '';
                if (this.translate.currentLang == 'en-US') {
                    dir = 'ltr';
                    ErrMsg = 'You must fill Health decleration for accpeted Members !';
                } else {
                    dir = 'rtl';
                    ErrMsg = 'يجب عليك ملء الإقرار الصحي  للأعضاء المقبولين!';
                }

                this.ErrDialog(ErrMsg);
            }
            else {
                let quotationUnderRevision = false;
                this.successMembers.forEach(element => {
                    if (element.questionYesCode != null && element.questionYesCode != undefined) {
                        quotationUnderRevision = true;
                    }
                });

                if (this.successMembers.length >= this.remainingMembers) {
                    this.isLoading = true;
                    let quotId = localStorage.getItem('quotationNumber');
                    let paymentObj = {
                        QuotationId: quotId,
                        SponsorId: this.SponsorId,
                        successMembers: this.successMembers
                    }
                    this.smeService.AddPaymentTrx(paymentObj).subscribe(
                        (res) => {
                            if (res != null) {

                                let data = {
                                    Endto: 0,
                                    QuotationNo: quotId,
                                    successMemers: this.successMembers,
                                    hDQuestions: this.HDQuestions,
                                    isFoundHd: this.MustFillHd ? "Y" : "N"
                                }
                                this.smeService.GetPremiumData(data).subscribe(
                                    (premRes) => {
                                        if (premRes != null) {

                                            this.isLoading = false;
                                            if (premRes.isFillHdMembersNoEqual == true) {
                                                let dir: string = '';
                                                let ErrMsg = '';

                                                // if (this.translate.currentLang == 'en-US') {
                                                //     dir = 'ltr';
                                                //     ErrMsg = 'You must fill Health decleration for all accpeted Members !';
                                                // } else {
                                                //     dir = 'rtl';
                                                //     ErrMsg = 'يجب عليك ملء الإقرار الصحي لجميع الأعضاء المقبولين!';
                                                // }

                                                this.ErrDialog(ErrMsg);
                                            }
                                            else if (premRes.isUnderReveision == true) {
                                                let dir: string = '';
                                                let ErrMsg = '';

                                                if (this.translate.currentLang == 'en-US') {
                                                    dir = 'ltr';
                                                    ErrMsg = 'This Quotation Under Revision !';
                                                } else {
                                                    dir = 'rtl';
                                                    ErrMsg = 'الوثيقة تحت المراجعة';
                                                }

                                                this.ErrDialog(ErrMsg);
                                            }
                                            else if (premRes.isUnderWritingError == true) {
                                                let msg = '';
                                                let dir: string = '';
                                                if (this.translate.currentLang == 'en-US') {
                                                    dir = 'ltr';
                                                    msg = `Your request is under review. You will receive SMS once it got finished. Thank you for choosing GulfUnion Al Ahliya.`;
                                                } else {
                                                    dir = 'rtl';
                                                    msg = `طلبك قيد الدراسة سيتم ارسال رسالة نصية قصيرة حال الانتهاء من دراسة طلبك. شكرا لاختيارك شركة الخليج الاهلية للتامين التعاوني.`;
                                                }
                                                Swal.fire({
                                                    title:
                                                        this.translate.currentLang == 'en-US'
                                                            ? 'Please Note !'
                                                            : '!يرجى الملاحظة ',
                                                    iconHtml:
                                                        '<img width=40 height=40 style="margin-top:10px;" src="assets/img/swal-icons-img/check.png">',
                                                    html:
                                                        "<div style='color:#333333; direction:" +
                                                        dir +
                                                        "'>" +
                                                        msg +
                                                        '</div>',
                                                    confirmButtonText:
                                                        this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                                                    customClass: {
                                                        icon: 'submit-claim-success-icon-img',
                                                        title: 'submit-claim-success-swal-title',
                                                        confirmButton: 'submit-claim-success-confirm-btn',
                                                    },
                                                });
                                            }
                                            else if (premRes.premiumData != null) {


                                                this.premiumData = premRes.premiumData;
                                                if (this.premiumData.totalVat != null && this.premiumData.totalPremWithoutVat != null && this.premiumData.totalPremWithVat != null) {
                                                    let tabIndex = 2;
                                                    let resultObj = {
                                                        tabIndex: 2,
                                                        premData: this.premiumData,
                                                        quotNumber: quotId
                                                    }
                                                    this.onUploadUsersSubmit.emit(resultObj);
                                                }

                                            }
                                        }
                                    },
                                    (err) => {
                                        this.isLoading = false;
                                        let dir: string = '';
                                        let ErrMsg = '';
                                        if (this.translate.currentLang == 'en-US') {
                                            dir = 'ltr';
                                            ErrMsg = 'Unable to Calculate Policy Value!';
                                        } else {
                                            dir = 'rtl';
                                            ErrMsg = 'حدث خطأ اثناء احتساب قيمة الوثيقة';
                                        }

                                        this.ErrDialog(ErrMsg);
                                    }
                                );
                            }
                        },
                        (err) => {

                        }
                    );
                }
                else {
                    let dir: string = '';
                    let ErrMsg = '';
                    if (this.translate.currentLang == 'en-US') {
                        dir = 'ltr';
                        ErrMsg = 'Number of accepted members are less than the required number from the CCHI !';
                    } else {
                        dir = 'rtl';
                        ErrMsg = 'عدد المؤمنين المقبولين اقل من العدد المطلوب من مجلس الضمان الصحي';
                    }

                    this.ErrDialog(ErrMsg);
                }

            }
        }
    }

    checkQuotationMembers() {
        this.isLoading = true;
        let quotationNmber = localStorage.getItem('quotationNumber');
        this.smeService
            .CheckQuotationMembersExistance(quotationNmber)
            .subscribe(
                (res) => {
                    this.Members = res;
                    this.isLoading = false;
                    if ((this.Members != null && this.Members != undefined)) {
                        this.successMembers = this.Members.successMembers;
                        this.failedMembers = this.Members.failedMembers;

                        if (this.successMembers.length != 0 && this.successMembers.length >= this.remainingMembers) {
                            this.passedRemaining = true;
                            this.IsFoundHd = true;
                        }

                        if(this.successMembers.length > 0) {
                            let quotaNo=localStorage.getItem("quotationNumber");
                        
                            if(this.HDReportEn == null || this.HDReportEn == undefined)
                                this.getHDReport(quotaNo);
                        }

                        this.failedMembersSource.paginator = this.paginator;
                        this.failedMembersSource = new MatTableDataSource(
                            this.failedMembers
                        );
                        this.dataSource = new MatTableDataSource(
                            this.successMembers
                        );

                        if (this.successMembers.length == 0)
                            this.CalcbtnDisabled = true;
                    }
                },
                (err) => {
                    this.isLoading = false;
                }
            );
    }

    ErrDialog(msg) {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        Swal.fire({
            title: this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#FF001F; direction:" +
                dir +
                "'>" +
                msg +
                '</div>',
            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
                icon: 'submit-claim-failed-icon-img',
                title: 'submit-claim-failed-swal-title',
                confirmButton: 'submit-claim-failed-confirm-btn',
            },
        });
    }

    prevStep() {
        this.myStepper.previous();
    }
}
