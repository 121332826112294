<div>
    <div class="page-title-area bg-1">
        <div class="container col-md-10">
            <div class="page-title-content">
                <h2>{{ "MEDICAL.MedicalBenefits" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/"
                            >{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li>
                        <a routerLink="/medical-profile">
                            {{ "NAV.MEDICAL" | translate }}

                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li class="active" style="font-weight: 500">
                        {{ "MEDICAL.MedicalBenefits" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container pt-3 pd-0 col-md-10">
    <div class="row" style="height: 60px">
        <div class="claims-header col-lg-4 col-md-12 col-sm-12">
            <div class="d-flex">
                <a href="/medical-profile">
                    <img
                        src="assets/img/medical-service/icons8-back-arrow-90.png"
                        class="back-icon"
                        alt=""
                        [ngClass]="{
                            'arrow-img-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                    />
                </a>
                <h5
                    class="color-green"
                    [ngClass]="{
                        'title-en': this.translate.currentLang == 'en-US',
                        'title-ar': this.translate.currentLang != 'en-US'
                    }"
                >
                    {{ "MEDICAL.MedicalBenefits" | translate }}
                </h5>
            </div>
            <hr
                [ngClass]="{
                    'hr-en': this.translate.currentLang == 'en-US',
                    'hr-ar': this.translate.currentLang != 'en-US'
                }"
            />
        </div>
    </div>
</div>

<div class="container pt-3 pb-2 col-md-10">
    <div class="row">
        <!-- Profile Card -->

        <div class="col-lg-4 col-md-12">
            <div class="profile-card row pt-2">
                <div></div>
                <img
                    class="avatar-s"
                    src="assets/img/medical-service/Screenshot (216)-modified.png"
                />
                <div
                    class="row"
                    [ngClass]="{
                        'degree-en': this.translate.currentLang == 'en-US',
                        'degree-ar': this.translate.currentLang != 'en-US'
                    }"
                >
                    {{ selectedMember.className }}
                </div>
                <div>
                    <h5 class="name">{{ selectedMember.memberEName }}</h5>
                    <span class="id"
                        >{{ "MEDICAL.NationalId" | translate }} :{{
                            selectedMember.id
                        }}
                    </span>
                </div>
                <div></div>
            </div>
            <!-- Services Table -->

            <div class="pt-3">
                <div class="row">
                    <div class="claims-header">
                        <div class="d-flex">
                            <img
                                src="assets/img/medical-service/check-list.png"
                                alt=""
                                width="40"
                                height="20"
                                class="p-1"
                            />
                            <div>
                                <h4 class="services">
                                    {{ "MEDICAL.PolicyPlan" | translate }}
                                    <div
                                        [ngClass]="{
                                            'hr-line':
                                                this.translate.currentLang ==
                                                'en-US',
                                            'hr-line-ar':
                                                this.translate.currentLang !=
                                                'en-US'
                                        }"
                                    ></div>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                class="d-flex align-items-center"
                            ></th>
                            <th scope="col">
                                <h4 class="max-limit">
                                    {{
                                        "MEDICAL.Maximum-percentage" | translate
                                    }}
                                </h4>
                            </th>
                            <th scope="col">
                                <h4 class="max-limit">
                                    {{ "MEDICAL.Max" | translate }}
                                </h4>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let MD of MemberDeductibleObj">
                            <td style="border-radius: 0">
                                <h5 class="services-item">
                                    {{ MD.deductGroup }}
                                </h5>
                            </td>
                            <td style="border-radius: 0">
                                <h5 class="max-item">{{ MD.percentage }}%</h5>
                            </td>

                            <td style="border-radius: 0">
                                <h5 class="max-item">
                                    {{ MD.maxAmount }}
                                    {{ "MEDICAL.KSACUR" | translate }}
                                </h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-lg-8 col-md-12">
            <div class="row">
                <div class="claims-header">
                    <div class="d-flex">
                        <img
                            src="assets/img/medical-service/health.png"
                            alt=""
                            width="40"
                            height="20"
                            class="p-1"
                        />
                        <div>
                            <h3 class="benefits-header">
                                {{ "MEDICAL.Benefits" | translate }}
                            </h3>
                            <div
                                [ngClass]="{
                                    'hr-line':
                                        this.translate.currentLang == 'en-US',
                                    'hr-line-ar':
                                        this.translate.currentLang != 'en-US'
                                }"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Policy plan Card -->
            <div class="row pt-4">
                <div
                    class="col-md-4 col-6 pb-4"
                    *ngFor="let BC of BenefitDetailsObj"
                >
                    <div class="policy-card row pb-2">
                        <img src="{{ cardImage(BC) }}" class="p-0 card-image" />
                        <h4
                            class="card-tit"
                            *ngIf="this.translate.currentLang == 'en-US'"
                        >
                            {{ BC.benefitName }}
                        </h4>
                        <h4
                            class="card-tit"
                            *ngIf="this.translate.currentLang == 'ar-SA'"
                        >
                            {{ BC.benefitNameAR }}
                        </h4>

                        <div class="pb-2">
                            <h4 class="card-p">
                                {{ BC.maxLimit }}
                                {{ "MEDICAL.KSACUR" | translate }}
                            </h4>
                            <h5 class="card-d">
                                {{ "MEDICAL.Max1" | translate }}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
