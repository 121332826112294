// Local Environment
// export const environment = {
//     production: true,
//     BaseURL: 'https://localhost:7101/'
// };

export const environment = {
    production: true,
    BaseURL: 'localhost:8885/'
};
