import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/core/AppConfigService';
import { MmpService } from 'src/app/core/services/MMP-Service/mmp.service';

@Component({
    selector: 'app-redirect-page',
    templateUrl: './redirect-page.component.html',
    styleUrls: ['./redirect-page.component.scss'],
})
export class RedirectPageComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private mmpService: MmpService,
        private environment: AppConfigService,
        public translate: TranslateService
    ) {
        this.websiteURL = environment?.config?.webSiteURL;
    }
    @ViewChild('mmpPaymentSuccess') successBtn: any;
    @ViewChild('mmpPaymentFailed') failedBtn: any;

    isLoading = true;
    transectionID: any;
    transactionStatus: any;
    websiteURL: any;

    downloadEn: any;
    downloadAr: any;

    downloadReport: any;

    message = '';
    errMessage = '';

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.transectionID = params['orderId'];
            this.isLoading = true;
            let entityName;
            let trx;
            if (
                (localStorage.getItem('trxId') !== undefined ||
                    localStorage.getItem('trxId') !== null) &&
                (localStorage.getItem('method') !== undefined ||
                    localStorage.getItem('method') !== null) &&
                (params['orderId'] !== undefined || params['orderId'] !== null)
            ) {
                trx = Number(localStorage.getItem('trxId'));
                entityName = localStorage.getItem('method');
                this.getPaymentStatys(params['orderId'], entityName, trx);
            }
        });
    }
    // async ngAfterViewInit() {
    //     console.log('afterview init');

    //     if (localStorage.getItem('method') && this.transectionID) {
    //         this.isLoading = true;
    //         this.getPaymentStatys();
    //     }
    // }

    getPaymentStatys(id, entityName, trx) {
        this.isLoading = true;

        let queryString = `transactionId=${id}&trxId=${trx}&entityName=${entityName}`;
        this.mmpService.GetPaymentStatus(queryString).subscribe(
            (res) => {
                this.transactionStatus = res;
                if (res?.statusCode == '200') {
                    if (this.translate.currentLang == 'en-US') {
                        this.message = 'Your policy issued successfully,';
                    } else {
                        this.message = 'لقد تم إصدار وثيقتك بنجاح';
                    }

                    this.downloadEn = res?.enLink;
                    this.downloadAr = res?.arLink;
                    this.downloadReport = res?.newReport;
                    this.successBtn.nativeElement.click();
                } else {
                    this.message = res?.content?.status;
                    this.errMessage = res?.content?.errorMsg;
                    this.failedBtn.nativeElement.click();
                }
                this.isLoading = false;
            },
            (err) => {
                console.log(err);
                this.isLoading = false;
            }
        );
    }
    homePage() {
        window.location.href = '';
    }

    download(input) {
        if (input === 'en') {
            window.open(this.downloadEn, '_blank');
        } else if (input === 'report') {
            window.open(this.downloadReport, '_blank');
        } else {
            window.open(this.downloadAr, '_blank');
        }
    }
    openEsericeportal() {
        window.open(this.websiteURL + '/compliant', '_blank');
    }
}
