<div *ngIf="translate.currentLang === 'en-US'" style="direction: ltr;">
    <div mat-dialog-title>
        <div class="d-flex">
            <span class="upload-dialog-second-header" style="color: #156538;">
                Declaration and Terms and Condition
            </span>
        </div>
    </div>

    <div>
        <div class="col-lg-12 col-md-12 col-sm-12 d-flex flex-column upload-dialog-content"
            style="padding: 3% 5% 3% 5%">
            <div class="row">
                Please Read Carefully
            </div>
            <div class="row" style="font-weight: 600;">
                By going forward with our service you hereby declare all the upcoming
            </div>
            <div class="upload-dialog-second-header">
                <ul>
                    <li>
                        1 - I hereby declare that all the unchecked members have no health declarations (Declaration
                        Questions
                        will be answered as 'No' without weight and height)
                    </li>
                    <li>
                        2 - I hereby declare that I have provided the correct information and assigned Gulf Union Al
                        Ahlia
                        Cooperative Insurance to inquire about my company members’ information.
                    </li>
                    <li>
                        3 - I hereby declare that all health declarations has been filled by referring to the relevant
                        employee for the purpose of pricing properly and to ensure to have correct health care services
                        as
                        required, this means:
                        <ol type="A">
                            <li>
                                You confirm that information in all health declarations are correct and the acceptance
                                of your
                                enrollment will be on the basis of such information and that Gulf Union Al Ahlia
                                Cooperative Insurance
                                has the right to contact the hospitals) you deal with to collect any medical information
                                needed to
                                assess the risk(s).
                            </li>
                            <li>
                                You agree that Gulf Union Al Ahlia Cooperative Insurance has the right to reject the
                                coverage/claims
                                in full in case of no declaration of any cases prior to the contractual date or before
                                enrolling or
                                adding a new Insured during the contract.
                            </li>
                            <li>
                                You confirm reading and understanding all points presented in health declaration form
                                and you agree
                                that not marking any case is understood as ""Nothing requires declaration"" and sign on
                                these basis
                            </li>
                            <li>
                                Failure to fill the weight and height information will result in refusal to cover the
                                cost of obesity
                                surgery.
                            </li>
                        </ol>
                    </li>
                    <li>
                        4 - I declare as an employer that will comply with all the requirements of Council of
                        Cooperative
                        Health Insurance rules and the Council's regulations and related resolutions.
                    </li>
                </ul>
            </div>
        </div>
    </div>


    <div>
        <div class="row sme-health-save-container mb-2 mt-5">
            <button class="sme-save-btn" mat-dialog-close>
                <span>Confirm</span>
            </button>
        </div>
    </div>
</div>

<div *ngIf="translate.currentLang === 'ar-SA'" style="direction: rtl;">
    <div mat-dialog-title>
        <div class="d-flex">
            <span class="upload-dialog-second-header" style="color: #156538;">
                الشروط والأحكام الخاصة بالأفصاح الطبي
            </span>
        </div>
    </div>

    <div>
        <div class="col-lg-12 col-md-12 col-sm-12 d-flex flex-column upload-dialog-content"
            style="padding: 3% 5% 3% 5%">
            <div class="row">
                يرجى القراءة بعناية
            </div>
            <div class="row" style="font-weight: 600;">
                من خلال المضي قدمًا في استخدام خدمتنا، فإنك تعلن من خلال ذلك عن كل ما سيأتي:-  
            </div>
            <div class="upload-dialog-second-header">
                <ul>
                    
                    <li>
                        أقر بموجبه أن جميع الأعضاء غير المفحوصين ليس لديهم أي إعلانات صحية (سيتم الإجابة على أسئلة الإعلانات بـ 'لا' دون ذكر الوزن والطول)
                    </li>
                    <li>
                        أقر بموجبه أنني قدمت المعلومات الصحيحة وقمت بتفويض شركة إتحاد الخليج الاهلية للتأمين التعاوني الاستفسار عن معلومات موظفي شركتي                      
                    </li>
                    <li>أقر بموجبه أن جميع الإعلانات الصحية قد تم ملؤها بالرجوع إلى الموظف المعني لأغراض تحديد الأسعار بشكل صحيح وضمان توفير الخدمات الصحية الصحيحة كما هو مطلوب، وهذا يعني:-
                        <ul>
                            <li>تؤكد أن المعلومات المذكورة في جميع الإعلانات الصحية صحيحة وأن قبول تسجيلك سيكون على أساس هذه المعلومات وأن شركة إتحاد الخليج الاهلية للتأمين التعاوني لها الحق في الاتصال بالمستشفيات التي تتعامل معها لجمع أي معلومات طبية مطلوبة لتقييم المخاطر
                            </li>
                            <li>
                                اوافق على أن شركة إتحاد الخليج الاهلية للتأمين التعاوني لها الحق في رفض التغطية/المطالبات بالكامل في حالة عدم الإعلان عن أي حالات قبل التاريخ التعاقدي أو قبل التسجيل أو إضافة مؤمن جديد خلال العقد
                            </li>
                            <li>تؤكد قراءتك وفهمك لجميع النقاط المطروحة في استمارة الإعلان الصحي وتوافق على أن عدم وضع علامة على أي حالة يفهم على أنه "لا يلزم الإعلان" وتوقع على هذا الأساس
                            </li>
                            <li>
                                في حالة عدم تعبئة معلومات الوزن والطول، سيؤدي ذلك إلى رفض تغطية تكاليف جراحة السمنة
                            </li>
                        </ul>
                    </li>
                    <li>
                        أنا كصاحب عمل أعلن أنني سألتزم بجميع متطلبات قواعد مجلس التأمين الصحي التعاوني ولوائح المجلس والقرارات ذات الصلة
                    </li>
                </ul>
            </div>
        </div>
    </div>


    <div>
        <div class="row sme-health-save-container mb-2 mt-5">
            <button class="sme-save-btn" mat-dialog-close>
                <span>{{ "SME.Confirm" | translate}}</span>
            </button>
        </div>
    </div>
</div>