<div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center">
        <span>{{ "SME.UPLOADDETAILS" | translate }}</span>
        <img src="assets/img/medical-sme/dialog-close.png" mat-dialog-close />
    </div>
    <hr class="title-underline" />

    <div class="row col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-5 col-md-5 col-sm-12">
            <div class="d-flex flex-column align-items-center">
                <div class="d-flex flex-row">
                    <div class="sme-quotation-left-line"></div>
                    <div class="sme-quotation-details-container">
                        <h4 class="sme-quotation-details">
                            {{ "SME.ACCMEMBERS" | translate }}
                        </h4>
                    </div>
                    <div class="sme-quotation-right-line"></div>
                </div>
                <div class="d-flex flex-row align-items-center">
                    <span class="members-count-label"
                        >{{ successMembers.length }}
                        {{ "SME.MEMBERS" | translate }}
                    </span>
                    <img
                        src="assets/img/medical-sme/check-mark (1) 7.png"
                        style="height: 20px; width: 20px"
                    />
                </div>
            </div>
        </div>

        <div class="col-lg-5 col-md-5 col-sm-12">
            <div class="d-flex flex-column align-items-center">
                <div class="d-flex flex-row">
                    <div class="sme-quotation-left-line"></div>
                    <div class="sme-quotation-details-container">
                        <h4 class="sme-quotation-details">
                            {{ "SME.REJMEMBERS" | translate }}
                        </h4>
                    </div>
                    <div class="sme-quotation-right-line"></div>
                </div>
                <div class="d-flex flex-row align-items-center">
                    <span class="members-count-label"
                        >{{ failedMembers.length }}
                        {{ "SME.MEMBERS" | translate }}
                    </span>
                    <img
                        src="assets/img/medical-sme/close (2) 4.png"
                        style="height: 20px; width: 20px"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-content>
    <div class="col-lg-12 col-md-12 col-sm-12 d-flex flex-column">
        <div class="d-flex flex-row">
            <div class="sme-quotation-left-line"></div>
            <div class="sme-quotation-details-container">
                <h4 class="sme-quotation-details">
                    {{ "SME.REJMEMBERSDETAILS" | translate }}
                </h4>
            </div>
            <div class="sme-quotation-right-line"></div>
        </div>

        <div class="d-flex flex-row">
            <table
                mat-table
                [dataSource]="dataSource"
                matSort
                style="width: 100%"
                class="sme-quotation-table table-striped table"
            >
                <!-- ID Column -->
                <ng-container matColumnDef="IqamaId">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="text-center sme-table-title"
                    >
                        {{ "SME.IQAMAID" | translate }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        class="text-center"
                        style="padding-top: 1%"
                    >
                        {{ row.iQamaId }}
                    </td>
                </ng-container>

                <!-- Progress Column -->
                <ng-container matColumnDef="Errors">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="text-center sme-table-title"
                    >
                        {{ "SME.REASON" | translate }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        class="text-center"
                        style="padding-top: 1%"
                    >
                        {{ row.errors[0].error }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </div>
    </div>
</div>
