import { MatPaginatorIntl } from '@angular/material/paginator';

const CustomPaginatorComponentEN = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return `0 of ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} of ${length}`;
};

const CustomPaginatorComponentAR = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return ` ${length.toLocaleString('ar-EG')} من ${length.toLocaleString('ar-EG')}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `${(startIndex + 1).toLocaleString('ar-EG')} - ${endIndex.toLocaleString('ar-EG')} من ${length.toLocaleString('ar-EG')}`;
};

export function getDutchPaginatorIntl() {
  const languageValue = localStorage.getItem('language');

  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = 'Custom_Label:';

  if(languageValue==='en-US')
  {
    paginatorIntl.getRangeLabel = CustomPaginatorComponentEN;
    paginatorIntl.itemsPerPageLabel = 'Items Per Page:';

  }
  else
  {
    paginatorIntl.getRangeLabel = CustomPaginatorComponentAR;
    paginatorIntl.itemsPerPageLabel = 'عدد العناصر لكل صفحة:';

  }

  return paginatorIntl;
}

