<div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center" style="color: #226439;">
        <span></span>
        <img src="assets/img/medical-sme/dialog-close.png" mat-dialog-close style="width: 4%;" />
    </div>
</div>
<div class="">
    <div class="white">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="login-form-container">
                <div class="container height-100 d-flex justify-content-center align-items-center">
                    <div class="position-relative">
                        <div class="card p-2 text-center" (keyup.enter)="navigate()">
                            <h6>{{ "Verification.title" | translate }}</h6>
                            <div class="message">
                                <i><mat-icon> chat</mat-icon></i>
                                <span>{{ "Verification.code" | translate
                                    }}{{ display }}
                                </span>
                            </div>
                            <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2"
                                *ngIf="this.translate.currentLang == 'en-US'">
                                <ngx-otp-input [(ngModel)]="OTPValue" [config]="otpInputConfig"
                                    (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)"
                                    (keyup.enter)="navigate()">
                                </ngx-otp-input>
                            </div>
                            <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2"
                                *ngIf="this.translate.currentLang == 'ar-SA'" style="direction: ltr">
                                <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                                    (fill)="handleFillEvent($event)" (keyup.enter)="navigate()">
                                </ngx-otp-input>
                            </div>
                            <div class="mt-4">
                                <button class="btn px-4 validate" (click)="resendCode()" [disabled]="seconds !== 0">
                                    {{ "Verification.resend" | translate }}
                                </button>
                                <button class="btn px-4 validate login-btn" [disabled]="disableBTN || !ValidOTP"
                                    (click)="navigate()" (keyup.enter)="navigate()">
                                    {{ "Verification.verify" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>