
<br />
<loading *ngIf="isLoading">{{ isLoading }}</loading>
<section style="background-color: #eee;">
    <div class="container py-5">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="py-4 d-flex flex-row">
                        <legend class="claim-title">
                            {{ "MMLHome.payment" | translate }} &ensp;
                            <span class="ml-3">
                                <img src="assets/img/MML/mada.svg" alt="" width="100px" height="45px" />
                                <img src="assets/img/MML/visa.svg" alt="" width="50px" height="45px" />
                                <img src="assets/img/MML/master-card.svg" alt="" width="50px" height="45px" class="ml-2" />
                            </span>
                        </legend>
                    </div>
                </div>
                <form class="pb-3" [formGroup]="PaymentForm">
                    <div class="row d-flex justify-content-center pb-5">
                        <div class="col-md-7 col-xl-5 mb-4 mb-md-0">


                            <div class="d-flex pt-2">
                                <div>
                                    <p>
                                        <b><span class="text-success"> {{ "MMLHome.billing" | translate }}</span></b>
                                    </p>
                                </div>
                                <div class="ms-auto">
                                    <p class="text-primary">

                                    </p>
                                </div>
                            </div>
                            <p>
                                {{ memberBillingDetails?.city }} , {{ memberBillingDetails?.district }},{{ memberBillingDetails?.streetName }}
                            </p>
                            <div class="rounded d-flex" style="background-color: #f8f9fa;">
                                <input type="text" placeholder="{{ 'SME.VATNumber' | translate }}" class="mmp-payment-input" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" [maxLength]="18"
                                       type="text" formControlName="vatNumber" [ngClass]="{
                    'red-border-class':
                        PaymentFormControl.vatNumber.errors &&
                        (PaymentFormControl.vatNumber.touched ||
                            isSubmit),
                    'form-control': defaultClassFlag == true,
                    'input-form-design':
                        defaultClassFlag == true
                }" />
                                <div *ngIf="
                    PaymentFormControl.vatNumber.errors &&
                    (PaymentFormControl.vatNumber.touched ||
                        isSubmit)
                " class="">
                                    <div *ngIf="
                        PaymentFormControl.vatNumber.errors.required
                    " style="color: #ff001f">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        {{ "GENERIC.REQUIRED" | translate }}
                                    </div>
                                    <div *ngIf="
                        PaymentFormControl.vatNumber.errors.pattern
                    " style="color: #ff001f">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        {{ "SME.InvalidVat" | translate }}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="pt-2">
                                <div class="d-flex pb-2">
                                    <div>
                                        <p>
                                            <b> <span class="text-success">{{ "MMLHome.paymentInfo" | translate }}</span></b>
                                        </p>
                                    </div>

                                </div>



                                <br />
                                <div class="col-md-12">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1" style="margin-right: 5%;
                                padding: 11px;">SA</span>
                                        </div>
                                        <input type="text" placeholder="{{
                                'MMLHome.iban' | translate
                            }}" class="mmp-payment-input" type="number" formControlName="iban" [ngClass]="{
                                'red-border-class':
                                    PaymentFormControl.iban.errors &&
                                    (PaymentFormControl.iban.touched ||
                                        isSubmit),
                                'form-control':
                                    defaultClassFlag == true,
                                'input-form-design':
                                    defaultClassFlag == true
                            }" maxlength="22" aria-label="Username" aria-describedby="basic-addon1"
                                               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                               style="border-radius: 5px !important" />
                                    </div>

                                    <div *ngIf="
                            PaymentFormControl.iban.errors &&
                            (PaymentFormControl.iban.touched ||
                                isSubmit)
                        " class="">
                                        <div *ngIf="
                                PaymentFormControl.iban.errors.required
                            " style="color: #ff001f">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                        <div *ngIf="
                                PaymentFormControl.iban.errors.pattern
                            " style="color: #ff001f">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                            {{ "GENERIC.InvalidIban" | translate }}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="col-md-12">
                                    <input type="text" placeholder="{{ 'MMLHome.email' | translate }}" class="mmp-payment-input"
                                           type="email" formControlName="email" [ngClass]="{
            'red-border-class':
                PaymentFormControl.email.errors &&
                (PaymentFormControl.email.touched ||
                    isSubmit),
            'form-control': defaultClassFlag == true,
            'input-form-design':
                defaultClassFlag == true
        }" class="disabled-input" disabled />
                                    <div *ngIf="
            PaymentFormControl.email.errors &&
            (PaymentFormControl.email.touched ||
                isSubmit)
        " class="">
                                        <div *ngIf="
                PaymentFormControl.email.errors.required
            " style="color: #ff001f">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                        <div *ngIf="
                PaymentFormControl.email.errors.pattern
            " style="color: #ff001f">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                            {{ "GENERIC.InvalidEmail" | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row w-100 m-0">
                                    <div class="col-12" style="max-height: fit-content; width: 100%">
                                        <!-- Next & back Buttons -->
                                        <div class="d-flex mt-3 justify-content-center align-items-center">
                                            <button mat-button class="d-flex flex-row mmp-pay-btn p-1" id="add-payment-btn" (click)="submitPayment()"
                                                    style="margin-right: 10px; width: 130px">
                                                <span>{{ "MMLHome.paymentBtn" | translate }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <br />
                        <div class="col-md-5 col-xl-5 offset-xl-1">
                            <div class="py-4 d-flex justify-content-end">

                            </div>
                            <div class="rounded d-flex flex-column p-2" style="background-color: #f8f9fa;">

                                <div class="sme-quotation-address-details-container-ar">
                                    <h4 class="sme-quotation-details" style="color:#156538;font-weight: 600;">
                                        {{ "MMLHome.premBreak" | translate }}
                                    </h4>
                                </div>
                                <div class="sme-quotation-right-line"></div>
                                <br />
                                <div class="col-md-12">
                                    <ng-select [ngClass]="{
                            'red-border-class':
                                PaymentFormControl.paymentType.errors &&
                                (PaymentFormControl.paymentType
                                    .touched ||
                                    isSubmit)
                        }" placeholder="{{ 'MMLHome.crdT' | translate }}" formControlName="paymentType"
                                               [searchable]="true" class="input-form-design">
                                        <ng-option [value]="'VISA'"> VISA </ng-option>
                                        <ng-option [value]="'MASTER'">
                                            MASTER
                                        </ng-option>
                                        <ng-option [value]="'MADA'"> MADA </ng-option>
                                    </ng-select>
                                    <div *ngIf="
                            PaymentFormControl.paymentType.errors &&
                            (PaymentFormControl.paymentType.touched ||
                                isSubmit)
                        " style="color: #ff001f">
                                        <div *ngIf="
                                PaymentFormControl.paymentType.errors
                                    .required
                            ">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <br />
                                <div class="p-2 d-flex">
                                    <div class="col-8">{{ "MMLHome.PS1" | translate }}</div>
                                    <div class="ms-auto">
                                        <p style="
                    padding-left: 0px;
                    padding-right: 0px;
                    font-size: 18px;
                    font-weight: 600;
                    color: black;
                " [ngClass]="{
                    fs: this.translate.currentLang === 'en-US',
                    'fs-ar':
                        this.translate.currentLang === 'ar-SA'
                }">
                                            {{ formatter.format(this.premData?.totalPremWithoutVat)}}
                                        </p>
                                    </div>
                                </div>
                                <div class="p-2 d-flex">
                                    <div class="col-8"> {{ "MMLHome.PS2" | translate }}</div>
                                    <div class="ms-auto">
                                        <p style="
                    padding-left: 0px;
                    padding-right: 0px;
                    font-size: 18px;
                    font-weight: 600;
                    color: black;
                " [ngClass]="{
                    fs: this.translate.currentLang === 'en-US',
                    'fs-ar':
                        this.translate.currentLang === 'ar-SA'
                }">

                                            {{ formatter.format(this.premData?.totalVat)}}
                                        </p>
                                    </div>
                                </div>
                                <div class="p-2 d-flex">
                                    <div class="col-8">{{ "MMLHome.PS3" | translate }}</div>
                                    <div class="ms-auto">
                                        <p style="
                    padding-left: 0px;
                    padding-right: 0px;
                    font-size: 18px;
                    font-weight: 600;
                    color: black;
                " [ngClass]="{
                    fs: this.translate.currentLang === 'en-US',
                    'fs-ar':
                        this.translate.currentLang === 'ar-SA'
                }">
                                            {{ formatter.format(this.premData?.totalPremWithVat) }}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
