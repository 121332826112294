   <div mat-dialog-content>

    <div class="row">
        <div class="col-md-6" style="color: #1B7B2C; font-size: 14px;">
            <div class="form-outline mb-4">
                <label class="form-label" for="form2Example11">{{"LogIn.Name" | translate}} :</label>
                <input autocomplete="false" type="text" id="form2Example11"  formControlName="driverId" class="form-control" maxlength="10"
                value="{{infodetails.claimantnameen}}" disabled/>
            </div>
        </div>
            <div class="col-md-6" style="color: #1B7B2C; font-size: 14px;">
                <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example11">{{"LogIn.ClientID" | translate}} :</label>
                    <input autocomplete="false" type="text" id="form2Example11"  formControlName="driverId" class="form-control" maxlength="10"
                    value="{{infodetails.iD_NO}}" disabled/>
                </div>
            </div>
      </div>
    <div class="row">
        <div class="col-md-8" style="color: #1B7B2C; font-size: 14px;">
            <label class="form-label" for="form2Example11">{{"LogIn.Vehicle" | translate}}</label>
            <input autocomplete="false" type="text" id="form2Example11"  formControlName="driverId" class="form-control" maxlength="10"
            value="{{infodetails.vehiclemake}} / {{infodetails.vehiclemakear}} - {{infodetails.vehiclemodel}}" disabled/>

        </div>
        <div class="col-md-4" style="color: #1B7B2C; font-size: 14px;">
            <label class="form-label" for="form2Example11">{{"LogIn.Sequence" | translate}}</label>
            <input autocomplete="false" type="text" id="form2Example11"  formControlName="driverId" class="form-control" maxlength="10"
            value="{{infodetails.sequenceno}}" disabled/>

        </div>

      </div>
      <br/>
      <div class="row">
        <div class="col-md-4" style="color: #1B7B2C; font-size: 14px;">
            <label class="form-label" for="form2Example11">{{"LogIn.PlateNo" | translate}}</label>
            <input autocomplete="false" type="text" id="form2Example11"  formControlName="driverId" class="form-control" maxlength="10"
            value="{{infodetails.plateno}}" disabled/>
        </div>
        <div class="col-md-4" style="color: #1B7B2C; font-size: 14px;">
            <label class="form-label" for="form2Example11">{{"LogIn.PaymentAmt" | translate}}</label>
            <input autocomplete="false" type="text" id="form2Example11"  formControlName="driverId" class="form-control" maxlength="10"
            value=" {{infodetails.paymentamount}}" disabled/>

        </div>
        <div class="col-md-4" style="color: #1B7B2C; font-size: 14px;">
            <label class="form-label" for="form2Example11">{{"LogIn.PaymentDate" | translate}}</label>
            <input autocomplete="false" type="text" id="form2Example11"  formControlName="driverId" class="form-control" maxlength="10"
            value="{{infodetails.paymentdate}}" disabled/>

        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-12" style="color: #1B7B2C; font-size: 14px;">
            <label class="form-label" for="form2Example11">Iban</label>
            <input autocomplete="false" type="text" id="form2Example11"  formControlName="driverId" class="form-control" maxlength="10"
            value="{{infodetails.ibanno}}" disabled/>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-6" style="color: #1B7B2C; font-size: 14px;">
            <input autocomplete="false" type="text" id="form2Example11"  formControlName="driverId" class="form-control" maxlength="10"
            value="{{infodetails.claimstatus}}" disabled/>

        </div>
        <div class="col-md-6 text-center pt-1 mb-5 pb-1">
            <button class="btn btn-danger btn-rounded" style="float: right;cursor: pointer !important;" (click)="dialogRef.close()" type="submit">{{"LogIn.Close" | translate}}</button>
        </div>
    </div>

</div>


