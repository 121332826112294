import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';

@Component({
    selector: 'app-sme-quotation-details',
    templateUrl: './sme-quotation-details.component.html',
    styleUrls: ['./sme-quotation-details.component.scss'],
})
export class SmeQuotationDetailsComponent implements OnInit {
    @Input() CompanyDetails;
    @Input() PremiumData: any;
    @Input() QuotationNumber: any;
    @Output() onPayClickSubmit = new EventEmitter<any>();
    @Output() onQuotationSubmit = new EventEmitter<any>();

    @Input() myStepper: MatStepper;
    languageValue = localStorage.getItem('language');
    CompanyAddress: any;
    CompanyCCHI: any;
    quotaNo:string;
    isLoading = true;
    formatter:any;
    draftReportAr: string | URL;
    draftReportEn: string | URL;
    constructor(
        private smeService: MedicalSMEService,
        private _router: Router,
        public translate: TranslateService,
    ) {
        
        this.quotaNo=localStorage.getItem("quotationNumber");
        this.languageValue = localStorage.getItem('language');
        this.getDraftReport(this.quotaNo);
    }
    getDraftReport(quotaNo: string) {
        this.smeService.GetDraftReport(quotaNo).subscribe(res=>{
            if(res!=null && res!=undefined){
                this.draftReportAr = res.draftReport;
                this.draftReportEn = res.draftReport;
            }
        })
        
    }

    ngOnInit(): void {
        if (this.CompanyDetails !== null) {
            this.GetCompanyAddress();
            this.GetCompanyCCHI();
        }
        
        // Create our number formatter.
        this.formatter = new Intl.NumberFormat(this.languageValue, {
            style: 'currency',
            currency: 'SAR',
        
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
    }

    async GetCompanyAddress() {
        this.isLoading = true;
        this.smeService
            .GetCompanyAddressInfo(this.CompanyDetails.crEntityNumber)
            .subscribe(
                (res) => {
                    this.CompanyAddress = res;
                    this.isLoading = false;
                },
                (err) => {
                    this.isLoading = false;
                }
            );
    }

    async GetCompanyCCHI() {
        this.isLoading = true;
        this.smeService
            .GetSponsorDetails(this.CompanyDetails.crEntityNumber)
            .subscribe(
                (res) => {
                    this.CompanyCCHI = res;
                    this.isLoading = false;
                },
                (err) => {
                    this.isLoading = false;
                }
            );
    }

    goToPayment() {
        let resultObj = {
            tabIndex: 3,
            PremData: this.PremiumData,
            CompanyDetails: this.CompanyDetails,
            CompanyAddress: this.CompanyAddress,
        };

        this.onPayClickSubmit.emit(resultObj);
        // this._router.navigateByUrl('/sme-payment', {
        //     state: {
        //         PremData: this.PremiumData.premData,
        //         CompanyDetails: this.CompanyDetails,
        //         CompanyAddress: this.CompanyAddress
        //     },
        // });
    }

    getBackToMemberDetails() {
        localStorage.setItem("backToMembers", "true");
        let quotationNumber = localStorage.getItem('quotationNumber');
        this.onQuotationSubmit.emit(quotationNumber);
    }

    goToPaymentStep() {
        let resultObj = {
            tabIndex: 3,
            PremData: this.PremiumData,
            CompanyDetails: this.CompanyDetails,
            CompanyAddress: this.CompanyAddress,
        };
        localStorage.removeItem('PremData');
        localStorage.removeItem('CompanyDetails');
        localStorage.removeItem('CompanyAddress');

        localStorage.setItem('PremData',JSON.stringify(this.PremiumData));
        localStorage.setItem('CompanyDetails',JSON.stringify(this.CompanyDetails));
        localStorage.setItem('CompanyAddress',JSON.stringify(this.CompanyAddress?.addresses[0]));
        this.myStepper.next()
    }

    download(input) {
     
        /////////////////////////
      if (input === 'draftReportAr') {
            window.open(this.draftReportAr, '_blank');
        }
        else if (input === 'draftReportEn') {
            window.open(this.draftReportEn, '_blank');
        }
        ////////////////////////////

       
    }
}
