import { Component } from '@angular/core';

@Component({
  selector: 'app-vehicle-details-info',
  templateUrl: './vehicle-details-info.component.html',
  styleUrls: ['./vehicle-details-info.component.scss']
})
export class VehicleDetailsInfoComponent {
  lang = localStorage.getItem('language');

}
