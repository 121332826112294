<div style="padding: 12px">
    <div class="row">
        <button class="addvehicle">{{ "motor.AddAnotherVehicle" | translate }}</button>
    </div>
    <div class="motor-form-container row">
        <div class="custom-stepper-content-form">
            <h3>{{ "motor.Vehicles Details" | translate }}</h3>
            <div class="roundbuttoncontainer">
                <button
                    type="button"
                    class="btn btn-outline-success btn-floating edit"
                    data-mdb-ripple-init
                    data-mdb-ripple-color="dark"
                ></button>
                <button
                    type="button"
                    class="btn btn-outline-success btn-floating trash"
                    data-mdb-ripple-init
                    data-mdb-ripple-color="dark"
                ></button>
            </div>
            <div class="btn-group-toggle-container"></div>

            <div class="row">
                <div class="carrearview col-12">
                    <div class="plateimg">
                        <img
                            src="../../../../../../assets/img/motor-pages/licenseplate.png"
                            width="80px"
                        />
                    </div>
                </div>
                <div class="cardetails col-12">
                    <div class="row">
                        <div class="column col-xl-6 col-12">
                            <div class="carcontent">
                                <span
                                    [ngClass]="{
                                        cardetailtitle: lang === 'en-US',
                                        'cardetailtitle-ar': lang === 'ar-SA'
                                    }"
                                    >{{ "motor.VehicleMake" | translate }}</span
                                >
                                <div
                                    [ngClass]="{
                                        cardetailcontent: lang === 'en-US',
                                        'cardetailcontent-ar': lang === 'ar-SA'
                                    }"
                                >
                                    <img
                                        src="../../../../../../assets/img/motor-pages/hyundai-logo-2011-download 1.png"
                                    />
                                </div>
                            </div>
                            <div class="carcontent">
                                <span
                                    [ngClass]="{
                                        cardetailtitle: lang === 'en-US',
                                        'cardetailtitle-ar': lang === 'ar-SA'
                                    }"
                                    >{{ "motor.VehicleValue" | translate }}</span
                                >
                                <div
                                    [ngClass]="{
                                        cardetailcontent: lang === 'en-US',
                                        'cardetailcontent-ar': lang === 'ar-SA'
                                    }"
                                >
                                    56400 SAR
                                </div>
                            </div>
                            <div class="carcontent">
                                <span
                                    [ngClass]="{
                                        cardetailtitle: lang === 'en-US',
                                        'cardetailtitle-ar': lang === 'ar-SA'
                                    }"
                                    >{{ "motor.MajorColor" | translate }}</span
                                >
                                <div
                                    [ngClass]="{
                                        cardetailcontent: lang === 'en-US',
                                        'cardetailcontent-ar': lang === 'ar-SA'
                                    }"
                                >
                                    Black
                                </div>
                            </div>
                        </div>
                        <div class="column col-xl-6 col-12">
                            <div class="carcontent">
                                <span
                                    [ngClass]="{
                                        cardetailtitle: lang === 'en-US',
                                        'cardetailtitle-ar': lang === 'ar-SA'
                                    }"
                                    >{{ "motor.VehicleModel" | translate }}</span
                                >
                                <div
                                    [ngClass]="{
                                        cardetailcontent: lang === 'en-US',
                                        'cardetailcontent-ar': lang === 'ar-SA'
                                    }"
                                >
                                    Tuscan
                                </div>
                            </div>
                            <div class="carcontent">
                                <span
                                    [ngClass]="{
                                        cardetailtitle: lang === 'en-US',
                                        'cardetailtitle-ar': lang === 'ar-SA'
                                    }"
                                    >{{ "motor.ModelYear" | translate }}</span
                                >
                                <div
                                    [ngClass]="{
                                        cardetailcontent: lang === 'en-US',
                                        'cardetailcontent-ar': lang === 'ar-SA'
                                    }"
                                >
                                    2020
                                </div>
                            </div>
                            <div class="carcontent">
                                <span
                                    [ngClass]="{
                                        cardetailtitle: lang === 'en-US',
                                        'cardetailtitle-ar': lang === 'ar-SA'
                                    }"
                                    >{{ "motor.MajorColor" | translate }}</span
                                >
                                <div
                                    [ngClass]="{
                                        cardetailcontent: lang === 'en-US',
                                        'cardetailcontent-ar': lang === 'ar-SA'
                                    }"
                                >
                                    Black
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
