import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-motor-stepper',
    templateUrl: './motor-stepper.component.html',
    styleUrls: ['./motor-stepper.component.scss'],
})
export class MotorStepperComponent {
    stepNumber = 1;
    lang = localStorage.getItem('language');
    VehicleDetailsNumber = 1;

    constructor() {}

    moveToRed() {
        const car: HTMLElement = document.getElementById('car-icon');
        car.className =
            this.lang === 'en-US' ? 'move-to-red-en' : 'move-to-red-ar';
        this.stepNumber = 1;
        this.scrollTop();
    }

    moveToYellow() {
        const car: HTMLElement = document.getElementById('car-icon');
        car.className =
            this.lang === 'en-US' ? 'move-to-yellow-en' : 'move-to-yellow-ar';
        this.stepNumber = 2;
        this.scrollTop();
    }

    moveToGreen() {
        const car: HTMLElement = document.getElementById('car-icon');
        car.className =
            this.lang === 'en-US' ? 'move-to-green-en' : 'move-to-green-ar';
        this.stepNumber = 3;
        this.scrollTop();
    }

    back() {
        if (this.stepNumber == 2) {
            this.VehicleDetailsNumber = 1;
            this.moveToRed();
        } else if (this.stepNumber == 3) this.moveToYellow();
        this.scrollTop();
    }

    next() {
        if (this.stepNumber == 1) this.moveToYellow();
        else if (this.stepNumber == 2) this.moveToGreen();
        this.scrollTop();
    }

    nextVehicleDetails() {
        if (this.VehicleDetailsNumber == 2) {
            this.VehicleDetailsNumber += 1;
            this.moveToYellow();
        } else {
            this.VehicleDetailsNumber += 1;
        }
        this.scrollTop();
    }
    backVehicleDetails() {
        this.VehicleDetailsNumber -= 1;
        this.scrollTop();
    }

    pay() {
        this.scrollTop();
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }
}
