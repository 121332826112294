import { Injectable, NgModule } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpHeaders,
    HttpResponse,
    HttpErrorResponse,
    HttpClient,
} from '@angular/common/http';
import { LocalService } from './core/services/LocalService/LocalService.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import { UserService } from './core/services/UserService/user.service';
import { AppConfigService } from './core/AppConfigService';

@Injectable()
export class HttpConfigInterceptorInterceptor implements HttpInterceptor {
    OTPValue: any;
    auth_member: any;
    RefreshTokenURL: string;
    constructor(
        private http: HttpClient,
        private _LocalService: LocalService,
        private environment: AppConfigService,

    ) {

        this.RefreshTokenURL = environment?.config?.servicesBaseUrl + 'User/'+ 'RefreshToken';
        
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = this._LocalService.getJsonValue('userLoginInfo')?.token;
        this.auth_member = this._LocalService.getJsonValue('userLoginInfo');
        if (token) {
            let x = new Date().toUTCString();
            let nowDate = new Date(x).getTime();
            let TokenEX =
                this._LocalService.getJsonValue(
                    'userLoginInfo'
                )?.tokenExpirationDate;
            let refreshTokenEX =
                this._LocalService.getJsonValue(
                    'userLoginInfo'
                )?.refreshTokenExpirationDate;

            let TokenEXUTC = new Date(TokenEX).getTime();
            let refreshTokenEXUTC = new Date(refreshTokenEX).getTime();

            if (nowDate <= TokenEXUTC && nowDate <= refreshTokenEXUTC) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } else if (nowDate > TokenEXUTC && nowDate <= refreshTokenEXUTC) {
                this.getnewToken(this.auth_member.refreshToken);
                let newToken =
                    this._LocalService.getJsonValue('userLoginInfo')?.token;
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${newToken}`,
                    },
                });
            } else if (nowDate > TokenEXUTC && nowDate > refreshTokenEXUTC) {
                this.logout();
            }
            // add token to headers
            request = request.clone({
                headers: request.headers.set('Accept', 'application/json'),
            });
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.logout();
                }
                const error = err.error || err.statusText;
                return throwError(error);
            })
        );
    }
    async getnewToken(refresToken: any) {
        this.RefreshTokenURL = this.environment?.config?.servicesBaseUrl + 'User/'+ 'RefreshToken';

        let queryString = {'refreshToken' :  refresToken};
        await this.RefreshToken(queryString).subscribe(async (res) => {
            if (res?.loginData) {
                this._LocalService.setJsonValue(
                    'userLoginInfo',
                    res?.loginData
                );
            }
        },
        (err) => {
            this.logout();
        }
        );
    }
    logout() {
        this._LocalService.setJsonValue('userVerified', null);
        this._LocalService.setJsonValue('userLoginInfo', null);
        this._LocalService.setJsonValue('memberDetails', null);
        window.location.href = '/user-login';
    }
    RefreshToken(queryString): Observable<any> {
        
        return this.http.post<any>(this.RefreshTokenURL, queryString);
    }
}
