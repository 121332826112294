<div class="">
    <loading *ngIf="isLoading">{{ isLoading }}</loading>

    <div class="row white">
        <div class="col-lg-6 col-md-6 col-sm-12 square">
            <h4>{{ "LogIn.dear" | translate }}</h4>
            <hr class="horizontal-line" />
            <ul style="text-align: justify">
                <li>
                    {{ "LogIn.des1" | translate }}
                </li>
                <li>
                    {{ "LogIn.des2" | translate }}
                </li>
                <li>
                    {{ "LogIn.des3" | translate }}
                </li>
                <li>
                    {{ "LogIn.des4" | translate }}
                </li>
            </ul>
        </div>
        <form [formGroup]="loginForm" class="col-lg-6 col-md-6 col-sm-12">
            <div class="login-form-container">
                <div>
                    <i
                        class="fa fa-user-circle fa-4x"
                        style="color: #d4ab36"
                        aria-hidden="true"
                    ></i>
                </div>
                <div class="input-group mb-3 mt-5">
                    <div style="width: 100%" class="d-flex">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="fa fa-id-card-o" aria-hidden="true"></i>
                        </span>
                        <input
                            #LOGIN
                            type="text"
                            class="form-control"
                            placeholder="{{ 'LogIn.ID' | translate }}"
                            aria-describedby="basic-addon1"
                            type="number"
                            formControlName="memberid"
                            class="form-control input-form-design"
                            maxlength="10"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            (keyup.enter)="logIn($event)"
                        />
                    </div>
                    <div
                        *ngIf="
                            newloginFormFromControl.memberid.errors &&
                            newloginFormFromControl.memberid.touched &&
                            loginForm.controls['memberid'].value.length > 0
                        "
                        style="
                            color: #ff001f;
                            font-weight: bold;
                            font-size: 14px;
                        "
                    >
                        <div>
                            <i
                                class="fa fa-exclamation-circle"
                                aria-hidden="true"
                            ></i>
                            {{ "GENERIC.id" | translate }}
                        </div>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <div style="width: 100%" class="d-flex">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="bx bx-phone-call phone-icon"></i>
                        </span>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="{{ 'LogIn.phone' | translate }}"
                            aria-describedby="basic-addon1"
                            type="number"
                            formControlName="mobNumber"
                            class="form-control input-form-design"
                            pattern="\d*"
                            maxlength="10"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            (keyup.enter)="logIn($event)"
                        />
                    </div>

                    <div
                        *ngIf="
                            newloginFormFromControl.mobNumber.errors &&
                            newloginFormFromControl.mobNumber.touched &&
                            loginForm.controls['mobNumber'].value.length > 0
                        "
                        style="
                            color: #ff001f;
                            font-weight: bold;
                            font-size: 14px;
                        "
                    >
                        <div>
                            <i
                                class="fa fa-exclamation-circle"
                                aria-hidden="true"
                            ></i>
                            {{ "GENERIC.PN" | translate }}
                        </div>
                    </div>
                </div>

                <div class="input-group mb-3" style="width: 150px">
                    <button
                        type="button"
                        class="btn form-control login-btn"
                        (click)="logIn($event)"
                        (keyup.enter)="logIn($event)"
                        [disabled]="disable"
                    >
                        {{ "NAV.LOGIN" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- 
<form [formGroup]="loginForm" class="login-form-container">   
    <mat-form-field appearance="outline">
        <mat-label>Owner National ID</mat-label>
        <input matInput placeholder="Placeholder" formControlName="nationalId">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-error *ngIf="this.loginForm.controls['nationalId'].invalid">
            A comment is required.</mat-error>
      </mat-form-field>
    
        <button mat-button  cdkFocusInitial> Send
        </button> 
</form> -->
