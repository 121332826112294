import { HttpHeaders } from '@angular/common/http';
import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subject } from 'rxjs';
import { OTPService } from 'src/app/core/services/eservice-otp.service';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { UserService } from 'src/app/core/services/UserService/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-otp-verification-dialog',
  templateUrl: './otp-verification-dialog.component.html',
  styleUrls: ['./otp-verification-dialog.component.scss']
})
export class OtpVerificationDialogComponent {

  lnk = localStorage.getItem('navLink');
  display: string;
  seconds: number;
  disableBTN = false;
  memberID;
  memberDetails: any;
  selectedMember: any;
  auth_member: any;

  private unsubscriber: Subject<void> = new Subject<void>();

  @HostListener('document:keyup.enter', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this.navigate();
  }
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  ValidOTP = false;
  OTPValue: any;
  phoneNumber = this._LocalService.getJsonValue('mobileNumber');
  OTPDetails: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _LocalService: LocalService,
    public medicalClaimsService: MedicalClaimsService,
    public translate: TranslateService,
    public user: UserService,
    public router: Router,
    public otpService: OTPService,
    public dialogRef: MatDialogRef<OtpVerificationDialogComponent>,
  ) {
    this.lnk = this._LocalService.getJsonValue('navLink');
    this.memberID = this._LocalService.getJsonValue('memberID');
    this.phoneNumber = this._LocalService.getJsonValue('mobileNumber');
    this.memberDetails = null;
    this.OTPValue = null;
    this.OTPDetails = data.otpDetails;
    this.selectedMember = {
      id: '',
      policyNumber: '',
      policyHolder: '',
      policyExpiryData: '',
      memberCode: '',
      memberEName: '',
      gender: '',
      birthDate: '',
      className: '',
      relation: '',
    };
    this.auth_member = this._LocalService.getJsonValue('userLoginInfo');
  }


  ngOnInit(): void {
    this._LocalService.setJsonValue('isEligableToAccessOTP', false);
    this.timer(2);
    if (this._LocalService.getJsonValue('navLink') !== null) {
      this.lnk = this._LocalService.getJsonValue('navLink');
    } else {
      this.lnk = '';
    }
  }
  async navigate() {
    if (this.ValidOTP) {
      await this.OtpVerification();
    }
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);

  }

  timer(minute) {
    // let minute = 1;
    let textSec: any = '0';
    let statSec: number = 60;
    this.seconds = minute * 60;
    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      this.seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(
        this.seconds / 60
      )}:${textSec}`;

      if (this.seconds == 0) {
        this.disableBTN = true;
        clearInterval(timer);
      }
    }, 1000);
  }
  async OtpVerification() {
    let verifyOTPObject = {
      otpCode: this.OTPValue,
      IqamaId: this.OTPDetails.data.data.iqamaId
    }

    this.otpService.verifyOTP(verifyOTPObject).subscribe(
      async (res) => {
        if (res != null && res) {
          this.dialogRef.close({ verified: true });
        } else {
          let dir: string = '';
          if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
          } else {
            dir = 'rtl';
          }
          Swal.fire({
            title:
              this.translate.currentLang == 'en-US'
                ? 'Error!'
                : '!خطأ',
            iconHtml:
              '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
              "<div style='color:#FF001F !important; direction:" +
                dir +
                "'>" +
                this.translate.currentLang ==
                'en-US'
                ? 'Invalid Verification Code!'
                : '!رمز التحقق غير صحيح' + '</div>',
            confirmButtonText:
              this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
              icon: 'submit-claim-failed-icon-img',
              title: 'submit-claim-failed-swal-title',
              confirmButton: 'submit-claim-failed-confirm-btn',
            },
          });
        }
      },
      async (err) => {
        let dir: string = '';

        if (this.translate.currentLang == 'en-US') {
          dir = 'ltr';
        } else {
          dir = 'rtl';
        }
        Swal.fire({
          title:
            this.translate.currentLang == 'en-US'
              ? 'Error!'
              : '!خطأ',
          iconHtml:
            '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
          html:
            "<div style='color:#FF001F !important; direction:" +
              dir +
              "'>" +
              this.translate.currentLang ==
              'en-US'
              ? 'Invalid Verification Code!'
              : '!رمز التحقق غير صحيح' + '</div>',
          confirmButtonText:
            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
          customClass: {
            icon: 'submit-claim-failed-icon-img',
            title: 'submit-claim-failed-swal-title',
            confirmButton: 'submit-claim-failed-confirm-btn',
          },
        });
      }
    );
  }

  async GetUserMedicalProfile() {
    let queryString = 'MemberId=' + this.memberID;
    this.medicalClaimsService
      .MemberDetails(queryString)
      .subscribe((res) => {
        this.memberDetails = res;
        this._LocalService.setJsonValue('memberDetails', res?.data);
        if (res?.data.length > 0) {
          this.selectedMember = res?.data[0];
        }
      });
  }

  handeOtpChange(value: string[]): void {
    this.ValidOTP = false;
  }

  handleFillEvent(value: string): void {
    this.ValidOTP = true;
    this.OTPValue = value;
  }
  async resendCode() {
    await this.login();
  }

  async login() {

    let resendOTPObject = {
      IqamaId: this.OTPDetails.data.data.iqamaId,
      MobileNumber: this.OTPDetails.data.data.mobileNumber,
      isWeb: true,
      Lob: this.OTPDetails.data.data.lob,
      ActionType: this.OTPDetails.data.data.type
    };

    this.otpService.sendOTP(resendOTPObject).subscribe(
      async (res) => {
        debugger;
        if (res.errors.length <= 0) {
          this.timer(2);
          this.OTPValue = '';

        }
      },
      (err) => {
        let dir: string = '';

        if (this.translate.currentLang == 'en-US') {
          dir = 'ltr';
        } else {
          dir = 'rtl';
        }
        Swal.fire({

        });
      }
    );
  }

  async getnewToken(refresToken) {
    let queryString = { 'refreshToken': refresToken };
    this.user.RefreshToken(queryString).subscribe(async (res) => {
      if (res?.loginData) {
        this._LocalService.setJsonValue(
          'userLoginInfo',
          res?.loginData
        );
      }
    });
  }
  ngOnDestroy(): void {
    this._LocalService.setJsonValue('isEligableToAccessOTP', false);
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
