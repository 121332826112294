<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container-fluid mt-5 mb-5">
    <!-- quotation info -->
    <div class="row mt-5">
        <div class="sme-quotation-left-line"></div>
        <div class=""
            [ngClass]="{
            'sme-quotation-info-details-container-ar': languageValue != 'en-US', 'sme-quotation-info-details-container-en': languageValue === 'en-US' }">
            <h4 class="sme-quotation-details">
                {{ "SME.QUOTATIONINFO" | translate }}
            </h4>
        </div>
        <div class="sme-quotation-right-line"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.QUOTATIONNO" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{quotaNo ?? 0}}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- sponsor -->
    <div class="row mt-5">
        <div class="sme-quotation-left-line"></div>
        <div
            [ngClass]="{
            'sme-quotation-info-details-container-ar': languageValue != 'en-US', 'sme-quotation-sponsor-details-container-en': languageValue === 'en-US' }">
            <h4 class="sme-quotation-details">
                {{ "SME.SPONSORINFO" | translate }}
            </h4>
        </div>
        <div class="sme-quotation-right-line"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.SPONSORID" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyDetails.crEntityNumber }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.SPONSORNAME" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyDetails.crName }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.SPONSORMOBILE" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyDetails.crNumber }}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- address -->
    <div class="row mt-5">
        <div class="sme-quotation-left-line"></div>
        <div
            [ngClass]="{
            'sme-quotation-info-details-container-ar': languageValue != 'en-US', 'sme-quotation-address-details-container-en': languageValue === 'en-US' }">
            <h4 class="sme-quotation-details">
                {{ "SME.SPONSORADDRESS" | translate }}
            </h4>
        </div>
        <div class="sme-quotation-right-line"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.CITYCODE" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyAddress?.addresses[0]?.city }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.ADDNUMBER" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{
                        CompanyAddress?.addresses[0]?.additionalNumber
                        }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.POSTALCODE" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyAddress?.addresses[0]?.postCode }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.BULNUMBER" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{
                        CompanyAddress?.addresses[0]?.buildingNumber
                        }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.DISTRICT" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{ CompanyAddress?.addresses[0]?.district }}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label class="sme-quotation-label">{{
                    "SME.STREET" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{
                        CompanyAddress?.addresses[0]?.streetName
                        }}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- breakdown -->
    <div class="row mt-5">
        <div class="sme-quotation-left-line"></div>
        <div
            [ngClass]="{
            'sme-quotation-info-details-container-ar': languageValue != 'en-US', 'sme-quotation-cchi-details-container-en': languageValue === 'en-US' }">
            <h4 class="sme-quotation-details">
                {{ "MMLHome.premBreak" | translate }}
            </h4>
        </div>
        <div class="sme-quotation-right-line"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label [ngClass]="{
                    'sme-quotation-label': languageValue != 'en-US',
                     'sme-quotation-label-en': languageValue === 'en-US' }">{{
                    "MMLHome.PS1" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label> {{formatter.format(this.PremiumData?.totalPremWithoutVat)}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <div class="sme-form-control">
                <label [ngClass]="{
                    'sme-quotation-label': languageValue != 'en-US',
                     'sme-quotation-label-en': languageValue === 'en-US' }">{{
                    "MMLHome.PS2" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label>{{formatter.format(this.PremiumData?.totalVat)}}</label>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="sme-form-control">
                <label [ngClass]="{
                    'sme-quotation-label': languageValue != 'en-US',
                     'sme-quotation-label-en': languageValue === 'en-US' }">{{
                    "MMLHome.PS3" | translate
                    }}</label>
                <div class="sme-quoation-text">
                    <label> {{formatter.format(this.PremiumData?.totalPremWithVat)}}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- btns -->
    <div class="row sme-next-btn-container mb-5 mt-5">
        <button class="sme-back-btn" mat-button (click)="getBackToMemberDetails()">
            {{ "SME.back" | translate }}
        </button>
        <button class="sme-next-btn" mat-button (click)="goToPaymentStep()" id="payment-btn">
            {{ "SME.PAY" | translate }}
        </button>
        <button class="sme-next-btn " style="margin-right: 3% ;" (click)="download('draftReportAr')" *ngIf="translate.currentLang != 'en-US'">
            عرض السعر المبدئي
            <!-- {{ "MMLHome.report" | translate }} -->
        </button>

        <button class="sme-next-btn" style="margin-left: 3% ;" (click)="download('draftReportEn')" *ngIf="translate.currentLang == 'en-US'">
            Draft Report<!-- {{ "MMLHome.report" | translate }} -->
        </button>
    </div>
</div>