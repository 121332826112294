import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';
import { MMPClaim, MMPClaimdocs } from 'src/app/core/interfaces/MMPClaim';
import { OTPService } from 'src/app/core/services/eservice-otp.service';
import { MmpService } from 'src/app/core/services/MMP-Service/mmp.service';
import Swal from 'sweetalert2';
import { OtpVerificationDialogComponent } from '../../../OTPVerification/otp-verification-dialog/otp-verification-dialog.component';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';

@Component({
  selector: 'app-new-mmp-cash-claim',
  templateUrl: './new-mmp-cash-claim.component.html',
  styleUrls: ['./new-mmp-cash-claim.component.scss']
})
export class NewMmpCashClaimComponent implements OnInit {

  NewMMPClaimFrom: FormGroup;
  RequiredFiles: any;
  mandatoryDocs: any = [];
  today: Date;
  isLoading: boolean;
  isSaveFrom: boolean = false;
  isSaved: boolean = false;
  referenceNumber: string;
  uploadedImgsList: any[] = [];
  selectedCauseOfLoss: any;
  CauseOfLossList:any[] = [];
  ImgsId: any[] = [];
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  
  constructor(public translate: TranslateService,
    public fb: FormBuilder, private mmpService: MmpService, public _router: Router,
    private otpService: OTPService,
    private _dialog: MatDialog,
    private _LocalService: LocalService) { }

  ngOnInit(): void {
    this.today = new Date();

    this.NewMMPClaimFrom = this.fb.group({
      iqamaId: [null, [Validators.required, Validators.pattern(/^\d+$/), Validators.minLength(10), Validators.maxLength(10)]],
      dateOfLoss: [null, Validators.required],
      causeOfLoss: [null, Validators.required],
      lossDescription: [null, [Validators.required]]
    });
    this.GetRequiredDocuments();
    this.GetCauseOfLosses();
  }

  get newMMPClaimFromControl() {
    return this.NewMMPClaimFrom.controls;
  }

  GetRequiredDocuments() {
    this.mmpService.GetCashClaimMandatoryFiles().subscribe((res) => {
      this.RequiredFiles = res;
      for (let index = 0; index < res.length; index++) {
        if (res[index]['isMandatory'] === true)
          this.mandatoryDocs.push(res[index]['id']);
      }
    });
  }

  GetCauseOfLosses() {
    this.mmpService.GetMMPCauseOfLosses().subscribe((res) => {
      this.CauseOfLossList = res;
    });
  }

  setDocsList(event, id, man) {
    for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const fileName = file.name;
        this.uploadedImgsList.push({
            image: file,
            id: id,
            name: fileName,
            mandatory: man,
        })
        if (!this.ImgsId.includes(id)) {
            this.ImgsId.push(id);
        }
        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = () => {
        //     this.nationalImgsList.push({
        //         image: reader.result,
        //         id: id,
        //         name: fileName,
        //         mandatory: man,
        //     });
        //     if (!this.ImgsId.includes(id)) {
        //         this.ImgsId.push(id);
        //     }
        // };
    }
    this.myInputVariable.nativeElement.value = '';
}

  deleteAtt(x) {
    this.uploadedImgsList.splice(x, 1);
  }

  DisplayDocument(img: any) {
    Swal.fire({
        title: img.name,
        showCloseButton: true,
        showConfirmButton: false,
        imageUrl: img.image,
        imageHeight: 300,
        imageAlt: 'A tall image',
    });
}

SendOTPAndOpenVerificationDialog() {
  let otpObject = {
    ActionType: SMSActionType.IssueClaim,
    Lob: LineOfBusiness.Medical,
    IqamaId: this._LocalService.getJsonValue('LoggedInIqamaId'),
    MobileNumber: this._LocalService.getJsonValue('LoggedInMobileNo'),
    isWeb: true
  }

  this.isLoading = true;
  this.otpService.sendOTP(otpObject).subscribe((res) => {
    let result = res;
    if (result.errors.length <= 0) {
      const dialogRef = this._dialog.open(OtpVerificationDialogComponent, {
        data: { otpDetails: result },
        width: '600px',
        disableClose:true
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (data.verified) {
          this.buildSaveModel();
        }
      });
    }
  });
}

  submitMMPCashClaim() {
    this.isLoading = true;
    let dir: string = '';
    if (this.translate.currentLang == 'en-US') {
      dir = 'ltr';
    } else {
      dir = 'rtl';
    }
    if (
      this.NewMMPClaimFrom.valid &&
      this.docsValidation()
    ) {
      this.SendOTPAndOpenVerificationDialog();
    } else {
      this.isLoading = false;
      let index = 1;

      let errorMessage: string = '';
      if (this.NewMMPClaimFrom.controls['iqamaId'].value == null) {
        errorMessage +=
          this.translate.currentLang == 'en-US'
            ? index + '- Please Enter The Iqama ID !<br/>'
            : index + '- برحاء ادخال رقم الهوية !<br/>';
        index++;
      }

      if (this.NewMMPClaimFrom.controls['dateOfLoss'].value == null) {
        errorMessage +=
          this.translate.currentLang == 'en-US'
            ? index + '- Please Enter Date of Loss !<br/>'
            : index + '- برحاء ادخال تاريخ الخطأ الطبي !<br/>';
        index++;
      } else if (
        this.NewMMPClaimFrom.controls['dateOfLoss'].status ===
        'INVALID'
      ) {
        errorMessage +=
          this.translate.currentLang == 'en-US'
            ? index + '- Please Enter Vaild Date !<br/>'
            : index + '- برحاء ادخال تاريخ صحيح!<br/>';
        index++;
      }

      if (this.NewMMPClaimFrom.controls['causeOfLoss'].value === null ||
      this.NewMMPClaimFrom.controls['causeOfLoss'].value === undefined) {
        errorMessage +=
          this.translate.currentLang == 'en-US'
            ? index + '- Please Select The Cause of Loss !<br/>'
            : index + '- برحاء ادخال سبب الخطأ الطبي  !<br/>';
        index++;
      }

      if (this.NewMMPClaimFrom.controls['lossDescription'].value == null) {
        errorMessage +=
          this.translate.currentLang == 'en-US'
            ? index + '- Please Enter The Loss Description !<br/>'
            : index + '- برحاء ادخال وصف الخطأ الطبي !<br/>';
        index++;
      }
      
      if (!this.docsValidation()) {
        errorMessage +=
          this.translate.currentLang == 'en-US'
            ? index + '- Please Upload the required files  !<br/>'
            : index + '- الرجاء تحميل الملفات المطلوبة!<br/>';
        index++;
      }
      
      Swal.fire({
        title:
          this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
        iconHtml:
          '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
        html:
          "<div style='color:#FF001F; direction:" +
          dir +
          "'>" +
          errorMessage +
          '</div>',
        confirmButtonText:
          this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
        customClass: {
          icon: 'submit-claim-failed-icon-img',
          title: 'submit-claim-failed-swal-title',
          confirmButton: 'submit-claim-failed-confirm-btn',
        },
      });
    }
  }

  buildSaveModel() {
    this.isLoading = true;

    let claimsModel: MMPClaim = new (class implements MMPClaim {
      IqamaId: string; //done
      LossDate: string; //done
      CauseOfLoss: string;
      Description: string; //done
      Files: Array<MMPClaimdocs>;
    })();

    claimsModel.IqamaId =
      this.NewMMPClaimFrom.value['iqamaId'];
    claimsModel.LossDate = this.format(
      this.NewMMPClaimFrom.value['dateOfLoss'],
      1
    );
    claimsModel.CauseOfLoss = this.NewMMPClaimFrom.value['causeOfLoss'];
    claimsModel.Description = this.NewMMPClaimFrom.value['lossDescription'];

    if (this.uploadedImgsList != null && this.uploadedImgsList.length) {
      let finalList: any = [];

      for (let i = 0; i < this.uploadedImgsList.length; i++) {
        let list = {
          CS_DOC_TYPE: '',
          DOC: File,
          DOC_IS_MANDATORY: '',
          FILE_NAME:''
        };
        let item = this.uploadedImgsList[i];

        list.CS_DOC_TYPE = item.id.toString();
        list.DOC = item.image;
        list.DOC_IS_MANDATORY = item.mandatory ? 'true' : 'false';
        list.FILE_NAME = item.name;
        
        finalList.push(list);
      }
      claimsModel.Files = finalList;
    }

    const formData = new FormData();

    formData.append('IqamaId', claimsModel.IqamaId);
    formData.append('LossDate', claimsModel.LossDate);
    formData.append('CauseOfLoss', claimsModel.CauseOfLoss);
    formData.append('Description', claimsModel.Description);

    claimsModel.Files.forEach((element, index) => {
      formData.append(`Files[${index}][file]`, element.DOC);
      formData.append(`Files[${index}][isMandatory]`, element.DOC_IS_MANDATORY);
      formData.append(`Files[${index}][lookupDetailsCode]`, element.CS_DOC_TYPE);
      formData.append(`Files[${index}][fileName]`, element.FILE_NAME);
    });


    this.mmpService.SubmitNewMMPCashClaim(formData).subscribe(
      (res) => {
        if (res) {
          
          this.referenceNumber = res.claimNumber;
          this.isSaved = true;
          this.isLoading = false;

          Swal.fire({
            title:
              this.translate.currentLang == 'en-US'
                ? 'Success'
                : 'نجحت المطالبة',
            iconHtml:
              '<img width=40 height=40 style="margin-top:10px;" src="assets/img/swal-icons-img/check.png">',
            html:
              this.translate.currentLang == 'en-US'
                ? 'Your reference number is ' +
                "<span style='color:#D4AB36;font-weight: bold;padding: 0% 3%;'>" +
                this.referenceNumber +
                '</span>'
                : "<span style='color:#D4AB36;font-weight: bold;padding: 0% 3%;'>" +
                this.referenceNumber +
                '</span>' +
                'الرقم المرجعي للمطالبة هو ',
            confirmButtonText:
              this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
              icon: 'submit-claim-success-icon-img',
              title: 'submit-claim-success-swal-title',
              confirmButton: 'submit-claim-success-confirm-btn',
            },
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this._router.navigate(['/mmp-cash-claims']);
            }
          });
        }
        this.isLoading = false;
      },
      (error) => {
        this.isSaved = false;
        this.isSaveFrom = false;
        this.isLoading = false;
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
          dir = 'ltr';
        } else {
          dir = 'rtl';
        }

        if (error.messages != null && error.messages.length > 0) {
          Swal.fire({
            title:
              this.translate.currentLang == 'en-US'
                ? 'Error!'
                : '!خطأ',
            iconHtml:
              '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
              "<div style='color:#FF001F; direction:" +
              dir +
              "'>" +
              error.messages +
              '</div>',
            confirmButtonText:
              this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
              icon: 'submit-claim-failed-icon-img',
              title: 'submit-claim-failed-swal-title',
              confirmButton: 'submit-claim-failed-confirm-btn',
            },
          });
        } else if (error.message) {
          Swal.fire({
            title:
              this.translate.currentLang == 'en-US'
                ? 'Error!'
                : '!خطأ',
            iconHtml:
              '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
              "<div style='color:#FF001F; direction:" +
              dir +
              "'>" +
              error.message +
              '</div>',
            confirmButtonText:
              this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
              icon: 'submit-claim-failed-icon-img',
              title: 'submit-claim-failed-swal-title',
              confirmButton: 'submit-claim-failed-confirm-btn',
            },
          });
        }
      }
    );
  }
  docsValidation() {
    let manLength = this.mandatoryDocs.length;
    let count = 0;
    for (let i = 0; i < this.uploadedImgsList.length; i++) {
      if (this.uploadedImgsList[i]['id'] === this.mandatoryDocs[0]) {
        count++;
        this.mandatoryDocs.splice(0, 1);
      }
    }
    if (manLength === count) return true;

    return false;
  }

  format(inputDate, mnth) {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + mnth;
    year = inputDate.getFullYear();
    if (month === 13) {
      month = 1;
    }
    date = date.toString().padStart(2, '0');

    month = month.toString().padStart(2, '0');

    return `${year}-${month}-${date}T00:00:00`;
  }
}
