<div [ngClass]="{ 'dir-en': this.translate.currentLang === 'en-US', 'dir-ar': this.translate.currentLang === 'ar-SA' }">
    <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
            <div class="health-dialog-header-container">
                <span>{{ "SME.SELECTCLASS" | translate }}</span>
                <div class="sme-health-bordered"></div>
            </div>

            <img src="assets/img/medical-sme/dialog-close.png" width="30px" mat-dialog-close />
        </div>
    </div>
    <div mat-dialog-content>
        <div class="col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-center" style="padding: 3% 5% 3% 5%">
            <div class="col-lg-4 col-md-4 col-sm-12 class-card" style="margin-right: 4%"
                *ngFor="let class of classes; index as i" [ngClass]="{
                'sme-selected-class': selectedClasses.includes(class?.name),
                'sme-not-selected-class': !selectedClasses.includes(class?.name)
            }" (click)="HandleClassSelection(class?.name)">
                <!-- <div class="class-card-header d-flex">
                <div class="sme-basic-class-img">
                    300 SAR.
                </div>
            </div> -->
                <div class="class-card-body">
                    <span class="{{ class.name }} d-flex flex-row align-items-center justify-content-center">{{
                        class.name == 'CLASSIC'? 'CLASSIC' : 'BASIC' }}</span>

                    <div class="pb-5 d-flex flex-row align-items-center justify-content-center mt-3">
                        <img *ngIf="i==0" src="assets/img/medical-sme/Basic.png"  width="100"/>

                        <img *ngIf="i==1" src="assets/img/medical-sme/Classic.png" width="100"/>
                    </div>

                    <!-- <div class="d-flex flex-row mt-3">
                    <img
                        src="assets/img/medical-sme/check-mark-svgrepo-com 1.png"
                        class="mr-5"
                    />
                    <span class="benifit-text"
                        >primary care physician required</span
                    >
                </div>

                <div class="d-flex pt-3">
                    <img
                        src="assets/img/medical-sme/check-mark-svgrepo-com 1.png"
                        class="mr-5"
                    />
                    <span class="benifit-text"
                        >referral required to see a specialist</span
                    >
                </div>

                <div class="d-flex pt-3">
                    <img
                        src="assets/img/medical-sme/plus-svgrepo-com (1) 3.png"
                        class="mr-5"
                    />
                    <span class="benifit-text">in network benefits</span>
                </div>

                <div class="d-flex pt-3">
                    <img
                        src="assets/img/medical-sme/plus-svgrepo-com (1) 3.png"
                        class="mr-5"
                    />
                    <span class="benifit-text"
                        >non-emergency out-of-network benefits</span
                    >
                </div>

                <div class="d-flex pt-3 pb-3">
                    <img
                        src="assets/img/medical-sme/plus-svgrepo-com (1) 3.png"
                        class="mr-5"
                    />
                    <span class="benifit-text">emergency coverage</span>
                </div> -->
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-4 col-sm-12 class-card" style="margin-right: 2%;">
            <div class="class-card-header d-flex">
                <div class="sme-prem-class-img">
                    500 SAR.
                </div>
            </div>
            <div class="class-card-body">
                <span class="premium-class-title">Premium</span>
                <span class="d-flex most-pop-lbl">Most Popular</span>
                <div class="d-flex flex-row mt-3">
                    <img src="assets/img/medical-sme/check-mark-svgrepo-com 1.png" class="mr-5">
                    <span class="benifit-text">primary care physician required</span>
                </div>

                <div class="d-flex pt-3">
                    <img src="assets/img/medical-sme/check-mark-svgrepo-com 1.png" class="mr-5">
                    <span class="benifit-text">referral required to see a specialist</span>
                </div>

                <div class="d-flex pt-3">
                    <img src="assets/img/medical-sme/check-mark-svgrepo-com 1.png" class="mr-5">
                    <span class="benifit-text">in network benefits</span>
                </div>

                <div class="d-flex pt-3">
                    <img src="assets/img/medical-sme/plus-svgrepo-com (1) 3.png" class="mr-5">
                    <span class="benifit-text">non-emergency out-of-network benefits</span>
                </div>

                <div class="d-flex pt-3 pb-3">
                    <img src="assets/img/medical-sme/plus-svgrepo-com (1) 3.png" class="mr-5">
                    <span class="benifit-text">emergency coverage</span>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 class-card">
            <div class="class-card-header d-flex">
                <div class="sme-adv-class-img">
                    700 SAR.
                </div>
            </div>
            <div class="class-card-body">
                <span class="advance-class-title">Advance</span>
                <div class="d-flex flex-row mt-3">
                    <img src="assets/img/medical-sme/check-mark-svgrepo-com 1.png" class="mr-5">
                    <span class="benifit-text">primary care physician required</span>
                </div>

                <div class="d-flex pt-3">
                    <img src="assets/img/medical-sme/check-mark-svgrepo-com 1.png" class="mr-5">
                    <span class="benifit-text">referral required to see a specialist</span>
                </div>

                <div class="d-flex pt-3">
                    <img src="assets/img/medical-sme/check-mark-svgrepo-com 1.png" class="mr-5">
                    <span class="benifit-text">in network benefits</span>
                </div>

                <div class="d-flex pt-3">
                    <img src="assets/img/medical-sme/check-mark-svgrepo-com 1.png" class="mr-5">
                    <span class="benifit-text">non-emergency out-of-network benefits</span>
                </div>

                <div class="d-flex pt-3 pb-3">
                    <img src="assets/img/medical-sme/check-mark-svgrepo-com 1.png" class="mr-5">
                    <span class="benifit-text">emergency coverage</span>
                </div>
            </div>
        </div> -->
        </div>

        <!-- <div class="row col-lg-12 col-md-12 col-sm-12 pb-2">
        <mat-radio-group aria-label="Select an option" class="d-flex flex-row">
            <div
                class="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center"
                *ngFor="let class of classes"
            >
                <mat-checkbox (change)="addClass(class.name)"></mat-checkbox>
            </div>
        </mat-radio-group>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 pb-2">
        <div class="d-flex justify-content-center pt-4 mb-5">
            <button class="sme-submit-btn" (click)="submitSelectedClasses()">
                {{ "SME.SUBMIT" | translate }}
            </button>
        </div>
    </div> -->
        <div class="row sme-health-save-container mb-2 mt-5">
            <button class="sme-save-btn" (click)="submitSelectedClasses()" [disabled]="!isClassesSelected"
            [ngClass]="{'sme-save-btn-disabled': !isClassesSelected}">
                {{ "SME.SUBMIT" | translate }}
            </button>
        </div>
    </div>
</div>