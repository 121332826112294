import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { UserService } from 'src/app/core/services/UserService/user.service';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
@Component({
    selector: 'app-user-verification',
    templateUrl: './user-verification.component.html',
    styleUrls: ['./user-verification.component.scss'],
})
export class UserVerificationComponent implements OnInit, OnDestroy {

    lnk = localStorage.getItem('navLink');
    display: string;
    seconds: number;
    disableBTN = false;
    memberID;
    memberDetails: any;
    selectedMember: any;
    auth_member: any;

    private unsubscriber: Subject<void> = new Subject<void>();

    @HostListener('document:keyup.enter', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        this.navigate();
    }
    otpInputConfig: NgxOtpInputConfig = {
        otpLength: 4,
        autofocus: true,
        classList: {
            inputBox: 'my-super-box-class',
            input: 'my-super-class',
            inputFilled: 'my-super-filled-class',
            inputDisabled: 'my-super-disable-class',
            inputSuccess: 'my-super-success-class',
            inputError: 'my-super-error-class',
        },
    };
    ValidOTP = false;
    OTPValue: any;
    phoneNumber = this._LocalService.getJsonValue('mobileNumber');

    constructor(
        private _LocalService: LocalService,
        public medicalClaimsService: MedicalClaimsService,
        public translate: TranslateService,
        public user: UserService,
        public router: Router
    ) {
        this.lnk = this._LocalService.getJsonValue('navLink');
        this.memberID = this._LocalService.getJsonValue('memberID');
        this.phoneNumber = this._LocalService.getJsonValue('mobileNumber');
        this.memberDetails = null;
        this.OTPValue = null;

        this.selectedMember = {
            id: '',
            policyNumber: '',
            policyHolder: '',
            policyExpiryData: '',
            memberCode: '',
            memberEName: '',
            gender: '',
            birthDate: '',
            className: '',
            relation: '',
        };
        this.auth_member = this._LocalService.getJsonValue('userLoginInfo');
    }


    ngOnInit(): void {
        this._LocalService.setJsonValue('isEligableToAccessOTP', false);
        this.timer(2);
        if (this._LocalService.getJsonValue('navLink') !== null) {
            this.lnk = this._LocalService.getJsonValue('navLink');
        } else {
            this.lnk = '';
        }
    }
    async navigate() {
        if (this.ValidOTP) {
            await this.OtpVerification();
        }
    }
    ngAfterViewInit() {
        window.scrollTo(0, 0);

    }

    timer(minute) {
        // let minute = 1;
        let textSec: any = '0';
        let statSec: number = 60;
        this.seconds = minute * 60;
        const prefix = minute < 10 ? '0' : '';

        const timer = setInterval(() => {
            this.seconds--;
            if (statSec != 0) statSec--;
            else statSec = 59;

            if (statSec < 10) {
                textSec = '0' + statSec;
            } else textSec = statSec;

            this.display = `${prefix}${Math.floor(
                this.seconds / 60
            )}:${textSec}`;

            if (this.seconds == 0) {
                this.disableBTN = true;
                clearInterval(timer);
            }
        }, 1000);
    }
    async OtpVerification() {
        let x = new Date().toUTCString();
        let nowDate = new Date(x).getTime();
        let TokenEX =
            this._LocalService.getJsonValue(
                'userLoginInfo'
            )?.tokenExpirationDate;
        let refreshTokenEX =
            this._LocalService.getJsonValue(
                'userLoginInfo'
            )?.refreshTokenExpirationDate;

        let TokenEXUTC = new Date(TokenEX).getTime();
        let refreshTokenEXUTC = new Date(refreshTokenEX).getTime();

        let queryString = { otpCode: this.OTPValue };
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if (nowDate <= TokenEXUTC && nowDate <= refreshTokenEXUTC) {
            headers = headers.append(
                'Authorization',
                'Bearer ' + this.auth_member.token
            );
        } else if (nowDate > TokenEXUTC && nowDate <= refreshTokenEXUTC) {
            await this.getnewToken(this.auth_member.refreshToken);
        } else if (nowDate > TokenEXUTC && nowDate > refreshTokenEXUTC) {
            window.location.href = `/user-login`;
        }

        this.user.OTP(queryString, headers).subscribe(
            async (res) => {
                if (res?.errorCode === 0) {
                    await this.GetUserMedicalProfile();
                    this.router.navigate([`${this.lnk}`]);
                    this._LocalService.setJsonValue('isEligableToAccessOTP', false);
                    this._LocalService.setJsonValue('userVerified', true);
                } else {
                    let dir: string = '';

                    if (this.translate.currentLang == 'en-US') {
                        dir = 'ltr';
                    } else {
                        dir = 'rtl';
                    }
                    Swal.fire({
                        title:
                            this.translate.currentLang == 'en-US'
                                ? 'Error!'
                                : '!خطأ',
                        iconHtml:
                            '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                        html:
                            "<div style='color:#FF001F; direction:" +
                            dir +
                            "'>" +
                            res?.message +
                            '</div>',
                        confirmButtonText:
                            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                        customClass: {
                            icon: 'submit-claim-failed-icon-img',
                            title: 'submit-claim-failed-swal-title',
                            confirmButton: 'submit-claim-failed-confirm-btn',
                        },
                    });
                }
            },
            async (err) => {
                if (err?.errorCode === 0) {
                    await this.GetUserMedicalProfile();
                    window.location.href =`${this.lnk}`
                    this._LocalService.setJsonValue('isEligableToAccessOTP', false);
                    this._LocalService.setJsonValue('userVerified', true);
                } else {
                    let dir: string = '';

                    if (this.translate.currentLang == 'en-US') {
                        dir = 'ltr';
                    } else {
                        dir = 'rtl';
                    }
                    Swal.fire({
                        title:
                            this.translate.currentLang == 'en-US'
                                ? 'Error!'
                                : '!خطأ',
                        iconHtml:
                            '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                        html:
                            "<div style='color:#FF001F !important; direction:" +
                                dir +
                                "'>" +
                                this.translate.currentLang ==
                                'en-US'
                                ? 'Invalid Verification Code!'
                                : '!رمز التحقق غير صحيح' + '</div>',
                        confirmButtonText:
                            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                        customClass: {
                            icon: 'submit-claim-failed-icon-img',
                            title: 'submit-claim-failed-swal-title',
                            confirmButton: 'submit-claim-failed-confirm-btn',
                        },
                    });
                }
            }
        );
    }

    async GetUserMedicalProfile() {
        let queryString = 'MemberId=' + this.memberID;
        this.medicalClaimsService
            .MemberDetails(queryString)
            .subscribe((res) => {
                this.memberDetails = res;
                this._LocalService.setJsonValue('memberDetails', res?.data);
                if (res?.data.length > 0) {
                    this.selectedMember = res?.data[0];
                }
            });
    }

    handeOtpChange(value: string[]): void {
        this.ValidOTP = false;
    }

    handleFillEvent(value: string): void {
        this.ValidOTP = true;
        this.OTPValue = value;
    }
    async resendCode() {
        await this.login();
    }

    async login() {
        let queryString = {
            userIqamaId: this.memberID.toString(),
            phoneNumber: this.phoneNumber.toString(),
            isWeb:true
        };

        this.user.UserLogin(queryString).subscribe(
            async (res) => {
                if (res?.loginData) {
                    this._LocalService.setJsonValue(
                        'isEligableToAccessOTP',
                        true
                    );
                    this.router.navigate(['/user-verification']);
                }
            },
            (err) => {
                let dir: string = '';

                if (this.translate.currentLang == 'en-US') {
                    dir = 'ltr';
                } else {
                    dir = 'rtl';
                }
                Swal.fire({
                    title:
                        this.translate.currentLang == 'en-US'
                            ? 'Error!'
                            : '!خطأ',
                    iconHtml:
                        '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                    html:
                        "<div style='color:#FF001F; direction:" +
                        dir +
                        "'>" +
                        err?.message +
                        '</div>',
                    confirmButtonText:
                        this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                    customClass: {
                        icon: 'submit-claim-failed-icon-img',
                        title: 'submit-claim-failed-swal-title',
                        confirmButton: 'submit-claim-failed-confirm-btn',
                    },
                });
            }
        );
    }

    async getnewToken(refresToken) {
        let queryString = {'refreshToken' :  refresToken};
        this.user.RefreshToken(queryString).subscribe(async (res) => {
            if (res?.loginData) {
                this._LocalService.setJsonValue(
                    'userLoginInfo',
                    res?.loginData
                );
            }
        });
    }
    ngOnDestroy(): void {
        this._LocalService.setJsonValue('isEligableToAccessOTP', false);
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }
}
