import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';

@Component({
    selector: 'app-sme-quotation-form-step',
    templateUrl: './sme-quotation-form-step.component.html',
    styleUrls: ['./sme-quotation-form-step.component.scss'],
})
export class SmeQuotationFormStepComponent implements OnInit {
    @Input() CompanyDetails: any;
    @Input() QuotationNumber: any;
    @Input() myStepper: MatStepper;
    @Output() onUploadUsersSubmit = new EventEmitter<any>();

    creationDate: any = new Date();

    CompanyAddress: any;
    CompanyCCHI: any;
    isLoading = true;
    Members: any;
    SponsorId: any;
    premiumData: any;

    constructor(private smeService: MedicalSMEService,
        public translate: TranslateService,) { }

    ngOnInit(): void {
        if (
            this.QuotationNumber === null ||
            this.QuotationNumber === undefined ||
            this.QuotationNumber === 0
        ) {
            this.QuotationNumber = localStorage.getItem('quotationNumber');
            localStorage.setItem("crEntityNumber",this.CompanyDetails.crEntityNumber.toString());
            if (this.QuotationNumber === 0 || this.QuotationNumber === null || this.QuotationNumber === undefined) {
                // get quotation id
                // let requestData = {
                //     CompanyId: this.CompanyDetails.crEntityNumber.toString(),
                //     EmailAddress: 'aa',
                //     MobileNumber: '00',
                // };
                // this.smeService.GetQuotationId(requestData).subscribe(
                //     (res) => {
                //         if (res != null) {
                //             this.QuotationNumber = res;
                //             localStorage.removeItem('quotationNumber');
                //             localStorage.setItem('quotationNumber', this.QuotationNumber);
                //         }
                //     },
                //     (err) => { }
                // );
            }
        }

        if (this.CompanyDetails !== null) {
            this.GetCompanyAddress();
            this.GetCompanyCCHI();
        } else this.SponsorId = this.CompanyDetails.crEntityNumber;

        let backToMembers =  localStorage.getItem("backToMembers");
        if(backToMembers != null && backToMembers != undefined){
            localStorage.removeItem("backToMembers");
            this.myStepper.selectedIndex = 1;
        }
    }

    async GetCompanyAddress() {
        this.isLoading = true;
        this.smeService
            .GetCompanyAddressInfo(this.CompanyDetails.crEntityNumber)
            .subscribe(
                (res) => {
                    this.CompanyAddress = res;
                    this.isLoading = false;
                    this.SponsorId = this.CompanyDetails.crEntityNumber;
                },
                (err) => {
                    this.isLoading = false;
                }
            );
    }

    async GetCompanyCCHI() {
        this.isLoading = true;
        this.smeService
            .GetSponsorDetails(this.CompanyDetails.crEntityNumber)
            .subscribe(
                (res) => {
                    this.CompanyCCHI = res;
                    this.isLoading = false;
                    localStorage.removeItem('remainingMembers');
                    localStorage.setItem('remainingMembers', this.CompanyCCHI.required_Main);
                },
                (err) => {
                    this.isLoading = false;
                }
            );
    }

    checkQuotationMembers() {
        // this.isLoading = true;
        // this.smeService
        //     .CheckQuotationMembersExistance(this.QuotationNumber)
        //     .subscribe(
        //         (res) => {
        //             this.Members = res;
        //             this.isLoading = false;
        //             if ((this.Members != null && this.Members != undefined)
        //                 && this.Members.successMembers.length <= Number(this.CompanyCCHI?.remaining_Main)) {
        //                 localStorage.removeItem("ExistMembers");
        //                 localStorage.setItem("ExistMembers", this.Members);
        //                 this.myStepper.next();
        //             }
        //             else {
        //                 // calculate el policy
        //                 // go to quoation details
        //                 this.getPremiumData();
        //             }
        //         },
        //         (err) => {
        //             this.isLoading = false;
        //         }
        //     );

        this.myStepper.next()
    }

    getPremiumData() {
        let data = {
            Endto: 0,
            QuotationNo: Number(this.QuotationNumber),
        }
        this.isLoading = true;
        this.smeService.GetPremiumData(data).subscribe(
            (res) => {
                if (res != null) {
                    this.isLoading = false;
                    this.premiumData = res;
                    //console.log(this.premiumData);
                    let resultObj = {
                        tabIndex: 2,
                        premData: this.premiumData,
                        quotNumber: this.QuotationNumber
                    }
                    this.onUploadUsersSubmit.emit(resultObj);
                }
            },
            (err) => {

            }
        );
    }
}
