import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {
    MatDialog,
    MatDialogRef,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
} from '@angular/material/dialog';
import { Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MotorClaimsService } from 'src/app/core/services/motor-claims.service';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DLSubmitMissingDocsDialogComponent } from '../dlsubmit-missing-docs-dialog/dlsubmit-missing-docs-dialog.component';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { OTPService } from 'src/app/core/services/eservice-otp.service';
import { OtpVerificationDialogComponent } from '../../OTPVerification/otp-verification-dialog/otp-verification-dialog.component';
import { AppConfigService } from 'src/app/core/AppConfigService';
@Component({
    selector: 'app-dl-policy-details',
    templateUrl: './dl-policy-details.component.html',
    styleUrls: ['./dl-policy-details.component.scss'],
})
export class DLPolicyDetailsComponent implements OnInit {
    ProductCode: number = 1;
    ProductDetails: any;

    claimsDetails = [];
    mobile: string;
    form: any;
    national: string;
    policyInfo: PolicyDetails;
    HasClaims: string;
    DetailsForm: FormGroup;
    percePassedDays: number;
    cancellationDto: CancellationDTO;
    showCancelButton: boolean = true;
    isSubmitted = false;
    Claim: string;
    listClaims: any[] = [];
    isCancel=false;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    pageSize: number = 5;

    dataSource = new MatTableDataSource();
    columnsToDisplay = [
        'claimno',
        'reporteddate',
        'claimstatus',
        'accidentdate',
        'insuredname',
        'settlementdate',
        'paymentamount',
        'actions',
    ];
    claims: any = [];
    claimsLoader: any = false;

    constructor(
        public _dialog: MatDialog,
        public motorClaimsService: MotorClaimsService,
        private _formbuilder: FormBuilder,
        private _router: Router,
        public translate: TranslateService,
        private location: Location,
        private dialog: MatDialog,
        private otpService: OTPService,
    ) {
        //  this.form = this.toFormGroup(this.formInput);
        // this.DetailsForm = this._formbuilder.group({
        // MobileNumber: [
        //     '',

        //      ],
        //     OTP: [null],
        //     driverId:[
        //         '',

        //     ]
        //     });
        this.policyInfo = this.location.getState()['policyInfo'];

        if (
            localStorage.getItem('policyInfo') !== null &&
            localStorage.getItem('policyInfo') !== undefined
        ) {
            this.policyInfo = JSON.parse(localStorage.getItem('policyInfo'));
        }

        if (
            localStorage.getItem('claimDL') !== null &&
            localStorage.getItem('claimDL') !== undefined
        ) {
            this.Claim = localStorage.getItem('claimDL');
            //  this.filteredPolicy =  JSON.parse(localStorage.getItem('policyInfo'));
        }
        if (this.policyInfo != null && this.policyInfo !== undefined) {
            //this.motorClaimsService.getDlClaims(this.policyInfo.policy).subscribe((res) => {
            //    if (res != null && res.length > 0) {
            //        this.listClaims = res;

            //    }
            //});

            this.claimsLoader = true;
            this.motorClaimsService
                .getDomesticPolicyClaims(this.policyInfo.policy)
                .subscribe((res) => {
                    this.claims = res;
                    this.dataSource = new MatTableDataSource(this.claims);
                    setTimeout(
                        () => (this.dataSource.paginator = this.paginator)
                    );
                    this.dataSource.paginator = this.paginator;
                    this.claimsLoader = false;
                });

            this.national = this.policyInfo.nationalId;
            this.motorClaimsService
                .getDomesticCancelDetails(
                    this.policyInfo.nationalId,
                    this.policyInfo.policy
                )
                .subscribe((res) => {
                    if (res != null && res.policyno != null) {
                        this.cancellationDto = res;
                        this.showCancelButton = false;
                    } else {
                        this.showCancelButton = true;
                    }
                });
        } else {
            this.showCancelButton = true;
        }
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        console.log(this.dataSource.filter);
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    ngOnInit(): void {
        debugger;
        this.policyInfo = JSON.parse(localStorage.getItem('policyInfo'));
        var nationalId=this.policyInfo.nationalId;

        this.motorClaimsService.checkCancelation(nationalId).subscribe((res)=>{
           if(res==true){
            this.isCancel=true;
           }
           else{
            this.isCancel=false;
           }

        })
    }
    openDialog(
        enterAnimationDuration: string,
        exitAnimationDuration: string,
        element
    ): void {
        this.isSubmitted = true;

        this._dialog.open(dlCancelationModal, {
            data: { details: element },
            width: '800px',
            enterAnimationDuration,
            exitAnimationDuration,
        });
        this.isSubmitted = false;
    }
    openClaimSubmissionDialog(policyInfo): void {
        this.isSubmitted = true;
        this._dialog.open(dlClaimSubmission, {
            data: { details: policyInfo },
            width: '1000px',
            height: '100%',
        });
        this.isSubmitted = false;
    }

    resubmitMissingFiles(event: any) {
        this.dialog.open(DLSubmitMissingDocsDialogComponent, {
            width: '60em',
            height: '30em',
            data: {
                claim: event,
            },
            disableClose: true,
        });
    }
    saveCancellation() {}
}

interface PolicyDetails {
    effectivedate: string;
    policy: string;
    nationality: string;
    policyholderAr: string;
    policyholderEn: string;
    nationalId: string;
    status: string;
    expirydate: string;
    mobile: string;
    iqamaId:string;
}
interface Food {
    value: string;
    viewValue: string;
}
interface CancellationDTO {
    cancellationReason: any;
    iban: string;
    hasSuit: string;
    notes: string;
    proposer: string;
    proposerId: string;
    policyno: string;
    nationalId: string;
    mobile: string;
    cS_DOC_TYPE: string;
    doC_BYTE: string;
    cancellation_Reason_doC: any;
}
interface cancellationDocs {
    cS_DOC_TYPE: string;
    dOC_BYTE: string;
}

@Component({
    selector: 'dl-cancellation-dialog.component',
    templateUrl: 'dl-cancellation-dialog.component.html',
})
export class dlCancelationModal {
    policyDetails: PolicyDetails;
    nationalImgsList = [];
    foods: Food[] = [
        { value: 'steak-0', viewValue: 'Steak' },
        { value: 'pizza-1', viewValue: 'Pizza' },
        { value: 'tacos-2', viewValue: 'Tacos' },
    ];
    isLoading: boolean;
    ImgsId: any[] = [];
    IBANpattern = /^[saSA]+[0-9]{22}$/;
    DetailsForm: FormGroup;
    cancellationDto: CancellationDTO = {} as CancellationDTO;
    lengthIban: string;
    lengthId: string;
    cancellationReasons: any = [];
    cancellationFiles: any = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<dlCancelationModal>,
        public motorClaimsService: MotorClaimsService,
        private _formbuilder: FormBuilder,
        private _router: Router,
        public translate: TranslateService,
        private otpService: OTPService,
        private _dialog: MatDialog,
    ) {
        const IBANpattern = '^(SA)([0-9-a-z]{22})$';
        this.DetailsForm = this._formbuilder.group({
            cancellationReason: [[], Validators.required],
            iban: [
                null,
                [Validators.required, Validators.pattern(this.IBANpattern)],
            ],
            hasSuit: [''],
            notes: [''],
            proposer: [''],
            proposerId: [''],
        });

        this.policyDetails = data.details;
        this.lengthIban = '';
        this.lengthId = '';

        this.motorClaimsService.getCancellationReasons().subscribe((res) => {
            this.cancellationReasons = res;
        });
    }

    SendOTPAndOpenVerificationDialog() {
        if (this.DetailsForm.controls['iban'].value.length == 24) {
            let otpObject = {
                ActionType: SMSActionType.Cancelation,
                Lob: LineOfBusiness.DomesticLabor,
                IqamaId: this.policyDetails.iqamaId.toString(),
                MobileNumber: this.policyDetails.mobile.toString(),
                isWeb: true
            }

            this.isLoading = true;
            this.otpService.sendOTP(otpObject).subscribe((res) => {
                let result = res;
                if (result.errors.length <= 0) {
                    const dialogRef = this._dialog.open(OtpVerificationDialogComponent, {
                        data: { otpDetails: result },
                        width: '600px',
                        disableClose: true
                    });
                    dialogRef.afterClosed().subscribe((data) => {
                        if (data.verified) {
                            debugger;
                            this.saveCancellation();
                        }
                    });
                }
            });
        } else if (this.DetailsForm.controls['proposerId'].value.length < 10) {
            this.lengthId =
                this.translate.currentLang == 'en-US'
                    ? 'ID Must be 10 digits'
                    : 'رقم الهوية او الاقامة يجب ان يكون 10 ارقام';
        } else {
            this.lengthIban =
                this.translate.currentLang == 'en-US'
                    ? 'IBAN must be 24 Digits including SA at beggining '
                    : 'SA رقم الايبان يجب ان يكون 24 رقم متضمن حرفين ';
        }
    }

    setCancellationReasonFile(event, id) {
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            const fileName = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.cancellationFiles.push({
                    image: reader.result,
                    id: id,
                    name: fileName,
                });
            };
        }
    }

    setDocsList(event, id) {
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            const fileName = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.nationalImgsList.push({
                    image: reader.result,
                    id: id,
                    name: fileName,
                });
                if (!this.ImgsId.includes(id)) {
                    this.ImgsId.push(id);
                }
            };
        }
    }
    deleteAtt(x) {
        this.nationalImgsList.splice(x, 1);
    }

    saveCancellation() {
        debugger;
        this.lengthIban = '';
        this.lengthId = '';
        this.cancellationDto.cancellationReason =
            this.DetailsForm.controls['cancellationReason'].value;
        this.cancellationDto.iban = this.DetailsForm.controls['iban'].value;
        this.cancellationDto.hasSuit =
            this.DetailsForm.controls['hasSuit'].value;
        this.cancellationDto.mobile = this.policyDetails.mobile;
        this.cancellationDto.notes =
            this.DetailsForm.controls['notes'].value;
        this.cancellationDto.policyno = this.policyDetails.policy;
        this.cancellationDto.proposer =
            this.DetailsForm.controls['proposer'].value;
        this.cancellationDto.proposerId =
            this.DetailsForm.controls['proposerId'].value;
        this.cancellationDto.nationalId =
            this.policyDetails.nationalId.toString();
            debugger;

        if (this.nationalImgsList.length > 0) {
            let item = this.nationalImgsList[0];
            this.cancellationDto.cS_DOC_TYPE = item.id.toString();
            this.cancellationDto.doC_BYTE = item.image;
            this.cancellationDto.cancellation_Reason_doC =
                this.cancellationFiles;
        }
        this.motorClaimsService
            .saveDomesticCancellation(this.cancellationDto)
            .subscribe((res) => {
                if (res) {
                    this.dialogRef.close();
                    this._router
                        .navigateByUrl('/domestic-policy-details', {
                            state: {
                                policyInfo: this.policyDetails,
                            },
                        })
                        .then(() => {
                            window.location.reload();
                        });
                } else {
                }
            });
    }
}
export interface claimsDl {
    entrydate: Date;
    exitDate: Date;
    benefeciery: string;
    recuitment: string;
    claimDescription: string;
    policyMobile: string;
    policyEmail: string;
    claimaintMobile: string;
    claimaintEmail: string;
    pickupdate: Date;
    claimreason: string;
    decleration: number;
    contractamount: string;
    policyno: string;
    docs: cancellationDocs[];
    lang?: string;
}
@Component({
    selector: 'dl-claim-submission.component',
    templateUrl: 'dl-claim-submissions.component.html',
})
export class dlClaimSubmission {
    policyDetails: PolicyDetails;
    today: Date;
    documents: number;
    nationalImgsList = [];
    attachmentsCheck: string;
    DetailsForm: FormGroup;
    foods: Food[] = [
        { value: 'steak-0', viewValue: 'Steak' },
        { value: 'pizza-1', viewValue: 'Pizza' },
        { value: 'tacos-2', viewValue: 'Tacos' },
    ];
    ImgsId: any[] = [];
    claim: claimsDl = {} as claimsDl;
    fromdatevalidator: string;
    expirydatevalidator: string;
    claimreasonvalidator: string;
    pickupdatevalidator: string;
    declarevalidator: string;
    benefecieryValidator: string;
    recuitmentValidator: string;
    policyMobileValidator: string;
    claimDescriptionValidator: string;
    policyEmailValidator: string;
    claimaintMobileValidator: string;
    claimaintEmailValidator: string;
    contractamountValidator: string;
    mobilePattern = '^(05)([0-9]{8})$';
    claimTypeDetailsValidator: string;

    get DetailsFormControl() {
        return this.DetailsForm.controls;
    }
    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<dlClaimSubmission>,
        public motorClaimsService: MotorClaimsService,
        private _formbuilder: FormBuilder,
        private _router: Router,
        public translate: TranslateService,
        public otpService:OTPService,
         public _dialog:MatDialog,
        private environment: AppConfigService
    ) {
        const IBANpattern = '^(SA)([0-9-a-z]{22})$';
        this.today = new Date();
        this.policyDetails = data.details;
        //this.policyDetails = data.details;
        this.DetailsForm = this._formbuilder.group({
            fromdate: ['', Validators.required],
            expirydate: ['', Validators.required],
            ddlbenefeciery: ['', Validators.required],
            ddlRecuitment: ['', Validators.required],
            claimDescription: ['', Validators.required],
            policyMobile: ['', Validators.required],
            policyEmail: ['', Validators.required],
            claimaintMobile: ['', Validators.required],
            claimaintEmail: ['', Validators.required],
            contractamt: ['', Validators.required],
            pickupdate: ['', Validators.required],
            claimreason: ['', Validators.required],
            declare: ['', Validators.required],
        });

        this.DetailsFormControl.ddlbenefeciery.clearValidators();
        this.DetailsFormControl.ddlbenefeciery.updateValueAndValidity();

        this.DetailsFormControl.ddlRecuitment.clearValidators();
        this.DetailsFormControl.ddlRecuitment.updateValueAndValidity();

        this.DetailsFormControl.claimDescription.clearValidators();
        this.DetailsFormControl.claimDescription.updateValueAndValidity();

        this.DetailsFormControl.policyMobile.clearValidators();
        this.DetailsFormControl.policyMobile.updateValueAndValidity();
        this.DetailsFormControl.policyEmail.clearValidators();
        this.DetailsFormControl.policyEmail.updateValueAndValidity();
        this.DetailsFormControl.claimaintMobile.clearValidators();
        this.DetailsFormControl.claimaintMobile.updateValueAndValidity();
        this.DetailsFormControl.claimaintEmail.clearValidators();
        this.DetailsFormControl.claimaintEmail.updateValueAndValidity();

        this.DetailsFormControl.expirydate.clearValidators();
        this.DetailsFormControl.expirydate.updateValueAndValidity();
        this.DetailsFormControl.fromdate.clearValidators();
        this.DetailsFormControl.fromdate.updateValueAndValidity();
        this.DetailsFormControl.claimreason.clearValidators();
        this.DetailsFormControl.claimreason.updateValueAndValidity();
        this.DetailsFormControl.pickupdate.clearValidators();
        this.DetailsFormControl.pickupdate.updateValueAndValidity();
        this.DetailsFormControl.declare.clearValidators();
        this.DetailsFormControl.declare.updateValueAndValidity();
    }
    setDocsList(event, id) {
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            const fileName = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.nationalImgsList.push({
                    image: reader.result,
                    id: id,
                    name: fileName,
                });
                if (!this.ImgsId.includes(id)) {
                    this.ImgsId.push(id);
                }
            };
        }
    }
    deleteAtt(x) {
        this.nationalImgsList.splice(x, 1);
    }
    defineDocs(event: any) {
        debugger;
        let doc = event.value;
        if (doc == '1') {
            this.documents = 1;
        }
        if (doc == '2') {
            this.documents = 2;
        }
        if (doc == '3') {
            this.documents = 3;
        }
        if (doc == '4') {
            this.documents = 4;
        }
        if (doc == '5') {
            this.documents = 5;
        }
    }

    claimsLoader = false;
    saveClaims() {

        this.claim.benefeciery =
            this.DetailsForm.controls['ddlbenefeciery'].value;
        if (
            (this.recuitmentValidator == null ||
                this.recuitmentValidator == '') &&
            (this.claimDescriptionValidator == null ||
                this.claimDescriptionValidator == '') &&
            (this.benefecieryValidator == null ||
                this.benefecieryValidator == '') &&
            (this.expirydatevalidator == null ||
                this.expirydatevalidator == '') &&
            (this.fromdatevalidator == null || this.fromdatevalidator == '') &&
            (this.claimreasonvalidator == null ||
                this.claimreasonvalidator == '') &&
            (this.pickupdatevalidator == null ||
                this.pickupdatevalidator == '') &&
            (this.declarevalidator == null || this.declarevalidator == '') &&
            (this.contractamountValidator == null ||
                this.contractamountValidator == '') &&
            (this.attachmentsCheck == null || this.attachmentsCheck == '') &&
            (this.policyMobileValidator == null ||
                this.policyMobileValidator == '') &&
            (this.policyEmailValidator == null ||
                this.policyEmailValidator == '') &&
            (this.claimaintMobileValidator == null ||
                this.claimaintMobileValidator == '') &&
            (this.claimaintEmailValidator == null ||
                this.claimaintEmailValidator == '') &&
            (this.claimTypeDetailsValidator == null ||
                this.claimTypeDetailsValidator == '')
        ) {
            this.claim.benefeciery =
                this.DetailsForm.controls['ddlbenefeciery'].value;

        this.claim.recuitment =
            this.DetailsForm.controls['ddlRecuitment'].value;

        this.claim.claimDescription =
            this.DetailsForm.controls['claimDescription'].value;

        this.claim.policyMobile =
            this.DetailsForm.controls['policyMobile'].value;
        this.claim.policyEmail =
            this.DetailsForm.controls['policyEmail'].value;
        this.claim.claimaintMobile =
            this.DetailsForm.controls['claimaintMobile'].value;
        this.claim.claimaintEmail =
            this.DetailsForm.controls['claimaintEmail'].value;

        this.claim.entrydate =
            this.DetailsForm.controls['fromdate'].value;
        this.claim.exitDate = this.DetailsForm.controls['expirydate'].value;
            this.claim.entrydate = this.DetailsForm.controls['fromdate'].value;
            this.claim.exitDate = this.DetailsForm.controls['expirydate'].value;

        this.claim.claimreason =
            this.DetailsForm.controls['claimreason'].value;
        this.claim.pickupdate =
            this.DetailsForm.controls['pickupdate'].value;

        this.claim.decleration =
            this.DetailsForm.controls['declare'].value == true ? 1 : 0;
        this.claim.contractamount =
            this.DetailsForm.controls['contractamt'].value;
        this.claim.policyno = this.policyDetails.policy;
        this.claim.docs = [];
        this.nationalImgsList.forEach((x) => {
            let item = {} as cancellationDocs;
            item.dOC_BYTE = x.image;
            item.cS_DOC_TYPE = x.id;

            this.claim.docs.push(item);
        });

        this.claim.lang = this.translate.currentLang;
        this.claimsLoader = true;
        this.motorClaimsService
            .submitDlClaims(this.claim)
            .subscribe((res) => {
                if (res != null && res.requestId > 0) {
                    this.claimsLoader = false;
                    this.dialogRef.close();
                    this._router
                        .navigateByUrl('/domestic-policy-details', {
                            state: {
                                policyInfo: this.policyDetails,
                            },
                        })
                        .then(() => {
                            window.location.reload();
                        });
                } else {
                    this.claimsLoader = false;
                }
            });

    }
}

    contractFileValidator: boolean = false;
    passportFileValidator: boolean = false;
    ibanFileValidator: boolean = false;
    hospitalReportValidator: boolean = false;
    workerDeathValidator: boolean = false;
    policyAbsenceValidator: boolean = false;
    proofOfEmergencyCaseValidator: boolean = false;
    stopWorkValidator: boolean = false;
    passportOfficePrintValidator: boolean = false;

    ValidateAllDocsAttached() {
        let result = false;
        this.contractFileValidator = false;
        this.passportFileValidator = false;
        this.ibanFileValidator = false;
        this.hospitalReportValidator = false;
        this.workerDeathValidator = false;
        this.policyAbsenceValidator = false;
        this.proofOfEmergencyCaseValidator = false;
        this.stopWorkValidator = false;
        this.passportOfficePrintValidator = false;

        if (this.nationalImgsList == null || this.nationalImgsList.length == 0)
            result = true;
        if (!this.ImgsId.includes('contract')) {
            this.contractFileValidator = true;
        }
        if (!this.ImgsId.includes('passport')) {
            this.passportFileValidator = true;
        }
        if (!this.ImgsId.includes('iban')) {
            this.ibanFileValidator = true;
        }

        if (this.documents == 1) {
            if (!this.ImgsId.includes('hospitalreport')) {
                this.hospitalReportValidator = true;
                result = true;
            }
        } else if (this.documents == 2) {
            if (!this.ImgsId.includes('WorkerDeath')) {
                this.workerDeathValidator = true;
                result = true;
            }
        } else if (this.documents == 3) {
            if (!this.ImgsId.includes('PolicyAbsenceReport')) {
                this.policyAbsenceValidator = true;
                result = true;
            }
        } else if (this.documents == 4) {
            if (!this.ImgsId.includes('ProofOfEmergencyCase')) {
                this.proofOfEmergencyCaseValidator = true;
                result = true;
            }
        } else if (this.documents == 5) {
            if (!this.ImgsId.includes('stopwork')) {
                this.stopWorkValidator = true;
                result = true;
            }
            if (!this.ImgsId.includes('PassportOfficePrint')) {
                this.passportOfficePrintValidator = true;
                result = true;
            }
        }

        if (result) {
            this.attachmentsCheck =
                this.translate.currentLang == 'en-US'
                    ? 'Please Upload All required attachments'
                    : 'الرجاء ارفاق كل الملفات المطلوبة';
        } else this.attachmentsCheck = '';
    }

    ValidateClaimFormInputs() {
        if (
            this.DetailsForm.controls['claimaintMobile'].value == null ||
            this.DetailsForm.controls['claimaintMobile'].value == ''
        ) {
            this.claimaintMobileValidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else if (
            this.DetailsForm.controls['claimaintMobile'].value.charAt(0) !=
                '0' ||
            this.DetailsForm.controls['claimaintMobile'].value.charAt(1) != '5'
        ) {
            this.claimaintMobileValidator =
                this.translate.currentLang == 'en-US'
                    ? 'Mobile should start with 05'
                    : 'الرقم يجب أن يبدأ ب 05';
        } else if (
            this.DetailsForm.controls['claimaintMobile'].value.length < 10
        ) {
            this.claimaintMobileValidator =
                this.translate.currentLang == 'en-US'
                    ? 'Mobile should be 10 numbers at maximum'
                    : 'الرقم يجب أن يكون 10 أرقام';
        } else this.claimaintMobileValidator = '';

        if (
            this.DetailsForm.controls['policyMobile'].value == null ||
            this.DetailsForm.controls['policyMobile'].value == ''
        ) {
            this.policyMobileValidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else if (
            this.DetailsForm.controls['policyMobile'].value.charAt(0) != '0' ||
            this.DetailsForm.controls['policyMobile'].value.charAt(1) != '5'
        ) {
            this.policyMobileValidator =
                this.translate.currentLang == 'en-US'
                    ? 'Mobile should start with 05'
                    : 'الرقم يجب أن يبدأ ب 05';
        } else if (
            this.DetailsForm.controls['policyMobile'].value.length < 10
        ) {
            this.policyMobileValidator =
                this.translate.currentLang == 'en-US'
                    ? 'Mobile should be 10 numbers at maximum'
                    : 'الرقم يجب أن يكون 10 أرقام';
        } else this.policyMobileValidator = '';

        if (
            this.DetailsForm.controls['ddlbenefeciery'].value == null ||
            this.DetailsForm.controls['ddlbenefeciery'].value == ''
        ) {
            this.benefecieryValidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else this.benefecieryValidator = '';

        if (
            this.DetailsForm.controls['ddlRecuitment'].value == null ||
            this.DetailsForm.controls['ddlRecuitment'].value == ''
        ) {
            this.recuitmentValidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else this.recuitmentValidator = '';

        if (
            this.DetailsForm.controls['claimDescription'].value == null ||
            this.DetailsForm.controls['claimDescription'].value == ''
        ) {
            this.claimDescriptionValidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else this.claimDescriptionValidator = '';

        if (
            this.DetailsForm.controls['policyEmail'].value == null ||
            this.DetailsForm.controls['policyEmail'].value == ''
        ) {
            this.policyEmailValidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else if (
            !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
                this.DetailsForm.controls['policyEmail'].value
            )
        ) {
            this.policyEmailValidator =
                this.translate.currentLang == 'en-US'
                    ? 'Not a valid email'
                    : 'بريد إلكتروني غير صحيح';
        } else this.policyEmailValidator = '';

        if (
            this.DetailsForm.controls['claimaintEmail'].value == null ||
            this.DetailsForm.controls['claimaintEmail'].value == ''
        ) {
            this.claimaintEmailValidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else if (
            !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
                this.DetailsForm.controls['claimaintEmail'].value
            )
        ) {
            this.claimaintEmailValidator =
                this.translate.currentLang == 'en-US'
                    ? 'Not a valid email'
                    : 'بريد إلكتروني غير صحيح';
        } else this.claimaintEmailValidator = '';

        // if (
        //     this.DetailsForm.controls['expirydate'].value == null ||
        //     this.DetailsForm.controls['expirydate'].value == ''
        // ) {
        //     this.expirydatevalidator =
        //         this.translate.currentLang == 'en-US'
        //             ? 'This Field is Required'
        //             : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        // }
        // else this.expirydatevalidator = '';

        if (
            this.DetailsForm.controls['fromdate'].value == null ||
            this.DetailsForm.controls['fromdate'].value == ''
        ) {
            this.fromdatevalidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else this.fromdatevalidator = '';

        if (
            this.DetailsForm.controls['claimreason'].value == null ||
            this.DetailsForm.controls['claimreason'].value == ''
        ) {
            this.claimreasonvalidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else this.claimreasonvalidator = '';

        if (
            this.DetailsForm.controls['pickupdate'].value == null ||
            this.DetailsForm.controls['pickupdate'].value == ''
        ) {
            this.pickupdatevalidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else this.pickupdatevalidator = '';

        if (
            this.DetailsForm.controls['declare'].value == null ||
            this.DetailsForm.controls['declare'].value == ''
        ) {
            this.declarevalidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else this.declarevalidator = '';
        if (
            this.DetailsForm.controls['contractamt'].value == null ||
            this.DetailsForm.controls['contractamt'].value == ''
        ) {
            this.contractamountValidator =
                this.translate.currentLang == 'en-US'
                    ? 'This Field is Required'
                    : 'هذا الحقل اجباري و يجب تعبئة معلوماته';
        } else this.contractamountValidator = '';

        if (this.documents == null || this.documents == 0) {
            this.claimTypeDetailsValidator =
                this.translate.currentLang == 'en-US'
                    ? 'Please Select Claim Detail'
                    : 'الرجاء اختيار تفاصيل المطالبة ';
        } else this.claimTypeDetailsValidator = '';
    }

    SendOTPAndOpenVerificationDialog() {
        this.ValidateAllDocsAttached();
        this.ValidateClaimFormInputs();

        if ((this.recuitmentValidator == null ||
            this.recuitmentValidator == '') &&
            (this.claimDescriptionValidator == null ||
                this.claimDescriptionValidator == '') &&
            (this.benefecieryValidator == null ||
                this.benefecieryValidator == '') &&
            (this.expirydatevalidator == null ||
                this.expirydatevalidator == '') &&
            (this.fromdatevalidator == null || this.fromdatevalidator == '') &&
            (this.claimreasonvalidator == null ||
                this.claimreasonvalidator == '') &&
            (this.pickupdatevalidator == null ||
                this.pickupdatevalidator == '') &&
            (this.declarevalidator == null || this.declarevalidator == '') &&
            (this.contractamountValidator == null ||
                this.contractamountValidator == '') &&
            (this.attachmentsCheck == null || this.attachmentsCheck == '') &&
            (this.policyMobileValidator == null ||
                this.policyMobileValidator == '') &&
            (this.policyEmailValidator == null ||
                this.policyEmailValidator == '') &&
            (this.claimaintMobileValidator == null ||
                this.claimaintMobileValidator == '') &&
            (this.claimaintEmailValidator == null ||
                this.claimaintEmailValidator == '')
            &&
            (this.claimTypeDetailsValidator == null ||
                this.claimTypeDetailsValidator == '')
        ) {
            let otpObject = {
                ActionType: SMSActionType.IssueClaim,
                Lob: LineOfBusiness.DomesticLabor,
                IqamaId: this.policyDetails.iqamaId,
                MobileNumber: this.policyDetails.mobile,
                isWeb: true
            }

            this.otpService.sendOTP(otpObject).subscribe((res) => {
                let result = res;
                if (result.errors.length <= 0) {
                    const dialogRef = this._dialog.open(OtpVerificationDialogComponent, {
                        data: { otpDetails: result },
                        width: '600px',
                        disableClose:true
                    });
                    dialogRef.afterClosed().subscribe((data) => {
                        if (data.verified) {
                            this.saveClaims();
                        }
                    });
                }
            });
        }
    }
}
