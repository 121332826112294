import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import { UploadMembersDialogComponent } from '../upload-members-dialog/upload-members-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MedicalSmeService } from 'src/app/core/services/Medical-Sme-Service/medical-sme-service';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';

@Component({
  selector: 'app-add-member-dialog',
  templateUrl: './add-member-dialog.component.html',
  styleUrls: ['./add-member-dialog.component.scss']
})
export class AddMemberDialogComponent implements OnInit {

  AddMemberForm: FormGroup;
  isLoading: boolean;
  isErrorOccured: boolean;
  WathqCompanyDetails: any;
  MembersList: any;
  RelationList: any;
  ErrorMessage: any;
  MaritalStatusList: any;
  sponsorId: any;
  quotationNumber: any;
  passedMembers: any = [];
  failedMembers: any = [];
  isShowSponsor2: boolean = true;
  Types: any;
  SMEClasses: any[];
  choosenRelation: any;
  today = new Date();
  isApproved: boolean = false;
  QuotationNumber: any;

  constructor(private _formbuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddMemberDialogComponent>, private smeService: MedicalSMEService,
    public medicalSmeService: MedicalSmeService,
    public translate: TranslateService,
    public _LocalService: LocalService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.AddMemberForm = this._formbuilder.group({
      IqamaId: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      MemberId: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      MobileNumber: ['', [Validators.required]],
      ExceptionSponsor: [''],
      Relation: ['', [Validators.required]],
      ClassType: ['', [Validators.required]],
      BirthDate: ['', [Validators.required]],
      MaritalStatus: ['', [Validators.required]],
      Height: ['', [Validators.required, Validators.pattern(/^\d*\.?\d*$/), Validators.max(4)]],
      Weight: ['', [Validators.required, Validators.pattern(/^\d+$/), Validators.max(300)]],
    });

    this.quotationNumber = data.quotationNumber;
    this.sponsorId = data.sponsorId;
  }

  ngOnInit(): void {
    this.medicalSmeService.GetSmeMedicalAges().subscribe((res) => {

      this.RelationList = res;
    });
    this.GetSMEClasses();

    this.MaritalStatusList = [
      {
        Code: 'S',
        Name: 'Single'
      },
      {
        Code: 'M',
        Name: 'Married'
      },
      {
        Code: "D",
        Name: 'Divorced'
      },
      {
        Code: 'W',
        Name: 'Widowed'
      },
      {
        Code: "SP",
        Name: 'Separated'
      },
    ]
  }

  get newMemberFormControl() {
    return this.AddMemberForm.controls;
  }

  setAll(event: any) {
    this.isApproved = event;
  }

  GetSMEClasses() {

    this.isLoading = true;
    this.SMEClasses = [];
    this.smeService.GetSMEClasses().subscribe(
      (res) => {
        for (let index = 0; index < res?.length; index++) {
          this.SMEClasses.push(res[index]);
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  focusOutIqama(event: any) {
    this.AddMemberForm.controls['MemberId'].setValue(this.AddMemberForm.controls['IqamaId'].value);
  }
  onRelationChange(event: any) {
    this.isShowSponsor2 = true;
    this.choosenRelation = event;

    if (event.source.value == 2 || event.source.value == 3)
      this.isShowSponsor2 = false;
  }

  AddMember() {

    this.isLoading = true;
    this.isErrorOccured = false;

    let queryString;
    let month = this.handleBirthDateDayAndMonthFormat(this.AddMemberForm.controls['BirthDate'].value.getMonth() + 1);
    let year = this.AddMemberForm.controls['BirthDate'].value.getFullYear();

    let BirthDate = `${month}-${year}`;
    queryString = `identityNumber=${this.AddMemberForm.controls['IqamaId'].value}&MobilePhone=${this.AddMemberForm.controls['MobileNumber'].value}&dateOfBirth=${BirthDate}`;

    let relationDesc = this.RelationList.find(x => x.type === this.AddMemberForm.controls['Relation'].value);
    let MaritalStatusDesc = this.MaritalStatusList.find(x => x.Code === this.AddMemberForm.controls['MaritalStatus'].value);
    // let sponsorId = this.AddMemberForm.controls['ExceptionSponsor'].value != null ?
    //  this.AddMemberForm.controls['ExceptionSponsor'].value : this.sponsorId

    let className = this.SMEClasses.find(x => x.id === this.AddMemberForm.controls['ClassType'].value);
    let EmailAddress = this._LocalService.getJsonValue('SponsorEmail');
    let MobileNumber = this._LocalService.getJsonValue('SponsorMobile');
    let CompanyName = this._LocalService.getJsonValue('CompanyName');

    let requestData = {
      CompanyId: localStorage.getItem("crEntityNumber"),
      EmailAddress: EmailAddress,
      MobileNumber: MobileNumber,
      CompanyName: CompanyName
    };

    if (this.quotationNumber == null || this.quotationNumber == undefined || this.quotationNumber == 0 || this.quotationNumber == "") {
      this.smeService.GetQuotationId(requestData).subscribe(
        (res) => {
          if (res != null) {
            this.quotationNumber = res;
            localStorage.removeItem('quotationNumber');
            localStorage.setItem('quotationNumber', this.quotationNumber);

            let newMemberDTO = {
              iqamaId: this.AddMemberForm.controls['IqamaId'].value,
              RelationDesc: relationDesc.typeName,
              relationCode: this.AddMemberForm.controls['Relation'].value,
              mainMemberID: Number(this.AddMemberForm.controls['MemberId'].value),
              classNumber: this.AddMemberForm.controls['ClassType'].value,
              className: className.name,
              mobileNumber: this.AddMemberForm.controls['MobileNumber'].value,
              birthDate: this.AddMemberForm.controls['BirthDate'].value,
              maritalStatusDesc: MaritalStatusDesc.Name,
              maritalStatusCode: this.AddMemberForm.controls['MaritalStatus'].value,
              weight: this.AddMemberForm.controls['Weight'].value,
              height: this.AddMemberForm.controls['Height'].value,
              sponsorId: this.sponsorId,
              quotationNumber: this.quotationNumber
            }
            this.smeService.AddMember(newMemberDTO).subscribe(
              (res) => {
                if (res != null) {
                  res.successMembers.forEach((element) => {
                    this.passedMembers.push(element);
                  });
                  res.failedMembers.forEach((element) => {
                    this.failedMembers.push(element);
                  });
                }
                this.isLoading = false;
                this.dialogRef.close({
                  data: {
                    successMembers: this.passedMembers,
                    failedMembers: this.failedMembers,
                  },
                });
                this.isLoading = false;
              },
              (err) => {
                this.isErrorOccured = true;
                this.ErrorMessage = err[0].error
                this.isLoading = false;
              }
            );
          }
        },
        (err) => { }
      );
    } else {
      let newMemberDTO = {
        iqamaId: this.AddMemberForm.controls['IqamaId'].value,
        RelationDesc: relationDesc.typeName,
        relationCode: this.AddMemberForm.controls['Relation'].value,
        mainMemberID: Number(this.AddMemberForm.controls['MemberId'].value),
        classNumber: this.AddMemberForm.controls['ClassType'].value,
        className: className.name,
        mobileNumber: this.AddMemberForm.controls['MobileNumber'].value,
        birthDate: this.AddMemberForm.controls['BirthDate'].value,
        maritalStatusDesc: MaritalStatusDesc.Name,
        maritalStatusCode: this.AddMemberForm.controls['MaritalStatus'].value,
        weight: this.AddMemberForm.controls['Weight'].value,
        height: this.AddMemberForm.controls['Height'].value,
        sponsorId: this.sponsorId,
        quotationNumber: this.quotationNumber
      }
      this.smeService.AddMember(newMemberDTO).subscribe(
        (res) => {
          if (res != null) {
            res.successMembers.forEach((element) => {
              this.passedMembers.push(element);
            });
            res.failedMembers.forEach((element) => {
              this.failedMembers.push(element);
            });
          }
          this.isLoading = false;
          this.dialogRef.close({
            data: {
              successMembers: this.passedMembers,
              failedMembers: this.failedMembers,
            },
          });
          this.isLoading = false;
        },
        (err) => {
          this.isErrorOccured = true;
          this.ErrorMessage = err[0].error
          this.isLoading = false;
        }
      );
    }


  }

  handleBirthDateDayAndMonthFormat(value) {
    switch (value) {
      case '1':
        return '01';
      case '2':
        return '02';
      case '3':
        return '03';
      case '4':
        return '04';
      case '5':
        return '05';
      case '6':
        return '06';
      case '7':
        return '07';
      case '8':
        return '08';
      case '9':
        return '09';
      default:
        return value;
    }
  }

  ClearValidation() {
    this.isErrorOccured = false;
    this.ErrorMessage = '';
  }
}
