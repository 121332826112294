<div class="maincontaienr">
    <div class="carimg">
        <div class="trafficfunnel">
            <div class="buildingsimg">
                <div class="container2">
                    <!--Main Content Container-->
                    <div class="midcontainer">
                        <div class="left-container">
                            <h1 class="title-h1">{{ "motor.Driven" | translate }}</h1>
                            <div class="underlinelogin"></div>
                        </div>

                        <div class="right-container">
                            <!--Register Container-->
                            <div class="registercontainer">
                                <h2>{{ "motor.InsurePolicy" | translate }}</h2>

                                <div class="registercontent">
                                    <form [formGroup]="idForm">
                                        <span>{{
                                            "SERVICES.ID" | translate
                                        }}</span>
                                        <div
                                            class=" registerationform"
                                        >
                                            <input
                                                placeholder="{{
                                                    'motor.enterNational'
                                                        | translate
                                                }}"
                                                type="text"
                                                id="form12"
                                                class="form-control"
                                                maxlength="10"
                                                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '');"
                                                formControlName="Iqama"
                                                [ngClass]="{
                                                'red-border-class':
                                                    idFormControl.Iqama.errors &&
                                                    idFormControl.Iqama.touched ,
                                              
                                            }"
                                            />
                                            <div
                                                *ngIf="
                                                    idFormControl.Iqama
                                                        .errors &&
                                                    idFormControl.Iqama.touched
                                                "
                                            >
                                                <div
                                                    *ngIf="
                                                        idFormControl.Iqama
                                                            .errors.required
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        idFormControl.Iqama
                                                            .errors.pattern
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.DRIVER_ID_PLACEHOLDER"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>

                                        <span>{{
                                            "MMLHome.Birthdate" | translate
                                        }}</span>
                                        <div
                                            class=" registerationform"
                                            data-mdb-datepicker-init
                                            data-mdb-input-init
                                        >
                                            <input
                                                placeholder="DD/MM/YYYY"
                                                type="text"
                                                class="form-control"
                                                id="exampleDatepicker11"
                                            />
                                        </div>

                                        <span>{{
                                            "MEDICAL.MobileNumber" | translate
                                        }}</span>
                                        <div
                                            class=" registerationform"
                                            data-mdb-input-init
                                        >
                                            <input
                                                placeholder="{{
                                                    'motor.enterMobile'
                                                        | translate
                                                }}"
                                                type="tel"
                                                id="typePhone"
                                                class="form-control"
                                                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                formControlName="MobileNumber"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        idFormControl
                                                            .MobileNumber
                                                            .errors &&
                                                        idFormControl
                                                            .MobileNumber
                                                            .touched
                                                }"
                                            />
                                            <div
                                                *ngIf="
                                                    idFormControl.MobileNumber
                                                        .errors.required &&
                                                    idFormControl.MobileNumber
                                                        .touched
                                                "
                                                style="color: #ff001f"
                                            >
                                                <i
                                                    class="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                ></i>
                                                {{
                                                    "GENERIC.REQUIRED"
                                                        | translate
                                                }}
                                            </div>
                                            <div
                                                *ngIf="
                                                    idFormControl.MobileNumber
                                                        .errors.pattern &&
                                                    idFormControl.MobileNumber
                                                        .touched
                                                "
                                                style="color: #ff001f"
                                            >
                                                <i
                                                    class="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                ></i>
                                                {{
                                                    "GENERIC.MN_PLACEHOLDER"
                                                        | translate
                                                }}
                                            </div>
                                        </div>

                                        <button
                                            type="button"
                                            class="btn btn-primary loginbutton"
                                            data-mdb-ripple-init
                                            (click)="handleMotorLogin()"
                                        >
                                            {{ "MEDICAL.Search" | translate }}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
