declare var require: any

import { Injectable } from '@angular/core';
//import SecureStorage from 'secure-web-storage';
const _SecureStorage = require('secure-web-storage');
const SECRET_KEY = 'secret_key';
//import CryptoJS from 'crypto-js';
var _CryptoJS = require("crypto-js");
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}
  public secureStorage = new _SecureStorage(localStorage, {
    hash: function hash(key: string): any {
      key = _CryptoJS.SHA256(key, SECRET_KEY);
      return key.toString();
    },
    // Encrypt the localstorage data
    encrypt: function encrypt(data: string) {
      data = _CryptoJS.AES.encrypt(data, SECRET_KEY);
      data = data.toString();
      return data;
    },
    // Decrypt the encrypted data
    decrypt: function decrypt(data: any) {
      data = _CryptoJS.AES.decrypt(data, SECRET_KEY);
      data = data.toString(_CryptoJS.enc.Utf8);
      return data;
    },
  });
}
