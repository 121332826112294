import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {complaintSaveModel} from '../../../../../core/interfaces/complaint-save-model';
import Swal from 'sweetalert2';
import {MotorClaimsService} from '../../../../../core/services/motor-claims.service';

@Component({
  selector: 'app-register-complaint',
  templateUrl: './register-complaint.component.html',
  styleUrls: ['./register-complaint.component.scss'],
    providers: [DatePipe]
})
export class RegisterComplaintComponent implements OnInit {
    complaintForm: FormGroup;
    isSubmit:boolean =false;
    timeNow: any = new Date();
    get f() {
        return this.complaintForm.controls;
    }

    caseTypeList:any = [{'nameAr':'استفسار','nameEn':'Technical support','id':'Technical support'}
        ,{'nameAr':'شكوى','nameEn':'Complaint','id':'Complaint'}
        ,{'nameAr':'اقتراح','nameEn':'Suggestion','id':'Suggestion'},];

    areaList:any = [{'nameAr':'الرياض','nameEn':'Riyadh','id':'Riyadh'}
        ,{'nameAr':'الدمام','nameEn':'“Dammam','id':'“Dammam'}
        ,{'nameAr':'جده','nameEn':'Jeddah','id':'Jeddah'},];

    about:any = [{'nameAr':'مطالبة مركبة','nameEn':'Motor claim','id':'Motor claim'}
        ,{'nameAr':'التأخر في سداد مطالبة','nameEn':' Delay In Transfer Claim Amount','id':' Delay In Transfer Claim Amount'}
        ,{'nameAr':'إلغاء وثيقة التأمين','nameEn':'Policy Cancellation','id':'Policy Cancellation'}
        ,{'nameAr':'ربط نجم','nameEn':'Najm upload','id':'Najm upload'}
        ,{'nameAr':'أخرى','nameEn':'Other','id':'Other'},];

    constructor(public fb: FormBuilder, public translate: TranslateService, private datePipe: DatePipe,public motorClaimsService:MotorClaimsService) { }

  ngOnInit(): void {
        this.buildForm();
  }
    buildForm() {
        this.timeNow = this.datePipe.transform(this.timeNow, 'yyyy-MM-dd');
        const  EmployeeNoPattern = "/^[0-9\\s]*$/";
        const idNumber = '^[0-9]+$';
        let emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var mobilePattern = "^(05)([0-9]{8})$";
        const IBANpattern = "^(SA)([0-9-a-z]{22})$"
        this.complaintForm = this.fb.group({
            caseType: [null,[Validators.required,]],
            about: [null,[Validators.required,]],
            policyNo: [null,[Validators.required,]],
            details: [null,[Validators.required,]],
            nationalId: [null,[Validators.required,]], //Validators.pattern(EmployeeNoPattern)
            name: [null,Validators.required],
            dateOfBirth: [null,Validators.required],
            area: [null,Validators.required],
            email: [null,[Validators.required,Validators.pattern(emailPattern)]],
            phone: [null,[Validators.required,Validators.pattern(mobilePattern)]],
        });

    }
    Submit(){
        this.isSubmit = true;
        if (this.complaintForm.valid){
            let saveModel : complaintSaveModel = new class implements complaintSaveModel {
                area: string;
                casedesc: string;
                casetype: string;
                customeR_NAME: string;
                dob: Date;
                email: string;
                id: string;
                mobileno: string;
                nationalid: string;
                policyno: string;
                policytype: string;
                status: string;
            }
            saveModel.casetype = this.f.caseType.value;
            saveModel.area = this.f.area.value;
            saveModel.casedesc = this.f.details.value;
            saveModel.customeR_NAME = this.f.name.value;
            saveModel.dob =new Date(this.f.dateOfBirth.value) ;
            saveModel.email = this.f.email.value;
            saveModel.mobileno = this.f.phone.value;
            saveModel.nationalid = this.f.nationalId.value;
            saveModel.policyno = this.f.policyNo.value;


            this.motorClaimsService.saveComplaint(saveModel).subscribe(res=>{

            });
        }  else {
            Swal.fire({
                icon: 'warning',
                text: this.translate.currentLang == 'en-US'?"Please fill all mandatory fields" : "يرجى ملء جميع الحقول الإلزامية",
            });
        }

    }
}
