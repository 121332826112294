<div class="">
    <div class="row white">
        <div class="col-lg-6 col-md-6 col-sm-12 square">
            <h4>{{ "LogIn.dear" | translate }}</h4>
            <hr class="horizontal-line" />
            <ul style="text-align: justify">
                <li>
                    {{ "LogIn.des1" | translate }}
                </li>
                <li>
                    {{ "LogIn.des2" | translate }}
                </li>
                <li>
                    {{ "LogIn.des3" | translate }}
                </li>
                <li>
                    {{ "LogIn.des4" | translate }}
                </li>
            </ul>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="login-form-container">
                <div
                    class="container height-100 d-flex justify-content-center align-items-center"
                >
                    <div class="position-relative">
                        <div
                            class="card p-2 text-center"
                            (keyup.enter)="navigate()"
                        >
                            <h6>{{ "Verification.title" | translate }}</h6>
                            <div class="message">
                                <i><mat-icon> chat</mat-icon></i>
                                <span
                                    >{{ "Verification.code" | translate
                                    }}{{ display }}
                                </span>
                            </div>
                            <div
                                id="otp"
                                class="inputs d-flex flex-row justify-content-center mt-2"
                                *ngIf="this.translate.currentLang == 'en-US'"
                            >
                                <ngx-otp-input
                                    [config]="otpInputConfig"
                                    (otpChange)="handeOtpChange($event)"
                                    (fill)="handleFillEvent($event)"
                                    (keyup.enter)="navigate()"
                                >
                                </ngx-otp-input>
                            </div>
                            <div
                                id="otp"
                                class="inputs d-flex flex-row justify-content-center mt-2"
                                *ngIf="this.translate.currentLang == 'ar-SA'"
                                style="direction: ltr"
                            >
                                <ngx-otp-input
                                    [config]="otpInputConfig"
                                    (otpChange)="handeOtpChange($event)"
                                    (fill)="handleFillEvent($event)"
                                    (keyup.enter)="navigate()"
                                >
                                </ngx-otp-input>
                            </div>
                            <div class="mt-4">
                                <button
                                    class="btn px-4 validate"
                                    (click)="resendCode()"
                                    [disabled]="seconds !== 0"
                                >
                                    {{ "Verification.resend" | translate }}
                                </button>
                                <button
                                    class="btn px-4 validate login-btn"
                                    [disabled]="disableBTN || !ValidOTP"
                                    (click)="navigate()"
                                    (keyup.enter)="navigate()"
                                >
                                    {{ "Verification.verify" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
