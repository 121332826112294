import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/core/AppConfigService';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MessageDialogComponent } from 'src/app/core/shared/message-dialog/message-dialog.component';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-e-service-home',
    templateUrl: './e-service-home.component.html',
    styleUrls: ['./e-service-home.component.scss'],
})
export class EServiceHomeComponent implements OnInit {
    labelName: number;
    products: any;
    elClass = 'en';
    label = '0';
    languageValue = localStorage.getItem('language');
    arrow = '🡲';
    numOfSec: number;
    userVerified = this._LocalService.getJsonValue('userVerified');

    productsObj = [
        {
            title: 'Products.Medical',
            des: 'Products.Medical_Descreption',
            firstiIcon: 'assets/img/prouducts/doctor-bag.png',
            secondIcon: 'assets/img/prouducts/Medica-w.png',
            index: 0,
            subItems: [
                {
                    title: 'SERVICES.POLICYINFO',
                    link: '/medical-profile',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: 'medical',
                },

                {
                    title: 'MEDICAL.FamilyMembers',
                    link: '/family-members',
                    icon: 'assets/img/prouducts/1 (2).png',
                    eventItem: 'medical',
                },
                {
                    title: 'MEDICAL.MedicalBenefits',
                    link: '/medical-benefits',
                    icon: 'assets/img/prouducts/1 (3).png',
                    eventItem: 'medical',
                },
                {
                    title: 'MEDICAL.MedicalApprovals',
                    link: '/medical-approvals',
                    icon: 'assets/img/prouducts/1 (5).png',
                    eventItem: 'medical',
                },
                {
                    title: 'MEDICAL.ServiceProvider',
                    link: '/medical-service-providers',
                    icon: 'assets/img/prouducts/1 (4).png',
                    eventItem: 'medical',
                },

                {
                    title: 'MEDICAL.CashClaims',
                    link: '/medical-claims',
                    icon: 'assets/img/prouducts/1 (1).png',
                    eventItem: 'medical',
                },

                {
                    // title: 'MEDICAL.EmbassyLetter',
                    // link: '/embassy-letter',
                    title: 'MEDICAL.ExtendVisa',
                    link: 'https://guv.gulfunion-saudi.com/#/public',
                    icon: 'assets/img/prouducts/1 (6).png',
                    eventItem: 'medical',
                },
                {
                    title: 'MEDICAL.EmbassyLetter',
                    link: '/embassy-letter',
                    // title: 'MEDICAL.ExtendVisa',
                    // link: 'https://guv.gulfunion-saudi.com/#/public',
                    icon: 'assets/img/medical-sme/Embassy Letter.jpg',
                    eventItem: 'medical',
                },
            ],
        },
        {
            title: 'Products.Motor',
            des: 'Products.Motor_Descreption',
            firstiIcon: 'assets/img/prouducts/Motor-g.png',
            secondIcon: 'assets/img/prouducts/sports-car.png',
            index: 1,
            subItems: [
                // {
                //     title: 'SERVICES.MOtOR_Claim',
                //     //link: '/new-motor-claim',
                //     link: ' https://mytameen.gulfunion-saudi.com/',

                //     icon: 'assets/img/prouducts/policy.jpg',
                //     eventItem: 'motor',
                // },
                // {
                //     title: 'SERVICES.MOtOR_Claim',
                //     link: '/motor-claims',
                //     icon: 'assets/img/prouducts/policy.jpg',
                //     eventItem: 'motor',
                // },
                {
                    title: 'SERVICES.CLAIMS',
                    link: '/new-motor-claim',
                    //link: ' https://mytameen.gulfunion-saudi.com/',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: 'motor',
                },
                {
                    title: 'SERVICES.CLAIMSINQUIRY',
                    link: '/motor-login',
                    //link: ' https://mytameen.gulfunion-saudi.com/',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: 'motor',
                },
                {
                    title: 'SERVICES.POLICYDETAILS',
                    link: '/motor-policy-login',
                    //link: ' https://mytameen.gulfunion-saudi.com/',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: 'motor',
                },
            ],
        },
        {
            title: 'SME.HOMESMETITLE',
            des: 'SME.HOMESMEDESC',
            firstiIcon: 'assets/img/prouducts/doctor-bag.png',
            secondIcon: 'assets/img/prouducts/Medica-w.png',
            index: 2,
            subItems: [
                {
                    title: 'SME.FASTCALCQUOTATION',
                    link: '/medical-sme-login',
                    icon: 'assets/img/medical-sme/Sme.jpg',
                    eventItem: 'sme',
                },
            ],
        },
        {
            title: 'MMLHome.MedicalMalpractice',
            des: 'Products.Motor_Descreption',
            firstiIcon: 'assets/img/prouducts/MML-G.png',
            secondIcon: 'assets/img/prouducts/MML-W.png',
            index: 3,
            subItems: [
                {
                    title: 'MMLHome.PurchasePolicy',
                    link: '/medical-malpractice',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: null,
                },
                {
                    title: 'MMLHome.PolicyInquiry',
                    link: '/medical-malpractice-inquiry',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: null,
                },
                {
                    title: 'MEDICAL.CashClaims',
                    link: '/mmp-cash-claims',
                    icon: 'assets/img/prouducts/1 (1).png',
                    eventItem: null,
                },
            ],
        },
        {
            title: 'Products.Home',
            des: 'Products.Home_Descreption',
            firstiIcon: 'assets/img/prouducts/home.png',
            secondIcon: 'assets/img/prouducts/Home-w.png',
            index: 4,
        },

        {
            title: 'Products.Marine',
            des: 'Products.Marine_Descreption',
            firstiIcon: 'assets/img/prouducts/steering.png',
            secondIcon: 'assets/img/prouducts/Marin-w.png',
            index: 5,
        },
        {
            title: 'Products.Engineering',
            des: 'Products.Engineering_Descreption',
            firstiIcon: 'assets/img/prouducts/Engineering.png',
            secondIcon: 'assets/img/prouducts/Engineering-White.png',
            index: 6,
        },
        {
            title: 'Products.DL',
            des: 'Products.DL_Descreption',
            firstiIcon: 'assets/img/prouducts/home.png',
            secondIcon: 'assets/img/prouducts/Home-w.png',
            index: 7,
            subItems: [
                {
                    title: 'SERVICES.DLPOL',
                    link: '/domestic-policy-login',
                    //link: ' https://mytameen.gulfunion-saudi.com/',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: 'motor',
                },
            ],
        },
    ];

    productsObjwithOut = [
        {
            title: 'Products.Motor',
            des: 'Products.Motor_Descreption',
            firstiIcon: 'assets/img/prouducts/Motor-g.png',
            secondIcon: 'assets/img/prouducts/sports-car.png',
            index: 0,
            subItems: [
                {
                    title: 'SERVICES.MOtOR_Claim',
                    //link: '/new-motor-claim',
                    link: ' https://mytameen.gulfunion-saudi.com/',

                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: 'motor',
                },
            ],
        },
        {
            title: 'Products.Medical',
            des: 'Products.Medical_Descreption',
            firstiIcon: 'assets/img/prouducts/doctor-bag.png',
            secondIcon: 'assets/img/prouducts/Medica-w.png',
            index: 1,
            subItems: [
                {
                    title: 'SERVICES.POLICYINFO',
                    link: '/medical-profile',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: null,
                },

                {
                    title: 'MEDICAL.FamilyMembers',
                    link: '/family-members',
                    icon: 'assets/img/prouducts/1 (2).png',
                    eventItem: null,
                },
                {
                    title: 'MEDICAL.MedicalBenefits',
                    link: '/medical-benefits',
                    icon: 'assets/img/prouducts/1 (3).png',
                    eventItem: null,
                },
                {
                    title: 'MEDICAL.MedicalApprovals',
                    link: '/medical-approvals',
                    icon: 'assets/img/prouducts/1 (5).png',
                    eventItem: null,
                },
                {
                    title: 'MEDICAL.ServiceProvider',
                    link: '/medical-service-providers',
                    icon: 'assets/img/prouducts/1 (4).png',
                    eventItem: null,
                },

                {
                    title: 'MEDICAL.CashClaims',
                    link: '/medical-claims',
                    icon: 'assets/img/prouducts/1 (1).png',
                    eventItem: null,
                },

                {
                    // title: 'MEDICAL.EmbassyLetter',
                    // link: '/embassy-letter',
                    title: 'MEDICAL.ExtendVisa',
                    link: 'https://guv.gulfunion-saudi.com/#/public',
                    icon: 'assets/img/prouducts/1 (6).png',
                    eventItem: null,
                },
            ],
        },
        {
            title: 'MMLHome.MedicalMalpractice',
            des: 'Products.Motor_Descreption',
            firstiIcon: 'assets/img/prouducts/MML-G.png',
            secondIcon: 'assets/img/prouducts/MML-W.png',
            index: 2,
            subItems: [
                {
                    title: 'MMLHome.PurchasePolicy',
                    link: '/medical-malpractice',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: null,
                },
                {
                    title: 'MMLHome.PolicyInquiry',
                    link: '/medical-malpractice',
                    icon: 'assets/img/prouducts/policy.jpg',
                    eventItem: null,
                },
            ],
        },
        {
            title: 'Products.Home',
            des: 'Products.Home_Descreption',
            firstiIcon: 'assets/img/prouducts/home.png',
            secondIcon: 'assets/img/prouducts/Home-w.png',
            index: 4,
        },
        {
            title: 'Products.Marine',
            des: 'Products.Marine_Descreption',
            firstiIcon: 'assets/img/prouducts/steering.png',
            secondIcon: 'assets/img/prouducts/Marin-w.png',
            index: 5,
        },
        {
            title: 'Products.Engineering',
            des: 'Products.Engineering_Descreption',
            firstiIcon: 'assets/img/prouducts/Engineering.png',
            secondIcon: 'assets/img/prouducts/Engineering-White.png',
            index: 6,
        },
    ];

    selectedService: boolean = false;
    productsCards: any;
    MedSme: boolean = false;

    constructor(
        private _LocalService: LocalService,
        public _router: Router,
        public translate: TranslateService,
        private environment: AppConfigService,
        private dialog: MatDialog
    ) {
        this.labelName = 0;
        var x = environment?.config?.MedicalSme;
        if (x == 'true') this.productsCards = this.productsObj;
        else this.productsCards = this.productsObjwithOut;

        this._LocalService.removeJsonValue('motorClaimType');
    }

    disableMotor: any = false;
    checkMotorService = (tabIndex) => {
        if (tabIndex == 1) {
            if (
                new Date(this.environment?.config?.MotorStartDate) <=
                    new Date() &&
                new Date() <= new Date(this.environment?.config?.MotorEndDate)
            ) {
                this.disableMotor = true;
                this.dialog.open(MessageDialogComponent, {
                    data: {
                        message: this.environment?.config?.MotorMessageEN,
                        messageAR: this.environment?.config?.MotorMessageAR,
                    },
                    width: '500px',
                    height: '300px',
                });
            } else this.disableMotor = false;
        } else this.disableMotor = false;
    };

    ngOnInit(): void {
        this.languageValue = localStorage.getItem('language');
        this.userVerified = this._LocalService.getJsonValue('userVerified');
        localStorage.removeItem('policyInfo');
        localStorage.removeItem('CitizenInfo');
        localStorage.removeItem('company');
        localStorage.removeItem('premiumData');
        localStorage.removeItem('remainingMembers');
        localStorage.removeItem('claimDetails');
    }

    selectedTabValue(event) {
        this.labelName = event.index;
        this.checkMotorService(this.labelName);
        this.label = `${this.labelName}`;
    }

    ngDoCheck() {
        this.languageValue = localStorage.getItem('language');
        if (this.languageValue === 'en-US') {
            this.arrow = '🡲';
            this.elClass = 'en';
        } else {
            this.arrow = '🡰';
            this.elClass = 'ar';
        }
    }
    logInoPage(link, eventitem) {
        if (eventitem === 'motor') {
            this.ChooseClaimType(link);
        } else if (eventitem === 'sme') {
            window.location.href = '/medical-sme-login';
        } else {
            if (link === '/medical-malpractice') {
                window.location.href = '/medical-malpractice';
            } else if (link === '/medical-malpractice-inquiry') {
                window.location.href = '/medical-malpractice-inquiry';
            } else if (link === 'https://guv.gulfunion-saudi.com/#/public') {
                window.open(link);
            } else {
                if (this.userVerified === true) {
                    window.location.href = `${link}`;
                } else {
                    
                    this._LocalService.removeJsonValue('product');
                    if(link == '/mmp-cash-claims'){
                        this._LocalService.setJsonValue('product','mmp');
                    } else if (eventitem == 'medical') {
                        this._LocalService.setJsonValue('product','medical');
                    }

                    this._LocalService.setJsonValue('navLink', link);
                    window.location.href = '/user-login';
                }
            }
        }
    }

    async ChooseClaimType(link) {
        if (link === '/new-motor-claim') {
            this.selectedService = true;
            let dir: string = '';
            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }

            const { value: TypeId } = await Swal.fire({
                title:
                    this.translate.currentLang == 'en-US'
                        ? 'Choose Your Claim Type'
                        : 'اختر نوع الوثيقة',
                iconHtml:
                    '<img width=40 height=40 src="assets/img/swal-icons-img/writing.png">',
                input: 'radio',
                showCloseButton: true,
                confirmButtonText:
                    this.translate.currentLang == 'en-US' ? 'Submit' : 'متابعة',
                inputLabel: 'Your email address',
                inputOptions: {
                    '8':
                        this.translate.currentLang == 'en-US'
                            ? 'Comprehensive'
                            : 'شامل',
                    '7':
                        this.translate.currentLang == 'en-US'
                            ? 'Third Party'
                            : 'طرف ثالث',
                },
                didClose: () => {
                    this.selectedService = false;
                },
                inputValidator: (value: any) => {
                    if (!value) {
                        return this.translate.currentLang == 'en-US'
                            ? 'Please Select Claim Type'
                            : 'يرجي اختيار نوع الوثيقة';
                    }
                },
                customClass: {
                    icon: 'icon-img',
                    title: 'claim-type-swal-title',
                    input: 'claim-type-swal-input',
                    confirmButton: 'claim-type-confirm-btn',
                },
            });
            if (TypeId) {
                // pass type id to motor claim
                let claimType = { typeId: TypeId };
                this._LocalService.setJsonValue('motorClaimType', claimType);
                this._router.navigate(['/new-motor-claim']);
            }
        } else {
            window.open(link, '_self');
        }
    }
}
