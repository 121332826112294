import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import { MotorClaimsService } from './core/services/motor-claims.service';
import { LocalService } from './core/services/LocalService/LocalService.service';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from './core/services/UserService/user.service';
import { ToasterService } from './core/services/toaster.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent {
    private timeout: any = null;
    private readonly TIMEOUT_IN_MS = 10 * 60 * 1000; // 10 minutes
    OTPValue: any;
    location: any;
    routerSubscription: any;
    public textDir = 'rtl';
    lang = localStorage.getItem('language');
    constructor(
        private router: Router,
        public motorClaimsService: MotorClaimsService,
        public user: UserService,
        private _LocalService: LocalService,
        public toaster: ToasterService
    ) {
        if (this.lang === 'ar-SA') {
            this.textDir = 'rtl';
        } else if (this.lang === 'en-US') {
            this.textDir = 'ltr';
        } else {
            localStorage.setItem('language', 'ar-SA');

            this.textDir = 'rtl';
        }
        this.OTPValue = null;
       
    }
    resetTimeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.logout();
            // Perform logout logic here
        }, this.TIMEOUT_IN_MS);
    }

    ngOnInit() {
        document.addEventListener('click', () => this.resetTimeout());
        document.addEventListener('keypress', () => this.resetTimeout());
        document.addEventListener('mousemove', () => this.resetTimeout());

        this.recallJsFuntions();
    }
    changeLang() {
        this.lang = localStorage.getItem('language');
        if (this.lang === 'ar-SA') {
            this.textDir = 'rtl';
        } else {
            this.textDir = 'ltr';
        }
    }
    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                $.getScript('../assets/js/custom.js');
                this.location = this.router.url;
                console.log(this.router);
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
    logout() {
        this._LocalService.setJsonValue('userVerified', null);
        this._LocalService.setJsonValue('userLoginInfo', null);
        this._LocalService.setJsonValue('memberDetails', null);
        window.location.reload();
    }
}
