import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-fast-calculation-summary-dialog',
    templateUrl: './fast-calculation-summary-dialog.component.html',
    styleUrls: ['./fast-calculation-summary-dialog.component.scss'],
})
export class FastCalculationSummaryDialogComponent implements OnInit {
    classes: any = [];
    fastCalcDetails: any = [];
    companyId: any;
    numberOfMembers: number;
    membersSummary: any;
    totalPrice: number;
    totalPriceVat: number;
    totalPriceAfterVat: number;
    isLoading = true;
    masterTrxId:number;
    fastCalcResult: any;
    totalAmount:any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public translate: TranslateService,
        private smeService: MedicalSMEService,
        private dialogRef: MatDialogRef<FastCalculationSummaryDialogComponent>,
    ) {
        this.isLoading = true;
        this.classes = data.classes;
        this.companyId = data.companyId;
        this.fastCalcResult = data.result;
        this.masterTrxId=this.fastCalcResult[0].masterTrxId
        console.log(this.masterTrxId);
        console.log(this.fastCalcResult);
        this.fastCalcDetails = data.result.fastCalcDetails;
        this.numberOfMembers = data.result.totalMembers;
        this.totalPrice = data.result.totalPremimum;
        this.totalPriceVat = (this.totalPrice * 15) / 100;
        this.totalPriceAfterVat = this.totalPrice + this.totalPriceVat;
        this.isLoading = false;
        //console.log(data);
        
        this.totalAmount = 0;
        data.result.forEach(element => {
            this.totalAmount += (element.totalPremimum * 15 / 100) + element.totalPremimum;
        });
    }
    formatter:any;
    languageValue = localStorage.getItem('language');

    ngOnInit(): void {
        this.formatter = new Intl.NumberFormat(this.languageValue, {
            style: 'currency',
            currency: 'SAR',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

    }
    backToFastCalc() {
        this.dialogRef.close(null);
    }

    CreateSMEQuotation() {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        let errorMesg;

        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
            errorMesg = 'Do You Want To Create This Quotation ? ';
        } else {
            dir = 'rtl';
            errorMesg =
                'هل ترغب في إصدار عرض السعر علما بأن مدة صلاحية عرض السعر ستكون 3أيام ؟';
        }
        Swal.fire({
            title:
                this.translate.currentLang == 'en-US'
                    ? 'Confirmation !'
                    : '!تأكيد ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#D4AB36; direction:" +
                dir +
                "'>" +
                errorMesg +
                '</div>',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#36d446',
            cancelButtonColor: '#FF001F',

            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'YES' : 'نعم',
            cancelButtonText:
                this.translate.currentLang == 'en-US' ? 'NO' : 'لا',
            customClass: {
                icon: 'submit-claim-success-icon-img',
                title: 'submit-claim-success-swal-title',
                actions: 'actions',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                this.SubmitFastCalc();
            } else {
            }
        });
    }

    SubmitFastCalc() {
        let reqData = {
            companyId: this.companyId,
            masterTrxId: this.masterTrxId,
        };
        this.smeService.quotationWithFastCalc(reqData).subscribe((res) => {
            this.dialogRef.close(res);
        });
    }
}
