import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-sme-class-selection-dialog',
    templateUrl: './sme-class-selection-dialog.component.html',
    styleUrls: ['./sme-class-selection-dialog.component.scss'],
})
export class SmeClassSelectionDialogComponent implements OnInit {
    selectedClasses = [];
    isClassesSelected: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public classes: any,
        private dialogRef: MatDialogRef<SmeClassSelectionDialogComponent>,
        public translate: TranslateService,
    ) {}

    ngOnInit(): void {
        //console.log(this.classes);
    }

    HandleClassSelection(className) {
        this.isClassesSelected = false;

        if (this.selectedClasses.includes(className)) {
            const index: number = this.selectedClasses.indexOf(className);
            if (index !== -1) {
                this.selectedClasses.splice(index, 1);
            }
        } else this.selectedClasses.push(className);

        if(this.selectedClasses.length > 0)
            this.isClassesSelected = true;
    }

    // addClass(event: any) {
    //     if (this.selectedClasses.includes(event)) {
    //         const index: number = this.selectedClasses.indexOf(event);
    //         if (index !== -1) {
    //             this.selectedClasses.splice(index, 1);
    //         }
    //     } else this.selectedClasses.push(event);
    // }

    submitSelectedClasses() {
        this.dialogRef.close({ data: this.selectedClasses });
    }
}
