import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import Swal from 'sweetalert2';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import {
    EmedicalClaimdocs,
    EmedicalClaim,
} from 'src/app/core/interfaces/EmedicalClaim';
import { Router } from '@angular/router';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { OTPService } from 'src/app/core/services/eservice-otp.service';
import { MatDialog } from '@angular/material/dialog';
import { OtpVerificationDialogComponent } from '../../OTPVerification/otp-verification-dialog/otp-verification-dialog.component';

@Component({
    selector: 'app-new-medical-cash-claim',
    templateUrl: './new-medical-cash-claim.component.html',
    styleUrls: ['./new-medical-cash-claim.component.scss'],
})
export class NewMedicalCashClaimComponent implements OnInit {
    faXmark = faXmark;
    emailPattern =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    mobilePattern = '^(05)([0-9]{8})$';
    IBANpattern ='^([0-9]{22})$';

    Countries: any;
    NewMedicalClaimFrom: FormGroup;
    isLoading: boolean;
    nationalImgsList: any[] = [];
    ImgsId: any[] = [];
    @ViewChild('myInput')
    myInputVariable: ElementRef;
    Categories: any;
    memberDetails: any;
    PointedProviders: any;
    Currencies: any;
    ClaimType: boolean;
    ClaimTypes: { id: string; name: string }[];
    selectedClaimType: any;
    disableCurrency: boolean;
    ClaimReasons: any;
    showClaimReasonText: boolean;
    selectedClaimReason: any;
    ClaimRequiredDocuments: any;
    documentsFrom: FormGroup;
    mandatoryDocs = [];
    today: Date;
    phoneNumber: any;
    isSaveFrom: boolean = false;
    refranceNumber: string = null;
    isSaved: boolean = false;

    get newMedicalClaimFromControl() {
        return this.NewMedicalClaimFrom.controls;
    }
    get documentsFromControl() {
        return this.documentsFrom.controls;
    }

    constructor(
        public fb: FormBuilder,
        public translate: TranslateService,
        private medicalClaimsService: MedicalClaimsService,
        private _LocalService: LocalService,
        public _router: Router,
        private otpService: OTPService,
        private _dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.today = new Date();

        this.NewMedicalClaimFrom = this.fb.group({
            inKsa: [null, Validators.required],
            country: [null, Validators.required],
            category: [null, Validators.required],
            type: [null, Validators.required],
            memberName: [null, Validators.required],
            visitDate: [null, Validators.required],
            serviceProvider: [null, [Validators.required,Validators.maxLength(100)]],
            currency: [null, Validators.required],
            amount: [null, [Validators.required,Validators.min(1)]],
            claimReason: [null, Validators.required],
            memberEmail: [
                null,
                [Validators.required, Validators.pattern(this.emailPattern),Validators.maxLength(100)],
            ],
            memberIBan: [
                null,
                [Validators.required, Validators.pattern(this.IBANpattern),Validators.maxLength(22)],
            ],
            bankName: [null, [Validators.required,Validators.maxLength(100)]],
            cityName: [null, [Validators.required,Validators.maxLength(10)]],

            claimReasonText: [null],
            save: [false],
        });

        this.ClaimTypes = [
            {
                id: 'Y',
                name:
                    this.translate.currentLang == 'en-US'
                        ? 'In Ksa'
                        : 'داخل المملكة العربية السعودية',
            },
            {
                id: 'N',
                name:
                    this.translate.currentLang == 'en-US'
                        ? 'Out Ksa'
                        : 'خارج المملكة العربية السعودية',
            },
        ];

        this.memberDetails = this._LocalService.getJsonValue('memberDetails');
        this.phoneNumber = this._LocalService.getJsonValue('mobileNumber');

        this.GetAllCountries();
        this.GetAllCategories();
        this.GetAllServiceProviders(this.memberDetails[0].id);
        this.GetAllCurrencies();
        this.GetAllClaimReasons();
        this.GetRequiredDocuments();
    }

    SendOTPAndOpenVerificationDialog() {
        let otpObject = {
          ActionType: SMSActionType.IssueClaim,
          Lob: LineOfBusiness.Medical,
          IqamaId: this.memberDetails.find(x => x.memberEName == this.NewMedicalClaimFrom.value['memberName']['memberEName'])['id'].toString(),
          MobileNumber: this.phoneNumber.toString(),
          isWeb: true
        }
    
        this.isLoading = true;
        this.otpService.sendOTP(otpObject).subscribe((res) => {
          let result = res;
          if (result.errors.length <= 0) {
            const dialogRef = this._dialog.open(OtpVerificationDialogComponent, {
              data: { otpDetails: result },
              width: '600px',
              disableClose:true
            });
            dialogRef.afterClosed().subscribe((data) => {
              if (data.verified) {
                this.buildSaveModel();
              }
            });
          }
        });
      }
      
    submitMedicalClaim() {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        if (
            this.NewMedicalClaimFrom.valid &&
            this.docsValidation() &&
            this.NewMedicalClaimFrom.controls['save'].value === true
        ) {
            this.SendOTPAndOpenVerificationDialog();
        } else {
            let index = 1;

            let errorMessage: string = '';
            if (this.NewMedicalClaimFrom.controls['inKsa'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Claim Type !<br/>'
                        : index + '- برحاء ادخال نوع المطالبة !<br/>';
                index++;
            }

            if (this.NewMedicalClaimFrom.controls['country'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Country !<br/>'
                        : index + '- برحاء ادخال الدولة !<br/>';
                index++;
            }
            if (this.NewMedicalClaimFrom.controls['cityName'].value === null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The City !<br/>'
                        : index + '- برحاء ادخال المدينه  !<br/>';
                index++;
            }
            else if (
                this.NewMedicalClaimFrom.controls['cityName'].status ===
                'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter Vaild City !<br/>'
                        : index + '-  برحاء ادخال المدينه بطريقة صحيحة  !<br/>';
                index++;
            }

            if (this.NewMedicalClaimFrom.controls['category'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Category !<br/>'
                        : index + '- برحاء ادخال الفئه !<br/>';
                index++;
            }

            if (this.NewMedicalClaimFrom.controls['type'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Type !<br/>'
                        : index + '- برحاء ادخال النوع !<br/>';
                index++;
            }

            if (this.NewMedicalClaimFrom.controls['memberName'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Member Name !<br/>'
                        : index + '- برحاء ادخال تفاصيل المؤمن!<br/>';
                index++;
            } 

            if (this.NewMedicalClaimFrom.controls['visitDate'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Visit Date !<br/>'
                        : index + '- برحاء ادخال التاريخ  !<br/>';
                index++;
            } else if (
                this.NewMedicalClaimFrom.controls['visitDate'].status ===
                'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter Vaild Date !<br/>'
                        : index + '- برحاء ادخال تاريخ صحيح!<br/>';
                index++;
            }

            if (
                this.NewMedicalClaimFrom.controls['serviceProvider'].value ==
                null
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Service Provider !<br/>'
                        : index + '- برحاء ادخال مزود الخدمة !<br/>';
                index++;
            }
            else if (
                this.NewMedicalClaimFrom.controls['serviceProvider'].status ===
                'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter Vaild Service Provider !<br/>'
                        : index + '- برحاء ادخال مزود خدمة بطريقه صحيحه!<br/>';
                index++;
            }

            if (this.NewMedicalClaimFrom.controls['currency'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Currency !<br/>'
                        : index + '-  برحاء ادخال العملة !<br/>';
                index++;
            }

            if (this.NewMedicalClaimFrom.controls['amount'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Amount !<br/>'
                        : index + '- برحاء ادخال قيمة المستحقات !<br/>';
                index++;
            } else if (
                this.NewMedicalClaimFrom.controls['amount'].status === 'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter Vaild Amount !<br/>'
                        : index + '- برحاء ادخال قيمة مستحقات صحيحة !<br/>';
                index++;
                errorMessage +=
                this.translate.currentLang == 'en-US'
                    ? index + '- Amount Should be more than 0 !<br/>'
                    : index + '-  يجب أن يكون المبلغ أكثر من 0!<br/>';
            index++;
            }

            if (
                this.NewMedicalClaimFrom.controls['claimReason'].value == null
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Claim Reason !<br/>'
                        : index + '- برحاء ادخال سبب المطالبة !<br/>';
                index++;
            }
            else if (
                this.NewMedicalClaimFrom.controls['claimReason'].status ===
                'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter Vaild Claim Reason !<br/>'
                        : index + '- برحاء ادخال  سبب المطالبة بطريقة صحيحة!<br/>';
                index++;
            }

            if (this.NewMedicalClaimFrom.controls['memberIBan'].value == null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The IBan !<br/>'
                        : index + '- برحاء ادخال الحساب البنكى  !<br/>';
                index++;
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                    ? index + '- IBan Should be 22 number<br/>'
                    : index + '-  الحساب البنكى  يجب ان يكون 22 رقم<br/>';
                index++;
            } else if (
                this.NewMedicalClaimFrom.controls['memberIBan'].status ===
                'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter Vaild IBan !<br/>'
                        : index + '- برحاء ادخال الحساب البنكى الصحيح!<br/>';
                index++;
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- IBan Should be 22 number<br/>'
                        : index + '-  الحساب البنكى  يجب ان يكون 22 رقم<br/>';
                index++;
            }
            if (
                this.NewMedicalClaimFrom.controls['memberEmail'].value == null
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Member Email !<br/>'
                        : index + '- برحاء ادخال البريد الالكترونى  !<br/>';
                index++;
            } else if (
                this.NewMedicalClaimFrom.controls['memberEmail'].status ===
                'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter Vaild Email  !<br/>'
                        : index + '- برحاء ادخال البريد الالكترونى الصحيح!<br/>';

                index++;
            }
            if (
                this.NewMedicalClaimFrom.controls['claimReason'].value == 4 &&
                this.NewMedicalClaimFrom.controls['claimReasonText'].value ==
                null
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The Claim Reason !<br/>'
                        : index + '- برحاء ادخال سبب المطالبة !<br/>';
                index++;
            }
            else if (
                this.NewMedicalClaimFrom.controls['claimReason'].value == 4 &&
             

                this.NewMedicalClaimFrom.controls['visitDate'].status ===
                'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter Vaild Claim Reason  !<br/>'
                        : index + '- برحاء ادخال سبب مطالبة صحيح!<br/>';
                index++;
            }
            if (this.NewMedicalClaimFrom.controls['bankName'].value === null) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter The bank Name!<br/>'
                        : index + '- ادخل اسم البنك!<br/>';
                index++;
            }
            else if (
                this.NewMedicalClaimFrom.controls['bankName'].status ===
                'INVALID'
            ) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Enter Vaild bank Name !<br/>'
                        : index + '- برحاء ادخال اسم بنك صحيح!<br/>';
                index++;
            }
            if (!this.docsValidation()) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Please Upload the required files  !<br/>'
                        : index + '- الرجاء تحميل الملفات المطلوبة!<br/>';
                index++;
            }
            if (this.NewMedicalClaimFrom.controls['save'].value === false) {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? 'please confirm  the rule and policies !<br/> '
                        : 'الرجاء الموافقه علي السياسات والقوانين. !<br/>';
                index++;
            }

            Swal.fire({
                title:
                    this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
                iconHtml:
                    '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                html:
                    "<div style='color:#FF001F; direction:" +
                    dir +
                    "'>" +
                    errorMessage +
                    '</div>',
                confirmButtonText:
                    this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                customClass: {
                    icon: 'submit-claim-failed-icon-img',
                    title: 'submit-claim-failed-swal-title',
                    confirmButton: 'submit-claim-failed-confirm-btn',
                },
            });
        }
    }
    docsValidation() {
        let manLength = this.mandatoryDocs.length;
        let count = 0;
        for (let i = 0; i < this.nationalImgsList.length; i++) {
            if (this.nationalImgsList[i]['id'] === this.mandatoryDocs[0]) {
                count++;
                this.mandatoryDocs.splice(0, 1);
            }
        }
        if (manLength === count) return true;

        return false;
    }

    setDocsList(event, id, man) {
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            const fileName = file.name;
            this.nationalImgsList.push({
                image: file,
                id: id,
                name: fileName,
                mandatory: man,
            })
            if (!this.ImgsId.includes(id)) {
                this.ImgsId.push(id);
            }
            // const reader = new FileReader();
            // reader.readAsDataURL(file);
            // reader.onload = () => {
            //     this.nationalImgsList.push({
            //         image: reader.result,
            //         id: id,
            //         name: fileName,
            //         mandatory: man,
            //     });
            //     if (!this.ImgsId.includes(id)) {
            //         this.ImgsId.push(id);
            //     }
            // };
        }
        this.myInputVariable.nativeElement.value = '';
    }

    deleteAtt(x) {
        this.nationalImgsList.splice(x, 1);
    }

    ChangeClaimType() {
        this.disableCurrency = false;
        this.NewMedicalClaimFrom.controls['currency'].enable();
        this.NewMedicalClaimFrom.controls['currency'].reset();
        this.NewMedicalClaimFrom.controls['country'].enable();
        this.NewMedicalClaimFrom.controls['country'].reset();
        if (
            this.selectedClaimType != null &&
            this.selectedClaimType.id == 'Y'
        ) {
            this.disableCurrency = true;
            var x = this.Currencies.find((x) => x.currencyCode == 'SR');
            this.NewMedicalClaimFrom.controls['currency'].disable();
            this.NewMedicalClaimFrom.controls['currency'].setValue(
                this.Currencies.find((x) => x.currencyCode == 'SR').currencyCode
            );

            this.disableCurrency = true;
            var x = this.Currencies.find((x) => x.currencyCode == 173);
            this.NewMedicalClaimFrom.controls['country'].disable();
            this.NewMedicalClaimFrom.controls['country'].setValue(
                this.Countries.find((x) => x.countryCode == 173).countryCode
            );
        }
    }

    ChangeClaimReason() {
        this.showClaimReasonText = false;
        this.newMedicalClaimFromControl.claimReasonText.clearValidators();
        this.newMedicalClaimFromControl.claimReasonText.updateValueAndValidity();

        if (this.selectedClaimReason == 4) {
            this.showClaimReasonText = true;
            this.newMedicalClaimFromControl.claimReasonText.setValidators(
                [Validators.required,Validators.maxLength(1000)]
            );
            this.newMedicalClaimFromControl.claimReasonText.updateValueAndValidity();
        }
    }

    GetAllCountries() {
        this.medicalClaimsService.ServiceCountries().subscribe((res) => {
            this.Countries = res;
        });
    }

    GetAllCategories() {
        this.medicalClaimsService.ServiceCategories().subscribe((res) => {
            this.Categories = res;
        });
    }

    GetAllServiceProviders(memberId: number) {
        let queryString = 'MemberId=' + memberId;
        this.medicalClaimsService
            .PointedProviders(queryString)
            .subscribe((res) => {
                this.PointedProviders = res.data;
            });
    }

    GetAllCurrencies() {
        this.medicalClaimsService.ServiceCurrencies().subscribe((res) => {
            this.Currencies = res;
        });
    }

    GetAllClaimReasons() {
        this.medicalClaimsService.ServiceReasonCodes().subscribe((res) => {
            this.ClaimReasons = res;
        });
    }

    GetRequiredDocuments() {
        this.medicalClaimsService.ClaimDocumentsTypes().subscribe((res) => {
            this.ClaimRequiredDocuments = res;
            for (let index = 0; index < res.length; index++) {
                if (res[index]['mandatory'] === true)
                    this.mandatoryDocs.push(res[index]['documentType']);
            }
        });
    }
    DisplayDocument(img: any) {
        Swal.fire({
            title: img.name,
            showCloseButton: true,
            showConfirmButton: false,
            imageUrl: img.image,
            imageHeight: 300,
            imageAlt: 'A tall image',
        });
    }


    buildSaveModel() {
        let claimsModel: EmedicalClaim = new (class implements EmedicalClaim {
            InKsa: boolean; //done
            ServiceProvider: string; //done
            PolicyNumber: string;
            RequestDate: string; //done
            Category: string; //done
            MemberMobile: number;
            MemberIqamaId: string;
            MemberName: string; //done
            MemberEmail: string; //done
            MemberIban: string; //done
            BankName: string; //done
            Type: string; //done
            Country: string; //done
            City: string; //done
            CurrencyCode: string; //done
            ReasonCode: string; //done
            Reason: string; //done
            ClaimValue: number; //done
            Documents: Array<EmedicalClaimdocs>;
        })();
        if (this.NewMedicalClaimFrom.value['inKsa']['id'] === 'Y') {
            claimsModel.InKsa = true;
            claimsModel.CurrencyCode =
                this.NewMedicalClaimFrom.controls['currency'].value;
            claimsModel.Country =
                this.NewMedicalClaimFrom.controls['country'].value.toString();
        } else {
            claimsModel.InKsa = false;
            claimsModel.CurrencyCode =
                this.NewMedicalClaimFrom.controls['currency'].value;
            claimsModel.Country =
                this.NewMedicalClaimFrom.value['country'].toString();
        }
        claimsModel.ServiceProvider =
            this.NewMedicalClaimFrom.value['serviceProvider'];
        claimsModel.RequestDate = this.format(
            this.NewMedicalClaimFrom.value['visitDate'],
            1
        );
        claimsModel.Category = this.NewMedicalClaimFrom.value['category'];
        claimsModel.MemberName = this.NewMedicalClaimFrom.value['memberName']['memberEName'];
        claimsModel.MemberEmail = this.NewMedicalClaimFrom.value['memberEmail'];
        claimsModel.MemberIban = this.NewMedicalClaimFrom.value['memberIBan'];
        claimsModel.BankName = this.NewMedicalClaimFrom.value['bankName'];
        claimsModel.Type = this.NewMedicalClaimFrom.value['type'];

        claimsModel.City = this.NewMedicalClaimFrom.value['cityName'];
        claimsModel.ReasonCode = this.NewMedicalClaimFrom.value['claimReason'];
        claimsModel.Reason = this.NewMedicalClaimFrom.value['claimReasonText'];
        claimsModel.ClaimValue = this.NewMedicalClaimFrom.value['amount'];
        claimsModel.MemberMobile = this.phoneNumber;

        claimsModel.MemberIqamaId = this.memberDetails.find(x => x.memberEName == this.NewMedicalClaimFrom.value['memberName']['memberEName'])['id'];
        claimsModel.PolicyNumber = this.memberDetails[0]['policyNumber'];

        if (this.nationalImgsList != null && this.nationalImgsList.length) {
            let finalList: any = [];

            for (let i = 0; i < this.nationalImgsList.length; i++) {
                let list = {
                    CS_DOC_TYPE: '',
                    DOC: File,
                    DOC_IS_MANDATORY: '',
                };
                let item = this.nationalImgsList[i];

                list.CS_DOC_TYPE = item.id.toString();
                list.DOC = item.image;
                list.DOC_IS_MANDATORY = item.mandatory ? 'Y' : 'N';
                finalList.push(list);
            }
            claimsModel.Documents = finalList;
        }

        const formData = new FormData();

        formData.append('InKsa', JSON.stringify(claimsModel.InKsa));
        formData.append('CurrencyCode', claimsModel.CurrencyCode);
        formData.append('Country', claimsModel.Country);
        formData.append('ServiceProvider', claimsModel.ServiceProvider);
        formData.append('RequestDate', claimsModel.RequestDate);
        formData.append('Category', claimsModel.Category);
        formData.append('MemberName', claimsModel.MemberName);
        formData.append('MemberEmail', claimsModel.MemberEmail);
        formData.append('MemberIban', claimsModel.MemberIban);
        formData.append('BankName', claimsModel.BankName);
        formData.append('Type', claimsModel.Type);
        formData.append('City', claimsModel.City);
        formData.append('ReasonCode', claimsModel.ReasonCode);
        formData.append('Reason', claimsModel.Reason);
        formData.append('ClaimValue', JSON.stringify(claimsModel.ClaimValue));
        formData.append('MemberMobile', JSON.stringify(claimsModel.MemberMobile));
        formData.append('MemberIqamaId', claimsModel.MemberIqamaId);
        formData.append('PolicyNumber', claimsModel.PolicyNumber);

        claimsModel.Documents.forEach((element, index) => {
            formData.append(`Documents[${index}][CS_DOC_TYPE]`, element.CS_DOC_TYPE);
            formData.append(`Documents[${index}][DOC]`, element.DOC);
            formData.append(`Documents[${index}][DOC_IS_MANDATORY]`, element.DOC_IS_MANDATORY);
        });


        this.medicalClaimsService.SubmitNewCliam(formData).subscribe(
            (res) => {
                if (res) {
                    this.refranceNumber = res.message;
                    this.isSaved = true;
                    this.isLoading = false;

                    Swal.fire({
                        title:
                            this.translate.currentLang == 'en-US'
                                ? 'Success'
                                : 'نجحت المطالبة',
                        iconHtml:
                            '<img width=40 height=40 style="margin-top:10px;" src="assets/img/swal-icons-img/check.png">',
                        html:
                            this.translate.currentLang == 'en-US'
                                ? 'Your reference number is ' +
                                "<span style='color:#D4AB36'>" +
                                this.refranceNumber +
                                '</span>'
                                : "<span style='color:#D4AB36'>" +
                                this.refranceNumber +
                                '</span>' +
                                'الرقم المرجعي للمطالبة هو ',
                        confirmButtonText:
                            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                        customClass: {
                            icon: 'submit-claim-success-icon-img',
                            title: 'submit-claim-success-swal-title',
                            confirmButton: 'submit-claim-success-confirm-btn',
                        },
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            this._router.navigate(['/medical-profile']);
                        }
                    });
                }
            },
            (error) => {
                this.isSaved = false;
                this.isSaveFrom = false;
                this.isLoading = false;
                let dir: string = '';
                if (this.translate.currentLang == 'en-US') {
                    dir = 'ltr';
                } else {
                    dir = 'rtl';
                }

                if (error.messages != null && error.messages.length > 0) {
                    Swal.fire({
                        title:
                            this.translate.currentLang == 'en-US'
                                ? 'Error!'
                                : '!خطأ',
                        iconHtml:
                            '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                        html:
                            "<div style='color:#FF001F; direction:" +
                            dir +
                            "'>" +
                            error.messages +
                            '</div>',
                        confirmButtonText:
                            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                        customClass: {
                            icon: 'submit-claim-failed-icon-img',
                            title: 'submit-claim-failed-swal-title',
                            confirmButton: 'submit-claim-failed-confirm-btn',
                        },
                    });
                } else if (error.message) {
                    Swal.fire({
                        title:
                            this.translate.currentLang == 'en-US'
                                ? 'Error!'
                                : '!خطأ',
                        iconHtml:
                            '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                        html:
                            "<div style='color:#FF001F; direction:" +
                            dir +
                            "'>" +
                            error.message +
                            '</div>',
                        confirmButtonText:
                            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                        customClass: {
                            icon: 'submit-claim-failed-icon-img',
                            title: 'submit-claim-failed-swal-title',
                            confirmButton: 'submit-claim-failed-confirm-btn',
                        },
                    });
                }

            }
        );
    }
    format(inputDate, mnth) {
        let date, month, year;

        date = inputDate.getDate();
        month = inputDate.getMonth() + mnth;
        year = inputDate.getFullYear();
        if(month===13)
        {
            month=1;
        }
        date = date.toString().padStart(2, '0');

        month = month.toString().padStart(2, '0');

        return `${year}-${month}-${date}T00:00:00`;
    }
}
