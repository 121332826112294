<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container pt-5" style="min-height: 500px">
    <div class="row">
        <div class="col-12" style="text-align: center">
            <img src="assets/img/status/Success.png" style="width: 500px" />
            <div>
                <h2
                    class="color-green"
                    style="padding-top: 20px; color: black"
                    *ngIf="message"
                >
                    {{ message }}
                </h2>
                <h2
                    class="color-green"
                    style="padding-top: 20px; color: black"
                    *ngIf="message"
                >
                    {{ "MMLHome.PaymentFailed" | translate }}
                </h2>
                <h2
                    class="color-green"
                    style="padding-top: 20px; color: black"
                    *ngIf="message"
                >
                    {{ errMessage }}
                </h2>
                <h2
                    class="color-green"
                    style="
                        padding-top: 10px;
                        color: black;
                        font-size: 20px !important;
                        width: 80%;
                        margin: auto;
                    "
                >
                    {{ "MMLHome.redirectMSG" | translate }}
                </h2>
                <h2
                    class="color-green"
                    style="
                        padding-top: 10px;
                        color: black;
                        font-size: 20px !important;
                        width: 80%;
                        margin: auto;
                    "
                    *ngIf="message"
                >
                    {{ "MMLHome.redirectDown" | translate }}
                </h2>
                <!-- <div
                    class="d-flex"
                    style="width: 80%; justify-content: center; margin: auto"
                >
                    <h2
                        class="color-green"
                        style="
                            padding-top: 10px;
                            color: black;
                            font-size: 20px !important;
                        "
                    >
                        {{ "MMLHome.redirectCom" | translate }}
                    </h2>
                    <h2
                        style="
                            padding-top: 10px;
                            color: #d4ab36;
                            font-size: 22px !important;
                            padding-left: 10px;
                            padding-right: 10px;
                            font-weight: 600;
                            text-decoration: underline;
                            cursor: pointer;
                            margin-top: -2px !important;
                        "
                        (click)="openEsericeportal()"
                    >
                        {{ "NAV.Quote" | translate }}
                    </h2>
                </div> -->

                <button
                    class="btn default-btn"
                    style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    "
                    (click)="homePage()"
                >
                    {{ "MMLHome.bkHome" | translate }}
                </button>

                <button
                    class="btn default-btn"
                    style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    "
                    *ngIf="
                        transactionStatus?.statusCode == '200' &&
                        translate.currentLang == 'en-US'
                    "
                    (click)="download('en')"
                    id="mmpDownloadPolicyEn"
                >
                    Download Policy
                </button>

                <button
                    class="btn default-btn"
                    style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    "
                    *ngIf="
                        transactionStatus?.statusCode == '200' &&
                        translate.currentLang != 'en-US'
                    "
                    (click)="download('ar')"
                    id="mmpDownloadPolicyAr"
                >
                    تحميل الوثيقة
                </button>
                <button
                    class="btn default-btn"
                    style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    "
                    *ngIf="transactionStatus?.statusCode == '200'"
                    (click)="download('report')"
                    id="mmpDownloadReport"
                >
                    {{ "MMLHome.report" | translate }}
                </button>
                <button style="display: none" id="mmpPaymentSuccess">
                    Payment Success
                </button>
                <button style="display: none" id="mmpPaymentFailed">
                    Payment Failed
                </button>
            </div>
        </div>
    </div>
</div>
