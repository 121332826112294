import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppConfigService } from '../../AppConfigService';

@Injectable({
    providedIn: 'root',
})
export class MedicalSmeService {
    baseUrl: string;
    GetSMEAgesUrl: string;
    GetSMEClassesUrl: string;
    GetSMENationalitiesUrl: string;
    constructor(
        private http: HttpClient,
        private environment: AppConfigService
    ) {
        //https://localhost:7101
        // http://localhost:8885
        //this.baseUrl = "http://eservices.gulfunion-saudi.com:8885";
        this.baseUrl = 'https://localhost:44333';
        this.baseUrl = environment?.config?.servicesBaseUrl;
        this.GetSMEAgesUrl = this.baseUrl + 'MedicalSMELookup/GetSMEAges/';
        this.GetSMEClassesUrl = this.baseUrl + '/GetSMEClasses/';
        this.GetSMENationalitiesUrl = this.baseUrl + '/GetSMENationalities/';
    }

    GetSmeMedicalAges(): Observable<any> {
        return this.http.get<any>(this.GetSMEAgesUrl);
    }
    GetSMEClasses(): Observable<any> {
        return this.http.get<any>(this.GetSMEClassesUrl);
    }
    GetSMENationalities(): Observable<any> {
        return this.http.get<any>(this.GetSMENationalitiesUrl);
    }
}
