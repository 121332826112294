import { Component, OnInit, ElementRef, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { UserService } from 'src/app/core/services/UserService/user.service';
import Swal from 'sweetalert2';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { LineOfBusiness } from 'src/app/core/Enums/LineOfBusiness';
import { SMSActionType } from 'src/app/core/Enums/SMSActionType';


@Component({
    selector: 'app-user-login',
    templateUrl: './user-login.component.html',
    styleUrls: ['./user-login.component.scss'],
})
export class UserLoginComponent implements OnInit {
    @ViewChild('LOGIN') myInput: ElementRef;

    loginForm: FormGroup;
    lnk = localStorage.getItem('navLink');
    memberDetails: any;
    selectedMember: any;
    mobilePattern = '^(05)([0-9]{8})$';
    idPattern = '^([0-9]{10})$';

    userData: any;
    validUser = false;
    isLoading = false;
    disable= true;
    //@ViewChildren('input') vc;

    private unsubscriber: Subject<void> = new Subject<void>();
    product: any = '';
    Lob: LineOfBusiness;
    Type: SMSActionType;

    get newloginFormFromControl() {
        return this.loginForm.controls;
    }
    constructor(
        private _LocalService: LocalService,
        public medicalClaimsService: MedicalClaimsService,
        public fb: FormBuilder,
        public translate: TranslateService,
        public user: UserService,
        private renderer: Renderer2,
        public router: Router

    ) {
        this.disable=true;
        this.lnk = this._LocalService.getJsonValue('navLink');
        this.memberDetails = null;
        this.selectedMember = {
            id: '',
            policyNumber: '',
            policyHolder: '',
            policyExpiryData: '',
            memberCode: '',
            memberEName: '',
            gender: '',
            birthDate: '',
            className: '',
            relation: '',
        };
        this.loginForm = this.fb.group({
            memberid: [null, [Validators.required, Validators.pattern(this.idPattern)]],
            mobNumber: [
                null,
                [Validators.required, Validators.pattern(this.mobilePattern)],
            ]
        });
        //  this._LocalService.setJsonValue('isEligableToAccessOTP',false);
    }
    ngAfterViewInit() {
        this.myInput.nativeElement.focus();


        window.scrollTo(0, 0);

        this.renderer.selectRootElement(this.myInput.nativeElement).focus();
    }

    ngOnInit(): void {
        this.product = this._LocalService.getJsonValue('product');

        history.pushState(null, '');
        fromEvent(window, 'popstate').pipe(
            takeUntil(this.unsubscriber)
        ).subscribe((_) => {
            history.pushState(null, '');
        });
    }
    async login() {
        if (this.product != null) {
            switch (this.product) {
                case 'mmp':
                    this.Lob = LineOfBusiness.MedicalMalPractice;
                    this.Type = SMSActionType.Login
                    break;
                case 'motor':
                    this.Lob = LineOfBusiness.Motor;
                    this.Type = SMSActionType.Login
                    break;
                case 'medical':
                    this.Lob = LineOfBusiness.Medical;
                    this.Type = SMSActionType.Login
                    break;
            }
        }

        let queryString =
        {
            'userIqamaId': this.loginForm.value.memberid.toString(),
            'phoneNumber': this.loginForm.value.mobNumber.toString(),
            'isWeb':true,
            'Lob':this.Lob,
            'Type':this.Type
        }

        this.user.UserLogin(queryString).subscribe(async (res) => {
            this.isLoading = false;

            this.userData = res;
            if (res?.loginData) {

                this._LocalService.removeJsonValue('LoggedInIqamaId');
                this._LocalService.removeJsonValue('LoggedInMobileNo');
                
                this._LocalService.setJsonValue('userLoginInfo', res?.loginData);
                this._LocalService.setJsonValue('isEligableToAccessOTP', true);
                this._LocalService.setJsonValue('LoggedInIqamaId', this.loginForm.value.memberid.toString());
                this._LocalService.setJsonValue('LoggedInMobileNo', this.loginForm.value.mobNumber.toString());
                this.validUser = true;
                await this.SaevUserMedicalProfile();
                this.router.navigate(['user-verification'])
                this.loginForm.reset();
            }
        },
            (err) => {

                this.isLoading = false;

                let dir: string = '';
                let errorMesg;

                if (this.translate.currentLang == 'en-US') {
                    dir = 'ltr';
                    errorMesg = 'An Error Occurred ,Please Check Your Connection !'
                } else {
                    dir = 'rtl';
                    errorMesg = 'حدث خطأ , تحقق من اتصالك بالانترنت !  '

                }
                if (err?.error?.errorCode < 10 || err?.errorCode <10) {
                    if (err?.error?.errorCode === 6) {
                        this.translate.currentLang == 'en-US' ?
                            errorMesg = 'Wrong Iqama id or Phone Number  , Please Call The Customer Services'
                            :
                            errorMesg = 'رقم الهوية / الهاتف خاطئ , يرجى الاتصال بخدمة العملاء'
                    }
                    else {
                        if (this.translate.currentLang == 'en-US') {
                            errorMesg = 'Mobile Number / MemberID Not Vaild !'
                        }
                        else {
                            errorMesg = 'رقم الهوية / الهاتف غير صالح ! ';
                        }
                    }
                }

                Swal.fire({
                    title:
                        this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
                    iconHtml:
                        '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                    html:
                        "<div style='color:#FF001F; direction:" +
                        dir +
                        "'>" +
                        errorMesg +
                        '</div>',
                    confirmButtonText:
                        this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                    customClass: {
                        icon: 'submit-claim-failed-icon-img',
                        title: 'submit-claim-failed-swal-title',
                        confirmButton: 'submit-claim-failed-confirm-btn',
                    },
                });
            }

        );



    }

    async SaevUserMedicalProfile() {

        this._LocalService.setJsonValue('mobileNumber', this.loginForm.value.mobNumber);
        this._LocalService.setJsonValue('memberID', this.loginForm.value.memberid);
    }
    async logIn(event) {
        if(this?.loginForm?.controls['memberid']?.value?.length===10 &&this?.loginForm?.controls['mobNumber']?.value?.length===10 )
        {
        if (this.loginForm.status !== 'VALID') {
            let dir: string = '';
            let index = 1;
            let errorMessage: string = '';

            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }

            if (this.loginForm.controls['memberid'].status ===
                'INVALID') {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Invalid MemberID !<br/>'
                        : index + "- رقم الهوية / الإقامة غير صالح!<br/>"

                index++;
            }

            if (this.loginForm.controls['mobNumber'].status ===
                'INVALID') {
                errorMessage +=
                    this.translate.currentLang == 'en-US'
                        ? index + '- Invalid Mobile Number!<br/>'
                        : index + '- رقم الجوال غير صالح!<br/>';
                index++;
            }
            Swal.fire({
                title:
                    this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
                iconHtml:
                    '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                html:
                    "<div style='color:#FF001F; direction:" +
                    dir +
                    "'>" +
                    errorMessage +
                    '</div>',
                confirmButtonText:
                    this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                customClass: {
                    icon: 'submit-claim-failed-icon-img',
                    title: 'submit-claim-failed-swal-title',
                    confirmButton: 'submit-claim-failed-confirm-btn',

                },
            });
        }
        else if (this.loginForm.status === 'VALID') {
            this.isLoading = true;
            await this.login();

        }
    }
    }


    ngDoCheck() {
        if (this.isLoading !== false) {
            this._LocalService.setJsonValue('isEligableToAccessOTP', false);
        }
        if(this?.loginForm?.controls['memberid']?.value?.length===10 &&this?.loginForm?.controls['mobNumber']?.value?.length===10 )
        {
            this.disable=false;
        }
        else
        {
            this.disable=true;

        }

    }
}
