import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';

@Component({
    selector: 'app-medical-benefits',
    templateUrl: './medical-benefits.component.html',
    styleUrls: ['./medical-benefits.component.scss'],
})
export class MedicalBenefitsComponent implements OnInit {
    defaultClass: boolean = true;
    MemberDeductibleObj: any;
    BenefitDetailsObj: any;
    selectedMember: any;
    memberDetails: any;
    memID = localStorage.getItem('memberID');
    isLoading = true;

    constructor(
        public translate: TranslateService,
        public medicalClaimsService: MedicalClaimsService,
        private _LocalService: LocalService
    ) {
        this.memID=this._LocalService.getJsonValue('memberID');

        this.MemberDeductibleObj = [];
        this.BenefitDetailsObj = [];
        this.GetMemberDeductible();
        this.GetBenefitDetails();
        this.memberDetails=[];
        this.selectedMember = {
            id: '',
            policyNumber: '',
            PolicyStartData:'',
            policyHolder: '',
            policyExpiryData: '',
            memberCode: '',
            memberEName: '',
            gender: '',
            birthDate: '',
            className: '',
            relation: '',
        };
      
    }

    ngOnInit(): void {
        this.GetMemeberDetails();

    }

    GetMemberDeductible() {
        let queryString = 'MemberId=' + this.memID;
        this.medicalClaimsService
            .MemberDeductible(queryString)
            .subscribe((res) => {
                this.MemberDeductibleObj = res?.data;
            });
    }

    GetBenefitDetails() {
        let queryString = 'MemberId=' + this.memID;
        this.medicalClaimsService
            .BenefitDetails(queryString)
            .subscribe((res) => {
                this.BenefitDetailsObj = res?.data;
                this.isLoading=false

            },
            (err)=>
            {
              this.isLoading=false
        
            }
            );
    }
    GetMemeberDetails()
    {
        this.memberDetails = this._LocalService.getJsonValue('memberDetails');
        if( this.memberDetails !==null )
        {
        if (this.memberDetails?.length > 0) {
            this.selectedMember = this.memberDetails[0];
        }
    }
    else{
        
        let queryString = 'MemberId=' + this.memID  ;
        this.medicalClaimsService
            .MemberDetails(queryString)
            .subscribe((res) => {
                this.memberDetails = res;
                this._LocalService.setJsonValue('memberDetails', res?.data);
                if (res?.data.length > 0) {
                    this.selectedMember = res?.data[0];
                }
            });
        }
    
    }
    cardImage(BC) {
        if (BC.benefitName.toLowerCase().includes('general')) {
            return 'assets/img/medical-service/General-logo.png';
        } else if (BC.benefitName.toLowerCase().includes('dental')) {
            return 'assets/img/medical-service/Dental.png';
        } else if (BC.benefitName.toLowerCase().includes('maternity')) {
            return 'assets/img/medical-service/Maternity.png';
        } else if (BC.benefitName.toLowerCase().includes('transportation')) {
            return 'assets/img/medical-service/Transportation.png';
        } else if (BC.benefitName.toLowerCase().includes('vision')) {
            return 'assets/img/medical-service/Vision.png';
        } else if (BC.benefitName.toLowerCase().includes('hearing')) {
            return 'assets/img/medical-service/Hearing.png';
        } else {
            return 'assets/img/medical-service/policyCardImg.png';
        }
    }
}
