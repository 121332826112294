<div>
    <div class="page-title-area bg-1">
        <div class="container col-md-10">
            <div class="page-title-content">
                <h2>{{ "MEDICAL.ServiceProvider" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/"
                            >{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li>
                        <a routerLink="/medical-profile">
                            {{ "NAV.MEDICAL" | translate }}

                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li class="active" style="font-weight: 500">
                        {{ "MEDICAL.ServiceProvider" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container pt-3">
    <div class="claims-header col-lg-4 col-md-12 col-sm-12">
        <div class="d-flex">
            <a href="/medical-profile">
                <img
                    src="assets/img/medical-service/icons8-back-arrow-90.png"
                    alt=""
                    class="back-icon"
                    [ngClass]="{
                        'arrow-img-ar': this.translate.currentLang != 'en-US'
                    }"
                />
            </a>
            <h5
                class="color-green"
                [ngClass]="{
                    'title-en': this.translate.currentLang == 'en-US',
                    'title-ar': this.translate.currentLang != 'en-US'
                }"
            >
                {{ "MEDICAL.ServiceProvider" | translate }}
            </h5>
        </div>
        <hr
            [ngClass]="{
                'hr-en': this.translate.currentLang == 'en-US',
                'hr-ar': this.translate.currentLang != 'en-US'
            }"
            style="margin-bottom: 0"
        />
    </div>
</div>

<div
    class="container pb-2"
    [ngClass]="{
        'table-container-en': this.translate.currentLang == 'en-US',
        'table-container-ar': this.translate.currentLang != 'en-US'
    }"
    style="margin-top: -5px"
>
    <div class="row col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-3 col-md-12 col-sm-12">
            <label class="providers-area">{{
                "MEDICAL.City" | translate
            }}</label>
            <ng-select
                placeholder="{{ 'MEDICAL.Select' | translate }}"
                class="input-form-design"
                [items]="allCities"
                bindLabel="cityName"
                bindValue="cityCode"
                [(ngModel)]="selectedCity"
                (change)="toggleButtons()"
            >
            </ng-select>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12">
            <label class="providers-area"></label>

            <button
                type="button"
                class="btn btn-warning"
                [disabled]="!selectedCity || resetDefualt"
                (click)="getProviders()"
            >
                {{ "MEDICAL.Search" | translate }}
            </button>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12">
            <label class="providers-area"></label>

            <button
                type="button"
                class="btn btn-warning"
                [disabled]="!resetDefualt"
                (click)="resetFilters()"
            >
                {{ "MEDICAL.Reset" | translate }}
            </button>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12"></div>

        <div class="col-lg-3 col-md-6 col-sm-12">
            <label class="providers-area"></label>
            <div class="input-group">
                <input
                    name="accidentNum"
                    type="search"
                    class="input-form-design form-control"
                    placeholder="{{ 'MEDICAL.SelectProvider' | translate }}"
                    [(ngModel)]="providerName"
                    (search)="resetProvideName()"
                />

                <div class="input-group-append">
                    <button
                        class="btn btn-secondary"
                        type="button"
                        (click)="filterByName()"
                    >
                        <i class="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row col-lg-12 col-md-12 col-sm-12 pt-2">
        <div class="panel panel-default">
            <div class="panel-body" *ngIf="providers.length > 0">
                <div class="col-md-2"></div>
                <div class="table-content col-md-11" style="overflow-x: auto">
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource">
                            <!-- Position Column -->
                            <ng-container matColumnDef="serviceProvider">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.Service" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-template
                                        [ngIf]="languageValue === 'en-US'"
                                    >
                                        {{ element.providerEName }}
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="languageValue === 'ar-SA'"
                                    >
                                        {{ element.providerAName }}
                                    </ng-template>
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="city">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.City" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.city }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="address">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.Address" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.address }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="specialities">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.Specialties" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.specialities }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="telephone">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.Telephone" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.telephone }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="latitude">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.Location" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a
                                        style="cursor: pointer"
                                        (click)="
                                            navToGoogleMaps(element.location)
                                        "
                                    >
                                        <img
                                            src="assets/img/medical-service/placeholder.png"
                                            width="30"
                                            alt=""
                                        />
                                    </a>
                                </td>
                            </ng-container>

                            <tr
                                mat-header-row
                                *matHeaderRowDef="displayedColumns"
                            ></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns"
                            ></tr>
                        </table>

                        <mat-paginator
                            [pageSizeOptions]="[10]"
                            showFirstLastButtons
                            aria-label="Select page of periodic elements"
                        >
                        </mat-paginator>
                    </div>
                </div>
            </div>

            <div
                *ngIf="
                    (!providers || providers.length == 0) &&
                    pageInit === false &&
                    isLoading === false
                "
                class="d-flex justify-content-center"
                style="text-align: center !important"
            >
                <div>
                    <img
                        src="assets/img/medical-service/noClaims.png"
                        alt=""
                        style="width: 250px"
                    />
                    <h5 class="color-green" style="text-align: center">
                        {{ "MEDICAL.Provider-Mes" | translate }}
                    </h5>
                </div>
            </div>
            <div *ngIf="pageInit === true" style="height: 30vh"></div>
        </div>
    </div>
</div>
