import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../AppConfigService';

@Injectable({
    providedIn: 'root',
})
export class MotorClaimsService {
    baseUrl: string;
    servicesNajmBaseUrl: string;
    SaveClaimURL: string;
    DetailsClaimURL: string;
    validateMotorPolicyURL: string;
    validateCarReferenceNumberURL: string;
    OTPValidateURL: string;
    DocumentMissingURL: string;
    SaveComplaintURL: string;
    getRequesterTypeListUrl: string;
    GetClaimDetailsOnlyURL: string;
    getDrivingLicenseListUrl: string;
    getCitysListUrl: string;
    getMotorClaimMessagesUrl: string;
    getDocsUrl: string;
    getPolicyDetailsUrl: string;
    checkAccidentNo: string;
    insertPolicyCancellation: string;
    loadCancellationURL: string;
    Authentication: string;
    DomesticLaborPolicy: string;
    DomesticLaborPolicyClaims: string;
    DomesticLaborCancel: string;
    CheckDomesticLaborExisiting:string;
    loadDomesticcancellationURL: string;
    reUploadClaimDocsUrl: string;
    dlclaimsubmit: string;
    dlclaimMissingDocsSubmitURL: string;
    dlclaimGet: string;
    dlCancellationReasons: string;
    constructor(
        private http: HttpClient,
        private environment: AppConfigService
    ) {
        //https://localhost:7101
        // http://localhost:8885
        //this.baseUrl = "http://eservices.gulfunion-saudi.com:8885";
        //this.baseUrl = "https://localhost:7101";
        this.baseUrl = environment.config.servicesBaseUrl;
        this.servicesNajmBaseUrl = environment.config.servicesNajmBaseUrl;
        this.SaveClaimURL =
            this.baseUrl + 'ClaimSubmission/SubmitClaimWithDocs/';
        this.SaveComplaintURL = this.baseUrl + 'Complaint/Save/';
        this.DetailsClaimURL =
            this.baseUrl + 'ClaimSubmission/GetClaimDetails?NationalId=';
        this.validateMotorPolicyURL =
            this.baseUrl +
            'ClaimSubmission/ValidateClaimPolicyNumber?policyNumber=';
        this.validateCarReferenceNumberURL =
            this.baseUrl +
            'ClaimSubmission/ValidateCarReferenceNumber?carReferenceNumber=';
        this.getRequesterTypeListUrl =
            this.baseUrl + 'LookUpDetails/GetRequesterType/';
        this.getDrivingLicenseListUrl =
            this.baseUrl + 'LookUpDetails/GetDrivingLicenseType/';
        this.getCitysListUrl = this.baseUrl + 'Location/GetCityList/';
        this.getMotorClaimMessagesUrl = this.baseUrl + 'ClaimSubmission/GetMotorClaimMessages?lang=';
        this.getDocsUrl =
            this.baseUrl + 'EMotClaimDoc/GetDocsByClaimType?ClaimType=';
        this.checkAccidentNo =
            this.servicesNajmBaseUrl + 'NajmLiability/CheckCaseNo?Key=';
        this.OTPValidateURL =
            this.baseUrl + 'ClaimSubmission/ValidateOTP?NationalId=';
        this.DocumentMissingURL =
            this.baseUrl +
            'ClaimSubmission/GetClaimMissingDocument?AccidentNo=';
        this.GetClaimDetailsOnlyURL =
            this.baseUrl + 'ClaimSubmission/GetClaimDetails?NationalId=';
        this.getPolicyDetailsUrl =
            this.baseUrl + 'Endoresements/LoadPolicyDetails?NationalId=';
        this.insertPolicyCancellation =
            this.baseUrl + 'Endoresements/InsertCancellation/';
        this.loadCancellationURL =
            this.baseUrl + 'Endoresements/LoadCancellationDetails?NationalId=';
        this.dlCancellationReasons =
            this.baseUrl + 'Endoresements/getDLCancellationReasons';
        this.Authentication =
            this.baseUrl + 'ClaimSubmission/VerifyUser?NationalId=';
        this.DomesticLaborPolicy =
            this.baseUrl + 'Endoresements/getDomesticPolicyInfo?nationalId=';
        this.DomesticLaborPolicyClaims =
            this.baseUrl + 'Endoresements/getClaimsPolicyInfo?policyId=';
        this.DomesticLaborCancel =
            this.baseUrl + 'Endoresements/SaveCancellationDL/';

            this.CheckDomesticLaborExisiting=
            this.baseUrl + 'Endoresements/checkCancelation?nationalId=';

        this.loadDomesticcancellationURL =
            this.baseUrl + 'Endoresements/getDLCancellationDetails?nationalId=';
        this.reUploadClaimDocsUrl =
            this.baseUrl + 'ClaimSubmission/ReUploadClaimDocs/';
        this.dlclaimsubmit = this.baseUrl + 'Endoresements/saveDomesticsClaim';
        this.dlclaimMissingDocsSubmitURL = this.baseUrl + 'Endoresements/SubmitDomesticClaimMissingDocs';
        this.dlclaimGet = this.baseUrl + 'Endoresements/getDlClaims?policyNo=';
    }
    getMotorClaimMessages(lang:string): Observable<any> {
        return this.http.get<any>(this.getMotorClaimMessagesUrl+lang);
    }
    saveClaim(model): Observable<any> {
        return this.http.post<any>(this.SaveClaimURL, model);
    }
    saveComplaint(model): Observable<any> {
        return this.http.post<any>(this.SaveComplaintURL, model);
    }
    getRequesterTypeList(): Observable<any> {
        return this.http.get<any>(this.getRequesterTypeListUrl);
    }
    getDrivingLicenseList(): Observable<any> {
        return this.http.get<any>(this.getDrivingLicenseListUrl);
    }
    getCitysList(): Observable<any> {
        return this.http.get<any>(this.getCitysListUrl);
    }
    getDocs(type): Observable<any> {
        return this.http.get<any>(this.getDocsUrl + type);
    }
    getClaimDetails(nat, mobile, lob, type): Observable<any> {
        return this.http.get<any>(
            this.DetailsClaimURL + nat + '&MobileNo=' + mobile+'&isWeb='+true + '&Lob='+lob + '&Type=' + type 
        );
    }
    validatePolicyNumber(policyNumber): Observable<any> {
        return this.http.get<any>(this.validateMotorPolicyURL + policyNumber);
    }
    validateCarReferenceNumber(carRefNumber): Observable<any> {
        return this.http.get<any>(
            this.validateCarReferenceNumberURL + carRefNumber
        );
    }
    validateAcciedentNo(type): Observable<any> {
        return this.http.get<any>(this.checkAccidentNo + type);
    }
    validateOTP(nat, mob, otp): Observable<any> {
        return this.http.get<any>(
            this.OTPValidateURL + nat + '&MobileNo=' + mob + '&otpCode=' + otp
        );
    }
    getDetailsOnly(nat): Observable<any> {
        return this.http.get<any>(this.GetClaimDetailsOnlyURL + nat);
    }
    getMissingDocs(acc, nat): Observable<any> {
        return this.http.get<any>(
            this.DocumentMissingURL + acc + '&NationalId=' + nat
        );
    }
    getPolicyDetails(nat): Observable<any> {
        return this.http.get<any>(this.getPolicyDetailsUrl + nat);
    }
    saveCancellation(model): Observable<any> {
        return this.http.post<any>(this.insertPolicyCancellation, model);
    }
    checkCancelation(nationalId){
        return this.http.get<any>(this.CheckDomesticLaborExisiting + nationalId);

    }
    getCancellation(nat): Observable<any> {
        return this.http.get<any>(this.loadCancellationURL + nat);
    }
    getCancellationReasons(): Observable<any> {
        return this.http.get<any>(this.dlCancellationReasons);
    }
    getAuthenticate(nat, mob, lob, type): Observable<any> {
        return this.http.get<any>(
            this.Authentication + nat + '&MobileNo=' + mob+ '&isWeb='+true + '&Lob='+ lob + '&Type=' + type
        );
    }
    getDomesticPolicyDetails(nat): Observable<any> {
        return this.http.get<any>(this.DomesticLaborPolicy + nat);
    }
    getDomesticPolicyClaims(policy): Observable<any> {
        return this.http.get<any>(this.DomesticLaborPolicyClaims + policy);
    }
    saveDomesticCancellation(model): Observable<any> {
        return this.http.post<any>(this.DomesticLaborCancel, model);
    }
    getDomesticCancelDetails(nat, pol): Observable<any> {
        return this.http.get<any>(
            this.loadDomesticcancellationURL + nat + '&policyNo=' + pol
        );
    }
    reUploadClaimDocs(model): Observable<any> {
        return this.http.post<any>(this.reUploadClaimDocsUrl, model);
    }
    submitDlClaims(model): Observable<any> {
        return this.http.post<any>(this.dlclaimsubmit, model);
    }
    getDlClaims(policy): Observable<any> {
        return this.http.get<any>(this.dlclaimGet + policy);
    }
    submitDlClaimsMissingDocs(model): Observable<any> {
        return this.http.post<any>(this.dlclaimMissingDocsSubmitURL, model);
    }
}
