<div class="page-title-area bg-1">
    <div class="container col-md-10">
        <div class="page-title-content">
            <h2>{{ "MEDICAL.NewClaim" | translate }}</h2>
            <ul>
                <li>
                    <a routerLink="/">{{ "NAV.ESERVICES" | translate }}
                        <span style="margin: 0 10px"> / </span></a>
                </li>
                <li>
                    <a routerLink="/mmp-cash-claims">
                        {{ "MMLHome.MedicalMalpractice" | translate }}

                        <span style="margin: 0 10px"> / </span></a>
                </li>
                <li class="active" style="color: white">
                    {{ "MEDICAL.NewClaim" | translate }}
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="container pt-5 pb-5">
    <div class="col-lg-12 col-md-12 col-sm-12 form-container p-2">
        <div class="row">
            <div style="display: flex">
                <a href="/mmp-cash-claims">
                    <img src="assets/img/medical-service/icons8-back-arrow-90.png" class="back-icon" alt=""
                        [ngClass]="{'arrow-img-ar':this.translate.currentLang != 'en-US'}" />
                </a>
                <h5 class="color-green" [ngClass]="{
                        'title-en': this.translate.currentLang == 'en-US',
                        'title-ar': this.translate.currentLang != 'en-US'}">
                    {{ "MEDICAL.NewCashClaim" | translate }}
                </h5>
            </div>
            <hr [ngClass]="{
                    'hr-en': this.translate.currentLang == 'en-US',
                    'hr-ar': this.translate.currentLang != 'en-US'}" />
        </div>

        <form [formGroup]="NewMMPClaimFrom" style="padding: 2%">
            <div class="row form-element-container pb-4">
                <h5 style="color: #156538" class="pt-4">
                    {{ "MEDICAL.LossDetails" | translate }}
                </h5>

                <div style="display: flex" class="pt-4">
                    <div class="row col-md-6 d-flex align-items-center">
                        <div class="col-md-4 text-end">
                            <label for="iqamaId" class="form-label">
                                {{ "MEDICAL.ID" | translate }}
                                <span style="color: #ff001f; font-size: 13px">*</span>
                            </label>
                        </div>
                        <div class="col-md-8">
                            <input (keypress)="($event.charCode >= 48 && $event.charCode < 58)" id="serviceProviderId"
                                name="iqamaId" type="text" formControlName="iqamaId" [ngClass]="{
                                    'red-border-class':
                                        newMMPClaimFromControl
                                            .iqamaId.errors &&
                                        newMMPClaimFromControl
                                            .iqamaId.touched
                                }" class="form-control input-form-design" placeholder="{{
                                    'MEDICAL.ID' | translate
                                }}" [maxLength]="10" />
                            <div *ngIf="
                                    newMMPClaimFromControl.iqamaId
                                        .errors &&
                                    newMMPClaimFromControl.iqamaId
                                        .touched
                                ">
                                <div *ngIf="
                                        newMMPClaimFromControl
                                            .iqamaId.errors.required
                                    " style="color: #ff001f">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                                <div *ngIf="
                                        newMMPClaimFromControl
                                            ?.iqamaId?.errors.minlength
                                    " style="color: #ff001f">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    {{ "GENERIC.minLengthC" | translate }} 10
                                    {{ "GENERIC.maxLengthChar" | translate }}
                                </div>
                                <div *ngIf="newMemberFormControl?.IqamaId.errors.pattern" style="color: #ff001f">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    {{ "SME.ONLYNUMBERS" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex" class="pt-4">
                    <div class="row col-md-6 d-flex align-items-center">
                        <div class="col-md-4 text-end">
                            <label for="causeOfLoss" class="form-label">
                                {{ "MEDICAL.CauseOfLoss" | translate }}
                                <span style="color: #ff001f; font-size: 13px">*</span>
                            </label>
                        </div>
                        <div class="col-md-8">
                            <ng-select [ngClass]="{
                                    'red-border-class':
                                        newMMPClaimFromControl.causeOfLoss
                                            .errors &&
                                        newMMPClaimFromControl.causeOfLoss
                                            .touched
                                }" placeholder="{{
                                    'MEDICAL.CauseOfLoss' | translate
                                }}" formControlName="causeOfLoss" [searchable]="true" class="input-form-design"
                                [(ngModel)]="selectedCauseOfLoss">
                                <ng-option [value]="item.codeId" *ngFor="let item of CauseOfLossList">
                                    {{ item.value }}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="
                                    newMMPClaimFromControl.causeOfLoss
                                        .errors &&
                                    newMMPClaimFromControl.causeOfLoss
                                        .touched
                                " style="color: #ff001f;">
                                <div *ngIf="
                                        newMMPClaimFromControl.causeOfLoss
                                            .errors.required
                                    ">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-md-6 d-flex align-items-center">
                        <div class="col-md-4 text-end">
                            <label for="dateOfLoss" class="form-label">
                                {{ "MEDICAL.DateOfLoss" | translate }}
                                <span style="color: #ff001f; font-size: 13px">*</span>
                            </label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="none" class="form-control input-form-design w-100" [ngClass]="{
                                    'red-border-class':
                                        newMMPClaimFromControl.dateOfLoss
                                            .errors &&
                                        newMMPClaimFromControl.dateOfLoss
                                            .touched
                                }">
                                <input formControlName="dateOfLoss" matInput [matDatepicker]="picker"
                                    placeholder="MM/DD/YYYY" [max]="today" />
                                <mat-datepicker-toggle style="
                                        margin-right: -6%;
                                        margin-top: -3%;
                                        color: #156538;
                                    " matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="
                                    newMMPClaimFromControl.dateOfLoss
                                        .errors &&
                                    newMMPClaimFromControl.dateOfLoss.touched
                                " style="color: #ff001f">
                                <div *ngIf="
                                        newMMPClaimFromControl.dateOfLoss
                                            .errors.required
                                    ">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex" class="pt-4">
                    <div class="row col-md-12">
                        <div class="col-lg-2 col-md-2 col-sm-12 text-end">
                            <label for="lossDescription" class="form-label">
                                {{ "MEDICAL.LossDescription" | translate }}
                                <span style="color: #ff001f; font-size: 13px">*</span>
                            </label>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-12">
                            <textarea id="lossDescriptionId" name="lossDescription" type="textarea"
                                formControlName="lossDescription" [ngClass]="{
                                    'red-border-class':
                                        newMMPClaimFromControl
                                            .lossDescription.errors &&
                                        newMMPClaimFromControl
                                            .lossDescription.touched
                                }" class="form-control input-form-design" placeholder="{{
                                    'MEDICAL.LossDescription' | translate
                                }}">
                            </textarea>
                            <div *ngIf="
                                    newMMPClaimFromControl.lossDescription
                                        .errors &&
                                    newMMPClaimFromControl.lossDescription
                                        .touched
                                ">
                                <div *ngIf="
                                        newMMPClaimFromControl
                                            .lossDescription.errors.required
                                    " style="color: #ff001f">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                                <div *ngIf="
                                    newMMPClaimFromControl
                                            ?.lossDescription?.value?.length >
                                        1000
                                    " style="color: #ff001f">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    {{ "GENERIC.maxLengthC" | translate }} 1000
                                    {{ "GENERIC.maxLengthChar" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row form-element-container pt-4 pb-4 mt-4">
                <h5 style="color: #156538">
                    {{ "MEDICAL.RequiredDocuments" | translate }}
                </h5>
                <div style="display: flex; flex-direction: row" class="pt-3 row">
                    <div class="col-md-4 pb-5" *ngFor="let item of RequiredFiles">
                        <div class="upload-item pb-2" 
                        [ngClass]="{'text-start':this.translate.currentLang == 'en-US', 
                        'text-right':this.translate.currentLang != 'en-US'}">
                            <label class="form-label font-18">
                                <span style="color: #ff001f; font-size: 13px" *ngIf="item.isMandatory === true">*
                                    {{ "GENERIC.REQUIRED" | translate }}</span>
                                <span style="
                                        color: #ff001f;
                                        font-size: 14px;
                                        visibility: hidden;
                                    " *ngIf="item.isMandatory === false">* required</span>
                            </label>

                            <label for="medicalReports" class="form-label font-18" style="min-height: 40px">
                                {{ item.name }}
                            </label>
                            <div>
                                <input type="file" [id]="item.id" [required]="true" #myInput class="hidden"
                                    accept="application/pdf, image/*" multiple (change)="
                                        setDocsList(
                                            $event,
                                            item.id,
                                            item.isMandatory
                                        )
                                    " />

                                <label [for]="item.id" class="custom-file-input upload-lbl">
                                    <img src="assets/img/medical-service/cloud-computing.png" width="25" alt="" />
                                    {{ "MEDICAL.UploadFiles" | translate }}
                                </label>
                            </div>
                        </div>
                        <div class="row text-center" *ngIf="ImgsId.includes(item.id)">
                            <ng-container *ngFor="
                                    let img of uploadedImgsList;
                                    let x = index
                                ">
                                <div class="col-md-5 imageContainer" *ngIf="img.id == item.id">
                                    <div class="col-md-12 row mt-3">
                                         <div class="item-container" > <!--(click)="DisplayDocument(img)" -->
                                            <div style="
                                                    overflow: clip;
                                                    margin-right: 5%;
                                                ">
                                                {{ img.name }}
                                            </div>
                                            <div [ngClass]="{'pr-10-ar':this.translate.currentLang != 'en-US', 
                                            'pr-10-en':this.translate.currentLang == 'en-US'}">
                                                <span class="delete" (click)="deleteAtt(x)" *ngIf="
                                                        img.id ==
                                                        item.id
                                                    " style="font-weight: bold;">
                                                    X
                                                    <!-- <fa-icon [icon]="faXmark"></fa-icon> -->
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 text-center pt-3">
                <button class="btn next-btn p-1" (click)="submitMMPCashClaim()" [disabled]="isLoading">
                    <span *ngIf="!isLoading">{{
                        "GENERIC.SUBMIT" | translate
                        }}</span>
                    <span *ngIf="isLoading">
                        <div class="spinner-border text-warning"></div>
                    </span>
                </button>
            </div>
        </form>
    </div>
</div>