<loading
    *ngIf="isLoading || Load || ddlLoader || billingLoader || finalFormLoader"
    >{{ isLoading }}</loading
>
<mat-stepper
    ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}"
    labelPosition="bottom"
    [orientation]="(stepperOrientation | async)!"
    #stepper
>
    <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
    </ng-template>

    <!--01-->
    <mat-step [stepControl]="idForm">
        <ng-template matStepLabel>
            {{ "MMLHome.IDVerification" | translate }}
        </ng-template>

        <div class="claim-form mt-5">
            <legend
                class="claim-title"
                style="padding-left: 30px; padding-right: 30px"
            >
                {{ "MMLHome.IDVerification" | translate }}
            </legend>
            <hr />
            <div class="row">
                <div
                    class="col-md-6 col-sm-12"
                    style="
                        padding-left: 40px;
                        padding-right: 40px;
                        padding-bottom: 25px;
                    "
                >
                    <form [formGroup]="idForm">
                        <!--(ngSubmit)="" -->

                        <!-- Claim Processed By -->

                        <!-- Driver Id / Iqama -->

                        <div class="mb-4 mt-5">
                            <div class="row">
                                <div class="col-md-4 pt-2">
                                    <label for="driverId" class="form-label"
                                        >{{ "MMLHome.ID" | translate }}:</label
                                    >
                                </div>
                                <div class="col-md-7">
                                    <input
                                        #input
                                        id="iqama"
                                        name="iqama"
                                        type="text"
                                        maxlength="10"
                                        (input)="
                                            handleIqamaNumberChange($event)
                                        "
                                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '');"
                                        formControlName="Iqama"
                                        [ngClass]="{
                                            'red-border-class':
                                                idFormControl.Iqama.errors &&
                                                (idFormControl.Iqama.touched ||
                                                    isSubmit),
                                            'form-control':
                                                defaultClassFlag == true,
                                            'input-form-design':
                                                defaultClassFlag == true
                                        }"
                                        class="form-control input-form-design"
                                        pattern="[0-9]+"
                                    />
                                    <div
                                        *ngIf="
                                            idFormControl.Iqama.errors &&
                                            (idFormControl.Iqama.touched ||
                                                isSubmit)
                                        "
                                        class=""
                                    >
                                        <div
                                            *ngIf="
                                                idFormControl.Iqama.errors
                                                    .required
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                        <div
                                            *ngIf="
                                                idFormControl.Iqama.errors
                                                    .pattern
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{
                                                "GENERIC.DRIVER_ID_PLACEHOLDER"
                                                    | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Mobile Number-->
                        <div class="mb-4">
                            <div class="row mt-2">
                                <div class="col-md-4 pt-2">
                                    <label for="driverId" class="form-label"
                                        >{{
                                            "MEDICAL.MobileNumber" | translate
                                        }}:</label
                                    >
                                </div>
                                <div class="col-md-7">
                                    <input
                                        #input
                                        id="MMLMobileNumber"
                                        name="MMLMobileNumber"
                                        type="text"
                                        maxlength="10"
                                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        formControlName="MobileNumber"
                                        [ngClass]="{
                                            'red-border-class':
                                                idFormControl.MobileNumber
                                                    .errors &&
                                                (idFormControl.MobileNumber
                                                    .touched ||
                                                    isSubmit),
                                            'form-control':
                                                defaultClassFlag == true,
                                            'input-form-design':
                                                defaultClassFlag == true
                                        }"
                                        class="form-control input-form-design"
                                        pattern="[0-9]+"
                                    />
                                    <div
                                        *ngIf="
                                            idFormControl.MobileNumber.errors &&
                                            (idFormControl.MobileNumber
                                                .touched ||
                                                isSubmit)
                                        "
                                        class=""
                                    >
                                        <div
                                            *ngIf="
                                                idFormControl.MobileNumber
                                                    .errors.required
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                        <div
                                            *ngIf="
                                                idFormControl.MobileNumber
                                                    .errors.pattern
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{
                                                "GENERIC.MN_PLACEHOLDER"
                                                    | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--BirthDate-->
                        <div class="mb-5" *ngIf="isSA">
                            <div class="row" style="margin-top: -8px">
                                <div class="col-md-4 pt-2">
                                    <label for="driverId" class="form-label"
                                        >{{
                                            "MMLHome.Birthdate" | translate
                                        }}:</label
                                    >
                                </div>
                                <div class="col-md-7">
                                    <div class="row justify-content-between">
                                        <div class="col-3 pt-1">
                                            <input
                                                #input
                                                id="BirthDateDay"
                                                name="BirthDateDay"
                                                type="text"
                                                type="number"
                                                maxlength="2"
                                                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                formControlName="BirthDateDay"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        idFormControl
                                                            .BirthDateDay
                                                            .errors &&
                                                        (idFormControl
                                                            .BirthDateDay
                                                            .touched ||
                                                            isSubmit),
                                                    '':
                                                        defaultClassFlag ==
                                                        true,
                                                    'mmp-input-form-design':
                                                        defaultClassFlag == true
                                                }"
                                                class="mmp-input-form-design"
                                                placeholder="DD"
                                                style="
                                                    font-size: 12px;
                                                    display: flex;
                                                    text-align: center;
                                                "
                                                pattern="\d*"
                                            />
                                        </div>
                                        <div
                                            class="col-1 pt-2"
                                            style="
                                                font-size: 20px;
                                                font-weight: 500;
                                            "
                                        >
                                            /
                                        </div>
                                        <div class="col-3 pt-1">
                                            <input
                                                #input
                                                id="BirthDateMonth"
                                                name="BirthDateMonth"
                                                type="text"
                                                type="number"
                                                maxlength="2"
                                                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                formControlName="BirthDateMonth"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        idFormControl
                                                            .BirthDateMonth
                                                            .errors &&
                                                        (idFormControl
                                                            .BirthDateMonth
                                                            .touched ||
                                                            isSubmit),
                                                    '':
                                                        defaultClassFlag ==
                                                        true,
                                                    'mmp-input-form-design':
                                                        defaultClassFlag == true
                                                }"
                                                class="mmp-input-form-design"
                                                placeholder="MM"
                                                style="
                                                    font-size: 12px;
                                                    display: flex;
                                                    text-align: center;
                                                "
                                                pattern="\d*"
                                            />
                                        </div>
                                        <div
                                            class="col-1 pt-2"
                                            style="
                                                font-size: 20px;
                                                font-weight: 500;
                                            "
                                        >
                                            /
                                        </div>
                                        <div class="col-3 pt-1">
                                            <input
                                                #input
                                                id="BirthDateYear"
                                                name="BirthDateYear"
                                                type="email"
                                                maxlength="4"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                formControlName="BirthDateYear"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        idFormControl
                                                            .BirthDateYear
                                                            .errors &&
                                                        (idFormControl
                                                            .BirthDateYear
                                                            .touched ||
                                                            isSubmit),
                                                    '':
                                                        defaultClassFlag ==
                                                        true,
                                                    'mmp-input-form-design':
                                                        defaultClassFlag == true
                                                }"
                                                class="mmp-input-form-design"
                                                placeholder="YYYY"
                                                style="
                                                    font-size: 12px;
                                                    display: flex;
                                                    text-align: center;
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Sponsor Number-->
                        <div class="mb-5" *ngIf="!isSA">
                            <div class="row">
                                <div class="col-md-4 pt-2">
                                    <label for="driverId" class="form-label"
                                        >{{
                                            "MEDICAL.SponsorNumber" | translate
                                        }}:</label
                                    >
                                </div>
                                <div class="col-md-7">
                                    <input
                                        #input
                                        id="MMLSponsorNumber"
                                        name="MMLSponsorNumber"
                                        type="text"
                                        maxlength="10"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9]/g, '');"
                                        formControlName="SponsorNumber"
                                        [ngClass]="{
                                            'red-border-class':
                                                idFormControl.SponsorNumber
                                                    .errors &&
                                                (idFormControl.SponsorNumber
                                                    .touched ||
                                                    isSubmit),
                                            'form-control':
                                                defaultClassFlag == true,
                                            'input-form-design':
                                                defaultClassFlag == true
                                        }"
                                        class="form-control input-form-design"
                                        pattern="[0-9]+"
                                    />

                                    <div
                                        *ngIf="
                                            idFormControl.SponsorNumber
                                                .errors &&
                                            (idFormControl.SponsorNumber
                                                .touched ||
                                                isSubmit)
                                        "
                                        class=""
                                    >
                                        <div
                                            *ngIf="
                                                idFormControl.SponsorNumber
                                                    .errors.required
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                        <div
                                            *ngIf="
                                                idFormControl.SponsorNumber
                                                    .errors.pattern
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{
                                                "GENERIC.SN_PLACEHOLDER"
                                                    | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-2 d-flex">
                            <mat-checkbox
                                class="mt-4 user-agreement-confirm"
                                formControlName="save"
                                id="save"
                                [checked]="idFormControl.save.value"
                            >
                            </mat-checkbox>
                            <p
                                style="
                                    padding-left: 12px !important;
                                    padding-right: 12px !important;
                                    padding-top: 22px;
                                    font-size: 18px;
                                    color: #777676;
                                "
                            >
                                {{ "MMLHome.idCheckBox" | translate }}
                            </p>
                        </div>

                        <!-- Next & back Buttons -->

                        <div
                            class="mt-3 id-next"
                            [ngClass]="{
                                'mmp-next-btn-container-en':
                                    translate.currentLang == 'en-US',
                                'mmp-next-btn-container-ar':
                                    translate.currentLang != 'en-US'
                            }"
                            style="padding-right: 25px"
                        >
                            <div>
                                <button
                                    mat-button
                                    class="next-btn p-1"
                                    style="margin-right: 10px"
                                    (click)="submitIDVer(stepper)"
                                    [disabled]="!idFormControl.save.value"
                                    [ngClass]="{
                                        'disabled-button':
                                            !idFormControl.save.value
                                    }"
                                    id="mmpsubmitIDVer"
                                >
                                    {{ "GENERIC.NEXT" | translate }}
                                    <i
                                        class="fa fa-arrow-right"
                                        aria-hidden="true"
                                        *ngIf="translate.currentLang == 'en-US'"
                                    ></i>
                                    <i
                                        class="fa fa-arrow-left"
                                        aria-hidden="true"
                                        *ngIf="translate.currentLang != 'en-US'"
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-5">
                    <div>
                        <img
                            class="form-ltr-image"
                            *ngIf="this.translate.currentLang == 'en-US'"
                            src="assets/img/MML/IDIMG.png"
                            alt="aaa"
                        />

                        <img
                            class="form-rtl-image"
                            *ngIf="this.translate.currentLang != 'en-US'"
                            src="assets/img/MML/IDIMG1.png"
                            alt="aaa"
                        />
                    </div>
                </div>
            </div>
        </div>
    </mat-step>

    <!--02 -->
    <mat-step [stepControl]="personalDetailsForm">
        <ng-template matStepLabel>
            {{ "MMLHome.PersonalDetails" | translate }}
        </ng-template>

        <div
            class="claim-form mt-5"
            style="padding-left: 30px; padding-right: 30px"
        >
            <!-- Policy Info -->
            <div
                style="
                    margin-left: -10px !important;
                    margin-right: -10px !important;
                "
            >
                <legend
                    class="claim-title mt-3 mb-5"
                    style="text-align: center; font-size: 20px"
                >
                    {{ "MMLHome.policyInfo" | translate }}
                </legend>
            </div>
            <hr />
            <form [formGroup]="personalDetailsForm">
                <div class="pt-3">
                    <div class="row">
                        <div
                            class="col-md-2 pt-2"
                            style="padding-left: 0px; padding-right: 0px"
                        >
                            <label
                                for="driverId"
                                [ngClass]="{
                                    'mmp-payment-form-label-en':
                                        this.translate.currentLang === 'en-US',
                                    'mmp-payment-form-label-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }"
                                >{{
                                    "MMLHome.ProfessionSub" | translate
                                }}:</label
                            >
                        </div>
                        <div class="col-md-4">
                            <ng-select
                                [ngClass]="{
                                    'red-border-class':
                                        personalDetailsControl
                                            .ProfessionSubCategory.errors &&
                                        (personalDetailsControl
                                            .ProfessionSubCategory.touched ||
                                            isSubmit)
                                }"
                                placeholder="{{
                                    'MMLHome.SelectSubProFession' | translate
                                }}"
                                [searchable]="true"
                                class="input-form-design"
                                (change)="changeProfessionSubCategory($event)"
                            >
                                <ng-option
                                    *ngFor="let cat of professionSubCategories"
                                    [value]="cat"
                                >
                                    {{ cat?.name }}
                                </ng-option>
                            </ng-select>
                            <div
                                *ngIf="
                                    personalDetailsControl.ProfessionSubCategory
                                        .errors &&
                                    (personalDetailsControl
                                        .ProfessionSubCategory.touched ||
                                        isSubmit)
                                "
                                style="color: #ff001f"
                            >
                                <div
                                    *ngIf="
                                        personalDetailsControl
                                            .ProfessionSubCategory.errors
                                            .required
                                    "
                                >
                                    <i
                                        class="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    ></i>
                                    {{ "GENERIC.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="row w-100 m-0">
                <div
                    class="col-12"
                    style="max-height: fit-content; width: 100%"
                >
                    <form [formGroup]="personalDetailsForm">
                        <!-- Profession   -->
                        <div class="mb-4">
                            <!-- <div class="row mb-0"> -->
                            <!-- <div class="col-md-6 col-12">
                                    <div class="row">
                                        <div
                                            class="col-md-4 pt-2"
                                            style="
                                                padding-left: 0px;
                                                padding-right: 0px;
                                            "
                                        >
                                            <label
                                                for="driverId"
                                                [ngClass]="{
                                                    'mmp-payment-form-label-en':
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'mmp-payment-form-label-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                                >{{
                                                    "MMLHome.Profession"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-6">
                                            <ng-select
                                                [ngClass]="{
                                                    'red-border-class':
                                                        personalDetailsControl
                                                            .Profession
                                                            .errors &&
                                                        (personalDetailsControl
                                                            .Profession
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                placeholder="{{
                                                    'MMLHome.SelectProFession'
                                                        | translate
                                                }}"
                                                formControlName="Profession"
                                                [searchable]="true"
                                                class="input-form-design"
                                                (change)="
                                                    changeProfessionCategory(
                                                        $event
                                                    )
                                                "
                                            >
                                                <ng-option
                                                    *ngFor="
                                                        let cat of professionCategories
                                                    "
                                                    [value]="cat?.id"
                                                >
                                                    {{ cat?.id }}
                                                </ng-option>
                                            </ng-select>
                                            <div
                                                *ngIf="
                                                    personalDetailsControl
                                                        .Profession.errors &&
                                                    (personalDetailsControl
                                                        .Profession.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        personalDetailsControl
                                                            .Profession.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            <!-- </div> -->

                            <div class="row mb-2 mt-4">
                                <div class="col-md-6 col-12">
                                    <div class="row">
                                        <div
                                            class="col-md-4 pt-2"
                                            style="
                                                padding-left: 0px;
                                                padding-right: 0px;
                                            "
                                        >
                                            <label
                                                for="driverId"
                                                [ngClass]="{
                                                    'mmp-payment-form-label-en':
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'mmp-payment-form-label-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                                >{{
                                                    "MMLHome.PolicyStart"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field
                                                appearance="none"
                                                class="form-control input-form-design w-100"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        personalDetailsControl
                                                            .policyStartDate
                                                            .errors &&
                                                        (personalDetailsControl
                                                            .policyStartDate
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                            >
                                                <input
                                                    formControlName="policyStartDate"
                                                    matInput
                                                    [matDatepicker]="picker"
                                                    placeholder="{{
                                                        'MMLHome.mm-placehold'
                                                            | translate
                                                    }}"
                                                    [min]="minPolicyDate"
                                                    [max]="maxPolicyDate"
                                                    [readonly]="true"
                                                />
                                                <mat-datepicker-toggle
                                                    style="
                                                        margin-right: -6%;
                                                        margin-top: -11px;
                                                        color: #156538;
                                                    "
                                                    matIconSuffix
                                                    [for]="picker"
                                                ></mat-datepicker-toggle>
                                                <mat-datepicker
                                                    #picker
                                                ></mat-datepicker>
                                            </mat-form-field>
                                            <div
                                                *ngIf="
                                                    personalDetailsControl
                                                        .policyStartDate
                                                        .errors &&
                                                    (personalDetailsControl
                                                        .policyStartDate
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        personalDetailsControl
                                                            .policyStartDate
                                                            .errors.required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12">
                                    <div
                                        class="row"
                                        *ngIf="
                                            personalDetailsForm.controls[
                                                'Profession'
                                            ].value != null
                                        "
                                    >
                                        <div class="col-md-2"></div>

                                        <div
                                            class="col-md-4 pt-2"
                                            style="
                                                padding-left: 0px;
                                                padding-right: 0px;
                                            "
                                        >
                                            <label
                                                for="driverId"
                                                [ngClass]="{
                                                    'mmp-payment-form-label-en':
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'mmp-payment-form-label-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                                >{{
                                                    "MMLHome.covPeriod"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-6">
                                            <ng-select
                                                [ngClass]="{
                                                    'red-border-class':
                                                        personalDetailsControl
                                                            .coveragePeriod
                                                            .errors &&
                                                        (personalDetailsControl
                                                            .coveragePeriod
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                placeholder="{{
                                                    'MMLHome.Select' | translate
                                                }}"
                                                formControlName="coveragePeriod"
                                                [searchable]="true"
                                                class="input-form-design"
                                                (change)="
                                                    changeCoveragePeriod()
                                                "
                                            >
                                                <ng-option
                                                    *ngFor="
                                                        let policy of policyPeriods
                                                    "
                                                    [value]="policy?.id"
                                                >
                                                    {{ policy?.name }}
                                                </ng-option>
                                            </ng-select>
                                            <div
                                                *ngIf="
                                                    personalDetailsControl
                                                        .coveragePeriod
                                                        .errors &&
                                                    (personalDetailsControl
                                                        .coveragePeriod
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        personalDetailsControl
                                                            .coveragePeriod
                                                            .errors.required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Promo code -->
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <div class="row pt-2">
                                        <div
                                            class="col-md-4"
                                            style="
                                                padding-left: 0px;
                                                padding-right: 0px;
                                                font-size: 18px;
                                            "
                                            [ngClass]="{
                                                fs:
                                                    this.translate
                                                        .currentLang ===
                                                    'en-US',
                                                'fs-ar':
                                                    this.translate
                                                        .currentLang === 'ar-SA'
                                            }"
                                        >
                                            <label
                                                class="form-label pt-2"
                                                style="padding-top: 2px"
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                            >
                                                {{
                                                    "MMLHome.PromotionCode"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-6">
                                            <input
                                                type="text"
                                                class="input-form-design"
                                                formControlName="promotionCode"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        personalDetailsControl
                                                            .promotionCode
                                                            .errors &&
                                                        (personalDetailsControl
                                                            .promotionCode
                                                            .touched ||
                                                            isSubmit),
                                                    '':
                                                        defaultClassFlag ==
                                                        true,
                                                    'input-form-design':
                                                        defaultClassFlag == true
                                                }"
                                                style="width: 100%"
                                            />
                                            <div
                                                *ngIf="
                                                    personalDetailsControl
                                                        .promotionCode.errors &&
                                                    (personalDetailsControl
                                                        .promotionCode
                                                        .touched ||
                                                        isSubmit)
                                                "
                                                class=""
                                            >
                                                <div
                                                    *ngIf="
                                                        personalDetailsControl
                                                            .promotionCode
                                                            .errors.pattern
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.PCissue"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Question -->

                            <div class="row mb-3">
                                <div
                                    class="form-check"
                                    style="
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding-left: 0;
                                        padding-right: 0;
                                        font-size: 18px;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    <label
                                        class="form-check-label"
                                        for="flexSwitchCheckChecked"
                                        >{{
                                            "MMLHome.haveMMP" | translate
                                        }}</label
                                    >
                                    <div class="d-flex">
                                        <div class="form-check newFCR">
                                            <input
                                                #swith
                                                class="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="swith"
                                                (change)="
                                                    changeHaveMMPPersonalForm(
                                                        true
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexRadioDefault1"
                                                style="
                                                    color: #146437;
                                                    padding: 1px;
                                                    font-size: 17px;
                                                "
                                            >
                                                {{ "MMLHome.yes" | translate }}
                                            </label>
                                        </div>
                                        <div class="form-check newFCR">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="swith"
                                                checked
                                                (change)="
                                                    changeHaveMMPPersonalForm(
                                                        false
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexRadioDefault2"
                                                style="
                                                    color: #146437;
                                                    padding: 1px;
                                                    font-size: 17px;
                                                "
                                            >
                                                {{ "MMLHome.no" | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <!-- <input
                                        class="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="success-outlined"
                                        style="
                                            width: 60px;
                                            height: 30px;
                                            margin-right: 75px;
                                            margin-left: 75px;
                                        "
                                        formControlName="haveMMP"
                                        (change)="changeHaveMMPPersonalForm()"
                                    /> -->
                                </div>
                            </div>
                            <!-- -->
                            <div
                                class="row mb-5"
                                *ngIf="
                                    !personalFormFlag &&
                                    prevPolicyData.length == 0 &&
                                    personalFormFlag !== null
                                "
                            >
                                <div class="col-md-6 col-12 pt-2">
                                    <div class="row">
                                        <div
                                            class="col-md-4 pt-2"
                                            style="
                                                padding-left: 0px;
                                                padding-right: 0px;
                                                font-size: 18px;
                                            "
                                            [ngClass]="{
                                                fs:
                                                    this.translate
                                                        .currentLang ===
                                                    'en-US',
                                                'fs-ar':
                                                    this.translate
                                                        .currentLang === 'ar-SA'
                                            }"
                                        >
                                            <label
                                                for="driverId"
                                                [ngClass]="{
                                                    'mmp-payment-form-label-en':
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'mmp-payment-form-label-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                                >{{
                                                    "MMLHome.InsuranceCom"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-6">
                                            <ng-select
                                                [ngClass]="{
                                                    'red-border-class':
                                                        personalDetailsControl
                                                            .insurance.errors &&
                                                        (personalDetailsControl
                                                            .insurance
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                                placeholder="{{
                                                    'MMLHome.Select' | translate
                                                }}"
                                                formControlName="insurance"
                                                [searchable]="true"
                                                class="input-form-design"
                                            >
                                                <ng-option
                                                    *ngFor="
                                                        let com of insuranceCompanies
                                                    "
                                                    [value]="com?.id"
                                                >
                                                    {{ com?.name }}
                                                </ng-option>
                                            </ng-select>
                                            <div
                                                *ngIf="
                                                    personalDetailsControl
                                                        .insurance.errors &&
                                                    (personalDetailsControl
                                                        .insurance.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        personalDetailsControl
                                                            .insurance.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 pt-2">
                                    <div class="row">
                                        <div class="col-md-2"></div>

                                        <div
                                            class="col-md-4 pt-2"
                                            style="
                                                padding-left: 0px;
                                                padding-right: 0px;
                                                font-size: 18px;
                                            "
                                            [ngClass]="{
                                                fs:
                                                    this.translate
                                                        .currentLang ===
                                                    'en-US',
                                                'fs-ar':
                                                    this.translate
                                                        .currentLang === 'ar-SA'
                                            }"
                                        >
                                            <label
                                                for="driverId"
                                                [ngClass]="{
                                                    'mmp-payment-form-label-en':
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'mmp-payment-form-label-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                                >{{
                                                    "MOTORCLAIMS.POLICYNUMBER"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-6">
                                            <input
                                                #input
                                                id="MMLpolicyHolder"
                                                name="MMLpolicyHolder"
                                                type="text"
                                                formControlName="policyHolder"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        personalDetailsControl
                                                            .policyHolder
                                                            .errors &&
                                                        (personalDetailsControl
                                                            .policyHolder
                                                            .touched ||
                                                            isSubmit),
                                                    'form-control':
                                                        defaultClassFlag ==
                                                        true,
                                                    'input-form-design':
                                                        defaultClassFlag == true
                                                }"
                                                class="form-control input-form-design"
                                            />
                                            <div
                                                *ngIf="
                                                    personalDetailsControl
                                                        .policyHolder.errors &&
                                                    (personalDetailsControl
                                                        .policyHolder.touched ||
                                                        isSubmit)
                                                "
                                                class=""
                                            >
                                                <div
                                                    *ngIf="
                                                        personalDetailsControl
                                                            .policyHolder.errors
                                                            .required
                                                    "
                                                    style="color: #ff001f"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-12 pt-2">
                                    <div class="row">
                                        <div
                                            class="col-md-4 pt-2"
                                            style="
                                                padding-left: 0px;
                                                padding-right: 0px;
                                                font-size: 18px;
                                            "
                                            [ngClass]="{
                                                fs:
                                                    this.translate
                                                        .currentLang ===
                                                    'en-US',
                                                'fs-ar':
                                                    this.translate
                                                        .currentLang === 'ar-SA'
                                            }"
                                        >
                                            <label
                                                for="driverId"
                                                [ngClass]="{
                                                    'mmp-payment-form-label-en':
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'mmp-payment-form-label-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                                >{{
                                                    "MEDICAL.ExpiryDate"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field
                                                appearance="none"
                                                class="form-control input-form-design w-100"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        personalDetailsControl
                                                            .expiryDate
                                                            .errors &&
                                                        (personalDetailsControl
                                                            .expiryDate
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                            >
                                                <input
                                                    formControlName="expiryDate"
                                                    matInput
                                                    [matDatepicker]="picker"
                                                    placeholder="{{
                                                        'MMLHome.mm-placehold'
                                                            | translate
                                                    }}"
                                                    (dateChange)="
                                                        onExpiryDateChange()
                                                    "
                                                    [max]="maxExpiryDate"
                                                />
                                                <mat-datepicker-toggle
                                                    style="
                                                        margin-right: -6%;
                                                        margin-top: -11px;
                                                        color: #156538;
                                                    "
                                                    matIconSuffix
                                                    [for]="picker"
                                                ></mat-datepicker-toggle>
                                                <mat-datepicker
                                                    #picker
                                                ></mat-datepicker>
                                            </mat-form-field>
                                            <div
                                                *ngIf="
                                                    personalDetailsControl
                                                        .expiryDate.errors &&
                                                    (personalDetailsControl
                                                        .expiryDate.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        personalDetailsControl
                                                            .expiryDate.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-12 pt-2">
                                    <div class="row">
                                        <div class="col-md-2"></div>

                                        <div
                                            class="col-md-4 pt-2"
                                            style="
                                                padding-left: 0px;
                                                padding-right: 0px;
                                                font-size: 18px;
                                            "
                                            [ngClass]="{
                                                fs:
                                                    this.translate
                                                        .currentLang ===
                                                    'en-US',
                                                'fs-ar':
                                                    this.translate
                                                        .currentLang === 'ar-SA'
                                            }"
                                        >
                                            <label
                                                for="driverId"
                                                [ngClass]="{
                                                    'mmp-payment-form-label-en':
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'mmp-payment-form-label-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                                >{{
                                                    "MMLHome.Ratification"
                                                        | translate
                                                }}:</label
                                            >
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field
                                                appearance="none"
                                                class="form-control input-form-design w-100"
                                                [ngClass]="{
                                                    'red-border-class':
                                                        personalDetailsControl
                                                            .Ratification
                                                            .errors &&
                                                        (personalDetailsControl
                                                            .Ratification
                                                            .touched ||
                                                            isSubmit)
                                                }"
                                            >
                                                <input
                                                    formControlName="Ratification"
                                                    matInput
                                                    [matDatepicker]="pickerr"
                                                    placeholder="{{
                                                        'MMLHome.mm-placehold'
                                                            | translate
                                                    }}"
                                                    [min]="minRatroActiveDate"
                                                    [max]="
                                                        personalDetailsForm
                                                            .controls[
                                                            'expiryDate'
                                                        ].value
                                                    "
                                                />
                                                <mat-datepicker-toggle
                                                    style="
                                                        margin-right: -6%;
                                                        margin-top: -11px;
                                                        color: #156538;
                                                    "
                                                    matIconSuffix
                                                    [for]="pickerr"
                                                ></mat-datepicker-toggle>
                                                <mat-datepicker
                                                    #pickerr
                                                ></mat-datepicker>
                                            </mat-form-field>
                                            <div
                                                *ngIf="
                                                    personalDetailsControl
                                                        .Ratification.errors &&
                                                    (personalDetailsControl
                                                        .Ratification.touched ||
                                                        isSubmit)
                                                "
                                                style="color: #ff001f"
                                            >
                                                <div
                                                    *ngIf="
                                                        personalDetailsControl
                                                            .Ratification.errors
                                                            .required
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        "GENERIC.REQUIRED"
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- -->
                            <div
                                class="row mb-5"
                                *ngIf="
                                    personalFormFlag &&
                                    prevPolicyData.length > 0 &&
                                    personalFormFlag !== null
                                "
                            >
                                <div
                                    class="col-md-6"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 16px;
                                        color: #777676;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.InsuranceCom" | translate }}:
                                    {{ "MMLHome.GUlf" | translate }}
                                </div>
                                <div
                                    class="col-md-6"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 16px;
                                        color: #777676;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{
                                        "MOTORCLAIMS.POLICYNUMBER" | translate
                                    }}: {{ prevPolicyData[0]?.policyNumber }}
                                </div>
                                <div
                                    class="col-md-6"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 16px;
                                        color: #777676;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MEDICAL.ExpiryDate" | translate }}:
                                    {{
                                        prevPolicyData[0]?.dateTo
                                            | date : "dd/MM/yyyy"
                                    }}
                                </div>
                                <div
                                    class="col-md-6"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 16px;
                                        color: #777676;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.Ratification" | translate }}:
                                    {{
                                        prevPolicyData[0]?.dateFrom
                                            | date : "dd/MM/yyyy"
                                    }}
                                </div>
                            </div>
                            <!-- Question div items -->

                            <div
                                class="row mt-3"
                                *ngFor="let question of questions"
                            >
                                <div
                                    class="col-md-8"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    <label
                                        class="form-check-label"
                                        for="flexSwitchCheckChecked"
                                        >{{ question?.name }}
                                    </label>
                                </div>

                                <div
                                    class="col-md-4 form-check d-flex justify-content-end p-0"
                                >
                                    <div class="d-flex">
                                        <div class="form-check newFCR">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="{{ question?.name }}"
                                                id="{{ question?.name }}"
                                                (change)="
                                                    changeQuestionFlag(
                                                        true,
                                                        question
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexRadioDefault1"
                                                style="
                                                    color: #146437;
                                                    padding: 1px;
                                                    font-size: 17px;
                                                "
                                            >
                                                {{ "MMLHome.yes" | translate }}
                                            </label>
                                        </div>
                                        <div class="form-check newFCR">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="{{ question?.name }}"
                                                id="{{ question?.name }}"
                                                checked
                                                (change)="
                                                    changeQuestionFlag(
                                                        false,
                                                        question
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexRadioDefault2"
                                                style="
                                                    color: #146437;
                                                    padding: 1px;
                                                    font-size: 17px;
                                                "
                                            >
                                                {{ "MMLHome.no" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-md-12 mt-3"
                                    *ngIf="question['visible']"
                                >
                                    <textarea
                                        style="width: 98% !important"
                                        placeholder="{{
                                            'MMLHome.tellMore' | translate
                                        }}"
                                        rows="3"
                                        (input)="
                                            handleQuestionText($event, question)
                                        "
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <!-- Next & back Buttons -->
                        <div class="claim-form p-5 mt-5">
                            <legend class="claim-title">
                                {{ "MMLHome.CoverageDetails" | translate }}
                            </legend>
                            <hr />

                            <div style="text-align: center" class="pb-5">
                                <legend
                                    class="claim-title"
                                    style="font-size: 20px"
                                >
                                    {{ "MMLHome.Coverage" | translate }}
                                </legend>
                            </div>
                            <div class="row">
                                <div
                                    class="col-12"
                                    style="max-height: fit-content"
                                >
                                    <div class="row w-100 m-0">
                                        <div class="col-12" style="width: 100%">
                                            <form
                                                [formGroup]="
                                                    CoverageAndDocsForm
                                                "
                                            >
                                                <!-- Coverage Details   -->
                                                <div class="mb-4 mt-3">
                                                    <div class="row mb-3">
                                                        <div
                                                            class="col-md-6 col-12 mb-3"
                                                        >
                                                            <div class="row">
                                                                <div
                                                                    class="col-lg-4 col-md-4 pt-2"
                                                                    style="
                                                                        padding-left: 0px;
                                                                        padding-right: 0px;
                                                                        font-size: 18px;
                                                                    "
                                                                    [ngClass]="{
                                                                        fs:
                                                                            this
                                                                                .translate
                                                                                .currentLang ===
                                                                            'en-US',
                                                                        'fs-ar':
                                                                            this
                                                                                .translate
                                                                                .currentLang ===
                                                                            'ar-SA'
                                                                    }"
                                                                >
                                                                    <label
                                                                        for="driverId"
                                                                        [ngClass]="{
                                                                            'mmp-payment-form-label-en':
                                                                                this
                                                                                    .translate
                                                                                    .currentLang ===
                                                                                'en-US',
                                                                            'mmp-payment-form-label-ar':
                                                                                this
                                                                                    .translate
                                                                                    .currentLang ===
                                                                                'ar-SA'
                                                                        }"
                                                                        >{{
                                                                            "MMLHome.claimLimit"
                                                                                | translate
                                                                        }}:</label
                                                                    >
                                                                </div>
                                                                <div
                                                                    class="col-md-6"
                                                                >
                                                                    <ng-select
                                                                        [ngClass]="{
                                                                            'red-border-class':
                                                                                CoverageAndDocsFormControl
                                                                                    .claimLimit
                                                                                    .errors &&
                                                                                (CoverageAndDocsFormControl
                                                                                    .claimLimit
                                                                                    .touched ||
                                                                                    isSubmit)
                                                                        }"
                                                                        placeholder="{{
                                                                            'MMLHome.Select'
                                                                                | translate
                                                                        }}"
                                                                        formControlName="claimLimit"
                                                                        [searchable]="
                                                                            true
                                                                        "
                                                                        class="input-form-design"
                                                                        (change)="
                                                                            ChangeClaimLimit()
                                                                        "
                                                                    >
                                                                        <ng-option
                                                                            [value]="
                                                                                item.excess
                                                                            "
                                                                            *ngFor="
                                                                                let item of claimLimitData
                                                                            "
                                                                        >
                                                                            {{
                                                                                item.excess
                                                                            }}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                    <div
                                                                        *ngIf="
                                                                            CoverageAndDocsFormControl
                                                                                .claimLimit
                                                                                .errors &&
                                                                            (CoverageAndDocsFormControl
                                                                                .claimLimit
                                                                                .touched ||
                                                                                isSubmit)
                                                                        "
                                                                        style="
                                                                            color: #ff001f;
                                                                        "
                                                                    >
                                                                        <div
                                                                            *ngIf="
                                                                                CoverageAndDocsFormControl
                                                                                    .claimLimit
                                                                                    .errors
                                                                                    .required
                                                                            "
                                                                        >
                                                                            <i
                                                                                class="fa fa-exclamation-circle"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                            {{
                                                                                "GENERIC.REQUIRED"
                                                                                    | translate
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-md-2"
                                                                ></div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-md-6 col-12"
                                                        >
                                                            <div class="row">
                                                                <div
                                                                    class="col-md-2"
                                                                ></div>

                                                                <div
                                                                    class="col-md-4 pt-2"
                                                                    style="
                                                                        padding-left: 0px;
                                                                        padding-right: 0px;
                                                                        font-size: 18px;
                                                                    "
                                                                    [ngClass]="{
                                                                        fs:
                                                                            this
                                                                                .translate
                                                                                .currentLang ===
                                                                            'en-US',
                                                                        'fs-ar':
                                                                            this
                                                                                .translate
                                                                                .currentLang ===
                                                                            'ar-SA'
                                                                    }"
                                                                >
                                                                    <label
                                                                        for="driverId"
                                                                        [ngClass]="{
                                                                            'mmp-payment-form-label-en':
                                                                                this
                                                                                    .translate
                                                                                    .currentLang ===
                                                                                'en-US',
                                                                            'mmp-payment-form-label-ar':
                                                                                this
                                                                                    .translate
                                                                                    .currentLang ===
                                                                                'ar-SA'
                                                                        }"
                                                                        >{{
                                                                            "MMLHome.totalLimit"
                                                                                | translate
                                                                        }}:</label
                                                                    >
                                                                </div>
                                                                <div
                                                                    class="col-md-6"
                                                                >
                                                                    <ng-select
                                                                        [ngClass]="{
                                                                            'red-border-class':
                                                                                CoverageAndDocsFormControl
                                                                                    .totalAnnualLimit
                                                                                    .errors &&
                                                                                (CoverageAndDocsFormControl
                                                                                    .totalAnnualLimit
                                                                                    .touched ||
                                                                                    isSubmit)
                                                                        }"
                                                                        placeholder="{{
                                                                            'MMLHome.Select'
                                                                                | translate
                                                                        }}"
                                                                        formControlName="totalAnnualLimit"
                                                                        [searchable]="
                                                                            true
                                                                        "
                                                                        class="input-form-design"
                                                                    >
                                                                        <ng-option
                                                                            [value]="
                                                                                item.aggregate
                                                                            "
                                                                            *ngFor="
                                                                                let item of totalLimitData
                                                                            "
                                                                        >
                                                                            {{
                                                                                item.aggregate
                                                                            }}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                    <div
                                                                        *ngIf="
                                                                            CoverageAndDocsFormControl
                                                                                .totalAnnualLimit
                                                                                .errors &&
                                                                            (CoverageAndDocsFormControl
                                                                                .totalAnnualLimit
                                                                                .touched ||
                                                                                isSubmit)
                                                                        "
                                                                        style="
                                                                            color: #ff001f;
                                                                        "
                                                                    >
                                                                        <div
                                                                            *ngIf="
                                                                                CoverageAndDocsFormControl
                                                                                    .totalAnnualLimit
                                                                                    .errors
                                                                                    .required
                                                                            "
                                                                        >
                                                                            <i
                                                                                class="fa fa-exclamation-circle"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                            {{
                                                                                "GENERIC.REQUIRED"
                                                                                    | translate
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="row mb-2 mr-1 ml-1"
                                                        [ngClass]="{
                                                            'mb-5':
                                                                personalFormFlag ===
                                                                false,
                                                            'mb-3':
                                                                personalFormFlag ===
                                                                true
                                                        }"
                                                        *ngIf="
                                                            personalDetailsForm
                                                                .controls[
                                                                'coveragePeriod'
                                                            ]?.value == 24 ||
                                                            personalDetailsForm
                                                                .controls[
                                                                'coveragePeriod'
                                                            ]?.value == 12
                                                        "
                                                    >
                                                        <div
                                                            class="form-check"
                                                            style="
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: space-between;
                                                                padding-left: 0;
                                                                padding-right: 0;
                                                                font-size: 18px;
                                                            "
                                                            [ngClass]="{
                                                                fs:
                                                                    this
                                                                        .translate
                                                                        .currentLang ===
                                                                    'en-US',
                                                                'fs-ar':
                                                                    this
                                                                        .translate
                                                                        .currentLang ===
                                                                    'ar-SA'
                                                            }"
                                                        >
                                                            <label
                                                                class="form-check-label"
                                                                for="flexSwischCheckChecked"
                                                                >{{
                                                                    "MMLHome.include"
                                                                        | translate
                                                                }}</label
                                                            >
                                                            <div class="d-flex">
                                                                <div
                                                                    class="form-check newFCR"
                                                                >
                                                                    <input
                                                                        #swith
                                                                        class="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="swith"
                                                                        (change)="
                                                                            displayTheCovers(
                                                                                true
                                                                            )
                                                                        "
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexRadioDefault1"
                                                                        style="
                                                                            color: #146437;
                                                                            padding: 1px;
                                                                            font-size: 17px;
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MMLHome.yes"
                                                                                | translate
                                                                        }}
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    class="form-check newFCR"
                                                                >
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="swith"
                                                                        checked
                                                                        (change)="
                                                                            displayTheCovers(
                                                                                false
                                                                            )
                                                                        "
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexRadioDefault2"
                                                                        style="
                                                                            color: #146437;
                                                                            padding: 1px;
                                                                            font-size: 17px;
                                                                        "
                                                                    >
                                                                        {{
                                                                            "MMLHome.no"
                                                                                | translate
                                                                        }}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <!-- <input
                                                class="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="success-outlined"
                                                style="
                                                    width: 60px;
                                                    height: 30px;
                                                    margin-right: 75px;
                                                    margin-left: 75px;
                                                    font-size: 18px;
                                                "
                                                [ngClass]="{
                                                    fs:
                                                        this.translate
                                                            .currentLang ===
                                                        'en-US',
                                                    'fs-ar':
                                                        this.translate
                                                            .currentLang ===
                                                        'ar-SA'
                                                }"
                                                formControlName="Includequestion"
                                                (change)="displayTheCovers()"
                                            /> -->
                                                        </div>
                                                        <ul>
                                                            <div
                                                                class="row w-100 pt-2"
                                                            >
                                                                <div
                                                                    class="col-md-6 col-12 pt-2"
                                                                    style="
                                                                        padding-left: 0px;
                                                                        padding-right: 0px;
                                                                        font-size: 18px;
                                                                        color: #777676;
                                                                    "
                                                                    *ngFor="
                                                                        let cat of includeObjectCoverage
                                                                    "
                                                                    [ngClass]="{
                                                                        fs:
                                                                            this
                                                                                .translate
                                                                                .currentLang ===
                                                                            'en-US',
                                                                        'fs-ar':
                                                                            this
                                                                                .translate
                                                                                .currentLang ===
                                                                            'ar-SA'
                                                                    }"
                                                                >
                                                                    <li>
                                                                        {{
                                                                            cat?.name
                                                                        }}
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <!-- <legend
                                                    class="claim-title"
                                                    style="
                                                        font-size: 20px;
                                                        text-align: center;
                                                    "
                                                >
                                                    {{
                                                        "SERVICES.DOCUMENTS"
                                                            | translate
                                                    }}
                                                </legend> -->

                                                <!-- Docs   -->

                                                <!-- <div
                                                    style="
                                                        display: flex;
                                                        flex-direction: row;
                                                    "
                                                    class="pt-3 row"
                                                >
                                                    <div
                                                        class="col-md-6 pb-5"
                                                        style="
                                                            padding-left: 0;
                                                            padding-right: 0;
                                                            font-size: 18px;
                                                        "
                                                        [ngClass]="{
                                                            fs:
                                                                this.translate
                                                                    .currentLang ===
                                                                'en-US',
                                                            'fs-ar':
                                                                this.translate
                                                                    .currentLang ===
                                                                'ar-SA'
                                                        }"
                                                        *ngFor="
                                                            let item of DocsList
                                                        "
                                                    >
                                                        <div
                                                            class="text-center upload-item pb-2"
                                                        >
                                                            <label
                                                                for="MMPReports"
                                                                class="form-label m-0"
                                                            >
                                                                {{ item.name }}
                                                            </label>
                                                            <div>
                                                                <label
                                                                    class="form-label"
                                                                >
                                                                    <span
                                                                        style="
                                                                            color: #ff001f;
                                                                            font-size: 13px;
                                                                        "
                                                                        *ngIf="
                                                                            item.isMandatory ===
                                                                            true
                                                                        "
                                                                        >*
                                                                        {{
                                                                            "GENERIC.REQUIRED"
                                                                                | translate
                                                                        }}</span
                                                                    >
                                                                    <span
                                                                        style="
                                                                            color: #ff001f;
                                                                            font-size: 14px;
                                                                            visibility: hidden;
                                                                        "
                                                                        *ngIf="
                                                                            item.isMandatory ===
                                                                            false
                                                                        "
                                                                        >*
                                                                        {{
                                                                            "GENERIC.REQUIRED"
                                                                                | translate
                                                                        }}</span
                                                                    >
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="file"
                                                                    [id]="
                                                                        item.id
                                                                    "
                                                                    [required]="
                                                                        true
                                                                    "
                                                                    #myInput
                                                                    class="hidden"
                                                                    accept="application/pdf, image/*"
                                                                    multiple
                                                                    (change)="
                                                                        setDocsList(
                                                                            $event,
                                                                            item.id,
                                                                            item.isMandatory,
                                                                            item.name
                                                                        )
                                                                    "
                                                                />

                                                                <label
                                                                    [for]="
                                                                        item.id
                                                                    "
                                                                    class="custom-file-input upload-lbl"
                                                                >
                                                                    <img
                                                                        src="assets/img/medical-service/cloud-computing.png"
                                                                        width="25"
                                                                        alt=""
                                                                    />
                                                                    {{
                                                                        "MEDICAL.UploadFiles"
                                                                            | translate
                                                                    }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="row d-flex ng-star-inserted justify-content-center"
                                                            *ngIf="
                                                                ImgsId.includes(
                                                                    item.id
                                                                )
                                                            "
                                                        >
                                                            <ng-container
                                                                *ngFor="
                                                                    let img of nationalImgsList;
                                                                    let x = index
                                                                "
                                                            >
                                                                <div
                                                                    class="imageContainer ng-star-inserted text-center d-flex"
                                                                    *ngIf="
                                                                        img.id ==
                                                                        item.id
                                                                    "
                                                                >
                                                                    <div
                                                                        class="col-md-12 row mt-3"
                                                                    >
                                                                        <div
                                                                            class="item-container"
                                                                            (click)="
                                                                                DisplayDocument(
                                                                                    img
                                                                                )
                                                                            "
                                                                        >
                                                                            <div
                                                                                style="
                                                                                    overflow: clip;
                                                                                "
                                                                            >
                                                                                {{
                                                                                    img.name
                                                                                }}
                                                                            </div>
                                                                            <div>
                                                                                <span
                                                                                    class="delete"
                                                                                    (click)="
                                                                                        deleteAtt(
                                                                                            x
                                                                                        )
                                                                                    "
                                                                                    *ngIf="
                                                                                        img.id ==
                                                                                        item.id
                                                                                    "
                                                                                >
                                                                                    <fa-icon
                                                                                        [icon]="
                                                                                            faXmark
                                                                                        "
                                                                                    ></fa-icon>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div> -->

                                                <div class="mt-2 d-flex">
                                                    <mat-checkbox
                                                        class="mt-4 user-agreement-confirm"
                                                        formControlName="save"
                                                        id="savess"
                                                        [checked]="
                                                            CoverageAndDocsFormControl
                                                                .save.value
                                                        "
                                                    >
                                                    </mat-checkbox>
                                                    <p
                                                        style="
                                                            padding-left: 12px !important;
                                                            padding-right: 12px !important;
                                                            padding-top: 22px;
                                                            font-size: 18px;
                                                            color: #777676;
                                                        "
                                                    >
                                                        {{
                                                            "MMLHome.covCheck"
                                                                | translate
                                                        }}
                                                    </p>
                                                </div>
                                            </form>

                                            <!-- Next & back Buttons -->
                                            <div
                                                class="d-flex mt-3 justify-content-end align-items-center"
                                                style="
                                                    justify-content: center !important ;
                                                    padding-bottom: 25px;
                                                "
                                            >
                                                <div>
                                                    <!-- <button
                                                        mat-button
                                                        matStepperPrevious
                                                        class="next-btn mr-2 ml-2 p-1"
                                                        style="
                                                            margin-right: 10px;
                                                            width: 130px;
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-arrow-left"
                                                            aria-hidden="true"
                                                            *ngIf="
                                                                translate.currentLang ==
                                                                'en-US'
                                                            "
                                                        ></i>
                                                        <i
                                                            class="fa fa-arrow-right"
                                                            aria-hidden="true"
                                                            *ngIf="
                                                                translate.currentLang !=
                                                                'en-US'
                                                            "
                                                        ></i>
                                                        {{
                                                            "GENERIC.BACK"
                                                                | translate
                                                        }}
                                                    </button> -->

                                                    <button
                                                        mat-button
                                                        class="next-btn p-1"
                                                        id="mmpsubmitCovergaeDocsForm"
                                                        style="
                                                            margin-right: 10px;
                                                            width: 130px;
                                                        "
                                                        (click)="
                                                            submitCovergaeDocsForm(
                                                                stepper
                                                            )
                                                        "
                                                        [disabled]="
                                                            !CoverageAndDocsFormControl
                                                                .save.value
                                                        "
                                                        [ngClass]="{
                                                            'disabled-button':
                                                                !CoverageAndDocsFormControl
                                                                    .save.value
                                                        }"
                                                    >
                                                        {{
                                                            "MMLHome.calculate"
                                                                | translate
                                                        }}
                                                        <i
                                                            class="fa fa-arrow-right"
                                                            aria-hidden="true"
                                                            *ngIf="
                                                                translate.currentLang ==
                                                                'en-US'
                                                            "
                                                        ></i>
                                                        <i
                                                            class="fa fa-arrow-left"
                                                            aria-hidden="true"
                                                            *ngIf="
                                                                translate.currentLang !=
                                                                'en-US'
                                                            "
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div
                            class="d-flex mt-3 justify-content-end align-items-center"
                            style="
                                justify-content: center !important;
                                padding-bottom: 25px;
                            "
                        >
                            <div>
                                <button
                                    mat-button
                                    class="next-btn p-1"
                                    style="margin-right: 10px"
                                    (click)="submitPersonalDetails(stepper)"
                                    id="mmpsubmitPersonalDetails"
                                >
                                    {{ "GENERIC.NEXT" | translate }}
                                    <i
                                        class="fa fa-arrow-right"
                                        aria-hidden="true"
                                        *ngIf="translate.currentLang == 'en-US'"
                                    ></i>
                                    <i
                                        class="fa fa-arrow-left"
                                        aria-hidden="true"
                                        *ngIf="translate.currentLang != 'en-US'"
                                    ></i>
                                </button>
                            </div>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </mat-step>

    <!--Perview -->
    <mat-step [stepControl]="">
        <ng-template matStepLabel class="Stepperheader">
            {{ "MMLHome.perview" | translate }}
        </ng-template>

        <div class="claim-form p-5 mt-5" style="max-height: fit-content">
            <div class="d-flex justify-content-between">
                <legend class="claim-title" style="width: auto">
                    {{ "MMLHome.perview" | translate }}
                </legend>
                <h1
                *ngIf="policyIsPaid == false"
                    style="
                        color: #146437;
                        font-weight: 600;
                        font-size: 1.5rem !important;
                    "
                >
                    {{ timeRemaining }}
                </h1>
            </div>

            <hr style="margin-top: 0" />

            <div class="row w-100 m-0">
                <div class="col-md-7">
                    <h5 class="mb-0 text-success">
                        {{ "MMLHome.QutationInfo" | translate }}
                    </h5>

                    <div>
                        <div class="d-flex justify-content-between">
                            <div class="d-flex flex-row mt-1 text-success">
                                <span class="ms-1"
                                    >{{
                                        "MMLHome.Quotionnum" | translate
                                    }}
                                    :</span
                                >
                            </div>
                            <div
                                class="d-flex flex-row align-items-center text-success"
                            >
                                <h6>
                                    {{ "MMLHome.Quotionnum" | translate }} :
                                </h6>
                                <h6 class="fw-bold text-success ms-1">
                                    {{ qutationResponce?.trxId }}
                                </h6>
                            </div>
                        </div>

                        <div
                            class="p-2 d-flex justify-content-between align-items-center"
                            style="background-color: #eee"
                        >
                            <span>{{ "MMLHome.ValTo" | translate }} :</span>
                            <span>
                                {{
                                    qutationResponce?.quotationExpiryDate
                                        | date : "dd/MM/yyyy"
                                }}
                            </span>
                        </div>
                        <hr />
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div class="d-flex flex-row mt-1 text-success">
                                <!--<h6>Patient Balance</h6>-->
                                <span class="ms-1"
                                    >{{
                                        "MMLHome.claimLimit" | translate
                                    }}
                                    :</span
                                >
                            </div>
                            <div
                                class="d-flex flex-row align-items-center text-success"
                            >
                                <h6 class="fw-bold text-success ms-1">
                                    {{
                                        qutationResponce?.risks[0]
                                            .policyCategoryExcess | number
                                    }}
                                    {{ "MEDICAL.KSACUR" | translate }}
                                </h6>
                            </div>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div class="d-flex flex-row mt-1 text-success">
                                <!--<h6>Patient Balance</h6>-->
                                <span class="ms-1"
                                    >{{
                                        "MMLHome.totalLimit" | translate
                                    }}
                                    :</span
                                >
                            </div>
                            <div
                                class="d-flex flex-row align-items-center text-success"
                            >
                                <h6 class="fw-bold text-success ms-1">
                                    {{
                                        qutationResponce?.risks[0]
                                            .policyAggregate | number
                                    }}
                                    {{ "MEDICAL.KSACUR" | translate }}
                                </h6>
                            </div>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div class="d-flex flex-row mt-1 text-success">
                                <!--<h6>Patient Balance</h6>-->
                                <span class="ms-1">
                                    {{ "MMLHome.mmpStartDate" | translate }}
                                    :
                                </span>
                            </div>
                            <div
                                class="d-flex flex-row align-items-center text-success"
                            >
                                <h6 class="fw-bold text-success ms-1">
                                    {{
                                        qutationResponce?.policyDateTo
                                            | date : "dd/MM/yyyy"
                                    }}
                                </h6>
                            </div>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center text-success"
                        >
                            <div class="d-flex flex-row mt-1">
                                <!--<h6>Patient Balance</h6>-->
                                <span class="ms-1">
                                    {{ "MMLHome.Covers" | translate }}
                                </span>
                            </div>
                            <div
                                class="d-flex flex-row align-items-center text-success"
                            >
                                <h6
                                    class="fw-bold text-success ms-1"
                                    *ngFor="let cat of includeObjectPreview"
                                >
                                    {{ cat?.name }}
                                </h6>
                            </div>
                        </div>
                        <button
                            mat-button
                            class="next-btn mr-2 ml-2 p-1 mt-2"
                            style="
                                margin-right: 10px;
                                width: 165px;
                                font-size: 13px;
                            "
                            *ngIf="
                            policyIsPaid == false&&(
                                qutationResponce?.calcStatus === 'C' ||
                                qutationResponce?.calcStatus === 'R')
                            "
                            (click)="cancelQutation()"
                            id="mmpcancelQutation"
                        >
                            <i
                                class="fa fa-arrow-left"
                                aria-hidden="true"
                                *ngIf="translate.currentLang == 'en-US'"
                            ></i>
                            <i
                                class="fa fa-arrow-right"
                                aria-hidden="true"
                                *ngIf="translate.currentLang != 'en-US'"
                            ></i>
                            {{ "MMLHome.cancel" | translate }}
                        </button>

                        <button
                            mat-button
                            class="next-btn mr-2 ml-2 p-1 mt-2"
                            style="
                                margin-right: 10px;
                                width: 165px;
                                font-size: 13px;
                            "
                            *ngIf="qutationResponce?.calcStatus === 'UW'"
                            (click)="goBack()"
                        >
                            <i
                                class="fa fa-arrow-left"
                                aria-hidden="true"
                                *ngIf="translate.currentLang == 'en-US'"
                            ></i>
                            <i
                                class="fa fa-arrow-right"
                                aria-hidden="true"
                                *ngIf="translate.currentLang != 'en-US'"
                            ></i>
                            {{ "MMLHome.back" | translate }}
                        </button>
                        <button
                            mat-button
                            class="next-btn p-1 mt-2"
                            style="
                                margin-right: 10px;
                                width: 165px;
                                font-size: 13px;
                            "
                            *ngIf="
                            policyIsPaid == false&&( qutationResponce?.calcStatus === 'C' ||
                                qutationResponce?.calcStatus === 'R')
                            "
                            (click)="proceedToPayment(stepper)"
                            id="mmpproceedToPayment"
                        >
                            {{ "MMLHome.pay" | translate }}
                            <i
                                class="fa fa-arrow-right"
                                aria-hidden="true"
                                *ngIf="translate.currentLang == 'en-US'"
                            ></i>
                            <i
                                class="fa fa-arrow-left"
                                aria-hidden="true"
                                *ngIf="translate.currentLang != 'en-US'"
                            ></i>
                        </button>

                        <!-- //policy is paid -->
                        <button class="btn default-btn"
                        style="
                            margin-bottom: 30px;
                            margin-top: 30px;
                            font-size: 18px;
                            margin-left: 15px;
                            margin-right: 15px;
                        "
                        *ngIf="policyIsPaid == true&&translate.currentLang == 'en-US'"
                        (click)="download('en')"
                        id="mmpDownloadPolicyEn">
                    Download Policy
                </button>

                <button class="btn default-btn"
                        style="
                            margin-bottom: 30px;
                            margin-top: 30px;
                            font-size: 18px;
                            margin-left: 15px;
                            margin-right: 15px;
                        "
                        *ngIf="policyIsPaid == true&&translate.currentLang != 'en-US'"
                        (click)="download('ar')"
                        id="mmpDownloadPolicyAr">
                    تحميل الوثيقة
                </button>

                <button
                *ngIf="policyIsPaid == true"
                class="btn default-btn"
                        style="
                            margin-bottom: 30px;
                            margin-top: 30px;
                            font-size: 18px;
                            margin-left: 15px;
                            margin-right: 15px;
                        "
                        (click)="download('report')"
                        id="mmpDownloadReport">
                    {{ "MMLHome.report" | translate }}
                </button>
                        <!--<div class="btn btn-success btn-lg btn-block">Proceed to payment</div>-->
                    </div>
                </div>
                <div
                    class="col-md-5"
                    *ngIf="
                        qutationResponce?.calcStatus === 'C' ||
                        qutationResponce?.calcStatus === 'R'
                    "
                >
                    <div class="p-3" style="background-color: #eee">
                        <span class="fw-bold">
                            {{ "MMLHome.premBreak" | translate }}</span
                        >
                        <div class="d-flex justify-content-between mt-2">
                            <span> {{ "MMLHome.PS1" | translate }}</span>
                            <span>
                                {{
                                    showPromo &&
                                    qutationResponce?.promotionAmount !==
                                        null &&
                                    qutationResponce?.promotionAmount !==
                                        undefined
                                        ? (qutationResponce?.policyPremium +
                                              qutationResponce?.promotionAmount
                                          | number)
                                        : (qutationResponce?.policyPremium
                                          | number)
                                }}

                                {{ "MEDICAL.KSACUR" | translate }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between mt-2">
                            <span> {{ "MMLHome.PS2" | translate }}</span>
                            <span>
                                {{ qutationResponce?.policyVatAmount | number }}

                                {{ "MEDICAL.KSACUR" | translate }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-content-between mt-2"
                            *ngIf="
                                showPromo &&
                                qutationResponce?.promotionAmount !== null &&
                                qutationResponce?.promotionAmount !== undefined
                            "
                        >
                            <span>{{ "MMLHome.PromoAmount" | translate }}</span>
                            <span>
                                {{ qutationResponce?.promotionAmount | number }}

                                {{ "MEDICAL.KSACUR" | translate }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between mt-2">
                            <span>{{ "MMLHome.PS3" | translate }} </span>
                            <span>
                                {{
                                    showPromo &&
                                    qutationResponce?.promotionAmount !==
                                        null &&
                                    qutationResponce?.promotionAmount !==
                                        undefined
                                        ? (qutationResponce?.policyTotalPremium -
                                              qutationResponce?.promotionAmount
                                          | number)
                                        : (qutationResponce?.policyTotalPremium
                                          | number)
                                }}

                                {{ "MEDICAL.KSACUR" | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div style="text-align: center" class="pb-5">
                    <legend class="claim-title" style="font-size: 20px">
                        {{ "MMLHome.QutationInfo" | translate }}
                    </legend>
                </div>

                <div class="row w-100 m-0">
                    <div class="col-md-9 col-12 pt-2" style="padding: 0">
                        <div class="row w-100 m-0">-->
            <!-- <div
                        class="col-md-12 col-12"
                        style="
                            padding-left: 0px;
                            padding-right: 0px;
                            font-size: 18px;
                        "
                    >
                        {{ "MMLHome.QutExp" | translate }} :
                    </div> -->
            <!--<div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.Quotionnum" | translate }} :
                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{ qutationResponce?.trxId }}
                                </p>
                            </div>

                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.ValTo" | translate }} :

                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{
                                    qutationResponce?.quotationExpiryDate
                                        | date : "dd/MM/yyyy"
                                    }}
                                </p>
                            </div>

                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.claimLimit" | translate }} :

                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{
                                    qutationResponce?.risks[0]
                                        .policyCategoryExcess | number
                                    }}
                                    {{ "MEDICAL.KSACUR" | translate }}
                                </p>
                            </div>

                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.totalLimit" | translate }} :
                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{qutationResponce?.risks[0].policyAggregate | number}}
                                    {{ "MEDICAL.KSACUR" | translate }}
                                </p>
                            </div>

                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.mmpStartDate" | translate }}
                                :
                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{
                                    qutationResponce?.policyDateFrom
                                        | date : "dd/MM/yyyy"
                                    }}
                                </p>
                            </div>

                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.mmpEndDate" | translate }} :
                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{qutationResponce?.policyDateTo| date : "dd/MM/yyyy"}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-12 pt-2" style="text-align: end">
                        <img src="assets/img/MML/quotation.png"
                             alt=""
                             style="height: 220px" />
                    </div>
                </div>
                <div style="text-align: center" class="pb-5">
                    <legend class="claim-title" style="font-size: 20px">
                        {{ "MMLHome.PersonalDetails" | translate }}
                    </legend>
                </div>

                <div class="row w-100 m-0">
                    <div class="col-md-9 col-12" style="padding: 0">
                        <div class="row w-100 m-0 pt-4 pb-5">
                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.Client" | translate }} :
                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{ qutationResponce?.insuredName }}
                                </p>
                            </div>

                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.idNumber" | translate }} :
                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{ qutationResponce?.insuredIqamaId }}
                                </p>
                            </div>

                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.Birthdate" | translate }} :
                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{ memberDetails?.birthDate }}
                                </p>
                            </div>
                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.nationality" | translate }} :
                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{ memberDetails?.nationality }}
                                </p>
                            </div>
                            <div class="col-md-6 col-12 pt-4"
                                 style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                                 [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                                {{ "MMLHome.gender" | translate }} :
                                <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                                   [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                                    {{ memberDetails?.gender }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-12 pt-2">
                        <img src="assets/img/MML/per.png" alt="" />
                    </div>
                </div>

                <div style="text-align: center" class="pb-5">
                    <legend class="claim-title" style="font-size: 20px">
                        {{ "MMLHome.Covers" | translate }}
                    </legend>
                </div>

                <div class="row w-100 m-0">
                    <div class="col-md-9 col-12" style="padding: 0">-->
            <!--Covers-->

            <!--<ul>
                            <div class="row w-100 m-0 pb-5">
                                <div class="col-md-6 col-12 pt-2"
                                     style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    color: #777676;
                                "
                                     [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }"
                                     *ngFor="let cat of includeObjectPreview">
                            <li>
                                {{ cat?.name }}
                            </li>
                    </div>
                </div>
                </ul>-->
        </div>
        <!--</div>-->
        <!--Premium -->

        <!--<div style="text-align: center"
             class="pb-5"
             *ngIf="
                    qutationResponce?.calcStatus === 'C' ||
                    qutationResponce?.calcStatus === 'R'
                ">
            <legend class="claim-title" style="font-size: 20px">
                {{ "MMLHome.premBreak" | translate }}
            </legend>
        </div>

        <div class="row w-100 m-0"
             *ngIf="
                    qutationResponce?.calcStatus === 'C' ||
                    qutationResponce?.calcStatus === 'R'
                ">
            <div class="col-md-9 col-12" style="padding: 0">
                <div class="row w-100 m-0 pt-4 pb-5">
                    <div class="col-md-6 col-12 pt-4"
                         style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                         [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                        {{ "MMLHome.PS1" | translate }}
                        <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                           [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                            {{
                                    showPromo &&
                                    qutationResponce?.promotionAmount !==
                                        null &&
                                    qutationResponce?.promotionAmount !==
                                        undefined
                                        ? (qutationResponce?.policyPremium +
                                              qutationResponce?.promotionAmount
                                          | number)
                                        : (qutationResponce?.policyPremium
                                          | number)
                            }}

                            {{ "MEDICAL.KSACUR" | translate }}
                        </p>
                    </div>

                    <div class="col-md-6 col-12 pt-4"
                         style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                         [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                        {{ "MMLHome.PS2" | translate }}
                        <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                           [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                            {{ qutationResponce?.policyVatAmount | number }}

                            {{ "MEDICAL.KSACUR" | translate }}
                        </p>
                    </div>

                    <div class="col-md-6 col-12 pt-4"
                         style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                         *ngIf="
                                showPromo &&
                                qutationResponce?.promotionAmount !== null &&
                                qutationResponce?.promotionAmount !== undefined
                            "
                         [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                        {{ "MMLHome.PromoAmount" | translate }}
                        <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                           [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                            {{ qutationResponce?.promotionAmount | number }}

                            {{ "MEDICAL.KSACUR" | translate }}
                        </p>
                    </div>

                    <div class="col-md-6 col-12 pt-4"
                         style="
                                padding-left: 0px;
                                padding-right: 0px;
                                font-size: 18px;
                                display: flex;
                            "
                         [ngClass]="{
                                fs: this.translate.currentLang === 'en-US',
                                'fs-ar': this.translate.currentLang === 'ar-SA'
                            }">
                        {{ "MMLHome.PS3" | translate }}
                        <p style="
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    color: black;
                                    margin-top: -4px;
                                "
                           [ngClass]="{
                                    fs: this.translate.currentLang === 'en-US',
                                    'fs-ar':
                                        this.translate.currentLang === 'ar-SA'
                                }">
                            {{
                                    showPromo &&
                                    qutationResponce?.promotionAmount !==
                                        null &&
                                    qutationResponce?.promotionAmount !==
                                        undefined
                                        ? (qutationResponce?.policyTotalPremium -
                                              qutationResponce?.promotionAmount
                                          | number)
                                        : (qutationResponce?.policyTotalPremium
                                          | number)
                            }}

                            {{ "MEDICAL.KSACUR" | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 pt-2">
                <img src="assets/img/MML/money.png"
                     alt=""
                     style="height: 220px" />
            </div>
        </div>

        <div class="row w-100 m-0">
            <div class="col-md-12 col-12" style="padding: 0">-->
        <!-- Next & back Buttons -->
        <!--<div class="d-flex mt-3 justify-content-end align-items-center"
                     style="
                            justify-content: center !important ;
                            padding-bottom: 25px;
                        ">
                    <div>
                        <button mat-button
                                class="next-btn mr-2 ml-2 p-1 mt-2"
                                style="
                                    margin-right: 10px;
                                    width: 165px;
                                    font-size: 13px;
                                "
                                *ngIf="
                                    qutationResponce?.calcStatus === 'C' ||
                                    qutationResponce?.calcStatus === 'R'
                                "
                                (click)="cancelQutation()"
                                id="mmpcancelQutation">
                            <i class="fa fa-arrow-left"
                               aria-hidden="true"
                               *ngIf="translate.currentLang == 'en-US'"></i>
                            <i class="fa fa-arrow-right"
                               aria-hidden="true"
                               *ngIf="translate.currentLang != 'en-US'"></i>
                            {{ "MMLHome.cancel" | translate }}
                        </button>

                        <button mat-button
                                class="next-btn mr-2 ml-2 p-1 mt-2"
                                style="
                                    margin-right: 10px;
                                    width: 165px;
                                    font-size: 13px;
                                "
                                *ngIf="qutationResponce?.calcStatus === 'UW'"
                                (click)="goBack()">
                            <i class="fa fa-arrow-left"
                               aria-hidden="true"
                               *ngIf="translate.currentLang == 'en-US'"></i>
                            <i class="fa fa-arrow-right"
                               aria-hidden="true"
                               *ngIf="translate.currentLang != 'en-US'"></i>
                            {{ "MMLHome.back" | translate }}
                        </button>
                        <button mat-button
                                class="next-btn p-1 mt-2"
                                style="
                                    margin-right: 10px;
                                    width: 165px;
                                    font-size: 13px;
                                "
                                *ngIf="
                                    qutationResponce?.calcStatus === 'C' ||
                                    qutationResponce?.calcStatus === 'R'
                                "
                                (click)="proceedToPayment(stepper)"
                                id="mmpproceedToPayment">
                            {{ "MMLHome.pay" | translate }}
                            <i class="fa fa-arrow-right"
                               aria-hidden="true"
                               *ngIf="translate.currentLang == 'en-US'"></i>
                            <i class="fa fa-arrow-left"
                               aria-hidden="true"
                               *ngIf="translate.currentLang != 'en-US'"></i>
                        </button>
                    </div>
                </div>

                <div class="d-flex mt-3 justify-content-end align-items-center"
                     style="
                            justify-content: center !important ;
                            padding-bottom: 25px;
                        "
                     *ngIf="paymentStatus">
                    <div>
                        <button class="btn default-btn"
                                style="
                                    margin-bottom: 30px;
                                    margin-top: 30px;
                                    font-size: 18px;
                                    margin-left: 15px;
                                    margin-right: 15px;
                                "
                                *ngIf="translate.currentLang == 'en-US'"
                                (click)="download('en')"
                                id="mmpDownloadPolicyEn">
                            Download Policy
                        </button>

                        <button class="btn default-btn"
                                style="
                                    margin-bottom: 30px;
                                    margin-top: 30px;
                                    font-size: 18px;
                                    margin-left: 15px;
                                    margin-right: 15px;
                                "
                                *ngIf="translate.currentLang != 'en-US'"
                                (click)="download('ar')"
                                id="mmpDownloadPolicyAr">
                            تحميل الوثيقة
                        </button>

                        <button class="btn default-btn"
                                style="
                                    margin-bottom: 30px;
                                    margin-top: 30px;
                                    font-size: 18px;
                                    margin-left: 15px;
                                    margin-right: 15px;
                                "
                                (click)="download('report')"
                                id="mmpDownloadReport">
                            {{ "MMLHome.report" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>-->
        <!--</div>-->
    </mat-step>

    <!--Payment -->
    <mat-step [stepControl]="PaymentForm">
        <ng-template matStepLabel class="Stepperheader">
            {{ "MMLHome.payment" | translate }}
        </ng-template>

        <div class="claim-form p-5 mt-5">
            <legend class="claim-title">
                {{ "MMLHome.payment" | translate }}
            </legend>
            <hr />

            <div class="row" style="max-height: fit-content">
                <div class="row mt-3 payment-type-container">
                    <div class="col-md-8">
                        <p class="mmp-payment-option">
                            <!-- {{ "MMLHome.mada" | translate
                            }} -->
                            <span class="ml-3">
                                <img
                                    src="assets/img/MML/mada.svg"
                                    alt=""
                                    width="100px"
                                    height="45px"
                                />
                                <img
                                    src="assets/img/MML/visa.svg"
                                    alt=""
                                    width="50px"
                                    height="45px"
                                />
                                <img
                                    src="assets/img/MML/master-card.svg"
                                    alt=""
                                    width="50px"
                                    height="45px"
                                    class="ml-2"
                                />
                            </span>
                        </p>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-1">
                        <!-- <input
                            class="form-check-input"
                            type="radio"
                            name="mmpPaymentType"
                            id="payment1"
                            (change)="changePaymentType('cash')"
                            checked="true"
                        /> -->
                    </div>
                </div>

                <!-- <div class="row mt-3 mb-4 payment-type-container">
                    <div class="col-md-6">
                        <p class="mmp-payment-option">
                            {{ "MMLHome.payLater" | translate }}
                            <span class="mmp-payment-hint ml-3">
                                {{ "MMLHome.payLink" | translate }}
                            </span>
                        </p>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-1">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="mmpPaymentType"
                            id="payment2"
                            [disabled]="true"
                            (change)="changePaymentType('later')"
                        />
                    </div>
                </div> -->
                <div style="text-align: center" class="pt-4 pb-3">
                    <legend class="claim-title" style="font-size: 20px">
                        {{ "MMLHome.paymentInfo" | translate }}
                    </legend>
                </div>

                <form [formGroup]="PaymentForm">
                    <div class="row mt-4 mb-4 w-100">
                        <div class="col-md-6 pt-2">
                            <div class="row">
                                <div class="col-md-4">
                                    <label
                                        *ngIf="translate.currentLang == 'en-US'"
                                        class="mmp-payment-form-label-en pt-2"
                                        style="font-size: 18px"
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                        >{{ "MMLHome.iban" | translate }}</label
                                    >
                                    <label
                                        *ngIf="translate.currentLang != 'en-US'"
                                        class="mmp-payment-form-label-ar pt-2"
                                        style="font-size: 18px"
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                        >{{ "MMLHome.iban" | translate }}</label
                                    >
                                </div>

                                <div
                                    class="col-md-6"
                                    *ngIf="translate.currentLang == 'en-US'"
                                >
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text"
                                                id="basic-addon1"
                                                style="padding: 7px"
                                                >SA</span
                                            >
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="{{
                                                'MMLHome.iban' | translate
                                            }}"
                                            class="mmp-payment-input"
                                            type="number"
                                            formControlName="iban"
                                            [ngClass]="{
                                                'red-border-class':
                                                    PaymentFormControl.iban
                                                        .errors &&
                                                    (PaymentFormControl.iban
                                                        .touched ||
                                                        isSubmit),
                                                'form-control':
                                                    defaultClassFlag == true,
                                                'input-form-design':
                                                    defaultClassFlag == true
                                            }"
                                            maxlength="22"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                            style="
                                                border-radius: 5px !important;
                                            "
                                        />
                                    </div>

                                    <div
                                        *ngIf="
                                            PaymentFormControl.iban.errors &&
                                            (PaymentFormControl.iban.touched ||
                                                isSubmit)
                                        "
                                        class=""
                                    >
                                        <div
                                            *ngIf="
                                                PaymentFormControl.iban.errors
                                                    .required
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                        <div
                                            *ngIf="
                                                PaymentFormControl.iban.errors
                                                    .pattern
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{
                                                "GENERIC.InvalidIban"
                                                    | translate
                                            }}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="col-md-6"
                                    *ngIf="translate.currentLang == 'ar-SA'"
                                >
                                    <div class="input-group mb-3">
                                        <input
                                            type="text"
                                            placeholder="{{
                                                'MMLHome.iban' | translate
                                            }}"
                                            class="mmp-payment-input"
                                            type="number"
                                            formControlName="iban"
                                            [ngClass]="{
                                                'red-border-class':
                                                    PaymentFormControl.iban
                                                        .errors &&
                                                    (PaymentFormControl.iban
                                                        .touched ||
                                                        isSubmit),
                                                'form-control':
                                                    defaultClassFlag == true,
                                                'input-form-design':
                                                    defaultClassFlag == true
                                            }"
                                            maxlength="22"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                            style="
                                                border-radius: 5px !important;
                                            "
                                        />
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text"
                                                id="basic-addon1"
                                                style="padding: 7px"
                                                >SA</span
                                            >
                                        </div>
                                    </div>

                                    <div
                                        *ngIf="
                                            PaymentFormControl.iban.errors &&
                                            (PaymentFormControl.iban.touched ||
                                                isSubmit)
                                        "
                                        class=""
                                    >
                                        <div
                                            *ngIf="
                                                PaymentFormControl.iban.errors
                                                    .required
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                        <div
                                            *ngIf="
                                                PaymentFormControl.iban.errors
                                                    .pattern
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{
                                                "GENERIC.InvalidIban"
                                                    | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                        <div class="col-md-6 pt-2">
                            <div class="row">
                                <div class="col-md-2"></div>

                                <div class="col-md-4">
                                    <label
                                        class="mmp-payment-form-label-en pt-2"
                                        *ngIf="translate.currentLang == 'en-US'"
                                        style="font-size: 18px"
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                        >{{
                                            "MMLHome.email" | translate
                                        }}</label
                                    >
                                    <label
                                        class="mmp-payment-form-label-ar pt-2"
                                        *ngIf="translate.currentLang != 'en-US'"
                                        style="font-size: 18px"
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                        >{{
                                            "MMLHome.email" | translate
                                        }}</label
                                    >
                                </div>

                                <div class="col-md-6">
                                    <input
                                        type="text"
                                        placeholder="{{
                                            'MMLHome.email' | translate
                                        }}"
                                        class="mmp-payment-input"
                                        type="email"
                                        formControlName="email"
                                        [ngClass]="{
                                            'red-border-class':
                                                PaymentFormControl.email
                                                    .errors &&
                                                (PaymentFormControl.email
                                                    .touched ||
                                                    isSubmit),
                                            'form-control':
                                                defaultClassFlag == true,
                                            'input-form-design':
                                                defaultClassFlag == true
                                        }"
                                    />
                                    <div
                                        *ngIf="
                                            PaymentFormControl.email.errors &&
                                            (PaymentFormControl.email.touched ||
                                                isSubmit)
                                        "
                                        class=""
                                    >
                                        <div
                                            *ngIf="
                                                PaymentFormControl.email.errors
                                                    .required
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                        <div
                                            *ngIf="
                                                PaymentFormControl.email.errors
                                                    .pattern
                                            "
                                            style="color: #ff001f"
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{
                                                "GENERIC.InvalidEmail"
                                                    | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Card-->

                    <div
                        class="row pt-2 mb-4 w-100"
                        *ngIf="PaymentForm.controls['type'].value == 'cash'"
                    >
                        <div class="col-md-6 col-12">
                            <div class="row">
                                <div class="col-md-4 pt-2">
                                    <label
                                        for="driverId"
                                        class="form-label"
                                        style="font-size: 18px"
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                        >{{
                                            "MMLHome.PayMethod" | translate
                                        }}:</label
                                    >
                                </div>
                                <div class="col-md-6">
                                    <ng-select
                                        [ngClass]="{
                                            'red-border-class':
                                                PaymentFormControl.paymentType
                                                    .errors &&
                                                (PaymentFormControl.paymentType
                                                    .touched ||
                                                    isSubmit)
                                        }"
                                        placeholder="{{
                                            'MMLHome.crdT' | translate
                                        }}"
                                        formControlName="paymentType"
                                        [searchable]="true"
                                        class="input-form-design"
                                    >
                                        <ng-option [value]="'VISA'">
                                            VISA
                                        </ng-option>
                                        <ng-option [value]="'MASTER'">
                                            MASTER
                                        </ng-option>
                                        <ng-option [value]="'MADA'">
                                            MADA
                                        </ng-option>
                                    </ng-select>
                                    <div
                                        *ngIf="
                                            PaymentFormControl.paymentType
                                                .errors &&
                                            (PaymentFormControl.paymentType
                                                .touched ||
                                                isSubmit)
                                        "
                                        style="color: #ff001f"
                                    >
                                        <div
                                            *ngIf="
                                                PaymentFormControl.paymentType
                                                    .errors.required
                                            "
                                        >
                                            <i
                                                class="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            {{ "GENERIC.REQUIRED" | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center" class="pb-5">
                        <legend class="claim-title" style="font-size: 20px">
                            {{ "MMLHome.billing" | translate }}
                        </legend>
                    </div>
                    <div class="row w-100 m-0 pt-3 pb-2">
                        <div class="col-md-9 col-12 pt-2" style="padding: 0">
                            <div class="row w-100 m-0">
                                <div
                                    class="col-md-6 col-12 pt-4"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                        display: flex;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.city" | translate }} :
                                    <p
                                        style="
                                            padding-left: 0px;
                                            padding-right: 0px;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: black;
                                            margin-top: -4px;
                                        "
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                    >
                                        {{ memberBillingDetails?.city }}
                                    </p>
                                </div>

                                <div
                                    class="col-md-6 col-12 pt-4"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                        display: flex;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.district" | translate }} :
                                    <p
                                        style="
                                            padding-left: 0px;
                                            padding-right: 0px;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: black;
                                            margin-top: -4px;
                                        "
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                    >
                                        {{ memberBillingDetails?.district }}
                                    </p>
                                </div>
                                <div
                                    class="col-md-6 col-12 pt-4"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                        display: flex;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.street" | translate }} :
                                    <p
                                        style="
                                            padding-left: 0px;
                                            padding-right: 0px;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: black;
                                            margin-top: -4px;
                                        "
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                    >
                                        {{ memberBillingDetails?.street }}
                                    </p>
                                </div>
                                <div
                                    class="col-md-6 col-12 pt-4"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                        display: flex;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.buildingNum" | translate }} :
                                    <p
                                        style="
                                            padding-left: 0px;
                                            padding-right: 0px;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: black;
                                            margin-top: -4px;
                                        "
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                    >
                                        {{ memberBillingDetails?.buildingNum }}
                                    </p>
                                </div>
                                <div
                                    class="col-md-6 col-12 pt-4"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                        display: flex;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.postalCode" | translate }} :
                                    <p
                                        style="
                                            padding-left: 0px;
                                            padding-right: 0px;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: black;
                                            margin-top: -4px;
                                        "
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                    >
                                        {{ memberBillingDetails?.postalCode }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-12 pt-2">
                            <img src="assets/img/MML/address.png" alt="" />
                        </div>
                    </div>

                    <!--Submit-->
                    <div style="text-align: center" class="pb-5">
                        <legend class="claim-title" style="font-size: 20px">
                            {{ "MMLHome.premBreak" | translate }}
                        </legend>
                    </div>

                    <div class="row w-100 m-0">
                        <div class="col-md-9 col-12" style="padding: 0">
                            <div class="row w-100 m-0 pt-4 pb-5">
                                <div
                                    class="col-md-6 col-12 pt-4"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                        display: flex;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.PS1" | translate }}
                                    <p
                                        style="
                                            padding-left: 0px;
                                            padding-right: 0px;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: black;
                                            margin-top: -4px;
                                        "
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                    >
                                        {{
                                            showPromo &&
                                            qutationResponce?.promotionAmount !==
                                                null &&
                                            qutationResponce?.policyPremium !==
                                                undefined
                                                ? (qutationResponce?.policyPremium +
                                                      qutationResponce?.promotionAmount
                                                  | number)
                                                : (qutationResponce?.policyPremium
                                                  | number)
                                        }}

                                        {{ "MEDICAL.KSACUR" | translate }}
                                    </p>
                                </div>

                                <div
                                    class="col-md-6 col-12 pt-4"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                        display: flex;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.PS2" | translate }}
                                    <p
                                        style="
                                            padding-left: 0px;
                                            padding-right: 0px;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: black;
                                            margin-top: -4px;
                                        "
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                    >
                                        {{
                                            qutationResponce?.policyVatAmount
                                                | number
                                        }}

                                        {{ "MEDICAL.KSACUR" | translate }}
                                    </p>
                                </div>

                                <div
                                    class="col-md-6 col-12 pt-4"
                                    *ngIf="
                                        showPromo &&
                                        qutationResponce?.promotionAmount !==
                                            null &&
                                        qutationResponce?.promotionAmount !==
                                            undefined
                                    "
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                        display: flex;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.PromoAmount" | translate }}
                                    <p
                                        style="
                                            padding-left: 0px;
                                            padding-right: 0px;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: black;
                                            margin-top: -4px;
                                        "
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                    >
                                        {{
                                            qutationResponce?.promotionAmount
                                                | number
                                        }}

                                        {{ "MEDICAL.KSACUR" | translate }}
                                    </p>
                                </div>

                                <div
                                    class="col-md-6 col-12 pt-4"
                                    style="
                                        padding-left: 0px;
                                        padding-right: 0px;
                                        font-size: 18px;
                                        display: flex;
                                    "
                                    [ngClass]="{
                                        fs:
                                            this.translate.currentLang ===
                                            'en-US',
                                        'fs-ar':
                                            this.translate.currentLang ===
                                            'ar-SA'
                                    }"
                                >
                                    {{ "MMLHome.PS3" | translate }}
                                    <p
                                        style="
                                            padding-left: 0px;
                                            padding-right: 0px;
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: black;
                                            margin-top: -4px;
                                        "
                                        [ngClass]="{
                                            fs:
                                                this.translate.currentLang ===
                                                'en-US',
                                            'fs-ar':
                                                this.translate.currentLang ===
                                                'ar-SA'
                                        }"
                                    >
                                        {{
                                            showPromo &&
                                            qutationResponce?.promotionAmount !==
                                                null &&
                                            qutationResponce?.promotionAmount !==
                                                undefined
                                                ? (qutationResponce?.policyTotalPremium -
                                                      qutationResponce?.promotionAmount
                                                  | number)
                                                : (qutationResponce?.policyTotalPremium
                                                  | number)
                                        }}

                                        {{ "MEDICAL.KSACUR" | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-12 pt-2">
                            <img
                                src="assets/img/MML/money.png"
                                alt=""
                                style="height: 220px"
                            />
                        </div>
                    </div>

                    <div class="row w-100 m-0">
                        <div
                            class="col-12"
                            style="max-height: fit-content; width: 100%"
                        >
                            <!-- Next & back Buttons -->
                            <div
                                class="d-flex mt-3 justify-content-center align-items-center"
                            >
                                <button
                                    mat-button
                                    matStepperPrevious
                                    (click)="scrollTop()"
                                    class="next-btn mr-2 ml-2 p-1"
                                    style="margin-right: 10px; width: 130px"
                                >
                                    <i
                                        class="fa fa-arrow-left"
                                        aria-hidden="true"
                                        *ngIf="translate.currentLang == 'en-US'"
                                    ></i>
                                    <i
                                        class="fa fa-arrow-right"
                                        aria-hidden="true"
                                        *ngIf="translate.currentLang != 'en-US'"
                                    ></i>
                                    {{ "GENERIC.BACK" | translate }}
                                </button>
                                <button
                                    mat-button
                                    class="d-flex flex-row mmp-pay-btn p-1"
                                    (click)="submitPayment()"
                                    style="margin-right: 10px; width: 130px"
                                    id="mmpsubmitPayment"
                                >
                                    <span>{{
                                        "MMLHome.paymentBtn" | translate
                                    }}</span>
                                    <i
                                        class="fa fa-arrow-right ml-2"
                                        aria-hidden="true"
                                        *ngIf="translate.currentLang == 'en-US'"
                                    ></i>
                                    <i
                                        class="fa fa-arrow-left mr-2"
                                        aria-hidden="true"
                                        *ngIf="translate.currentLang != 'en-US'"
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </mat-step>
</mat-stepper>
