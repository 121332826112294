import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../AppConfigService';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class MedicalClaimsService {
    baseUrl: string;
    MemberDeductibleURL: string;
    SaveComplaintURL: string;
    getRequesterTypeListUrl: string;
    getDrivingLicenseListUrl: string;
    getCitysListUrl: string;
    getDocsUrl: string;
    ClaimInKSAUrl: string;
    ClaimOutKSAUrl: string;
    CashClaimURL: string;
    ClaimsURL: string;
    ProvidersURL: string;
    MemberDetailsURL: string;
    BenefitDetailsURL: string;
    CountriesURL: string;
    RegionURL:string;
    citiesURL : string;
    medicalApprovalURL : string;
    medicalApprovalDetailsURL : string;
    ServiceCountriesURL: string;
    lang:string
    ServiceCategoriesURL: string;
    ServiceCurrenciesURL: string;
    ServiceReasonCodesURL: string;
    ClaimDocumentsTypesURL: string;
    CliamRequestsURL : string ;
    CliamRequestsDetailsURL:string;
    embassyLetterURL:string;
    constructor(
        private http: HttpClient,
        private environment: AppConfigService,
        public translate: TranslateService
    ) {
        this.lang = this.translate.currentLang == "en-US" ? "en" : "ar";

        this.baseUrl = environment?.config?.servicesBaseUrl +  'Medical/';

        this.ClaimInKSAUrl = this.baseUrl + 'ClaimInKsa';
        this.ClaimOutKSAUrl = this.baseUrl + 'ClaimOutKsa';

        this.CashClaimURL = this.baseUrl + 'CashClaim?claimId=';
        this.ClaimsURL = this.baseUrl + 'Claim';
        this.ProvidersURL = this.baseUrl + 'PointedProviders?';
        this.MemberDetailsURL = this.baseUrl + 'MemberDetails?';
        this.MemberDeductibleURL = this.baseUrl + 'MemberDeductible?';
        this.BenefitDetailsURL = this.baseUrl + 'BenefitDetails?';
        this.CountriesURL = this.baseUrl + 'ServiceCountries?lang=';
        this.RegionURL= this.baseUrl + 'Regions?lang=';
        this.citiesURL= this.baseUrl + 'Cities?lang=';
        this.medicalApprovalURL= this.baseUrl + 'ApprovalMaster?';
        this.medicalApprovalDetailsURL= this.baseUrl + 'ApprovalServicesDetails?'
        this.ServiceCountriesURL = this.baseUrl + "ServiceCountries?lang=";
        this.ServiceCategoriesURL = this.baseUrl + "ServiceCategories?lang=";
        this.ServiceCurrenciesURL = this.baseUrl + "ServiceCurrencies?lang=";
        this.ServiceReasonCodesURL = this.baseUrl + "ServiceReasonCodes?lang=";
        this.ClaimDocumentsTypesURL = this.baseUrl + "ClaimDocumentsTypes?lang=";
        this.CliamRequestsURL = this.baseUrl + 'ClaimRequests?';
        this.CliamRequestsDetailsURL= this.baseUrl + 'ClaimRequestsDetails?lang=';
        this.embassyLetterURL= this.baseUrl + 'GetEmbassyLetter?';

        //this.getDocsUrl= this.baseUrl +'/EMotClaimDoc/GetDocsByClaimType?ClaimType=';
    }
    claimInKSA(model): Observable<any> {
        return this.http.post<any>(this.ClaimInKSAUrl, model);
    }
    claimOutKSA(model): Observable<any> {
        return this.http.post<any>(this.ClaimOutKSAUrl, model);
    }
    PointedProviders(queryString): Observable<any> {
        return this.http.get<any>(this.ProvidersURL + queryString);
    }
    MemberDetails(queryString): Observable<any> {
        return this.http.get<any>(this.MemberDetailsURL + queryString);
    }
    MemberDeductible(queryString): Observable<any> {
        return this.http.get<any>(this.MemberDeductibleURL + queryString);
    }
    BenefitDetails(queryString): Observable<any> {
        return this.http.get<any>(this.BenefitDetailsURL + queryString);
    }

    ServiceCountries(): Observable<any> {
        return this.http.get<any>(this.CountriesURL +  this.lang);
    }
    ServiceRegions(language: any): Observable<any> {
        return this.http.get<any>(this.RegionURL + language);
    }
    ServiceCities(language: any): Observable<any> {
        return this.http.get<any>(this.citiesURL + language);
    }

    MedicalApprovals(queryString): Observable<any> {
        return this.http.get<any>(this.medicalApprovalURL + queryString);
    }
    MedicalApprovalsDetails(queryString): Observable<any> {
        return this.http.get<any>(this.medicalApprovalDetailsURL + queryString);
    }
   

    ServiceCategories():Observable<any> {
        return this.http.get<any>(this.ServiceCategoriesURL + this.lang);
    }

    ServiceCurrencies():Observable<any> {
        return this.http.get<any>(this.ServiceCurrenciesURL + this.lang);
    }

    ServiceReasonCodes():Observable<any> {
        return this.http.get<any>(this.ServiceReasonCodesURL + this.lang);
    }

    ClaimDocumentsTypes():Observable<any> {
        return this.http.get<any>(this.ClaimDocumentsTypesURL + this.lang);
    }

    CliamRequests(queryString): Observable<any> {
        return this.http.get<any>(this.CliamRequestsURL + queryString);
    }
    CliamRequestsDetails(queryString):Observable<any> {
        return this.http.get<any>(this.CliamRequestsDetailsURL +queryString);
    }

    SubmitNewCliam(model):Observable<any>{
        return this.http.post<any>(this.ClaimsURL, model);
    }
    GetEmbassyLetterDetails(queryString):Observable<any> {
        return this.http.get<any>(this.embassyLetterURL +queryString);
    }

}
