import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserModel} from '../../../core/interfaces/user-model';
import {MenuItem} from 'primeng/api';
import {MotorClaimsService} from '../../../core/services/motor-claims.service';
import { Router } from '@angular/router';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { AppConfigService } from 'src/app/core/AppConfigService';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],

})
export class NavbarComponent implements OnInit {
    currentUser: UserModel;
    languagesItems:MenuItem[];
    userItems: MenuItem[];
    languageValue = localStorage.getItem('language');
    userVerified=this._LocalService.getJsonValue('userVerified');
    public aboutUsButton: boolean = false;
    websiteURL :any;
    currentPath: string = "";

    @Output() changeLang = new EventEmitter();
    constructor(public translate: TranslateService, private router: Router,
        private _LocalService: LocalService,
        private environment: AppConfigService

        ) { 
        this.currentPath = window.location.href.includes("Login-Motor") ? '1' : '0';

        this.currentUser = JSON.parse(localStorage.getItem('user')) as UserModel;
        translate.addLangs(['en-US', 'ar-SA']);
        
        this.languageValue = localStorage.getItem('language');
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/ar|ar-SA/) ? 'ar-SA' : 'en-US');
        this.userVerified=this._LocalService.getJsonValue('userVerified');
        this.websiteURL= environment?.config?.webSiteURL;
    }   

  ngOnInit(): void {

      this.languageHandler();
      this.currentUser = JSON.parse(localStorage.getItem('user')) as UserModel;
      this.languagesItems = [
          {
              label: 'عربى',
              icon: 'flag-icon flag-icon-sa',
              command: () => this.languageSwitcher('ar-SA'),
              styleClass: this.translate.currentLang === 'ar-SA' ? 'active' : '',
          },
          {
              label: 'English',
              icon: 'flag-icon flag-icon-us',
              command: () => this.languageSwitcher('en-US'),
              styleClass: this.translate.currentLang === 'en-US' ? 'active' : '',
          },
      ];
      this.userVerified=this._LocalService.getJsonValue('userVerified');

      this.languageValue = localStorage.getItem('language');
  }

    languageSwitcher(language): void {
       
        this.translate.use(language);

        this.translate.use(language);
        localStorage.setItem('language', language);

        this.changeLang.emit();
        this.languageValue = localStorage.getItem('language');
    }
    swichLanguage() {

      
        location.reload();

        if (this.languageValue === 'ar-SA') {
            this.languageSwitcher('en-US');
        } else {
            this.languageSwitcher('ar-SA');
        }
    }
    languageHandler(): void {
        let localLang = localStorage.getItem('language');
        if (localLang) {
            this.translate.use(localLang);
            this.translate.currentLang = localLang;
            // this.authService.switchLanguage(localLang).subscribe((res) => {});
        } else {
            this.translate.use('ar-SA');
            // this.authService.switchLanguage('ar-SA').subscribe((res) => {});
        }
        this.languageValue = localStorage.getItem('language');
    }
    ngDoCheck() {
        let path = this.router.url;
        if (path.includes('about-us') || path.includes('chairman-message')) {
            this.aboutUsButton = true;
        } else {
            this.aboutUsButton = false;
        }
        this.languageValue = localStorage.getItem("language");
        this.userVerified=this._LocalService.getJsonValue('userVerified');


    }
    openEsericeportal()
    {
        window.open(this.websiteURL+'/compliant'  ,'_blank' )
    }   
    logout()
    {
        this._LocalService.setJsonValue('userVerified',null);
        this._LocalService.setJsonValue('userLoginInfo',null);
        this._LocalService.setJsonValue('memberDetails',null);
        this._LocalService.setJsonValue('navLink', null);

        window.location.href = '';

    }
}
