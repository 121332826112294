import { Component } from '@angular/core';

@Component({
    selector: 'app-policy-coverage-page',
    templateUrl: './policy-coverage-page.component.html',
    styleUrls: ['./policy-coverage-page.component.scss'],
})
export class PolicyCoveragePageComponent {
    showAdditionalInfo = true;
    lang = localStorage.getItem('language');
    ComprehensiveShow = false;
    TPLShow= false;
  
    handleShowAdditionalInfo() {
        this.showAdditionalInfo = !this.showAdditionalInfo;
    }
}
