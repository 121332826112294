import { HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subject } from 'rxjs';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MmpService } from 'src/app/core/services/MMP-Service/mmp.service';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { UserService } from 'src/app/core/services/UserService/user.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-id-verification',
    templateUrl: './id-verification.component.html',
    styleUrls: ['./id-verification.component.scss'],
})
export class IdVerificationComponent implements OnInit, OnDestroy {
    lnk = localStorage.getItem('navLink');
    display: string;
    seconds: number;
    disableBTN = false;
    memberID;
    memberDetails: any;
    selectedMember: any;
    auth_member: any;
    retrievedObject = JSON.parse(localStorage.getItem('idObject'));

    private unsubscriber: Subject<void> = new Subject<void>();

    @HostListener('document:keyup.enter', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        this.navigate();
    }
    otpInputConfig: NgxOtpInputConfig = {
        otpLength: 4,
        autofocus: true,
        classList: {
            inputBox: 'my-super-box-class',
            input: 'my-super-class',
            inputFilled: 'my-super-filled-class',
            inputDisabled: 'my-super-disable-class',
            inputSuccess: 'my-super-success-class',
            inputError: 'my-super-error-class',
        },
    };
    ValidOTP = false;
    OTPValue: any;
    phoneNumber = this._LocalService.getJsonValue('mobileNumber');

    constructor(
        public user: UserService,
        public router: Router,
        public translate: TranslateService,
        public _router: Router,
        private mmpService: MmpService,
        private _LocalService: LocalService,
        public medicalClaimsService: MedicalClaimsService
    ) {
        this.memberDetails = null;
        this.OTPValue = null;
        this.retrievedObject = JSON.parse(localStorage.getItem('idObject'));
    }

    ngAfterViewInit() {
        window.scrollTo(0, 0);
    }

    ngOnInit(): void {
        this.retrievedObject = JSON.parse(localStorage.getItem('idObject'));
        localStorage.removeItem('EligableForIDVerfication');

        this.timer(2);
        // if (this._LocalService.getJsonValue('navLink') !== null) {
        //     this.lnk = this._LocalService.getJsonValue('navLink');
        // } else {
        //     this.lnk = '';
        // }
    }
    async navigate() {
        if (this.ValidOTP) {
            await this.OtpVerification();
        }
    }

    timer(minute) {
        // let minute = 1;
        let textSec: any = '0';
        let statSec: number = 60;
        this.seconds = minute * 60;
        const prefix = minute < 10 ? '0' : '';

        const timer = setInterval(() => {
            this.seconds--;
            if (statSec != 0) statSec--;
            else statSec = 59;

            if (statSec < 10) {
                textSec = '0' + statSec;
            } else textSec = statSec;

            this.display = `${prefix}${Math.floor(
                this.seconds / 60
            )}:${textSec}`;

            if (this.seconds == 0) {
                this.disableBTN = true;
                clearInterval(timer);
            }
        }, 1000);
    }
    async OtpVerification() {
        let queryString =
            'iqamaNumber=' +
            this.retrievedObject?.Iqama +
            '&mobileNumber=' +
            this.retrievedObject?.MobileNumber +
            '&otpCode=' +
            this.OTPValue;

        try {
            let result = await this.mmpService
                .GetVerifyOTP(queryString)
                .toPromise();

            if (result && result?.errors.length === 0) {
                localStorage.setItem('phNumberVerification', 'true');
                window.history.back();
            } else {
                if (result?.errors.length > 0) {
                    let msg = '';

                    for (
                        let index = 0;
                        index < result?.errors?.length;
                        index++
                    ) {
                        if (result?.errors[index]?.error) {
                            msg += result?.errors[index]?.error;
                            msg += '<br/>';
                        }
                    }
                    let dir: string = '';

                    Swal.fire({
                        title:
                            this.translate.currentLang == 'en-US'
                                ? 'Error!'
                                : '!خطأ',
                        iconHtml:
                            '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                        html:
                            "<div style='color:#FF001F; direction:" +
                            dir +
                            "'>" +
                            msg +
                            '</div>',
                        confirmButtonText:
                            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                        customClass: {
                            icon: 'submit-claim-failed-icon-img',
                            title: 'submit-claim-failed-swal-title',
                            confirmButton: 'submit-claim-failed-confirm-btn',
                        },
                    });
                } else {
                    let msg = '';
                    let dir: string = '';
                    if (this.translate.currentLang == 'en-US') {
                        dir = 'ltr';
                        msg = 'an Error occurred.';
                    } else {
                        dir = 'rtl';
                        msg = 'حدث خطأ ما';
                    }
                    Swal.fire({
                        title:
                            this.translate.currentLang == 'en-US'
                                ? 'Error!'
                                : '!خطأ',
                        iconHtml:
                            '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                        html:
                            "<div style='color:#FF001F; direction:" +
                            dir +
                            "'>" +
                            msg +
                            '</div>',
                        confirmButtonText:
                            this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                        customClass: {
                            icon: 'submit-claim-failed-icon-img',
                            title: 'submit-claim-failed-swal-title',
                            confirmButton: 'submit-claim-failed-confirm-btn',
                        },
                    });
                }
            }
        } catch (err) {
            let dir: string = '';
            let ErrMsg = '';

            if (this.translate.currentLang == 'en-US') {
                dir = 'ltr';
                ErrMsg = 'An Error Occurred !';
            } else {
                dir = 'rtl';
                ErrMsg = 'حدث خطأ ما !';
            }

            Swal.fire({
                title:
                    this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
                iconHtml:
                    '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                html:
                    "<div style='color:#FF001F; direction:" +
                    dir +
                    "'>" +
                    ErrMsg +
                    '</div>',
                confirmButtonText:
                    this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
                customClass: {
                    icon: 'submit-claim-failed-icon-img',
                    title: 'submit-claim-failed-swal-title',
                    confirmButton: 'submit-claim-failed-confirm-btn',
                },
            });
        }
    }

    handeOtpChange(value: string[]): void {
        this.ValidOTP = false;
    }

    handleFillEvent(value: string): void {
        this.ValidOTP = true;
        this.OTPValue = value;
    }
    async resendCode() {
        localStorage.setItem('EligableForIDVerfication', 'true');

        let query =
            'iqamaNumber=' +
            this.retrievedObject?.Iqama +
            '&mobileNumber=' +
            this.retrievedObject?.MobileNumber +
            '&isWeb='+ true;
        try {
            let otpRes = await this.mmpService
                .GetGenarateOTP(query)
                .toPromise();
            window.location.reload();
        } catch (err) {
            window.location.reload();

            // let msg = '';
            // let dir: string = '';
            // if (this.translate.currentLang == 'en-US') {
            //     dir = 'ltr';
            //     msg = 'Error while While sending OTP.';
            // } else {
            //     dir = 'rtl';
            //     msg = 'حدث خطأ أثناء الحصول  ارسال التحقق';
            // }
            // Swal.fire({
            //     title:
            //         this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
            //     iconHtml:
            //         '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            //     html:
            //         "<div style='color:#FF001F; direction:" +
            //         dir +
            //         "'>" +
            //         msg +
            //         '</div>',
            //     confirmButtonText:
            //         this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            //     customClass: {
            //         icon: 'submit-claim-failed-icon-img',
            //         title: 'submit-claim-failed-swal-title',
            //         confirmButton: 'submit-claim-failed-confirm-btn',
            //     },
            //}
            //);
        }
    }

    ngOnDestroy(): void {
        //localStorage.removeItem('idObject');
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }
}
