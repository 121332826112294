import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';

@Component({
  selector: 'app-quotation-details',
  templateUrl: './quotation-details.component.html',
  styleUrls: ['./quotation-details.component.scss']
})
export class QuotationDetailsComponent implements OnInit {

  @Input() CompanyDetails;
  @Input() PremiumData: any;
  @Input() QuotationNumber: any;
  @Output() onQuotationSubmit = new EventEmitter<any>();

  CompanyAddress: any;
  CompanyCCHI: any;
  isLoading = true;
  premData: any;
  quotNumber: any;
  compDetails: any;

  constructor(private smeService: MedicalSMEService) {
    this.premData = this.PremiumData;
    this.compDetails = this.CompanyDetails;
    this.quotNumber = this.QuotationNumber;
   }

  ngOnInit(): void {
    if (this.CompanyDetails !== null) {
      this.GetCompanyAddress();
      this.GetCompanyCCHI();
    }
  }
  async GetCompanyAddress() {
    this.isLoading = true;
    this.smeService
      .GetCompanyAddressInfo(this.CompanyDetails.crEntityNumber)
      .subscribe(
        (res) => {
          this.CompanyAddress = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  async GetCompanyCCHI() {
    this.isLoading = true;
    this.smeService
      .GetSponsorDetails(this.CompanyDetails.crEntityNumber)
      .subscribe(
        (res) => {
          this.CompanyCCHI = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  getBackToMemberDetails(res) {
    this.onQuotationSubmit.emit(res);
}

  nextTab(event: any) {
    this.premData = event.premData;
    this.quotNumber = event.quotNumber;
  }
}
