<div class="footer-container">
    <div class="container">
        <div class="row pt-4">
            <div class="col-md-3 pb-3">
                <h2 class="mb-2 footer-title">
                    {{ "FOOTER.About_Company" | translate }}
                </h2>
                <p>
                    {{ "FOOTER.About_Company_TEXT" | translate }}
                </p>
                <div class="d-flex suadi-central-bank">
                    <img
                        class="logo-footer-icon"
                        src="assets/img/logo/Logo_Saudi_Arabian_Monetary_Authority.svg.png"
                        alt=""
                    />
                </div>
            </div>
            <div class="col-md-3 pb-3">
                <h2 class="mb-2 footer-title">
                    {{ "FOOTER.IMPORTANT_Linkes" | translate }}
                </h2>
                <div class="mb-2">
                    <img
                        src="assets/img/logo/play.png"
                        *ngIf="languageValue === 'en-US'"
                        alt=""
                        class="play-icon"
                    />
                    <img
                        *ngIf="languageValue === 'ar-SA'"
                        src="assets/img/logo/play-ar.png"
                        alt=""
                        class="play-icon"
                    />
                    <a
                        href="https://www.sama.gov.sa/ar-sa/Pages/default.aspx"
                        target="_blank"
                    >
                        {{ "NAV.SAUDI_BANK" | translate }}</a
                    >
                </div>
                <div class="mb-2">
                    <img
                        src="assets/img/logo/play.png"
                        *ngIf="languageValue === 'en-US'"
                        alt=""
                        class="play-icon"
                    />
                    <img
                        src="assets/img/logo/play-ar.png"
                        *ngIf="languageValue === 'ar-SA'"
                        alt=""
                        class="play-icon"
                    />
                    <a
                        href="https://chi.gov.sa/Pages/default.aspx"
                        target="_blank"
                    >
                        {{ "NAV.COUNCIL" | translate }}</a
                    >
                </div>
                <div class="mb-2">
                    <img
                        src="assets/img/logo/play.png"
                        *ngIf="languageValue === 'en-US'"
                        alt=""
                        class="play-icon"
                    />
                    <img
                        src="assets/img/logo/play-ar.png"
                        *ngIf="languageValue === 'ar-SA'"
                        alt=""
                        class="play-icon"
                    />
                    <a [routerLink]="['/non-insurance-violations-reporting']">
                        {{ "non-insurance.title" | translate }}</a
                    >
                </div>

                <div class="mb-2">
                    <img
                        src="assets/img/logo/play.png"
                        *ngIf="languageValue === 'en-US'"
                        alt=""
                        class="play-icon"
                    />
                    <img
                        src="assets/img/logo/play-ar.png"
                        *ngIf="languageValue === 'ar-SA'"
                        alt=""
                        class="play-icon"
                    />
                    <a (click)="openEsericeportal()" style="cursor: pointer">
                        {{ "complaint.complaintF" | translate }}</a
                    >
                </div>
            </div>
            <div class="col-md-3 pb-3">
                <h2 class="mb-2 footer-title">
                    {{ "FOOTER.Contact_Info" | translate }}
                </h2>
                <div class="mb-0">
                    <img
                        src="assets/img/logo/location-pin.png"
                        alt=""
                        class="contact-icon"
                    />
                    {{ "FOOTER.Address" | translate }}
                </div>
                <div
                    class="mb-2"
                    style="padding-left: 16px; padding-right: 16px"
                >
                    {{ "FOOTER.Address-2" | translate }}
                </div>

                <div class="mb-2">
                    <img
                        src="assets/img/logo/telephone-call.png"
                        alt=""
                        class="contact-icon"
                    />
                    {{ "FOOTER.Phone_Number" | translate }}
                </div>
                <div class="mb-2">
                    <img
                        src="assets/img/logo/email.png"
                        alt=""
                        class="contact-icon"
                    />
                    customercare@gulfunion-saudi.com
                </div>
                <div class="row mt-2 social-dev">
                    <a
                        href="https://www.linkedin.com/company/gulf-union-al-ahlia-cooperative-insurance-co/"
                        target="_blank"
                        class="social-logos"
                    >
                        <img
                            src="assets/img/logo/linkedin.png"
                            class="logon"
                            (mouseenter)="over()"
                            (mouseout)="out()"
                            [class.logoBn]="hover"
                        />
                    </a>

                    <a
                        href="https://twitter.com/gulfunionKSA"
                        target="_blank"
                        class="social-logos"
                    >
                        <img
                            src="assets/img/logo/twitter.png"
                            class="logo"
                            (mouseenter)="overT()"
                            (mouseout)="outT()"
                            [class.logoB]="hoverT"
                        />
                    </a>
                </div>
                <div class="row mt-2 social-dev">
                    <div class="terprev">
                        <button
                            class="priv"
                            (click)="openPDF('privacy')"
                            style="padding-left: 2px; padding-right: 2px"
                        >
                            {{ "FOOTER.Privacy" | translate }}
                        </button>
                        <button class="priv">|</button>
                        <button
                            class="priv"
                            style="padding-left: 2px; padding-right: 2px"
                            (click)="openPDF('terms')"
                        >
                            {{ "FOOTER.terms" | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-3 d-flex flex-column pb-3 app-div">
                <h2
                    class="mb-2 footer-title"
                    [ngClass]="{
                        '': languageValue === 'en-US',
                        'app-ar': languageValue === 'ar-SA'
                    }"
                >
                    {{ "FOOTER.APP" | translate }}
                </h2>
                <img
                    src="assets/img/logo/google.png"
                    class="download-icons mb-3"
                    (click)="navToStore('google')"
                />
                <img
                    src="assets/img/logo/apple.png"
                    class="download-icons"
                    (click)="navToStore('apple')"
                />
            </div>
        </div>
    </div>
</div>
<div class="copy-rights-footer">
    <div class="container px-md-5 d-flex pt-2 pb-2 copy-right-p">
        <p>
            {{ "FOOTER.C-Right" | translate }}
        </p>
    </div>
</div>
