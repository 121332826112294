<div [ngClass]="{ 'dir-en': this.translate.currentLang === 'en-US', 'dir-ar': this.translate.currentLang === 'ar-SA' }">
    <div mat-dialog-title>
        <div class="d-flex justify-content-between">
            <span class="sme-header-text">{{ "SME.FILLHD" | translate }}</span>
            <img src="assets/img/medical-sme/dialog-close.png" width="30px" (click)="saveAndClose()" mat-dialog-close />
        </div>
    </div>
    <div class="sme-fast-calc-table-container">
        <span class="hint-message"> {{ "SME.ADD_MEMBER_HINT_MESSAGE" | translate}}</span>
        <div class="row mt-2 mb-4">

            <div class="col-md-4" style="padding-left: 0">
                <mat-form-field appearance="none" class="sme-select">
                    <mat-select [formControl]="classesControl" (selectionChange)="onClassChange()"
                        placeholder="{{ 'SME.CLASS' | translate }}">
                        <mat-option *ngFor="let i of Classes" [value]="i">{{
                            i
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="none" class="sme-select">
                    <mat-select [formControl]="nationalitiesControl" (selectionChange)="onNationalityChange()" [disabled]="!isClassSelected"
                        placeholder="{{ 'SME.NATIONALITIES' | translate }}">
                        <mat-option *ngFor="let i of Nationalites" [value]="i">
                            <span style="margin-right: 0.5rem; margin-left: 0.5rem" *ngIf="i.isoCode !== ''" class="flag-icon flag-icon-{{
                            i.isoCode | lowercase
                        }} flag-icon-squared"></span>
                            <img width="20px" *ngIf="i.isoCode === ''"
                                src="https://png.pngtree.com/png-vector/20190307/ourmid/pngtree-vector-flag-icon-png-image_762945.jpg"
                                alt="" />
                            {{ i.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="none" class="sme-select">
                    <mat-select (selectionChange)="OnchangeType($event.value)" [formControl]="typesControl" [disabled]="!isNationalitySelected"
                        placeholder="{{ 'MEDICAL.Type' | translate }}">
                        <mat-option *ngFor="let i of Types" [value]="i.typeName">{{
                            i.typeName
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="sme-fast-calc-table-details-container" *ngIf="ShowMembersTable">
        <span class="hint-message"> {{ "SME.HINT_MSG_2" | translate }}</span>
        <div class="sme-calc-tables mt-2">
            <form [formGroup]="form">
                <div class="mb-5 row" *ngIf="IsEmployee">
                    <table mat-table [dataSource]="dataRows" formArrayName="dataRows" class="sme-table table-bordered">
                        <!-- Age Code -->
                        <ng-container matColumnDef="ageCode">
                            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"
                                class="text-center sme-table-header">
                                {{ "SME.AGECODE" | translate }}
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="ageCodeRange">
                            <th mat-header-cell *matHeaderCellDef style="display: none"
                                class="text-center sme-table-header">
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center">
                                {{ element.get("ageCode").value }}
                            </td>
                        </ng-container>

                        <!-- emp -->
                        <ng-container matColumnDef="employee">
                            <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"
                                class="text-center sme-table-header">
                                {{ "SME.EMPLOYEE" | translate }}
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="empMale">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.MALE" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center">
                                <input formControlName="Male" mat-input type="text" [maxLength]="3"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)" class="sme-dialog-text"  />
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="empFemaleSingle">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.FEMALES" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center sme-table-title">
                                <input formControlName="FemaleS" mat-input type="text" [maxLength]="3"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)" class="sme-dialog-text" />
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="empFemaleMarried">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.FEMALEM" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center">
                                <input formControlName="FemaleM" mat-input type="text" [maxLength]="3"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)" class="sme-dialog-text" />
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedHeadersColumnsEmployee"></tr>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsEmployee"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsEmployee"></tr>
                    </table>
                </div>

                <div class="mb-5 row" *ngIf="IsSpouse">
                    <table mat-table [dataSource]="dataRows" formArrayName="dataRows" class="sme-table table-bordered">
                        <!-- Age Code -->
                        <ng-container matColumnDef="ageCode">
                            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"
                                class="text-center sme-table-header">
                                {{ "SME.AGECODE" | translate }}
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="ageCodeRange">
                            <th mat-header-cell *matHeaderCellDef style="display: none"
                                class="text-center sme-table-header">
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center">
                                {{ element.get("ageCode").value }}
                            </td>
                        </ng-container>

                        <!-- spouse -->
                        <ng-container matColumnDef="spouse">
                            <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"
                                class="text-center sme-table-header">
                                {{ "SME.Spouse2" | translate }}
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="spouseMale">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.MALE" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center sme-table-title">
                                <input formControlName="Male" mat-input type="text" [maxLength]="3"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"class="sme-dialog-text" />
                            </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="spouseFemaleSingle">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.FEMALES" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center">
                                <input formControlName="FemaleS" mat-input type="text" 
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"class="sme-dialog-text" />
                            </td>
                        </ng-container> -->
                        <ng-container matColumnDef="spouseFemaleMarried">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.FEMALE" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center">
                                <input formControlName="FemaleM" mat-input type="text" [maxLength]="3"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"class="sme-dialog-text" />
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedHeadersColumnsSpouse"></tr>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsSpouse"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsSpouse"></tr>
                    </table>
                </div>

                <div class="mb-5 row" *ngIf="IsChild">
                    <table mat-table [dataSource]="dataRows" formArrayName="dataRows" class="sme-table table-bordered">
                        <!-- Age Code -->
                        <ng-container matColumnDef="ageCode">
                            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"
                                class="text-center sme-table-header">
                                {{ "SME.AGECODE" | translate }}
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="ageCodeRange">
                            <th mat-header-cell *matHeaderCellDef style="display: none"
                                class="text-center sme-table-header">
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center">
                                {{ element.get("ageCode").value }}
                            </td>
                        </ng-container>

                        <!-- child -->
                        <ng-container matColumnDef="child" class="text-center">
                            <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"
                                class="text-center sme-table-header">
                                {{ "SME.CHILD" | translate }}
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="childMale">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.MALE" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center sme-table-title">
                                <input formControlName="Male" mat-input type="text" [maxLength]="3"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"class="sme-dialog-text" />
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="childFemaleSingle">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.FEMALES" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center">
                                <input formControlName="FemaleS" mat-input type="text" [maxLength]="3"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)" class="sme-dialog-text" />
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="childFemaleMarried">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.FEMALEM" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center sme-table-title">
                                <input formControlName="FemaleM" mat-input type="text" [maxLength]="3"
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)" class="sme-dialog-text" />
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedHeadersColumnsChild"></tr>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsChild"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsChild"></tr>
                    </table>
                </div>

                <div class="mb-5 row" *ngIf="IsOthers">
                    <table mat-table [dataSource]="dataRows" formArrayName="dataRows" class="sme-table table-bordered">
                        <!-- Age Code -->
                        <ng-container matColumnDef="ageCode">
                            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"
                                class="text-center sme-table-header">
                                {{ "SME.AGECODE" | translate }}
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="ageCodeRange">
                            <th mat-header-cell *matHeaderCellDef style="display: none"
                                class="text-center sme-table-header">
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center">
                                {{ element.get("ageCode").value }}
                            </td>
                        </ng-container>

                        <!-- others -->
                        <ng-container matColumnDef="others" class="text-center">
                            <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"
                                class="text-center sme-table-header">
                                {{ "SME.CHILD" | translate }}
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="othersMale">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.MALE" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center sme-table-title"></td>
                        </ng-container>
                        <ng-container matColumnDef="othersFemaleSingle">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.FEMALES" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center sme-table-title"></td>
                        </ng-container>
                        <ng-container matColumnDef="othersFemaleMarried">
                            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                                {{ "SME.FEMALEM" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index" [formGroupName]="index"
                                class="text-center sme-table-title"></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedHeadersColumnsOthers"></tr>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsOthers"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsOthers"></tr>
                    </table>
                </div>
            </form>
        </div>
    </div>
    <div class="sme-save-btn mt-1" *ngIf="ShowMembersTable">
        <button mat-raised-button (click)="onSubmit()" class="sme-calculate-btn">
            {{ "SME.SAVE" | translate }}
        </button>
        <button mat-raised-button type="button" (click)="saveAndClose()" class="sme-calculate-btn">
            {{ "SME.SAVE&CLOSE" | translate }}
        </button>
    </div>

    <div class="d-flex text-center justify-content-center align-items-center" *ngIf="!ShowMembersTable && isSaved">
        <span style="color:#156538; font-size: 25px; font-weight: 700; padding: 10%;">
        {{ "SME.MEMBER_TYPES_ADDED_MESSAGE" | translate }}</span>
    </div>

    <div class="sme-save-btn mt-1" *ngIf="!ShowMembersTable && OutputTypes.length > 0">
        <button mat-raised-button type="button" (click)="closeDialog()" class="sme-calculate-btn">
            {{ "SME.SAVE&CLOSE" | translate }}
        </button>
    </div>
</div>