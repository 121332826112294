<br />

  <br />
  <section class="h-100 gradient-form" style="background-color: #eee;">

    <br />
    <div class="row">
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
            <div class="example-header">

                <input autocomplete="false" type="text" id="form2Example11"   class="form-control"
                (keyup)="applyFilter($event.target.value)" placeholder="{{'LogIn.Filter' | translate}}"
                 />


          </div>
        </div>
        <div class="col-md-2">
            <div class="example-header">

                <input autocomplete="false" type="text" id="Name"   class="form-control" value="{{name}}"
                disabled
                 />


          </div>
        </div>
        <div class="col-md-2">
            <div class="example-header">

                <input autocomplete="false" type="text" id="id"   class="form-control" value="{{nat}}"
                disabled
                 />


          </div>
        </div>
    </div>
    <br />
    <mat-card class="example-card col-md-12">

        <!-- <mat-card-header>
          <div mat-card-avatar class="example-header-image text-center"></div>
          <mat-card-title></mat-card-title>
          <mat-card-subtitle>

        </mat-card-subtitle>
        </mat-card-header> -->

        <mat-card-content>
            <div class="panel panel-default">
                <div class="table-content col-md-12" style="overflow-x: auto">
                    <div class="mat-elevation-z8">
                <mat-table [dataSource]="dataSource" matSort >

                  <!-- ID Column -->
                  <ng-container matColumnDef="portalReference">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{"LogIn.PortalReference" | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.portal_Ref}} </mat-cell>
                  </ng-container>

                  <!-- Progress Column -->
                  <ng-container matColumnDef="accidentNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>  {{"LogIn.AccidentNo" | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.reportno}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="claimStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{"LogIn.AccidentDate" | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.accidentdate}} </mat-cell>
                  </ng-container>
                  <!-- Name Column -->
                  <ng-container matColumnDef="accidentDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{"LogIn.ClaimNo" | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.claimno}} </mat-cell>
                  </ng-container>

                  <!-- Color Column -->
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{"LogIn.ClaimStatus" | translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let row" >
                    <div *ngIf="row.claimstatus == 'Closed'">
                        <button mat-raised-button style="cursor:context-menu !important;background-color: #770921;color:white" disabled >
                            {{row.claimstatus}}
                        </button>
                    </div>
                    <div *ngIf="row.claimstatus != 'Closed'">
                        <button disabled mat-raised-button style="cursor: context-menu !important;background-color: #075f24;color:white" disabled>
                            {{row.claimstatus}}
                        </button>
                    </div>
                </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="documentNote">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{"LogIn.DocStatus" | translate}}</mat-header-cell>

                    <mat-cell *matCellDef="let row" >
                        <div *ngIf="row.documentstatus == 'Document In-complete'">
                            <button mat-raised-button 
                            style="cursor: pointer !important;background-color: #770921;color:white" 
                            (click)="openMissingDialog(row)" >
                                {{row.documentstatus}}
                            </button>
                        </div>
                        <div *ngIf="row.documentstatus != 'Document In-complete' && row.documentstatus != 'Documents Under Review'">
                            <button disabled mat-raised-button style="cursor: pointer !important;background-color: #075f24;color:white" >
                                {{row.documentstatus}}
                            </button>
                        </div>
                        <div *ngIf="row.documentstatus == 'Documents Under Review'">
                            <button mat-raised-button style="cursor: pointer !important;background-color:#008ab3;color:white" (click)="openMissingDialog(row)" >
                                {{row.documentstatus}}
                            </button>
                        </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="details">
                    <mat-header-cell *matHeaderCellDef>{{"LogIn.More" | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <button mat-raised-button style="cursor: pointer !important;background-color: #cfac3d;color:white" (click)="openDialog(row)">{{"LogIn.Show" | translate}}</button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;">
                  </mat-row>
                </mat-table>
            </div>
        </div>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
              </div>
        </mat-card-content>

      </mat-card>

  </section>

