<mat-stepper #stepper>
    <mat-step><app-sme-quotation-form-step [CompanyDetails]="CompanyDetails" [QuotationNumber]="QuotationNumber"
            [myStepper]="stepper" (onUploadUsersSubmit)="goToPremiumDetails($event)"></app-sme-quotation-form-step>
    </mat-step>
    <mat-step>
        <div *ngIf="stepper.selectedIndex == 1">
            <app-sme-quotation-table-step [SponsorId]="SponsorId" [myStepper]="stepper"
                (onUploadUsersSubmit)="goToPremiumDetails($event)"></app-sme-quotation-table-step>
        </div>
    </mat-step>
</mat-stepper>