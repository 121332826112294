import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../AppConfigService';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentOnlineService {
    baseUrl: string;
    lang: string;
    GetPaymentInfoURL: string;
    UpdateDetailsURL: string;
    PaymentURL: string;
    PaymentStatusURL: string;
    constructor(private http: HttpClient,
        private environment: AppConfigService,
        public translate: TranslateService) {

        this.lang = this.translate.currentLang == 'en-US' ? 'en' : 'ar';
        this.baseUrl = environment?.config?.servicesBaseUrl + 'Payment/';
        this.GetPaymentInfoURL = this.baseUrl + "GetPaymentInfo?query=";
        this.UpdateDetailsURL = this.baseUrl + "UpdateDetails";
        this.PaymentURL = this.baseUrl + "PaymentProcess";
        this.PaymentStatusURL =  this.baseUrl + "getGeneralPaymentStatus";

    }

    GetPaymentInfo(queryString): Observable<any> {
        return this.http.get<any>(
            this.GetPaymentInfoURL + queryString 
        );
    }
    UpdatePaymentDetails(model): Observable<any> {
        return this.http.post<any>(this.UpdateDetailsURL, model);
    }
    ProceedPaymentData(model): Observable<any> {
        return this.http.post<any>(this.PaymentURL, model);
    }

     GetPaymentStatus(model): Observable<any> {
        return this.http.post<any>(this.PaymentStatusURL, model);
    }
}
