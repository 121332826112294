<div>
    <div class="page-title-area bg-1">
        <div class="container col-md-10">
            <div class="page-title-content">
                <h2>{{ "MEDICAL.FamilyMembers" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/"
                            >{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li>
                        <a routerLink="/medical-profile">
                            {{ "NAV.MEDICAL" | translate }}

                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li class="active" style="font-weight: 500">
                        {{ "MEDICAL.FamilyMembers" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="container pt-3 pb-0">
    <div class="row" style="height: 60px">
        <div class="claims-header col-lg-4 col-md-12 col-sm-12">
            <div class="d-flex">
                <a href="/medical-profile">
                    <img
                        src="assets/img/medical-service/icons8-back-arrow-90.png"
                        class="back-icon"
                        alt=""
                        [ngClass]="{
                            'arrow-img-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                    />
                </a>
                <h5
                    class="color-green"
                    [ngClass]="{
                        'title-en': this.translate.currentLang == 'en-US',
                        'title-ar': this.translate.currentLang != 'en-US'
                    }"
                >
                    {{ "MEDICAL.FamilyMembers" | translate }}
                </h5>
            </div>
            <hr
                [ngClass]="{
                    'hr-en': this.translate.currentLang == 'en-US',
                    'hr-ar': this.translate.currentLang != 'en-US'
                }"
            />
        </div>
    </div>
</div>

<div class="container pt-3 pb-4">
    <div class="row">
        <div class="col-lg-4 col-md-12 pb-1">
            <div class="profile-card row pt-2">
                <div></div>
                <img
                    class="avatar-s"
                    *ngIf="selectedMember.gender === 'Male'"
                    src="assets/img/medical-service/Screenshot (216)-modified.png"
                />
                <img
                    class="avatar-s"
                    *ngIf="selectedMember.gender === 'Female'"
                    src="assets/img/medical-service/female.png"
                />
                <div
                    class="row"
                    [ngClass]="{
                        'degree-en': this.translate.currentLang == 'en-US',
                        'degree-ar': this.translate.currentLang != 'en-US'
                    }"
                >
                    {{ selectedMember.className }}
                </div>
                <div>
                    <h5 class="name">{{ selectedMember.memberEName }}</h5>
                    <span class="id">
                        {{ "MEDICAL.NationalId" | translate }} :{{
                            selectedMember.id
                        }}
                    </span>
                </div>
                <div></div>
            </div>
            <!-- Services Table -->
        </div>

        <div
            class="col-lg-4 col-md-12"
            *ngFor="let tabl of allTables; let i = index"
        >
            <div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 60px">
                                <h4
                                    class="services"
                                    *ngIf="tabl[0].relation === 'Spouse'"
                                >
                                    {{ "MEDICAL.Spouse" | translate }}

                                    <div
                                        [ngClass]="{
                                            'hr-line':
                                                this.translate.currentLang ==
                                                'en-US',
                                            'hr-line-ar':
                                                this.translate.currentLang !=
                                                'en-US'
                                        }"
                                    ></div>
                                </h4>

                                <h4
                                    class="services"
                                    *ngIf="tabl[0].relation === 'Children'"
                                >
                                    {{ "MEDICAL.Children" | translate }}

                                    <div
                                        [ngClass]="{
                                            'hr-line':
                                                this.translate.currentLang ==
                                                'en-US',
                                            'hr-line-ar':
                                                this.translate.currentLang !=
                                                'en-US'
                                        }"
                                    ></div>
                                </h4>

                                <h4
                                    class="services"
                                    *ngIf="tabl[0].relation === 'Parent'"
                                >
                                    {{ "MEDICAL.Parent" | translate }}

                                    <div
                                        [ngClass]="{
                                            'hr-line':
                                                this.translate.currentLang ==
                                                'en-US',
                                            'hr-line-ar':
                                                this.translate.currentLang !=
                                                'en-US'
                                        }"
                                    ></div>
                                </h4>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tabl">
                            <td style="border-radius: 0">
                                <img
                                    height="30"
                                    width="30"
                                    *ngIf="
                                        item.gender === 'Female' &&
                                        item.relation === 'Spouse'
                                    "
                                    src="assets/img/medical-service/woman.png"
                                />
                                <img
                                    height="30"
                                    width="30"
                                    *ngIf="
                                        item.gender === 'Male' &&
                                        item.relation === 'Spouse'
                                    "
                                    src="assets/img/medical-service/man1.png"
                                />
                                <img
                                    height="30"
                                    width="30"
                                    *ngIf="
                                        item.gender === 'Male' &&
                                        item.relation === 'Children'
                                    "
                                    src="assets/img/medical-service/man.png"
                                />
                                <img
                                    height="30"
                                    width="30"
                                    *ngIf="
                                        item.gender === 'Female' &&
                                        item.relation === 'Children'
                                    "
                                    src="assets/img/medical-service/girl (1).png"
                                />
                            </td>
                            <td style="border-radius: 0">
                                <h5 class="services-item">
                                    {{ item.memberEName }}
                                </h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
