import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../AppConfigService';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    lang: string;
    baseUrl: string;
    UserLoginURL: string;
    UserOtpURL: string;
    RefreshTokenURL: string;
    constructor(
        private http: HttpClient,
        private environment: AppConfigService,
        public translate: TranslateService
    ) {
        this.lang = this?.translate?.currentLang == 'en-US' ? 'en' : 'ar';

        this.baseUrl = environment.config.servicesBaseUrl + 'User/';

        this.UserLoginURL = this?.baseUrl + 'Login';
        this.UserOtpURL = this?.baseUrl + 'VerifyOtp';
        this.RefreshTokenURL = this?.baseUrl + 'RefreshToken';
    }

    UserLogin(queryString): Observable<any> {
        
        return this.http.post<any>(this.UserLoginURL , queryString);
    }

    OTP(queryString,headers): Observable<any> {
        return this.http.post<any>(this.UserOtpURL, queryString, { headers: headers });
    } 
    RefreshToken(queryString): Observable<any> {
        return this.http.post<any>(this.RefreshTokenURL, queryString);
    }
}
