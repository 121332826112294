import { Component, OnInit, Inject } from '@angular/core';
import Swal from 'sweetalert2'
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NavigationExtras } from "@angular/router";

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

  selectedService:boolean = false;
  
  constructor(public translate: TranslateService,    
    public _router: Router
    ) { }

  ngOnInit(): void {
  }
 async ChooseClaimType() {
  this.selectedService=true;
  let dir: string = "";
        if (this.translate.currentLang == 'en-US') {
          dir = "ltr";
        }
        else {
          dir = "rtl";
        }
        
    const { value: TypeId } = await Swal.fire({
      title: this.translate.currentLang == 'en-US'? "Choose Your Claim Type" : "اختر نوع الوثيقة",
      iconHtml: '<img width=40 height=40 src="assets/img/swal-icons-img/writing.png">',
      input: 'radio',
      showCloseButton: true,
      confirmButtonText:this.translate.currentLang == 'en-US'? "Submit" : "متابعة",
      inputLabel: 'Your email address',
      inputOptions: {
        '8': this.translate.currentLang == 'en-US'? "Comprehensive" : "شامل",
        '7': this.translate.currentLang == 'en-US'? "Third Party" : "طرف ثالث"
      },
      didClose: ()=> {
        this.selectedService=false;
      },
      inputValidator: (value:any) => {
        if (!value) {
          return this.translate.currentLang == 'en-US'?"Please Select Claim Type" : "يرجي اختيار نوع الوثيقة"
        }
      },
      customClass: {
        icon: 'icon-img',
        title: 'claim-type-swal-title',
        input: 'claim-type-swal-input',
        confirmButton: 'claim-type-confirm-btn',
      }
    })
    if (TypeId) {
      // pass type id to motor claim 
      let claimType = { typeId: TypeId };
      let navigationExtras: NavigationExtras = {
        state: {
          user: claimType,
        },
      };
      this._router.navigate(['/new-motor-claim'], navigationExtras);
    }
  }
}