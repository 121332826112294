<loading *ngIf="isLoading">{{ isLoading }}</loading>
<loading *ngIf="isSubmitted">{{ isSubmitted }}</loading>

<div class="container-fluid">
    <div class="d-flex flex-row col-12 pb-5">
        <div class="col-6 d-flex align-items-center">
            <label class="textbox-lbl" [ngClass]="{
                'pl-5-ar': languageValue != 'en-US', 'pl-5-en': languageValue === 'en-US' }" 
                >{{ "SME.CLASSES" | translate }}</label>
            <input
                class="form-control input-form-design"
                style="width: 70%"
                placeholder="{{ 'SME.CLASSES_PLACEHOLDER' | translate }}"
                type="text"
                [(ngModel)]="selectedClasses"
                disabled
            />
            <!-- <tag-input style="width: 70%;" class="form-control input-form-design" [(ngModel)]='selectedClasses' disabled></tag-input> -->
            <button class="add-class-btn" (click)="OpenAddClassDialog()">
                +
            </button>
        </div>

        <div class="col-6 d-flex align-items-center">
            <label class="textbox-lbl" [ngClass]="{
                'pl-5-ar': languageValue != 'en-US', 'pl-5-en': languageValue === 'en-US' }" >{{
                "SME.NATIONALITIES" | translate
            }}</label>
            <mat-form-field appearance="none" class="sme-select">
                <mat-select
                    [formControl]="nationalities"
                    placeholder="{{ 'SME.NATIONALITIES' | translate }}"
                    multiple
                >
                <!-- <input type="text" (keyup)="onKey($event)" style="width: 100%;
                    border: 1px solid #965432; border-radius: 7px;  height: 40px; padding:3%"
                placeholder="{{ 'SME.FILTER_NATIONALITY' | translate }}"
                > -->

                    <mat-option
                        *ngFor="let item of SMENationalities"
                        [value]="item"
                    >
                        <span *ngIf="item.isoCode !== ''"
                            class="flag-icon flag-icon-{{
                                item.isoCode | lowercase
                            }} flag-icon-squared"
                        ></span>
                        <img width="20px" *ngIf="item.isoCode === ''" src="https://png.pngtree.com/png-vector/20190307/ourmid/pngtree-vector-flag-icon-png-image_762945.jpg" alt=""/>
                        {{ item.name }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row sme-table-container">
        <div class="sme-left-line"></div>
        <div class="sme-details-container">
            <h4 class="sme-details">{{ "SME.DETAILS" | translate }}</h4>
        </div>
        <div [ngClass]="{
            'sme-right-line-ar': languageValue != 'en-US', 'sme-right-line-en': languageValue === 'en-US' }"></div>
    </div>

    <div class="row mt-2">
        <div class="col-md-4">
            <button
                (click)="OpenFillDataModal()"
                [ngClass]="{
                    'sme-fill-data-btn-disabled': selectedClasses.length === 0 || nationalities.value === '',
                    'sme-fill-data-btn': selectedClasses.length !== 0 && nationalities.value !== ''}"
                [disabled]="selectedClasses.length === 0 || nationalities.value === ''">
                {{ "SME.FILLHD" | translate }}
            </button>
        </div>
    </div>

    <label class="sme-note mt-4 mb-4">
        {{ "SME.MUSTFILLDDL" | translate }}
    </label>

    <div class="row sme-table-container">
        <div class="sme-left-line"></div>
        <div class="sme-details-container">
            <h4 class="sme-details">{{ "SME.MEMBERDETAILS" | translate }}</h4>
        </div>
        <div [ngClass]="{
            'sme-right-line-ar': languageValue != 'en-US', 'sme-right-line-en': languageValue === 'en-US' }"></div>
    </div>

    <div class="row mt-2">
        <div class="col-md-4" style="padding-left: 0">
            <mat-form-field appearance="none" class="sme-select">
                <mat-select [disabled]="this.NumberOfEmployees <= 0"
                    [formControl]="ChosenclassesControl"
                    (selectionChange)="onChangeClass($event.value)"
                    placeholder="{{ 'SME.CLASS' | translate }}"
                >
                    <mat-option *ngFor="let i of selectedClasses" [value]="i">{{
                        i
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4" style="padding-left: 0">
            <mat-form-field appearance="none" class="sme-select">
                <mat-select
                    [formControl]="ChosenNationalityControl"
                    (selectionChange)="onChangeNationality($event.value)"
                    placeholder="{{ 'SME.NATIONALITIES' | translate }}"
                >
                    <mat-option
                        *ngFor="let i of uniqufillNatDd"
                        [value]="i.NationalityName"
                    >
                    <span
                    style="margin-right: 0.5rem; margin-left: 0.5rem"
                    *ngIf="i.NationalityIsoCode !== ''"
                    class="flag-icon flag-icon-{{
                        i.NationalityIsoCode | lowercase
                    }} flag-icon-squared"
                    ></span>
                    <img width="20px" *ngIf="i.NationalityIsoCode === ''" src="https://png.pngtree.com/png-vector/20190307/ourmid/pngtree-vector-flag-icon-png-image_762945.jpg" alt=""/>
                        {{ i.NationalityName }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <label class="sme-note mt-4 mb-2">
        {{ "SME.MUST_SELECT_DDL" | translate }}
    </label>

    <div class="mb-5 mt-2 row">
        <table
            mat-table
            [dataSource]="dataSource"
            class="sme-table table-bordered"
        >
            <!-- Age Code -->
            <ng-container matColumnDef="ageCode">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [attr.rowspan]="2"
                    class="text-center sme-table-header"
                >
                    {{ "SME.AGECODE" | translate }}
                </th>
            </ng-container>
            <ng-container matColumnDef="ageCodeRange">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="display: none"
                    class="text-center sme-table-header"
                ></th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    {{ element.ageCodeRange }}
                </td>
            </ng-container>

            <!-- emp -->
            <ng-container matColumnDef="employee">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [attr.colspan]="3"
                    class="text-center sme-table-header"
                >
                    {{ "SME.EMPLOYEE" | translate }}
                </th>
            </ng-container>
            <ng-container matColumnDef="empMale">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.MALE" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    {{ element.employee.empMale }}
                </td>
            </ng-container>
            <ng-container matColumnDef="empFemaleSingle">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.FEMALES" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="text-center sme-table-title"
                >
                    {{ element.employee.empFemaleSingle }}
                </td>
            </ng-container>
            <ng-container matColumnDef="empFemaleMarried">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.FEMALEM" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    {{ element.employee.empFemaleMarried }}
                </td>
            </ng-container>

            <!-- spouse -->
            <ng-container matColumnDef="spouse">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [attr.colspan]="3"
                    class="text-center sme-table-header"
                >
                    {{ "SME.SPOUSE" | translate }}
                </th>
            </ng-container>
            <ng-container matColumnDef="spouseMale">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.MALE" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="text-center sme-table-title"
                >
                    {{ element.spouse.spouseMale }}
                </td>
            </ng-container>
            <ng-container matColumnDef="spouseFemaleSingle">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.FEMALES" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    {{ element.spouse.spouseFemaleSingle }}
                </td>
            </ng-container>
            <ng-container matColumnDef="spouseFemaleMarried">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.FEMALEM" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    {{ element.spouse.spouseFemaleMarried }}
                </td>
            </ng-container>

            <!-- child -->
            <ng-container matColumnDef="child" class="text-center">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [attr.colspan]="3"
                    class="text-center sme-table-header"
                >
                    {{ "SME.CHILD" | translate }}
                </th>
            </ng-container>
            <ng-container matColumnDef="childMale">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.MALE" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="text-center sme-table-title"
                >
                    {{ element.child.childMale }}
                </td>
            </ng-container>
            <ng-container matColumnDef="childFemaleSingle">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.FEMALES" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    {{ element.child.childFemaleSingle }}
                </td>
            </ng-container>
            <ng-container matColumnDef="childFemaleMarried">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.FEMALEM" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="text-center sme-table-title"
                >
                    {{ element.child.childFemaleMarried }}
                </td>
            </ng-container>

            <!-- others -->
            <ng-container matColumnDef="others" class="text-center">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    [attr.colspan]="3"
                    class="text-center sme-table-header"
                >
                    {{ "SME.OTHERS" | translate }}
                </th>
            </ng-container>
            <ng-container matColumnDef="othersMale">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.MALE" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="text-center sme-table-title"
                >
                    {{ element.others.othersMale }}
                </td>
            </ng-container>
            <ng-container matColumnDef="othersFemaleSingle">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.FEMALES" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="text-center sme-table-title"
                >
                    {{ element.others.othersFemaleSingle }}
                </td>
            </ng-container>
            <ng-container matColumnDef="othersFemaleMarried">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="text-center sme-table-title"
                >
                    {{ "SME.FEMALEM" | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="text-center sme-table-title"
                >
                    {{ element.others.othersFemaleMarried }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedHeadersColumns"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>

    <div class="row sme-calculate-btn-container mb-5">
        <button (click)="fastCal()" class="sme-calculate-btn" id="fast-calc-btn">
            {{ "SME.CALCULATE" | translate }}
        </button>
    </div>
</div>
