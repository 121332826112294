<loading *ngIf="submitLoader">{{ isLoading }}</loading>

<div [ngClass]="{ 'dir-en': this.translate.currentLang === 'en-US', 'dir-ar': this.translate.currentLang === 'ar-SA' }">
    <div mat-dialog-title>
        <div class="d-flex justify-content-between align-items-center">
            <div class="health-dialog-header-container">
                <span class="health-dialog-header">{{
                    "SME.HEALTHDEC" | translate
                    }}</span>
                <div class="sme-health-bordered"></div>
            </div>

            <img src="assets/img/medical-sme/dialog-close.png" width="30px" mat-dialog-close />
        </div>
    </div>

    <div class="sme-questions-container mt-4">
        <div class="row" style="    border-bottom: 1px dotted black;
     padding-top: 1rem;
     padding-bottom: 0.5rem;" *ngFor="let question of questionAnswerBind">
            <div class="col-md-8">
                <label class="sme-question-text">{{ lang == 'en-US' ? question.description :
                    question.a_Description
                    }}</label>
            </div>
            <div class="col-md-4">
                <mat-radio-group aria-label="Select an option" class="sme-radio-container">
                    <mat-radio-button value="true" [checked]="question.yesValue" class="sme-radio-option-label"
                        [ngClass]="{ 'dir-en': this.translate.currentLang === 'ar-SA' }"
                        (change)="addRemoveQuestionAnswer(question, $event)">{{
                        "SME.YES" | translate
                        }}</mat-radio-button>
                    <mat-radio-button value="false" [checked]="question.noValue" class="sme-radio-option-label"
                        [ngClass]="{ 'dir-en': this.translate.currentLang === 'ar-SA' }"
                        (change)="addRemoveQuestionAnswer(question, $event)">{{
                        "SME.NO" | translate
                        }}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <div *ngIf="!submitLoader" class="row sme-health-save-container mb-2 mt-5">
        <button class="sme-save-btn" (click)="saveAnswers()">{{ "SME.SAVE" | translate }}</button>
    </div>
</div>