<app-navbar
    *ngIf="!(location == '/coming-soon') && !(location?.includes('/OnlineSadad')) "
    (changeLang)="changeLang()"
    [dir]="textDir"
    style="
        position: fixed;
        top: 0;
        background-color: white;
        z-index: 1000;
        width: 100%;
    "
></app-navbar>
<div [dir]="textDir" class="white resStyle">
    <router-outlet></router-outlet>
</div>
<app-footer
    [dir]="textDir"
    *ngIf="!(location == '/coming-soon') && !(location?.includes('/OnlineSadad')) && !(location?.includes('/Online-Sadad-Page/')) && !(location?.includes('/online-sadad-forbidden'))"
></app-footer>
<ngx-scrolltop
    *ngIf="!(location == '/coming-soon') && !(location?.includes('/OnlineSadad')) && !(location?.includes('/Online-Sadad-Page/')) && !(location?.includes('/online-sadad-forbidden'))"
></ngx-scrolltop>
