<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container pt-5" style="min-height: 500px">
    <div class="row">
        <div class="col-12" style="text-align: center">
            <img src="assets/img/status/Success.png" style="width: 500px" />

            <div *ngIf="!payIsAccpted && !isLoading">
                <h2 class="color-green" style="padding-top: 20px; color: black" *ngIf="message">
                    {{ "SME.Transaction" | translate }} {{ message }}
                </h2>

                <h2 class="color-green" style="padding-top: 20px; color: black" *ngIf="message">
                    {{ errMessage }}
                </h2>

                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="createQuotation()" id="try-again-btn">
                    {{ "SME.TRY_AGAIN" | translate }}
                </button>
            </div>

            <div *ngIf="payIsAccpted && !isLoading">
                <h2 class="color-green" style="padding-top: 20px; color: black" *ngIf="message">
                    {{ message }}
                </h2>
                <h2 class="color-green" style="
                        padding-top: 10px;
                        color: black;
                        font-size: 20px !important;
                        width: 80%;
                        margin: auto;
                    ">
                    {{ "MMLHome.redirectMSG" | translate }}
                </h2>
                <h2 class="color-green" style="
                        padding-top: 10px;
                        color: black;
                        font-size: 20px !important;
                        width: 80%;
                        margin: auto;
                    " *ngIf="message">
                    {{ "MMLHome.redirectDown" | translate }}
                </h2>

                <div class="btn-container">

                </div>
                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="homePage()">
                    {{ "MMLHome.bkHome" | translate }}
                </button>

                <!-- <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('en')">
                    Download Policy
                </button>

                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('ar')">
                    تحميل الوثيقة
                </button> -->

                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('cchiReportAR')" *ngIf="translate.currentLang != 'en-US'">
                    تقرير مجلس الضمان
                </button>

                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('cchiReportEn')" *ngIf="translate.currentLang == 'en-US'">
                    CCHI Report <!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('draftReportAr')" *ngIf="translate.currentLang != 'en-US'">
                    عرض السعر المبدئي
                    <!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('draftReportEn')" *ngIf="translate.currentLang == 'en-US'">
                    Draft Report<!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('finalReportAR')" *ngIf="translate.currentLang != 'en-US'">
                    عرض السعر النهائي
                    <!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('finalReportEn')" *ngIf="translate.currentLang == 'en-US'">
                    Final Report <!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <!-- <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('hdReportAR')" *ngIf="translate.currentLang != 'en-US'">
                    الإفصاح الطبي
                </button>

                <button class="btn default-btn" style="
                        margin-bottom: 30px;
                        margin-top: 30px;
                        font-size: 18px;
                        margin-left: 15px;
                        margin-right: 15px;
                    " (click)="download('hdReportEn')" *ngIf="translate.currentLang == 'en-US'">
                    HD Report
                </button> -->


                <button class="btn default-btn" style="
                    margin-bottom: 30px;
                    margin-top: 30px;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-right: 15px;
                " (click)="download('invoiceReportAR')" *ngIf="translate.currentLang != 'en-US'">
                    الفاتورة <!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <button class="btn default-btn" style="
                    margin-bottom: 30px;
                    margin-top: 30px;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-right: 15px;
                " (click)="download('invoiceReportEn')" *ngIf="translate.currentLang == 'en-US'">
                    Invoice <!-- {{ "MMLHome.report" | translate }} -->
                </button>


                <button class="btn default-btn" style="
                    margin-bottom: 30px;
                    margin-top: 30px;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-right: 15px;
                " (click)="download('memListReportAR')" *ngIf="translate.currentLang != 'en-US'">
                    قائمة المؤمنين
                    <!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <button class="btn default-btn" style="
                    margin-bottom: 30px;
                    margin-top: 30px;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-right: 15px;
                " (click)="download('memListReportEn')" *ngIf="translate.currentLang == 'en-US'">
                    Members Report <!-- {{ "MMLHome.report" | translate }} -->
                </button>


                <button class="btn default-btn" style="
                    margin-bottom: 30px;
                    margin-top: 30px;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-right: 15px;
                " (click)="download('policyScheduleReportAR')" *ngIf="translate.currentLang != 'en-US'">
                    وثيقة التأمين <!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <button class="btn default-btn" style="
                    margin-bottom: 30px;
                    margin-top: 30px;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-right: 15px;
                " (click)="download('policyScheduleReportEn')" *ngIf="translate.currentLang == 'en-US'">
                    Policy Schedule <!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <button class="btn default-btn" style="
                    margin-bottom: 30px;
                    margin-top: 30px;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-right: 15px;
                " (click)="download('providerReportAR')" *ngIf="translate.currentLang != 'en-US'">
                    قائمة مزودين الخدمة
                    <!-- {{ "MMLHome.report" | translate }} -->
                </button>

                <button class="btn default-btn" style="
                    margin-bottom: 30px;
                    margin-top: 30px;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-right: 15px;
                " (click)="download('providerReportEn')" *ngIf="translate.currentLang == 'en-US'">
                    Providers Report <!-- {{ "MMLHome.report" | translate }} -->
                </button>
            </div>
        </div>
    </div>
</div>