<div class="backgroundwatermark" style="background-color: #f6f8fa">
    <div class="container">
        <div class="row pb-5">
            <div class="col-md-1 p-0 m-0">
                <img
                    [ngClass]="{
                        'car-step-icon-en': lang === 'en-US',
                        'car-step-icon-ar': lang === 'ar-SA'
                    }"
                    id="car-icon"
                    src="assets/img/motor-pages/car1.png"
                    alt=""
                />
                <img
                    src="assets/img/motor-pages/roadpxl1.png"
                    alt=""
                    class="road-img"
                />
                <img
                    src="assets/img/motor-pages/roadpxl2.png"
                    alt=""
                    class="road-img"
                />
                <img
                    src="assets/img/motor-pages/roadpxl3.png"
                    alt=""
                    class="road-img"
                />
                <div class="pricesummary" *ngIf="stepNumber == 2">
                    <h3>{{ "motor.PriceSummary" | translate }}</h3>
                    <p class="pricedetail">
                        {{ "motor.ComprehensivePolicy" | translate }}
                    </p>
                    <p class="pricenumber">
                        2,329 {{ "motor.SAR" | translate }}
                    </p>
                    <hr class="seperator" />
                    <h5>{{ "motor.AdditionalCovers" | translate }}</h5>
                    <p class="pricedetail">Windscreen+Fire+Theft</p>
                    <p class="pricenumber">
                        2,329 {{ "motor.SAR" | translate }}
                    </p>
                    <p class="pricedetail">Personal Accident Cover</p>
                    <p class="pricenumber">
                        2,329 {{ "motor.SAR" | translate }}
                    </p>
                    <hr class="seperator" />
                    <h5>{{ "motor.Tax&Discounts" | translate }}</h5>
                    <p class="pricedetail">{{ "motor.Tax" | translate }}</p>
                    <p class="pricenumber">
                        2,329 {{ "motor.SAR" | translate }}
                    </p>
                    <p class="pricedetail">
                        {{ "motor.Discount" | translate }} 5%
                    </p>
                    <p class="pricenumber">
                        2,329 {{ "motor.SAR" | translate }}
                    </p>
                    <hr class="seperator" />
                    <h4>
                        <b>{{ "motor.Total" | translate }}</b> 5,555
                        {{ "motor.SAR" | translate }}
                    </h4>
                </div>
            </div>
            <div class="col-md-2">
                <div class="d-flex flex-row mt-5" style="height: 20vh">
                    <span
                        [ngClass]="{
                            'first-step-icon-en': lang === 'en-US',
                            'first-step-icon-ar': lang === 'ar-SA'
                        }"
                    ></span>
                    <p class="first-step-label">
                        {{ "motor.VehicleDetails" | translate }}
                    </p>
                </div>
                <div class="d-flex flex-row" style="height: 20vh">
                    <span
                        [ngClass]="{
                            'second-step-icon-en': lang === 'en-US',
                            'second-step-icon-ar': lang === 'ar-SA'
                        }"
                    ></span>
                    <p class="second-step-label">
                        {{ "motor.PolicyCoverage" | translate }}
                    </p>
                </div>
                <div class="d-flex flex-row" style="height: 20vh">
                    <span
                        [ngClass]="{
                            'third-step-icon-en': lang === 'en-US',
                            'third-step-icon-ar': lang === 'ar-SA'
                        }"
                    ></span>
                    <p class="third-step-label">
                        {{ "motor.Payment" | translate }}
                    </p>
                </div>
            </div>
            <div class="col-md-9 py-3">
                <div class="user-info-card">
                    <div class="d-flex flex-row">
                        <img
                            src="assets/img/motor-pages/man 1.png"
                            alt=""
                            class="card-info-img"
                        />
                        <p class="card-info-text">
                            {{ "motor.Welcome" | translate }} Ahmed
                        </p>
                    </div>
                    <div class="d-flex flex-row">
                        <img
                            src="assets/img/motor-pages/user (2) 1.png"
                            alt=""
                            class="card-info-img"
                        />
                        <p class="card-info-text">
                            {{ "motor.Name" | translate }} : Ahmed islam Fathy
                        </p>
                    </div>
                    <div class="d-flex flex-row">
                        <img
                            src="assets/img/motor-pages/calendar 2.png"
                            alt=""
                            class="card-info-img"
                        />
                        <p class="card-info-text">
                            {{ "motor.Birthdate" | translate }} : 05/07/1990
                        </p>
                    </div>
                    <div class="d-flex flex-row">
                        <img
                            src="assets/img/motor-pages/driver-license 2.png"
                            alt=""
                            class="card-info-img"
                        />
                        <p class="card-info-text">
                            {{ "motor.Iqama" | translate }} : 2500003849
                        </p>
                    </div>
                </div>
                <div class="motor-form-container" *ngIf="stepNumber == 1">
                    <app-vehicle-details-form
                        style="width: 100%"
                        *ngIf="VehicleDetailsNumber == 1"
                    ></app-vehicle-details-form>
                    <app-vehicle-details-info
                        style="width: 100%"
                        *ngIf="VehicleDetailsNumber == 2"
                    ></app-vehicle-details-info>
                </div>
                <div class="motor-form-container" *ngIf="stepNumber == 2">
                    <app-policy-coverage-page
                        style="width: 100%"
                    ></app-policy-coverage-page>
                </div>
                <div class="motor-form-container" *ngIf="stepNumber == 3">
                    third form
                </div>

                <div
                    class="motor-actions-container"
                    *ngIf="VehicleDetailsNumber < 3"
                >
                    <button
                        *ngIf="VehicleDetailsNumber == 2"
                        (click)="backVehicleDetails()"
                        class="motor-back-btn"
                    >
                        {{ "motor.back" | translate }}
                    </button>
                    <button
                        *ngIf="VehicleDetailsNumber != 3"
                        (click)="nextVehicleDetails()"
                        class="motor-next-btn"
                    >
                        {{ "motor.Next" | translate }}
                    </button>
                </div>
                <div
                    class="motor-actions-container"
                    *ngIf="VehicleDetailsNumber > 2"
                >
                    <button
                        *ngIf="stepNumber != 1"
                        (click)="back()"
                        class="motor-back-btn"
                    >
                        {{ "motor.back" | translate }}
                    </button>
                    <button
                        *ngIf="stepNumber != 3"
                        (click)="next()"
                        class="motor-next-btn"
                    >
                        {{ "motor.Next" | translate }}
                    </button>
                    <button
                        *ngIf="stepNumber == 3"
                        (click)="pay()"
                        class="motor-next-btn"
                    >
                        {{ "motor.Pay" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
