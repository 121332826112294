import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatInput } from '@angular/material/input';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MmpService } from 'src/app/core/services/MMP-Service/mmp.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mmp-cash-claims',
  templateUrl: './mmp-cash-claims.component.html',
  styleUrls: ['./mmp-cash-claims.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})

export class MmpCashClaimsComponent implements OnInit, AfterViewInit {

  today: Date;
  minDate: Date;
  languageValue = localStorage.getItem('language');
  selectedMember: any;
  memID = localStorage.getItem('memberID');
  fromDate: any;
  ToDate: any;
  status: any;
  Claims: any;
  ClaimsDetails: any;
  isLoading = true;
  pageInit: boolean = true;
  memberClaims: any;
  ClaimStatues: any = [];
  resertoggle: any;
  claimNumber: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('fromInput', {
    read: MatInput,
  })
  fromInput: MatInput;

  @ViewChild('toInput', {
    read: MatInput,
  })
  toInput: MatInput;

  dataSource = new MatTableDataSource();
  policyData: any;

  constructor(
    public translate: TranslateService,
    public medicalClaimsService: MedicalClaimsService,
    private _LocalService: LocalService,
    public mmpClaimsService: MmpService,
    public _router: Router
  ) {
    this.memID = this._LocalService.getJsonValue('memberID');
    this.dataSource = new MatTableDataSource(this.memberClaims);
    this.languageValue = localStorage.getItem('language');
    this.fromDate = null;
    this.ToDate = null;
    this.status = null;
    this.minDate = null;
    this.pageInit = true;
    this.resertoggle = false;

  }

  columnsToDisplay = [
    'claimNo',
    'status',
    'causeOfLoss',
    'createdDate',
  ];
  ngOnInit(): void {
    this.today = new Date();
    this.GetCashClaimStatues();

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getActivePolicy() {
    var queryString = '?insuredIqamaId=' + this.memID;
    this.mmpClaimsService.GetActivePolicy(queryString).subscribe((res) => {
      this.policyData = res;
      let todayDate = new Date();
      let policyExpiryDate = new Date(this.policyData.policyDateTo.split('T')[0]);

      if (policyExpiryDate < todayDate) {
        this.GoToNewPolicy();
      }
      this.isLoading = false;
    });
  }

  GetCashClaimStatues() {
    let queryString = '?iqama=' + this.memID;
    this.mmpClaimsService.GetMMPClaims(queryString).subscribe((res) => {
      this.memberClaims = res;

      this.memberClaims.forEach(element => {
        if (element.status.toLocaleLowerCase() === 'approved') {
          element.status = this.translate.instant('MMLHome.Approved')
        } else if (element.status.toLocaleLowerCase() === 'rejected') {
          element.status = this.translate.instant('MMLHome.Rejected')
        } else {
          element.status = this.translate.instant('MMLHome.InProgress')
        }
      });

      this.dataSource = new MatTableDataSource<mmpClaims>(this?.memberClaims);
      setTimeout(() => (this.dataSource.paginator = this.paginator));
      this.dataSource.paginator = this.paginator;

      this.ClaimStatues = this.memberClaims
        .map(p => ({
          name: p.status,
        }));

      this.ClaimStatues = this.ClaimStatues.filter((item, i, arr) => arr.findIndex((t) => t.name === item.name) === i);
      this.isLoading = false;
    });
  }

  onChange(event: any) {
    this.resertoggle = true;
  }


  newMedicalCliam() {
    window.location.href = '/new-mmp-cash-claim';
  }

  addFromDate(type: string, event) {
    this.toInput.value = '';
    this.minDate = event.value;
    this.fromDate = this.format(event.value, 1);

    this.resertoggle = true;
  }

  addToDate(type: string, event) {
    this.ToDate = this.format(event.value, 1);
  }

  format(inputDate, mnth) {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + mnth;
    year = inputDate.getFullYear();
    if (month === 13) {
      month = 1;
    }
    date = date.toString().padStart(2, '0');

    month = month.toString().padStart(2, '0');

    return `${month}-${date}-${year}`;
  }

  filterByStatus(event) {
    this.status = event;
    this.resertoggle = true;
  }

  filterClaims() {
    let cashClaims = this.memberClaims;
    if (this.claimNumber != '' && this.claimNumber != undefined && this.claimNumber != null) {
      cashClaims = cashClaims.filter(clm => clm.claimNo.includes(this.claimNumber));
    }
    else {
      if (this.status != null) {
        cashClaims = cashClaims.filter(clm => clm.status === this.status);
      }
      if (this.fromDate != null) {
        let dateFrom = new Date(this.fromDate);
        dateFrom = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), 0, 0, 0);
        cashClaims = cashClaims.filter(clm => new Date(new Date(clm.createdDate.split('T')[0]).setHours(0, 0, 0, 0)) >= dateFrom);
      }
      if (this.ToDate != null) {
        let dateTo = new Date(this.ToDate);
        dateTo = new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 0, 0, 0);
        cashClaims = cashClaims.filter(clm => new Date(new Date(clm.createdDate.split('T')[0]).setHours(0, 0, 0, 0)) <= dateTo);
      }
    }
    this.dataSource = new MatTableDataSource<mmpClaims>(cashClaims);
    setTimeout(() => (this.dataSource.paginator = this.paginator));
    this.dataSource.paginator = this.paginator;
  }

  reset() {
    if (this.fromInput != undefined)
      this.fromInput.value = null;
    if (this.toInput != undefined)
      this.toInput.value = null;

    this.claimNumber = null;
    this.fromDate = null;
    this.ToDate = null;
    this.status = null;
    this.pageInit = true;
    this.Claims = null;
    this.today = new Date();
    this.resertoggle = false;

    this.dataSource = new MatTableDataSource<mmpClaims>(this?.memberClaims);
    setTimeout(() => (this.dataSource.paginator = this.paginator));
    this.dataSource.paginator = this.paginator;
  }

  async GoToNewPolicy() {
    let dir: string = '';
    if (this.translate.currentLang == 'en-US') {
      dir = 'ltr';
    } else {
      dir = 'rtl';
    }

    const { value: TypeId } = await Swal.fire({
      title:
        this.translate.currentLang == 'en-US'
          ? 'The current policy is expired'
          : 'الوثيقة منتهية',
      iconHtml:
        '<img width=40 height=40 src="assets/img/swal-icons-img/icons8-natural-user-interface-2-100.png">',
      input: 'radio',
      showCloseButton: true,
      confirmButtonText:
        this.translate.currentLang == 'en-US' ? 'Create New Policy' : 'إنشاء وثيقة جديدة',
      customClass: {
        icon: 'icon-img',
        title: 'claim-type-swal-title',
        input: 'claim-type-swal-input',
        confirmButton: 'claim-type-confirm-btn',
      },
    });
  }
}

export interface mmpClaims {
  claimNo: string;
  status: string;
  causeOfLoss: string;
  createdDate: string;
}