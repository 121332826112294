<!-- Start Page Title Area -->
<div>
    <div class="page-title-area bg-1">
        <div class="container col-md-10">
            <div class="page-title-content">
                <h2>{{ "NAV.CASHCLAIMS" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/">{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a>
                    </li>
                    <li>
                        <a routerLink="/">
                            {{ "MMLHome.MedicalMalpractice" | translate }}

                            <span style="margin: 0 10px"> / </span></a>
                    </li>
                    <li class="active" style="color: white">
                        {{ "NAV.CASHCLAIMS" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <loading *ngIf="isLoading">{{ isLoading }}</loading>

    <div class="container pt-3 pb-1">
        <div class="row">
            <div style="display: flex">
                <a href="/">
                    <img src="assets/img/medical-service/icons8-back-arrow-90.png" class="back-icon" alt="" [ngClass]="{
                            'arrow-img-ar':
                                this.translate.currentLang != 'en-US'
                        }" />
                </a>
                <h5 class="color-green" [ngClass]="{
                        'title-en': this.translate.currentLang == 'en-US',
                        'title-ar': this.translate.currentLang != 'en-US'
                    }">
                    {{ "MEDICAL.MMPCashClaims" | translate }}
                </h5>
            </div>
            <hr [ngClass]="{
                    'hr-en': this.translate.currentLang == 'en-US',
                    'hr-ar': this.translate.currentLang != 'en-US'
                }" />
        </div>

        <div class="row claims-container" [ngClass]="{
                'inner-container-en': this.translate.currentLang == 'en-US',
                'inner-container-ar': this.translate.currentLang != 'en-US'
            }" style="margin-top: -10px; width: 99%">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="col-lg-3 col-md-3 col-sm-6 col-5 pb-2">
                    <button mat-button class="new-claim-btn" (click)="newMedicalCliam()">
                        <span style="font-size: 20px; font-weight: 600;padding: 2% 2%;">+</span>
                        <span>
                            {{ "MEDICAL.NewMedicalCashClaim" | translate }}
                        </span>
                    </button>
                </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 pb-2">
                <mat-form-field appearance="none">
                    <input matInput type="text" style="padding:1% 3%" (input)="onChange($event.target.value)"
                        (keypress)="($event.charCode >= 48 && $event.charCode < 58)" [(ngModel)]="claimNumber"
                        placeholder="{{'MMLHome.EnterPolicyNumber' | translate}}" [maxLength]="7">
                </mat-form-field>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 pb-2">
                <mat-form-field appearance="none">
                    <mat-select placeholder="{{
                            'MEDICAL.SelectStatus' | translate
                        }}" style="padding-top: 4px" (selectionChange)="filterByStatus($event.value)"
                        [(ngModel)]="status">
                        <mat-option *ngFor="let status of ClaimStatues" [value]="status.name">{{ status.name
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 pb-2">
                <mat-form-field appearance="none" class="form-control input-form-design w-100">
                    <input #fromInput formControlName="date" matInput [matDatepicker]="fromInputPicker" placeholder="{{
                            'MEDICAL.Medical-Approval-Fdate' | translate
                        }}" [max]="today" (dateChange)="addFromDate('change', $event)" style="padding: 1% 3%;" />
                    <mat-datepicker-toggle style="margin-top: -3%; color: #156538" matIconSuffix
                        [for]="fromInputPicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromInputPicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 pb-2">
                <mat-form-field appearance="none" class="form-control input-form-design w-100">
                    <input #toInput formControlName="date" matInput [matDatepicker]="toInputPicker" placeholder="{{
                            'MEDICAL.Medical-Approval-Tdate' | translate
                        }}" [max]="today" [min]="minDate" (dateChange)="addToDate('change', $event)"
                        [disabled]="!minDate" style="padding: 1% 3%;" />
                    <mat-datepicker-toggle style="margin-top: -3%; color: #156538" matIconSuffix
                        [for]="toInputPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toInputPicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-lg-1 col-md-3 col-sm-6 col-12 pb-2" style="padding-right: 0px">
                <label class="providers-area"></label>
                <button class="btn default-btn searchBtn" [disabled]="
                        (!fromDate && !status && !claimNumber)" (click)="filterClaims()">
                    {{ "MEDICAL.Search" | translate }}
                </button>
            </div>
            <div class="col-lg-1 col-md-3 col-sm-6 col-12 pb-2" style="padding-right: 0px">
                <button class="btn default-btn searchBtn" [disabled]="!resertoggle" (click)="reset()">
                    {{ "MEDICAL.Reset" | translate }}
                </button>
            </div>
        </div>

        <div class="row pt-2" [ngClass]="{
                'inner-container-en': this.translate.currentLang == 'en-US',
                'inner-container-ar': this.translate.currentLang != 'en-US'
            }">
            <div class="panel panel-default col-lg-12 col-md-12 col-sm-12">
                <div class="panel-body" *ngIf="memberClaims?.length > 0">
                    <div class="col-md-2"></div>
                    <div class="table-content col-md-11" style="overflow-x: auto">
                        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
                            <ng-container matColumnDef="claimNo">
                                <th mat-header-cell *matHeaderCellDef style="padding:0">
                                    {{ "MEDICAL.Claim-Refrence" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.claimNo }}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.Status" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span [ngClass]="{'approved': element.status == translate.instant('MMLHome.Approved'),
                                    'rejected': element.status == translate.instant('MMLHome.Rejected'),
                                    'in-progress': element.status == translate.instant('MMLHome.InProgress')}">
                                        {{ element.status }}
                                    </span>
                                </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="causeOfLoss">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.CauseOfLoss" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.causeOfLoss }}
                                </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="createdDate">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "MEDICAL.ClaimDate" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.createdDate?.split('T')[0] }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                            <tr mat-row *matRowDef="
                                    let element;
                                    columns: columnsToDisplay
                                " class="example-element-row" [class.example-expanded-row]="element.expanded"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10]" showFirstLastButtons
                            aria-label="Select page of periodic elements">
                        </mat-paginator>
                    </div>
                </div>

                <div *ngIf="
                        (!memberClaims || memberClaims?.length == 0) &&
                        pageInit === false &&
                        isLoading === false
                    " class="d-flex justify-content-center" style="text-align: center !important">
                    <div>
                        <img src="assets/img/medical-service/noClaims.png" alt="" style="width: 250px" />
                        <h5 class="color-green" style="text-align: center">
                            {{ "MEDICAL.cash-Mes" | translate }}
                        </h5>
                    </div>
                </div>
                <div *ngIf="pageInit === true" style="height: 10vh"></div>
            </div>
        </div>
    </div>
</div>