import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';

@Component({
    selector: 'app-sme-create-quotation',
    templateUrl: './sme-create-quotation.component.html',
    styleUrls: ['./sme-create-quotation.component.scss'],
})
export class SmeCreateQuotationComponent implements OnInit {
    @Input() CompanyDetails: any;
    @Input() QuotationNumber: any;
    @Output() onUploadUsersSubmit = new EventEmitter<any>();

    SponsorId: any;
    premiumData: any;
    isLoading: boolean;
    Members: any;
    successMembers: any;
    failedMembers: any;
    constructor(private smeService:MedicalSMEService) {}

    ngOnInit(): void {
        this.SponsorId = this.CompanyDetails.crEntityNumber;
        //console.log("quotation " + this.QuotationNumber);
    }

    goToPremiumDetails(event:any)
    {
        //console.log(event)
        let resultObj = {
            tabIndex:2,
            premData: event.premData,
            quotNumber: event.quotNumber
        }
        this.onUploadUsersSubmit.emit(resultObj);
    }
}
