<section style="background-color: #eee; top: 5">
    <div class="container py-5">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <img src="assets//img/logo/NewLogo2.png" alt="logo" />
                    </div>
                </div>
                <br />
                <div class="row d-flex justify-content-center pb-5">
                    <div class="col-md-7 col-xl-5 mb-4 mb-md-0">
                        <h4>
                            <b style="color: #d4ab36">
                                {{ "AfterPayment.WelcomeClientpart1" | translate }}
                                <span style="margin-left: 0.5rem;margin-right: 0.5rem;color: #156538">|</span>  {{paymentinfo.clientname}}
                            </b>
                        </h4>

                        <b style="color: #156538">{{ "AfterPayment.WelcomeClientPart2" | translate }}</b>

                        <p style="color: #156538">
                            {{ "AfterPayment.Description" | translate }}
                        </p>
                 

                    </div>

                    <div class="col-md-5 col-xl-4 offset-xl-1">
                        <b><h4 style="color: #156538">  {{ "AfterPayment.Documents" | translate }} </h4> </b>
                        <div class="rounded d-flex flex-column p-2 " style="background-color: #ECEFF1">
                           
                            <div class="p-2 d-flex">
                                <div class="col-8" style="color: #d4ab36; font-size: 18px;"><b> {{ "AfterPayment.PolicySchedule" | translate }}</b></div>
                                <div class="ms-auto" style="color: #156538;font-size:24px">
                                    <i class="far fa-file-pdf" style="cursor:pointer" data-mdb-tooltip-init title="Download"  (click)="download()"></i>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="rounded d-flex bg-body-tertiary" style="color: #d4ab36">
                        <b>
                            <div class="toast show fade toast-warning"
                                 role="alert"
                                 aria-live="assertive"
                                 aria-atomic="true"
                                 style="width:100%">
                                <div class="toast-header toast-warning">
                                    <i class="fas fa-check fa-lg me-2"></i>
                                    <strong class="me-auto">{{ "AfterPayment.Invoice" | translate }}</strong>


                                </div>
                                <div class="toast-body"> {{ "AfterPayment.Description2" | translate }}</div>
                            </div>
                        </b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
