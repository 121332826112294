import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SmeFastCalcTableComponent } from '../sme-fast-calc-table/sme-fast-calc-table.component';
import { FormControl } from '@angular/forms';
import { MedicalSMEService } from 'src/app/core/services/SME-Service/medical-sme.service';
import { SmeClassSelectionDialogComponent } from '../sme-class-selection-dialog/sme-class-selection-dialog.component';
import { AgesModel } from 'src/app/core/interfaces/Ages.modal';
import { FastCalculationSummaryDialogComponent } from '../fast-calculation-summary-dialog/fast-calculation-summary-dialog.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
const lookup = require('country-code-lookup');

export interface TableData {
    ageCodeRange: any;
    employee: any;
    spouse: any;
    child: any;
    others: any;
}

export interface EmployeeInterface {
    empMale: any;
    empFemaleSingle: any;
    empFemaleMarried: any;
}

export interface SpouseInterface {
    spouseMale: any;
    spouseFemaleSingle: any;
    spouseFemaleMarried: any;
}

export interface ChildInterface {
    childMale: any;
    childFemaleSingle: any;
    childFemaleMarried: any;
}

export interface OthersInterface {
    othersMale: any;
    othersFemaleSingle: any;
    othersFemaleMarried: any;
}

export class Employee_Data implements EmployeeInterface {
    empMale: string;
    empFemaleSingle: string;
    empFemaleMarried: string;
}
export class Spouse_Data implements SpouseInterface {
    spouseMale: string;
    spouseFemaleSingle: string;
    spouseFemaleMarried: string;
}
export class Child_Data implements ChildInterface {
    childMale: string;
    childFemaleSingle: string;
    childFemaleMarried: string;
}
export class Others_Data implements OthersInterface {
    othersMale: string;
    othersFemaleSingle: string;
    othersFemaleMarried: string;
}
let Table_Data: TableData[] = [
    {
        ageCodeRange: '',
        employee: Employee_Data,
        spouse: Spouse_Data,
        child: Child_Data,
        others: Others_Data,
    },
];

@Component({
    selector: 'app-sme-table',
    templateUrl: './sme-table.component.html',
    styleUrls: ['./sme-table.component.scss'],
})
export class SmeTableComponent implements OnInit {
    @Output() onQuotationSubmit = new EventEmitter<any>();

    @Input() CompanyDetails;
    OutputTypes: AgesModel[] = [];
    fillNatDd: AgesModel[] = [];
    uniqufillNatDd: any[] = [];
    arr = [];

    isSubmitted = false;
    languageValue = localStorage.getItem('language');
    ChosenclassesControl = new FormControl('');
    ChosenNationalityControl = new FormControl('');

    nationalities = new FormControl('');
    selectedNationalities: any = [];
    classes = new FormControl('');
    isLoading = true;
    SMENationalities = [];
    SMEClasses = [];
    SMEAges = [];
    selectedClasses = [];

    displayedHeadersColumns: string[] = [
        'ageCode',
        'employee',
        'spouse',
        'child',
        'others',
    ];
    displayedColumns: string[] = [
        'ageCodeRange',
        'empMale',
        'empFemaleSingle',
        'empFemaleMarried',
        'spouseMale',
        'spouseFemaleSingle',
        'spouseFemaleMarried',
        'childMale',
        'childFemaleSingle',
        'childFemaleMarried',
        'othersMale',
        'othersFemaleSingle',
        'othersFemaleMarried',
    ];
    dataSource = Table_Data;
    Nat: any;
    TotalSMENationalities: any = [];
    NumberOfEmployees: number = 0;
    constructor(
        private dialog: MatDialog,
        private smeService: MedicalSMEService,
        public translate: TranslateService,
        public _LocalService: LocalService
    ) {
        this.languageValue = localStorage.getItem('language');
    }

    onChangeClass(event: any) {
        this.uniqufillNatDd = [];
        this.arr = []
        Table_Data = this.arr;
        this.dataSource = Table_Data;
        console.log(this.dataSource);
        this.ChosenNationalityControl.reset();
        this.fillNatDd = this.OutputTypes.filter((c) => c.className == event);
        var filterList = [...new Set(this.fillNatDd.map((item) => item.NationalityName))];
        for (let index = 0; index < filterList.length; index++) {
            var row = this.fillNatDd.find(c => c.NationalityName == filterList[index]);
            this.uniqufillNatDd.push({
                NationalityName: row.NationalityName,
                NationalityIsoCode: row.NationalityIsoCode,
                NationalityCode: row.NationalityCode
            })

        }



    }

    fastCal() {
        if (this.NumberOfEmployees > 250) {
            let dir: string = '';
                let ErrMsg = '';
                if (this.translate.currentLang == 'en-US') {
                    dir = 'ltr';
                    ErrMsg = 'Number of employees exceeded 250, please visit any GULF Union AL AHLIA Branches for more assistance!';
                } else {
                    dir = 'rtl';
                    ErrMsg = 'عدد الموظفيين تخطي 250 موظف, يرجى مراجعة احد افرع شركة اتحاد الخليج الأهليه لنقوم بخدمتكم';
                }

                this.ErrDialog(ErrMsg)
        }
        else {
            debugger;
            if (this.OutputTypes != null && this.OutputTypes != undefined && this.OutputTypes.length > 0 && this.NumberOfEmployees > 0) {
                this.isSubmitted = true;
                let MobileNo = this._LocalService.getJsonValue('SponsorMobile');

                let fastCalcObject = {
                    ageRangesPerNatClasses: this.OutputTypes,
                    MobileNo: MobileNo,
                    SponsorName: this.CompanyDetails.crName
                }

                this.smeService.fastCalculation(fastCalcObject).subscribe((res) => {
                    var x = res;
                    let dialogRef = this.dialog.open(
                        FastCalculationSummaryDialogComponent,
                        {
                            width: '100%',
                            height: '95%',
                            position: { top: '20px' },
                            data: {
                                classes: this.SMEClasses,
                                result: res,
                                companyId: this.CompanyDetails.crEntityNumber,
                            },
                        }
                    );
                    dialogRef.afterClosed().subscribe((res) => {
                        console.log('ahyyyy', res);
                        if(res != null || res != undefined) {
                            this.onQuotationSubmit.emit(res);
                        }
                    });
                    this.isSubmitted = false;
                });
            }
            else {
                let dir: string = '';
                let ErrMsg = '';
                if (this.translate.currentLang == 'en-US') {
                    dir = 'ltr';
                    ErrMsg = 'You should fill members age details to calculate the price offer!';
                } else {
                    dir = 'rtl';
                    ErrMsg = 'يجب ملئ بيانات اعمار الأفراد لكي يتم احتساب عرض السعر!';
                }

                this.ErrDialog(ErrMsg)
            }
        }
    }

    onChangeNationality(event: any) {
        var table = this.fillNatDd.filter((c) => c.NationalityName == event);
        this.Nat = event;
        this.arr = []
        Table_Data = this.arr;
        this.dataSource = Table_Data;

        for (let i = 0; i < table.length; i++) {
            for (let j = 0; j < table[i].ages.length; j++) {
                if (
                    table[i].ages[j].Male == null &&
                    table[i].ages[j].FemaleM == null &&
                    table[i].ages[j].FemaleS == null
                )
                    continue;
                else {
                    // clearTypesData();

                    // clearTypesData();

                    if (table[i].typeName == 'Employee') {
                        var employe = new Employee_Data();
                        employe.empMale = table[i].ages[j].Male;
                        employe.empFemaleMarried = table[i].ages[j].FemaleM;
                        employe.empFemaleSingle = table[i].ages[j].FemaleS;
                        this.arr.push({
                            ageCodeRange: table[i].ages[j].ageCode,
                            child: '',
                            spouse: '',
                            employee: employe,
                            others: '',
                        });
                    } else if (table[i].typeName == 'Child') {
                        var child = new Child_Data();

                        child.childMale = table[i].ages[j].Male;
                        child.childFemaleMarried = table[i].ages[j].FemaleM;
                        child.childFemaleSingle = table[i].ages[j].FemaleS;
                        this.arr.push({
                            ageCodeRange: table[i].ages[j].ageCode,
                            child: child,
                            spouse: '',
                            employee: '',
                            others: '',
                        });
                    } else if (table[i].typeName == 'Spouse') {
                        var spouseObject = new Spouse_Data();

                        spouseObject.spouseMale = table[i].ages[j].Male;
                        spouseObject.spouseFemaleMarried =
                            table[i].ages[j].FemaleM;
                        spouseObject.spouseFemaleSingle =
                            table[i].ages[j].FemaleS;
                        this.arr.push({
                            ageCodeRange: table[i].ages[j].ageCode,
                            child: '',
                            spouse: spouseObject,
                            employee: '',
                            others: '',
                        });
                    } else if (table[i].typeName == 'Others') {
                        var others = new Others_Data();

                        others.othersMale = table[i].ages[j].Male;
                        others.othersFemaleMarried = table[i].ages[j].FemaleM;
                        others.othersFemaleSingle = table[i].ages[j].FemaleS;
                        this.arr.push({
                            ageCodeRange: table[i].ages[j].ageCode,
                            child: '',
                            spouse: '',
                            employee: '',
                            others: others,
                        });
                    }
                }
            }
            Table_Data = this.arr;
        }
        this.dataSource = Table_Data;
        console.log(Table_Data);
    }

    onKey(event: any) {
        this.SMENationalities = this.search(event.target.value);
    }

    search(value: string) {
        let filter = value.toLowerCase();
        return this.TotalSMENationalities.filter(option => option.name.toLowerCase().startsWith(filter));
    }

    Calc() {
        this.OutputTypes;
    }
    ngOnInit(): void {
        this.GetSMENationalities();
    }
    OpenAddClassDialog() {
        let dialogRef = this.dialog.open(SmeClassSelectionDialogComponent, {
            width: '60%',
            height: '70%',
            position: { top: '50px' },
            data: this.SMEClasses,

        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res != null && res != undefined && res != "")
                this.selectedClasses = res.data;
        });
    }

    GetSMENationalities() {
        this.isLoading = true;
        this.SMENationalities = [];
        this.smeService.GetSMENationalities().subscribe(
            (res) => {
                for (let index = 0; index < res?.length; index++) {
                    if (res[index]?.isoCode === null || res[index]?.isoCode === undefined || res[index]?.isoCode === "") {
                        res[index].isoCode = "";
                    }
                    this.SMENationalities.push(res[index]);
                    this.TotalSMENationalities.push(res[index]);
                }
                this.GetSMEClasses();
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
                this.GetSMEClasses();
            }
        );
    }

    GetSMEClasses() {
        this.isLoading = true;
        this.SMEClasses = [];
        this.smeService.GetSMEClasses().subscribe(
            (res) => {
                for (let index = 0; index < res?.length; index++) {
                    this.SMEClasses.push(res[index]);
                }
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            }
        );
    }

    NationalitySelectionChange(event: any) {
        var row = this.selectedNationalities.filter(x => x.name == event.value[0].name)
        if (row != undefined && row != null && row.length != 0) {
            const index: number = this.selectedNationalities.findIndex(x => x.name === event.value[0].name);
            if (index !== -1) {
                this.selectedNationalities.splice(index, 1);
            }
        } else {
            let nat = {
                code: event.value[0].code,
                name: event.value[0].name,
                isoCode: event.value[0].isoCode
            }
            this.selectedNationalities.push(nat);
        }


        //this.selectedNationalities.forEach(val => this.nationalities.push(Object.assign({}, val)));
    }
    OpenFillDataModal() {
        let dialoug = this.dialog.open(SmeFastCalcTableComponent, {
            width: '70%',
            height: '80%',
            position: { top: '50px' },
            disableClose: true,
            data: {
                classes: this.selectedClasses,
                nationalities: this.nationalities.value,
                CompanyDetails: this.CompanyDetails,
                OutputTypes: this.OutputTypes
            },
        });
        dialoug.afterClosed().subscribe((res) => {
            this.OutputTypes = res.data.OutputTypes;
            this.NumberOfEmployees = res.data.NumberOfEmployees;
        });
    }

    ErrDialog(msg) {
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }
        Swal.fire({
            title: this.translate.currentLang == 'en-US' ? 'Error!' : '!خطأ',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
            html:
                "<div style='color:#FF001F; direction:" +
                dir +
                "'>" +
                msg +
                '</div>',
            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'OK' : 'تم',
            customClass: {
                icon: 'submit-claim-failed-icon-img',
                title: 'submit-claim-failed-swal-title',
                confirmButton: 'submit-claim-failed-confirm-btn',
            },
        });
    }
}
