import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'app-mml-home',
    templateUrl: './mml-home.component.html',
    styleUrls: ['./mml-home.component.scss'],
})
export class MMLHomeComponent implements OnInit {
    languageValue = localStorage.getItem('language');

    productsObj = [
        {
            title: 'MMLHome.PurchasePolicy',
            des: 'Products.Motor_Descreption',
            firstiIcon: '../../../../../assets/img/MML/PurchaseActive.png',
            secondIcon: '../../../../../assets/img/MML/PurchaseActive.png',
            index: 0,
        },
        // {
        //     title: 'MMLHome.PolicyInquiry',
        //     des: 'Products.Medical_Descreption',
        //     firstiIcon: '../../../../../assets/img/MML/PolicyInquiryINACTIVE.png',
        //     secondIcon: '../../../../../assets/img/MML/PolicyInquiryINACTIVE.png',
        //     index: 1,
        //     href: '#Products.Medical',
        // },
    ];
    activeTab = 0;
    constructor(public translate: TranslateService) {
        this.languageValue = localStorage.getItem('language');
    }

    ngOnInit(): void {}
    // onTabChanged(e) {
    //     this.activeTab = e;
    // }
}
