<!-- Start Page Title Area -->
<div>
    <div class="page-title-area bg-1">
        <div class="container md-10">
            <div class="page-title-content">
                <h2>{{ "NAV.MEDICAL" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/">{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a>
                    </li>
                    <li class="active" style="color: white">
                        {{ "MMLHome.MedicalMalpracticeServices" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container pb-3">
    <div class="row pt-4 pb-4">
        <div class="col-lg-5 col-md-12 col-sm-12 profile">
            <div class="col-lg-12 col-md-12 col-sm-12 services-title pb-5"></div>
            <div class="col-lg-12 col-md-12 col-sm-12 profile-card">
                <div class="col-lg-12 col-md-12 col-sm-12 pt-4" [ngClass]="{
                        'card-header-en': this.translate.currentLang == 'en-US',
                        'card-header-ar': this.translate.currentLang != 'en-US'
                    }">
                    <div class="avatar-container col-6">
                        <div class="profile-avatar"></div>
                        <!-- <span
                            [ngClass]="{
                                'policy-class':
                                    this.translate.currentLang == 'en-US',
                                'policy-class-ar':
                                    this.translate.currentLang != 'en-US'
                            }"
                        >
                            {{ policyData.className }}
                        </span> -->
                    </div>
                    <div class="col-6">
                        <!-- <img src="assets/img/medical-service/profile-full-logo.png" width="150" class="profile-logo-img"
                                    alt="" /> -->
                        <span class="font-14 pb-1">
                            {{ policyData.insuredName }}
                        </span>
                    </div>
                </div>

                <div class="row pb-3" [ngClass]="{
                        'card-details-container-en':
                            this.translate.currentLang == 'en-US',
                        'card-details-container-ar':
                            this.translate.currentLang != 'en-US'
                    }">
                    <!-- <span class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-1">
                        {{ selectedMember.memberEName }}
                    </span> -->

                    <span class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-1">
                        <div class="pb-1">
                            {{ "MEDICAL.NationalId" | translate }}:
                            {{ policyData.insuredIqamaId }}
                        </div>

                    </span>
                    <span class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-2" style="padding: 0">
                        <div>
                            {{ "MEDICAL.PolicyNumber" | translate }}:
                            {{ policyData.policyNumber }}
                        </div>
                    </span>

                    <span class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-1">
                        {{ "MEDICAL.StartDate" | translate }}:
                        {{
                        policyData.policyDateFrom
                        | date : "dd / M / yyyy"
                        }}
                    </span>

                    <span class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-1" style="padding: 0">
                        {{ "MEDICAL.ExpiryDate" | translate }}:
                        {{
                        policyData.policyDateTo
                        | date : "dd / M / yyyy"
                        }}
                    </span>
                </div>
            </div>
        </div>

        <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="col-lg-12 col-md-12 col-sm-12 services-title pb-3">
                {{ "MMLHome.MedicalMalpracticeServices" | translate }}
                <div
                    [ngClass]="{
                        'hr-line': this.translate.currentLang == 'en-US',
                        'hr-line-ar': this.translate.currentLang != 'en-US'
                    }"
                ></div>
            </div>

            <div class="row col-lg-12 col-md-12 col-sm-12 pt-4">
                <a
                    class="col-lg-4 col-md-4 col-sm-4 service-item pb-2"
                    (click)="ChooseClaimAction()"
                >
                    <img
                        class="frame-img"
                        src="assets/img/medical-service/frame1.png"
                        alt=""
                        width="80"
                    />

                    <img
                        class="inner-img"
                        src="assets/img/medical-service/cashback.png"
                        alt=""
                        width="40"
                    />
                    <span
                        [ngClass]="{
                            'medical-services-list':
                                this.translate.currentLang == 'en-US',
                            'medical-services-list-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                        >{{ "MEDICAL.CashClaims" | translate }}</span
                    >
                </a>
            </div>
        </div>
    </div>
</div>