<loading *ngIf="isLoading">{{ isLoading }}</loading>


<div *ngIf="!isUnderWriting" class="row mt-5">
    <div class="sme-quotation-left-line"></div>
    <div class="sme-quotation-details-container">
        <h4 class="sme-quotation-details">
            {{ "SME.UPLOADMEMBERS" | translate }}
        </h4>
    </div>
    <div class="sme-quotation-right-line"></div>
</div>
<div *ngIf="!isUnderWriting" class="row mt-3">
    <div class="col-lg-3 col-md-3">
        <button class="sme-upload-manual-btn" (click)="OpenAddMemberDialog()">
            {{ "SME.UPLOADMANUAL" | translate }}
        </button>
    </div>
    <div *ngIf="!isUnderWriting" class="col-lg-3 col-md-3">
        <button class="sme-upload-excel-btn" (click)="OpenUploadMembersDialog()">
            {{ "SME.UPLOADEXCEL" | translate }}
        </button>
    </div>
</div>
<!-- <div class="row mt-5 justify-content-end" *ngIf="successMembers.length>0">
    <div class="col-md-7"></div>
    <div class="col-md-2 text-end">
        <button class="sme-fill-hd-btn" (click)="openSelectedRows()">
            {{ "SME.FILLHD" | translate }}
        </button>
    </div>
    <div class="col-md-3">
        <button class="sme-add-edit-btn">
            {{ "SME.ADDEDITHEALTH" | translate }}
        </button>
    </div>
</div> -->

<div class="row mt-2">
    <div class="sme-quotation-left-line"></div>
    <div class="sme-quotation-details-container">
        <h4 class="sme-quotation-details" style="font-weight: 700;">
            {{ "SME.ACCMEMBERS" | translate }}
        </h4>
    </div>
    <div class="sme-quotation-right-line"></div>
</div>
<div>
    <label class="sme-question-text">{{ "SME.FoundHD" | translate}}</label>

    <mat-radio-group style="margin-right: 4%;margin-left: 4%;" aria-label="Select an option">
        <mat-radio-button value="true" [disabled]="yesValue || noValue" style="padding-left: 2%; padding-right: 2%;"
            class="sme-radio-option-label" [checked]="yesValue"
            [ngClass]="{ 'dir-en': this.translate.currentLang === 'ar-SA' }"
            (change)="addRemoveQuestionAnswer($event.value)">{{
            "SME.YES" | translate
            }}</mat-radio-button>
        <mat-radio-button value="false" [disabled]="yesValue || noValue" class="sme-radio-option-label"
            [checked]="noValue" [ngClass]="{ 'dir-en': this.translate.currentLang === 'ar-SA' }"
            (change)="addRemoveQuestionAnswer($event.value)">{{
            "SME.NO" | translate
            }}</mat-radio-button>
    </mat-radio-group>
</div>
<div *ngIf="translate.currentLang === 'en-US'">
    <input mat-input type="number" class="sme-dialog-text" (input)="onSearchChange($event.target.value)" [maxLength]="10"
        placeholder="Search By Iqama Id" />
</div>

<div *ngIf="translate.currentLang === 'ar-SA'">
    <input mat-input type="number" class="sme-dialog-text" (input)="onSearchChange($event.target.value)" [maxLength]="10"
        placeholder="ابحث برقم الأقامة" />
</div>

<!-- <div class="row mt-3" *ngIf="successMembers.length <= 0">
    <div class="sme-quotation-details-container">
        <h4 class="no-data-found">
            {{ "SME.NODATEFOUND" | translate }}
        </h4>
    </div>
</div> -->

<div class="row mt-3">
    <table mat-table [dataSource]="dataSource" class="sme-quotation-table table-striped table">
        <!-- ID Column -->
        <ng-container matColumnDef="IqamaId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center sme-table-title">
                {{ "SME.IQAMAID" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="text-center" style="padding-top: 1%">
                {{ row.iqamaId }}
            </td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center sme-table-title">
                {{ "SME.PHONE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="text-center" style="padding-top: 1%">
                {{ row.mobileNumber }}
            </td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="sponsorId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center sme-table-title">
                {{ "SME.SPONSORID" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="text-center" style="padding-top: 1%">
                {{ row.sponsorId }}
            </td>
        </ng-container>

        <ng-container matColumnDef="health">
            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                {{ "SME.HEALTHDEC" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center sme-table-title">
                <div *ngIf="element.isFillHd; else falseContent">
                    <img class="sme-table-icon" src="assets/img/medical-sme/true.png" alt=""
                        matTooltip="{{ 'SME.HD_RESULT_FILLED' | translate }}" />
                </div>
                <ng-template #falseContent><img class="sme-table-icon" src="assets/img/medical-sme/false.png" alt=""
                        matTooltip="{{ 'SME.HD_RESULT_NOT_FILLED' | translate }}" /></ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-center sme-table-title">
                {{ "SME.ACTIONS" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center sme-table-title">
                <div class="sme-table-icons-container">
                    <button class="rounded-btn-en" *ngIf="this.translate.currentLang == 'en-US'"
                        [disabled]="isUnderWriting" [ngClass]="{'disabled-btn': isUnderWriting}"
                        (click)="openSelectedRows(element)" matTooltip="{{ 'SME.HD' | translate }}">
                        {{ "SME.FILL_HD" | translate }}</button>

                    <button class="rounded-btn" *ngIf="this.translate.currentLang == 'ar-SA'"
                        [disabled]="isUnderWriting" [ngClass]="{'disabled-btn': isUnderWriting}"
                        (click)="openSelectedRows(element)" matTooltip="{{ 'SME.HD' | translate }}">
                        {{ "SME.FILL_HD" | translate }}</button>
                    <!-- <img class="sme-table-icon" src="assets/img/medical-sme/eye.png" alt="" />
                    <img class="sme-table-icon" src="assets/img/medical-sme/edit.png" alt="" />
                    <img class="sme-table-icon" src="assets/img/medical-sme/print.png" alt="" />
                    <img class="sme-table-icon" src="assets/img/medical-sme/delete.png" alt="" /> -->
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">{{ "SME.NODATEFOUND" | translate }}</td>
        </tr>
    </table>

</div>

<div class="row mt-3" *ngIf="failedMembers.length>0">
    <div class="sme-quotation-left-line"></div>
    <div class="sme-quotation-details-container" style="font-weight: 700;">
        <h4 class="sme-quotation-details">
            {{ "SME.REJMEMBERSDETAILS" | translate }}
        </h4>
    </div>
    <div class="sme-quotation-right-line"></div>
</div>

<div class="d-flex flex-row justify-content-end" *ngIf="failedMembers.length > 0">
    <button id="excel-table" style="background: none; width: 2%;" mat-raised-button
        matTooltip="{{'SME.EXPORTFAILEDMEMBERS' | translate}}" (click)="exportexcel()">
        <img src="assets/img/medical-sme/excel-icon.png">
    </button>
</div>
<div class="row mt-3" *ngIf="failedMembers.length > 0">
    <table mat-table [dataSource]="failedMembersSource" matSort style="width: 100%"
        class="sme-quotation-table table-striped table">
        <!-- ID Column -->
        <ng-container matColumnDef="IqamaId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center sme-table-title">
                {{ "SME.IQAMAID" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="text-center" style="padding-top: 1%">
                {{ row.iqamaId }}
            </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="Errors">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center sme-table-title">
                {{ "SME.REASON" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="text-center" style="padding-top: 1%; width: 75%;">
                {{ row.errorMessage }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
    </table>
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->

</div>

<div class="d-flex flex-row col-lg-12 col-md-12 col-sm-12 pt-3">
    <mat-checkbox class="example-margin" [checked]="allComplete" (change)="setAll($event.checked)"
        *ngIf="translate.currentLang === 'en-US'">
        I understand and Accept all declaration and terms and conditions mentioned above
    </mat-checkbox>

    <mat-checkbox class="example-margin" [checked]="allComplete" (change)="setAll($event.checked)"
        *ngIf="translate.currentLang === 'ar-SA'">
        أنا أفهم وأقبل جميع البيانات والشروط والأحكام المذكورة أعلاه
    </mat-checkbox>
</div>

<div class="row sme-back-btn-container mb-5 mt-3">
    <button *ngIf="!isLoading" class="sme-back-btn-o"
        [ngClass]="{ 'mr-5': this.translate.currentLang === 'en-US', 'ml-5': this.translate.currentLang === 'ar-SA' }"
        mat-button matStepperPrevious (click)="prevStep()">
        {{ "SME.BACK" | translate }}
    </button>

    <button class="sme-back-btn" mat-button (click)="getPremiumData()" id="get-prem-btn">
        <span>{{ "SME.CALCULATE" | translate }}</span>
        <!-- <span *ngIf="isLoading">
            <div
                class="spinner-border text-warning"
            ></div>
        </span> -->
    </button>

    <button class="sme-back-btn" style="margin-right: 5% ;"
        (click)="download('hdReportAR')" *ngIf="translate.currentLang != 'en-US' && successMembers.length > 0">
        الإفصاح الطبي
    </button>

    <button class="sme-back-btn" style="margin-left: 5% ;" *ngIf="translate.currentLang == 'en-US' && successMembers.length > 0"
        (click)="download('hdReportEn')">
        HD Report
    </button>
</div>