import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IsAuthorizedService } from '../services/is-authorized.service';

@Injectable({
  providedIn: 'root'
})
export class OtpaAuthGuardService   {
  constructor(
      private IsAuthorizedService: IsAuthorizedService,
      public _router: Router
  ) {}

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {
      if (this.IsAuthorizedService.isEligableToAccessOTP()===true) 
      {
          return true;
      } else {
        this._router.navigate(['/user-login']);
            return false;
      }
  }
}
