import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../AppConfigService';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class MedicalSMEService {
    baseUrl: string;
    lang: string;

    SMENationalitiesURL: string;
    SMEClassesURL: string;
    SMEAgesURL: string;
    GetWathqCompanyDetailsURL: string;
    ValidateWathqLoginURL: string;
    GetCompanyNationalAddressURL: string;
    GetCompanyCCHIURL: string;
    UploadMembersURL: string;
    ValidateMembersExcelURL: string;
    sponserHasQtnUrl: string;
    accptedMembersCount: string;
    GetDraftReportURL: string;
    GetHealthReportURL: string;
    fastcal: any;
    GetCitizenInfoURL: string;
    GetQuotationIdURL: string;
    GetQuestionHDURL: string;
    GetUnderWritingStatusURL: string;

    CreateQuotationFastCalcURL: string;
    FillHdQuestionURL: string;
    getQtnAndPolicyUrl: string;

    CalculateQuotationURL: string;
    CheckQuotationMembersExistanceURL: string;
    AddPaymentTrxURL: string;
    GetPaymentTrxURL: string;
    GetPaymentDataURL: string;
    GetPaymentStatusURL: string;
    AddMemberURL: string;
    getAccptedmembersCountUrl: string;
    GetPolicyReportsURL: string;
    UpdateSponsorMobileURL:string;
    getQuotationReqByIdURL: string;

    constructor(
        private http: HttpClient,
        private environment: AppConfigService,
        public translate: TranslateService
    ) {
        this.lang = this.translate.currentLang == 'en-US' ? 'en' : 'ar';
        this.baseUrl = environment?.config?.servicesBaseUrl + 'MMPDocCode/';

        this.SMENationalitiesURL =
            environment?.config?.servicesBaseUrl +
            'MedicalSMELookup/' +
            'GetSMENationalities';

        this.SMEClassesURL =
            environment?.config?.servicesBaseUrl +
            'MedicalSMELookup/' +
            'GetSMEClasses';

        this.GetDraftReportURL = environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'GetDraftReport';

        this.GetHealthReportURL = environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'GetHealthDeclarionReport';

            this.GetUnderWritingStatusURL=environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'getUnderWritingStatus';

            

        this.SMEAgesURL =
            environment?.config?.servicesBaseUrl +
            'MedicalSMELookup/' +
            'GetSMEAges';

        this.fastcal =
            environment?.config?.servicesBaseUrl +
            'FastCalculatedQuotation/' +
            'GetSMEFastCalc';

        this.GetWathqCompanyDetailsURL =
            environment?.config?.servicesBaseUrl +
            'Validate/' +
            'GetWathqCompanyDetails';

        this.ValidateWathqLoginURL = 
        environment?.config?.servicesBaseUrl +
            'Validate/' +
            'ValidateLogin';

        this.GetCompanyNationalAddressURL =
            environment?.config?.servicesBaseUrl +
            'Validate/' +
            'GetCompanyAddressInfo';

        this.GetCompanyCCHIURL =
            environment?.config?.servicesBaseUrl +
            'Validate/' +
            'GetSponserDetails';

        this.UploadMembersURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'UploadEmployeesInfo';

       

        this.GetCitizenInfoURL =
            environment?.config?.servicesBaseUrl +
            'Validate/' +
            'GetCitizenInfo?';

        this.ValidateMembersExcelURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'ValidateEmployeesExcel?';

            this.getAccptedmembersCountUrl =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'acceptedMembersCount?';

        this.sponserHasQtnUrl =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'SponserHasQtn?';

            this.FillHdQuestionURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'FillHdQuestions?';

        this.GetQuotationIdURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'CreateQuotationWithoutFastCalc';

        this.CreateQuotationFastCalcURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'CreateQuotationWithFastCalc';

        this.getQtnAndPolicyUrl =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'SponserHasQtn';

            this.getQuotationReqByIdURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'GetQuotationByQutationId';

        this.GetQuestionHDURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'GetHdQuestions';

        this.CalculateQuotationURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'CalculateSMEQuota';

        this.CheckQuotationMembersExistanceURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'CheckQuotationExistance';

        this.AddPaymentTrxURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'AddPaymentTransaction';

        this.GetPaymentTrxURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/' +
            'GetQuotationTransactionId';

        this.GetPaymentDataURL =
            environment?.config?.servicesBaseUrl +
            'Payment/PostPayment';

        this.GetPaymentStatusURL =
            environment?.config?.servicesBaseUrl +
            'Payment/getPaymentStatus';

            this.GetPolicyReportsURL =
            environment?.config?.servicesBaseUrl +
            'Payment/GetReports';

        this.AddMemberURL =
            environment?.config?.servicesBaseUrl +
            'Quotation/AddMember';

        this.UpdateSponsorMobileURL = 
        environment?.config?.servicesBaseUrl +
            'Validate/UpdateSponsorMobile';
    }

    GetSMENationalities(): Observable<any> {
        return this.http.get<any>(this.SMENationalitiesURL);
    }

    GetSMEClasses(): Observable<any> {
        return this.http.get<any>(this.SMEClassesURL);
    }

    GetSMEAges(): Observable<any> {
        return this.http.get<any>(this.SMEAgesURL);
    }

    GetWathqCompanyDetails(data: any): Observable<any> {
        return this.http.post<any>(this.GetWathqCompanyDetailsURL, data);
    }

    ValidateWathqLogin(data: any): Observable<any> {
        return this.http.post<any>(this.ValidateWathqLoginURL, data);
    }

    UpdateSponsorMobile(data: any): Observable<any> {
        return this.http.post<any>(this.UpdateSponsorMobileURL, data);
    }

    GetCompanyAddressInfo(data: any): Observable<any> {
        return this.http.get<any>(
            this.GetCompanyNationalAddressURL +
            '?CRN=' +
            data +
            '&lang=' +
            this.lang
        );
    }

    GetSponsorDetails(data: any): Observable<any> {
        return this.http.get<any>(this.GetCompanyCCHIURL + '?CRN=' + data);
    }

    GetDraftReport(data: any): Observable<any> {
        return this.http.get<any>(this.GetDraftReportURL + '?QuotationNo=' + data);
    }

    GetHDReport(data: any): Observable<any> {
        return this.http.get<any>(this.GetHealthReportURL + '?QuotationNo=' + data);
    }

    GetQuotationReqById(data: any): Observable<any> {
        return this.http.get<any>(this.getQuotationReqByIdURL + '?QuotationNumber=' + data);
    }

   

    

    UploadMembers(data: any, options: any): Observable<any> {
        return this.http.post<any>(this.UploadMembersURL, data, options);
    }

    

    ValidateMembersExcel(data: any, options: any): Observable<any> {
        return this.http.post<any>(this.ValidateMembersExcelURL, data, options);
    }

    fastCalculation(data: any): Observable<any> {
        return this.http.post<any>(this.fastcal, data);
    }

    quotationWithFastCalc(data: any): Observable<any> {
        return this.http.post<any>(this.CreateQuotationFastCalcURL, data);
    }

    fillhdQuestion(data: any): Observable<any> {
        return this.http.post<any>(this.FillHdQuestionURL, data);
    }

    GetUnderWritingStatus(data: any): Observable<any> {
        return this.http.post<any>(this.GetUnderWritingStatusURL, data);
    }

    getQtnAndPolicyNo(data: any): Observable<any> {
        return this.http.post<any>(this.getQtnAndPolicyUrl, data);
    }

    GetCitizenInfo(data: any): Observable<any> {
        return this.http.get<any>(
            this.GetCitizenInfoURL + data + '&lang=' + this.lang
        );
    }

    GetQuotationId(data: any): Observable<any> {
        return this.http.post<any>(this.GetQuotationIdURL, data);
    }

    GetHDQuestions(): Observable<any> {
        return this.http.get<any>(this.GetQuestionHDURL);
    }

    GetPremiumData(data: any): Observable<any> {
        return this.http.post<any>(this.CalculateQuotationURL, data);
    }

    AddMember(data: any): Observable<any> {
        return this.http.post<any>(this.AddMemberURL, data);
    }

    CheckQuotationMembersExistance(data: any): Observable<any> {
        return this.http.get<any>(this.CheckQuotationMembersExistanceURL + '?quotationNumber=' + data);
    }

    AddPaymentTrx(data: any): Observable<any> {
        return this.http.post<any>(this.AddPaymentTrxURL, data);
    }

    GetQuotationTrxId(data: any): Observable<any> {
        return this.http.get<any>(this.GetPaymentTrxURL + '?quotationNumber=' + data);
    }

    GetPaymentData(data: any): Observable<any> {
        return this.http.post<any>(this.GetPaymentDataURL, data);
    }

    GetPaymentStatus(data: any): Observable<any> {
        return this.http.post<any>(this.GetPaymentStatusURL, data);
    }

    GetPolicyReports(data: any): Observable<any> {
        return this.http.post<any>(this.GetPolicyReportsURL, data);
    }

    sponserHasQtn(data: any): Observable<any> {
        return this.http.post<any>(this.sponserHasQtnUrl, data);
    }

    getAccptedmembersCount(data: any): Observable<any> {
        return this.http.post<any>(this.sponserHasQtnUrl, data);
    }

    fillhd(data:any): Observable<any> {
        return this.http.post<any>(this.FillHdQuestionURL, data);
    }
}
