import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { numbers } from '@material/slider';
import { TranslateService } from '@ngx-translate/core';
import { debug, table } from 'console';
import { AgesModel } from 'src/app/core/interfaces/Ages.modal';
import { MedicalSmeService } from 'src/app/core/services/Medical-Sme-Service/medical-sme-service';

@Component({
    selector: 'app-sme-fast-calc-table',
    templateUrl: './sme-fast-calc-table.component.html',
    styleUrls: ['./sme-fast-calc-table.component.scss'],
})

export class SmeFastCalcTableComponent implements OnInit {
    CompanyDetails: any;
    IsChild: boolean = false;
    IsOthers: boolean = false;
    IsSpouse: boolean = false;
    IsEmployee: boolean = false;
    form: FormGroup;
    Types: AgesModel[] = [];
    OutputTypes: AgesModel[] = [];
    Classes: [] = [];
    Nationalites: [] = [];
    nationalitiesControl = new FormControl('');
    classesControl = new FormControl('');
    //dataSource: MatTableDataSource<any>;
    typesControl = new FormControl('');
    isClassSelected = false;
    isNationalitySelected = false;
    ShowMembersTable: boolean;
    isSaved: boolean;
    TotalNumberOfEmployees: number = 0;

    constructor(
        private dialogRef: MatDialogRef<SmeFastCalcTableComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private fb: FormBuilder,
        public medicalSmeService: MedicalSmeService,
        public translate: TranslateService,
    ) {
        this.Classes = this.data.classes;
        this.Nationalites = this.data.nationalities;
        this.CompanyDetails = this.data.CompanyDetails;
        this.OutputTypes = this.data.OutputTypes;

        this.form = this.fb.group({
            dataRows: this.fb.array([]), // FormArray to hold data
        });
        // this.addDataRow();
        // Add some initial data
    }

    ngOnInit(): void {
        this.medicalSmeService.GetSmeMedicalAges().subscribe((res) => {
            this.Types = res;
        });
    }

    get dataRows() {
        return (this.form.get('dataRows') as FormArray).controls;
    }

    addDataRow(agesModelObject: AgesModel, typeCode: string, natCode: string, className: string) {
        this.clearFormArray(this.form.get('dataRows') as FormArray);
        var typeRangeObj = this.OutputTypes.find(c => c.type == typeCode && c.NationalityCode == natCode && c.className == className);
        if (typeRangeObj != undefined) {
            for (let index = 0; index < typeRangeObj.ages.length; index++) {
                const newRow = this.fb.group({
                    ageFrom: typeRangeObj.ages[index].ageFrom,
                    ageTo: typeRangeObj.ages[index].ageTo,
                    ageCode: typeRangeObj.ages[index].ageCode,
                    Male: new FormControl(typeRangeObj.ages[index].Male),
                    FemaleS: new FormControl(typeRangeObj.ages[index].FemaleS),
                    FemaleM: new FormControl(typeRangeObj.ages[index].FemaleM),
                });
                (this.form.get('dataRows') as FormArray).push(newRow);

            }
        }
        else {

            for (let index = 0; index < agesModelObject.ages.length; index++) {
                const newRow = this.fb.group({
                    ageFrom: agesModelObject.ages[index].ageFrom,
                    ageTo: agesModelObject.ages[index].ageTo,
                    ageCode: agesModelObject.ages[index].ageCode,
                    Male: new FormControl(),
                    FemaleS: new FormControl(),
                    FemaleM: new FormControl(),
                });

                //this.dataRows.push(newRow);
                (this.form.get('dataRows') as FormArray).push(newRow);
            }
        }


        //this.dataSource = new MatTableDataSource((this.form.get('dataRows') as FormArray).controls);
    }

    clearFormArray = (formArray: FormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    };

    displayedHeadersColumnsEmployee: string[] = [
        'ageCode',
        'employee',
        // 'spouse',
        // 'child',
        // 'others',
    ];
    displayedColumnsEmployee: string[] = [
        'ageCodeRange',
        'empMale',
        'empFemaleSingle',
        'empFemaleMarried',
    ];

    displayedHeadersColumnsSpouse: string[] = ['ageCode', 'spouse'];
    displayedColumnsSpouse: string[] = [
        'ageCodeRange',
        'spouseMale',
        // 'spouseFemaleSingle',
        'spouseFemaleMarried',
    ];

    displayedHeadersColumnsChild: string[] = ['ageCode', 'child'];

    displayedColumnsChild: string[] = [
        'ageCodeRange',
        'childMale',
        'childFemaleSingle',
        // 'childFemaleMarried',
    ];

    displayedHeadersColumnsOthers: string[] = ['ageCode', 'others'];
    displayedColumnsOthers: string[] = [
        'ageCodeRange',
        'othersMale',
        'othersFemaleSingle',
        'othersFemaleMarried',
    ];
    addFormChild: FormGroup;

    saveAndClose() {
        //if (!this.isSaved) {
        this.onSubmit();
        //}
        this.dialogRef.close({ data: {OutputTypes: this.OutputTypes, NumberOfEmployees: this.TotalNumberOfEmployees} });
    }

    closeDialog() {
        this.dialogRef.close({ data: {OutputTypes: this.OutputTypes, NumberOfEmployees: this.TotalNumberOfEmployees} });
    }
    
    onSubmit() {
        this.typesControl.reset()
        let nat_code: string = this.nationalitiesControl.value['code'];
        let nat_name: string = this.nationalitiesControl.value['name'];
        let nat_isoCode: string = this.nationalitiesControl.value['isoCode'];
        let class_name: string = this.classesControl.value;


        let typeCode: string;
        if (this.IsEmployee)
            typeCode = "1";
        else if (this.IsChild)
            typeCode = "3";

        else if (this.IsSpouse)
            typeCode = "2";

        else if (this.IsOthers)
            typeCode = "5";
        if (this.OutputTypes.length > 0) {
            var row = this.OutputTypes.find(c => c.type == typeCode && c.NationalityName == nat_name && c.className == class_name);
            if (row != undefined) {
                for (let i = 0; i < this.OutputTypes.length; i++) {
                    if (this.OutputTypes[i].type == typeCode && this.OutputTypes[i].NationalityName == nat_name && this.OutputTypes[i].className == class_name) {
                        this.OutputTypes.splice(i--, 1);
                    }
                }
            }
        }

        if (this.IsEmployee) {
            this.OutputTypes.push({
                type: '1',
                typeName: 'Employee',
                ages: this.form.value.dataRows,
                NationalityCode: nat_code,
                NationalityName: nat_name,
                NationalityIsoCode: nat_isoCode,
                className: class_name,
                sponserId: this.CompanyDetails.crEntityNumber.toString()
            });

            this.form.value.dataRows.forEach(element => {
                let FemaleSingle = element.FemaleS != null ? Number(element.FemaleS) : 0;
                let FemaleMarried = element.FemaleM != null ? Number(element.FemaleM) : 0;
                let Male = element.Male != null ? Number(element.Male) : 0;
                this.TotalNumberOfEmployees += FemaleSingle + FemaleMarried + Male;
            });
        }
        else if (this.IsChild)
            this.OutputTypes.push({
                type: '3',
                typeName: 'Child',
                ages: this.form.value.dataRows,
                NationalityCode: nat_code,
                NationalityName: nat_name,
                NationalityIsoCode: nat_isoCode,
                className: class_name,
                sponserId: this.CompanyDetails.crEntityNumber.toString()
            });
        else if (this.IsSpouse)
            this.OutputTypes.push({
                type: '2',
                typeName: 'Spouse',
                ages: this.form.value.dataRows,
                NationalityCode: nat_code,
                NationalityName: nat_name,
                NationalityIsoCode: nat_isoCode,
                className: class_name,
                sponserId: this.CompanyDetails.crEntityNumber.toString()
            });
        else if (this.IsOthers)
            this.OutputTypes.push({
                type: '5',
                typeName: 'Others',
                ages: this.form.value.dataRows,
                NationalityCode: nat_code,
                NationalityName: nat_name,
                NationalityIsoCode: nat_isoCode,
                className: class_name,
                sponserId: this.CompanyDetails.crEntityNumber.toString()
            });

        this.isSaved = true;
        this.ShowMembersTable = false;
        this.clearFormArray(this.form.get('dataRows') as FormArray);
        this.form.controls.reset;
    }
    onChange() {
        if (this.IsEmployee || this.IsChild || this.IsSpouse || this.IsOthers) {
            //this.dataSource.data = [];
            this.IsChild = false;
            this.IsEmployee = false;
            this.IsSpouse = false;
            this.IsOthers = false;
            this.typesControl.reset();
        }
    }
    onClassChange() {
        this.isClassSelected = true;
        this.typesControl.reset();
        this.ShowMembersTable = false;
        this.isSaved = false;
    }

    onNationalityChange() {
        this.isNationalitySelected = true;
        this.typesControl.reset();
        this.ShowMembersTable = false;
    }

    OnchangeType(SelectedType: string) {

        // this.typesControl.reset();
        this.ShowMembersTable = true;
        let nat_code: string = this.nationalitiesControl.value['code'];
        let nat_name: string = this.nationalitiesControl.value['name'];
        let nat_isoCode: string = this.nationalitiesControl.value['isoCode'];
        let class_name: string = this.classesControl.value;

        switch (SelectedType) {
            case 'Employee':
                this.IsEmployee = true;
                this.IsChild = false;
                this.IsSpouse = false;
                this.IsOthers = false;
                var emp = this.Types.find((c) => c.typeName == SelectedType);
                this.addDataRow(emp, "1", nat_code, class_name);

                break;
            case 'Child':
                this.IsEmployee = false;
                this.IsChild = true;
                this.IsSpouse = false;
                this.IsOthers = false;
                var child = this.Types.find((c) => c.typeName == SelectedType);
                this.addDataRow(child, "3", nat_code, class_name);
                break;
            case 'Spouse':
                this.IsEmployee = false;
                this.IsChild = false;
                this.IsSpouse = true;
                this.IsOthers = false;
                var spouse = this.Types.find((c) => c.typeName == SelectedType);
                this.addDataRow(spouse, "2", nat_code, class_name);
                break;
            case 'Other':
                this.IsEmployee = false;
                this.IsChild = false;
                this.IsSpouse = false;
                this.IsOthers = true;
                var other = this.Types.find((c) => c.typeName == SelectedType);
                this.addDataRow(other, "5", nat_code, class_name);
                break;

            default:
                break;
        }
    }
}
