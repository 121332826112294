import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';

@Component({
    selector: 'app-services-providers',
    templateUrl: './services-providers.component.html',
    styleUrls: ['./services-providers.component.scss'],
})
export class ServicesProvidersComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = [
        'serviceProvider',
        'city',
        'address',
        'specialities',
        'telephone',
        'latitude',
    ];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    languageValue = localStorage.getItem('language');
    cityId: number = 0;
    memberId= localStorage.getItem('memberID');;
    providerId: number = 0;
    providers: any[] = [];
    allRegions: any[] = null;
    allCities: any[] = null;
    selectedCity: any;
    providerName: string;
    dataSource = new MatTableDataSource();
    resetDefualt :any;
    isLoading = true;
    cityFlag: boolean = true;
    pageInit: boolean = true;


    constructor(
        public translate: TranslateService,
        public medicalClaimsService: MedicalClaimsService,
        private _LocalService: LocalService
    ) {
        this.dataSource = new MatTableDataSource(this.providers);

        this.languageValue = localStorage.getItem('language');
        this.selectedCity = null;
        this.providerName = null;
        this.memberId=this._LocalService.getJsonValue('memberID');
        this.resetDefualt=false;
        this.pageInit = true;

    }
    Area = new FormControl('area');
    ngOnInit(): void {
        this.GetCities();

    }
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;

    }

    getProviders() {
        this.isLoading=true;

        let queryString =
            'MemberId=' + this.memberId + '&CityId=' + this.selectedCity;

        if (this.providerId > 0) {
            queryString += '&ProviderId=' + this.providerId;
        }

        this.medicalClaimsService
            .PointedProviders(queryString)
            .subscribe((res) => {
                this.providers = res?.data;
                this.dataSource = new MatTableDataSource<providerElem>(
                    this.providers
                );

                setTimeout(() => this.dataSource.paginator = this.paginator);
                this.isLoading=false

                this.dataSource.paginator = this.paginator;

            },(err)=>
            {
              this.isLoading=false

            });
            this.resetDefualt=true;
            this.pageInit = false;


    }
    toggleButtons()
    {
        this.resetDefualt=false;
    }


    GetCities() {
        let lang = this.languageValue.slice(0, 2);
        let queryString = lang ;

        this.medicalClaimsService
            .ServiceCities(queryString)
            .subscribe((res) => {
                this.allCities = res;
                this.isLoading=false

            },
            (err)=>
            {
              this.isLoading=false

            });
    }

    filterByName() {
        const filterValue = this.providerName;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        setTimeout(() => this.dataSource.paginator = this.paginator);

    }
    navToGoogleMaps(Location) {
        if (Location) {
            let Gmap = 'https://www.google.com/maps/search/?api=1&query=';
            window.open(Gmap + Location);
        }
    }
    resetFilters()
    {
        this.selectedCity=null;
        this.dataSource=null;
        this.providers=[];
        this.resetDefualt=null;
        this.pageInit = true;
        this.resetProvideName();

    }
    resetProvideName()
    {
    this.providerName=null;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

}
}

export interface providerElem {
    providerEName: 'string';
    providerAName: 'string';
    city: 'string';
    latitude: 'string';
    longitude: 'string';
}
