<mat-stepper #stepper>
    <mat-step><app-sme-quotation-details [CompanyDetails]="CompanyDetails" [PremiumData]="PremiumData"
        [QuotationNumber]="QuotationNumber" [myStepper]="stepper"
        (onQuotationSubmit)="getBackToMemberDetails($event)"></app-sme-quotation-details>
    </mat-step>
    <mat-step>
        <div *ngIf="stepper.selectedIndex == 1">
            <app-add-payment-info (onPayClickSubmit)="nextTab($event)"
             [CompanyDetails]="compDetails" [premData]="premData"
            [QuotationNumber]="quotNumber"></app-add-payment-info>
        </div>
    </mat-step>
</mat-stepper>