import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MotorClaimsService } from 'src/app/core/services/motor-claims.service';

@Component({
  selector: 'app-dlsubmit-missing-docs-dialog',
  templateUrl: './dlsubmit-missing-docs-dialog.component.html',
  styleUrls: ['./dlsubmit-missing-docs-dialog.component.scss']
})
export class DLSubmitMissingDocsDialogComponent {

  claim: any;
  isLoading: boolean;
  domesticClaimImagesList = [];
  ImagesId: any[] = [];
  isAllDocsUploaded: boolean;
  RequiredMissingDocs = [];
  attachmentsCheck: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _router: Router,
    public motorClaimsService: MotorClaimsService,
    public translate: TranslateService,
    private dialogRef: MatDialogRef<DLSubmitMissingDocsDialogComponent>
  ) {
    this.isLoading = false;
    this.claim = data.claim;
    console.log(this.claim);

    this.RequiredMissingDocs = ['contract', 'passport', 'iban'];
  }

  setDocsList(event, id) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const fileName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.domesticClaimImagesList.push({
          image: reader.result,
          id: id,
          name: fileName,
        });
        if (!this.ImagesId.includes(id)) {
          this.ImagesId.push(id);
        }
      };
    }
  }

  deleteAtt(image) {
    this.domesticClaimImagesList.splice(image, 1);
  }

  ValidateAllDocsUploaded() {
    return this.ImagesId.length == this.RequiredMissingDocs.length
  }

  SubmitClaimMissingDocs() {
    debugger;
    let validationResult = this.ValidateAllDocsUploaded();

    if (validationResult) {
      let docsList = [];
      this.domesticClaimImagesList.forEach((x) => {
        let item = {} as MissingDocs;
        item.dOC_BYTE = x.image;
        item.cS_DOC_TYPE = x.id;

        docsList.push(item);
      });

      let missingDocsDTO = {
        PolicyNo: this.claim.claimno,
        Docs: docsList
      }

      this.isLoading = true;
      this.motorClaimsService
        .submitDlClaimsMissingDocs(missingDocsDTO)
        .subscribe((res) => {
          if (res != null && res) {
            this.isLoading = false;
            this.dialogRef.close();
            window.location.reload();
          } else {
            this.isLoading = false;
          }
        });
    } else {
      this.attachmentsCheck =
                this.translate.currentLang == 'en-US' ?
                    'Please Upload All required attachments'
                    : 'الرجاء ارفاق كل الملفات المطلوبة';
    }
  }
}

interface MissingDocs {
  cS_DOC_TYPE: string;
  dOC_BYTE: string;
}
