<loading *ngIf="isSubmitted">{{ isSubmitted }}</loading>

<br/>
<section style="background-color: #eee;">
    <div class="container py-5" *ngIf="ProductCode == 1">
        <div class="row">
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <nav aria-label="breadcrumb" class="bg-light rounded-3 p-3 mb-4">
                      <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #b58e3e;"><strong>{{"LogIn.Home" | translate}} </strong></a></li>
                        <li class="breadcrumb-item"><a href="#" style="color: #b58e3e;"><strong>{{"LogIn.Login" | translate}} </strong></a></li>
                        <li class="breadcrumb-item active" aria-current="page" style="color: #226439;"><strong>{{"LogIn.PolicyDetails" | translate}} </strong></li>
                      </ol>
                    </nav>

                  </div>
                  <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-body text-center">
                          <img src="../../../../assets/img/logo/motorlog.png" alt="avatar"
                            class="rounded-circle img-fluid" style="width: 50%;">
                          <hr />
                          <div class="d-flex justify-content-center mb-2" *ngIf="filteredPolicy.status == 'Active'">
                            <div class="col-md-6">
                                <button type="button" class="btn btn-rounded"  style="background-color: darkred; color: white;" (click)="openDialog('500ms', '200ms',filteredPolicy);clicked = true;" *ngIf="showCancelButton">{{"LogIn.cancelPolicy" | translate}}</button>
                            </div>
                            <div class="col-md-6">
                            <button type="button" class="btn btn-rounded" style="background-color:#226439; color:white;"  (click)="GoToclaims();">{{"LogIn.claimInq" | translate}}</button>
                        </div>
                        </div>
                          <div class="d-flex justify-content-center mb-2">

                          </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">

            <div class="row">
                <div class="col-md-12">
                    <nav aria-label="breadcrumb" class="bg-light rounded-3 p-3 mb-4">
                            <div class="form-outline mb-4">
                            <mat-form-field style="height: 100%;width: 80%;" >
                                <mat-select class="form-outline mb-4"   #mySelect
                                    placeholder="{{'LogIn.PolicyNumber' | translate}}" style="width: 100%;height: 35px;" >
                                    <mat-option
                                        *ngFor="let pol of policyNumbers"
                                        [value]="pol"
                                        >
                                        {{ pol}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                                <button type="button" class="btn btn-rounded" style="background-color:#226439; color:white;float: inline-end;" (click)="getPolicyInfo(mySelect.value);clicked=true;">{{"LogIn.Search" | translate}}</button>

                        </div>



                    </nav>

                  </div>
                <div class="col">
               <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
                 <div class="toast-header toast-info" style="background-color: #226439;color: white;">
                <i class="fa fa-info-circle fa-lg me-2"></i>
                <strong class="me-auto">{{"LogIn.ClientID" | translate}}</strong>

              </div>
              <div class="toast-body text-center"><strong>{{filteredPolicy.idNo}}</strong></div>
            </div>
                </div>
                <div class="col">
                    <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
                      <div class="toast-header toast-info" style="background-color: #D4AB36;color: white;">
                     <i class="fa fa-info-circle fa-lg me-2"></i>
                     <strong class="me-auto">{{"LogIn.MobileNo" | translate}}</strong>

                   </div>
                   <div class="toast-body text-center"><strong>{{filteredPolicy.mobile}}</strong></div>
                 </div>
                </div>
            </div>
            <br/>
            <div class="row">

                <div class="col">
                                <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
                                  <div class="toast-header toast-info" style="background-color: #D4AB36;color: white;">
                                 <i class="fa fa-info-circle fa-lg me-2"></i>
                                 <strong class="me-auto">{{"LogIn.PolicyEffective" | translate}}</strong>

                               </div>
                               <div class="toast-body text-center"><strong>{{filteredPolicy.policyDate}}</strong>
                                <div class="progress rounded" style="height: 20px; background-color:#226439;cursor: pointer;" matTooltip="{{filteredPolicy.daysLeft}} Days Left in your policy">
                                    <div class="progress-bar"  role="progressbar" style=" background-color: darkred;color:white;cursor: pointer;"  matTooltip="Policy Expired Days {{filteredPolicy.daysPassed}}" [style.width.%]="percePassedDays" aria-valuenow="{{filteredPolicy.daysPassed}}"
                                      aria-valuemin="0" aria-valuemax="365"><strong></strong> </div>
                                  </div>
                            </div>
                             </div>
                </div>
                <div class="col">
                    <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
                        <div class="toast-header toast-info" style="background-color: #226439;color: white;">
                       <i class="fa fa-info-circle fa-lg me-2"></i>
                       <strong class="me-auto">{{"LogIn.PolicyNumber" | translate}}</strong>
                       <small></small>
                     </div>
                     <div class="toast-body text-center"><strong>{{filteredPolicy.policyNo}}</strong>
                  </div>
                   </div>

                </div>
            </div>
            <br/>

            <div class="row">
                <div class="col" *ngIf="cancellationDto != null && cancellationDto.srV_TRX_ID > 0">
                    <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
                      <div class="toast-header toast-info" style="background-color: darkred;color: white;">
                     <i class="fa fa-info-circle fa-lg me-2"></i>
                     <strong class="me-auto">{{"LogIn.CancellationStatus" | translate}}</strong>
                     <small>{{"LogIn.RequestNo" | translate}} {{cancellationDto.srV_TRX_ID}}</small>
                   </div>
                   <div class="toast-body text-center" *ngIf="filteredPolicy.status == 'Active'"><strong> {{"LogIn.Registered" | translate}}</strong>
                    <div class="toast-body text-center" *ngIf="filteredPolicy.status != 'Active'"><strong> {{"LogIn.Accepted" | translate}}</strong>

                </div>
                 </div>
                </div>
            </div>
            <div class="col" *ngIf="cancellationDto != null && cancellationDto.srV_TRX_ID > 0">
                <div class="toast show fade toast-info" role="alert" aria-live="assertive" aria-atomic="true" >
                  <div class="toast-header toast-info" style="background-color: darkred;color: white;">
                 <i class="fa fa-info-circle fa-lg me-2"></i>
                 <strong class="me-auto">{{"LogIn.CancellationRequestDate" | translate}} </strong>
                 <small>{{"LogIn.RequestNo" | translate}} {{cancellationDto.srV_TRX_ID}}</small>
               </div>
               <div class="toast-body text-center" *ngIf="filteredPolicy.status == 'Active'"><strong> {{cancellationDto.dateofrequest}}</strong>


             </div>
            </div>
        </div>
            </div>
            <br />
            <div class="row">
                <mat-card class="example-card col-md-12">

                    <!-- <mat-card-header>
                      <div mat-card-avatar class="example-header-image text-center"></div>
                      <mat-card-title></mat-card-title>
                      <mat-card-subtitle>

                    </mat-card-subtitle>
                    </mat-card-header> -->

                    <mat-card-content>
                        <div class="panel panel-default">
                            <div class="table-content col-md-12" style="overflow-x: auto">
                                <div class="mat-elevation-z8">
                            <mat-table [dataSource]="dataSource" matSort >

                              <!-- ID Column -->
                              <ng-container matColumnDef="vehicleName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> {{"LogIn.vehicleName" | translate}} </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.vehicleName}} </mat-cell>
                              </ng-container>

                              <!-- Progress Column -->
                              <ng-container matColumnDef="plateNo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> {{"LogIn.PlateNo" | translate}} </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.plateNo}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="chassis">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> {{"LogIn.Chassis" | translate}} </mat-header-cell>
                                <mat-cell *matCellDef="let row" > {{row.chassis}} </mat-cell>
                              </ng-container>
                              <!-- Name Column -->
                              <ng-container matColumnDef="manefacturingYear">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> {{"LogIn.Manefactor" | translate}} </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.manefacturingYear}} </mat-cell>
                              </ng-container>
                              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                              <mat-row *matRowDef="let row; columns: displayedColumns;">
                              </mat-row>
                            </mat-table>
                        </div>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                 </div>
                    </mat-card-content>

                  </mat-card>
            </div>
        </div>
    </div>

    <br/>



    </div>
  </section>
