<section style="background-color: #eee;  top: 0">
    <div class="container py-5">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <img src="assets//img/logo/NewLogo2.png" alt="logo" />
                    </div>
                </div>
                <br />
                <div class="row d-flex justify-content-center pb-5">
                    <div class="col-md-7 col-xl-5 mb-4 mb-md-0">
                        <h4>
                            <b style="color: #d4ab36">
                                {{ "Payment.WelcomeClientpart1" | translate }}
                                <span style="margin-left: 0.5rem;margin-right: 0.5rem;color: #156538">|</span>     {{paymentinfo.clientname}}
                            </b>
                        </h4>
                       
                        <b style="color: #156538">{{ "Payment.WelcomeClientPart2" | translate }}</b>
                       
                        <p style="color: #156538">
                            {{ "Payment.Description" | translate }}
                        </p>
                        <div class="rounded d-flex bg-body-tertiary" style="color: #d4ab36">
                            <b><i style="color: #156538 " class="fas fa-shield-alt"></i>  {{ "Payment.Description2" | translate }}</b> 
                        </div>
                        <hr />
                        <div class="pt-2">
                            <div class="d-flex pb-2">
                                <div>
                                    <p>
                                        <b>
                                            {{ "Payment.RequestNo" | translate }}
                                            <span style="color: #156538">
                                                {{paymentinfo.policyno}}
                                            </span>
                                        </b>
                                    </p>
                                </div>

                            </div>
                            <div class="d-flex pb-2">
                                <div>
                                    <p>
                                        <b>
                                            {{ "Payment.LOB" | translate }}
                                            <span style="color: #156538">
                                                {{paymentinfo.product}}
                                            </span>
                                        </b>
                                    </p>
                                </div>

                            </div>

                            <div class="d-flex pb-2">
                                <div>
                                    <p>
                                        <b>
                                            {{ "Payment.MobileNo" | translate }}
                                            <span style="color: #156538">
                                                {{paymentinfo.mobilE_NO}}
                                            </span>
                                        </b>
                                    </p>
                                </div>

                            </div>

                            <div class="card mb-3" style="border-radius: 15px" hidden>
                                <div class="card-body p-4">
                                    <form>
                                        <div class="d-flex justify-content-between align-items-center mb-3">
                                            <div data-mdb-input-init
                                                 class="form-outline">
                                                <input type="text"
                                                       id="typeText"
                                                       class="form-control form-control-lg"
                                                       size="17"
                                                       placeholder="1234 5678 9012 3457"
                                                       minlength="19"
                                                       maxlength="19" />
                                                <label class="form-label"
                                                       for="typeText">IBAN</label>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-between align-items-center mb-4">
                                            <div data-mdb-input-init
                                                 class="form-outline">
                                                <input type="text"
                                                       id="typeName"
                                                       class="form-control form-control-lg"
                                                       siez="17"
                                                       placeholder="Cardholder's Name" />
                                                <label class="form-label"
                                                       for="typeName">EMAIL</label>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-rounded" (click)="ProceedPayment($event);" style="background-color: #156538 !important;color: #ffffff;" mdbRipple><h6><b><i class="far fa-credit-card"></i> &ensp; {{ "Payment.ProceedToPayment" | translate }}</b></h6></button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-5 col-xl-4 offset-xl-1">
                        <b><h4 style="color: #d4ab36"><i style="color: #156538" class="fas fa-file-invoice-dollar"></i>   {{ "Payment.PremiumBreakDown" | translate }} </h4> </b>
                        <div class="rounded d-flex flex-column p-2 " style="background-color: #ECEFF1">
                            
                            <div class="p-2 d-flex">
                                <div class="col-8" style="color: #d4ab36"><b><i style="color: #156538" class="fas fa-money-bill-wave"></i> {{ "Payment.Premium" | translate }}</b></div>
                                <div class="ms-auto" style="color: #156538">
                                    <b>{{paymentinfo.premium | number}} {{ "Payment.Currency" | translate }}</b> 
                                </div>
                            </div>
                            <div class="p-2 d-flex">
                                <div class="col-8" style="color: #d4ab36">
                                    <b><i style="color: #156538" class="fas fa-money-bill-wave"></i> {{ "Payment.VAT" | translate }}</b>
                                </div>
                                <div class="ms-auto" style="color: #156538">
                                    <b>{{paymentinfo.vat | number}} {{ "Payment.Currency" | translate }}</b>
                                </div>
                            </div>
                            <div class="p-2 d-flex">
                                <div class="col-8" style="color: #d4ab36"> <b><i style="color: #156538" class="fas fa-money-bill-wave"></i> {{ "Payment.Discount" | translate }}</b></div>
                                <div class="ms-auto" style="color: #156538">
                                    <b> {{paymentinfo.discount | number}} {{ "Payment.Currency" | translate }}</b>
                                </div>
                            </div>
                           
                            <div class="border-top px-2 mx-2" style="color: #156538"></div>
                            <div class="p-2 d-flex pt-3">
                                <div class="col-8" style="color: #d4ab36">
                                    <b><i style="color: #156538" class="fas fa-money-bill-wave"></i> {{ "Payment.TotalPrem" | translate }}</b>
                                </div>
                                <div class="ms-auto" style="color: #156538">
                                    <b>{{paymentinfo.totalpremium | number}} {{ "Payment.Currency" | translate }}</b>  
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
