<!-- Start Page Title Area -->
<div>
    <div class="page-title-area bg-1">
        <div class="container md-10">
            <div class="page-title-content">
                <h2>{{ "NAV.MEDICAL" | translate }}</h2>
                <ul>
                    <li>
                        <a routerLink="/"
                            >{{ "NAV.ESERVICES" | translate }}
                            <span style="margin: 0 10px"> / </span></a
                        >
                    </li>
                    <li class="active" style="color: white">
                        {{ "NAV.MEDICAL" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<loading *ngIf="isLoading">{{ isLoading }}</loading>

<div class="container pb-3">
    <div class="row pt-4 pb-4">
        <div class="col-lg-5 col-md-12 col-sm-12 profile">
            <div
                class="col-lg-12 col-md-12 col-sm-12 services-title pb-5"
            ></div>
            <div class="col-lg-12 col-md-12 col-sm-12 profile-card">
                <div
                    class="col-lg-12 col-md-12 col-sm-12 pt-4"
                    [ngClass]="{
                        'card-header-en': this.translate.currentLang == 'en-US',
                        'card-header-ar': this.translate.currentLang != 'en-US'
                    }"
                >
                    <div class="avatar-container col-6">
                        <div class="profile-avatar"></div>
                        <span
                            [ngClass]="{
                                'policy-class':
                                    this.translate.currentLang == 'en-US',
                                'policy-class-ar':
                                    this.translate.currentLang != 'en-US'
                            }"
                        >
                            {{ selectedMember.className }}
                        </span>
                    </div>
                    <div class="col-6">
                        <!-- <img src="assets/img/medical-service/profile-full-logo.png" width="150" class="profile-logo-img"
                                    alt="" /> -->
                        <span class="font-14 pb-1">
                            {{ selectedMember.memberEName }}
                        </span>
                    </div>
                </div>

                <div
                    class="row pb-3"
                    [ngClass]="{
                        'card-details-container-en':
                            this.translate.currentLang == 'en-US',
                        'card-details-container-ar':
                            this.translate.currentLang != 'en-US'
                    }"
                >
                    <!-- <span class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-1">
                        {{ selectedMember.memberEName }}
                    </span> -->

                    <span class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-1">
                        <div class="pb-1">
                            {{ "MEDICAL.NationalId" | translate }}:
                            {{ selectedMember.id }}
                        </div>
                        <div>
                            {{ "MEDICAL.PolicyNumber" | translate }}:
                            {{ selectedMember.policyNumber }}
                        </div>
                    </span>
                    <span
                        class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-2"
                        style="padding: 0"
                    >
                        {{ "MEDICAL.PolicyHolder" | translate }}:
                        {{ selectedMember.policyHolder }}
                    </span>

                    <span class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-1">
                        {{ "MEDICAL.StartDate" | translate }}:
                        {{
                            selectedMember.policyStartData
                                | date : "dd / M / yyyy"
                        }}
                    </span>

                    <span
                        class="col-lg-6 col-md-6 col-sm-6 col-6 font-14 pb-1"
                        style="padding: 0"
                    >
                        {{ "MEDICAL.ExpiryDate" | translate }}:
                        {{
                            selectedMember.policyExpiryData
                                | date : "dd / M / yyyy"
                        }}
                    </span>
                </div>
            </div>
        </div>

        <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="col-lg-12 col-md-12 col-sm-12 services-title pb-3">
                {{ "MEDICAL.Title" | translate }}
                <div
                    [ngClass]="{
                        'hr-line': this.translate.currentLang == 'en-US',
                        'hr-line-ar': this.translate.currentLang != 'en-US'
                    }"
                ></div>
            </div>

            <div class="row col-lg-12 col-md-12 col-sm-12">
                <a
                    href="/family-members"
                    class="col-lg-4 col-md-4 col-sm-4 service-item pb-2"
                >
                    <img
                        class="frame-img"
                        src="assets/img/medical-service/frame1.png"
                        alt=""
                        width="80"
                    />
                    <img
                        class="inner-img"
                        src="assets/img/medical-service/family (1).png"
                        alt=""
                        width="40"
                    />
                    <span
                        [ngClass]="{
                            'medical-services-list':
                                this.translate.currentLang == 'en-US',
                            'medical-services-list-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                        >{{ "MEDICAL.FamilyMembers" | translate }}</span
                    >
                </a>

                <a
                    href="/medical-benefits"
                    class="col-lg-4 col-md-4 col-sm-4 service-item pb-2"
                >
                    <img
                        class="frame-img"
                        src="assets/img/medical-service/frame1.png"
                        alt=""
                        width="80"
                    />
                    <img
                        class="inner-img"
                        src="assets/img/medical-service/health.png"
                        alt=""
                        width="40"
                    />
                    <span
                        [ngClass]="{
                            'medical-services-list':
                                this.translate.currentLang == 'en-US',
                            'medical-services-list-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                        >{{ "MEDICAL.MedicalBenefits" | translate }}</span
                    >
                </a>
                <a
                    href="/medical-approvals"
                    class="col-lg-4 col-md-4 col-sm-4 service-item pb-2"
                >
                    <img
                        class="frame-img"
                        src="assets/img/medical-service/frame1.png"
                        alt=""
                        width="80"
                    />
                    <img
                        class="inner-img"
                        src="assets/img/medical-service/agreement (1).png"
                        alt=""
                        width="40"
                    />
                    <span
                        [ngClass]="{
                            'medical-services-list':
                                this.translate.currentLang == 'en-US',
                            'medical-services-list-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                        >{{ "MEDICAL.MedicalApprovals" | translate }}</span
                    >
                </a>
            </div>

            <div class="row col-lg-12 col-md-12 col-sm-12 pt-4">
                <a
                    href="/medical-service-providers"
                    class="col-lg-4 col-md-4 col-sm-4 service-item pb-2"
                >
                    <img
                        class="frame-img"
                        src="assets/img/medical-service/frame1.png"
                        alt=""
                        width="80"
                    />
                    <img
                        class="inner-img"
                        src="assets/img/medical-service/provider.png"
                        alt=""
                        width="40"
                    />
                    <span
                        [ngClass]="{
                            'medical-services-list':
                                this.translate.currentLang == 'en-US',
                            'medical-services-list-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                    >
                        {{ "MEDICAL.ServiceProvider" | translate }}</span
                    >
                </a>

                <a
                    class="col-lg-4 col-md-4 col-sm-4 service-item pb-2"
                    (click)="ChooseClaimAction()"
                >
                    <img
                        class="frame-img"
                        src="assets/img/medical-service/frame1.png"
                        alt=""
                        width="80"
                    />

                    <img
                        class="inner-img"
                        src="assets/img/medical-service/cashback.png"
                        alt=""
                        width="40"
                    />
                    <span
                        [ngClass]="{
                            'medical-services-list':
                                this.translate.currentLang == 'en-US',
                            'medical-services-list-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                        >{{ "MEDICAL.CashClaims" | translate }}</span
                    >
                </a>

                <a
                    href="/embassy-letter"
                    class="col-lg-4 col-md-4 col-sm-4 service-item pb-2"
                >
                    <img
                        class="frame-img"
                        src="assets/img/medical-service/frame1.png"
                        alt=""
                        width="80"
                    />
                    <img
                        class="inner-img"
                        src="assets/img/medical-service/approval.png"
                        alt=""
                        width="40"
                    />
                    <span
                        [ngClass]="{
                            'medical-services-list':
                                this.translate.currentLang == 'en-US',
                            'medical-services-list-ar':
                                this.translate.currentLang != 'en-US'
                        }"
                        >{{ "MEDICAL.EmbassyLetter" | translate }}</span
                    >
                </a>
            </div>
        </div>
    </div>

    <div class="row"></div>
</div>
