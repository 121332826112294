import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';

@Component({
  selector: 'app-family-members',
  templateUrl: './family-members.component.html',
  styleUrls: ['./family-members.component.scss']
})
export class FamilyMembersComponent implements OnInit {
  selectedMember: any;
  memberDetails: any;
  spouses:any;
  childs:any;
  perants:any;
  allTables:any;
  memberID:any;
  constructor(public translate: TranslateService, public medicalClaimsService: MedicalClaimsService,
    private _LocalService: LocalService) { 
      this.selectedMember = {
        id: '',
        policyNumber: '',
        PolicyStartData:'',
        policyHolder: '',
        policyExpiryData: '',
        memberCode: '',
        memberEName: '',
        gender: '',
        birthDate: '',
        className: '',
        relation: '',
    };
    this.spouses=[];
    this.childs=[];
    this.perants=[];
    this.allTables=[];
    this.memberDetails = this._LocalService.getJsonValue('memberDetails');
    this.memberID = this._LocalService.getJsonValue('memberID');

    }
    GetMemeberDetails()
    {
        this.memberDetails = this._LocalService.getJsonValue('memberDetails');
        if( this.memberDetails !==null )
        {
        if (this.memberDetails?.length > 0) {
            this.selectedMember = this.memberDetails[0];
            for (let index = 0; index < this.memberDetails.length; index++) {
              if(this.memberDetails[index]?.relation.toLowerCase()==="spouse")
              this.spouses.push(this.memberDetails[index]);
              else if(this.memberDetails[index]?.relation.toLowerCase()==='children')
            {
              this.childs.push(this.memberDetails[index]);
 
            }
            else if(this.memberDetails[index]?.relation.toLowerCase()==='perant')
            {
              this.perants.push(this.memberDetails[index]);
 
            }
            }
            if(this.spouses.length>0)
            {
              this.allTables.push(this.spouses);
            }
             if(this.childs.length>0)
            {
              this.allTables.push(this.childs);
            }
             if(this.perants.length>0)
            {
              this.allTables.push(this.perants);
            }
            
        }
      }
      else
      {

        let queryString = 'MemberId=' + this.memberID;
        this.medicalClaimsService
            .MemberDetails(queryString)
            .subscribe((res) => {
                this.memberDetails = res;
                this._LocalService.setJsonValue('memberDetails', res?.data);
                if (res?.data.length > 0) {
                    this.selectedMember = res?.data[0];
                    for (let index = 0; index <res?.data.length; index++) {
                      if(res?.data[index]?.relation.toLowerCase()==="spouse")
                      this.spouses.push(res?.data[index]);
                      else if(res?.data[index]?.relation.toLowerCase()==='children')
                    {
                      this.childs.push(res?.data[index]);
         
                    }
                    else if(res?.data[index]?.relation.toLowerCase()==='perant')
                    {
                      this.perants.push(res?.data[index]);
         
                    }
                    }
                    if(this.spouses.length>0)
                    {
                      this.allTables.push(this.spouses);
                    }
                     if(this.childs.length>0)
                    {
                      this.allTables.push(this.childs);
                    }
                     if(this.perants.length>0)
                    {
                      this.allTables.push(this.perants);
                    }
                }
            });
      }

    }

  ngOnInit(): void {
    this.memberDetails = this._LocalService.getJsonValue('memberDetails');
    this.GetMemeberDetails();
  }
  

}
