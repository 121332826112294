import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from "../AppConfigService";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class OTPService {
    baseUrl: string;
    private SendOTPURL:string;
    private VerifyOTPURL:string;
    constructor(
        private http: HttpClient,
        private environment: AppConfigService
    ) {
        this.baseUrl = environment.config.servicesBaseUrl;

        this.SendOTPURL =
            this.baseUrl + 'otp/sendotp';

        this.VerifyOTPURL = this.baseUrl + 'otp/verifyotp'
    }

    sendOTP(model): Observable<any> {
        return this.http.post<any>(this.SendOTPURL, model);
    }

    verifyOTP(model): Observable<any> {
        return this.http.post<any>(this.VerifyOTPURL, model);
    }
}