import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MedicalClaimsService } from 'src/app/core/services/Medical-Service/medical-claims.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-medical-profile',
    templateUrl: './medical-profile.component.html',
    styleUrls: ['./medical-profile.component.scss'],
})
export class MedicalProfileComponent implements OnInit {
    defaultClass: boolean = true;
    memberDetails: any;
    selectedMember: any;
    memberId: number;
    selectedService: boolean;
    memID = localStorage.getItem('memberID');
    isLoading = true;

    constructor(
        public translate: TranslateService,
        public medicalClaimsService: MedicalClaimsService,
        public _router: Router,
        private _LocalService: LocalService
    ) {
        this.memID = this._LocalService.getJsonValue('memberID');

        this.memberId = 1;
        this.selectedMember = {
            id: '',
            policyNumber: '',
            policyHolder: '',
            policyExpiryData: '',
            PolicyStartData: '',
            memberCode: '',
            memberEName: '',
            gender: '',
            birthDate: '',
            className: '',
            relation: '',
        };
        this.isLoading = true;
    }

    ngOnInit(): void {
        this.GetUserMedicalProfile();
    }

    GetUserMedicalProfile() {
        let queryString = 'MemberId=' + this.memID;
        this.medicalClaimsService.MemberDetails(queryString).subscribe(
            (res) => {
                this.memberDetails = res;
                this._LocalService.setJsonValue(
                    'memberDetails',
                    this.memberDetails.data
                );
                if (this.memberDetails.data.length > 0) {
                    this.selectedMember = this.memberDetails.data[0];
                }
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
                let dir: string = '';
                let errorMesg;

                if (this.translate.currentLang == 'en-US') {
                    dir = 'ltr';
                    errorMesg =
                        'An Error Occurred ,Please Check Your Connection !';
                } else {
                    dir = 'rtl';
                    errorMesg = 'حدث خطأ , تحقق من اتصالك بالانترنت !  ';
                }
                Swal.fire({
                    title:
                        this.translate.currentLang == 'en-US'
                            ? 'Error!'
                            : '!خطأ',
                    iconHtml:
                        '<img width=40 height=40 src="assets/img/swal-icons-img/warning.png">',
                    html:
                        "<div style='color:#FF001F; direction:" +
                        dir +
                        "'>" +
                        errorMesg +
                        '</div>',
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonColor: '#FF001F',
                    cancelButtonColor: '#FF001F',

                    confirmButtonText:
                        this.translate.currentLang == 'en-US'
                            ? 'Log In'
                            : 'تسجيل الدخول',
                    cancelButtonText:
                        this.translate.currentLang == 'en-US'
                            ? 'Home Page'
                            : 'الصفحة الرئيسية',
                    customClass: {
                        icon: 'submit-claim-failed-icon-img',
                        title: 'submit-claim-failed-swal-title',
                        actions: 'actions',
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        this._LocalService.setJsonValue('userVerified', null);
                        this._LocalService.setJsonValue('userLoginInfo', null);
                        this._LocalService.setJsonValue('memberDetails', null);
                        window.location.href = '/user-login';
                    } else  {
                        this._LocalService.setJsonValue('userVerified', null);
                        this._LocalService.setJsonValue('userLoginInfo', null);
                        this._LocalService.setJsonValue('memberDetails', null);
                        window.location.href = '';
                    }
                });
            
            }
        );
    }

    async ChooseClaimAction() {
        this.selectedService = true;
        let dir: string = '';
        if (this.translate.currentLang == 'en-US') {
            dir = 'ltr';
        } else {
            dir = 'rtl';
        }

        const { value: TypeId } = await Swal.fire({
            title:
                this.translate.currentLang == 'en-US'
                    ? 'Choose Claim Action'
                    : 'اختر اجراء المطالبة',
            iconHtml:
                '<img width=40 height=40 src="assets/img/swal-icons-img/icons8-natural-user-interface-2-100.png">',
            input: 'radio',
            showCloseButton: true,
            confirmButtonText:
                this.translate.currentLang == 'en-US' ? 'Submit' : 'متابعة',
            html: '<div class="swal2-radio claim-type-swal-input" style="display: flex;"><label><input type="radio" name="swal2-radio" value="7"><span class="swal2-label">Tracking</span></label><label><input type="radio" name="swal2-radio" value="8"><span class="swal2-label">New Cash Claim</span></label></div>',
            inputOptions: {
                '8':
                    this.translate.currentLang == 'en-US'
                        ? 'New Cash Claim'
                        : 'مطالبة جديدة',
                '7':
                    this.translate.currentLang == 'en-US'
                        ? 'Tracking'
                        : 'تتبع المطالبات',
            },
            didClose: () => {
                this.selectedService = false;
            },
            inputValidator: (value: any) => {
                if (!value) {
                    return this.translate.currentLang == 'en-US'
                        ? 'Please Select Claim Action'
                        : 'يرجي اختيار اجراء المطالبة';
                }
            },
            customClass: {
                icon: 'icon-img',
                title: 'claim-type-swal-title',
                input: 'claim-type-swal-input',
                confirmButton: 'claim-type-confirm-btn',
            },
        });
        if (TypeId) {
            // new claim
            if (TypeId == 8) {
                this._router.navigate(['/new-medical-claim']);
            }
            // tracking
            else if (TypeId == 7) {
                this._router.navigate(['/medical-claims']);
            }
        }
    }
}
