import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { MatTimepickerModule } from 'mat-timepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ClaimsPageComponent } from './components/pages/claims-page/claims-page.component';
import { ServicesPageComponent } from './components/pages/services-page/services-page.component';
import { SmeFastCalcTableComponent } from './components/E-Services/Medical-SME/sme-fast-calc-table/sme-fast-calc-table.component';

import { RegisterComplaintComponent } from './components/pages/services-page/components/register-complaint/register-complaint.component';

import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';

import { MenuModule } from 'primeng/menu';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatGridListModule} from '@angular/material/grid-list';
import {
    CommonModule,
    APP_BASE_HREF,
    LocationStrategy,
    HashLocationStrategy,
    CurrencyPipe,
    DecimalPipe,
} from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserVerificationComponent } from './components/user-login/user-verification/user-verification.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MotorClaimsComponent } from './components/E-Services/Motor/motor-claims/motor-claims.component';
import { NewMotorClaimComponent } from './components/E-Services/Motor/new-motor-claim/new-motor-claim.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCommonModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MedicalApprovalsComponent } from './components/E-Services/Medical/medical-approvals/medical-approvals.component';
import { MatSelectModule } from '@angular/material/select';
import { MedicalProfileComponent } from './components/E-Services/Medical/medical-profile/medical-profile.component';
import { ServicesProvidersComponent } from './components/E-Services/Medical/services-providers/services-providers.component';
import { MedicalBenefitsComponent } from './components/E-Services/Medical/medical-benefits/medical-benefits.component';
import { NewMedicalCashClaimComponent } from './components/E-Services/Medical/new-medical-cash-claim/new-medical-cash-claim.component';
import { MedicalCashClaimsComponent } from './components/E-Services/Medical/medical-cash-claims/medical-cash-claims.component';
import { FamilyMembersComponent } from './components/E-Services/Medical/family-members/family-members.component';
import { EmbassyLetterComponent } from './components/E-Services/Medical/embassy-letter/embassy-letter.component';
import { AppConfigService } from '../app/core/AppConfigService';
import {
    MatPaginatorModule,
    MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { getDutchPaginatorIntl } from './components/custom-paginator/custom-paginator.component';
import { EServiceHomeComponent } from './components/pages/e-service-home/e-service-home.component';

import { MatTabsModule } from '@angular/material/tabs';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { LoadingComponent } from './components/common/loading/loading.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { HttpConfigInterceptorInterceptor } from './http-config-interceptor.interceptor';
import { MMLHomeComponent } from './components/E-Services/MML/mml-home/mml-home.component';
import { PurchasePolicyComponent } from './components/E-Services/MML/purchase-policy/purchase-policy.component';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IdVerificationComponent } from './components/E-Services/MML/id-verification/id-verification.component';
import { RedirectPageComponent } from './components/E-Services/MML/redirect-page/redirect-page.component';
import { DatePipe } from '@angular/common';
import { CommaToSpacePipePipe } from './comma-to-space-pipe.pipe';
import { MainPageComponent } from './components/E-Services/Medical-SME/main-page/main-page.component';
import { SmeTableComponent } from './components/E-Services/Medical-SME/sme-table/sme-table.component';
import { SmeLoginPageComponent } from './components/E-Services/Medical-SME/sme-login-page/sme-login-page.component';
import { SmeClassSelectionDialogComponent } from './components/E-Services/Medical-SME/sme-class-selection-dialog/sme-class-selection-dialog.component';
import { SmeCreateQuotationComponent } from './components/E-Services/Medical-SME/sme-create-quotation/sme-create-quotation.component';
import { SmeQuotationFormStepComponent } from './components/E-Services/Medical-SME/sme-quotation-form-step/sme-quotation-form-step.component';
import { SmeQuotationTableStepComponent } from './components/E-Services/Medical-SME/sme-quotation-table-step/sme-quotation-table-step.component';
// import { TagInputModule } from 'ngx-chips';
import { MatChipsModule } from '@angular/material/chips';
import { UploadMembersDialogComponent } from './components/E-Services/Medical-SME/upload-members-dialog/upload-members-dialog.component';
import { UploadMembersResultDialogComponent } from './components/E-Services/Medical-SME/upload-members-result-dialog/upload-members-result-dialog.component';
import { HealthDeclarationDialogComponent } from './components/E-Services/Medical-SME/health-declaration-dialog/health-declaration-dialog.component';
import { AddMemberDialogComponent } from './components/E-Services/Medical-SME/add-member-dialog/add-member-dialog.component';
import { FastCalculationSummaryDialogComponent } from './components/E-Services/Medical-SME/fast-calculation-summary-dialog/fast-calculation-summary-dialog.component';
import { SmeQuotationDetailsComponent } from './components/E-Services/Medical-SME/sme-quotation-details/sme-quotation-details.component';
import { AddPaymentInfoComponent } from './components/E-Services/Medical-SME/add-payment-info/add-payment-info.component';
import { QuotationDetailsComponent } from './components/E-Services/Medical-SME/quotation-details/quotation-details/quotation-details.component';
import { SmeIssuedPolicyComponent } from './components/E-Services/Medical-SME/quotation-details/sme-issued-policy/sme-issued-policy.component';
import { MmpInquiryComponent } from './components/E-Services/MML/mmp-inquiry/mmp-inquiry.component';

import { ClaimDigitalizationComponent } from './components/E-Services/ClaimDigitalization/claim-digitalization/claim-digitalization.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import{CancellationDigitalizationComponent} from './components/E-Services/cancellation-digitalization/cancellation-digitalization.component';
import { CancellationSubmissionComponent } from './components/E-Services/cancellation-submission/cancellation-submission.component'

import { MotorClaimsInquireComponent } from './components/E-Services/Motor/motor-claims-inquire/motor-claims-inquire.component';
import { MotorLoginComponent } from './components/E-Services/Motor/motor-login/motor-login.component';
import { ToasterContainerComponent } from './toaster-container.component';
import { ToasterComponent } from './toaster.component';
import { MotorPolicyDetailsComponent } from './components/E-Services/Motor/motor-policy-details/motor-policy-details.component';
import { MotorCancelationModal} from './components/E-Services/Motor/motor-policy-details/motor-policy-details.component';
import { MotorDetailsLoginComponent } from './components/E-Services/Motor/motor-details-login/motor-details-login.component';
import { DLPolicyDetailsComponent } from './components/E-Services/Domestic-Labor/dl-policy-details/dl-policy-details.component';
import { dlCancelationModal } from './components/E-Services/Domestic-Labor/dl-policy-details/dl-policy-details.component';
import { dlClaimSubmission } from './components/E-Services/Domestic-Labor/dl-policy-details/dl-policy-details.component';
import { DLPOLICYLOGINComponent } from './components/E-Services/Domestic-Labor/dl-policy-login/dl-policy-login.component';
import { MissingDocDialogComponent } from './components/E-Services/Motor/motor-claims-inquire/motor-claims-inquire.component'
import {ClaimDetailsDialogComponent} from './components/E-Services/Motor/motor-claims-inquire/motor-claims-inquire.component'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TermsAndConditionsDialogComponent } from './components/E-Services/Medical-SME/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { MmpCashClaimsComponent } from './components/E-Services/MML/mmp-cash-claims/mmp-cash-claims.component';
import { NewMmpCashClaimComponent } from './components/E-Services/MML/mmp-cash-claims/new-mmp-cash-claim/new-mmp-cash-claim.component';
import { MmpPolicyDetailsComponent } from './components/E-Services/MML/mmp-cash-claims/mmp-policy-details/mmp-policy-details.component';
import { ClaimStatusReplacementPipe } from './core/Pipes/claim-status-change-pipe';
import { MessageDialogComponent } from './core/shared/message-dialog/message-dialog.component';
import { LoginMotorComponent } from './components/E-Services/Motor-pages/login-motor/login-motor.component';
import { MotorStepperComponent } from './components/E-Services/Motor-pages/motor-stepper/motor-stepper.component';
import { VehicleDetailsFormComponent } from './components/E-Services/Motor-pages/motor-stepper-sub-pages/vehicle-details-form/vehicle-details-form.component';
import { VehicleDetailsInfoComponent } from './components/E-Services/Motor-pages/motor-stepper-sub-pages/vehicle-details-info/vehicle-details-info.component';
import { PolicyCoveragePageComponent } from './components/E-Services/Motor-pages/motor-stepper-sub-pages/policy-coverage-page/policy-coverage-page.component';
import { PaymentPageComponent } from './components/E-Services/payment/payment-page/payment-page.component';
import { AfterPaymentPageComponent } from './components/E-Services/payment/Payment-ThankPage/after-payment-page/after-payment-page.component';
import { RouterModule, provideRouter } from '@angular/router';
import { ErrorPaymentOnlineComponent } from './components/E-Services/payment/error-payment-online/error-payment-online.component';
import { DLSubmitMissingDocsDialogComponent } from './components/E-Services/Domestic-Labor/dlsubmit-missing-docs-dialog/dlsubmit-missing-docs-dialog.component';
import { OtpVerificationDialogComponent } from './components/E-Services/OTPVerification/otp-verification-dialog/otp-verification-dialog.component';

const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig();
    };
};
@NgModule({
    declarations: [
        
        ClaimDigitalizationComponent,
        AppComponent,
        FooterComponent,
        NavbarComponent,
        NotFoundComponent,
        ComingSoonPageComponent,
        ClaimsPageComponent,
        ServicesPageComponent,
        RegisterComplaintComponent,
        UserLoginComponent,
        UserVerificationComponent,
        MotorClaimsComponent,
        NewMotorClaimComponent,
        MedicalApprovalsComponent,
        MedicalProfileComponent,
        ServicesProvidersComponent,
        MedicalBenefitsComponent,
        NewMedicalCashClaimComponent,
        MedicalCashClaimsComponent,
        FamilyMembersComponent,
        EmbassyLetterComponent,
        EServiceHomeComponent,
        LoadingComponent,
        MMLHomeComponent,
        PurchasePolicyComponent,
        IdVerificationComponent,
        RedirectPageComponent,
        CommaToSpacePipePipe,
        MainPageComponent,
        SmeTableComponent,
        SmeLoginPageComponent,
        SmeClassSelectionDialogComponent,
        DLSubmitMissingDocsDialogComponent,
        SmeFastCalcTableComponent,
        SmeCreateQuotationComponent,
        SmeQuotationFormStepComponent,
        SmeQuotationTableStepComponent,
        UploadMembersDialogComponent,
        UploadMembersResultDialogComponent,
        HealthDeclarationDialogComponent,
        AddMemberDialogComponent,
        FastCalculationSummaryDialogComponent,
        SmeQuotationDetailsComponent,
        AddPaymentInfoComponent,
        QuotationDetailsComponent,
        SmeIssuedPolicyComponent,
        MmpInquiryComponent,
        CancellationDigitalizationComponent,
        CancellationSubmissionComponent,
        MotorClaimsInquireComponent,
        MotorLoginComponent,
        ToasterContainerComponent, ToasterComponent,
        MotorPolicyDetailsComponent,MotorCancelationModal, MotorDetailsLoginComponent,
        DLPolicyDetailsComponent,
        dlCancelationModal, 
        DLPOLICYLOGINComponent,
        MissingDocDialogComponent,
        ClaimDetailsDialogComponent,
        TermsAndConditionsDialogComponent,
        MmpCashClaimsComponent,
        NewMmpCashClaimComponent,
        MmpPolicyDetailsComponent,
        dlClaimSubmission,
        MessageDialogComponent,
        LoginMotorComponent,
        MotorStepperComponent,
        VehicleDetailsFormComponent,
        VehicleDetailsInfoComponent,
        PolicyCoveragePageComponent,
        PaymentPageComponent,
        AfterPaymentPageComponent,
        ErrorPaymentOnlineComponent,
        OtpVerificationDialogComponent
    ],
    exports: [ ToasterContainerComponent, ToasterComponent, TranslateModule ],
    imports: [
        MatSelectModule,
        
        
        MatSelectModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CarouselModule,
        NgxScrollTopModule,
        MatProgressBarModule,
        MatTimepickerModule,
        MatCardModule,
        MatButtonModule,
        MatBadgeModule,
        MatPaginatorModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        MatGridListModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MenuModule,
        ReactiveFormsModule,
        NgSelectModule,
        MatIconModule,
        MatMenuModule,
        MatFormFieldModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSliderModule,
        MatTooltipModule,
        MatStepperModule,
        CdkStepperModule,
        MatInputModule,
        MatRadioModule,
        MatCommonModule,
        MatCheckboxModule,
        MatTableModule,
        FormsModule,
        MatTabsModule,
        NgxOtpInputModule,
        MatListModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        // TagInputModule
        MatChipsModule
    ],
    providers: [
        CurrencyPipe, DecimalPipe,
        DatePipe,
        ClaimStatusReplacementPipe,
        MatTableModule,FormsModule ,
        MatTabsModule ,NgxOtpInputModule,
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService],
        },
        { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptorInterceptor,
            multi: true,
        },
        { provide: 'googleTagManagerId', useValue: 'GTM-NS62JCNW' },
       
    ],
        

    // providers: [{ provide: APP_BASE_HREF, useValue: '/' },
    //     { provide: LocationStrategy, useClass: HashLocationStrategy }],
    bootstrap: [AppComponent],
})
export class AppModule {}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
