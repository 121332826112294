<div mat-dialog-content>

    <div class="row" *ngFor="let item of docs">
        <div class="col-md-6" style="color: #1B7B2C; font-size: 16px;">

            <div class="form-outline">
                <input type="file" [id]="item.eName" required #myInput (change)="
                            setDocsList(
                                $event,
                                item.eName
                            )
                        " class="hidden" accept="application/pdf, image/*" multiple />

                <label [for]="item.eName" class="custom-file-input upload-lbl">
                    <i class="fa fa-upload" aria-hidden="true" style="color: #d4ab36"></i>
                    {{item.eName}} - {{item.aName}}</label>
            </div>

        </div>
        <div class="col-md-6" style="color: darkgoldenrod; font-size: 16px;">
            <div class="form-outline">
                <div *ngIf="ImgsId.includes(item.eName)">
                    <ng-container *ngFor="
                                                            let img of nationalImgsList;
                                                            let x = index
                                                        ">
                        <div class="imageContainer" *ngIf="img.id == item.eName">

                            <div class="item-container" (click)="DisplayDocument(img)">
                                <div style="overflow: clip; margin-right: 5%;">
                                    {{ img.name }}
                                </div>
                                <div>
                                    <span class="delete" (click)="
                                                                                deleteAtt(
                                                                                    x
                                                                                )
                                                                            " *ngIf="
                                                                                img.id ==
                                                                                item.eName
                                                                            ">
                                        <fa-icon [icon]="
                                                                                    faXmark
                                                                                "></fa-icon>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <br />
        <br />
    </div>
    <br />
    <div class="col-md-12" style="color: #1B7B2C;" *ngIf="UploadStatus != ''">
        <li>{{UploadStatus}}</li>
    </div>
    <br />

    <div class="row">

        <div class="col-md-12 text-center pt-1 mb-5 pb-1">
            <button class="btn btn-danger btn-rounded" style="float: inline-start;cursor: pointer !important;"
                (click)="dialogRef.close()" type="submit">{{"LogIn.Close" | translate}}</button>
            <button class="btn  btn-rounded"
                style="float: inline-end;background-color: #1B7B2C;color: white ;cursor: pointer !important;"
                [disabled]="clicked" (click)="ReUpload();clicked = true;" type="submit"
                *ngIf="UploadStatus == ''">{{"LogIn.SubmitDocs" | translate}}</button>
        </div>

    </div>

</div>