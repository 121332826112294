import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/core/services/LocalService/LocalService.service';
import { MmpService } from 'src/app/core/services/MMP-Service/mmp.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mmp-policy-details',
  templateUrl: './mmp-policy-details.component.html',
  styleUrls: ['./mmp-policy-details.component.scss']
})
export class MmpPolicyDetailsComponent implements OnInit {

  isLoading: any;
  memID: any;
  policyData: any;
  selectedService: boolean;

  constructor(public translate: TranslateService,
    public mmpService: MmpService,
    public _router: Router,
    private _LocalService: LocalService) {
    this.memID = this._LocalService.getJsonValue('memberID');
    this.getActivePolicy();

    this.isLoading = false;
  }

  ngOnInit(): void {
  }

  getActivePolicy() {
    var queryString = '?insuredIqamaId=' + this.memID;
    this.mmpService.GetActivePolicy(queryString).subscribe((res) => {
      this.policyData = res;
      debugger;
      this.isLoading = false;
    });
  }

  async ChooseClaimAction() {
    this.selectedService = true;
    let dir: string = '';
    if (this.translate.currentLang == 'en-US') {
      dir = 'ltr';
    } else {
      dir = 'rtl';
    }

    const { value: TypeId } = await Swal.fire({
      title:
        this.translate.currentLang == 'en-US'
          ? 'Choose Claim Action'
          : 'اختر اجراء المطالبة',
      iconHtml:
        '<img width=40 height=40 src="assets/img/swal-icons-img/icons8-natural-user-interface-2-100.png">',
      input: 'radio',
      showCloseButton: true,
      confirmButtonText:
        this.translate.currentLang == 'en-US' ? 'Submit' : 'متابعة',
      html: '<div class="swal2-radio claim-type-swal-input" style="display: flex;"><label><input type="radio" name="swal2-radio" value="7"><span class="swal2-label">Tracking</span></label><label><input type="radio" name="swal2-radio" value="8"><span class="swal2-label">New Cash Claim</span></label></div>',
      inputOptions: {
        '8':
          this.translate.currentLang == 'en-US'
            ? 'New Cash Claim'
            : 'مطالبة جديدة',
        '7':
          this.translate.currentLang == 'en-US'
            ? 'Tracking'
            : 'تتبع المطالبات',
      },
      didClose: () => {
        this.selectedService = false;
      },
      inputValidator: (value: any) => {
        if (!value) {
          return this.translate.currentLang == 'en-US'
            ? 'Please Select Claim Action'
            : 'يرجي اختيار اجراء المطالبة';
        }
      },
      customClass: {
        icon: 'icon-img',
        title: 'claim-type-swal-title',
        input: 'claim-type-swal-input',
        confirmButton: 'claim-type-confirm-btn',
      },
    });
    if (TypeId) {
      // new claim
      if (TypeId == 8) {
        this._router.navigate(['/new-mmp-cash-claim']);
      }
      // tracking
      else if (TypeId == 7) {
        this._router.navigate(['/mmp-cash-claims']);
      }
    }
  }
}
