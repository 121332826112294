import { Injectable } from '@angular/core';
import { LocalService } from './LocalService/LocalService.service';

@Injectable({
  providedIn: 'root'
})
export class IsAuthorizedService {

  constructor(
    private _LocalService: LocalService,
    ) { }
   isLoggedIn()
  {
    let verified=this._LocalService.getJsonValue('userVerified');
    return verified;
  } 

  
  isEligableToAccessOTP()
  {
    let AccessOTP=this._LocalService.getJsonValue('isEligableToAccessOTP');
    return AccessOTP;
  } 
  
  
  
  isEligableToAccessIdVerification()
  {

    let AccessOTP= JSON.parse(    localStorage.getItem('EligableForIDVerfication'));

    return AccessOTP;
  } 
}
